import {makeStyles} from '@material-ui/core';

import COLOR from '../../../styled/colors';

export const useStyles = makeStyles((theme) => ({
  stepContainer: {
    margin: theme.spacing(8, 0),
  },
  contentButton: {
    borderColor: COLOR.GREEN_BUTTON,
    color: COLOR.GREEN_BUTTON,
  },
  hyperlink: {
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    color: COLOR.GREENT_TEXT,
  },
}));
