import {
  DisplayFooterImageAction,
  DisplayStartDateAction,
  DisplayEndDateAction,
} from './types';

const editFooterImage = (value: string): DisplayFooterImageAction => ({
  type: 'display/footer_image',
  payload: value,
});

const editStartDate = (value: string): DisplayStartDateAction => ({
  type: 'display/start_date',
  payload: value,
});

const editEndDate = (value: string): DisplayEndDateAction => ({
  type: 'display/end_date',
  payload: value,
});

export default {
  editFooterImage,
  editStartDate,
  editEndDate,
};
