import React, {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import COLOR from '../../styled/colors';
import SearchBar from '../../presentation/SearchBar';
import MyButton from '../../presentation/button';
import MyTable from '../../presentation/Table';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import {makeStyles, Grid} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {
  fetchConsigneeData,
  createConsignee,
  deleteConsignee,
} from '../../store/add/actions';
import {PageHeader} from '../../presentation/withHeader';
import moment from 'moment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import {Buttons} from '../../presentation/ButtonsGroup';
import { CASL } from '@aglive/frontend-core';

type Props = {};
const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  searchBarContainer: {
    flexGrow: 1,
    marginRight: 50,
  },
  buttonContainer: {
    marginTop: 6,
    marginRight: 2,
  },
  formControl: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 210,
    maxHeight: 56,
    marginRight: 20,
  },
}));

const ConsigneeLibrary: React.FC<Props> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const wholeList = useAppSelector((state) => state.add.consigneeData);
  const consigneeList = wholeList.filter((consignee) => consignee.createdAt);
  const history = useHistory();
  const {path} = useRouteMatch();
  const [query, setQuery] = useState('');

  const ability = CASL.useAbility(CASL.AbilityContext);
  const data = ['Name', 'Added On'];
  ability.can('update', 'consignee') ? data.push('Action') : data.push('');

  useEffect(() => {
    dispatch(fetchConsigneeData());
  }, []);

  return (
    <PageHeader config={{title: 'Consignees Library', margin: 60, back: false}}>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar query={query} setQuery={setQuery} label={'Search'} />
        </Grid>
        <CASL.Can I="create" a="consignee">
          <Grid item className={classes.buttonContainer}>
            <MyButton
              text={'Create New'}
              variant="contained"
              width={160}
              fontSize={18}
              onClick={() => history.push(`${path}/new`)}
            />
          </Grid>
        </CASL.Can>
      </Grid>

      <MyTable
        heads={data}
        rows={consigneeList
          .filter((consignee) =>
            consignee.receiverName.toLowerCase().includes(query.toLowerCase()),
          )
          .map((consignee, index) => {
            return [
              <Button
                onClick={() => {
                  history.push({
                    pathname: `${path}/view/${consignee.agliveToken}`,
                  });
                }}>
                <span
                  style={{
                    color: COLOR.GREENT_TEXT,
                    textTransform: 'capitalize',
                  }}>
                  <u>{consignee.receiverName}</u>
                </span>
              </Button>,
              moment(consignee.createdAt).format('DD/MM/YYYY'),
              <CASL.Can I="update" a="consignee">
                <div style={{display: 'flex', gap: 20, width: 'fit-content'}}>
                  <div>
                    <EditIcon
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        history.push({
                          pathname: `${path}/${consignee.agliveToken}`,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <FileCopyIcon
                      style={{cursor: 'pointer'}}
                      onClick={() =>
                        dispatch(
                          createConsignee(
                            Object.assign(
                              {...consignee},
                              {
                                receiverName: `Copy of ${consignee.receiverName}`,
                              },
                            ),
                          ),
                        ).then((uniqueID) => history.push(`${path}/${uniqueID}`))
                      }
                    />
                  </div>
                  <div>
                    <DeleteIcon
                      onClick={() => {
                        dispatch(
                          toggleModal({
                            status: 'warning',
                            title: 'Delete Consignee?',
                            subtitle: 'This action cannot be undone',
                            renderButton: (
                              <Buttons
                                leftButtonTitle="Cancel"
                                rightButtonTitle="Delete"
                                leftButtonOnClick={() => {
                                  dispatch(toggleModalOff());
                                }}
                                rightButtonOnClick={() => {
                                  dispatch(toggleModalOff());
                                  dispatch(deleteConsignee(consignee));
                                }}
                              />
                            ),
                          }),
                        );
                      }}
                      style={{cursor: 'pointer'}}
                    />
                  </div>
                </div>
              </CASL.Can>,
            ];
          })}
      />
    </PageHeader>
  );
};

export default ConsigneeLibrary;
