import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import {
  makeStyles,
  withStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import COLOR from '../styled/colors';

interface TabProps {
  tabHeaders: Array<string>;
  children: React.ReactNode;
  value: number;
  setValue: (value: number) => void;
  style?: string;
  tabStyle?: string;
  activeTabStyle?: string;
  labelStyle?: string;
  enableIndicator?: boolean;
  readOnly?: boolean;
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  tabs: {
    backgroundColor: COLOR.WHITE,
    border: `1px solid ${COLOR.GRAY_BORDER}`,
    color: COLOR.BLACK,
  },
  tabsLabel: {
    fontSize: 25,
    fontFamily: 'Oswald',
    textTransform: 'capitalize',
  },
  activeTab: {
    backgroundColor: COLOR.GREEN, //`${COLOR.GREEN} !important`,
  },
}));

export default function FullWidthTabs(props: TabProps) {
  const classes = useStyles();
  const theme = useTheme();
  const {value, tabHeaders, children, setValue} = props;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        elevation={props.enableIndicator ? 4 : 0}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{
            style: {display: props.enableIndicator ? 'inherit' : 'none'},
          }}
          classes={{root: props.style}}>
          {tabHeaders.map((header, index) => (
            <StyledTab
              key={header}
              style={{
                minWidth: `${Math.floor(100 / tabHeaders.length)}%`,
                flex: 1,
                maxWidth: '100%',
              }}
              className={
                index === value
                  ? `${props.activeTabStyle || classes.activeTab} ${
                      props.tabStyle || classes.tabs
                    }`
                  : props.tabStyle || classes.tabs
              }
              label={
                <span className={props.labelStyle || classes.tabsLabel}>
                  {header.split(/(?=[A-Z])/).join(' ')}
                </span>
              }
              {...a11yProps(index)}
              disabled={props.readOnly}
            />
          ))}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}>
        {children}
      </SwipeableViews>
    </div>
  );
}

const StyledTab = withStyles({
  selected: {},
  textColorPrimary: {
    '&$selected': {
      color: 'white',
    },
  },
})(Tab);
