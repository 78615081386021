import {Box, Typography} from '@material-ui/core';
import COLOR from '../styled/colors';

const LabelData: React.FC<{
  label: string;
  field: string;
  characters?: number;
  minHeight?: number;
  placeHolder?: string;
}> = ({label, field, characters, minHeight, placeHolder}) => {
  return (
    <Box marginBottom={3}>
      <Typography variant="h6" role="label" style={{fontWeight: 600, marginBottom: 5}}>
        <strong>{label}</strong>
      </Typography>
      <Typography
        variant="h6"
        role="label"
        style={{
          opacity: 0.6,
          wordWrap: 'break-word',
          whiteSpace: 'pre-line',
          overflow: 'auto',
          minHeight: minHeight || 0,
          maxHeight: 250,
          marginBottom: 5
        }}>
        {field || placeHolder}
      </Typography>
      {characters && (
        <Typography
          variant="h6"
          role="label"
          align="right"
          style={{color: COLOR.GRAY_TEXT, fontSize: 14}}>
          {`${field?.length || 0}/${characters} Characters`}
        </Typography>
      )}
    </Box>
  );
};

export default LabelData;
