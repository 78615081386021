import React from 'react';

import { Typography, Grid, TextField } from "@material-ui/core";
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { LayerNode } from "../../store/site/types";
import { HyperLink } from '../../presentation/word';

type SiteLayerTreeType = {
  tree: LayerNode;
  addSibling: (arg0: Array<number>) => void;
  addChild: (arg0: Array<number>) => void;
  removeNode: (arg0: Array<number>) => void;
  changeLayerName: (arg0: string, arg1: Array<number>) => void;
  index: Array<number>;
  allowDelete: boolean;
}

const SiteLayerTree: React.FC<SiteLayerTreeType> = ({ 
  tree, 
  addSibling, 
  addChild, 
  removeNode,
  changeLayerName,
  index,
  allowDelete
}) => (
  <>
    {tree.children && tree.children.map((node, nodeIndex) => (
      <>
        <Grid container justify="space-between" style={{ width: 500 }}>
          <Grid item xs={4}>
            <Typography variant='h6' style={{ fontWeight: 'bold' }}>
              Layer {node.name}
            </Typography>
          </Grid>
          <Grid item container xs={8} justify="space-between">
            <Grid item onClick={() => addSibling([...index, nodeIndex])} style={{ cursor: 'pointer' }}>
              <AddBoxIcon style={{ paddingRight: 5, fontSize: 25 }} />
              <HyperLink onClick={(e: Event) => { e.preventDefault(); }} style={{ verticalAlign: 'top' }}>Add New Layer</HyperLink>
            </Grid>
            <Grid item onClick={() => addChild([...index, nodeIndex])} style={{ cursor: 'pointer' }}>
              <AddBoxIcon style={{ paddingRight: 5, fontSize: 25 }} />
              <HyperLink onClick={(e: Event) => { e.preventDefault(); }} style={{ verticalAlign: 'top' }}>Add New Sublayer</HyperLink>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: -10, marginBottom: 30 }} alignItems="center">
          <Grid item>
            <TextField 
              value={node.layerName}
              onChange={e => changeLayerName(e.target.value, [...index, nodeIndex])}
              margin="normal" 
              variant="outlined"
              InputLabelProps={{
                shrink: false,
              }}
              style={{ width: 500 }}
            />
          </Grid>
          {allowDelete && (
            <Grid item onClick={() => removeNode([...index, nodeIndex])} style={{ cursor: 'pointer' }}>
              <DeleteOutlinedIcon style={{ paddingLeft: 20, fontSize: 35 }} />
            </Grid>
          )}
        </Grid>
        {node.children && (
          <SiteLayerTree 
            tree={node} 
            addSibling={addSibling} 
            addChild={addChild}
            removeNode={removeNode}
            changeLayerName={changeLayerName}
            index={[...index, nodeIndex]}
            allowDelete
          />
        )}
      </>
    ))}
  </>
);

export default SiteLayerTree;
