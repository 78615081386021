import React, {useState, useEffect} from 'react';
import {useHistory, useRouteMatch} from 'react-router';
import {Link} from 'react-router-dom';

import _ from 'lodash';
import moment from 'moment';
import produce from 'immer';
import {TokenService} from '@aglive/data-model';
import {Button, Grid} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Qrcode from 'qrcode.react';
import {pdf} from '@react-pdf/renderer';

import {useStyles} from './styles';
import SearchBar from '../../../presentation/SearchBar';
import MyButton from '../../../presentation/button';
import MyTable from '../../../presentation/Table';
import {PageHeader} from '../../../presentation/withHeader';
import PromotionLanding from '../PromotionLanding';
import SampleCattlePromotion from '../../../img/promotion/SampleCattlePromotion.svg';
import SampleProductPromotion from '../../../img/promotion/SampleProductPromotion.svg';
import {promotionLandingContent} from '../PromotionLanding/promotionLandingContent';
import PosterPreview from '../PosterPreview';
import {processBrandList} from '../CreateAssetPromotion/utils';
import {
  createPromotion,
  getPromotions,
  manageArchivePromotion,
} from '../../../store/promotion/actions';
import {CompleteProductPromotion} from '../../../store/promotion/types';
import {useAppDispatch, useAppSelector} from '../../../utils/hooks';
import COLOR from '../../../styled/colors';
import GreenQRCodeIcon from '../../../img/GreenQRCodeIcon.svg';
import {toggleModal, toggleModalOff} from '../../../store/modal/actions';
import {Buttons} from '../../../presentation/ButtonsGroup';
import {usePrintPoster, useDownloadPoster} from '../../../utils/promotion';
import {fetchBrands} from '../../../store/brand/actions';
import PopupMenu from './PopupMenu';
import PreviewModal from './PreviewModal';
import PromotionPDF from '../PromotionPDF';
import {
  SPINNER_TOGGLE_OFF,
  SPINNER_TOGGLE_ON,
} from '../../../store/spinner/types';
import {fetchProductProfileTokens} from '../ProductProfileLibrary/utils';
import {convertPromotionTokens} from './utils';
import CONSTANT from '../../../config/constant';
import ArchiveIcon from '../../../img/ArchiveIcon.svg';

const tableHeaders = [
  'Promotion Name',
  'Status',
  'Expiry Date',
  'Preview',
  'Actions',
];

const PromotionLibrary: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {path} = useRouteMatch();

  const industryType = useAppSelector(
    (state) => state.user.businessProfileData.industryType,
  );
  const isAngus = useAppSelector(
    // (state) => !!state.user.businessProfileData.angusProfile,
    (state) =>
      state.user.businessProfileData.industryType === 'ANIMALS' &&
      state.user.businessProfileData.businessType === 'Angus',
  );
  const isAnimal = industryType === 'ANIMALS';
  const isProducts = industryType === 'PRODUCTS';
  const ARCHIVED_PROMOTION_LIBRARY_PATH = `/private/${
    isProducts ? 'codes/promotionCodes/archived' : 'assets/promotions/archived'
  }`;
  const userId = useAppSelector((state) => state.auth.wallet);
  const [brandList, setBrandList] = useState<
    Array<{
      name: string;
      agliveToken: string;
      logo: string;
    }>
  >([]);

  const [query, setQuery] = useState('');
  const [openPreview, setOpenPreview] = useState(false);
  const [promotions, setPromotions] = useState<
    (TokenService.AnimalPromotion | CompleteProductPromotion)[]
  >([]);
  const [productProfiles, setProductProfiles] = useState<
    TokenService.ProductToken[]
  >([]);

  // Custom hooks for download / print poster
  const {printContent, setPrintContent, customQRWidth, componentRef} =
    usePrintPoster(isAngus);
  const {
    promotionToDownload,
    setPromotionToDownload,
    QRCodeImage,
    getQRCodeSize,
    downloadPosterHandler,
  } = useDownloadPoster();

  useEffect(() => {
    (async () => {
      const [brandTokens, promotions, productProfiles] = await Promise.all([
        fetchBrands(dispatch),
        dispatch(
          getPromotions(industryType === 'ANIMALS' ? industryType : 'PRODUCTS'),
        ),
        fetchProductProfileTokens(),
      ]);
      if (brandTokens) {
        setBrandList(processBrandList(brandTokens));
      }
      setProductProfiles(productProfiles);
      // Convert product promotion tokens into tokens with product profile details
      const convertedPromotions = convertPromotionTokens(
        promotions,
        productProfiles,
      );

      // Overwrite logo if promotion's logo is selected as brand logo
      setPromotions(
        convertedPromotions
          ?.map((promotion) => {
            const promotionDetails = promotion.details;
            if (brandTokens) {
              const tempBrandList = processBrandList(brandTokens);
              if (
                tempBrandList?.length > 0 &&
                promotionDetails.story?.logoType === 'brand'
              ) {
                const brandIndex = tempBrandList.findIndex(
                  (brand) =>
                    brand.agliveToken === promotionDetails.brand.agliveToken,
                );
                if (brandIndex !== -1) {
                  promotionDetails.story.logo = tempBrandList[brandIndex]?.logo;
                }
              }
            }
            return promotion;
          })
          .filter((promotion) => !promotion.details.archive),
      );
    })();
  }, [dispatch, userId]);

  const copyPromotion = async (promotion: TokenService.PromotionToken) => {
    // Because product promotion tokens here are populated with some product profile details (story, brand, pages)
    // for display purpose. Thus, they need to be removed before creating the copied token.
    const filteredPromotionDetails =
      promotion.details.type === 'PRODUCTS'
        ? _.pick(promotion.details, [
            'status',
            'type',
            'archive',
            'name',
            'product',
            'display',
          ])
        : promotion.details;
    const copiedPromotionToken = await dispatch(
      createPromotion(
        produce(filteredPromotionDetails, (draft) => {
          draft.name = `${draft.name} - Copy`;
        }),
      ),
    );

    if (copiedPromotionToken) {
      refetchData();
    }
  };

  const archivePromotion = (promotion: TokenService.PromotionToken) => {
    dispatch(
      toggleModal({
        status: 'warning',
        title: 'Archive?',
        subtitle:
          'Archived items remain active and results will still be displayed when they are scanned',
        renderButton: (
          <Buttons
            leftButtonTitle="No"
            rightButtonTitle="Yes"
            leftButtonOnClick={() => {
              dispatch(toggleModalOff());
            }}
            rightButtonOnClick={() => {
              dispatch(
                manageArchivePromotion(promotion, true, () =>
                  history.push(ARCHIVED_PROMOTION_LIBRARY_PATH),
                ),
              );
            }}
          />
        ),
      }),
    );
  };

  const refetchData = async () => {
    const promotions = await dispatch(
      getPromotions(industryType === 'ANIMALS' ? industryType : 'PRODUCTS'),
    );
    const convertedPromotions = convertPromotionTokens(
      promotions,
      productProfiles,
    );
    setPromotions(
      convertedPromotions
        ?.map((promotion) => {
          const promotionDetails = promotion.details;
          if (
            brandList?.length > 0 &&
            promotionDetails.story?.logoType === 'brand'
          ) {
            const brandIndex = brandList.findIndex(
              (brand) =>
                brand.agliveToken === promotionDetails.brand.agliveToken,
            );
            if (brandIndex !== -1) {
              promotionDetails.story.logo = brandList[brandIndex]?.logo;
            }
          }
          return promotion;
        })
        .filter((promotion) => !promotion.details.archive),
    );
  };

  const downloadPoster = async () => {
    if (promotionToDownload) {
      dispatch({type: SPINNER_TOGGLE_ON});
      const QRSize = await getQRCodeSize(promotionToDownload, isAngus);
      const blob = await pdf(
        <PromotionPDF
          story={promotionToDownload?.details.story}
          display={promotionToDownload?.details.display}
          isAngus={isAngus}
          QRCodeImage={QRCodeImage}
          QRSize={QRSize}
        />,
      ).toBlob();
      downloadPosterHandler(blob, promotionToDownload?.details.name);
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };

  const onCreate = () => {
    if (isProducts || isAngus || isAnimal) {
      history.push(`${path}/new`);
    } else {
      dispatch(
        toggleModal({
          status: 'warning',
          title: 'Angus Verified Member Feature',
          subtitle:
            'Sign up with Angus Verified to use these cattle promotion tools',
          renderButton: (
            <Buttons
              leftButtonTitle="Close"
              rightButtonTitle="Join Angus Verified"
              leftButtonOnClick={() => dispatch(toggleModalOff())}
              rightButtonOnClick={() =>
                window.open('https://www.angusaustralia.com.au/', '_blank')
              }
            />
          ),
        }),
      );
    }
  };

  const PromotionLibraryContent = (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label="Search Promotion Name"
          />
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <MyButton
            text={'Create New'}
            variant="contained"
            width={160}
            fontSize={18}
            onClick={onCreate}
          />
        </Grid>
      </Grid>
      {promotions?.length > 0 ? (
        <MyTable
          firstColumnWidth={classes.firstColumnWidth}
          heads={tableHeaders}
          rows={promotions
            ?.filter((promotion) =>
              promotion.details.name
                .toLowerCase()
                .includes(query.toLowerCase()),
            )
            .map((promotion) => [
              <Link
                to={{
                  pathname: `${path}/view/${
                    Object.values(promotion.externalIds)[0].agliveToken
                  }`,
                }}
                className={classes.hyperlink}>
                {promotion.details.name}
              </Link>,
              <span style={{textTransform: 'capitalize'}}>
                {promotion.details.status}
              </span>,
              <span
                style={{
                  color:
                    promotion.details.display.endDate &&
                    promotion.details.status === 'published' &&
                    moment(promotion.details.display.endDate).valueOf() <
                      moment().valueOf()
                      ? COLOR.RED_WARNING
                      : '',
                }}>
                {promotion.details.display.endDate &&
                promotion.details.status === 'published'
                  ? moment(promotion.details.display.endDate).format(
                      'DD/MM/YYYY',
                    )
                  : '-'}
              </span>,
              <Button
                onClick={() => {
                  setPromotionToDownload(promotion);
                  setOpenPreview(true);
                }}>
                <img src={GreenQRCodeIcon} />
                <span
                  style={{
                    color: COLOR.GREENT_TEXT,
                    textTransform: 'capitalize',
                    marginLeft: '8px',
                  }}>
                  <u>Preview</u>
                </span>
              </Button>,
              <div style={{display: 'flex'}}>
                <div style={{marginRight: 20}}>
                  <EditIcon
                    style={{cursor: 'pointer'}}
                    onClick={() =>
                      history.push(
                        `${path}/edit/${
                          Object.values(promotion.externalIds)[0].agliveToken
                        }`,
                      )
                    }
                  />
                </div>
                <div style={{marginRight: 20}}>
                  <FileCopyIcon
                    onClick={() => copyPromotion(promotion)}
                    style={{cursor: 'pointer'}}
                  />
                </div>
                {promotion.details.display.endDate &&
                moment(promotion.details.display.endDate).valueOf() >
                  moment().valueOf() &&
                promotion.details.status === 'published' ? (
                  <div
                    style={{marginRight: 20}}
                    onClick={() => setPromotionToDownload(promotion)}>
                    <PopupMenu
                      promotion={promotion}
                      setPrintContent={setPrintContent}
                      downloadPoster={downloadPoster}
                      archivePromotion={archivePromotion}
                    />
                  </div>
                ) : (
                  <div style={{marginRight: 20}}>
                    <img
                      src={ArchiveIcon}
                      width="27px"
                      height="27px"
                      style={{cursor: 'pointer', marginRight: '8px'}}
                      onClick={() => archivePromotion(promotion)}
                      alt="Archive Promotion"
                    />
                  </div>
                )}
              </div>,
            ])}
        />
      ) : (
        <PromotionLanding
          promotionImage={
            industryType === 'ANIMALS'
              ? SampleCattlePromotion
              : SampleProductPromotion
          }
          promotionLandingContent={promotionLandingContent(
            industryType === 'ANIMALS' ? 'cattlePromotion' : 'productPromotion',
          )}
        />
      )}

      <Grid item style={{marginTop: 20}}>
        <Link to={`${path}/archived`} className={classes.hyperlink}>
          {industryType === 'ANIMALS'
            ? 'Archived Promotions'
            : 'Archived Promotion Codes'}
        </Link>
      </Grid>

      <PreviewModal
        openPreview={openPreview}
        setOpenPreview={setOpenPreview}
        previewPromotion={promotionToDownload}
        downloadPoster={downloadPoster}
        isAngus={isAngus}
        brandLogo={promotionToDownload?.details.story?.logo || null}
      />

      <div style={{display: 'none'}}>
        <PosterPreview
          display={printContent?.display}
          story={printContent?.story}
          footerImageOption={printContent?.display.footerImage ? 'image' : ''}
          angusVerified={isAngus}
          customQRWidth={customQRWidth}
          ref={componentRef}
          agliveToken={printContent?.agliveToken}
          brandLogo={promotionToDownload?.details.story?.logo || null}
        />

        <Qrcode
          value={
            promotionToDownload?.externalIds[0].agliveToken
              ? `${CONSTANT.SCAN_URL(
                  promotionToDownload?.externalIds[0].agliveToken,
                )}`
              : 'Invalid code'
          }
          size={256}
          id="qrCode"
        />
      </div>
    </>
  );

  return (
    <PageHeader
      config={{
        title: industryType === 'ANIMALS' ? 'Promotions' : 'Promotion Codes',
      }}>
      {PromotionLibraryContent}
    </PageHeader>
  );
};

export default PromotionLibrary;
