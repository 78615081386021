import React ,{ useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormHelperText, Grid, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import { findTargetBrandIndex, getBrandAgliveTokenId } from '../brand/utils';

import { TokenService } from '@aglive/data-model';

interface iProductHeader {
  productName: TokenService.ProductToken["details"]["name"];
  productBrand?: TokenService.BrandToken; // manually pass brand token to header for prefill
  onNameChange: (arg0: string) => void;
  onBrandChange: (arg0: TokenService.BrandToken) => void;
  disabled?: boolean;
  brandList?: Array<TokenService.BrandToken>;

  productNameError: string;
  productBrandError: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
      // minWidth: 879,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

const ProductHeader: React.FC<iProductHeader> =({
  productName,
  productBrand,

  onNameChange,
  onBrandChange,
  disabled = false,
  brandList = [],

  productNameError,
  productBrandError
}) => {
  const classes = useStyles();

  const [brand, setBrand] = useState('' as number | '');
  const brandHandleChange =(event: React.ChangeEvent<{ value: unknown }>)=>{
    const index=event.target.value as number;
    setBrand(index);
    onBrandChange(brandList[index]);
  }

  useEffect(() => {
    if (productBrand) {
      const targetBrandIndex = findTargetBrandIndex(
        brandList,
        getBrandAgliveTokenId(productBrand)
      );
      
      setBrand(targetBrandIndex);
      onBrandChange(brandList[targetBrandIndex]);
    }
  }, [productBrand]);
    
  return(
    <Grid item container spacing={3}>
      <Grid item xs={6}>
        <Typography variant="h6" role="label" style={{ fontWeight: 600, marginBottom: 10 }}>
          Product Name
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={productName}
          onChange={(e) => onNameChange(e.target.value)}
          disabled={disabled}
          error={!!productNameError.length}
          helperText={productNameError}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" role="label" style={{ fontWeight: 600, marginBottom: 10 }}>
          Brand
        </Typography>
        {
          disabled ? (
            <TextField
              fullWidth
              variant="outlined"
              value={productBrand?.details?.name}
              onChange={(e) => {}}
              disabled={disabled}
            />
          ) : (
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
              error={!!productBrandError.length}>
              <InputLabel id="product-brand">{"Please select"}</InputLabel>
              <Select
                label={"Please Select"}
                labelId="product-brand"
                value={brand}
                onChange={brandHandleChange}  
              >
                {brandList.map((brand,index) => {
                  return (
                    <MenuItem value={index} key={brand.externalIds[0].agliveToken}>{brand?.details?.name}</MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{productBrandError}</FormHelperText>
            </FormControl>
          )
        }
      </Grid>
    </Grid>
  )
};

export default ProductHeader;
