import React, {useEffect, useRef, useState} from 'react';

import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {TokenService} from '@aglive/data-model';

import {useStyles} from './styles';
import Tabs from '../../../presentation/Tab';
import TabPanel from '../../../presentation/TabPanel';
import MyButton from '../../../presentation/button';
import moment from 'moment';
import COLOR from '../../../styled/colors';
import PosterPreview from '../PosterPreview';
import {fileUploader} from '../../../store/product/actions';
import {useAppSelector} from '../../../utils/hooks';

enum TabMap {
  display,
}
const TabLabels = Object.values(TabMap).slice(0, 1);

interface DisplayPosterProps {
  display: TokenService.PromotionToken['details']['display'];
  story: TokenService.ProductToken['details']['story'];
  status: TokenService.PromotionToken['details']['status'];
  footerImageOption: 'image' | '';
  validationState: TokenService.PromotionToken['details']['display'];
  onFooterImageOptionChange: (value: 'image' | '') => void;
  onFooterImageChange: (value: string) => void;
  onStartDateChange: (value: string) => void;
  onEndDateChange: (value: string) => void;
  qrOverlay?: boolean;
  agliveToken?: string;
  isAngus?: boolean;
  originalEndDate?: string;
  brandLogo: string | null;
}

const DisplayPoster: React.FC<DisplayPosterProps> = ({
  display,
  story,
  status,
  validationState,
  footerImageOption,
  onFooterImageOptionChange,
  onFooterImageChange,
  onStartDateChange,
  onEndDateChange,
  qrOverlay,
  agliveToken,
  isAngus,
  originalEndDate,
  brandLogo,
}) => {
  const classes = useStyles();
  const userId = useAppSelector((state) => state.auth.wallet);

  const [tabValue, setTabValue] = useState(TabMap.display);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const footerImageRef = useRef(null);

  const startDateErrorMsg =
    startDate === 'Invalid Date' ? startDate : validationState.startDate;
  const endDateErrorMsg =
    endDate === 'Invalid Date' ? endDate : validationState.endDate;

  useEffect(() => {
    if (display.startDate && startDate === null) {
      setStartDate(display.startDate);
    }
    if (display.endDate && endDate === null) {
      setEndDate(display.endDate);
    }
  }, [display]);

  // Handle image uploads
  const uploadImageHandler = async (
    e,
    imageDispatch: (value: string) => void,
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10485760) {
        window.alert('Upload image size more than 10mb ！');
      } else {
        const response = await fileUploader(userId, e.target.files[0]);
        const responseData = response[0].data;
        if (typeof responseData == 'string') {
          imageDispatch(responseData);
        }
      }
    }
  };

  return (
    <>
      <Grid item container xs={12} lg={5} justifyContent="center">
        <PosterPreview
          display={display}
          story={story}
          footerImageOption={footerImageOption}
          qrOverlay={qrOverlay}
          agliveToken={agliveToken}
          border={true}
          angusVerified={isAngus}
          scale={0.9}
          brandLogo={brandLogo}
        />
      </Grid>
      <Grid item xs={12} lg={7}>
        <Tabs
          tabHeaders={TabLabels as string[]}
          value={tabValue}
          setValue={setTabValue}>
          <TabPanel
            isShown={tabValue === TabMap.display}
            index={TabMap.display}>
            <Box p={3} className={classes.tabContainer}>
              <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
                <Typography
                  variant="h6"
                  style={{fontWeight: 700, marginBottom: '10px'}}>
                  1. Set Footer Image
                </Typography>
                <RadioGroup
                  aria-label="footerImage"
                  name="footerImage"
                  value={footerImageOption}
                  onChange={(e) => {
                    onFooterImageOptionChange(e.target.value as 'image' | '');
                    onFooterImageChange(null);
                    footerImageRef.current.value = '';
                  }}>
                  <FormControlLabel
                    value="image"
                    control={<Radio color="primary" />}
                    label="Upload Image"
                  />
                  <Grid
                    container
                    alignItems="flex-start"
                    justifyContent="center"
                    spacing={3}
                    style={{
                      paddingLeft: '35px',
                      marginBottom: '5px',
                    }}>
                    <Grid item xs={7}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={
                          display.footerImage
                            ? decodeURIComponent(
                                display.footerImage.split('/').reverse()[0],
                              )
                            : ''
                        }
                        placeholder={'png, jpg, or jpeg'}
                        disabled
                        error={
                          !!validationState.footerImage &&
                          footerImageOption === 'image'
                        }
                        helperText={
                          footerImageOption === 'image' &&
                          validationState.footerImage
                        }
                        FormHelperTextProps={{
                          className: classes.errorHelperText,
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <MyButton
                        text={'Upload File'}
                        variant="outlined"
                        onClick={() => {
                          footerImageRef.current.click();
                        }}
                        buttonClass={`${classes.contentButton} ${classes.uploadBtn}`}
                        disabled={footerImageOption !== 'image'}
                      />
                      <input
                        type="file"
                        accept="image/jpg, image/jpeg, image/png"
                        onChange={(e) => {
                          uploadImageHandler(e, onFooterImageChange);
                        }}
                        ref={footerImageRef}
                        style={{height: 0, width: 0}}
                      />
                    </Grid>
                  </Grid>
                  <FormControlLabel
                    value=""
                    control={<Radio color="primary" />}
                    label="No Image"
                  />
                </RadioGroup>
              </Box>

              <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
                <Typography
                  variant="h6"
                  style={{fontWeight: 700, marginBottom: '10px'}}>
                  2. Set Duration
                </Typography>
                {status === 'published' && (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      paddingLeft: '20px',
                      marginBottom: '10px',
                    }}>
                    <Grid container item xs={6} alignItems="center">
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          style={{fontWeight: 700, marginBottom: '10px'}}>
                          Start Date
                        </Typography>
                      </Grid>
                      <Grid item container xs={12} alignItems="center">
                        <Grid item>
                          <Typography variant="h6">
                            {(display?.startDate &&
                              moment(display.startDate).format('DD/MM/YYYY')) ||
                              '-'}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <div className={classes.dateDivider}></div>
                        </Grid>
                        <Grid item>
                          <Typography variant="h6">
                            {(display?.startDate &&
                              moment(display.startDate).format('hh:mm A')) ||
                              '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container item xs={6} alignItems="center">
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          style={{fontWeight: 700, marginBottom: '10px'}}>
                          End Date
                        </Typography>
                      </Grid>
                      <Grid item container xs={12} alignItems="center">
                        <Grid item>
                          <Typography variant="h6">
                            {(originalEndDate &&
                              moment(originalEndDate).format('DD/MM/YYYY')) ||
                              '-'}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <div className={classes.dateDivider}></div>
                        </Grid>
                        <Grid item>
                          <Typography variant="h6">
                            {(originalEndDate &&
                              moment(originalEndDate).format('hh:mm A')) ||
                              '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    paddingLeft: '20px',
                    marginBottom: '10px',
                  }}>
                  {status !== 'published' && (
                    <Grid container item xs={6} alignItems="center">
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          style={{fontWeight: 700, marginBottom: '10px'}}>
                          Start Date
                        </Typography>
                      </Grid>
                      <Grid item xs={10} xl={8}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDateTimePicker
                            autoOk
                            value={startDate}
                            onChange={(date) => {
                              onStartDateChange(
                                moment(date).toISOString() || '',
                              );
                              setStartDate(
                                moment(date).toISOString() || 'Invalid Date',
                              );
                            }}
                            keyboardIcon={
                              <DateRangeIcon
                                style={{
                                  fill: COLOR.BLACK,
                                }}
                              />
                            }
                            format="dd/MM/yyyy hh:mm a"
                            emptyLabel="dd/mm/yyyy | hh:mm AM"
                            className={`${classes.dateField} ${
                              !!startDateErrorMsg ? classes.dateFieldError : ''
                            }`}
                            fullWidth
                            InputProps={{
                              classes: {input: classes.dateFieldFont},
                            }}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            invalidDateMessage=""
                          />
                        </MuiPickersUtilsProvider>
                        {!!startDateErrorMsg && (
                          <Typography className={classes.dateFieldErrorText}>
                            {startDateErrorMsg}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    item
                    xs={status === 'published' ? 12 : 6}
                    alignItems="center">
                    {status === 'published' && (
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: 700,
                            marginBottom: '10px',
                            marginTop: '15px',
                          }}>
                          {moment().valueOf() >
                          moment(originalEndDate).valueOf()
                            ? 'Reactivate'
                            : 'Change Duration'}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        style={{fontWeight: 700, marginBottom: '10px'}}>
                        {status === 'published' ? 'New End Date' : 'End Date'}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={status === 'published' ? 5 : 10}
                      xl={status === 'published' ? 5 : 8}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                          autoOk
                          value={endDate}
                          onChange={(date) => {
                            onEndDateChange(moment(date).toISOString() || '');
                            setEndDate(
                              moment(date).toISOString() || 'Invalid Date',
                            );
                          }}
                          keyboardIcon={
                            <DateRangeIcon style={{fill: COLOR.BLACK}} />
                          }
                          format="dd/MM/yyyy hh:mm a"
                          emptyLabel="dd/mm/yyyy | hh:mm AM"
                          className={`${classes.dateField} ${
                            !!endDateErrorMsg ? classes.dateFieldError : ''
                          }`}
                          fullWidth
                          InputProps={{
                            classes: {input: classes.dateFieldFont},
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          invalidDateMessage=""
                        />
                      </MuiPickersUtilsProvider>
                      {!!endDateErrorMsg && (
                        <Typography className={classes.dateFieldErrorText}>
                          {endDateErrorMsg}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </TabPanel>
        </Tabs>
      </Grid>
    </>
  );
};

export default DisplayPoster;
