// React
import React from 'react';
// Other packages
import moment from 'moment';
// Material UI
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import PrintIcon from '@material-ui/icons/Print';

// Presentation, Container, Style
import MyTable from '../../presentation/Table';

import CONSTANT from '../../config/constant';

// Redux, Types
import {iCodeEntryDocumentResponse} from '../../store/code/types';
import { PlantCodeDocumentDetails } from '../../store/plant/types';
import { useStyles } from '../promotion/PromotionLibrary/styles';
import { useAppDispatch } from '../../utils/hooks';
import { downloadCodeEntryCSV } from '../../store/code/actions';

interface Props {
  tableHeader: Array<string>;
  codeList: Array<iCodeEntryDocumentResponse<PlantCodeDocumentDetails>>;
  query: string;
  onPrint?: (arg0: iCodeEntryDocumentResponse<PlantCodeDocumentDetails>) => void;
}

const CodeLibraryTable: React.FC<Props> = ({
  tableHeader,
  codeList,
  query,
  onPrint,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  return (
    <MyTable
      firstColumnWidth={classes.firstColumnWidth}
      heads={tableHeader}
      rows={codeList
        ?.filter((res) => res.details.assetProfile.assetName.includes(query))
        .map((entry) => [
          entry.details.assetProfile.assetName,
          entry.details.quantity,
          moment(entry.createdAt).format('DD/MM/YYYY'),
          <div style={{display: 'flex', gap: 10}}>
            <GetAppSharpIcon
              style={{cursor: 'pointer'}}
              onClick={() => dispatch(downloadCodeEntryCSV(entry,
                entry.details.codes.map(code => [
                  code,
                  CONSTANT.ASSETTYPE.PLANT,
                  entry.details.assetProfile.assetName,
                  entry.details.pic_id,
                  entry.details.source.companyName,
                  entry.details.source.creatorEmail,
                ])))}
            />
            <PrintIcon onClick={() => onPrint(entry)} style={{cursor: 'pointer'}} />
          </div>
        ])}
    />
  );
};

export default CodeLibraryTable;
