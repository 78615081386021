import {TokenService} from '@aglive/data-model';
import produce from 'immer';

import {PRODUCT_PROFILE_ATTACHMENT_TEMPLATE} from '../../ProductProfile/CreateProductProfile/productProfileState/reducer';
import {processBrandList, validateData} from '../utils';
import {AssetPromotionActions, InitialState} from './types';

export const INITIAL_ADDITIONAL_SECTION: TokenService.ProductToken['details']['story']['additional'][number] =
  {
    title: '',
    content: '',
    mediaType: 'image',
    url: '',
  };

export const INITIAL_MILESTONES: TokenService.ProductToken['details']['story']['milestones'][number] =
  {
    title: '',
    details: '',
  };

export const INITIAL_MOB: TokenService.AnimalPromotion['details']['mob']['linkMob'] =
  {
    calves: [],
    info: {
      LT: false,
      EU: false,
      angusVerified: false,
      WHP: false,
      WHP_ESI: false,
      BRD: false,
      ERP: false,
      fiveInOne: false,
    },
    createDate: new Date(),
    mobId: '',
    mobName: '',
  };

export const INITIAL_STATE: InitialState = {
  state: {
    // Below are states that help in user inputs but are not sent in API request
    section: 'story',
    progressBarPosition: 0,
    selectedBrand: '',
    focusedTabIndex: 0,
    focusedMobTabIndex: 0,
    mainSectionImageOption: 'image',
    footerImageOption: 'image',
    submitted: '',
    mobList: [],
    brandList: [],
    currentStepError: false,
  },
  payload: {
    status: 'draft',
    type: 'ANIMALS',
    archive: false,
    templateType: 'tellStory',
    name: '',
    brand: {
      agliveToken: '',
    },
    story: {
      logo: null,
      logoType: 'brand',
      image: null,
      headline: ['', '', ''],
      content: '',
      milestones: [],
      additional: [],
    },
    display: {
      footerImage: null,
      startDate: '',
      endDate: '',
    },
    mob: {
      media: {
        item: 'image',
        url: '',
      },
      linkMob: null,
      display: {
        isMob: true,
        isAnimal: true,
      },
      signed: false,
      msa: false,
      attachment: [],
    },
  },
};

export const assetPromotionReducer = (
  state: InitialState,
  action: AssetPromotionActions,
) => {
  switch (action.type) {
    case 'promotion/name':
      return produce(state, (draft) => {
        draft.payload.name = action.payload;
      });
    case 'promotion/brand':
      return produce(state, (draft) => {
        draft.payload.brand = {
          agliveToken: action.payload,
        };
      });
    case 'brand_list':
      return produce(state, (draft) => {
        draft.state.brandList = processBrandList(action.payload);
      });
    case 'navigate':
      return produce(state, (draft) => {
        draft.state.section = action.payload;
        const sectionToValidate =
          action.payload === 'display'
            ? 'mob'
            : action.payload === 'mob'
            ? 'story'
            : '';

        let hasError = false;
        if (sectionToValidate) {
          const [validatedData, error, section, storyTab, mobTab] =
            validateData(
              state.payload,
              state.state.mainSectionImageOption,
              state.state.footerImageOption,
              sectionToValidate,
            );
          // Bring user to the page/tab that has errors
          section && (draft.state.section = section);
          storyTab !== null && (draft.state.focusedTabIndex = storyTab);
          mobTab !== null && (draft.state.focusedMobTabIndex = mobTab);
          hasError = !!error;
        }

        draft.state.currentStepError = hasError;
        if (draft.state.section === 'story') {
          draft.state.progressBarPosition = 0;
        } else if (draft.state.section === 'mob') {
          draft.state.progressBarPosition = 50;
        } else if (draft.state.section === 'display') {
          draft.state.progressBarPosition = 100;
        }
      });
    case 'navigate/next_step':
      return produce(state, (draft) => {
        const [validatedData, error, section, storyTab, mobTab] = validateData(
          state.payload,
          state.state.mainSectionImageOption,
          state.state.footerImageOption,
          state.state.section,
        );
        // Bring user to the tab that has errors
        storyTab !== null && (draft.state.focusedTabIndex = storyTab);
        mobTab !== null && (draft.state.focusedMobTabIndex = mobTab);

        draft.state.currentStepError = !!error;
        if (!!!error) {
          if (state.state.section === 'story') {
            draft.state.section = 'mob';
            draft.state.progressBarPosition = 50;
          } else {
            draft.state.section = 'display';
            draft.state.progressBarPosition = 100;
          }
        }
      });
    case 'navigate/prev_step':
      return produce(state, (draft) => {
        if (state.state.section === 'display') {
          draft.state.section = 'mob';
          draft.state.progressBarPosition = 50;
        } else {
          draft.state.section = 'story';
          draft.state.progressBarPosition = 0;
        }
      });
    case 'promotion/select_brand_index':
      return produce(state, (draft) => {
        draft.state.selectedBrand = action.payload;
      });
    case 'promotion/main_image_option':
      return produce(state, (draft) => {
        draft.state.mainSectionImageOption = action.payload;
      });
    case 'promotion/footer_image_option':
      return produce(state, (draft) => {
        draft.state.footerImageOption = action.payload;
      });
    case 'promotion/old_token':
      return produce(state, (draft) => {
        draft.state.originalAssetPromotion = action.payload;
      });
    case 'submit':
      return produce(state, (draft) => {
        draft.state.submitted = action.payload;
      });
    case 'promotion/get_link_mob_list':
      return produce(state, (draft) => {
        draft.state.mobList = action.payload;
      });
    // Populate state with existing promotion
    case 'promotion/retrieve_token':
      return produce(state, (draft) => {
        draft.payload = action.payload;
      });
    // TYS - Main Section
    case 'TYS/select_tab':
      return produce(state, (draft) => {
        draft.state.focusedTabIndex = action.payload;
      });
    case 'TYS/logo':
      return produce(state, (draft) => {
        draft.payload.story.logo = action.payload;
      });
    case 'TYS/logo_type':
      return produce(state, (draft) => {
        draft.payload.story.logoType = action.payload;
      });
    case 'TYS/image':
      return produce(state, (draft) => {
        draft.payload.story.image = action.payload;
      });
    case 'TYS/headline':
      return produce(state, (draft) => {
        const {headlineIndex, value} = action.payload;
        draft.payload.story.headline[headlineIndex] = value;
      });
    case 'TYS/content':
      return produce(state, (draft) => {
        draft.payload.story.content = action.payload;
      });
    // TYS - Milestones
    case 'TYS/milestones_add_entry':
      return produce(state, (draft) => {
        draft.payload.story.milestones.push({...INITIAL_MILESTONES});
      });
    case 'TYS/milestones_delete_entry':
      return produce(state, (draft) => {
        draft.payload.story.milestones.splice(action.payload, 1);
      });
    case 'TYS/milestones_dup_entry':
      return produce(state, (draft) => {
        draft.payload.story.milestones.splice(
          action.payload,
          0,
          state.payload.story.milestones[action.payload],
        );
      });
    case 'TYS/milestones_title':
      return produce(state, (draft) => {
        const {index, value} = action.payload;
        draft.payload.story.milestones[index].title = value;
      });
    case 'TYS/milestones_details':
      return produce(state, (draft) => {
        const {index, value} = action.payload;
        draft.payload.story.milestones[index].details = value;
      });
    // TYS - Additional Sections
    case 'TYS/additional_add_entry':
      return produce(state, (draft) => {
        draft.payload.story.additional.push({...INITIAL_ADDITIONAL_SECTION});
      });
    case 'TYS/additional_delete_entry':
      return produce(state, (draft) => {
        draft.payload.story.additional.splice(action.payload, 1);
      });
    case 'TYS/additional_dup_entry':
      return produce(state, (draft) => {
        draft.payload.story.additional.splice(
          action.payload,
          0,
          state.payload.story.additional[action.payload],
        );
      });
    case 'TYS/additional_title':
      return produce(state, (draft) => {
        const {index, value} = action.payload;
        draft.payload.story.additional[index].title = value;
      });
    case 'TYS/additional_content':
      return produce(state, (draft) => {
        const {index, value} = action.payload;
        draft.payload.story.additional[index].content = value;
      });
    case 'TYS/additional_media_type':
      return produce(state, (draft) => {
        const {index, value} = action.payload;
        draft.payload.story.additional[index].mediaType = value;
      });
    case 'TYS/additional_url':
      return produce(state, (draft) => {
        const {index, value} = action.payload;
        draft.payload.story.additional[index].url = value;
      });
    // Mob Details
    case 'mob_details/media_item':
      return produce(state, (draft) => {
        draft.payload.mob.media.item = action.payload;
      });
    case 'mob_details/media_url':
      return produce(state, (draft) => {
        draft.payload.mob.media.url = action.payload;
      });
    case 'mob_details/link_mob':
      return produce(state, (draft) => {
        const mob = state.state.mobList.find(
          (mob) => mob.mobId === action.payload,
        );
        if (mob) {
          draft.payload.mob.linkMob = mob;
        } else {
          draft.payload.mob.linkMob = null;
        }
      });
    case 'mob_details/display':
      return produce(state, (draft) => {
        switch (action.payload) {
          case 'animal':
            draft.payload.mob.display.isAnimal = true;
            draft.payload.mob.display.isMob = false;
            break;
          case 'mob':
            draft.payload.mob.display.isAnimal = false;
            draft.payload.mob.display.isMob = true;
            break;
          case 'mob_animal':
            draft.payload.mob.display.isAnimal = true;
            draft.payload.mob.display.isMob = true;
        }
      });
    case 'mob_details/msa':
      return produce(state, (draft) => {
        draft.payload.mob.msa = action.payload;
      });
    case 'mob_details/signed':
      return produce(state, (draft) => {
        draft.payload.mob.signed = action.payload;
      });
    case 'mob_details/declarations':
      return produce(state, (draft) => {
        draft.payload.mob.linkMob.info[action.payload.key] =
          action.payload.value;
      });
    case 'mob_details/edit_attachments':
      return produce(state, (draft) => {
        const {keys, value} = action.payload;
        let ptr = draft.payload.mob;

        for (let i = 0; i < keys.length; i++) {
          if (i !== keys.length - 1) {
            ptr = ptr[keys[i]];
          } else {
            ptr[keys[i]] = value;
          }
        }
      });
    case 'mob_details/add_attachment':
      return produce(state, (draft) => {
        // Make attachment backward compatible with old mob details not having attachment field
        if (!draft.payload.mob.attachment) {
          draft.payload.mob.attachment = [];
        }
        draft.payload.mob.attachment.push(PRODUCT_PROFILE_ATTACHMENT_TEMPLATE);
      });
    case 'mob_details/delete_attachment':
      return produce(state, (draft) => {
        draft.payload.mob.attachment.splice(action.payload.attachmentIndex, 1);
      });
    case 'mob_details/select_tab':
      return produce(state, (draft) => {
        draft.state.focusedMobTabIndex = action.payload;
      });
    // Display Poster
    case 'display/footer_image':
      return produce(state, (draft) => {
        draft.payload.display.footerImage = action.payload;
      });
    case 'display/start_date':
      return produce(state, (draft) => {
        draft.payload.display.startDate = action.payload;
      });
    case 'display/end_date':
      return produce(state, (draft) => {
        draft.payload.display.endDate = action.payload;
      });
    default:
      return state;
  }
};
