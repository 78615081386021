import {Link} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {callAPI} from '../../utils/network';
import {Paper} from '@material-ui/core';
import {
  Grid as GridTable,
  Table,
  TableHeaderRow,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {IntegratedPaging, PagingState, CustomPaging} from '@devexpress/dx-react-grid';
import COLOR from '../../styled/colors';
import CircularProgress from '@material-ui/core/CircularProgress';

export type DashboardDropDownTableProps = {
  columns: Array<{name: string; width: string; title: string}>;
  data;
  payload
  dashboardState
};

const HeaderComponentBase = ({classes, ...props}) => (
  <Table.TableHead {...props} style={{backgroundColor: COLOR.GRAY_SOLID}} />
);

const DashboardTableWidget: React.FC<DashboardDropDownTableProps> = ({
  columns,
  data,
  payload,
  dashboardState
}) => {
  const columnExtensions = columns.map((column) => ({
    // https://devexpress.github.io/devextreme-reactive/react/grid/docs/reference/table/#tablecolumnextension
    columnName: column.name,
    width: column.width,
  }));

  const [totalCount, setTotalCount] = useState(data.totalRows ?? data.length);
  const [pageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowData, setRows] = useState(data.rows ?? data);
  const [firstRender, setFirstRender] = useState(false)
  const [loading, setLoading] = useState(false);

  const transformedRows = rowData.map((row) =>
    row?.link
      ? {
          ...row,
          identifier: (
            <Link
              to={row.link}
              style={{
                color: COLOR.GREENT_TEXT,
                fontSize: 16,
                marginTop: 8,
              }}>
              {row.identifier}
            </Link>
          ),
        }
      : row,
  );

  useEffect(() => {
    const refetch = async () => {
      setLoading(true)
      let request;
      request = payload.source(dashboardState.data, dashboardState.state, currentPage + 1, pageSize);

      if (request) {
        const res = await callAPI(request);
        let transformedData = payload.transformData(
          {[payload.key]: res} || {},
          dashboardState.state,
          currentPage + 1, pageSize
        )
        console.log(transformedData)
        setRows(transformedData.rows)
        setTotalCount(transformedData.totalRows)
      }
      setLoading(false)
    }
    if(firstRender && payload.pagination) {
      refetch()
    } else {
      setFirstRender(true)
    }

  }, [currentPage])

  useEffect(() => {
    setTotalCount(data.totalRows ?? 0)
    setRows(data.rows ?? data)
    setCurrentPage(0)
  }, [data])

  if(payload.pagination) {
    return (
      <Paper style={{ position: 'relative' }}>
        <GridTable columns={columns} rows={transformedRows}>
          <PagingState 
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
          />
          <CustomPaging
            totalCount={totalCount}
          />
          <Table
            headComponent={HeaderComponentBase}
            columnExtensions={columnExtensions}
          />
          <TableHeaderRow
            titleComponent={(props) => (
              <span style={{fontWeight: 'bold'}}>{props.children}</span>
            )}
          />
          <PagingPanel />
        </GridTable>
        {loading && 
          <CircularProgress  style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}/> 
          }
      </Paper>
    );
  } else {
    return (
      <Paper>
        <GridTable columns={columns} rows={transformedRows}>
          <PagingState defaultCurrentPage={0} defaultPageSize={10} />
          <IntegratedPaging />
          <Table
            headComponent={HeaderComponentBase}
            columnExtensions={columnExtensions}
          />
          <TableHeaderRow
            titleComponent={(props) => (
              <span style={{fontWeight: 'bold'}}>{props.children}</span>
            )}
          />
          <PagingPanel />
        </GridTable>
      </Paper>
    );
  };
};

export default DashboardTableWidget;
