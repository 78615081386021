import API from '../../config/api';
import CONSTANT from '../../config/constant';
import {callAPI} from '../../utils/network';
import {WebErrorType} from '../../utils/error';
// Redux
import {AppThunk} from '../types';
import {RawAssetProfile} from '../assetProfile/types';
import {SiteTokenResponse} from '../site/types';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import {CreateAssetDetails, PlantCodeDocumentRequest} from './types';
import {toggleModal} from '../modal/actions';
import {
  bulkCreateTokenRes,
  generateCSV,
  csvInputT,
  PLANT_CSV_HEADER,
} from '../code/actions';

export function fetchDropdownOptions(): AppThunk<
  Promise<Array<RawAssetProfile | SiteTokenResponse>>
> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response: Array<RawAssetProfile | SiteTokenResponse> =
        await callAPI({
          url: API.POST.getTokenbyAddr,
          method: 'POST',
          data: {
            latestDetails: true,
            status: ['exist'],
            type: [CONSTANT.ASSETTYPE.ASSET_PROFILE, CONSTANT.ASSETTYPE.SITE],
            activityType: [],
          },
        });

      return response;
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function generateAssets(
  assetDetails: CreateAssetDetails,
  download: boolean,
  assetProfile: RawAssetProfile,
  successCB: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      // Get user ID from redux
      const userProfileData = getState().user.userProfileData;
      const businessProfileData = getState().user.businessProfileData;

      // The initial payload for generating assets without group
      const createTokenPayload: any = {
        type: CONSTANT.ASSETTYPE.PLANT,
        generateAgliveToken: true,
        generateUniqueAmount: true,
        amount: assetDetails.quantity, // Number of assets to be generated
        details: {
          assetProfile: assetDetails.assetProfile, // The agliveToken of assetProfile
          site: assetDetails.site, // The agliveToken of site
          pic_id: assetDetails.pic_id,
        },
      };

      /* The back-end create token API is updated specifically for this feature which uses
         a specific type of payload and will automatically generates 'x' number of plant tokens */
      const createTokenResponse = await callAPI({
        url: API.POST.createToken,
        method: 'POST',
        data: {
          tokens: [createTokenPayload],
        },
      });

      const generatedIds: bulkCreateTokenRes['data'] = createTokenResponse.data;

      // Call create document API to create a `code` record for keeping track of the tokens generated in this particular request
      await callAPI({
        url: API.POST.postDocument,
        method: 'POST',
        data: {
          userId: userProfileData.externalIds.userId,
          type: 'code',
          details: {
            quantity: assetDetails.quantity,
            used: download,
            codes: generatedIds,
            assetProfile: assetProfile.details,
            pic_id: assetDetails.pic_id,
            source: {
              companyName: businessProfileData.companyName,
              companyNumber: businessProfileData.companyNumber,
              creatorEmail: userProfileData.emailAddress,
            },
          },
        } as PlantCodeDocumentRequest,
      });

      if (download) {
        const csvInput = generatedIds.map(
          (id) =>
            [
              id,
              CONSTANT.ASSETTYPE.PLANT,
              assetProfile.details.assetName,
              assetDetails.pic_id,
              businessProfileData.companyName,
              userProfileData.emailAddress,
            ] as csvInputT,
        );
        const fileName = `${assetProfile.details.assetName}-${Date.now()}.csv`;
        generateCSV(csvInput, fileName, PLANT_CSV_HEADER);
      }

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Saved',
          button: 'Close',
          CTAHandler: successCB,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}
