import React from 'react';

import produce from 'immer';
import DeleteIcon from '@material-ui/icons/Delete';
import {Grid, TextField, Box} from '@material-ui/core';

import {useStyle} from './styles';
import {useAppSelector} from '../../../utils/hooks';
import TextFieldWithLimit from '../../../presentation/TextFieldWithLimit';
import MyButton from '../../../presentation/button';
import {fileUploader} from '../../../store/product/actions';
import COLOR from '../../../styled/colors';
import FieldHeading from '../../../presentation/FieldHeading';
import {Attachment as iAttachment} from '../../../store/assetProfile/types';

interface AttachmentProps {
  validationState: any;
  handleInputChange: (value: any, ...keys: Array<string | number>) => void;
  setValidationState: React.Dispatch<React.SetStateAction<any>>;
  state: {attachment: Array<iAttachment>};
}

const Attachment: React.FC<AttachmentProps> = ({
  validationState,
  handleInputChange,
  setValidationState,
  state,
}) => {
  const userId = useAppSelector((state) => state.auth.wallet);
  const classes = useStyle();
  const uploadFileHandler = async (e, index) => {
    if (e.target.files && e.target.files.length > 0) {
      const targetFile: File = e.target.files[0];
      if (targetFile.size > 10485760) {
        window.alert('Upload file size more than 20mb ！');
      } else {
        const response = await fileUploader(userId, targetFile);
        const url = response[0].data;
        handleInputChange(url, 'attachment', index, 'url');
      }
    }
  };
  const attachmentFileUpload = React.useRef([]);

  const handleClick = (index) => {
    attachmentFileUpload?.current[index]?.click();
  };

  const onDelete = (index) => {
    handleInputChange(
      state.attachment.length > 1
        ? state.attachment.filter((_, j) => index !== j)
        : [{title: '', url: ''}],
      'attachment',
    );
    setValidationState((prevState) =>
      produce(prevState, (draft) => {
        draft.attachment =
          draft.attachment.length > 1
            ? draft.attachment.filter((_, i) => index !== i)
            : draft.attachment;
      }),
    );
  };

  return (
    <>
      {state.attachment.map((attachment, index) => (
        <Grid item key={index}>
          <Grid
            item
            container
            direction="column"
            style={{backgroundColor: COLOR.WHITE}}>
            <Grid item container direction="column">
              <Grid item container justify="space-between">
                <Grid item>
                  <FieldHeading
                    title={`Attachment ${index + 1}`}
                    subtitle="Add a supporting file (pdf, png, doc, etc.)"
                  />
                </Grid>
                <Grid item>
                  <DeleteIcon
                    style={{marginTop: 16, marginRight: 16, cursor: 'pointer'}}
                    onClick={() => {
                      onDelete(index);
                    }}
                  />
                </Grid>
              </Grid>
              <Box p={3} marginBottom={3} className={classes.fieldContainer}>
                <Grid item container direction="column">
                  <TextFieldWithLimit
                    title="Attachment Title"
                    placeholder="e.g. Halal Certificate"
                    content={attachment.title}
                    style={classes.inputField}
                    limit={30}
                    onChange={(value) => {
                      handleInputChange(value, 'attachment', index, 'title');
                    }}
                    error={!!validationState.attachment[index].title}
                    helperText={validationState.attachment[index].title}
                  />
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    spacing={3}
                    style={{marginTop: '10px'}}>
                    <Grid item xs={7}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={decodeURIComponent(attachment.url.split('/').reverse()[0])}
                        placeholder={'png, jpg, or jpeg'}
                        disabled
                        error={!!validationState.attachment[index].url}
                        helperText={validationState.attachment[index].url}
                        FormHelperTextProps={{
                          className: classes.errorHelperText,
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <MyButton
                        text={'Upload File'}
                        variant="outlined"
                        width={120}
                        onClick={() => {handleClick(index)}}
                        buttonClass={`${classes.contentButton} ${classes.uploadBtn}`}
                      />
                    </Grid>
                  </Grid>
                  <input
                    type="file"
                    onChange={(e) => {
                      uploadFileHandler(e, index);
                    }}
                    ref={element => attachmentFileUpload.current[index] = element}
                    style={{height: 0, width: 0}}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ))}

      <Grid item style={{alignSelf: 'flex-start'}}>
        <MyButton
          onClick={() => {
            handleInputChange(
              [...state.attachment, {title: '', url: ''}],
              'attachment',
            );
            setValidationState((prevState) =>
              produce(prevState, (draft) => {
                draft.attachment.push({title: '', url: ''});
              }),
            );
          }}
          variant="outlined"
          text="Add"
          width={250}
          buttonClass={classes.contentButton}
        />
      </Grid>
    </>
  );
};

export default Attachment;
