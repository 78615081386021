import styled from "styled-components";

const Container = styled.footer`
  width: 100%;
  height: 72px;
  background-color: #373935;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function footer() {
  return (
    <Container>
      Copyright &reg; 2020 Aglive International Pty Ltd ACN 605 120 081
    </Container>
  );
}
