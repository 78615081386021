import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import NewiPhone from '../img/NewiPhone.png';
import COLOR from '../styled/colors';

interface iMockupPhone {
  children: JSX.Element;
  backgroundColor?: string;
}

const MockupPhoneNew: React.FC<iMockupPhone> = ({ children, backgroundColor }) => {
  const useStyle = makeStyles((theme) => ({
    newiPhone: {
      backgroundImage: `url(${NewiPhone})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      display: "flex",
      flexDirection: "column",
      width: 360,
      height: 720,
      backgroundColor: backgroundColor ? backgroundColor : "#6D7278",
      borderRadius: "52px",
    },
  }));
  const classes = useStyle();

  return (
    <div className={classes.newiPhone}>
      {children}
    </div>
  );
};

interface iMockupPhoneWithNav {
  children: JSX.Element | JSX.Element[];
  backgroundColor?: string;
  navTitle: string;
}

export const MockupPhoneNewWithNav: React.FC<iMockupPhoneWithNav> = ({ 
  children,
  backgroundColor,
  navTitle,
}) => {
  const useStyles = makeStyles((theme) => ({
    previewContainer: {
      marginTop: 70,
      height: 635,
      overflow: 'hidden',
      overflowY: 'scroll',
    },
    headerNav: {
      color: COLOR.WHITE,
      marginLeft: `calc(15% - ${theme.spacing(2)}px)`,
    },
    sectionContainer: {
      backgroundColor: 'white',
      borderRadius: '15px 15px 5px 5px',
      overflow: 'hidden',
      overflowY: 'scroll',
      height: 600,
      width: '80%',
      marginLeft: '10%',
      marginTop: theme.spacing(1),
    },
  }));

  const classes = useStyles();
  
  return (
    <MockupPhoneNew backgroundColor={backgroundColor}>
      <div className={classes.previewContainer}>
        <Grid container alignItems="center" className={classes.headerNav}>
          <ArrowBackIosIcon style={{fontSize: '16px', marginRight: '10px'}} />
          <Typography variant="h5">{navTitle}</Typography>
        </Grid>
        <div className={classes.sectionContainer}>
          {children}
        </div>
      </div>
    </MockupPhoneNew>
  );
};

export default MockupPhoneNew;
