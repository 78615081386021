import {Grid, Typography} from '@material-ui/core';
import {useStyles} from './styles';

const RowDetail = ({row}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.rowDetailsContainer}>
      <Grid item xs={6} className={classes.tokenDetailsContainer}>
        <Typography variant="h4">Token Details</Typography>
        <pre>{JSON.stringify(row.details, null, 2)}</pre>
      </Grid>
      <Grid item xs={6} className={classes.activityDetailsContainer}>
        <Typography variant="h4">Last Activity Details</Typography>
        {row.latestActivity ? (
          <pre>{JSON.stringify(row.latestActivity, null, 2)}</pre>
        ) : (
          <Typography variant="body2" className={classes.noActivityText}>
            This is a newly created token and does not have any activities.
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default RowDetail;
