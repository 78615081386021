import produce from 'immer';
import _ from 'lodash';
import { WebError } from '../../../../../utils/error';

import { INITIAL_ADDITIONAL_SECTION, INITIAL_MILESTONES } from '../../../CreateAssetPromotion/assetPromotionState/reducer';

import { isFormValid, sanitizeForm } from './actions';
import { getBrandAgliveTokenId } from '../../../../brand/utils';

import { DetailsTabMap } from '../../CreateProductProfileDetails';
import { TellYourStoryActions as TellYourStoryActionTypes } from '../../../TellYourStory/tellYourStoryState/types';
import { navigationState, LocalState, productProfileActions } from './types';
import { TokenService } from '@aglive/data-model';
import { TabMap } from '../../../TellYourStory/TellYourStory';
import ReactPlayer from 'react-player';

const PRODUCT_PROFILE_DISPLAY_TEMPLATE = {
  item: 'image' as TokenService.ProductToken["details"]["pages"][number]["display"]["item"],
  url: '',
};

const PRODUCT_PROFILE_CONTENT_TEMPLATE = {
  subheading: '',
  content: '',
};

export const PRODUCT_PROFILE_ATTACHMENT_TEMPLATE = {
  title: '',
  url: ''
};

export const PRODUCT_PROFILE_DETAILS_TEMPLATE: TokenService.ProductToken["details"]["pages"][number] = {
  display: PRODUCT_PROFILE_DISPLAY_TEMPLATE,
  headline: ['', '', ''],
  content: [],
  attachment: [],
};

export const DETAILS_VALIDATION_TEMPLATE: Pick<TokenService.ProductToken["details"]["pages"][number], 'display'> = {
  display: PRODUCT_PROFILE_DISPLAY_TEMPLATE
};

export const INITIAL_CREATE_PROFILE_STATE = {
  // control the ui
  state: {
    section: 'story',
    progressBarPosition: 0,
    story: {
      mainSectionImageOption: 'image',
      focusedTabIndex: TabMap.mainSection,
    },
    pages: {
      focusedPageIndex: 0,
      focusedTabIndex: DetailsTabMap.display,
    },
    canSubmit: false,
    status: 'draft', // status will only be changed by onSubmit
    brandList: [],
  } as LocalState,
  // control the payload to be registered
  payload: {
    archive: false,
    templateType: 'tellStory',
    name: '',
    brand: {
      agliveToken: '',
    },
    // TYS
    story: {
      logo: null,
      logoType: 'user',
      image: null,
      headline: ['', '', ''],
      content: '',
      milestones: [],
      additional: [],
    },
    // Product Details
    pages: [PRODUCT_PROFILE_DETAILS_TEMPLATE],
  } as TokenService.ProductToken["details"],
  validation: {
    name: '',
    brand: '',
    story: {
      logo: '',
      image: '',
      headline: ['', '', ''],
      content: '',
      additional: [],
    },
    pages: [DETAILS_VALIDATION_TEMPLATE],
  },
};

const INITIAL_NEXT_PAGE: navigationState = {
  section: null,
  progressBarPosition: null,
  story: {
    focusedTabIndex: null
  },
  pages: {
    focusedPageIndex: null,
    focusedTabIndex: null
  }
};

const productProfileReducer = <T>(
  state:  T & typeof INITIAL_CREATE_PROFILE_STATE,
  action: productProfileActions | TellYourStoryActionTypes) => {
  switch (action.type) {
    case 'input/name':
      return produce(state, draft => {
        draft.payload.name = action.payload;
      });
    case 'select/brand':
      return produce(state, draft => {
        if (!action.payload.externalIds.find(id => 'agliveToken' in id)) {
          throw new WebError('INVALID_ERROR');
        }

        draft.payload.brand = {
          agliveToken: getBrandAgliveTokenId(action.payload)
        };
      });
    case 'navigate':
      return produce(state, draft => {
        // TYS form must be valid before proceeding to next step
        sanitizeForm(draft.validation.story);
        const nextPage = _.cloneDeep(INITIAL_NEXT_PAGE);
        validateTellYourStory(state.payload.story, draft.validation, nextPage, state.state);

        if (isFormValid(draft.validation.story)) {
          navigateTo(draft.state, action.payload);
          if (action.payload === 'pages') {
            draft.state.canSubmit = true;
          }
        } else {
          navigateToErrorTab(draft.state, nextPage);
        }
      });
    case 'navigate/next_step':
      return produce(state, draft => {
        // TYS form must be valid before proceeding to next step
        sanitizeForm(draft.validation.story);
        const nextPage = _.cloneDeep(INITIAL_NEXT_PAGE);
        validateTellYourStory(state.payload.story, draft.validation, nextPage, state.state);

        if (isFormValid(draft.validation.story)) {
          navigateTo(draft.state, 'pages');
          draft.state.canSubmit = true;
        } else {
          navigateToErrorTab(draft.state, nextPage);
        }
      });
    case 'navigate/prev_step':
      return produce(state, draft => {
        navigateTo(draft.state, 'story');
      });
    case 'brand_list':
      return produce(state, draft => {
        draft.state.brandList = action.payload;
      });
    // TYS operations
    case 'TYS/select_tab':
      return produce(state, draft => {
        draft.state.story.focusedTabIndex = action.payload;
      });
    case 'TYS/logo':
      return produce(state, draft => {
        draft.payload.story.logo = action.payload;
      });
    case 'TYS/logo_type':
      return produce(state, draft => {
        draft.payload.story.logoType = action.payload;
      });
    case 'TYS/image_option':
      return produce(state, draft => {
        draft.state.story.mainSectionImageOption = action.payload
      })
    case 'TYS/image':
      return produce(state, draft => {
        draft.payload.story.image = action.payload;
      });
    case 'TYS/headline':
      return produce(state, draft => {
        draft.payload.story.headline[action.payload.headlineIndex] = action.payload.value;
      });
    case 'TYS/content':
      return produce(state, draft => {
        draft.payload.story.content = action.payload;
      });
    case 'TYS/milestones_add_entry':
      return produce(state, draft => {
        draft.payload.story.milestones.push(INITIAL_MILESTONES);
      });
    case 'TYS/milestones_delete_entry':
      return produce(state, draft => {
        draft.payload.story.milestones.splice(action.payload, 1);
      });
    case 'TYS/milestones_dup_entry':
      return produce(state, draft => {
        draft.payload.story.milestones.splice(
          action.payload,
          0,
          state.payload.story.milestones[action.payload],
        );
      });
    case 'TYS/milestones_title':
      return produce(state, draft => {
        const {index, value} = action.payload;
        draft.payload.story.milestones[index].title = value;
      });
    case 'TYS/milestones_details':
      return produce(state, draft => {
        const {index, value} = action.payload;
        draft.payload.story.milestones[index].details = value;
      });
    case 'TYS/additional_add_entry':
      return produce(state, draft => {
        draft.payload.story.additional.push(INITIAL_ADDITIONAL_SECTION);
        draft.validation.story.additional.push(INITIAL_ADDITIONAL_SECTION);
      });
    case 'TYS/additional_delete_entry':
      return produce(state, draft => {
        draft.payload.story.additional.splice(action.payload, 1);
        draft.validation.story.additional.splice(action.payload, 1);
      });
    case 'TYS/additional_dup_entry':
      return produce(state,draft => {
        draft.payload.story.additional.splice(
          action.payload,
          0,
          state.payload.story.additional[action.payload],
        );

        draft.validation.story.additional.splice(
          action.payload,
          0,
          INITIAL_ADDITIONAL_SECTION,
        );
      });
    case 'TYS/additional_title':
      return produce(state, draft => {
        const {index, value} = action.payload;
        draft.payload.story.additional[index].title = value;
      });
    case 'TYS/additional_content':
      return produce(state, draft => {
        const {index, value} = action.payload;
        draft.payload.story.additional[index].content = value;
      });
    case 'TYS/additional_media_type':
      return produce(state, draft => {
        const {index, value} = action.payload;
        draft.payload.story.additional[index].mediaType = value;
      });
    case 'TYS/additional_url':
      return produce(state, draft => {
        const {index, value} = action.payload;
        draft.payload.story.additional[index].url = value;
      });
    
    // details operations
    case 'details/select_page':
      return produce(state, draft => {
        draft.state.pages.focusedPageIndex = action.payload;
      });
    case 'details/add_page':
      return produce(state, draft => {
        draft.payload.pages.push(PRODUCT_PROFILE_DETAILS_TEMPLATE);
        draft.state.pages.focusedPageIndex = draft.payload.pages.length - 1;

        draft.validation.pages.push(DETAILS_VALIDATION_TEMPLATE);
      });
    case 'details/duplicate_page':
      return produce(state, draft => {
        draft.payload.pages.splice(action.payload, 0, state.payload.pages[action.payload]);
        draft.state.pages.focusedPageIndex = action.payload + 1;

        draft.validation.pages.splice(action.payload, 0, state.validation.pages[action.payload]);
      });
    case 'details/delete_page':
      return produce(state, draft => {
        // disable delete if there is only one
        if (state.payload.pages.length !== 1) {
          // if the last item is deleted, the app will attempt to access index outside array
          // therefore the index is shifted backwards
          if (state.payload.pages.length - 1 === action.payload) {
            draft.state.pages.focusedPageIndex = draft.state.pages.focusedPageIndex - 1;
          }

          draft.payload.pages.splice(action.payload, 1);
          draft.validation.pages.splice(action.payload, 1);
        }
      });
    case 'details/select_tab':
      return produce(state, draft => {
        draft.state.pages.focusedTabIndex = action.payload;
      });
    // display/content/attachments
    case 'details/select_media':
      return produce(state, draft => {
        draft.payload.pages[action.payload.pageIndex].display.item = action.payload.key;
        draft.payload.pages[action.payload.pageIndex].display.url = action.payload.value;
        draft.validation.pages[action.payload.pageIndex].display.item = action.payload.key;

        // clear warnings if changed options
        if (state.payload.pages[action.payload.pageIndex].display.item !== action.payload.key) {
          draft.validation.pages[action.payload.pageIndex].display.url = '';
        }
      });
    case 'details/page_input':
      return produce(state, draft => {
        // passing a key array for the reducer to navigate to the target property to update
        // if any additional changes required, please add a new case to handle
        const {
          pageIndex,
          keys,
          value
        } = action.payload;
      
        let ptr = draft.payload.pages[pageIndex];

        for (let i = 0; i < keys.length; i++) {
          if (i !== keys.length - 1) {
            ptr = ptr[keys[i]];
          } else {
            ptr[keys[i]] = value;
          }
        }
      });
    case 'content/add_subheading':
      return produce(state, draft => {
        draft.payload.pages[action.payload].content.push(PRODUCT_PROFILE_CONTENT_TEMPLATE);
      });
    case 'content/delete_subheading':
      return produce(state, draft => {
        draft.payload.pages[action.payload.pageIndex].content.splice(action.payload.subheadingIndex, 1);
      });
    case 'content/dup_subheading':
      return produce(state, draft => {
        const { subheadingIndex, pageIndex } = action.payload;

        draft.payload.pages[pageIndex].content.splice(
          subheadingIndex,
          0,
          state.payload.pages[pageIndex].content[subheadingIndex]
        );
      });
    case 'attachment/add_entry':
      return produce(state, draft => {
        draft.payload.pages[action.payload].attachment.push(PRODUCT_PROFILE_ATTACHMENT_TEMPLATE);
      });
    case 'attachment/delete_entry':
      return produce(state, draft => {
        draft.payload.pages[action.payload.pageIndex].attachment.splice(action.payload.subheadingIndex, 1);
      });

    case 'submit/validate_form':
      return produce(state, draft => {
        const {
          name,
          brand,
          story,
          pages,
        } = state.payload;

        const nextPage: navigationState = _.cloneDeep(INITIAL_NEXT_PAGE);

        // sanitize validation state if re-submit
        sanitizeForm(draft.validation);

        // metdata validation
        if (!name.length) {
          draft.validation.name = 'This field is required';

          setNextPage({
            section: 'story',
            pages: {
              focusedTabIndex: 0,
              focusedPageIndex: 0
            }
          },
          nextPage);
        }

        if (!brand.agliveToken.length) {
          draft.validation.brand = 'This field is required';

          setNextPage({
            section: 'story',
            pages: {
              focusedTabIndex: 0,
              focusedPageIndex: 0
            }
          },
          nextPage);
        }

        // TYS validation
        validateTellYourStory(story, draft.validation, nextPage, state.state);

        // details validation
        pages.forEach((page, index) => {
          if (!page.display.url.length) {
            draft.validation.pages[index].display.item = state.validation.pages[index].display.item;
            draft.validation.pages[index].display.url = 'This field is required';

            setNextPage({
              section: 'pages',
              pages: {
                focusedTabIndex: DetailsTabMap.display,
                focusedPageIndex: index
              }
            },
            nextPage);
          } else if (state.validation.pages[index].display.item === 'video' && !ReactPlayer.canPlay(page.display.url)) {
            draft.validation.pages[index].display.item = state.validation.pages[index].display.item;
            draft.validation.pages[index].display.url = 'Please enter a valid URL';

            setNextPage({
              section: 'pages',
              pages: {
                focusedTabIndex: DetailsTabMap.display,
                focusedPageIndex: index
              }
            },
            nextPage);
          }
        });

        // if all tests passed, allow form submission
        if (isFormValid(draft.validation)) {
          draft.state.status = 'submit';
        } else {
          navigateToErrorTab(draft.state, nextPage);
        }
      });
    default: 
      return state;
  }
}

export default productProfileReducer;

export function navigateTo(
  navigationState: navigationState,
  nextSection: typeof INITIAL_CREATE_PROFILE_STATE.state.section
  ) {
  navigationState.section = nextSection;

  if (nextSection === 'story') {
    navigationState.progressBarPosition = 0;
  } else if (nextSection === 'pages') {
    navigationState.progressBarPosition = 100;
  }
}

const navigateToErrorTab = (state: LocalState, nextPage: navigationState) => {
  state.section = nextPage.section;
  state.progressBarPosition = nextPage.progressBarPosition;

  if (nextPage.story.focusedTabIndex !== null) {
    state.story.focusedTabIndex = nextPage.story.focusedTabIndex;
  }
  
  if (Object.values(nextPage.pages).every(item => item !== null)) {
    state.pages = nextPage.pages;
  }
}

// This function will only accept first assignment to nextSection
const setNextPage = (nextSection: Partial<navigationState>, nextPage: navigationState) => {
  if (!nextPage.section) {
    navigateTo(nextPage, nextSection.section);

    if ('story' in nextSection && nextPage.story.focusedTabIndex === null) {
      nextPage.story.focusedTabIndex = nextSection.story.focusedTabIndex;
    }

    if ('pages' in nextSection && Object.values(nextPage.pages).every(item => item === null)) {
      nextPage.pages = nextSection.pages;
    }
  }
}

const validateTellYourStory = (
  story: TokenService.ProductToken['details']['story'],
  validation: typeof INITIAL_CREATE_PROFILE_STATE.validation,
  nextPage: navigationState,
  state: LocalState, 
) => {
  // story validation
  if (story.logoType === 'user' && !story.logo?.length) {
    validation.story.logo = 'This field is required';
    setNextPage(
      {
        section: 'story',
        story: {
          focusedTabIndex: TabMap.mainSection,
        },
      },
      nextPage,
    );
  }

  if (
    state.story.mainSectionImageOption === 'image' &&
    !story.image?.length
  ) {
    validation.story.image = 'This field is required';
    setNextPage(
      {
        section: 'story',
        story: {
          focusedTabIndex: TabMap.mainSection,
        },
      },
      nextPage,
    );
  }

  // no milestone validation
  // additional section validation
  story.additional.forEach((section, index) => {
    if (section.mediaType.length && !section.url?.length) {
      validation.story.additional[index].url = 'This field is required';
      setNextPage(
        {
          section: 'story',
          story: {
            focusedTabIndex: TabMap.additionalSection,
          },
        },
        nextPage,
      );
    } else if (section.mediaType === 'video' && !ReactPlayer.canPlay(section.url)) {
      validation.story.additional[index].url = 'Please enter a valid URL';
      setNextPage(
        {
          section: 'story',
          story: {
            focusedTabIndex: TabMap.additionalSection,
          },
        },
        nextPage,
      );
    }
  });
};