import React from 'react';
import {PieChart, Legend, Pie, Cell} from 'recharts';
import {Grid, Typography} from '@material-ui/core';

import {CustomizedLegend, LegendPayloadType} from './DashboardChart';
import {MainHeading, WidgetContainer} from './styles';
import COLOR from '../../styled/colors';

const COLORS = ['#417521', '#9DCC5F', '#88B481'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  value,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return value ? (
    <>
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{
          fontFamily: 'sans-serif',
          fontWeight: 700,
        }}>
        {value}
      </text>
      {/*<text
        x={x}
        y={y + 10}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{
          fontFamily: 'sans-serif',
          fontWeight: 700,
        }}>
        {`${(percent * 100).toFixed(0)}%`}
      </text>*/}
    </>
  ) : (
    <></>
  );
};

export type DashboardChartPieProps = {
  data: Array<{value: number}>;
  legend?: LegendPayloadType;
};

export const DashboardChartPie: React.FC<DashboardChartPieProps> = ({
  data,
  legend,
}) => {
  if (data.length === 0) {
    return <Grid item container style={{backgroundColor: COLOR.GRAY_SOLID, padding:30, marginTop: 100, marginBottom: 100}}>
      <Typography>No data to display</Typography>
    </Grid>
  }
  const sum = data.reduce((prev, dt) => prev + dt.value, 0);
  return (
    <PieChart width={500} height={260}>
      <Pie
        data={data}
        cx={120}
        cy={120}
        isAnimationActive={false}
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={125}
        fill="#8884d8"
        dataKey="value"
        style={{
          textAlign: 'center',
        }}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index]} />
        ))}
      </Pie>
      <Legend
        layout="vertical"
        align="right"
        verticalAlign="middle"
        iconType="circle"
        content={<CustomizedLegend title={legend?.title || ''} showValue={data.map((entry) => (` (${Math.round(entry.value / sum * 100)}%)`))} />}
      />
    </PieChart>
  );
};

type DashboardChartPieWidgetProps = {
  title: string;
  children?: any;
} & DashboardChartPieProps;

const DashboardChartPieWidget: React.FC<DashboardChartPieWidgetProps> = ({
  title,
  data,
  children,
  ...props
}) => {
  let hasData = false;
  data.forEach((dt) => {
    hasData = hasData || dt.value > 0
  });
  return (
    <WidgetContainer item container direction="row" style={{padding: 30}}>
      <Grid style={{width: '38%'}}>
        <MainHeading>{title}</MainHeading>
      </Grid>
      <Grid>
        <DashboardChartPie data={hasData ? data : []} {...props} />
        {children}
      </Grid>
    </WidgetContainer>
  );
};

export default DashboardChartPieWidget;
