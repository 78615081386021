import { FETCH_SITE_TOKENS, siteState, DROP_SITE_TOKENS } from './types';

const initialState: siteState = [];

export default function siteReducer(
  state = initialState,
  action,
): siteState {
  switch(action.type) {
    case FETCH_SITE_TOKENS:
      return action.payload;
    case DROP_SITE_TOKENS:
      return initialState;
    default:
      return state;
  }
}