import React, {useRef} from 'react';

import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import {TokenService} from '@aglive/data-model';

import {useStyles} from './styles';
import FieldHeading from '../../../presentation/FieldHeading';
import TextFieldWithLimit from '../../../presentation/TextFieldWithLimit';
import MyButton from '../../../presentation/button';
import {fileUploader} from '../../../store/product/actions';
import {useAppSelector} from '../../../utils/hooks';
import {RecursivePartial} from '../../../utils/type';

interface MainSectionProps {
  story: TokenService.ProductToken['details']['story'];
  mainSectionImageOption: 'image' | '';
  validationState: RecursivePartial<TokenService.ProductToken['details']['story']>;
  onMainSectionImageOptionChange: (value: 'image' | '') => void;
  onLogoChange: (value: string) => void;
  onLogoTypeChange: (
    value: TokenService.ProductToken['details']['story']['logoType'],
  ) => void;
  onMainSectionImageChange: (value: string) => void;
  onHeadlineChange: (headlineIndex: number, value: string) => void;
  onContentChange: (value: string) => void;
}

const MainSection: React.FC<MainSectionProps> = ({
  story,
  mainSectionImageOption,
  validationState,
  onMainSectionImageOptionChange,
  onLogoChange,
  onLogoTypeChange,
  onMainSectionImageChange,
  onHeadlineChange,
  onContentChange,
}) => {
  const classes = useStyles();
  const userId = useAppSelector((state) => state.auth.wallet);

  const logoRef = useRef(null);
  const mainSectionImageRef = useRef(null);

  // Handle image uploads
  const uploadImageHandler = async (
    e,
    imageDispatch: (value: string) => void,
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10485760) {
        window.alert('Upload image size more than 10mb ！');
      } else {
        const response = await fileUploader(userId, e.target.files[0]);
        const responseData = response[0].data;
        if (typeof responseData == 'string') {
          imageDispatch(responseData);
        }
      }
    }
  };

  return (
    <Box p={3} className={classes.tabContainer}>
      <FieldHeading title="Logo" subtitle="Upload your brand logo" />
      <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
        <RadioGroup
          aria-label="logoType"
          name="logoType"
          value={story.logoType || ''}
          onChange={(e) => {
            onLogoTypeChange(e.target.value as 'brand' | 'user');
            onLogoChange(null);
            logoRef.current.value = '';
          }}>
          <FormControlLabel
            value="brand"
            control={<Radio color="primary" />}
            label="Use Brand Logo"
          />
          <FormControlLabel
            value="user"
            control={<Radio color="primary" />}
            label="Upload Logo"
          />
          <Grid
            container
            alignItems="flex-start"
            justifyContent="center"
            spacing={3}
            style={{
              paddingLeft: '35px',
            }}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                variant="outlined"
                value={
                  story.logoType === 'user' && story.logo
                    ? decodeURIComponent(story.logo.split('/').reverse()[0])
                    : ''
                }
                placeholder={'png, jpg, or jpeg'}
                disabled
                error={!!validationState.logo && story.logoType === 'user'}
                helperText={story.logoType === 'user' && validationState.logo}
                FormHelperTextProps={{
                  className: classes.errorHelperText,
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <MyButton
                text={'Upload File'}
                variant="outlined"
                onClick={() => {
                  logoRef.current.click();
                }}
                buttonClass={`${classes.contentButton} ${classes.uploadBtn}`}
                disabled={story.logoType !== 'user'}
              />
              <input
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                onChange={(e) => {
                  uploadImageHandler(e, onLogoChange);
                }}
                ref={logoRef}
                style={{height: 0, width: 0}}
              />
            </Grid>
          </Grid>
          <FormControlLabel
            value=""
            control={<Radio color="primary" />}
            label="No Logo"
          />
        </RadioGroup>
      </Box>

      <FieldHeading title="Image" subtitle="Upload a compelling image" />
      <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
        <RadioGroup
          aria-label="mainSectionImage"
          name="mainSectionImage"
          value={mainSectionImageOption}
          onChange={(e) => {
            onMainSectionImageOptionChange(e.target.value as 'image' | '');
            onMainSectionImageChange(null);
            mainSectionImageRef.current.value = '';
          }}>
          <FormControlLabel
            value="image"
            control={<Radio color="primary" />}
            label="Upload Image"
          />
          <Grid
            container
            alignItems="flex-start"
            justifyContent="center"
            spacing={3}
            style={{
              paddingLeft: '35px',
              marginBottom: '5px',
            }}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                variant="outlined"
                value={
                  story.image
                    ? decodeURIComponent(story.image.split('/').reverse()[0])
                    : ''
                }
                placeholder={'png, jpg, or jpeg'}
                disabled
                error={
                  !!validationState.image && mainSectionImageOption === 'image'
                }
                helperText={
                  mainSectionImageOption === 'image' && validationState.image
                }
                FormHelperTextProps={{
                  className: classes.errorHelperText,
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <MyButton
                text={'Upload File'}
                variant="outlined"
                onClick={() => {
                  mainSectionImageRef.current.click();
                }}
                buttonClass={`${classes.contentButton} ${classes.uploadBtn}`}
                disabled={mainSectionImageOption !== 'image'}
              />
              <input
                type="file"
                accept="image/jpg, image/jpeg, image/png"
                onChange={(e) => {
                  uploadImageHandler(e, onMainSectionImageChange);
                }}
                ref={mainSectionImageRef}
                style={{height: 0, width: 0}}
              />
            </Grid>
          </Grid>
          <FormControlLabel
            value=""
            control={<Radio color="primary" />}
            label="No Image"
          />
        </RadioGroup>
      </Box>

      <FieldHeading
        title="Headline"
        subtitle="Capture your audience's attention"
      />
      <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
        <Grid item container direction="column">
          <TextFieldWithLimit
            title="Line 1"
            content={story.headline[0]}
            limit={30}
            style={classes.inputField}
            placeholder="Enter text"
            onChange={(value: string) => {
              onHeadlineChange(0, value);
            }}
            error={!!validationState.headline[0]}
            helperText={validationState.headline[0]}
          />
          <TextFieldWithLimit
            title="Line 2"
            content={story.headline[1]}
            limit={30}
            style={classes.inputField}
            placeholder="Enter text"
            onChange={(value: string) => {
              onHeadlineChange(1, value);
            }}
            error={!!validationState.headline[1]}
            helperText={validationState.headline[1]}
          />
          <TextFieldWithLimit
            title="Line 3"
            content={story.headline[2]}
            limit={30}
            style={classes.inputField}
            placeholder="Enter text"
            onChange={(value: string) => {
              onHeadlineChange(2, value);
            }}
            error={!!validationState.headline[2]}
            helperText={validationState.headline[2]}
          />
        </Grid>
      </Box>

      <FieldHeading title="Content" subtitle="Tell your story to boost sales" />
      <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
        <Grid item container direction="column">
          <TextFieldWithLimit
            title=""
            content={story.content}
            limit={600}
            style={classes.inputField}
            placeholder="Add content here"
            onChange={(value: string) => {
              onContentChange(value);
            }}
            error={!!validationState.content}
            helperText={validationState.content}
            multiline={true}
            rows={10}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default MainSection;
