import { TokenService } from '@aglive/data-model';

import API from '../../../config/api';
import CONSTANT from '../../../config/constant';
import { callAPI } from '../../../utils/network';

export const fetchProductProfileTokens = async (
  productProfileId?: Array<{agliveToken: string}>
) => {
  let payload: any = {
    latestDetails: true,
    status: [],
    activityType: [],
    type: [CONSTANT.ASSETTYPE.PRODUCT]
  };

  if (productProfileId) {
    payload.externalIds = productProfileId;
  }
  const response = await callAPI<Array<TokenService.ProductToken>>({
    url: productProfileId ? API.POST.getTokenbyExternalId : API.POST.getTokenbyAddr,
    method: 'POST',
    data: payload
  });

  return response;
};

export const archiveProductProfile = async (productProfile: TokenService.ProductToken) => {
  const response = await callAPI({
    url: API.POST.createActivity,
    method: 'POST',
    data: {
      tokens: [
        {
          type: CONSTANT.ASSETTYPE.PRODUCT,
          externalIds: {
            agliveToken: productProfile.externalIds[0].agliveToken,
          },
          activities: [
            {
              type: 'UP_details',
              details: {
                archive: true
              },
            },
          ],
        },
      ]
    }
  });

  return response;
};
