import {TokenService} from '@aglive/data-model';
import {
  NavigateAction,
  NavigateStepAction,
  PromotionBrandAction,
  PromotionNameAction,
  PromotionRetrieveAction,
  SelectedBrandIndexAction,
  MainSectionImageOptionAction,
  FooterImageOptionAction,
  OldPromotionAction,
  SubmitAction,
  BrandListAction,
  GetLinkMobListAction,
} from './types';
import TellYourStoryActions from '../../TellYourStory/tellYourStoryState/actions';
import MobDetailsActions from '../../MobDetails/mobDetailsState/actions';
import DisplayActions from '../../DisplayPoster/displayPosterState/actions';

const editName = (value: string): PromotionNameAction => ({
  type: 'promotion/name',
  payload: value,
});

const editBrand = (value: string): PromotionBrandAction => ({
  type: 'promotion/brand',
  payload: value,
});

export const getBrandList = (
  brandTokens: TokenService.BrandToken[],
): BrandListAction => ({
  type: 'brand_list',
  payload: brandTokens,
});

const navigate = (value: 'story' | 'mob' | 'display'): NavigateAction => ({
  type: 'navigate',
  payload: value,
});

const navigateNextStep = (): NavigateStepAction => ({
  type: 'navigate/next_step',
});

const navigatePreviousStep = (): NavigateStepAction => ({
  type: 'navigate/prev_step',
});

const retrieveAssetPromotion = (
  assetPromotion: TokenService.AnimalPromotion['details'],
): PromotionRetrieveAction => ({
  type: 'promotion/retrieve_token',
  payload: assetPromotion,
});

const selectBrandIndex = (
  value: number | string,
): SelectedBrandIndexAction => ({
  type: 'promotion/select_brand_index',
  payload: value,
});

const editMainSectionImageOption = (
  value: 'image' | '',
): MainSectionImageOptionAction => ({
  type: 'promotion/main_image_option',
  payload: value,
});

const editFooterImageOption = (
  value: 'image' | '',
): FooterImageOptionAction => ({
  type: 'promotion/footer_image_option',
  payload: value,
});

const storeOldPromotion = (
  value: TokenService.AnimalPromotion['details'],
): OldPromotionAction => ({
  type: 'promotion/old_token',
  payload: value,
});

const setSubmitFlag = (value: '' | 'draft' | 'publish'): SubmitAction => ({
  type: 'submit',
  payload: value,
});

const getLinkMobList = (
  mobList: TokenService.AnimalPromotion['details']['mob']['linkMob'][],
): GetLinkMobListAction => ({
  type: 'promotion/get_link_mob_list',
  payload: mobList,
});

export default {
  editName,
  editBrand,
  getBrandList,
  navigate,
  navigateNextStep,
  navigatePreviousStep,
  retrieveAssetPromotion,
  selectBrandIndex,
  editMainSectionImageOption,
  editFooterImageOption,
  storeOldPromotion,
  setSubmitFlag,
  getLinkMobList,
  ...TellYourStoryActions,
  ...MobDetailsActions,
  ...DisplayActions,
};
