import API from '../../config/api';
import {AppThunk} from '../types';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import {toggleModal} from '../modal/actions';
import {callAPI} from '../../utils/network';
import {stripUrlQueryAndFragment} from '@sentry/utils';
import {NetworkError, WebErrorType} from '../../utils/error';
import {AUTH_TOKEN} from '../../env';
import {nlisDetails, sireUpdateDetails, animalUpdateDetails} from './types';

export function getAnimalProfile(
  externalIds: any,
  backFunction?: () => void,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response = await callAPI({
        url: API.POST.getTokenbyExternalId,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          type: ['angusCalve'],
          activityType: [],
          externalIds: Array.isArray(externalIds) ? externalIds : [externalIds],
        },
      });

      return Promise.resolve(response);
    } catch (e) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: e.title ?? 'Unexpected Error',
          subtitle: e.message ?? 'Unexpected Error',
          CTAHandler: backFunction ?? null,
        }),
      );
      return Promise.reject(new NetworkError(e));
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function getSireProfile(
  externalIds: any,
  authToken: string,
  userId: string,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response = await callAPI({
        url: API.POST.getTokenbyExternalId,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          type: ['angusSire'],
          activityType: [],
          externalIds: [externalIds],
          angusAuthToken: authToken,
          userId,
        },
      });

      return Promise.resolve(response);
    } catch (e) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: e.title ?? 'Unexpected Error',
          subtitle: e.message ?? 'Unexpected Error',
        }),
      );
      return Promise.reject(new NetworkError(e));
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function getMobs(): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const data = {
        latestDetails: true,
        status: ['exist'],
        type: ['angusMob'],
        activityType: [],
      };
      const response = await callAPI({
        url: API.POST.getTokenbyAddr,
        method: 'POST',
        data: data,
      });
      return Promise.resolve(response);
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title ?? 'Unexpected Error',
          subtitle: error.message ?? 'Unexpected Error',
        }),
      );
      return Promise.reject(new NetworkError(error));
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}
export function getMobsByBusiness(
  userId: string,
  businessId: string,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const data = {
        latestDetails: true,
        status: ['exist'],
        type: ['angusMob'],
        activityType: [],
        businessId: [businessId],
      };
      const response = callAPI({
        url: API.POST.getTokenByBusiness,
        method: 'POST',
        data: data,
      });
      return Promise.resolve(response);
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title ?? 'Unexpected Error',
          subtitle: error.message ?? 'Unexpected Error',
        }),
      );
      return Promise.reject(new NetworkError(error));
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function getSires(userId: string): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const data = {
        latestDetails: true,
        status: ['exist'],
        type: ['angusSire'],
        activityType: [],
      };
      const response = callAPI({
        url: API.POST.getTokenbyAddr,
        method: 'POST',
        data: data,
      });
      return Promise.resolve(response);
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title ?? 'Unexpected Error',
          subtitle: error.message ?? 'Unexpected Error',
        }),
      );
      return Promise.reject(new NetworkError(error));
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function getSireGroups(userId: string): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const data = {
        latestDetails: true,
        status: ['exist'],
        type: ['angusSireGroup'],
        activityType: [],
      };
      const response = callAPI({
        url: API.POST.getTokenbyAddr,
        method: 'POST',
        data: data,
      });
      return Promise.resolve(response);
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title ?? 'Unexpected Error',
          subtitle: error.message ?? 'Unexpected Error',
        }),
      );
      return Promise.reject(new NetworkError(error));
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function deleteSireGroup(
  userId: string,
  agliveToken: string,
  angusAuthToken: string,
  successCB = () => {},
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const data = {
        tokens: [
          {
            type: 'angusSireGroup',
            externalIds: {
              agliveToken: agliveToken,
            },
            activities: [
              {
                type: 'DEL_token',
                details: {
                  angusAuthToken: angusAuthToken,
                  reason: 'no reason',
                },
              },
            ],
          },
        ],
      };
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: data,
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Deleted',
          CTAHandler: successCB,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      console.error(error);
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title ?? 'Unexpected Error',
          subtitle: error.message ?? 'Unexpected Error',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function updateSireGroup(
  agliveToken: string,
  groupDetails: any,
  ifDelete?: boolean,
  CTAHandler?: () => void,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const data = {
        tokens: [
          {
            type: 'angusSireGroup',
            externalIds: {
              agliveToken: agliveToken,
            },
            activities: [
              {
                type: ifDelete ? 'DEL_details' : 'UP_details',
                details: groupDetails,
              },
            ],
          },
        ],
      };
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: data,
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Updated',
          CTAHandler,
        }),
      );
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title ?? 'Unexpected Error',
          subtitle: error.message ?? 'Unexpected Error',
        }),
      );
      return Promise.reject(new NetworkError(error));
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function updateSire(
  userId: string,
  agliveToken: string,
  nlis: nlisDetails,
  details: sireUpdateDetails,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const data = {
        tokens: [
          {
            type: 'angusSire',
            externalIds: {
              agliveToken: agliveToken,
            },
            activities: [
              {
                type: 'UP_details',
                nlisDetails: nlis,
                details: details,
              },
            ],
          },
        ],
      };
      const response = callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: data,
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Updated',
        }),
      );
      return Promise.resolve(response);
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title ?? 'Unexpected Error',
          subtitle: error.message ?? 'Unexpected Error',
        }),
      );
      return Promise.reject(new NetworkError(error));
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function updateMob(
  userId: string,
  agliveToken: string,
  mobDetails: any,
  nlisUser: string,
  nlisPass: string,
  email: string,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const data = {
        tokens: [
          {
            type: 'angusMob',
            externalIds: {
              agliveToken: agliveToken,
            },
            activities: [
              {
                type: 'UP_details',
                nlisDetails: {
                  nlisUserId: nlisUser,
                  nlisPassword: nlisPass,
                  nlisEmail: email,
                },
                details: mobDetails,
              },
            ],
          },
        ],
      };
      const response = callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: data,
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Updated',
        }),
      );
      return Promise.resolve(response);
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      return Promise.reject(new NetworkError(error));
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function orderTags(
  userId: string,
  orderTagObj: any,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const response = callAPI({
        url: `${API.POST.angus}/${orderTagObj.acct_id}/tags`,
        method: 'POST',
        data: {...orderTagObj, userId: userId},
      });
      return Promise.resolve(response);
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
      return Promise.reject(new NetworkError(error));
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function replaceTag(
  userId: string,
  agliveToken: string,
  nlis: nlisDetails,
  details: animalUpdateDetails,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const data = {
        tokens: [
          {
            type: 'angusCalve',
            externalIds: {
              agliveToken: agliveToken,
            },
            activities: [
              {
                type: 'replace_tag',
                nlisDetails: nlis,
                details: details,
              },
            ],
          },
        ],
      };
      let responses = {success: {}, fail: {}};
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: data,
      })
        .then((resp) => {
          dispatch(
            toggleModal({
              status: 'success',
              title: 'Updated',
            }),
          );
          console.log('in actions', resp);
          responses.success = resp;
        })
        .catch((error) => {
          dispatch(
            toggleModal({
              status: 'failed',
              title: error.title ?? 'Unexpected Error',
              subtitle: error.message ?? 'Unexpected Error',
            }),
          );
          responses.fail = error;
        });
      if (Object.keys(responses.success).length > 0) {
        return Promise.resolve(responses.success);
      } else {
        return Promise.reject(responses.fail);
      }
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title ?? 'Unexpected Error',
          subtitle: error.message ?? 'Unexpected Error',
        }),
      );
      return Promise.reject(new NetworkError(error));
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export type preSaleDataType = {
  index: number;
  rfid: string;
  sex: string;
  birth_start: string;
  birth_end: string;
};
export function downloadPreSaleReportCSV(
  data: Array<preSaleDataType>,
  mobName: string,
): AppThunk<void> {
  return (dispatch) => {
    const reportData = data.map((row) => [
      row.index.toString(),
      row.rfid,
      row.sex,
      `${row.birth_start} - ${row.birth_end}`,
    ]);
    // generate csv to download
    generatePreSaleCSV(reportData, `${mobName}.csv`);
  };
}

function generatePreSaleCSV(
  codeTokens: Array<Array<string>>,
  csvName = 'mode.csv',
): void {
  const CSV_HEADER = [['No.', 'RFID', 'Sex', 'Birth Range by Month']];

  let csvContent =
    'data:text/csv;charset=utf-8,' +
    CSV_HEADER.map((row) => row.join(',')).join('\n') +
    '\n' +
    codeTokens.map((row) => row.join(',')).join('\n');

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', csvName);
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file
}
export async function fetchGroupName(
  sireGroupId: string,
  angusAuthToken: string,
  acct_id: string,
) {
  try {
    const response = await callAPI({
      url: `${API.GET.getAngus}/${acct_id}/group`,
      method: 'GET',
      params: {
        angusAuthToken,
        grp: sireGroupId,
      },
    });
    return response;
  } catch (error) {
    throw new NetworkError(error);
  }
}

export async function fetchPreSale(
  mobId: string,
  pic: string,
  nlisEmail: string,
  NLISUserID: string,
  NLISPassword: string,
) {
  try {
    const response = await callAPI({
      url: API.GET.getPreSaleReport,
      method: 'GET',
      params: {
        mobId,
        pic,
        NLISUserID,
        NLISPassword,
        nlisEmail,
      },
    });
    return response;
  } catch (error) {
    throw new NetworkError(error);
  }
}

export function deleteAnimal(
  userId: string,
  externalIds: {[key: string]: string},
  angusAuthToken: string,
  history,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const data = {
        tokens: [
          {
            type: 'angusCalve',
            externalIds: externalIds,
            activities: [
              {
                type: 'DEL_token',
                details: {
                  angusAuthToken: angusAuthToken,
                  reason: 'no reason',
                },
              },
            ],
          },
        ],
      };
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: data,
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Deleted',
          CTAHandler: () =>
            history.push(
              (history.location.pathname = '/private/verified/recordAnimals/mob-details'
                ? '/private/verified/recordAnimals'
                : `/private/verified/animalProfile`),
            ),
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      console.error(error);
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title ?? 'Unexpected Error',
          subtitle: error.message ?? 'Unexpected Error',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function deleteMob(
  userId: string,
  externalIds: {[key: string]: string},
  successCallback: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const data = {
        tokens: [
          {
            type: 'angusMob',
            externalIds: externalIds,
            activities: [
              {
                type: 'DEL_token',
                details: {
                  reason: 'no reason',
                },
              },
            ],
          },
        ],
      };
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: data,
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Deleted',
          CTAHandler: successCallback,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      console.error(error);
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title ?? 'Unexpected Error',
          subtitle: error.message ?? 'Unexpected Error',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}
