import produce from 'immer';
import {groupState, FETCH_GROUP_DATA, GroupDataTypes} from './types';

const initialState: groupState = {
  groupData: [],
};

export const initailGroup: GroupDataTypes = {
  name: '',
  animalsItems: [],
  animalsItemsCount: '',
  pic_id: '',
  createdAt: null,
  agliveToken: '',
};

export default function addReducer(state = initialState, action): groupState {
  switch (action.type) {
    case FETCH_GROUP_DATA:
      return produce(state, (draft) => {
        draft.groupData = action.payload;
      });
    default:
      return state;
  }
}
