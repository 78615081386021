import React, {useEffect, useCallback, useReducer, useState} from 'react';
import COLOR from '../../styled/colors';
import {useHistory, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import withHeader from '../../presentation/withHeader';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  Checkbox,
  Box,
} from '@material-ui/core';
import {
  Table,
  TableHeaderRow,
  Grid as TableGrid,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {IntegratedPaging} from '@devexpress/dx-react-grid';
import {PagingState} from '@devexpress/dx-react-grid';
import {HyperLink} from '../../presentation/word';
import MyButton from '../../presentation/button';
import CONSTANT from '../../config/constant';
import {FormAnswers, Question, FormType} from '../../store/form/types';
import {ExpandMore, ExpandLess, CheckCircle} from '@material-ui/icons';
import FormTemplate from '../asset/FormTemplate';
import {ConsignmentTypes} from '../../store/add/types';
import {
  fetchConsigneeData,
  fetchConsignmentData,
  saveAttach,
} from '../../store/add/actions';
import CONSTANTS from '../../config/constant';
import {extractQuestionType} from './consignment';
import {CASL} from '@aglive/frontend-core';
import {styled} from '@mui/material/styles';
import {initailConsignment} from '../../store/add/reducers';
import {SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON} from '../../store/spinner/types';
import {fileUploader} from '../../store/product/actions';

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  formContainer: {
    marginTop: 50,
  },
  formTitleContainer: {
    justifyContent: 'space-between',
    marginTop: 35,
  },
  formCardContainer: {
    backgroundColor: COLOR.GRAY_SOLID,
    marginTop: 25,
    padding: 10,
  },
  buttonContainer: {
    justifyContent: 'end',
    marginTop: 50,
  },
  label: {
    textTransform: 'none',
  },
  dividerStyle: {
    width: '100%',
    marginTop: 10,
  },
  boxDividerStyle: {width: '100%'},
  IconChecked: {
    color: COLOR.GREEN_BUTTON,
  },
  IconUnchecked: {
    color: COLOR.GRAY_ICON,
  },
  attachField: {
    marginLeft: 15,
    marginTop: 10,
    marginRight: 15,
  },
  shareAddFiled: {
    marginTop: 20,
  },
  tableContainer: {
    backgroundColor: COLOR.WHITE,
  },
  disabledCheckbox: {
    '&.MuiCheckbox-root': {
      '&.MuiCheckbox-colorPrimary.Mui-disabled': {
        color: COLOR.GREEN_BUTTON,
      },
    },
  },
}));

const initialState = {
  forms: [] as Array<FormType>,
  envdAnswers: {} as FormAnswers,
  hdAnswers: {} as FormAnswers,
  envdExpand: false,
  hdExpand: false,
  envdCompleted: false,
  hdCompleted: false,
  questionList: [] as Array<Question>,
  selectedEnvdForm: '' as string,
  selectedHdForm: '' as string,
  selectedConsigneeIndex: '',
  destinationPICRadio: '',
  destinationPIC: '',
  consignmentName: '',
  eNVDS3Link: '',
  externalIds: '',
  breakIndex: 0,
  showAnimalList: [],
  currentPage: 0,
  pageSize: 10,
  signDeclarationChecked: true,
  selectAnimalModel: '',
  consignment: initailConsignment,
  attachments: [],
  isSubmit: false,
  currentAgToken: '',
  newAttachments: [],
};

type Action =
  | {type: 'set/forms'; forms: Array<FormType>}
  | {type: 'set/questionList'; questionList: Array<Question>}
  | {type: 'update/envdAnswers'; envdAnswers: FormAnswers}
  | {type: 'update/hdAnswers'; hdAnswers: FormAnswers}
  | {type: 'change/envdExpand'; envdExpand: boolean}
  | {type: 'change/hdExpand'; hdExpand: boolean}
  | {type: 'change/envdCompleted'; envdCompleted: boolean}
  | {type: 'change/hdCompleted'; hdCompleted: boolean}
  | {type: 'change/selectedEnvdForm'; selectedEnvdForm: string}
  | {type: 'change/selectedHdForm'; selectedHdForm: string}
  | {type: 'change/selectedConsignee'; selectedConsigneeIndex: string}
  | {
      type: 'change/destinationPICRadio';
      destinationPICRadio: 'usePIC' | 'newPIC';
    }
  | {type: 'change/destinationPIC'; destinationPIC: string}
  | {type: 'change/consignmentName'; consignmentName: string}
  | {type: 'change/signDeclarationChecked'; signDeclarationChecked: boolean}
  | {type: 'set/breakIndex'; breakIndex: number}
  | {type: 'fetch/showAnimalList'; showAnimalList: Array<any>}
  | {type: 'change/page'; currentPage: number}
  | {type: 'change/pageSize'; pageSize: number}
  | {type: 'change/selectAnimalModel'; selectAnimalModel: string}
  | {
      type: 'fetch/formsAnswers';
      envdAnswers: FormAnswers;
      hdAnswers?: FormAnswers;
    }
  | {type: 'update/consignment'; consignment: ConsignmentTypes}
  | {
      type: 'update/attachments';
      attachments: Array<{name: string; link: string}>;
    }
  | {type: 'update/isSubmit'; isSubmit: boolean}
  | {type: 'change/currentAgliveToken'; currentAgToken: string}
  | {
      type: 'update/newAttachments';
      newAttachments: Array<{name: string; link: string}>;
    };

const reducer = (
  prevState: typeof initialState,
  action: Action,
): typeof initialState => {
  const {type, ...actionData} = action;
  switch (action.type) {
    default:
      return {...prevState, ...actionData};
  }
};
const StyledTable = styled(Table.Table)(({theme}) => ({
  backgroundColor: COLOR.WHITE,
}));
const StyledPaging = styled(PagingPanel.Container)(({theme}) => ({
  backgroundColor: COLOR.WHITE,
  alignSelf: 'center',
  marginTop: 20,
}));
const StyledHeader = styled(TableHeaderRow.Content)(({theme}) => ({
  fontWeight: 700,
}));
const ViewConsignment: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const userId = useAppSelector((state) => state.auth.wallet);
  const {id} = useParams<{id: string}>();
  const dispatch = useAppDispatch();
  const consignmentsList: Array<ConsignmentTypes> = useAppSelector(
    (state) => state.add.consignmentData,
  );
  const targetIndex = consignmentsList.findIndex(
    (consignment) => consignment.agliveToken === id,
  );
  const [state, localDispatch] = useReducer(reducer, initialState);
  const savedConsignees = useAppSelector((state) => state.add.consigneeData);

  const consigneeIndex = savedConsignees.findIndex(
    (consignee) => consignee.agliveToken === state.consignment.consignee?.id,
  );
  const consignees = {...savedConsignees[consigneeIndex]};

  const isDraft = state.consignment.status?.includes('draft');
  const fetchAnswers = useCallback(async () => {
    try {
      const res = await fetch(CONSTANT.FORM_JSON_LINK);
      const jsonData = await res.json();
      localDispatch({
        type: 'set/forms',
        forms: jsonData,
      });
      const envdAnswers = {};
      const hdAnswers = {};
      const envdQuestions = extractQuestionType(jsonData, 'LPAC1');
      const hdQuestions = extractQuestionType(jsonData, 'HSC2');
      state.consignment.answers?.forEach((ans) => {
        if (envdQuestions[ans.questionId]) {
          envdAnswers[`${ans.questionId}-${ans.index}`] = {
            questionId: ans.questionId,
            index: ans.index,
            value: ans.value,
          };
        }
        if (hdQuestions[ans.questionId]) {
          hdAnswers[`${ans.questionId}-${ans.index}`] = {
            questionId: ans.questionId,
            index: ans.index,
            value: ans.value,
          };
        }
      });
      localDispatch({type: 'fetch/formsAnswers', envdAnswers, hdAnswers});
    } catch (e) {
      console.error(e);
    }
  }, [state.consignment]);
  useEffect(() => {
    fetchAnswers();
    localDispatch({
      type: 'fetch/showAnimalList',
      showAnimalList: state.consignment.assets ?? [],
    });
    if (state.consignment.animalSelectionMode) {
      localDispatch({
        type: 'change/selectAnimalModel',
        selectAnimalModel: state.consignment.animalSelectionMode,
      });
    } else {
      localDispatch({
        type: 'change/selectAnimalModel',
        selectAnimalModel: 'Individual Animal Selection',
      });
    }
    const fetchAttachments = state.consignment.attachments?.map((attach) => {
      return {name: attach.split('/').pop(), link: attach};
    });
    localDispatch({
      type: 'update/attachments',
      attachments: fetchAttachments,
    });
  }, [state.consignment]);

  const handleEnvdClick = () => {
    localDispatch({
      type: 'change/envdExpand',
      envdExpand: !state.envdExpand,
    });
  };

  const handleHdClick = () => {
    localDispatch({
      type: 'change/hdExpand',
      hdExpand: !state.hdExpand,
    });
  };

  const attachFileUpload = React.useRef(null);
  const handleAttachClick = useCallback(() => {
    attachFileUpload.current.click();
  }, [attachFileUpload]);
  /**Handle csv and file uploads*/
  const uploadFileHandler = useCallback(
    (type: string) => async (e) => {
      const target =
        type === 'selectCSV'
          ? e
          : e.target.files.length > 0
          ? e.target.files[0]
          : '';
      if (target) {
        if (target.size > 10485760) {
          window.alert('Upload image size more than 10mb ！');
        } else {
          dispatch({type: SPINNER_TOGGLE_ON});
          const response = await fileUploader(userId, target, 'envd');
          const responseData = response[0].data;
          dispatch({type: SPINNER_TOGGLE_OFF});
          e.target.value = '';
          if (typeof responseData == 'string') {
            if (type === 'attachments') {
              localDispatch({
                type: 'update/newAttachments',
                newAttachments: [
                  ...state.newAttachments,
                  {name: target.name, link: responseData},
                ],
              });
            }
          }
        }
      }
    },
    [state.newAttachments, userId],
  );

  const saveProcess = useCallback(() => {
    if (state.consignment.status?.includes('completed')) {
      dispatch(
        saveAttach(
          state.newAttachments.map((attach) => attach.link),
          userId,
          state.consignment.agliveToken,
          () => {
            history.goBack();
          },
        ),
      );
    }
  }, [dispatch, state.newAttachments, userId, state.consignment.agliveToken]);

  const handleSave = useCallback(() => {
    saveProcess();
    localDispatch({type: 'update/isSubmit', isSubmit: true});
  }, [saveProcess]);

  const columns = [
    {name: 'rfid', title: 'RFID'},
    {name: 'nlis', title: 'NLIS ID'},
    {name: 'birthYear', title: 'Birth Year'},
    {name: 'mob', title: 'Mob'},
  ];

  const changePage = useCallback((pageNum: number) => {
    localDispatch({type: 'change/page', currentPage: pageNum});
  }, []);
  const changePageSize = useCallback((size: number) => {
    localDispatch({type: 'change/pageSize', pageSize: size});
  }, []);

  useEffect(() => {
    dispatch(fetchConsigneeData());
    dispatch(fetchConsignmentData());
  }, []);
  useEffect(() => {
    localDispatch({
      type: 'update/consignment',
      consignment: {...consignmentsList[targetIndex]},
    });
  }, [consignmentsList, targetIndex]);

  const agConsignmentNumber = React.useMemo(() => {
    let number = state.consignment?.agliveToken;
    if (!number) {
      const agToken = state.consignment.externalIds?.find(
        (exid) => !!exid.agliveToken,
      );
      if (agToken) {
        number = agToken.agliveToken;
      }
    }
    return 'consignment-' + number?.split('-')?.pop();
  }, [state.consignment?.agliveToken, state.consignment.externalIds]);

  return (
    <>
      <Grid
        container
        spacing={3}
        alignItems="center"
        className={classes.bodyContainer}>
        <Grid container item style={{display: 'flex', flexDirection: 'column'}}>
          <Grid
            container
            item
            direction="row"
            style={{marginBottom: 20}}
            justifyContent="space-between">
            <Grid item xs={5} style={{marginBottom: 20}}>
              <Typography
                variant="h6"
                role="label"
                style={{fontWeight: 700, marginBottom: 10}}>
                Consignment Name
              </Typography>
              <Typography variant="h6" role="label" style={{marginBottom: 10}}>
                {state.consignment.name}
              </Typography>
              <Divider
                className={classes.dividerStyle}
                style={{opacity: 0.5}}
              />
            </Grid>
            <Grid item xs={5}>
              <Typography
                variant="h6"
                role="label"
                style={{fontWeight: 700, marginBottom: 10}}>
                Consignee
              </Typography>

              <Typography
                variant="h6"
                role="label"
                style={{marginBottom: 10}}
                id={'viewConsigneeName'}>
                {consignees.receiverName}
                <Divider
                  className={classes.dividerStyle}
                  style={{opacity: 0.5}}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction="row"
            style={{marginBottom: 20}}
            justifyContent="space-between">
            <Grid item xs={5} style={{marginBottom: 20}}>
              <Typography
                variant="h6"
                role="label"
                style={{fontWeight: 700, marginBottom: 10}}>
                Aglive Consignment Number
              </Typography>
              <Typography variant="h6" role="label" style={{marginBottom: 10}}>
                {agConsignmentNumber}
              </Typography>
              <Divider
                className={classes.dividerStyle}
                style={{opacity: 0.5, marginBottom: 40}}
              />

              <Grid item style={{marginBottom: 20}}>
                <Typography
                  variant="h6"
                  role="label"
                  style={{fontWeight: 700, marginBottom: 10}}>
                  Select Animals
                </Typography>

                {state.selectAnimalModel === 'Individual Animal Selection' && (
                  <Typography
                    id={'animalSelectionMode'}
                    variant="h6"
                    role="label"
                    style={{marginBottom: 10}}>
                    {'Individual Animal Selection'}
                  </Typography>
                )}

                {state.selectAnimalModel === 'Group/Mob Selection' && (
                  <Typography
                    id={'animalSelectionMode'}
                    variant="h6"
                    role="label"
                    style={{marginBottom: 10}}>
                    {state.consignment.mob.name}
                  </Typography>
                )}

                {state.selectAnimalModel === 'CSV file Selection' && (
                  <Button
                    id={'animalSelectionMode'}
                    href={state.consignment.csvFile.link}
                    classes={{
                      label: classes.label,
                    }}>
                    <span style={{color: COLOR.GREENT_TEXT}}>
                      <u>{state.consignment.csvFile.name}</u>
                    </span>
                  </Button>
                )}

                <Divider
                  className={classes.dividerStyle}
                  style={{opacity: 0.5, marginBottom: 40}}
                />
              </Grid>

              {state.consignment?.eNVDS3Link && (
                <>
                  <Typography
                    variant="h6"
                    role="label"
                    style={{fontWeight: 700, marginBottom: 10}}>
                    eNVD Form
                  </Typography>
                  <Button
                    id={'animalSelectionMode'}
                    href={state.consignment.eNVDS3Link}
                    target="_blank"
                    classes={{
                      label: classes.label,
                    }}>
                    <span style={{color: COLOR.GREENT_TEXT}}>
                      <u>{'eNVD.pdf'}</u>
                    </span>
                  </Button>
                  <Divider
                    className={classes.dividerStyle}
                    style={{opacity: 0.5, marginBottom: 40}}
                  />
                </>
              )}
            </Grid>

            <Grid item xs={5}>
              <Grid
                container
                item
                direction="row"
                style={{marginBottom: 20}}
                justifyContent="space-between"
                alignItems="flex-start">
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    role="label"
                    style={{fontWeight: 700, marginBottom: 10}}>
                    Destination PIC
                  </Typography>
                  <Typography
                    variant="h6"
                    role="label"
                    style={{marginBottom: 10}}
                    id={'destinationPIC'}>
                    {state.consignment.destination?.pic
                      ? state.consignment.destination?.pic
                      : state.consignment.consignee?.pic}
                  </Typography>
                  <Divider
                    className={classes.dividerStyle}
                    style={{opacity: 0.5}}
                  />

                  {state.consignment.destination?.address && (
                    <>
                      <Typography
                        variant="h6"
                        role="label"
                        style={{fontWeight: 700, margin: '10px 0'}}>
                        Address
                      </Typography>
                      <Typography
                        variant="h6"
                        role="label"
                        style={{marginBottom: 10}}
                        id={'destinationPIC'}>
                        {state.consignment.destination.address}
                      </Typography>
                      <Divider
                        className={classes.dividerStyle}
                        style={{opacity: 0.5}}
                      />
                    </>
                  )}
                </Grid>

                {state.consignment.destination?.postcode && (
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      role="label"
                      style={{fontWeight: 700, margin: '10px 0'}}>
                      Post Code
                    </Typography>
                    <Typography
                      variant="h6"
                      role="label"
                      style={{marginBottom: 10}}
                      id={'destinationPIC'}>
                      {state.consignment.destination.postcode}
                    </Typography>
                    <Divider
                      className={classes.dividerStyle}
                      style={{opacity: 0.5}}
                    />
                  </Grid>
                )}

                {state.consignment.destination?.town && (
                  <Grid item xs={5}>
                    <Typography
                      variant="h6"
                      role="label"
                      style={{fontWeight: 700, margin: '10px 0'}}>
                      Town
                    </Typography>
                    <Typography
                      variant="h6"
                      role="label"
                      style={{marginBottom: 10}}
                      id={'destinationPIC'}>
                      {state.consignment.destination.town}
                    </Typography>
                    <Divider
                      className={classes.dividerStyle}
                      style={{opacity: 0.5}}
                    />
                  </Grid>
                )}

                {state.consignment.destination?.state && (
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      role="label"
                      style={{fontWeight: 700, margin: '10px 0'}}>
                      State/Province/Territory
                    </Typography>
                    <Typography
                      variant="h6"
                      role="label"
                      style={{marginBottom: 10}}
                      id={'destinationPIC'}>
                      {state.consignment.destination.state}
                    </Typography>
                    <Divider
                      className={classes.dividerStyle}
                      style={{opacity: 0.5}}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            {state.selectAnimalModel === 'Individual Animal Selection' && (
              <Grid
                xs={12}
                container
                style={{marginTop: 20, justifyContent: 'flex-start'}}>
                {/* <Grid style={{backgroundColor: COLOR.GRAY_BUTTON}}> */}
                <Box
                  id={'viewAttachField'}
                  p={'40px 15px 30px 15px'}
                  ml={5}
                  style={{
                    marginLeft: 0,
                    backgroundColor: COLOR.GRAY_SOLID,
                  }}>
                  <TableGrid rows={state.showAnimalList} columns={columns}>
                    <PagingState
                      currentPage={state.currentPage}
                      onCurrentPageChange={changePage}
                      pageSize={state.pageSize}
                      onPageSizeChange={changePageSize}
                    />
                    <IntegratedPaging />
                    <Table tableComponent={TableComponent} />
                    <TableHeaderRow contentComponent={HeaderComponent} />
                    <PagingPanel
                      pageSizes={[5, 10, 15, 20]}
                      containerComponent={PagingComponent}
                    />
                  </TableGrid>
                </Box>
                {/* </Grid> */}
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid container item>
          <Grid container className={classes.formContainer}>
            <Grid container item xs={12} className={classes.formTitleContainer}>
              <Typography variant="h3" role="label" style={{}}>
                Add Forms
              </Typography>
              <Divider className={classes.dividerStyle} />
            </Grid>
            <Grid
              container
              item
              style={{
                // display: 'flex',
                marginTop: 35,
              }}>
              <CheckCircle
                className={
                  state.consignment.forms.includes('LPAC1')
                    ? classes.IconChecked
                    : classes.IconUnchecked
                }
              />
              <Grid
                container
                item
                style={{
                  marginLeft: 24,
                  justifyContent: 'space-between',
                  flex: 1,
                }}
                onClick={handleEnvdClick}>
                <Typography variant="h3" role="label" style={{}}>
                  eNVD
                </Typography>
                {state.envdExpand ? <ExpandMore /> : <ExpandLess />}
                <Divider className={classes.dividerStyle} />
              </Grid>
              {state.envdExpand && (
                <Grid container item style={{marginTop: 16, opacity: 0.8}}>
                  <Grid container item className={classes.formCardContainer}>
                    {state.consignment.forms.includes('LPAC1') ? (
                      <>
                        <Grid
                          container
                          item
                          xs={6}
                          direction="column"
                          style={{padding: 10}}>
                          <Typography variant="h6" style={{fontWeight: 600}}>
                            {'Form Name'}
                          </Typography>
                          <Typography variant="h6" style={{marginTop: 8}}>
                            {state.consignment?.template[0]?.name}
                          </Typography>
                        </Grid>
                        <FormTemplate
                          formDocId={state.selectedEnvdForm}
                          viewAnswers={state.envdAnswers}
                          formType={'LPAC1'}
                        />
                      </>
                    ) : (
                      <Typography
                        variant="h6"
                        role="label"
                        style={{marginTop: 8}}>
                        {'No selected eNVD form'}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              style={{
                marginTop: 35,
              }}>
              <CheckCircle
                className={
                  state.consignment.forms.includes('HSC2') // question 17 is shared from envd form, so exclude it
                    ? classes.IconChecked
                    : classes.IconUnchecked
                }
              />
              <Grid
                container
                item
                style={{
                  marginLeft: 24,
                  justifyContent: 'space-between',
                  flex: 1,
                }}
                onClick={handleHdClick}>
                <Typography variant="h3" role="label" style={{}}>
                  Health Declaration
                </Typography>
                {state.hdExpand ? <ExpandMore /> : <ExpandLess />}
                <Divider className={classes.dividerStyle} />
              </Grid>
              {state.hdExpand && (
                <Grid container item style={{marginTop: 16, opacity: 0.8}}>
                  <Grid container item className={classes.formCardContainer}>
                    {state.consignment.forms.includes('HSC2') ? (
                      <>
                        <Grid
                          container
                          item
                          xs={6}
                          direction="column"
                          style={{padding: 10}}>
                          <Typography variant="h6" style={{fontWeight: 600}}>
                            {'Form Name'}
                          </Typography>
                          <Typography variant="h6" style={{marginTop: 8}}>
                            {state.consignment.template[1]?.name}
                          </Typography>
                        </Grid>
                        <FormTemplate
                          formDocId={state.selectedHdForm}
                          viewAnswers={state.hdAnswers}
                          formType={'HSC2'}
                        />
                      </>
                    ) : (
                      <Typography
                        variant="h6"
                        role="label"
                        style={{marginTop: 8}}>
                        {'No selected Health Declaration form'}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container item style={{marginTop: 50}}>
          <Grid container item xs={12} className={classes.formTitleContainer}>
            <Typography variant="h3" role="label">
              Declarations
            </Typography>
            <Divider className={classes.dividerStyle} />
          </Grid>
          <Grid container item alignItems="center">
            <Checkbox
              defaultChecked
              color="primary"
              disabled
              className={classes.disabledCheckbox}
              id={'declrartionCheckbox'}
              style={{
                display:
                  isDraft && state.consignment.declaration?.userId
                    ? 'flex'
                    : 'none',
              }}
            />
            <Checkbox
              color="primary"
              disabled
              id={'declrartionCheckbox'}
              style={{
                display:
                  isDraft && !state.consignment.declaration?.userId
                    ? 'flex'
                    : 'none',
              }}
            />
            <Checkbox
              defaultChecked
              color="primary"
              disabled
              className={classes.disabledCheckbox}
              id={'declrartionCheckbox'}
              style={{display: isDraft ? 'none' : 'flex'}}
            />
            <Typography variant="h6" role="label">
              Sign All Declarations
            </Typography>
          </Grid>

          {state.consignment?.forms && state.consignment?.forms[0] === 'LPAC1' && (
            <Grid container item style={{marginTop: 24}}>
              <Box
                p={'8px 16px 8px 16px'}
                ml={5}
                style={{
                  backgroundColor: COLOR.GRAY_SOLID,
                }}>
                <Typography style={{fontWeight: 700}} role="label">
                  eNVD
                </Typography>
                <Divider className={classes.boxDividerStyle} />
                <Typography variant="h6" role="label" style={{marginTop: 8}}>
                  {CONSTANTS.FORM_DECLARATIONS.envd}
                </Typography>
              </Box>
            </Grid>
          )}

          {state.consignment?.forms && state.consignment?.forms[1] === 'HSC2' && (
            <Grid container item style={{marginTop: 24}}>
              <Box
                p={'8px 16px 8px 16px'}
                ml={5}
                style={{
                  backgroundColor: COLOR.GRAY_SOLID,
                }}>
                <Typography style={{fontWeight: 700}} role="label">
                  Health Declaration
                </Typography>
                <Divider className={classes.boxDividerStyle} />
                <Typography variant="h6" role="label" style={{marginTop: 8}}>
                  {CONSTANTS.FORM_DECLARATIONS.hd}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid container item style={{marginTop: 50}}>
          <Grid container item xs={12} className={classes.formTitleContainer}>
            <Typography variant="h3" role="label">
              Attachments
            </Typography>
            <Divider className={classes.dividerStyle} />
          </Grid>
          <Grid container direction="row" style={{marginTop: 30}}>
            {state.consignment.status?.includes('completed') && (
              <Grid item xs={5}>
                <MyButton
                  text={'Attach File'}
                  variant="outlined"
                  onClick={handleAttachClick}
                />
                <input
                  type="file"
                  id={'AttachFile'}
                  onChange={uploadFileHandler('attachments')}
                  ref={attachFileUpload}
                  style={{height: 0, width: 0}}
                />
              </Grid>
            )}

            <Grid item xs={5}>
              <Box
                id={'viewAttachField'}
                p={'8px 16px 8px 16px'}
                ml={5}
                style={{
                  backgroundColor: COLOR.GRAY_SOLID,
                }}>
                {!(state.attachments && state.attachments.length > 0) ? (
                  <Typography
                    variant="h6"
                    role="label"
                    style={{marginTop: 8}}
                    id={'attachName'}>
                    {'No File Uploaded'}
                  </Typography>
                ) : (
                  state.attachments &&
                  state.attachments?.map((attachment) => [
                    <Grid item container>
                      <Grid
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                        className={classes.attachField}>
                        <HyperLink
                          href={attachment.link}
                          target="_blank"
                          rel="noreferrer noopener"
                          id={'attachName'}>
                          {attachment.name}
                        </HyperLink>
                      </Grid>
                      <Divider className={classes.boxDividerStyle} />
                    </Grid>,
                  ])
                )}
                {state.newAttachments &&
                  state.newAttachments?.map((attachment) => [
                    <Grid item container>
                      <Grid
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                        className={classes.attachField}>
                        <HyperLink
                          href={attachment.link}
                          target="_blank"
                          rel="noreferrer noopener"
                          id={'attachName'}>
                          {attachment.name}
                        </HyperLink>
                      </Grid>
                      <Divider className={classes.boxDividerStyle} />
                    </Grid>,
                  ])}
              </Box>
            </Grid>
          </Grid>
          {state.consignment.status?.includes('completed') && (
            <Grid item xs={12}>
              <Box
                id={'viewAttachField'}
                p={'8px 16px'}
                mt={2}
                style={{
                  backgroundColor: COLOR.GRAY_SOLID,
                }}>
                <Typography style={{fontWeight: 700}} role="label">
                  Important note
                </Typography>
                <Divider className={classes.dividerStyle} />
                <Divider className={classes.boxDividerStyle} />
                <Typography variant="h6" role="label" style={{marginTop: 8}}>
                  If you are completing the Part B on paper, three copies are
                  required to be printed – one each for the seller, transporter
                  and receiver. You can upload copies of all related documents
                  to your Aglive consignment.
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid container item style={{marginTop: 50}}>
            <Grid container item xs={12} className={classes.formTitleContainer}>
              <Typography variant="h3" role="label">
                Share
              </Typography>
              <Divider className={classes.dividerStyle} />
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.shareAddFiled}
              spacing={3}>
              <Grid
                container
                item
                direction="column"
                xs={5}
                id={'viewShareEmail'}>
                <Typography style={{fontWeight: 700}} role="label">
                  Email Address
                </Typography>
                {state.consignment.shareTo?.email?.length > 0 ? (
                  state.consignment.shareTo.email.map((emails, index) => [
                    <Typography
                      role="label"
                      variant="h6"
                      id={`shareEmail${index}`}>
                      {emails}
                    </Typography>,
                  ])
                ) : (
                  <Typography role="label" variant="h6" id={`shareEmail`}>
                    {'-'}
                  </Typography>
                )}
              </Grid>
              <Grid
                container
                item
                direction="column"
                xs={5}
                id={'viewShareMobile'}>
                <Typography style={{fontWeight: 700}} role="label">
                  Mobile Number
                </Typography>
                {state.consignment.shareTo?.mobile?.length > 0 ? (
                  state.consignment.shareTo.mobile.map((mobile, index) => {
                    return mobile ? (
                      <Typography
                        role="label"
                        variant="h6"
                        id={`shareMobile${index}`}>
                        {mobile}
                      </Typography>
                    ) : (
                      <Typography role="label" variant="h6" id={`shareMobile`}>
                        {'-'}
                      </Typography>
                    );
                  })
                ) : (
                  <Typography role="label" variant="h6" id={`shareMobile`}>
                    {'-'}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {state.consignment.status?.includes('completed') && (
          <Grid container direction="row" className={classes.buttonContainer}>
            <MyButton
              text={'Save'}
              variant="contained"
              width={230}
              height={60}
              fontSize={18}
              onClick={handleSave}
              disabled={!state.newAttachments?.length}
            />
          </Grid>
        )}
        <Grid
          container
          className={classes.buttonContainer}
          style={{
            display: isDraft ? 'flex' : 'none',
          }}>
          <CASL.Can I="update" a="consignment">
            <MyButton
              text={'Edit'}
              variant="contained"
              width={280}
              fontSize={18}
              onClick={() => {
                history.replace(
                  `/private/activity/consignment/edit/${state.consignment.agliveToken}`,
                );
              }}
            />
          </CASL.Can>
        </Grid>
      </Grid>
    </>
  );
};

export default withHeader(
  {
    title: 'View Consignment',
    back: true,
  },
  ViewConsignment,
);
export const TableComponent = (props) => <StyledTable {...props} />;
export const PagingComponent = (props) => <StyledPaging {...props} />;
export const HeaderComponent = (props) => <StyledHeader {...props} />;
