import React, {useEffect, useState} from 'react';

import {TokenService} from '@aglive/data-model';
import {
  Document,
  Font,
  Image as PDFImage,
  Page,
  Text,
  View,
} from '@react-pdf/renderer';

import {styles} from './styles';
import agliveLogo from '../../../img/agliveLogo.png';
import AngusLogoSVG from './AngusLogoSVG';

interface PromotionPDFProps {
  display: TokenService.PromotionToken['details']['display'];
  story: TokenService.ProductToken['details']['story'];
  isAngus: boolean;
  QRCodeImage: string;
  QRSize: number;
}

const PromotionPDF: React.FC<PromotionPDFProps> = ({
  display,
  story,
  isAngus,
  QRCodeImage,
  QRSize,
}) => {
  Font.register({
    family: 'Open Sans',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVc.ttf',
        fontStyle: 'normal',
        fontWeight: 400,
      },
      {
        src: 'https://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1x4gaVc.ttf',
        fontStyle: 'normal',
        fontWeight: 600,
      },
    ],
  });

  return (
    <Document creator="Aglive" producer="Aglive">
      <Page size="A4" orientation="landscape" wrap={false}>
        <View style={styles.pageContainer}>
          <View style={styles.posterContainer}>
            <View style={styles.headingContainer}>
              <View style={styles.protectedBy}>
                <Text style={styles.protectedByText}>Protected by</Text>
                <PDFImage src={agliveLogo} style={styles.agliveLogo} />
              </View>
            </View>

            {isAngus && (
              <View style={styles.angusVerifiedContainer}>
                <AngusLogoSVG />
                <Text style={styles.angusVerifiedText}>Angus Verified</Text>
              </View>
            )}

            <View style={styles.middleSectionContainer}>
              {story?.logo && (
                <PDFImage
                  src={{
                    uri: story.logo,
                    method: 'GET',
                    headers: {'Cache-Control': 'no-cache'},
                    body: '',
                  }}
                  style={styles.logo}
                />
              )}

              <View style={styles.discoverMsgContainer}>
                <Text style={styles.discoverMsgText}>
                  Discover our quality story by scanning the code below
                </Text>
              </View>

              {QRCodeImage.length > 0 && (
                <View style={styles.qrContainer}>
                  <PDFImage
                    src={QRCodeImage}
                    style={{width: QRSize - 12, height: QRSize - 12}}
                  />
                </View>
              )}
            </View>

            {display?.footerImage && (
              <View style={styles.footerContainer}>
                <PDFImage
                  src={{
                    uri: display?.footerImage,
                    method: 'GET',
                    headers: {'Cache-Control': 'no-cache'},
                    body: '',
                  }}
                  style={styles.footerImage}
                />
              </View>
            )}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PromotionPDF;
