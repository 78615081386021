import {GridSize, GridSpacing} from '@material-ui/core';

export type QuestionInput = {
  index: number | null;
  label: string;
  questionId: string;
  subQuestionId: string;
  required: boolean;
  type: string;
  multiline?: boolean;
  maxLength?: number;
  answers?: string[];
  minimum?: number;
  maximum?: number;
  boxWrapIndex?: number;
  style?: string;
  webIndex?: number;
  colSize?: GridSize;
  display?: string;
  value?: string;
  text?: string;
  subLabel?: string;
  subLabelIcon?: string;
  answersValues?: Array<string>;
};
export type Question = {
  answers: Array<string>;
  index: number | null;
  questionId: string;
  text: string;
  fullText: string;
  subQuestions?: {
    [key: string]: {
      multigroup?: boolean;
      addText?: string;
      style?: string;
      inputs: Array<QuestionInput>;
    };
  };
  inputs?: Array<QuestionInput>;
  note?: string;
  style?: string;
  default?: string;
  colSpacing?: GridSpacing;
  required: boolean;
  answersValues?: Array<string>;
};
export type FormType = {
  name: string;
  questions: Array<Question>;
  style: string;
  type: string;
};

export type InputValueType = string | Date | boolean | number;
export interface FormAnswer {
  questionId: string;
  value: InputValueType;
  index: number | null;
}

export type FormAnswers = {[key: string]: FormAnswer};

export type FormDocResponse = {
  createdAt: string;
  docId: string;
  formType: string;
  details: {
    answers: Array<FormAnswer>;
    name: string;
  };
};

export type FormDoc = {
  createdAt: string;
  docId: string;
  formType: string;
  formName: string;
};

export type formState = Array<FormDoc>;

export const FETCH_FORM_DOCS = 'FETCH_FORM_DOCS';
