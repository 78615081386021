import COLOR from '../styled/colors';

export const Line = () => {

  return (
    <hr
      style={{
        borderColor: 'transparent',
        height: 0,
        borderBottomColor: COLOR.GRAY_BORDER
      }}
    />
  );
};
