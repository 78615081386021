import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import withHeader from '../../presentation/withHeader';
import {Grid, Typography} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {getUserProfile, getBusinessProfile} from '../../store/profile/actions';
import MyButton from '../../presentation/button';
import {PageHeader} from '../../presentation/withHeader';
import CONSTANT from '../../config/constant';

const UserProfile: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const userid = useAppSelector((state) => state.auth.wallet);
  const userProfile = useAppSelector((state) => state.user.userProfileData);
  const [userLabels, setUserLabels] = useState(CONSTANT.EN_LABELS);
  const EDIT_USER_PROFILE = '/private/profile/users/edit';
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  useEffect(() => {
    dispatch(getUserProfile(userid));
    dispatch(getBusinessProfile());
  }, []);
  useEffect(() => {
    if (businessProfile.businessCountry === 'Argentina') {
      setUserLabels(CONSTANT.ES_LABELS);
    }
  }, [businessProfile]);

  return (
    <PageHeader
      config={{title: userLabels.viewUserProfile, margin: 60, back: false}}>
      <Grid container direction="row">
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {userLabels.fullName}
          </Typography>
          <Typography variant="h6" style={{marginTop: 8}}>
            {userProfile?.name}
          </Typography>
        </Grid>
        <Grid item container xs={6} direction="column" style={{marginTop: 32}}>
          <Typography variant="h6" style={{fontWeight: 600}}>
            {userLabels.phone}
          </Typography>
          <Typography variant="h6" style={{marginTop: 8}}>
            {userProfile?.mobileNumber}
          </Typography>
        </Grid>
        <Grid item container xs={6} direction="column" style={{marginTop: 32}}>
          <Typography variant="h6" style={{fontWeight: 600}}>
            {userLabels.emailAddress}
          </Typography>
          <Typography variant="h6" style={{marginTop: 8}}>
            {userProfile?.emailAddress}
          </Typography>
        </Grid>
        <Grid item container xs={6} direction="column" style={{marginTop: 32}}>
          <Typography variant="h6" style={{fontWeight: 600}}>
            {userLabels.signature}
          </Typography>
          <img
            style={{marginTop: 8, width: '100%'}}
            src={userProfile?.signature}
            alt=""
          />
        </Grid>
        <Grid item container justify="flex-end" style={{marginTop: 300}}>
          <MyButton
            text={userLabels.edit}
            variant="contained"
            onClick={() => history.push(EDIT_USER_PROFILE)}
          />
        </Grid>
      </Grid>
    </PageHeader>
  );
};

export default UserProfile;
