import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {useHistory, useRouteMatch} from 'react-router-dom';

import {fetchCSV} from '../../store/asset/actions';

import SearchBar from '../../presentation/SearchBar';
import MyButton from '../../presentation/button';
import {HyperLink} from '../../presentation/word';
import MyTable from '../../presentation/Table';
import withHeader from '../../presentation/withHeader';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import {makeStyles, Grid} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {toggleModal} from '../../store/modal/actions';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../../store/spinner/types';
import {WebErrorType} from '../../utils/error';
import {getLocation} from '../../store/add/actions';

const data = ['CSV File', 'No. of Assets', 'Inducted On'];

const useStyles = makeStyles((_) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  searchBarContainer: {
    flexGrow: 1,
    marginRight: 50,
  },
  buttonContainer: {
    marginTop: 6,
    marginRight: 2,
  },
}));

const AssetInductionLibrary: React.FC<{}> = () => {
  const classes = useStyles();
  const userid = useAppSelector(state => state.auth.wallet);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {path} = useRouteMatch();
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [query, setQuery] = useState('');
  useEffect(() => {
    (async () => {
      try {
        dispatch({type: SPINNER_TOGGLE_ON});

        const [{activities}] = await fetchCSV(userid);
        setTableData(
          activities
          .filter(({type}: {type: string}) => type === 'uploadcsv')
          .map((activity: {details: any, type: string}) =>
            Object.assign(activity.details, {type: activity.type}),
          ),
        );
      } catch (e) {
        const error = e as WebErrorType;
        if (error.message === 'E_USER_NOT_FOUND') return; // TODO
        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          }),
        );
      } finally {
        dispatch({type: SPINNER_TOGGLE_OFF});
      }
    })();
    dispatch(getLocation());
  }, []);

  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label="Search by CSV File Name"
          />
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <MyButton
            text={'Create New'}
            variant="outlined"
            width={160}
            fontSize={18}
            startIcon={<AddCircleIcon />}
            onClick={() => history.push(`${path}/new`)}
          />
        </Grid>
      </Grid>
      {tableData && (
        <MyTable
          heads={data}
          rows={tableData
            ?.filter((row) => row.filename.includes(query))
            .map(({type, tokenAmount, filename, csvlink, createdAt}) => [
              <HyperLink
                href={csvlink}
                style={type === 'uploadcsvError' ? {color: 'red'} : {}}>
                {filename}
              </HyperLink>,
              tokenAmount,
              moment(createdAt).format('Do MMMM YYYY, hh:mm a'),
            ])}
        />
      )}
    </>
  );
};

export default withHeader(
  {
    title: 'Asset Induction Library',
  },
  AssetInductionLibrary,
);
