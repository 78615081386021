export interface locationType {
  LPAUserID?: string;
  LPAPassword?: string;
  NLISUserID?: string;
  NLISPassword?: string;
  locationNickname: string;
  PICAddress?: string;
  locationId?: string;
  country?: string;
  address: string;
  postcode: string;
  town: string;
  state: string;
}

export interface locationState {
  readonly location: locationType[];
}

export const IMPORT_LOCATION = 'IMPORT_LOCATION';
export const DROP_LOCATION = 'DROP_LOCATION';

interface importLocation {
  type: typeof IMPORT_LOCATION;
  payload: locationType[];
}

interface dropLocation {
  type: typeof DROP_LOCATION;
}

export type locationTypes =
  importLocation |
  dropLocation;