// React
import React, {useState, useEffect} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
// Material UI Imports
import {makeStyles, Grid} from '@material-ui/core';
import Button from '@material-ui/core/Button';
// Other packages
import moment from 'moment';

// Presentation, Container, Styles
import COLOR from '../../styled/colors';
import withHeader from '../../presentation/withHeader';
import SearchBar from '../../presentation/SearchBar';
import MyButton from '../../presentation/button';
import MyTable from '../../presentation/Table';
import {HyperLink} from '../../presentation/word';
// Redux
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {
  fetchAssetProfiles,
  manageArchiveAssetProfile,
} from '../../store/assetProfile/actions';
import {RawAssetProfile} from '../../store/assetProfile/types';

interface Props {}

const tableHeaders = ['Asset Name', 'Created by', 'Created on', 'Actions'];
const CREATE_ASSET_PROFILE_PATH = '/private/assets/assetProfile/new';
const VIEW_ASSET_PROFILE_PATH = '/private/assets/assetProfile/view';

const ArchivedAssetProfiles: React.FC<Props> = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  // States
  const [query, setQuery] = useState('');
  const assetProfileList: Array<RawAssetProfile> = useAppSelector(
    (state) => state.assetProfile.fetchedAssetProfiles,
  );

  // Side Effects
  useEffect(() => {
    dispatch(fetchAssetProfiles());
  }, [dispatch]);

  const unarchiveAssetProfile = (assetProfile: RawAssetProfile) => {
    dispatch(
      manageArchiveAssetProfile(assetProfile, false, () => {
        dispatch(fetchAssetProfiles()); // refetch data
      }),
    );
  };

  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label="Search Asset Profile"
          />
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <MyButton
            text={'Create New'}
            variant="contained"
            width={160}
            fontSize={18}
            onClick={() => history.push(CREATE_ASSET_PROFILE_PATH)}
          />
        </Grid>
      </Grid>

      <MyTable
        heads={tableHeaders}
        rows={assetProfileList
          ?.filter((assetProfile) => assetProfile.details.archived)
          .filter((assetProfile) =>
            assetProfile.details.assetName.includes(query),
          )
          .map((assetProfile, index) => [
            <Button
              onClick={() =>
                history.push(
                  `${VIEW_ASSET_PROFILE_PATH}/${
                    Object.values(assetProfile.externalIds)[0].agliveToken
                  }`,
                )
              }>
              <span
                style={{color: COLOR.GREENT_TEXT, textTransform: 'capitalize'}}>
                <u>{assetProfile.details.assetName}</u>
              </span>
            </Button>,
            assetProfile.details.createdBy,
            moment(assetProfile.externalIds[0].timestamp).format(
              'DD/MM/YYYY',
            ),
            <div style={{display: 'flex', marginRight: -100}}>
              <div style={{marginRight: 20}}>
                <HyperLink
                  onClick={(e) => {
                    e.preventDefault();
                    unarchiveAssetProfile(assetProfile);
                  }}
                  href="">
                  Unarchive
                </HyperLink>
              </div>
            </div>,
          ])}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  searchBarContainer: {
    flexGrow: 1,
    marginRight: 50,
  },
  buttonContainer: {
    marginTop: 6,
    marginRight: 2,
  },
}));

export default withHeader(
  {
    title: 'Archived Asset Profiles',
    back: true,
  },
  ArchivedAssetProfiles,
);
