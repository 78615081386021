import {modalState, MODAL_TOGGLE_ON, MODAL_TOGGLE_OFF} from './types';

export const initialState: modalState={
  isOpen: false,
  status: 'success',
  title: '',
  subtitle: '',
  button: '',
  renderButton: undefined,
  aria: {
    label: '',
    describe: ''
  },
  CTAHandler: () => {}
}

export default function modalReducer(
  state = initialState,
  action
): modalState {
  switch(action.type) {
    case MODAL_TOGGLE_ON:
      return {
        ...state,
        ...action.payload,
      };
    case MODAL_TOGGLE_OFF:
      return {
        ...initialState,
        status: state.status
      };
    default:
      return state;
  }
}
