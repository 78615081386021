import React, {useState, useEffect, useRef, useCallback} from 'react';
import produce from 'immer';
import styled from 'styled-components';
import {Link, useRouteMatch} from 'react-router-dom';

import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';

import DashboardCard from './DashboardCard';
import DashboardDropDownWidget from './DashboardDropdown';
import DashboardMultiDropDownWidget from './DashboardMultiDropdown';
import DashboardTableWidget from './DashboardTable';
import DashboardChartBarWidget from './DashboardChartBar';
import DashboardChartPieWidget from './DashboardChartPie';

import COLOR from '../../styled/colors';
import CONSTANT from '../../config/constant';
import {callAPI} from '../../utils/network';

import {fetchSiteTokens} from '../../store/site/actions';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON} from '../../store/spinner/types';
import configSelector from './dashboardConfig';
import {effectiveWidgets} from './dashboardConfig/utils';

import {
  DashboardConfigType,
  DashboardWidgetType,
} from './dashboardConfig/types';

type AngusMobData = {
  sire_id: string;
  sire_group_id: string;
  acct_id: string; //"HLG",
  birth_year: number; // 2018
  birth_start: string; // "2018-07",
  birth_end: string; // "2018-08",
  pic: string; // "3CETP109",
  mob_name: string; // "AI GROUP",
  mob_sex: string; // "Female",
  calves: {
    add: Array<{
      rfid: string; // "982 123539397549",
      sex: string; // "Female",
      visual_tag: string; // "",
      nlis_id: string; // "3CETP109XBN11165"
      weight: number;
    }>;
    remove: Array<{
      rfid: string; // "982 123539397549",
      sex: string; // "Female",
      visual_tag: string; // "",
      nlis_id: string; // "3CETP109XBN11165"
    }>;
  };
  breederPicAddress: string; // "3CETP109",
  businessId: string; // "b2e42f9d-2d82-405f-991b-ce1347522696",
  recordedIn: string; // "HLG-NORF857"
};

type AngusMobResponse = {
  externalIds: Array<{
    agliveToken: string;
    timestamp: string;
  }>;
  type: 'angusMob';
  details: AngusMobData;
  activities: Array<{
    type: 'UP_details';
    details: AngusMobData;
  }>;
};

type DashboardDataRow = {
  identifier: string;
  species: string;
  breed: string;
  age?: number;
  sex: string;
  pic?: string;
  weight?: number;
};

type DashboardGroupRow = {
  name: string;
  pic: string;
  list: Array<DashboardDataRow>;
};

type DashboardDropdownStateType = {
  value: string | string[];
  show: boolean;
};

// This is the master schema which all widget should accept and work on
// please make sure all widgets accept this schema only
// update the schema if more information is needed to be passed into the widget
export type DashboardWidgetInputSchema = Array<{
  value: string | number;
  attribute: {
    label: string;
    key: string;
    [key: string]: string;
  };
}>;

const makeInitialDashboardState = (dashboardConfig: DashboardConfigType) => {
  let INITIAL_DASHBOARD_STATE: {
    state: Record<string, DashboardDropdownStateType>;
    data: Record<string, any>;
  } = {
    state: {},
    data: {},
  };

  dashboardConfig.rows.forEach((row) => {
    effectiveWidgets(row.widgets).forEach((widget) => {
      switch (widget.type) {
        case DashboardWidgetType.DROPDOWN:
        case DashboardWidgetType.MULTIDROPDOWN:
          INITIAL_DASHBOARD_STATE.state[widget.payload.key] = {
            value: widget.type === DashboardWidgetType.MULTIDROPDOWN ? [] : '',
            show: false,
          };
          break;
      }
    });
  });

  return INITIAL_DASHBOARD_STATE;
};

const useDataManager = () => {
  const dispatch = useAppDispatch();
  const {path} = useRouteMatch();

  // client information
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const userProfile = useAppSelector((state) => state.user.userProfileData);
  const locationSet = useAppSelector((state) => state.location.location);
  const siteTokens = useAppSelector((state) => state.site);
  const userid = useAppSelector((state) => state.auth.wallet);
  useEffect(() => {
    dispatch(fetchSiteTokens());
  }, []);

  // defer renderring until everything is loaded in
  useEffect(() => {
    if (!businessProfile.companyName) {
      return
    }
    if (userProfile.externalIds?.userId !== userid) {
      return;
    }

    const init = async () => {
      if (!userProfile.businessId) return;

      // compute the correct dashboardConfig to use
      let client = undefined;
      if (
        businessProfile.industryType === 'ANIMALS' &&
        !!businessProfile.angusProfile
      ) {
        client = 'Angus';
      }

      if (userProfile.businessId === CONSTANT.WARAKIRRI_BUSINESS_ID) {
        client = 'Warakirri';
      }

      const dashboardConfig = configSelector(
        businessProfile.businessCountry,
        client,
      );

      // initialize states after config is loaded
      setDashboardConfig(dashboardConfig);
      setDashboardState(makeInitialDashboardState(dashboardConfig));
      setInitialDashboardStateData(makeInitialDashboardState(dashboardConfig));
    };

    init();
  }, [userProfile.businessId, businessProfile.companyName, userid]);

  const [dashboardConfig, setDashboardConfig] = useState<DashboardConfigType>();

  const [dashboardState, setDashboardState] = useState<{
    state: Record<string, DashboardDropdownStateType>;
    data: Record<string, any>;
  }>();
  const [initialDashboardStateData, setInitialDashboardStateData] = useState<{
    data: Record<string, any>;
    state: Record<string, DashboardDropdownStateType>;
  }>();
  const [refetchList, setRefetchList] =
    useState<Record<string, Array<{key: string; source: any}>>>();
  const prevDashboardState =
    useRef<Record<string, DashboardDropdownStateType>>();

  // first fetch and build dependency map Record<stateKey, Array<source functions that need the fresh state value to callAPI>>
  // TODO: for same requests the same key is used to prevent repetitive fetching
  // However the code is not tested if it fetched multiple times on the same thing and would need a deep comparison of return value of source function before callAPI to be sure
  useEffect(() => {
    if (!dashboardConfig) return;

    dispatch({type: SPINNER_TOGGLE_ON});

    // fetch required data according to config
    const init = async () => {
      let dependentRequests: Record<
        string,
        Array<{key: string; source: any}>
      > = {};
      
      let responses: {[key:string]: any} = {};
      for (const {widgets} of dashboardConfig.rows) {
        for (const {payload} of effectiveWidgets(widgets)) {
          if (payload.key && !responses[payload.key] && payload.source) {
            let request;
            switch (typeof payload.source) {
              case 'function':
                request = payload.source(
                  dashboardState.data,
                  dashboardState.state,
                );

                payload.dependentKey.forEach((key) => {
                  if (!dependentRequests[key]) {
                    dependentRequests[key] = [
                      {
                        key: payload.key,
                        source: payload.source,
                      },
                    ];
                  } else {
                    dependentRequests[key].push({
                      key: payload.key,
                      source: payload.source,
                    });
                  }
                });

                break;
              case 'object':
                request = payload.source;
                break;
              case 'string':
                switch (payload.source) {
                  case 'location':
                    responses[payload.key] = locationSet;
                    break;
                  case 'site':
                    responses[payload.key] = [...siteTokens]?.sort(
                      (token, token2) => {
                        return token.details.siteName > token2.details.siteName
                          ? 1
                          : -1;
                      },
                    );
                    break;
                }
                break;
            }

            if (request) {
              const res = await callAPI(request);

              responses[payload.key] = res;
            }

          }
        }
      };

      setRefetchList(dependentRequests);

      setDashboardState((prevStore) => 
        produce(prevStore, (draft) => {
          Object.entries(responses).forEach(([key, res]) => {
            draft.data[key] = res;
          });

          draft.data.path = path;
        }),
      );
      setInitialDashboardStateData((prevStore) =>
        produce(prevStore, (draft) => {
          Object.entries(responses).forEach(([key, res]) => {
            draft.data[key] = res;
          });
          draft.data.path = path;
        }),
      );

      dispatch({type: SPINNER_TOGGLE_OFF});
    };

    init();
  }, [dashboardConfig, siteTokens]);

  useEffect(() => {
    if (!dashboardState || !dashboardConfig) return;

    if (
      Object.values(dashboardState?.state).every(
        (state) =>
          state.value === '' &&
          Object.keys(initialDashboardStateData.data).length > 0,
      )
    ) {
      setDashboardState((prevStore) =>
        produce(prevStore, (draft) => {
          draft.data = initialDashboardStateData.data;
        }),
      );

      prevDashboardState.current = dashboardState.state;
      return;
    }
    
    const refetch = async () => {
      // this is first render
      if (!prevDashboardState.current) return;

      dispatch({type: SPINNER_TOGGLE_ON});
      try {
        const responses = await Promise.all(
          Object.entries(refetchList)
            .filter(
              ([dependentKey]) =>
                prevDashboardState.current[dependentKey].value !==
                dashboardState.state[dependentKey].value,
            )
            .map(([, requests]) =>
              requests.map(({key, source}) =>
                callAPI(source(dashboardState.data, dashboardState.state)).then(
                  (res) => ({
                    [key]: res,
                  }),
                ),
              ),
            )
            .flat(),
        );
        setDashboardState((prevStore) =>
          produce(prevStore, (draft) => {
            responses.forEach((response) => {
              const [key, res] = Object.entries(response)[0];
              draft.data[key] = res;
            });
          }),
        );
      } catch (e) {
        console.warn('Failed to refetch resources')
        console.error(e);
      } finally {
        dispatch({type: SPINNER_TOGGLE_OFF});
      }
    };

    refetch();
    prevDashboardState.current = dashboardState.state;
  }, [dashboardState?.state]);

  // default to select 1st items
  // useEffect(() => {
  //   if (locationPicAddr.length && (mobs.length || !hasAngus)) {
  //     setDashboardState((prevState) =>
  //       produce(prevState, (draft) => {
  //         draft.picAddress.value = locationPicAddr[0].value;
  //         if (mobs.length) {
  //           draft.mobName.value = '0';
  //         }
  //       }),
  //     );
  //   }
  // }, [mobs, hasAngus]);

  return [dashboardConfig, dashboardState, setDashboardState] as const;
};

const Dashboard: React.FC<{}> = () => {
  const {isOpen: isSpinnerOpen} = useAppSelector((state) => state.spinner);

  // TESTZONE
  const [dashboardConfig, dashboardState, setDashboardState] = useDataManager();

  const handleDropdownValue = useCallback(
    (key: string, value: any) => {
      setDashboardState((prevState) =>
        produce(prevState, (draft) => {
          draft.state[key].value = value;
        }),
      );
    },
    [setDashboardState],
  );

  const resetBirthYear = useCallback(() => {
    handleDropdownValue('birthYear', '');
    setDashboardState((prevState) =>
      produce(prevState, (draft) => {
        draft.data.birthYear = '';
      }),
    );
  }, [handleDropdownValue, setDashboardState]);

  const handleResetFilter = useCallback(
    (key: string) => {
      setDashboardState((prevState) =>
        produce(prevState, (draft) => {
          if (Array.isArray(draft.state[key].value)) {
            draft.state[key].value = [];
          } else {
            draft.state[key].value = '';
          }
        }),
      );
      //resetBirthYear();
    },
    [setDashboardState],
  );

  const handleDropdownShow = useCallback(
    (key: string, show: boolean) => () => {
      setDashboardState((prevState) =>
        produce(prevState, (draft) => {
          draft.state[key].show = show;
        }),
      );
      resetBirthYear();
    },
    [resetBirthYear, setDashboardState],
  );

  const findNonZeroSeason = useCallback((birthYear) => {
    const firstNonZero = birthYear.find(({_id, ...stat}) =>
      Object.values(stat).reduce((sum, curVal) => sum + curVal, 0),
    );
    return `${firstNonZero._id.season}_${firstNonZero._id.year}`;
  }, []);

  useEffect(() => {
    if (
      dashboardState?.state.birthYear?.value ||
      !dashboardState?.data?.birthYear?.length
    )
      return;
    const firstNonZeroSeason = findNonZeroSeason(
      dashboardState?.data?.birthYear,
    );
    handleDropdownValue('birthYear', firstNonZeroSeason);
  }, [
    dashboardState?.state.birthYear?.value,
    dashboardState?.data?.birthYear,
    handleDropdownValue,
    findNonZeroSeason,
  ]);

  if (!dashboardConfig) return null;

  return (
    <Grid>
      {dashboardConfig.rows.map((row) => (
        <Grid
          item
          container
          spacing={2}
          alignContent="space-between"
          style={{
            marginBottom: 30,
            // flexWrap: 'nowrap',
          }}>
          {row.widgets.map((widget, index, widgets) => {
            switch (widget.type) {
              case DashboardWidgetType.CARD:
                if (!dashboardState.data[widget.payload.key]) return <></>;
                return (
                  <Grid item container xs={4}>
                    <DashboardCard
                      title={widget.payload.title}
                      subtitle={widget.payload.subtitle}
                      icon={widget.payload.icon}
                      cardContent={widget.payload.transformData(
                        dashboardState.data,
                        dashboardState.state,
                      )}
                      sizing={widget.payload.sizing}
                    />
                  </Grid>
                );
              case DashboardWidgetType.DROPDOWN:
                if (!dashboardState.data[widget.payload.key]) return <></>;

                return (
                  <Grid
                    item
                    style={{
                      marginRight: index === widgets.length - 1 ? 0 : 60,
                      width: `${Math.min(
                        Math.round((1 * 100) / widgets.length) - 3,
                        47,
                      )}%`,
                    }}>
                    <DashboardDropDownWidget
                      clearFilter={() => {
                        handleResetFilter(widget.payload.key);
                      }}
                      title={widget.payload.title}
                      key={widget.payload.key}
                      //open={dashboardState.state[widget.payload.key].show}
                      //onClose={handleDropdownShow(widget.payload.key, false)}
                      //onOpen={handleDropdownShow(widget.payload.key, true)}
                      value={dashboardState?.state[widget.payload.key].value}
                      onChange={(
                        e: React.ChangeEvent<{name?: string; value: any}>,
                      ) =>
                        handleDropdownValue(widget.payload.key, e.target.value)
                      }
                      options={widget.payload.transformData(
                        dashboardState.data,
                        dashboardState.state,
                      )}
                    />
                  </Grid>
                );
                case DashboardWidgetType.MULTIDROPDOWN:
                  if (!dashboardState.data[widget.payload.key]) return <></>;
  
                  return (
                    <Grid
                      item
                      style={{
                        marginRight: index === widgets.length - 1 ? 0 : 60,
                        width: `${Math.min(
                          Math.round((1 * 100) / widgets.length) - 3,
                          47,
                        )}%`,
                      }}>
                      <DashboardMultiDropDownWidget
                        clearFilter={() => {
                          handleResetFilter(widget.payload.key);
                        }}
                        title={widget.payload.title}
                        key={widget.payload.key}
                        //open={dashboardState.state[widget.payload.key].show}
                        //onClose={handleDropdownShow(widget.payload.key, false)}
                        //onOpen={handleDropdownShow(widget.payload.key, true)}
                        value={dashboardState?.state[widget.payload.key]?.value}
                        onChange={(value: any) => {
                          handleDropdownValue(widget.payload.key, value);
                        }}
                        options={widget.payload.transformData(
                          dashboardState.data,
                          dashboardState.state,
                        )}
                        />
                    </Grid>
                  );
              case DashboardWidgetType.TABLE:
                if (!dashboardState.data[widget.payload.key]) return <></>;

                return (
                  <DashboardTableWidget
                    columns={widget.payload.columns}
                    data={widget.payload.transformData(
                      dashboardState.data,
                      dashboardState.state,
                    )}
                    payload={widget.payload}
                    dashboardState={dashboardState}
                  />
                );
              case DashboardWidgetType.BAR:
                if (!dashboardState.data[widget.payload.key]) return <></>;

                return (
                  <DashboardChartBarWidget
                    title={widget.payload.title}
                    data={widget.payload.transformData(dashboardState.data)}
                    xLabel={widget.payload.xLabel}
                    yLabel={widget.payload.yLabel}
                    xAxis={widget.payload.xAxis}
                    legend={widget.payload.legend}
                    bars={widget.payload.bars}
                  />
                );
              case DashboardWidgetType.PIE:
                if (!dashboardState.data[widget.payload.key]) return <></>;

                return (
                  <DashboardChartPieWidget
                    title={widget.payload.title}
                    data={widget.payload.transformData(dashboardState.data)}
                    legend={widget.payload.legend}>
                    {widget.payload.composition &&
                      widget.payload.composition.map((nestedWidget) => (
                        <Grid
                          item
                          container
                          style={{width: 250}}
                          justifyContent="center">
                          <FormControl
                            variant="outlined"
                            style={{width: 200, marginTop: 20}}>
                            {/* <InputLabel
                                id="dashboard-select-filled-label"
                                style={{fontSize: 14}}>
                                Please select
                              </InputLabel> */}{' '}
                            <Select
                              labelId="dashboard-select-filled-label"
                              style={{height: 40, fontSize: 14}}
                              /*open={
                                dashboardState.state[nestedWidget.payload.key]
                                  .show
                              }*/
                              /*onClose={handleDropdownShow(
                                nestedWidget.payload.key,
                                false,
                              )}
                              onOpen={handleDropdownShow(
                                nestedWidget.payload.key,
                                true,
                              )}*/
                              value={
                                dashboardState?.state[nestedWidget.payload.key]
                                  .value
                              }
                              onChange={(
                                e: React.ChangeEvent<{
                                  name?: string;
                                  value: any;
                                }>,
                              ) =>
                                handleDropdownValue(
                                  nestedWidget.payload.key,
                                  e.target.value,
                                )
                              }>
                              {nestedWidget.payload
                                .transformData(dashboardState.data)
                                .map((option, index) => (
                                  <MenuItem
                                    value={option.value}
                                    key={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      ))}
                  </DashboardChartPieWidget>
                );
              default:
                return <></>;
            }
          })}
        </Grid>
      ))}
    </Grid>
  );
};

export default Dashboard;

const HerdContainer: React.FC<{title: string; price: string}> = (props) => (
  <Grid style={{marginTop: -20}}>
    {' '}
    {/* HACK */}
    <HerdValueTitle>{props.title}</HerdValueTitle>
    <HerdValueContainer>
      <HerdValue>{props.price} c/kg</HerdValue>
    </HerdValueContainer>
  </Grid>
);

const HerdValueTitle = styled(Typography)`
  font-weight: 700;
  font-size: 14px;
`;

const HerdValueContainer = styled(Grid)`
  border: 1px solid ${COLOR.GRAY_BORDER};
  width: 163px;
  height: 40px;
  text-align: center;
`;

const HerdValue = styled(Typography)`
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  color: ${COLOR.BLACK_BG};
  margin-top: 7px;
`;
