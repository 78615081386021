import { WebErrorType, WebError, NetworkError, WEB_ERROR } from './error';


//Checks the file is valid (e.g. file still exists, still same file (as file cannot be modified since uploading))
export async function checkFileValid (file: File) {
    //Get first byte & try to obtain data with arrayBuffer()
    try {
        //Get first byte & try to obtain data with arrayBuffer()
        await file.slice(0, 1).arrayBuffer()
    } catch (err) {
        if(err?.name && err.name === "NotReadableError") {
            throw new WebError("FILE_MODIFIED_ERROR")
        } else if (err?.name && err.name === "NotFoundError") {
            throw new WebError("FILE_NOT_FOUND_ERROR")
        } else {
            throw err
        }
    }
}