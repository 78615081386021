import React, {
  useEffect,
  useCallback,
  useReducer,
  useMemo,
  useState,
} from 'react';
import COLOR from '../../styled/colors';
import {useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import withHeader from '../../presentation/withHeader';
import {
  makeStyles,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Checkbox,
  Box,
  MuiThemeProvider,
} from '@material-ui/core';
import {createTheme} from '@material-ui/core/styles';
import MyButton from '../../presentation/button';
import CONSTANT from '../../config/constant';
import moment from 'moment';
import {fetchFormTemplates, saveFormTemplate} from '../../store/form/actions';
import {FormAnswers, FormType, FormDoc} from '../../store/form/types';
import {ExpandMore, ExpandLess, CheckCircle} from '@material-ui/icons';
import FormTemplate from '../asset/FormTemplate';
import CONSTANTS from '../../config/constant';
import CSVUploader from '../../presentation/CSVUploader';
import {HyperLink} from '../../presentation/word';
import {fetchGroupProjects, fetchAllAssets} from '../../store/activity/actions';
import {SelectionState} from '@devexpress/dx-react-grid';
import {
  Table,
  TableHeaderRow,
  TableSelection,
  Grid as TableGrid,
  PagingPanel,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';
import {
  PagingState,
  IntegratedSelection,
  CustomPaging,
} from '@devexpress/dx-react-grid';
import API from '../../config/api';
import FilterList from '@material-ui/icons/FilterList';
import {getMobsByBusiness} from '../../store/angus/actions';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {fileUploader} from '../../store/product/actions';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import PhoneInput, {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';
import {MobileInput} from '../../container/add/AddEditUser';
import {
  createConsignment,
  editConsignment,
  submitConsignment,
  fetchConsigneeData,
  fetchConsignmentData,
} from '../../store/add/actions';
import {validateWithSchema} from '../../utils/csvReader';
import {consigmentAnimalsSchema, uniqueIds} from '../../config/schema';
import {Buttons} from '../../presentation/ButtonsGroup';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import {
  TableComponent,
  PagingComponent,
  HeaderComponent,
} from './ViewConsignment';
import {SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON} from '../../store/spinner/types';
import {ConsignmentTypes} from '../../store/add/types';
import {BusinessMisc} from '@aglive/data-model';

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  formContainer: {
    marginTop: 50,
  },
  formTitleContainer: {
    justifyContent: 'space-between',
    marginTop: 35,
  },
  formCardContainer: {
    backgroundColor: COLOR.GRAY_SOLID,
    marginTop: 25,
    padding: 10,
  },
  buttonContainer: {
    justifyContent: 'end',
    marginTop: 50,
  },
  label: {
    textTransform: 'none',
  },
  dividerStyle: {
    width: '100%',
    marginTop: 10,
  },
  boxDividerStyle: {width: '100%'},
  IconChecked: {
    color: COLOR.GREEN_BUTTON,
  },
  IconUnchecked: {
    color: COLOR.GRAY_ICON,
  },
  filterIcon: {
    height: 28,
    width: 25,
    color: COLOR.GREENT_TEXT,
  },
  filterButton: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  filterColor: {backgroundColor: COLOR.WHITE},
  tableContainer: {
    backgroundColor: COLOR.GRAY_SOLID,
  },
  attachField: {
    marginLeft: 15,
    marginTop: 10,
    marginRight: 15,
  },
  shareAddFiled: {
    marginTop: 20,
  },
  checkbox: {
    '&.MuiCheckbox-root': {
      '&.MuiCheckbox-colorPrimary': {
        color: COLOR.GREEN_BUTTON,
      },
    },
  },
  shareToDelete: {
    cursor: 'pointer',
    color: COLOR.GRAY_TEXT,
    marginTop: 30,
    marginLeft: 20,
  },
  shareToMobile: {
    width: '100%',
    marginTop: 20,
  },
  draftButton: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    marginRight: 20,
  },
}));

const initialState = {
  envdAnswers: {} as FormAnswers,
  hdAnswers: {} as FormAnswers,
  forms: {},
  currentAgToken: '',
  envdExpand: false,
  hdExpand: false,
  envdMode: 'EDIT' as 'VIEW' | 'EDIT',
  hdMode: 'EDIT' as 'VIEW' | 'EDIT',
  envdCompleted: false,
  hdCompleted: false,
  selectedEnvdForm: '' as string,
  selectedHdForm: '' as string,
  selectedConsigneeIndex: '',
  destinationPICRadio: '',
  destinationPIC: '',
  address: '',
  postcode: '',
  town: '',
  selectedState: '',
  consignmentName: '',
  signDeclarationChecked: false,
  selectAnimalsRadio: '',
  selectedGroupIndex: '',
  groupMobList: [],
  csvData: {} as {[key: string]: any},
  csvAsset: [],
  animalList: [],
  currentPage: 0,
  pageSize: 10,
  animalSelectedIndex: [] /**can only save index on current page */,
  selectedAnimalInfo: [] /**save all selected animal info */,
  totalData: 0,
  showFilters: false,
  mobList: [],
  picList: [],
  animalFilters: {} as {[key: string]: any},
  attachments: [],
  emailList: [''],
  mobileList: [''],
  validationList: {email: [{helperText: ''}], mobile: [{helperText: ''}]},
  wrongRfids:
    [] /**fetch rfids that no longer belong to this onwer when sumbit the consignment */,
  isSubmit: false,
  addNewEnvd: false,
  addNewHd: false,
  envdFormName: '',
  hdFormName: '',
  firstMobile: '',
  deleteFistMobile: false,
  existingConsignmentData: undefined as ConsignmentTypes,
};

type Action =
  | {type: 'update/envdAnswers'; envdAnswers: FormAnswers}
  | {type: 'update/hdAnswers'; hdAnswers: FormAnswers}
  | {type: 'change/envdExpand'; envdExpand: boolean}
  | {type: 'change/currentAgliveToken'; currentAgToken: string}
  | {type: 'change/consignmentData'; existingConsignmentData: ConsignmentTypes}
  | {type: 'set/forms'; forms: {[key: string]: any}}
  | {type: 'change/hdExpand'; hdExpand: boolean}
  | {type: 'change/envdMode'; envdMode: 'VIEW' | 'EDIT'}
  | {type: 'change/hdMode'; hdMode: 'VIEW' | 'EDIT'}
  | {type: 'change/envdCompleted'; envdCompleted: boolean}
  | {type: 'change/hdCompleted'; hdCompleted: boolean}
  | {type: 'change/selectedEnvdForm'; selectedEnvdForm: string}
  | {type: 'change/selectedHdForm'; selectedHdForm: string}
  | {type: 'change/selectedConsignee'; selectedConsigneeIndex: string}
  | {
      type: 'change/destinationPICRadio';
      destinationPICRadio: 'usePIC' | 'newPIC';
    }
  | {type: 'change/destinationPIC'; destinationPIC: string}
  | {type: 'change/address'; address: string}
  | {type: 'change/postcode'; postcode: string}
  | {type: 'change/town'; town: string}
  | {type: 'change/selectedState'; selectedState: string}
  | {type: 'change/consignmentName'; consignmentName: string}
  | {type: 'change/signDeclarationChecked'; signDeclarationChecked: boolean}
  | {type: 'change/selectAnimalsRadio'; selectAnimalsRadio: string}
  | {
      type: 'change/selectedGroupIndex';
      selectedGroupIndex: string;
      csvData: {file: null};
    }
  | {
      type: 'change/csvData';
      csvData: {name: string; link: string; file: File};
      selectedGroupIndex: string;
    }
  | {type: 'change/csvAsset'; csvAsset: Array<any>}
  | {type: 'fetch/animalList'; animalList: Array<any>; totalData: number}
  | {
      type: 'fetch/groupMobList';
      groupMobList: Array<{name: string; id: string}>;
    }
  | {type: 'change/page'; currentPage: number}
  | {type: 'change/pageSize'; pageSize: number}
  | {type: 'change/animalSelectedIndex'; animalSelectedIndex: Array<number>}
  | {type: 'change/selectedAnimalInfo'; selectedAnimalInfo: Array<any>}
  | {type: 'change/showFiltes'; showFilters: boolean}
  | {type: 'update/animalFilters'; animalFilters: {[key: string]: any}}
  | {type: 'update/mobList'; mobList: Array<any>}
  | {type: 'update/picList'; picList: Array<any>}
  | {
      type: 'update/attachments';
      attachments: Array<{name: string; link: string}>;
    }
  | {type: 'update/emailList'; emailList: Array<string>}
  | {
      type: 'update/validationList';
      validationList: {
        email: {helperText: string}[];
        mobile: {helperText: string}[];
      };
    }
  | {type: 'update/mobileList'; mobileList: Array<string>}
  | {type: 'update/firstMobile'; firstMobile: string}
  | {type: 'update/deleteFistMobile'; deleteFistMobile: boolean}
  | {type: 'fetch/wrongRfids'; wrongRfids: Array<string>}
  | {type: 'update/isSubmit'; isSubmit: boolean}
  | {
      type: 'fetch/consignmentInfo';
      selectedConsigneeIndex: string;
      destinationPIC: string;
      address: string;
      postcode: string;
      town: string;
      selectedState: string;
      destinationPICRadio: 'usePIC' | 'newPIC';
      consignmentName: string;
      selectAnimalsRadio: string;
      csvData: {name: string; link: string; file: null} | {file: null};
      csvAsset: Array<any>;
      attachments: Array<{name: string; link: string}>;
      emailList: Array<string>;
      mobileList: Array<string>;
      selectedGroupIndex: string;
      signDeclarationChecked: boolean;
      validationList: {
        email: {helperText: string}[];
        mobile: {helperText: string}[];
      };
    }
  | {
      type: 'fetch/formsAnswers';
      envdAnswers: FormAnswers;
      hdAnswers?: FormAnswers;
    }
  | {type: 'change/addNewEnvd'; addNewEnvd: boolean}
  | {type: 'change/addNewHd'; addNewHd: boolean}
  | {type: 'update/envdFormName'; envdFormName: string}
  | {type: 'update/hdFormName'; hdFormName: string};

const reducer = (
  prevState: typeof initialState,
  action: Action,
): typeof initialState => {
  const {type, ...actionData} = action;
  switch (action.type) {
    default:
      return {...prevState, ...actionData};
  }
};

const Consignment: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const {path} = useRouteMatch();
  const {docId} = useParams<{docId: string}>();
  const dispatch = useAppDispatch();
  const [state, localDispatch] = useReducer(reducer, initialState);
  const savedConsignees = useAppSelector((state) => state.add.consigneeData);
  const userId = useAppSelector((state) => state.auth.wallet);
  const groups = useAppSelector((state) => state.activity.groups);
  const STATE_OPTIONS = BusinessMisc.SupportedCountries;
  const consignmentList = useAppSelector((state) => state.add.consignmentData);
  const businessProfileData = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const locations = businessProfileData.location;
  const hasCredentials = React.useMemo(
    () =>
      locations.find(
        (location) =>
          location.LPAPassword !== '' &&
          location.LPAUserID !== '' &&
          location.NLISPassword !== '' &&
          location.NLISUserID !== '',
      )
        ? true
        : false,
    [],
  );

  const isAngus =
    businessProfileData.industryType === 'ANIMALS' &&
    businessProfileData.businessType === 'Angus'
      ? true
      : false;

  const isCattle =
    businessProfileData.industryType === 'ANIMALS' &&
    businessProfileData.subIndustryType === 'Cattle'
      ? true
      : false;
  const businessId = useAppSelector(
    (state) => state.user.userProfileData.businessId,
  );

  const savedEnvdForms = useAppSelector((state) => state.form)
    ?.filter((form) => form.formType === 'LPAC1')
    ?.sort((form1: FormDoc, form2: FormDoc) => {
      return form1.formName > form2.formName ? 1 : -1;
    });
  const savedHdForms = useAppSelector((state) => state.form)
    ?.filter((form) => form.formType === 'HSC2')
    ?.sort((form1: FormDoc, form2: FormDoc) => {
      return form1.formName > form2.formName ? 1 : -1;
    });
  const columns = [
    {name: 'rfid', title: 'RFID'},
    {name: 'nlis', title: 'NLIS ID'},
    {name: 'birthYear', title: 'Birth Year'},
    {name: 'mob', title: 'Mob'},
    {name: 'pic', title: 'PIC'},
  ];

  const [hiddenColumnNames, setHiddenColumnNames] = useState([
    isAngus ? 'pic' : 'mob',
  ]);

  const updateAgliveToken = useCallback(
    (agToken: string, consignmentData?: ConsignmentTypes) => {
      localDispatch({
        type: 'change/currentAgliveToken',
        currentAgToken: agToken,
      });
      if (consignmentData) {
        localDispatch({
          type: 'change/consignmentData',
          existingConsignmentData: consignmentData,
        });
      }
    },
    [],
  );

  /** fetch all individual animals */
  const fetchAssets = useCallback(
    async (
      mobName = '',
      picId = '',
      birthYearStart = null,
      birthYearEnd = null,
      currentPage = state.currentPage,
      itemPerPage = state.pageSize,
      selectedAnimalInfo?: Array<any>,
      updateFilters?: boolean,
    ) => {
      const data = {
        latestDetails: true,
        status: ['exist'],
        type: [isAngus ? 'angusCalve' : 'asset'],
        activityType: [],
        businessId: [businessId],
        page: currentPage + 1,
        itemCountPerPage: itemPerPage,
        filter: {
          mob_name: mobName,
          pic_id: picId,
          birth_year_start: birthYearStart,
          birth_year_end: birthYearEnd,
          isRfidExist: true,
        },
      };
      const response = await fetchAllAssets({
        url: API.POST.getTokenByBusiness,
        method: 'POST',
        data: data,
      });
      const list = [];
      response.data?.map((res) => {
        if (res.details.externalIds.rfid) {
          list.push({
            rfid: res.details.externalIds.rfid,
            nlis: res.details.externalIds.nlis_id ?? '',
            birthYear:
              res.type === 'angusCalve'
                ? res.details.birth_year
                : res.details.date_of_birth
                ? moment(new Date(res.details.date_of_birth)).format('YYYY')
                : '',

            mob: (res.details.mob_name || res.details.pic_group_name) ?? '',
            pic:
              (res.type === 'asset' && res.details.pic_id) ||
              (res.type === 'angusCalve' && res.details.pic),
          });
        }
      });
      localDispatch({
        type: 'fetch/animalList',
        animalList: list,
        totalData: Number(response.headers.total_row),
      });
      /**fetch Selected Animal Index From rfid array*/
      if (updateFilters) return;
      const currentPageIndex = [];
      const rfidList = selectedAnimalInfo
        ? selectedAnimalInfo.map((item) => item.rfid)
        : state.selectedAnimalInfo.map((item) => item.rfid);

      for (let i = 0; i < itemPerPage; i++) {
        if (list[i]?.rfid && rfidList.includes(list[i]?.rfid)) {
          currentPageIndex.push(i);
        }
      }
      localDispatch({
        type: 'change/animalSelectedIndex',
        animalSelectedIndex: currentPageIndex,
      });
    },
    [businessId, state.currentPage, state.pageSize, state.selectedAnimalInfo],
  );

  const changePage = useCallback(
    (pageNum: number) => {
      localDispatch({type: 'change/page', currentPage: pageNum});
      fetchAssets(
        state.animalFilters.mob,
        state.animalFilters.pic,
        state.animalFilters.birthYearFrom
          ? Number(moment(state.animalFilters.birthYearFrom).format('yyyy'))
          : null,
        state.animalFilters.birthYearTo
          ? Number(moment(state.animalFilters.birthYearTo).format('yyyy'))
          : null,
        pageNum,
        state.pageSize,
      );
    },
    [state.animalFilters, state.pageSize, fetchAssets],
  );
  const changePageSize = useCallback(
    (size: number) => {
      localDispatch({type: 'change/pageSize', pageSize: size});
      localDispatch({type: 'change/page', currentPage: 0});
      fetchAssets(
        state.animalFilters.mob,
        state.animalFilters.pic,
        state.animalFilters.birthYearFrom
          ? Number(moment(state.animalFilters.birthYearFrom).format('yyyy'))
          : null,
        state.animalFilters.birthYearTo
          ? Number(moment(state.animalFilters.birthYearTo).format('yyyy'))
          : null,
        0,
        size,
      );
    },
    [state.animalFilters, fetchAssets],
  );
  /**when select animal, add or remove animal in selectedAnimalInfo */
  const selectAnimals = useCallback(
    (index: Array<number>) => {
      if (state.animalSelectedIndex.length > index.length) {
        let diff = state.animalSelectedIndex.filter(
          (item) => !index.includes(item),
        )[0];
        const newArray = state.selectedAnimalInfo.filter(
          (item) => item.rfid !== state.animalList[diff].rfid,
        );
        localDispatch({
          type: 'change/selectedAnimalInfo',
          selectedAnimalInfo: newArray,
        });
      } else {
        let diff = index.filter(
          (item) => !state.animalSelectedIndex.includes(item),
        )[0];
        localDispatch({
          type: 'change/selectedAnimalInfo',
          selectedAnimalInfo: [
            ...state.selectedAnimalInfo,
            state.animalList[diff],
          ],
        });
      }
      localDispatch({
        type: 'change/animalSelectedIndex',
        animalSelectedIndex: index,
      });
    },
    [state.animalSelectedIndex, state.selectedAnimalInfo, state.animalList],
  );
  const changeShowFilter = useCallback(() => {
    localDispatch({
      type: 'change/showFiltes',
      showFilters: !state.showFilters,
    });
    if (!state.showFilters) {
      localDispatch({
        type: 'update/animalFilters',
        animalFilters: {},
      });
    }
  }, [state.showFilters]);
  const handleConsignementNameChange = useCallback(
    (e: React.ChangeEvent<{value: string}>) => {
      localDispatch({
        type: 'change/consignmentName',
        consignmentName: e.target.value,
      });
    },
    [],
  );
  const handleConsigneeChange = useCallback(
    (e: React.ChangeEvent<{value: string}>) => {
      localDispatch({
        type: 'change/selectedConsignee',
        selectedConsigneeIndex: e.target.value,
      });
    },
    [],
  );

  const handleAddress = useCallback((e: React.ChangeEvent<{value: string}>) => {
    localDispatch({
      type: 'change/address',
      address: e.target.value,
    });
  }, []);

  const handlePostCode = useCallback(
    (e: React.ChangeEvent<{value: string}>) => {
      localDispatch({
        type: 'change/postcode',
        postcode: e.target.value,
      });
    },
    [],
  );

  const handleTown = useCallback((e: React.ChangeEvent<{value: string}>) => {
    localDispatch({
      type: 'change/town',
      town: e.target.value,
    });
  }, []);

  const handleSelectedState = useCallback(
    (e: React.ChangeEvent<{value: string}>) => {
      localDispatch({
        type: 'change/selectedState',
        selectedState: e.target.value,
      });
    },
    [],
  );

  const handleEnvdClick = useCallback(() => {
    if (hasCredentials) {
      localDispatch({
        type: 'change/envdExpand',
        envdExpand: !state.envdExpand,
      });
    }
  }, [state.envdExpand]);

  const handleHdClick = useCallback(() => {
    if (hasCredentials) {
      if (!state.envdCompleted) {
        dispatch(
          toggleModal({
            status: 'warning',
            title: '',
            subtitle: 'Please fill eNVD form first.',
          }),
        );
        return;
      }
      localDispatch({
        type: 'change/hdExpand',
        hdExpand: !state.hdExpand,
      });
    }
  }, [state.hdExpand, state.envdCompleted]);

  const handleSeletedEnvd = useCallback(
    (e: React.ChangeEvent<{value: string; name?: string}>) => {
      localDispatch({
        type: 'change/selectedEnvdForm',
        selectedEnvdForm: e.target.value,
      });
      if (e.target.value !== 'Add New') {
        const selectedFormName = savedEnvdForms.filter(
          (form) => form.docId === e.target.value,
        )[0]?.formName;
        localDispatch({
          type: 'update/envdFormName',
          envdFormName: selectedFormName,
        });
      }
    },
    [savedEnvdForms],
  );

  const handleSeletedHd = useCallback(
    (e: React.ChangeEvent<{value: string; name?: string}>) => {
      localDispatch({
        type: 'change/selectedHdForm',
        selectedHdForm: e.target.value,
      });
      if (e.target.value !== 'Add New') {
        const selectedFormName = savedHdForms.filter(
          (form) => form.docId === e.target.value,
        )[0]?.formName;
        localDispatch({
          type: 'update/hdFormName',
          hdFormName: selectedFormName,
        });
      }
    },
    [],
  );

  const handleFormMode = useCallback(
    (formType: string, mode: 'VIEW' | 'EDIT') => {
      if (formType === 'LPAC1') {
        localDispatch({
          type: 'change/envdMode',
          envdMode: mode,
        });
        if (state.envdFormName) {
          const selectedForm = savedEnvdForms.find(
            (sv) => sv.formName === state.envdFormName,
          );
          if (selectedForm) {
            localDispatch({
              type: 'change/selectedEnvdForm',
              selectedEnvdForm: selectedForm.docId,
            });
          }
        }
      } else if (formType === 'HSC2') {
        localDispatch({
          type: 'change/hdMode',
          hdMode: mode,
        });
        if (state.hdFormName) {
          const selectedForm = savedHdForms.find(
            (sv) => sv.formName === state.hdFormName,
          );
          if (selectedForm) {
            localDispatch({
              type: 'change/selectedHdForm',
              selectedHdForm: selectedForm.docId,
            });
          }
        }
      }
    },
    [state.envdFormName, state.hdFormName, savedEnvdForms, savedHdForms],
  );

  const handleCompletedAnswers = useCallback(
    (
      formType: string,
      formAnswers: FormAnswers,
      errorStates: {[key: string]: boolean},
    ): boolean => {
      for (const [key, answer] of Object.entries(formAnswers)) {
        if (!answer.value || answer.value === '') {
          if (errorStates[key]) {
            return true; // have unfilled fields, show 'required' error
          }
        }
      }
      if (formType === 'LPAC1') {
        localDispatch({
          type: 'update/envdAnswers',
          envdAnswers: formAnswers,
        });
        localDispatch({
          type: 'change/envdCompleted',
          envdCompleted: !state.envdCompleted,
        });
        localDispatch({
          type: 'change/envdMode',
          envdMode: !state.envdCompleted ? 'VIEW' : 'EDIT',
        });
        if (state.hdAnswers) {
          let hdCopy = {...state.hdAnswers};
          let hasChanges = false;
          for (const [k, v] of Object.entries(formAnswers)) {
            if (state.hdAnswers[k] || v.questionId === '20') { //20 is subquestion
              hdCopy[k] = v;
              hasChanges = true;
            }
          }
          if (hasChanges) {
            localDispatch({
              type: 'update/hdAnswers',
              hdAnswers: hdCopy,
            });
          }
        }
      } else if (formType === 'HSC2') {
        localDispatch({
          type: 'update/hdAnswers',
          hdAnswers: formAnswers,
        });
        localDispatch({
          type: 'change/hdCompleted',
          hdCompleted: !state.hdCompleted,
        });
        localDispatch({
          type: 'change/hdMode',
          hdMode: !state.hdCompleted ? 'VIEW' : 'EDIT',
        });
        if (state.envdAnswers) {
          let envdCopy = {...state.envdAnswers};
          let hasChanges = false;
          for (const [k, v] of Object.entries(formAnswers)) {
            if (state.envdAnswers[k] || v.questionId === '20') { //20 is subquestion
              envdCopy[k] = v;
              hasChanges = true;
            }
          }
          if (hasChanges) {
            localDispatch({
              type: 'update/envdAnswers',
              envdAnswers: envdCopy,
            });
          }
        }
      }
      return false;
    },
    [
      state.envdCompleted,
      state.hdCompleted,
      state.hdAnswers,
      state.envdAnswers,
    ],
  );

  const handleSignDeclarationCheck = useCallback(() => {
    localDispatch({
      type: 'change/signDeclarationChecked',
      signDeclarationChecked: !state.signDeclarationChecked,
    });
  }, [state.signDeclarationChecked]);

  const handleEmptyField = useCallback(() => {
    localDispatch({
      type: 'change/address',
      address: '',
    });
    localDispatch({
      type: 'change/postcode',
      postcode: '',
    });
    localDispatch({
      type: 'change/town',
      town: '',
    });
    localDispatch({
      type: 'change/selectedState',
      selectedState: '',
    });
  }, []);

  const handleDestinationPIC = useCallback(
    (type: string) => (e: React.ChangeEvent<{value: string}>) => {
      switch (type) {
        case 'destinationPICRadio':
          localDispatch({
            type: 'change/destinationPICRadio',
            destinationPICRadio: e.target.value as 'usePIC' | 'newPIC',
          });
          if (e.target.value === 'usePIC') {
            localDispatch({
              type: 'change/destinationPIC',
              destinationPIC:
                state.selectedConsigneeIndex !== ''
                  ? savedConsignees[state.selectedConsigneeIndex].receiverPIC
                  : '',
            });
            handleEmptyField();
          } else {
            localDispatch({
              type: 'change/destinationPIC',
              destinationPIC: '',
            });
            handleEmptyField();
          }
          break;
        case 'destinationPIC':
          localDispatch({
            type: 'change/destinationPIC',
            destinationPIC: e.target.value,
          });
          break;
        default:
          break;
      }
    },
    [state.selectedConsigneeIndex, savedConsignees],
  );
  const handleSelectAnimals = useCallback(
    (type: string) => (e) => {
      switch (type) {
        case 'selectAnimalsRadio':
          localDispatch({
            type: 'change/selectAnimalsRadio',
            selectAnimalsRadio: e.target.value as
              | 'CSV file Selection'
              | 'Group/Mob Selection'
              | 'Individual Animal Selection',
          });
          break;
        case 'selectGroup':
          localDispatch({
            type: 'change/selectedGroupIndex',
            selectedGroupIndex: e.target.value,
            csvData: {file: null},
          });
          break;
      }
    },
    [],
  );
  /**individual animal filter  */
  const updateAnimalFilter = useCallback(
    (type: string) => (e: React.ChangeEvent<{value: any}> | any) => {
      const mob = type === 'mob' ? e.target.value : state.animalFilters.mob;
      const pic = type === 'pic' ? e.target.value : state.animalFilters.pic;
      if (type === 'mob' || type === 'pic') {
        localDispatch({
          type: 'update/animalFilters',
          animalFilters: {...state.animalFilters, [type]: e.target.value},
        });
        fetchAssets(
          mob,
          pic,
          state.animalFilters.birthYearFrom
            ? Number(moment(state.animalFilters.birthYearFrom).format('yyyy'))
            : null,
          state.animalFilters.birthYearTo
            ? Number(moment(state.animalFilters.birthYearTo).format('yyyy'))
            : null,
          state.currentPage,
          state.pageSize,
          [],
          true,
        );
      } else {
        localDispatch({
          type: 'update/animalFilters',
          animalFilters: {...state.animalFilters, [type]: e},
        });

        if (type === 'birthYearFrom') {
          fetchAssets(
            state.animalFilters.mob,
            state.animalFilters.pic,
            Number(moment(e).format('yyyy')),
            state.animalFilters.birthYearTo
              ? Number(moment(state.animalFilters.birthYearTo).format('yyyy'))
              : null,
            state.currentPage,
            state.pageSize,
            [],
            true,
          );
        } else {
          fetchAssets(
            state.animalFilters.mob,
            state.animalFilters.pic,
            state.animalFilters.birthYearFrom
              ? Number(moment(state.animalFilters.birthYearFrom).format('yyyy'))
              : null,
            Number(moment(e).format('yyyy')),
            state.currentPage,
            state.pageSize,
            [],
            true,
          );
        }
      }
      localDispatch({
        type: 'change/animalSelectedIndex',
        animalSelectedIndex: [],
      });
      localDispatch({
        type: 'change/selectedAnimalInfo',
        selectedAnimalInfo: [],
      });
    },
    [state.animalFilters, state.currentPage, state.pageSize, fetchAssets],
  );

  const attachFileUpload = React.useRef(null);
  const handleAttachClick = useCallback(() => {
    attachFileUpload.current.click();
  }, [attachFileUpload]);
  /**Handle csv and file uploads*/
  const uploadFileHandler = useCallback(
    (type: string) => async (e) => {
      const target =
        type === 'selectCSV'
          ? e
          : e.target.files.length > 0
          ? e.target.files[0]
          : '';
      if (target) {
        if (target.size > 10485760) {
          window.alert('Upload image size more than 10mb ！');
        } else {
          dispatch({type: SPINNER_TOGGLE_ON});
          const response = await fileUploader(userId, target, 'envd');
          const responseData = response[0].data;
          dispatch({type: SPINNER_TOGGLE_OFF});
          e.target.value = '';
          if (typeof responseData == 'string') {
            if (type === 'selectCSV') {
              localDispatch({
                type: 'change/csvData',
                csvData: {name: target.name, link: responseData, file: target},
                selectedGroupIndex: '',
              });
            } else if (type === 'attachments') {
              localDispatch({
                type: 'update/attachments',
                attachments: [
                  ...state.attachments,
                  {name: target.name, link: responseData},
                ],
              });
            }
          }
        }
      }
    },
    [state.attachments, userId],
  );
  /** handle csv asset */
  const handleCsvAsset = useCallback((e) => {
    const rfidList = e.map((item) => ({rfid: item.rfid}));
    localDispatch({
      type: 'change/csvAsset',
      csvAsset: rfidList,
    });
  }, []);
  /**handle delete attachments */
  const handleDeleteAttach = useCallback(
    (index: number) => {
      const attach = [...state.attachments];
      attach.splice(index, 1);
      localDispatch({
        type: 'update/attachments',
        attachments: attach,
      });
    },
    [state.attachments],
  );
  /**handle share email address */
  const handleEmailAddress = useCallback(
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newEmail: Array<string> = [...state.emailList];
      newEmail.splice(index, 1, e.target.value);
      localDispatch({
        type: 'update/emailList',
        emailList: newEmail,
      });
    },
    [state.emailList],
  );
  /**handle share mobile address */
  const handleMobileAddress = useCallback(
    (index: number) => (e) => {
      const newMobile: Array<string> = [...state.mobileList];
      newMobile.splice(index, 1, e);
      localDispatch({
        type: 'update/mobileList',
        mobileList: newMobile,
      });
    },
    [state.mobileList],
  );

  /**handle delete share email */
  const handleDeleteEmail = useCallback(
    (index: number) => {
      if (state.emailList.length > 0) {
        const emailList = [...state.emailList];
        emailList.splice(index, 1);
        const emailValidationList = [...state.validationList.email];
        emailValidationList.splice(index, 1);
        localDispatch({
          type: 'update/emailList',
          emailList: emailList,
        });
        localDispatch({
          type: 'update/validationList',
          validationList: {
            ...state.validationList,
            email: emailValidationList,
          },
        });
      }
    },
    [state.validationList, state.emailList],
  );
  /**handle delete share mobile */
  const handleDeleteMobile = useCallback(
    (index: number) => {
      if (state.mobileList.length > 0) {
        const mobileList = [...state.mobileList];
        mobileList.splice(index, 1);
        const mobileValidationList = [...state.validationList.mobile];
        mobileValidationList.splice(index, 1);
        localDispatch({
          type: 'update/mobileList',
          mobileList: mobileList,
        });
        localDispatch({
          type: 'update/validationList',
          validationList: {
            ...state.validationList,
            mobile: mobileValidationList,
          },
        });
        if (index === 0) {
          localDispatch({
            type: 'update/deleteFistMobile',
            deleteFistMobile: true,
          });
        }
      }
    },
    [state.mobileList, state.validationList],
  );
  /** extract consignment details from local store*/
  const extractConsignment = useCallback(() => {
    const formTypes = [];
    const answersList = [];
    if (state.envdCompleted === true && Object.keys(state.envdAnswers).length) {
      formTypes.push('LPAC1');
      Object.values(state.envdAnswers)
        .filter(
          (answer) => answer.questionId !== undefined && answer.value !== null,
        )
        .map((answer) => answersList.push(answer));
    }
    if (state.hdCompleted === true && Object.keys(state.hdAnswers).length) {
      formTypes.push('HSC2');
      Object.values(state.hdAnswers)
        .filter(
          (answer) => 
            answer.questionId !== undefined &&
            answer.value !== null &&
            answer.value !== '' &&
            !state.envdAnswers[`${answer.questionId}-${answer.index}`]
        )
        .map((answer) => answersList.push(answer));
    }

    const consignmentDetails = {
      name: state.consignmentName,
      consignee: {
        id:
          state.selectedConsigneeIndex !== ''
            ? savedConsignees[state.selectedConsigneeIndex].agliveToken
            : '',
        pic: state.destinationPIC,
      },
      destination: {
        pic: state.destinationPIC,
        address: state.address,
        postcode: state.postcode,
        town: state.town,
        state: state.selectedState,
      },
      animalSelectionMode: state.selectAnimalsRadio,
      declaration: state.signDeclarationChecked
        ? {
            userId,
          }
        : {userId: ''},
      forms: formTypes,
      answers: answersList,
      shareTo: {
        mobile: state.mobileList.filter((item) => item !== ''),
        email: state.emailList.filter((item) => item !== ''),
      },
      attachments: state.attachments.map((attach) => attach.link),
      assets: [],
      template: [],
    };
    switch (state.selectAnimalsRadio) {
      case 'Individual Animal Selection':
        consignmentDetails['assets'] = state.selectedAnimalInfo;
        break;
      case 'Group/Mob Selection':
        consignmentDetails['mob'] = {
          id: state.groupMobList[state.selectedGroupIndex]?.id,
          name: state.groupMobList[state.selectedGroupIndex]?.name,
        };
        consignmentDetails['assets'] = [];
        break;
      case 'CSV file Selection':
        consignmentDetails['csvFile'] = {
          name: state.csvData.name,
          link: state.csvData.link,
        };
        consignmentDetails['assets'] = state.csvAsset;
    }
    if (state.envdFormName && state.envdCompleted === true) {
      consignmentDetails.template.push({
        name: state.envdFormName,
        type: 'LPAC1',
      });
    }
    if (state.hdFormName && state.hdCompleted === true) {
      consignmentDetails.template.push({name: state.hdFormName, type: 'HSC2'});
    }
    return consignmentDetails;
  }, [
    savedConsignees,
    state.attachments,
    state.consignmentName,
    state.csvAsset,
    state.csvData.link,
    state.csvData.name,
    state.destinationPIC,
    state.address,
    state.postcode,
    state.town,
    state.selectedState,
    state.emailList,
    state.envdAnswers,
    state.envdCompleted,
    state.groupMobList,
    state.hdAnswers,
    state.hdCompleted,
    state.mobileList,
    state.selectAnimalsRadio,
    state.selectedAnimalInfo,
    state.selectedConsigneeIndex,
    state.selectedGroupIndex,
    state.signDeclarationChecked,
    state.envdFormName,
    state.hdFormName,
    userId,
  ]);
  const consignmentValidation = useCallback(() => {
    let regex =
      /^([a-zA-Z0-9\.-]+)@([a-zA-Z0-9-]+).([a-z]{2,8})(.[a-z]{2,8})?$/;
    let validation = true;
    const newValidation = state.validationList;

    state.emailList.map((item, index) => {
      if (item !== '' && regex.test(item) === false) {
        newValidation.email.splice(index, 1, {
          helperText: 'Please input valide email',
        });
        localDispatch({
          type: 'update/validationList',
          validationList: {...state.validationList, email: newValidation.email},
        });
        validation = false;
      }
    });
    state.mobileList.map((item, index) => {
      if (
        (item && item !== '' && !isPossiblePhoneNumber(item)) ||
        (item && item !== '' && parsePhoneNumber(item).country === undefined)
      ) {
        newValidation.mobile.splice(index, 1, {
          helperText: 'Please input valide mobile number',
        });
        localDispatch({
          type: 'update/validationList',
          validationList: {
            ...state.validationList,
            mobile: newValidation.mobile,
          },
        });
        validation = false;
      }
    });

    return validation;
  }, [state.emailList, state.mobileList, state.validationList]);
  const saveDraftProcess = useCallback(
    (newConsignment?) => {
      const consignmentDetails = newConsignment.hasOwnProperty('name')
        ? newConsignment
        : extractConsignment();
      if (!consignmentValidation()) return;
      if (state.selectAnimalsRadio === 'CSV file Selection') {
        validateWithSchema(
          state.csvAsset,
          consigmentAnimalsSchema,
          true,
          uniqueIds.Australia,
        )
          .then(() => {
            if (path.includes('new') || path.includes('duplicate')) {
              dispatch(
                createConsignment(consignmentDetails, handleWrongRfid, () => {
                  history.goBack();
                }),
              );
            }
            if (path.includes('edit')) {
              dispatch(
                editConsignment(
                  consignmentDetails,
                  docId,
                  state.existingConsignmentData,
                  () => {
                    history.goBack();
                  },
                ),
              );
            }
          })
          .catch((error) => {
            console.error('error', error);
            dispatch(
              toggleModal({
                status: 'failed',
                title: 'Please input RFID for each animal',
                subtitle: '',
              }),
            );
          });
      } else {
        if (path.includes('new') || path.includes('duplicate')) {
          dispatch(
            createConsignment(consignmentDetails, handleWrongRfid, () => {
              history.goBack();
            }),
          );
        }
        if (path.includes('edit')) {
          dispatch(
            editConsignment(
              consignmentDetails,
              docId,
              state.existingConsignmentData,
              () => {
                history.goBack();
              },
            ),
          );
        }
      }
      if (state.addNewEnvd && state.envdFormName) {
        dispatch(
          saveFormTemplate(
            'LPAC1',
            state.envdFormName,
            Object.values(state.envdAnswers),
            history,
          ),
        );
      }
      if (state.addNewHd && state.hdFormName) {
        dispatch(
          saveFormTemplate(
            'HSC2',
            state.hdFormName,
            Object.values(state.hdAnswers),
            history,
          ),
        );
      }
    },
    [
      consignmentValidation,
      dispatch,
      docId,
      state.existingConsignmentData,
      extractConsignment,
      history,
      path,
      state.addNewEnvd,
      state.addNewHd,
      state.csvAsset,
      state.envdAnswers,
      state.hdAnswers,
      state.envdFormName,
      state.hdFormName,
      state.selectAnimalsRadio,
    ],
  );
  const handleWrongRfid = useCallback((rfids: Array<string>) => {
    localDispatch({type: 'fetch/wrongRfids', wrongRfids: rfids});
  }, []);
  const submitConsignmentProcess = useCallback(
    (newConsignment?) => {
      const consignmentDetails = newConsignment
        ? newConsignment
        : extractConsignment();
      if (!consignmentValidation()) return;
      if (state.selectAnimalsRadio === 'CSV file Selection') {
        /**if user uploads csv, validate rfid in csv */
        validateWithSchema(
          state.csvAsset,
          consigmentAnimalsSchema,
          true,
          uniqueIds.Australia,
        )
          .then(() => {
            if (docId && !path.includes('duplicate')) {
              dispatch(
                submitConsignment(
                  consignmentDetails,
                  () => {
                    history.goBack();
                  },
                  handleWrongRfid,
                  docId,
                  undefined,
                  state.existingConsignmentData,
                ),
              );
            } else {
              dispatch(
                submitConsignment(
                  consignmentDetails,
                  () => {
                    history.goBack();
                  },
                  handleWrongRfid,
                  state.currentAgToken,
                  updateAgliveToken,
                  state.existingConsignmentData,
                ),
              );
            }
          })
          .catch((error) => {
            dispatch(
              toggleModal({
                status: 'failed',
                title: 'Please input RFID for each animal',
                subtitle: '',
              }),
            );
          });
      } else {
        if (docId && !path.includes('duplicate')) {
          dispatch(
            submitConsignment(
              consignmentDetails,
              () => {
                history.goBack();
              },
              handleWrongRfid,
              docId,
              undefined,
              state.existingConsignmentData,
            ),
          );
        } else {
          dispatch(
            submitConsignment(
              consignmentDetails,
              () => {
                history.goBack();
              },
              handleWrongRfid,
              state.currentAgToken,
              updateAgliveToken,
              state.existingConsignmentData,
            ),
          );
        }
      }
      if (state.addNewEnvd && state.envdFormName) {
        dispatch(
          saveFormTemplate(
            'LPAC1',
            state.envdFormName,
            Object.values(state.envdAnswers),
            history,
          ),
        );
      }
      if (state.addNewHd && state.hdFormName) {
        dispatch(
          saveFormTemplate(
            'HSC2',
            state.hdFormName,
            Object.values(state.hdAnswers),
            history,
          ),
        );
      }
    },
    [
      consignmentValidation,
      dispatch,
      docId,
      state.existingConsignmentData,
      extractConsignment,
      handleWrongRfid,
      history,
      state.addNewEnvd,
      state.addNewHd,
      state.csvAsset,
      state.envdAnswers,
      state.hdAnswers,
      state.envdFormName,
      state.hdFormName,
      state.selectAnimalsRadio,
      state.currentAgToken,
    ],
  );
  const handleSubmitConsignment = useCallback(() => {
    submitConsignmentProcess();
    localDispatch({type: 'update/isSubmit', isSubmit: true});
  }, [submitConsignmentProcess]);

  const handleAddNewForm = useCallback(
    (formType: string, option: 'addNew' | 'selectForm') => {
      if (formType === 'envd') {
        if (option === 'addNew') {
          localDispatch({
            type: 'change/addNewEnvd',
            addNewEnvd: true,
          });
        } else if (option === 'selectForm') {
          localDispatch({
            type: 'change/addNewEnvd',
            addNewEnvd: false,
          });
        }
      } else if (formType === 'hd') {
        if (option === 'addNew') {
          localDispatch({
            type: 'change/addNewHd',
            addNewHd: true,
          });
        } else if (option === 'selectForm') {
          localDispatch({
            type: 'change/addNewHd',
            addNewHd: false,
          });
        }
      }
    },
    [],
  );

  const handleNewEnvdFormNameChange = useCallback(
    (e: React.ChangeEvent<{value: string}>) => {
      localDispatch({
        type: 'update/envdFormName',
        envdFormName: e.target.value,
      });
    },
    [],
  );

  const handleNewHdFormNameChange = useCallback(
    (e: React.ChangeEvent<{value: string}>) => {
      localDispatch({
        type: 'update/hdFormName',
        hdFormName: e.target.value,
      });
    },
    [],
  );

  useEffect(() => {
    dispatch(getMobsByBusiness(userId, businessId)).then((resp) => {
      localDispatch({
        type: 'update/mobList',
        mobList: resp.map((res) => res.details.mob_name),
      });
      const getMobList = resp.map((res) => ({
        name: res.details.mob_name,
        id: res.externalIds[0]?.agliveToken,
      }));
      localDispatch({
        type: 'fetch/groupMobList',
        groupMobList: [...getMobList],
      });
    });
    dispatch(fetchConsignmentData());
    dispatch(fetchConsigneeData());
    dispatch(fetchFormTemplates());
    dispatch(fetchGroupProjects(userId));
    if (path.includes('new')) {
      fetchAssets();
    }
    if (path.includes('edit') || path.includes('duplicate')) {
      fetchEditConsignmentInfo();
    }
    if (docId) {
      localDispatch({
        type: 'change/consignmentData',
        existingConsignmentData: consignmentList.find(
          (consignment) => consignment.agliveToken === docId,
        ),
      });
    }
    if (locations) {
      localDispatch({
        type: 'update/picList',
        picList: locations.map((res) => res.PICAddress),
      });
    }
  }, []);
  useEffect(() => {
    const getGroupList = groups.map((group) => ({
      name: group.details.name,
      id: group.externalIds[0].agliveToken,
    }));
    localDispatch({
      type: 'fetch/groupMobList',
      groupMobList: [...getGroupList],
    });
    localDispatch({
      type: 'fetch/groupMobList',
      groupMobList: [...getGroupList, ...state.mobList],
    });
  }, [groups, state.mobList]);
  useEffect(() => {
    if (path.includes('edit') || path.includes('duplicate')) {
      fetchEditConsignmentInfo();
    }
  }, [state.existingConsignmentData]);

  /**fetch edit/duplicate consignment info */
  const fetchEditConsignmentInfo = useCallback(() => {
    if (state.existingConsignmentData) {
      const fetchJson = async () => {
        try {
          /**display forms */
          const envdAnswers = {};
          const hdAnswers = {};
          const res = await fetch(CONSTANT.FORM_JSON_LINK);
          const jsonData = await res.json();
          const envdQuestions = extractQuestionType(jsonData, 'LPAC1');
          const hdQuestions = extractQuestionType(jsonData, 'HSC2');
          state.existingConsignmentData.answers.map((ans) => {
            if (envdQuestions[ans.questionId]) {
              envdAnswers[`${ans.questionId}-${ans.index}`] = {
                questionId: ans.questionId,
                index: ans.index,
                value: ans.value,
              };
            }
            if (hdQuestions[ans.questionId]) {
              hdAnswers[`${ans.questionId}-${ans.index}`] = {
                questionId: ans.questionId,
                index: ans.index,
                value: ans.value,
              };
            }
          });

          localDispatch({type: 'fetch/formsAnswers', envdAnswers, hdAnswers});
          if (state.existingConsignmentData.forms.includes("LPAC1")) {
            localDispatch({
              type: 'change/envdCompleted',
              envdCompleted: true,
            });
            localDispatch({
              type: 'change/envdMode',
              envdMode: 'VIEW',
            });
            localDispatch({
              type: 'update/envdFormName',
              envdFormName: state.existingConsignmentData.template[0]?.name,
            });
          }

          if (state.existingConsignmentData.forms.includes("HSC2")) {
            localDispatch({
              type: 'change/hdCompleted',
              hdCompleted: true,
            });
            localDispatch({
              type: 'change/hdMode',
              hdMode: 'VIEW',
            });
            localDispatch({
              type: 'update/hdFormName',
              hdFormName: state.existingConsignmentData.template[1]?.name,
            });
          }
        } catch (e) {
          console.error(e);
        }
      };
      const consigneeIndex = savedConsignees.findIndex(
        (consignee) =>
          consignee.agliveToken === state.existingConsignmentData.consignee.id,
      );
      var editDestinationPICRadio: 'newPIC' | 'usePIC' = 'usePIC';
      var editcsvData: {name?: string; link?: string; file: null} = {
        name: '',
        link: '',
        file: null,
      };
      var editCsvAsset = [];
      var editSelectAnimalsRadio = '';
      fetchJson();
      if (
        savedConsignees[consigneeIndex]?.receiverPIC ===
          state.existingConsignmentData.destination.pic ||
        !state.existingConsignmentData.destination?.pic
      ) {
        editDestinationPICRadio = 'usePIC';
      } else {
        editDestinationPICRadio = 'newPIC';
      }
      let groupMobSelectedIndex = '';
      if (state.existingConsignmentData?.status?.includes('draft')) {
        switch (state.existingConsignmentData.animalSelectionMode) {
          case 'Individual Animal Selection':
            editSelectAnimalsRadio = 'Individual Animal Selection';
            editcsvData = {file: null};
            const consignemntAnimalList = state.existingConsignmentData.assets;
            localDispatch({
              type: 'change/selectedAnimalInfo',
              selectedAnimalInfo: consignemntAnimalList,
            });
            fetchAssets(
              '',
              '',
              null,
              null,
              state.currentPage,
              state.pageSize,
              consignemntAnimalList,
            );
            break;
          case 'Group/Mob Selection':
            editSelectAnimalsRadio = 'Group/Mob Selection';
            editcsvData = {file: null};
            const groupList = groups.map((group) => ({
              name: group.details.name,
              id: group.externalIds[0].agliveToken,
            }));
            let mobList = [];
            dispatch(getMobsByBusiness(userId, businessId)).then((resp) => {
              localDispatch({
                type: 'update/mobList',
                mobList: resp.map((res) => res.details.mob_name),
              });
              mobList = resp.map((res) => ({
                name: res.details.mob_name,
                id: res.externalIds[0]?.agliveToken,
              }));
            });
            const groupMobMerge = [...groupList, ...mobList];
            const findGroupIndex = groupMobMerge.findIndex(
              (item) => item?.id === state.existingConsignmentData.mob.id,
            );
            groupMobSelectedIndex =
              findGroupIndex > -1 ? String(findGroupIndex) : '';
            fetchAssets(
              '',
              '',
              null,
              null,
              state.currentPage,
              state.pageSize,
              [],
            );
            break;
          case 'CSV file Selection':
            editSelectAnimalsRadio = 'CSV file Selection';
            editcsvData = {
              name: state.existingConsignmentData.csvFile.name,
              link: state.existingConsignmentData.csvFile.link,
              file: null,
            };
            fetchAssets(
              '',
              '',
              null,
              null,
              state.currentPage,
              state.pageSize,
              [],
            );
            state.existingConsignmentData.assets.map((asset) =>
              editCsvAsset.push({...asset}),
            );
            break;
          default:
            editSelectAnimalsRadio = 'Individual Animal Selection';
            editcsvData = {file: null};
            const consignemntAnimalList2 = state.existingConsignmentData.assets;
            localDispatch({
              type: 'change/selectedAnimalInfo',
              selectedAnimalInfo: consignemntAnimalList2,
            });
            fetchAssets(
              '',
              '',
              null,
              null,
              state.currentPage,
              state.pageSize,
              consignemntAnimalList2,
            );
            break;
        }
      } else {
        editSelectAnimalsRadio = '';
        editcsvData = {file: null};
        fetchAssets('', '', null, null, state.currentPage, state.pageSize, []);
        groupMobSelectedIndex = '';
      }

      const emailValidationList = [];
      state.existingConsignmentData.shareTo.email.length > 0
        ? state.existingConsignmentData.shareTo.email.map((email) =>
            emailValidationList.push({helperText: ''}),
          )
        : emailValidationList.push({helperText: ''});
      const mobileValidationList = [];
      state.existingConsignmentData.shareTo.mobile.length > 0
        ? state.existingConsignmentData.shareTo.mobile.map((mobile) =>
            mobileValidationList.push({helperText: ''}),
          )
        : mobileValidationList.push({helperText: ''});
      localDispatch({
        type: 'fetch/consignmentInfo',
        selectedConsigneeIndex:
          consigneeIndex < 0 ? '' : String(consigneeIndex),
        destinationPIC: state.existingConsignmentData.destination.pic,
        address: state.existingConsignmentData.destination.address,
        postcode: state.existingConsignmentData.destination.postcode,
        town: state.existingConsignmentData.destination.town,
        selectedState: state.existingConsignmentData.destination.state,
        destinationPICRadio: editDestinationPICRadio,
        consignmentName: path.includes('edit')
          ? state.existingConsignmentData.name
          : `Copy of ${state.existingConsignmentData.name}`,
        csvData: editcsvData,
        csvAsset: editCsvAsset,
        attachments: state.existingConsignmentData.attachments?.map(
          (attach) => {
            return {name: attach.split('/').pop(), link: attach};
          },
        ),
        emailList:
          state.existingConsignmentData.shareTo.email.length === 0
            ? ['']
            : state.existingConsignmentData.shareTo.email,
        mobileList:
          state.existingConsignmentData.shareTo.mobile.length === 0
            ? ['']
            : state.existingConsignmentData.shareTo.mobile,
        selectAnimalsRadio: editSelectAnimalsRadio,
        selectedGroupIndex: groupMobSelectedIndex,
        signDeclarationChecked: state.existingConsignmentData.declaration
          ?.userId
          ? true
          : false,
        validationList: {
          email: emailValidationList,
          mobile: mobileValidationList,
        },
      });
    }
  }, [state.existingConsignmentData]);

  /**if consignmment contains rfids that no longer belong to the owner, remove those rfids in the consignment */
  useEffect(() => {
    if (state.wrongRfids.length > 0) {
      const consignmentDetails = extractConsignment();
      const assetsArray = consignmentDetails.assets;
      const rfidToDeleteSet = new Set(state.wrongRfids);
      const newAeests = assetsArray.filter((item) => {
        return !rfidToDeleteSet.has(item.rfid);
      });
      consignmentDetails['assets'] = newAeests;
      dispatch(
        toggleModal({
          status: 'warning',
          title: 'Reminder',
          subtitle:
            'Some animals in this consignment have been transferred out from your account We will remove them and proceed',
          renderButton: (
            <Buttons
              leftButtonTitle="Cancel"
              rightButtonTitle="Okay"
              leftButtonOnClick={() => {
                dispatch(toggleModalOff());
              }}
              rightButtonOnClick={() => {
                dispatch(toggleModalOff());
                state.isSubmit
                  ? submitConsignmentProcess(consignmentDetails)
                  : saveDraftProcess(consignmentDetails);
              }}
            />
          ),
        }),
      );
    }
  }, [state.wrongRfids, state.isSubmit]);
  // reset validationState and warnings onKeyDown
  useEffect(() => {
    const resetValidationState = () =>
      localDispatch({
        type: 'update/validationList',
        validationList: clearWarning(state.validationList),
      });
    window.addEventListener('keydown', resetValidationState);

    return () => {
      window.removeEventListener('keydown', resetValidationState);
    };
  }, [state.validationList]);

  return (
    <>
      <Grid
        container
        spacing={3}
        alignItems="center"
        className={classes.bodyContainer}>
        <Grid container item style={{display: 'flex', flexDirection: 'column'}}>
          <Grid
            container
            item
            direction="row"
            style={{marginBottom: 20}}
            justifyContent="space-between"
            alignItems="flex-start">
            <Grid item xs={5}>
              <Grid item style={{marginBottom: 20}}>
                <Typography
                  variant="h6"
                  role="label"
                  style={{fontWeight: 700, marginBottom: 10}}>
                  Consignment Name
                </Typography>

                <TextField
                  fullWidth
                  variant="outlined"
                  value={state.consignmentName}
                  onChange={handleConsignementNameChange}
                />
              </Grid>
              <Grid
                container
                item
                style={{marginBottom: 20}}
                alignItems="flex-start">
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    role="label"
                    style={{fontWeight: 700, marginBottom: 10}}>
                    Consignee
                  </Typography>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="envd-form-type-select">
                      {'Please select'}
                    </InputLabel>
                    <Select
                      labelId="report-type-select"
                      label="Please select"
                      value={state.selectedConsigneeIndex}
                      onChange={handleConsigneeChange}>
                      {savedConsignees.map((consignee, index) => (
                        <MenuItem
                          value={index}
                          key={index}
                          id={consignee.receiverName}>
                          {consignee.receiverName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Grid item container justifyContent="flex-end">
                    <Button
                      onClick={() => {
                        history.push(`/private/add/consignees/new`);
                      }}>
                      <span
                        style={{
                          color: COLOR.GREENT_TEXT,
                          fontSize: 18,
                          textTransform: 'capitalize',
                        }}>
                        <u>{'Add New Consignee'}</u>
                      </span>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Typography
                variant="h6"
                role="label"
                style={{fontWeight: 700, marginBottom: 10}}>
                Destination PIC
              </Typography>

              <RadioGroup
                aria-label="item"
                name="item"
                value={state.destinationPICRadio}
                onChange={handleDestinationPIC('destinationPICRadio')}>
                <FormControlLabel
                  value="usePIC"
                  control={<Radio color="primary" />}
                  label="Use Consignee PIC"
                  id={'useConsigneePIC'}
                />
                {state.destinationPICRadio === 'usePIC' && (
                  <Typography
                    id={'consigneePIC'}
                    variant="h6"
                    role="label"
                    style={{fontWeight: 700, marginBottom: 10}}>
                    {state.selectedConsigneeIndex !== ''
                      ? savedConsignees[state.selectedConsigneeIndex]
                          .receiverPIC
                      : 'Please select consignee'}
                  </Typography>
                )}
                <FormControlLabel
                  value="newPIC"
                  control={<Radio color="primary" />}
                  label="New Destination PIC"
                  id={'newDestinationPIC'}
                />
                {state.destinationPICRadio === 'newPIC' && (
                  <>
                    <Grid
                      container
                      item
                      direction="row"
                      style={{marginBottom: 20}}
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={state.destinationPIC}
                        onChange={handleDestinationPIC('destinationPIC')}
                        id={'destinationPIC'}
                      />

                      <Typography
                        variant="h6"
                        role="label"
                        style={{fontWeight: 700, margin: '20px 0 10px 0'}}>
                        Address
                      </Typography>

                      <TextField
                        fullWidth
                        variant="outlined"
                        value={state.address}
                        onChange={handleAddress}
                        id={'address'}
                        rows={3}
                        multiline
                        style={{fontWeight: 700}}
                      />
                      <Grid item xs={6}>
                        <Typography
                          variant="h6"
                          role="label"
                          style={{fontWeight: 700, margin: '10px 0'}}>
                          {'Post Code'}
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={state.postcode}
                          onChange={handlePostCode}
                          id={'postcode'}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          variant="h6"
                          role="label"
                          style={{fontWeight: 700, margin: '10px 0'}}>
                          {'Town'}
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={state.town}
                          onChange={handleTown}
                          id={'town'}
                        />
                      </Grid>

                      <Typography
                        variant="h6"
                        style={{fontWeight: 700, margin: '10px 0'}}>
                        {'State/Province/Territory'}
                      </Typography>

                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="state-select">
                          {'Please select'}
                        </InputLabel>
                        <Select
                          labelId="state-select"
                          label="Please select"
                          value={state.selectedState}
                          onChange={handleSelectedState}>
                          {STATE_OPTIONS['Australia']?.map((state) => (
                            <MenuItem value={state} key={state}>
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              role="label"
              style={{fontWeight: 700, marginBottom: 10}}>
              Select Animals
            </Typography>

            <RadioGroup
              aria-label="item"
              name="item"
              value={state.selectAnimalsRadio}
              onChange={handleSelectAnimals('selectAnimalsRadio')}>
              <FormControlLabel
                id={'uploadCSVFile'}
                value="CSV file Selection"
                control={<Radio color="primary" />}
                label="Upload CSV File"
              />
              {state.selectAnimalsRadio === 'CSV file Selection' && (
                <>
                  {state.csvData.name ? (
                    <CSVUploader
                      file={state.csvData.file}
                      setFile={uploadFileHandler('selectCSV')}
                      setCSVData={handleCsvAsset}
                      useHeader={false}
                      fileName={state.csvData.name}
                    />
                  ) : (
                    <CSVUploader
                      file={state.csvData.file}
                      setFile={uploadFileHandler('selectCSV')}
                      setCSVData={handleCsvAsset}
                      useHeader={false}
                    />
                  )}

                  <Grid container>
                    <Grid
                      item
                      style={{
                        maxWidth: window.innerWidth / 2,
                        width: '100%',
                        textAlign: 'right',
                        marginTop: 20
                      }}>
                      <HyperLink href={CONSTANT.TEMPLATE.CONSIGNMENT.TEMP}>
                        Download CSV Template
                      </HyperLink>
                      <HyperLink
                        style={{marginLeft: 10}}
                        href={CONSTANT.TEMPLATE.CONSIGNMENT.EXAMPLE}>
                        Download Sample CSV
                      </HyperLink>
                    </Grid>
                  </Grid>
                </>
              )}
              <FormControlLabel
                value="Group/Mob Selection"
                control={<Radio color="primary" />}
                label="Group/Mob Selection"
                id={'groupMobSelection'}
              />
              {state.selectAnimalsRadio === 'Group/Mob Selection' && (
                <Grid container item xs={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="envd-form-type-select">
                      {'Please select'}
                    </InputLabel>
                    <Select
                      id={'groupListSelection'}
                      labelId="report-type-select"
                      label="Please select"
                      value={state.selectedGroupIndex}
                      onChange={handleSelectAnimals('selectGroup')}>
                      {state.groupMobList.map((group, index) => (
                        <MenuItem value={index} key={index} id={group.name}>
                          {group.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography
                    variant="h6"
                    role="label"
                    style={{color: COLOR.GRAY_TEXT}}>
                    {
                      'TIP: Groups can be created by scanning individual items in the mobile app'
                    }
                  </Typography>
                </Grid>
              )}
              <FormControlLabel
                value="Individual Animal Selection"
                control={<Radio color="primary" />}
                label="Individual Animal Selection"
                id={'individualAnimalSelection'}
              />
              {state.selectAnimalsRadio === 'Individual Animal Selection' && (
                <Grid container style={{marginTop: 20}}>
                  <Box
                    id={'viewAttachField'}
                    p={'40px 15px 30px 15px'}
                    ml={5}
                    style={{
                      marginLeft: 0,
                      backgroundColor: COLOR.GRAY_SOLID,
                    }}>
                    <div className={classes.filterButton}>
                      <FilterList className={classes.filterIcon} />
                      <Button onClick={changeShowFilter}>
                        <span
                          style={{
                            color: COLOR.GREENT_TEXT,
                            textTransform: 'capitalize',
                          }}>
                          <u>{'Filter'}</u>
                        </span>
                      </Button>
                    </div>
                    {state.showFilters && (
                      <Grid
                        container
                        direction="row"
                        style={{marginBottom: 20}}>
                        {isAngus ? (
                          <Grid item xs={3} style={{marginRight: 50}}>
                            <Typography
                              variant="h6"
                              role="label"
                              style={{fontWeight: 700}}>
                              Mob
                            </Typography>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="envd-form-type-select">
                                {'Please select'}
                              </InputLabel>
                              <Select
                                className={classes.filterColor}
                                labelId="mob-type-select"
                                label="Please select"
                                value={state.animalFilters.mob ?? ''}
                                onChange={updateAnimalFilter('mob')}>
                                {state.mobList.map((mob, index) => (
                                  <MenuItem value={mob} key={index}>
                                    {mob}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        ) : (
                          <Grid item xs={3} style={{marginRight: 50}}>
                            <Typography
                              variant="h6"
                              role="label"
                              style={{fontWeight: 700}}>
                              Location e.g PIC
                            </Typography>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="envd-form-type-select">
                                {'Please select'}
                              </InputLabel>
                              <Select
                                className={classes.filterColor}
                                labelId="pic-type-select"
                                label="Please select"
                                value={state.animalFilters.pic ?? ''}
                                onChange={updateAnimalFilter('pic')}>
                                {state.picList.map((pic, index) => (
                                  <MenuItem value={pic} key={index}>
                                    {pic}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                        <Grid item xs={3} style={{marginRight: 10}}>
                          <Typography
                            variant="h6"
                            role="label"
                            style={{fontWeight: 700}}>
                            Birth Year From
                          </Typography>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={classes.filterColor}
                              disableToolbar
                              variant="inline"
                              format="yyyy"
                              margin="normal"
                              views={['year']}
                              id="filterStartYear"
                              style={{
                                margin: 0,
                                border: 'solid 1px lightgray',
                                padding: '11px 14px',
                                borderRadius: '5px',
                              }}
                              emptyLabel={'yyyy'}
                              value={state.animalFilters.birthYearFrom ?? null}
                              onChange={updateAnimalFilter('birthYearFrom')}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              keyboardIcon={
                                <DateRangeIcon
                                  style={{fill: '#373935'}}
                                  id={'filterStartYearIcon'}
                                />
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            variant="h6"
                            role="label"
                            style={{fontWeight: 700}}>
                            Birth Year To
                          </Typography>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={classes.filterColor}
                              disableToolbar
                              variant="inline"
                              format="yyyy"
                              margin="normal"
                              views={['year']}
                              id="filterEndYear"
                              style={{
                                margin: 0,
                                border: 'solid 1px lightgray',
                                padding: '11px 14px',
                                borderRadius: '5px',
                              }}
                              emptyLabel={'yyyy'}
                              value={state.animalFilters.birthYearTo ?? null}
                              onChange={updateAnimalFilter('birthYearTo')}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              keyboardIcon={
                                <DateRangeIcon
                                  style={{fill: '#373935'}}
                                  id={'filterEndYearIcon'}
                                />
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>
                    )}

                    <TableGrid rows={state.animalList} columns={columns}>
                      <SelectionState
                        selection={state.animalSelectedIndex}
                        onSelectionChange={selectAnimals}
                      />
                      <IntegratedSelection />

                      <PagingState
                        currentPage={state.currentPage}
                        onCurrentPageChange={changePage}
                        pageSize={state.pageSize}
                        onPageSizeChange={changePageSize}
                      />
                      <CustomPaging totalCount={state.totalData} />
                      {/* <IntegratedPaging /> */}
                      <Table tableComponent={TableComponent} />
                      <TableColumnVisibility
                        hiddenColumnNames={hiddenColumnNames}
                        onHiddenColumnNamesChange={setHiddenColumnNames}
                      />
                      <TableHeaderRow contentComponent={HeaderComponent} />
                      <TableSelection cellComponent={cellComponent} />
                      <PagingPanel
                        pageSizes={[5, 10, 15, 20]}
                        containerComponent={PagingComponent}
                      />
                    </TableGrid>
                  </Box>
                </Grid>
              )}
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid container item>
          <Grid container className={classes.formContainer}>
            <Grid container item xs={12} className={classes.formTitleContainer}>
              <Typography
                variant="h3"
                role="label"
                style={{
                  color: hasCredentials ? COLOR.BLACK_BG : COLOR.GRAY_TEXT,
                }}>
                Add Forms
              </Typography>
              <Divider className={classes.dividerStyle} />
            </Grid>
            <Grid
              container
              item
              direction="row"
              style={
                hasCredentials
                  ? {display: 'none'}
                  : {
                      marginTop: 35,
                    }
              }>
              <Typography
                variant="h6"
                role="label"
                style={{color: COLOR.GRAY_TEXT, marginTop: 10}}>
                Please provide your NLIS and LPA credentials if you wish to use
                this function.
              </Typography>
              <Button
                onClick={() => {
                  history.push(`/private/profile/businessProfile`);
                }}>
                <span
                  style={{
                    color: COLOR.GREENT_TEXT,
                    fontSize: 18,
                    textTransform: 'capitalize',
                  }}>
                  <u>{'Add Now'}</u>
                </span>
              </Button>
            </Grid>
            <Grid
              container
              item
              style={{
                // display: 'flex',
                marginTop: 35,
              }}>
              <CheckCircle
                className={
                  state.envdCompleted
                    ? classes.IconChecked
                    : classes.IconUnchecked
                }
              />

              <Grid
                container
                item
                id={'envdExpand'}
                style={{
                  marginLeft: 24,
                  justifyContent: 'space-between',
                  flex: 1,
                }}
                onClick={handleEnvdClick}>
                <Typography variant="h3" role="label" style={{}}>
                  eNVD
                </Typography>
                {state.envdExpand ? <ExpandMore /> : <ExpandLess />}
                <Divider className={classes.dividerStyle} />
              </Grid>

              <Grid
                container
                item
                style={{
                  marginTop: 16,
                  display: state.envdExpand ? 'flex' : 'none',
                }}>
                <Grid container item className={classes.formCardContainer}>
                  {!state.envdCompleted ? (
                    <>
                      <Grid container item xs={6} style={{padding: 10}}>
                        <Typography
                          variant="h6"
                          role="label"
                          style={{fontWeight: 700, marginBottom: 10}}>
                          Saved Forms
                        </Typography>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="envd-form-type-select">
                            {'Please select'}
                          </InputLabel>
                          <Select
                            labelId="envd-form-select"
                            label="Please select"
                            value={state.selectedEnvdForm ?? ''}
                            onChange={handleSeletedEnvd}>
                            {savedEnvdForms.map(({formName, docId}, index) => (
                              <MenuItem
                                onClick={() =>
                                  handleAddNewForm('envd', 'selectForm')
                                }
                                value={docId}
                                key={index}>
                                {formName}
                              </MenuItem>
                            ))}
                            <MenuItem
                              onClick={() => handleAddNewForm('envd', 'addNew')}
                              value={'Add New'}
                              key={'addnew'}>
                              {'Add New'}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {state.addNewEnvd && (
                        <Grid container item xs={6} style={{padding: 10}}>
                          <Typography
                            variant="h6"
                            role="label"
                            style={{fontWeight: 700, marginBottom: 10}}>
                            Form Name
                          </Typography>
                          <TextField
                            id={'envdFormNameTextfield'}
                            fullWidth
                            variant="outlined"
                            value={state.envdFormName}
                            onChange={handleNewEnvdFormNameChange}
                          />
                        </Grid>
                      )}
                    </>
                  ) : (
                    <Grid
                      container
                      item
                      xs={6}
                      direction="column"
                      style={{padding: 10}}>
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        {'Form Name'}
                      </Typography>
                      <Typography variant="h6" style={{marginTop: 8}}>
                        {state.envdFormName}
                      </Typography>
                    </Grid>
                  )}
                  <>
                    {savedEnvdForms.length > 0 &&
                    state.selectedEnvdForm.length > 0 &&
                    !state.addNewEnvd ? (
                      <FormTemplate
                        formDocId={state.selectedEnvdForm}
                        formType={'LPAC1'}
                        handleCompletedAnswers={handleCompletedAnswers}
                        formName={'eNVD'}
                        viewAnswers={
                          Object.keys(state.envdAnswers).length > 0
                            ? state.envdAnswers
                            : undefined
                        }
                        mode={state.envdMode}
                        handleFormMode={handleFormMode}
                      />
                    ) : (
                      <>
                        <FormTemplate
                          formName={'eNVD'}
                          formType={'LPAC1'}
                          viewAnswers={
                            /*(path.includes('consignment/edit') ||
                              path.includes('consignment/duplicate')) &&*/
                            Object.keys(state.envdAnswers).length > 0
                              ? state.envdAnswers
                              : undefined
                          }
                          handleCompletedAnswers={handleCompletedAnswers}
                          mode={state.envdMode}
                          handleFormMode={handleFormMode}
                          newForm={state.addNewEnvd ? true : false}
                          completedAnswer={state.envdCompleted}
                        />
                      </>
                    )}
                  </>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              style={{
                // display: 'flex',
                marginTop: 35,
              }}>
              <CheckCircle
                className={
                  state.hdCompleted
                    ? classes.IconChecked
                    : classes.IconUnchecked
                }
              />
              <Grid
                container
                item
                id={'hdExpand'}
                style={{
                  marginLeft: 24,
                  justifyContent: 'space-between',
                  flex: 1,
                }}
                onClick={handleHdClick}>
                <Typography variant="h3" role="label" style={{}}>
                  Health Declaration
                </Typography>
                {state.hdExpand ? <ExpandMore /> : <ExpandLess />}
                <Divider className={classes.dividerStyle} />
              </Grid>
              <Grid
                container
                item
                style={{
                  marginTop: 16,
                  display: state.hdExpand ? 'flex' : 'none',
                }}>
                <Grid container item className={classes.formCardContainer}>
                  {!state.hdCompleted ? (
                    <>
                      <Grid container item xs={6} style={{padding: 10}}>
                        <Typography
                          variant="h6"
                          role="label"
                          style={{fontWeight: 700, marginBottom: 10}}>
                          Saved Forms
                        </Typography>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="hd-form-type-select">
                            {'Please select'}
                          </InputLabel>
                          <Select
                            labelId="envd-form-select"
                            label="Please select"
                            value={state.selectedHdForm ?? ''}
                            onChange={handleSeletedHd}>
                            {savedHdForms.map(({formName, docId}, index) => (
                              <MenuItem
                                onClick={() =>
                                  handleAddNewForm('hd', 'selectForm')
                                }
                                value={docId}
                                key={index}>
                                {formName}
                              </MenuItem>
                            ))}
                            <MenuItem
                              onClick={() => handleAddNewForm('hd', 'addNew')}
                              key={'addnew'}
                              value={'Add New'}>
                              {'Add New'}
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {state.addNewHd && (
                        <Grid container item xs={6} style={{padding: 10}}>
                          <Typography
                            variant="h6"
                            role="label"
                            style={{fontWeight: 700, marginBottom: 10}}>
                            Form Name
                          </Typography>
                          <TextField
                            id={'hdFormNameTextfield'}
                            fullWidth
                            variant="outlined"
                            value={state.hdFormName}
                            onChange={handleNewHdFormNameChange}
                          />
                        </Grid>
                      )}
                    </>
                  ) : (
                    <Grid
                      container
                      item
                      xs={6}
                      direction="column"
                      style={{padding: 10}}>
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        {'Form Name'}
                      </Typography>
                      <Typography variant="h6" style={{marginTop: 8}}>
                        {state.hdFormName}
                      </Typography>
                    </Grid>
                  )}

                  <>
                    {savedHdForms.length > 0 &&
                    state.selectedHdForm.length > 0 &&
                    !state.addNewHd ? (
                      <>
                        <Grid container item>
                          <FormTemplate
                            formDocId={state.selectedHdForm}
                            formType="HSC2"
                            handleCompletedAnswers={handleCompletedAnswers}
                            mode={state.hdMode}
                            viewAnswers={
                              Object.keys(state.hdAnswers).length > 0
                                ? state.hdAnswers
                                : undefined
                            }
                            handleFormMode={handleFormMode}
                          />
                        </Grid>
                      </>
                    ) : (
                      <FormTemplate
                        formName={'Health Declaration'}
                        formType={'HSC2'}
                        viewAnswers={
                          /*(path.includes('consignment/edit') ||
                            path.includes('consignment/duplicate')) &&*/
                          Object.keys(state.hdAnswers).length > 0
                            ? state.hdAnswers
                            : undefined
                        }
                        handleCompletedAnswers={handleCompletedAnswers}
                        mode={state.hdMode}
                        handleFormMode={handleFormMode}
                        newForm={state.addNewHd ? true : false}
                        completedAnswer={state.hdCompleted}
                      />
                    )}
                  </>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {state.envdCompleted && (
          <Grid container item style={{marginTop: 50}}>
            <Grid container item xs={12} className={classes.formTitleContainer}>
              <Typography variant="h3" role="label">
                Declarations
              </Typography>
              <Divider className={classes.dividerStyle} />
            </Grid>
            <Grid container item alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={state.signDeclarationChecked}
                    onChange={handleSignDeclarationCheck}
                    color="primary"
                    name="SignAllDeclarations"
                    id={'declarationCheck'}
                  />
                }
                label="Sign All Declarations"
              />
            </Grid>
            <Grid container item style={{marginTop: 24}}>
              <Box
                p={'8px 16px 8px 16px'}
                ml={5}
                style={{
                  backgroundColor: COLOR.GRAY_SOLID,
                }}>
                <Typography style={{fontWeight: 700}} role="label">
                  eNVD
                </Typography>
                <Divider className={classes.boxDividerStyle} />
                <Typography variant="h6" role="label" style={{marginTop: 8}}>
                  {CONSTANTS.FORM_DECLARATIONS.envd}
                </Typography>
              </Box>
            </Grid>
            {state.hdCompleted && (
              <Grid container item style={{marginTop: 24}}>
                <Box
                  p={'8px 16px 8px 16px'}
                  ml={5}
                  style={{
                    backgroundColor: COLOR.GRAY_SOLID,
                  }}>
                  <Typography style={{fontWeight: 700}} role="label">
                    Health Declaration
                  </Typography>
                  <Divider className={classes.boxDividerStyle} />
                  <Typography variant="h6" role="label" style={{marginTop: 8}}>
                    {CONSTANTS.FORM_DECLARATIONS.hd}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        )}
        <Grid container item style={{marginTop: 50}}>
          <Grid container item xs={12} className={classes.formTitleContainer}>
            <Typography variant="h3" role="label">
              Attachments
            </Typography>
            <Divider className={classes.dividerStyle} />
          </Grid>
          <Grid container direction="row" style={{marginTop: 30}}>
            <Grid item xs={5}>
              <MyButton
                text={'Attach File'}
                variant="outlined"
                onClick={handleAttachClick}
              />
              <input
                type="file"
                id={'AttachFile'}
                onChange={uploadFileHandler('attachments')}
                ref={attachFileUpload}
                style={{height: 0, width: 0}}
              />
            </Grid>
            <Grid item xs={5}>
              <Box
                id={'attachField'}
                p={'8px 16px 8px 16px'}
                ml={5}
                style={{
                  backgroundColor: COLOR.GRAY_SOLID,
                }}>
                {state.attachments.length === 0 ? (
                  <Typography variant="h6" role="label" style={{marginTop: 8}}>
                    {'No file uploaded'}
                  </Typography>
                ) : (
                  state.attachments.map((attachment, index) => {
                    return (
                      <Grid item container key={index}>
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                          className={classes.attachField}>
                          <HyperLink
                            href={attachment.link}
                            target="_blank"
                            rel="noreferrer noopener">
                            {attachment.name}
                          </HyperLink>
                          <DeleteIcon
                            id={`AttachDelete${index}`}
                            onClick={() => {
                              handleDeleteAttach(index);
                            }}
                            style={{cursor: 'pointer', color: COLOR.GRAY_TEXT}}
                          />
                        </Grid>
                        <Divider className={classes.boxDividerStyle} />
                      </Grid>
                    );
                  })
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item style={{marginTop: 50}}>
          <Grid container item xs={12} className={classes.formTitleContainer}>
            <Typography variant="h3" role="label">
              Share
            </Typography>
            <Divider className={classes.dividerStyle} />
          </Grid>
          <Grid
            container
            direction="row"
            className={classes.shareAddFiled}
            // spacing={2}
            alignItems="flex-start">
            <Grid container item xs={6}>
              <Typography style={{fontWeight: 700}} role="label">
                Email Address
              </Typography>
              {state.emailList.map((email, index) => {
                return (
                  <Grid container direction="row">
                    <Grid item container xs={5}>
                      <TextField
                        fullWidth
                        id={`shareEmail${index}`}
                        variant="outlined"
                        value={email}
                        onChange={handleEmailAddress(index)}
                        className={classes.shareAddFiled}
                        error={
                          state.validationList.email[index]?.helperText !== ''
                        }
                        helperText={
                          state.validationList.email[index]?.helperText
                        }
                      />
                    </Grid>
                    <DeleteIcon
                      id={`EmailDelete${index}`}
                      onClick={() => {
                        handleDeleteEmail(index);
                      }}
                      className={classes.shareToDelete}
                    />
                  </Grid>
                );
              })}

              <Grid container direction="row" className={classes.shareAddFiled}>
                <AddBoxIcon style={{marginTop: 7}} />
                <Button
                  id={'AddEmail'}
                  onClick={() => {
                    localDispatch({
                      type: 'update/emailList',
                      emailList: [...state.emailList, ''],
                    });
                    localDispatch({
                      type: 'update/validationList',
                      validationList: {
                        ...state.validationList,
                        email: [
                          ...state.validationList.email,
                          {helperText: ''},
                        ],
                      },
                    });
                  }}>
                  <span
                    style={{
                      color: COLOR.GREENT_TEXT,
                      fontSize: 18,
                      textTransform: 'capitalize',
                    }}>
                    <u>{'Add'}</u>
                  </span>
                </Button>
              </Grid>
            </Grid>
            <Grid container item direction="column" xs={6}>
              <Typography style={{fontWeight: 700}} role="label">
                Mobile Number
              </Typography>
              {state.mobileList.map((mobile, index) => {
                return (
                  <Grid container direction="row">
                    <Grid item container xs={5}>
                      <PhoneInput
                        id={`sharePhone${index}`}
                        international
                        defaultCountry="AU"
                        withCountryCallingCode={false}
                        countryCallingCodeEditable={true}
                        inputComponent={MobileInput}
                        value={mobile}
                        countries={['AU', 'CA', 'AR']}
                        onChange={handleMobileAddress(index)}
                        helperText={
                          state.validationList.mobile[index].helperText
                        }
                        error={
                          state.validationList.mobile[index]?.helperText !== ''
                        }
                        className={classes.shareToMobile}
                      />
                    </Grid>
                    <DeleteIcon
                      id={`PhoneDelete${index}`}
                      onClick={() => {
                        handleDeleteMobile(index);
                      }}
                      className={classes.shareToDelete}
                    />
                  </Grid>
                );
              })}
              <Grid container direction="row" className={classes.shareAddFiled}>
                <AddBoxIcon style={{marginTop: 7}} />
                <Button
                  id={'AddMobile'}
                  onClick={() => {
                    localDispatch({
                      type: 'update/mobileList',
                      mobileList: [...state.mobileList, ''],
                    });
                    localDispatch({
                      type: 'update/validationList',
                      validationList: {
                        ...state.validationList,
                        mobile: [
                          ...state.validationList.mobile,
                          {helperText: ''},
                        ],
                      },
                    });
                  }}>
                  <span
                    style={{
                      color: COLOR.GREENT_TEXT,
                      fontSize: 18,
                      textTransform: 'capitalize',
                    }}>
                    <u>{'Add'}</u>
                  </span>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" className={classes.buttonContainer}>
          <MyButton
            text={'Save as Draft'}
            variant="outlined"
            width={280}
            fontSize={18}
            buttonClass={classes.draftButton}
            disabled={
              !state.consignmentName ||
              !(
                state.selectedAnimalInfo.length ||
                state.csvData.name ||
                state.selectedGroupIndex !== ''
              ) ||
              (state.destinationPICRadio === 'newPIC' &&
                !(
                  state.destinationPIC &&
                  state.address &&
                  state.postcode &&
                  state.town &&
                  state.selectedState
                )) ||
              (state.addNewEnvd && !state.envdFormName) ||
              (state.addNewHd && !state.hdFormName)
            }
            onClick={saveDraftProcess}
          />
          <MyButton
            text={'Submit'}
            variant="contained"
            width={280}
            fontSize={18}
            disabled={
              !(Object.keys(state.envdAnswers).length === 0
                ? true
                : state.signDeclarationChecked) ||
              state.selectedConsigneeIndex === '' ||
              !state.consignmentName ||
              (state.destinationPICRadio === 'newPIC' &&
                !(
                  state.destinationPIC &&
                  state.address &&
                  state.postcode &&
                  state.town &&
                  state.selectedState
                )) ||
              !(
                state.selectedAnimalInfo.length ||
                state.csvData.file ||
                state.selectedGroupIndex !== ''
              ) ||
              (state.addNewEnvd && !state.envdFormName) ||
              (state.addNewHd && !state.hdFormName) ||
              !(isCattle ? (state.envdCompleted === true ? true : false) : true)
            }
            onClick={handleSubmitConsignment}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const CreateConsignment = withHeader(
  {
    title: 'Create Consignment',
    back: true,
  },
  Consignment,
);

export const EditConsignment = withHeader(
  {
    title: 'Edit Consignment',
    back: true,
  },
  Consignment,
);

export const DuplicateConsignment = withHeader(
  {
    title: 'Duplicate Consignment',
    back: true,
  },
  Consignment,
);

const cellTheme = createTheme({
  palette: {
    secondary: {
      main: COLOR.GREEN,
    },
  },
});

const cellComponent = (props) => (
  <MuiThemeProvider theme={cellTheme}>
    <TableSelection.Cell {...props} />
  </MuiThemeProvider>
);

/** extract each type questions from json */
export const extractQuestionType = (
  jsonData: Array<FormType>,
  type: string,
) => {
  const questions = jsonData.find((item) => item.type === type)?.questions;
  const questionIdObject = {};
  questions.map((question) => {
    questionIdObject[question.questionId] = true;
    if (question.subQuestions?.Yes) {
      question.subQuestions.Yes.inputs?.map(
        (input) => (questionIdObject[input.questionId] = true),
      );
    }
    if (question.subQuestions?.No) {
      question.subQuestions.No.inputs?.map(
        (input) => (questionIdObject[input.questionId] = true),
      );
    }
    if (question.style === 'boxed' || question.style === 'whole_box') {
      question.inputs?.map((input) => {
        questionIdObject[input.questionId] = true;
        // use subQuestionId as question id for 'boolean_date_text' text
        if (
          input.type === 'boolean_date' ||
          input.type === 'boolean_date_text'
        ) {
          questionIdObject[input.subQuestionId] = true;
        }
      });
    }
  });
  return questionIdObject;
};
const clearWarning = (obj: {
  [key: string]: any;
}): {
  email: {helperText: string}[];
  mobile: {helperText: string}[];
} => {
  let newObj = {};
  for (const key in obj) {
    if (typeof obj[key as string] === 'string') {
      newObj[key] = '';
    } else if (Array.isArray(obj[key])) {
      newObj[key] = obj[key].map((item: {[key: string]: any}) =>
        clearWarning(item),
      );
    }
  }
  return newObj as {
    email: {helperText: string}[];
    mobile: {helperText: string}[];
  };
};
