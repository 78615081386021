import React from 'react';

import {Typography} from '@material-ui/core';

import {useStyles} from './styles';
import {PromotionLandingContentT} from './types';

interface PromotionLandingContentProps {
  content: PromotionLandingContentT;
  tryItOutGap?: boolean; // default to true
  leftIndent?: boolean;
}

const PromotionLandingContent: React.FC<PromotionLandingContentProps> = ({content, tryItOutGap = true, leftIndent}) => (
  <>
    <Typography variant="h2" gutterBottom={true}>
      Three-In-One Powerful Tool
    </Typography>
    <br />
    <LandingContent
      subheader="1. Phone Camera Scan"
      {...content.phoneCamera}
      leftIndent={leftIndent}
      tryItOutGap={tryItOutGap}
    />
    <LandingContent
      subheader="2. Aglive App Scan"
      {...content.agliveApp}
      leftIndent={leftIndent}
      tryItOutGap={tryItOutGap}
    />
    <LandingContent
      subheader={`3. ${content.saleYard.customTitle || "Sale Yard Poster"}`}
      {...content.saleYard}
      leftIndent={leftIndent}
      tryItOutGap={tryItOutGap}
    />
  </>
);

interface LandingContentProps {
  subheader: string;
  description: string;
  tryItOut?: string;
  tryItOutGap?: boolean;
  leftIndent?: boolean;
}

const LandingContent: React.FC<LandingContentProps> = ({
  subheader,
  description,
  tryItOut,
  tryItOutGap,
  leftIndent,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.landingContentContainer}>
      <Typography component="h4" className={classes.landingContentSubheader}>
        {subheader}
      </Typography>
      <Typography variant="body2" style={{paddingLeft: leftIndent ? '22px' : undefined}}>
        {description}
        <br />
        {tryItOutGap && <br />}
        {tryItOut && (
          <span>
            <b>Try it out:</b> {tryItOut}
          </span>
        )}
      </Typography>
    </div>
  );
};

export default PromotionLandingContent;
