export interface GroupDataTypes {
  name: string;
  animalsItems: {rfid?: string; nlis?: string; agliveToken?: string}[];
  animalsItemsCount: string;
  pic_id: string;
  createdAt: Date;
  agliveToken?: string;
}

export interface groupState {
  readonly groupData: Array<GroupDataTypes>;
}

export const FETCH_GROUP_DATA = 'FETCH_GROUP_DATA';

interface fetchGroupDataType {
  type: typeof FETCH_GROUP_DATA;
  payload: GroupDataTypes;
}

export type addTypes = Array<fetchGroupDataType>;
