export interface GroupDataTypes{
    externalIds: Array<{
        agliveToken: string,
        timestamp: string
      }>;
      status: Array<string>;
      type: string;
      tokenId: string;
      details: any;
      userId: string;
      hash: string;
      activities: Array<{
        type: string;
        userId: string;
        details:any;
        createdAt: string;
        hash: string;
      }>
}

export interface AssetDataTypes extends GroupDataTypes{}

export interface activityState {
    readonly groups: Array<GroupDataTypes>;  
    readonly singleAssets: Array<AssetDataTypes>;
}

export interface formType {
  type:string;
  url:string;
  checked:boolean;
}

export interface fileType {
  url:string;
}
export const FETCH_GROUP_PROJECTS='FETCH_GROUP_PROJECTS';
export const FETCH_ASSET_PROJECTS='FETCH_ASSET_PROJECTS';