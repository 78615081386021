import styled from "styled-components";
import agliveBackground from "../../img/agliveBackground.jpg";

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${agliveBackground});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1920px 1080px;
  display: flex;
`;

export const LoginBox = styled.div`
  height: 100%;
  width: 100%;
  max-width: 996px;
  max-height: 548px;
  margin: auto;
  background-color: #ffffff;
  display: flex;
`;

export const LeftSection = styled.div`
  height: 100%;
  width: 100%;
  max-width: 478px;
  background: linear-gradient(135.25deg, #1e7b3e 0%, #87d978 100%);
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const LoginSteps = styled.div`
  max-width: 344px;
  text-align: left;
  margin-top: 60px;
  margin-left: 50px;
`;

export const Instruction = styled.span`
  color: #373935;
  width: 100%;
  max-width: 430px;
  margin: 0 auto;
  font-family: Open Sans Bold;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 23px;
`;

export const Line = styled.hr`
  width: 95%;
  color: #d9d9d9;
  margin-top: ${(props) => (props.mt ? props.mt : "0")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0")};
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 20px;
  text-align: left;
  margin-left: 30px;
`;

export const ContactBox = styled.div`
  width: 100%;
  max-width: 412px;
  display: flex;
  justify-content: space-between;
`;

export const PhoneContainer = styled.div`
  width: 175px;
  height: 28px;
  display: flex;
  justify-content: space-between;
`;

export const MailContainer = styled.div`
  width: 175px;
  height: 28px;
  display: flex;
  justify-content: space-between;
`;

export const AgliveLink = styled.a`
  font-family: Open Sans;
  font-size: 16px;
  line-height: 22px;
  color: #3fae2a;
  text-align: center;
`;

export const RightSection = styled.div`
  width: 100%;
  height: 100%;
  max-width: 518px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
`;

export const QRcodeBox = styled.div`
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  margin-top: 36px;
`;
