import {StyleSheet} from '@react-pdf/renderer';
import COLOR from '../../../styled/colors';

// All units are calculated from px to pt. @react-pdf/renderer does not support pixels
export const styles = StyleSheet.create({
  posterContainer: {
    width: 270.5,
    height: 595.25,
    position: 'relative',
  },
  pageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Open Sans',
  },
  headingContainer: {
    padding: '24 18 24 18',
    paddingBottom: 0,
  },
  protectedBy: {
    borderBottom: `1.5 solid ${COLOR.GRAY_BORDER}`,
    paddingBottom: 6,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  protectedByText: {
    fontWeight: 600,
    fontSize: 18.75,
    marginRight: 7,
    color: '#373935',
  },
  agliveLogo: {
    width: 83,
    padding: 1,
  },
  angusVerifiedContainer: {
    marginTop: 12,
    backgroundColor: COLOR.GRAY_SOLID,
    width: '100%',
    padding: '1 0 1 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  angusVerifiedText: {
    fontSize: 12,
    fontWeight: 600,
    marginLeft: 6,
    color: '#373935',
  },
  middleSectionContainer: {
    padding: 24,
    paddingTop: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    marginTop: 18,
    width: 203,
    height: 98,
    objectFit: 'contain',
  },
  discoverMsgContainer: {
    width: 160,
    marginVertical: 12,
  },
  discoverMsgText: {
    fontSize: 12,
    fontWeight: 600,
    textAlign: 'center',
    color: '#373935',
  },
  qrContainer: {
    padding: 6,
  },
  footerContainer: {
    width: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    bottom: 0,
  },
  footerImage: {
    width: '100%',
    maxHeight: 203,
    objectFit: 'contain',
  },
});
