import {Message} from '../../presentation/Modal';

export interface modalState extends Message {
  readonly isOpen: boolean;
}

export const MODAL_TOGGLE_ON = 'MODAL_TOGGLE_ON';
export const MODAL_TOGGLE_OFF = 'MODAL_TOGGLE_OFF';

interface toggleModalT {
  type: typeof MODAL_TOGGLE_ON;
  payload: modalState;
}

interface toggleModalOff {
  type: typeof MODAL_TOGGLE_OFF;
}

export type ModalTypes = toggleModalT | toggleModalOff;