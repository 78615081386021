import {TokenService} from '@aglive/data-model';
import {
  MobDetailsMediaItemAction,
  MobDetailsMediaUrlAction,
  MobDetailsLinkMobAction,
  MobDetailsDisplayAction,
  MobDetailsSignedAction,
  MobDetailsDeclarationsAction,
  MobDetailsMsaAction,
  MobDetailsEditAttachmentsAction,
  MobDetailsAddAttachmentAction,
  MobDetailsDeleteAttachmentAction,
  MobDetailsTabAction,
} from './types';

const editMediaItem = (
  value: TokenService.AnimalPromotion['details']['mob']['media']['item'],
): MobDetailsMediaItemAction => ({
  type: 'mob_details/media_item',
  payload: value,
});

const editMediaUrl = (value: string): MobDetailsMediaUrlAction => ({
  type: 'mob_details/media_url',
  payload: value,
});

const editLinkMob = (value: string): MobDetailsLinkMobAction => ({
  type: 'mob_details/link_mob',
  payload: value,
});

const editDisplay = (
  value: 'mob' | 'animal' | 'mob_animal',
): MobDetailsDisplayAction => ({
  type: 'mob_details/display',
  payload: value,
});

const editMsa = (value: boolean): MobDetailsMsaAction => ({
  type: 'mob_details/msa',
  payload: value,
});

const editSigned = (value: boolean): MobDetailsSignedAction => ({
  type: 'mob_details/signed',
  payload: value,
});

const editDeclarations = (
  key: 'ERP' | 'BRD' | 'WHP' | 'WHP_ESI' | 'fiveInOne',
  value: boolean,
): MobDetailsDeclarationsAction => ({
  type: 'mob_details/declarations',
  payload: {
    key,
    value,
  },
});

const editAttachments = (
  keys: Array<string | number>,
  value: string,
): MobDetailsEditAttachmentsAction => ({
  type: 'mob_details/edit_attachments',
  payload: {
    keys,
    value,
  },
});

const addAttachment = (): MobDetailsAddAttachmentAction => ({
  type: 'mob_details/add_attachment',
});

const deleteAttachment = (
  attachmentIndex: number,
): MobDetailsDeleteAttachmentAction => ({
  type: 'mob_details/delete_attachment',
  payload: {
    attachmentIndex,
  },
});

const changeMobTab = (value: number): MobDetailsTabAction => ({
  type: 'mob_details/select_tab',
  payload: value,
});

export default {
  editMediaItem,
  editMediaUrl,
  editLinkMob,
  editDisplay,
  editMsa,
  editSigned,
  editDeclarations,
  editAttachments,
  addAttachment,
  deleteAttachment,
  changeMobTab,
};
