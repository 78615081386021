import {
  Grid,
  makeStyles,
  Typography,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import {MultipleSelect} from '../../presentation/MultipleSelect';
import DatePicker from '../../presentation/DatePicker';
import {OuterFilterField} from './ReportsFields';
import moment from 'moment';

const useStyle = makeStyles((theme) => ({
  inputRangeShorten: {
    maxWidth: '75%',
  },
}));

export const OuterFilterFields: React.FC<{
  field: OuterFilterField;
  state: any;
  optionalState?: any;
  setState: any;
  errorState?: any;
  customItem?: any;
}> = ({field, state, setState, errorState, optionalState, customItem}) => {
  const classes = useStyle();

  const generateOuterFilterField = () => {
    switch (field.type) {
      case 'number':
        return (
          <TextField
            id={`number-${field.key}`}
            type={field.type}
            name={field.key}
            InputLabelProps={{shrink: true}}
            variant={field.variant ? 'standard' : 'outlined'}
            fullWidth
            value={state[field.key] ?? ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
              setState(field.key, e.target.value)
            }
            InputProps={{
              inputProps: {
                min: field.inputProps.min,
                step: field.inputProps.step,
              },
            }}
            error={state[field.key] < field.inputProps.min}
            helperText={
              state[field.key] < field.inputProps.min
                ? `Please enter number larger than ${field.inputProps.min - 1}`
                : ''
            }
          />
        );
      case 'text':
        return (
          <TextField
            id={`text-${field.key}`}
            type={field.type}
            name={field.key}
            InputLabelProps={{shrink: true}}
            variant={field.variant ? 'standard' : 'outlined'}
            fullWidth
            value={state[field.key] || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setState(field.key, e.target.value)
            }
            // onChange={setState}
            //   className={field.sublabel ? classes.inputRangeShorten : ''}
          />
        );
      case 'multiselect':
        return (
          <MultipleSelect
            id={`multiselect-${field.key}`}
            dataSet={field?.options ?? optionalState[field.key2] ?? []}
            selectedData={(state.selectedFilters ? state.selectedFilters[field.key] : state[field.key]) || []}
            setSelectedData={(payload) => setState(field.key, payload)}
          />
        );
      case 'dropdown':
        return (
          <FormControl variant="outlined" fullWidth>
            {state[field.key] === '' && (
              <InputLabel id="report-form-type-select" shrink={false}>
                {'Please select'}
              </InputLabel>
            )}
            <Select
              labelId="supplier-select"
              value={
                (field.secondKey
                  ? `${state[field.key]}::${state[field.secondKey]}`
                  : state[field.key]) || ''
              }
              onChange={(payload) => {
                if (field.secondKey) {
                  const splitVal = String(payload.target.value).split('::');
                  setState(field.key, splitVal[0], {
                    [field.secondKey]: splitVal[1],
                  });
                  return;
                }
                setState(field.key, payload.target.value);
              }}>
              {field.options?.map(({label, value}, index) => (
                <MenuItem value={value} key={index}>
                  {label}
                </MenuItem>
              )) ??
                optionalState[field.key2]?.map(({label, value}, index) => (
                  <MenuItem value={value} key={index}>
                    {label}
                  </MenuItem>
                )) ??
                []}
            </Select>
          </FormControl>
        );
      case 'date':
        const disabledWeek = (current) => {
          return moment(current).day() !== Number(field.disableWeek);
        };
        return (
          <DatePicker
            id={`date-${field.key}`}
            dateValue={state[field.key]}
            handleChange={(date) => {
              setState(field.key, moment(date).startOf('date').toDate());
            }}
            errorStatus={errorState[field.key2]?.status ?? true}
            errorMessage={errorState[field.key2]?.message}
            disablePast={field.disablePast ?? false}
            shouldDisableDate={field.disableWeek && disabledWeek}
          />
        );
    }
  };
  return (
    <Grid container item sm={6} md={4}>
      <Typography variant="h6" role="label" style={{fontWeight: 600}}>
        {field.customOther ? customItem : field.label}
      </Typography>
      <Grid container direction="column">
        {generateOuterFilterField()}
      </Grid>
    </Grid>
  );
};
