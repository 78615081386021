import React, {useEffect, useState} from 'react';

import {Grid, Typography} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ReactPlayer from 'react-player/youtube';
import {TokenService} from '@aglive/data-model';
import moment from 'moment';

import {MockupPhoneNewWithNav} from '../MockUpPhoneNew';
import COLOR from '../../styled/colors';
import {useStyles} from './styles';
import NewDefaultImage from '../../img/NewDefaultImage.png';
import MobTable, {InfoMap} from './MobTable';
import {HyperLink} from '../word';

interface MobDetailsProps {
  mob: TokenService.AnimalPromotion['details']['mob'];
}

const MockupPhoneMobDetails: React.FC<MobDetailsProps> = ({mob}) => {
  const classes = useStyles();

  const [open, setOpen] = useState([]);
  const [showAllMobInfo, setShowAllMobInfo] = useState(false);
  const [mobTableHeight, setMobTableHeight] = useState(0);

  useEffect(() => {
    setOpen(mob.linkMob?.calves.map(() => false) || []);
  }, [mob.linkMob?.calves]);

  return (
    <MockupPhoneNewWithNav
      backgroundColor={COLOR.BLACK_BG}
      navTitle={'Mob Information'}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column">
        <Grid item container>
          <div className={classes.mediaPreview}>
            {mob.media.url &&
            mob.media.item === 'video' &&
            ReactPlayer.canPlay(mob.media.url) ? (
              <ReactPlayer
                url={mob.media.url}
                width="100%"
                height="100%"
                controls
              />
            ) : (
              <img
                src={
                  mob.media.item === 'image' && mob.media.url
                    ? mob.media.url
                    : NewDefaultImage
                }
                alt="Mob Information Media"
                className={classes.media}
              />
            )}
            {(!mob.media.url ||
              (mob.media.item === 'video' &&
                !ReactPlayer.canPlay(mob.media.url))) && (
              <Typography className={classes.defaultMediaText}>
                Image or Video Here
              </Typography>
            )}
          </div>
        </Grid>

        <Grid item container className={classes.previewDetailsContainer}>
          <Grid
            item
            container
            direction="column"
            className={classes.fieldContainer}>
            <Typography className={classes.previewHeader}>
              Linked Mob
            </Typography>
            <Typography className={classes.previewDefaultText}>
              {mob.linkMob?.mobName || '-'}
            </Typography>
          </Grid>

          <Grid
            item
            container
            direction="column"
            className={classes.fieldContainer}>
            <Typography className={classes.previewHeader}>
              Date Created
            </Typography>
            <Typography className={classes.previewDefaultText}>
              {(mob.linkMob?.createDate &&
                moment(mob.linkMob?.createDate).format('DD/MM/YYYY')) ||
                '-'}
            </Typography>
          </Grid>

          <Grid item container direction="column">
            {mob.display.isMob && mob.linkMob && (
              <>
                <Grid item>
                  <Typography variant="h5" className={classes.infoHeader}>
                    Mob
                  </Typography>
                </Grid>
                <Grid item>
                  {/* Create table collapse animation */}
                  <div
                    style={{
                      height: mobTableHeight,
                      overflow: 'hidden',
                      transition: '0.2s',
                    }}>
                    <MobTable
                      mobInfo={mob.linkMob.info}
                      showAllInfo={showAllMobInfo}
                      setMobTableHeight={setMobTableHeight}
                    />
                  </div>
                  {(Object.values(mob.linkMob.info).some((info) => !info) ||
                    Object.keys(mob.linkMob.info).length !==
                      Object.keys(InfoMap).length) && (
                    <Grid
                      container
                      className={classes.hideRecord}
                      justifyContent="flex-end">
                      <div
                        className={classes.hideBtn}
                        onClick={() =>
                          setShowAllMobInfo((prevState) => !prevState)
                        }>
                        {showAllMobInfo ? (
                          <>
                            Show Less{' '}
                            <ExpandLess className={classes.hideIcon} />
                          </>
                        ) : (
                          <>
                            Show More{' '}
                            <ExpandMore className={classes.hideIcon} />
                          </>
                        )}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </>
            )}

            {mob.display.isAnimal && mob.linkMob?.calves.length > 0 && (
              <>
                <Grid item>
                  <Typography variant="h5" className={classes.infoHeader}>
                    Animals
                  </Typography>
                </Grid>
                <Grid item container>
                  <Grid
                    item
                    container
                    xs={12}
                    className={`${classes.animalsTableHeader} ${classes.previewHeader}`}>
                    <Grid item xs={7}>
                      RFID
                    </Grid>
                    <Grid item xs={5}>
                      Sex
                    </Grid>
                  </Grid>
                  {mob.linkMob.calves.map((calve, index) => (
                    <React.Fragment key={index}>
                      <Grid
                        item
                        container
                        xs={12}
                        className={`${classes.animalRecord}`}>
                        <Grid item xs={7}>
                          <span
                            className={classes.hyperlink}
                            onClick={() => {
                              setOpen((prevState) => {
                                const temp = prevState.concat();
                                temp[index] = !temp[index];
                                return temp;
                              });
                            }}>
                            {calve.rfid}
                          </span>
                        </Grid>
                        <Grid item xs={5} style={{textTransform: 'capitalize'}}>
                          {calve.sex || '-'}
                        </Grid>
                      </Grid>

                      <Collapse in={open[index]}>
                        <Grid item container xs={12} justifyContent="flex-end">
                          <Grid item xs={11}>
                            <MobTable
                              mobInfo={{...mob.linkMob.info, ...calve}}
                              showAllInfo
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          container
                          xs={12}
                          className={classes.hideRecord}
                          justifyContent="flex-end">
                          <div
                            className={classes.hideBtn}
                            onClick={() => {
                              setOpen((prevState) => {
                                const temp = prevState.concat();
                                temp[index] = false;
                                return temp;
                              });
                            }}>
                            Hide <ExpandLess className={classes.hideIcon} />
                          </div>
                        </Grid>
                      </Collapse>
                    </React.Fragment>
                  ))}
                </Grid>
              </>
            )}

            {!!mob.attachment?.length && (
              <>
                <Grid item>
                  <Typography variant="h5" className={classes.infoHeader}>
                    Attachments
                  </Typography>
                </Grid>
                <Grid item style={{marginBottom: 9}}>
                  {mob.attachment?.map(({title, url}, index) => (
                    <React.Fragment key={index}>
                      <Typography
                        className={classes.previewHeader}
                        style={{marginTop: 9}}>
                        {title}
                      </Typography>
                      <HyperLink
                        href={url}
                        target="_blank"
                        style={{fontSize: 14, lineHeight: '13px'}}>
                        {decodeURIComponent(url.split('/').reverse()[0])}
                      </HyperLink>
                    </React.Fragment>
                  ))}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </MockupPhoneNewWithNav>
  );
};

export default MockupPhoneMobDetails;
