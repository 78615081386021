import React, {
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from 'react';
import COLOR from '../../styled/colors';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {useHistory, useParams, useRouteMatch} from 'react-router-dom';
import withHeader from '../../presentation/withHeader';
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  TextField,
  IconButton,
} from '@material-ui/core';
import {
  Table,
  TableHeaderRow,
  Grid as TableGrid,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {PagingState, IntegratedPaging} from '@devexpress/dx-react-grid';
import {styled} from '@mui/material/styles';
import MyButton from '../../presentation/button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import RemoveIcon from '@material-ui/icons/Remove';

import {editGroup} from '../../store/group/actions';
import {initailGroup} from '../../store/group/reducers';
import {GroupDataTypes} from '../../store/group/types';
import moment from 'moment';

const StyledTable = styled(Table.Table)(({theme}) => ({
  backgroundColor: COLOR.WHITE,
}));
const StyledPaging = styled(PagingPanel.Container)(({theme}) => ({
  marginTop: 20,
}));
const StyledHeader = styled(TableHeaderRow.Content)(({theme}) => ({
  fontWeight: 700,
}));

const HeaderComponentBase = ({classes, ...props}) => (
  <Table.TableHead {...props} style={{backgroundColor: COLOR.GRAY_SOLID}} />
);

export const TableComponent = (props) => <StyledTable {...props} />;
export const PagingComponent = (props) => <StyledPaging {...props} />;
export const HeaderComponent = (props) => <StyledHeader {...props} />;

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    marginBottom: 80,
  },
  dropdownContainer: {
    marginRight: 50,
  },
  buttonContainer: {
    marginTop: 20,
  },
  dividerStyle: {
    width: '90%',
    marginTop: 10,
  },
  itemButton: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    marginRight: 20,
  },
}));

const initialState = {
  groupName: '',
  groupMode: '' as 'SAVE' | 'EDIT',
  group: initailGroup,
  showAnimalList: [],
  currentPage: 0,
  pageSize: 10,
  error: {
    groupName: false,
  },
};

type Action =
  | {type: 'change/groupName'; groupName: string}
  | {type: 'change/groupMode'; groupMode: 'SAVE' | 'EDIT'}
  | {type: 'change/showAnimalList'; showAnimalList: Array<any>}
  | {type: 'change/page'; currentPage: number}
  | {type: 'change/pageSize'; pageSize: number}
  | {type: 'update/group'; group: GroupDataTypes}
  | {type: 'change/errorMessage'; error: typeof initialState['error']};

const reducer = (
  prevState: typeof initialState,
  action: Action,
): typeof initialState => {
  const {type, ...actionData} = action;
  switch (action.type) {
    default:
      return {...prevState, ...actionData};
  }
};

const Group: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {path} = useRouteMatch();
  const [state, localDispatch] = useReducer(reducer, initialState);

  const {id} = useParams<{id: string}>();

  const groupList: Array<GroupDataTypes> = useAppSelector(
    (state) => state.group.groupData,
  );

  const groupName = useMemo(() => {
    return groupList.map((g) => g.name);
  }, [groupList]);

  const targetIndex = groupList.findIndex((group) => group.agliveToken === id);

  const group = groupList[targetIndex];

  const handleChangeName = (value: string) => {
    localDispatch({
      type: 'change/groupName',
      groupName: value,
    });
    if (
      groupName?.length &&
      groupName.includes(value) &&
      !group.name.includes(value)
    ) {
      localDispatch({
        type: 'change/errorMessage',
        error: {groupName: true},
      });
    } else if (state.error.groupName) {
      localDispatch({
        type: 'change/errorMessage',
        error: {groupName: false},
      });
    }
  };

  const handleButtonClick = (type: string) => {
    switch (type) {
      case 'EditButton':
        localDispatch({
          type: 'change/groupMode',
          groupMode: 'SAVE',
        });
        break;
      case 'SaveButton':
        dispatch(
          editGroup(state.groupName, group.agliveToken, () => {
            localDispatch({
              type: 'change/groupMode',
              groupMode: 'EDIT',
            });
          }),
        );
        break;
    }
  };

  const columns = [
    {name: 'id', title: 'No.'},
    {name: 'idRfid', title: 'ID/RFID'},
    {name: 'nlis', title: 'NLIS ID (If applicable)'},
  ];

  const columnWidths = (cols: Array<{name: string; title: string}>) =>
    cols.map((dt) => ({
      columnName: dt.name,
      width: dt.name === 'id' ? 120 : 380,
    }));

  const changePage = useCallback((pageNum: number) => {
    localDispatch({type: 'change/page', currentPage: pageNum});
  }, []);
  const changePageSize = useCallback((size: number) => {
    localDispatch({type: 'change/pageSize', pageSize: size});
  }, []);

  useEffect(() => {
    let animalList = group?.animalsItems?.map((item, index) => {
      return {
        id: index + 1,
        idRfid: item?.rfid?.length > 0 ? item?.rfid : item?.agliveToken ?? '',
        nlis: item?.nlis ?? '',
      };
    });
    localDispatch({
      type: 'change/showAnimalList',
      showAnimalList: animalList,
    });
    localDispatch({
      type: 'change/groupName',
      groupName: group.name,
    });
  }, []);

  useEffect(() => {
    localDispatch({
      type: 'update/group',
      group: {...group},
    });
  }, [group]);

  return (
    <>
      {/* Group Name / Select Location */}
      <Grid alignItems="center" container style={{marginBottom: 20}}>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginBottom: 10,
            }}>
            {'Group/Mob Name'}
          </Typography>

          <Grid alignItems="center" container>
            {state.groupMode === 'SAVE' ? (
              <>
                <TextField
                  style={{
                    margin: 0,
                    width: '80%',
                  }}
                  value={state.groupName}
                  onChange={(e) => handleChangeName(e.target.value)}
                  margin="normal"
                  variant="outlined"
                  error={state.error.groupName}
                  id={"groupName"}
                  helperText={
                    state.error.groupName
                      ? 'This group/mob name already exists'
                      : ''
                  }
                />
                <IconButton
                  style={{
                    marginLeft: 20,
                  }}
                  disabled={state.error.groupName}
                  id={"saveIcon"}>
                  <SaveIcon
                    onClick={() => {
                      handleButtonClick('SaveButton');
                    }}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <Typography
                  variant="h6"
                  role="label"
                  style={{width: '85%'}}
                  id={'viewGroupName'}>
                  {state.groupName}
                </Typography>

                {path.includes('edit') && (
                  <IconButton style={{padding: 0}} id={"editIcon"}>
                    <EditIcon
                      fontSize="small"
                      onClick={() => {
                        handleButtonClick('EditButton');
                      }}
                      
                    />
                  </IconButton>
                )}
                <Divider
                  className={classes.dividerStyle}
                  style={{opacity: 0.5}}
                />
              </>
            )}
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginBottom: state.groupMode === 'SAVE' ? 30 : 10,
            }}>
            {`Select Location e.g. PIC}`}
          </Typography>
          <Typography
            variant="h6"
            role="label"
            style={{marginBottom: 10}}
            id={'viewGroupPic'}>
            {state.group.pic_id}
          </Typography>
          <Divider className={classes.dividerStyle} style={{opacity: 0.5}} />
        </Grid>
      </Grid>
      <Grid alignItems="center" container style={{marginBottom: 20}}>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginBottom: 10,
            }}>
            {'Items/Animals'}
          </Typography>

          <Typography
            variant="h6"
            role="label"
            style={{marginBottom: 10}}
            id={'viewGroupAnimalsItemsCount'}>
            {state.group.animalsItemsCount}
            <Divider className={classes.dividerStyle} style={{opacity: 0.5}} />
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginBottom: 10,
            }}>
            {`Date Created`}
          </Typography>
          <Typography
            variant="h6"
            role="label"
            style={{marginBottom: 10}}
            id={'viewCreatedAt'}>
            {moment(state.group.createdAt).format('DD/MM/YYYY')}
            <Divider className={classes.dividerStyle} style={{opacity: 0.5}} />
          </Typography>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        container
        style={{marginTop: 50, justifyContent: 'flex-start'}}>
        <TableGrid rows={state.showAnimalList} columns={columns}>
          <PagingState
            currentPage={state.currentPage}
            onCurrentPageChange={changePage}
            pageSize={state.pageSize}
            onPageSizeChange={changePageSize}
          />
          <IntegratedPaging />
          <Table
            tableComponent={TableComponent}
            headComponent={HeaderComponentBase}
            columnExtensions={columnWidths(columns)}
          />
          <TableHeaderRow contentComponent={HeaderComponent} />
          <PagingPanel
            pageSizes={[5, 10, 15, 20]}
            containerComponent={PagingComponent}
          />
        </TableGrid>
        {path.includes('edit') && (
          <Grid container className={classes.buttonContainer}>
            <MyButton
              text={'Add Items/Animals'}
              variant="outlined"
              width={190}
              height={50}
              fontSize={18}
              buttonClass={classes.itemButton}
              startIcon={<AddIcon />}
              onClick={() =>
                history.push(path.replace('edit/:id', `add/${id}`))
              }
            />
            <MyButton
              text={'Remove Items/Animals'}
              variant="outlined"
              width={220}
              height={50}
              fontSize={18}
              buttonClass={classes.itemButton}
              startIcon={<RemoveIcon />}
              onClick={() =>
                history.push(path.replace('edit/:id', `remove/${id}`))
              }
            />
          </Grid>
        )}
      </Grid>

      {path.includes('view') && (
        <Grid
          container
          direction="row-reverse"
          className={classes.buttonContainer}>
          <MyButton
            text={'Edit'}
            variant="contained"
            width={280}
            height={50}
            marginTop={50}
            fontSize={18}
            onClick={() => {
              history.replace(
                `/private/activity/group/edit/${state.group.agliveToken}`,
              );
            }}
          />
        </Grid>
      )}
    </>
  );
};

export const ViewGroup = withHeader(
  {
    title: 'View Group/Mob',
    back: true,
  },
  Group,
);

export const EditGroup = withHeader(
  {
    title: 'Edit Group/Mob',
    back: true,
  },
  Group,
);
