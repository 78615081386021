import { FETCH_CODE_TOKENS, codeState, codeActions } from './types';

const initialState: codeState = [];

export default function codeReducer(
  state = initialState,
  action: codeActions
): codeState {
  switch(action.type) {
    case FETCH_CODE_TOKENS:
      return action.payload;
    default:
      return state;
  }
}