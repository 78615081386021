import produce from 'immer';
import {GroupDataTypes, activityState ,FETCH_GROUP_PROJECTS,FETCH_ASSET_PROJECTS} from './types';

const initialState: activityState = {
    groups:[],
    singleAssets:[],
};

export default function activityReducer(state = initialState, action) : activityState{
    switch (action.type) {      
        case FETCH_GROUP_PROJECTS:
            return produce(state, draft => {
                draft.groups=action.payload;
              });
        case FETCH_ASSET_PROJECTS:
        return produce(state, draft => {
            draft.singleAssets=action.payload;
            });
        default:
            return state;
    }
}