import React, {useState, useEffect, useMemo} from 'react';
import {useAppSelector, useAppDispatch} from '../../utils/hooks';
import {useHistory} from 'react-router';
import {getBusinessProfile, getUserProfile} from '../../store/profile/actions';
import withHeader from '../../presentation/withHeader';
import {
  Grid,
  makeStyles,
  Typography,
  ListItem,
  Collapse,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import COLOR from '../../styled/colors';
import MyButton from '../../presentation/button';
import moment from 'moment';
import {businessProfileObject} from '@aglive/frontend-core';
import {PageHeader} from '../../presentation/withHeader';
import CONSTANT from '../../config/constant';
import {BusinessMisc} from '@aglive/data-model';
import {CASL} from '@aglive/frontend-core';

const useStyle = makeStyles(() => ({
  imageStyle: {
    width: '100%',
    height: '100%',
    maxWidth: 312,
    maxHeight: 212,
  },
  titleContainer: {
    borderWidth: 1,
    borderStyle: 'none none solid none',
    borderColor: COLOR.GRAY_BORDER,
    paddingBottom: 10,
    marginTop: 40,
  },
}));

const BusinessProfile: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const userId = useAppSelector((state) => state.auth.wallet);
  const [businessTitles, setBusinessTitles] = useState(CONSTANT.EN_LABELS);
  const businessProfileDoc = useAppSelector(
    (state) =>
      state.user.businessProfileData as
        | BusinessMisc.AnimalBusinessInterface
        | BusinessMisc.PlantBusinessInterface,
  );

  let optiweighData = {
    interval: '',
    apiKey: '',
    clientId: '',
    agliveKey: '',
  };
  if (businessProfileDoc.industryType === 'ANIMALS') {
    optiweighData = businessProfileDoc.optiweigh;
  }

  const businessProfile = useMemo(
    () => businessProfileObject(businessProfileDoc),
    [businessProfileDoc],
  );
  const userProfile = useAppSelector((state) => state.user.userProfileData);
  const EDIT_BUSINESS_PROFILE = '/private/profile/businessProfile/edit';

  const initialCollapseState = {
    location: false,
    LPA: false,
    NLIS: false,
  };

  const locationArrObj = [];

  // multiple location
  const [collapse, setCollapse] = useState(
    locationArrObj as Array<typeof initialCollapseState>,
  );
  // multiple location
  const handleCollapse =
    (collapseName: 'location' | 'NLIS' | 'LPA', index: number) => (e) => {
      let updateState = [...collapse];
      updateState[index] = {
        ...updateState[index],
        [collapseName]: !updateState[index][collapseName],
      };
      setCollapse(updateState);
    };

  const permitsList =
    businessProfileDoc.industryType === 'PLANTS'
      ? businessProfileDoc.permits
      : [];
  const [permitsCollapse, SetPermitsCollapse] = useState(
    new Array(permitsList?.length ?? 0).fill(false),
  );
  const handlePermitCollapse = (index: number) => () => {
    let tempCollapse = [...permitsCollapse];
    tempCollapse[index] = !tempCollapse[index];
    SetPermitsCollapse(tempCollapse);
  };

  const classes = useStyle();
  useEffect(() => {
    for (let i = 0; i <= businessProfile.numLocations(); i++) {
      locationArrObj.push(initialCollapseState);
    }
    setCollapse(locationArrObj);
  }, [businessProfile.numLocations()]);
  useEffect(() => {
    dispatch(getBusinessProfile());
    dispatch(getUserProfile(userId));
  }, []);
  useEffect(() => {
    switch (businessProfileDoc.businessCountry) {
      case 'Argentina':
        setBusinessTitles((pre) => ({...pre, ...CONSTANT.ES_LABELS}));
        break;
      case 'Australia':
      case 'Canada':
      default:
        setBusinessTitles((pre) => ({...pre, ...CONSTANT.EN_LABELS}));
        break;
    }
  }, [businessProfileDoc.businessCountry]);

  return (
    <PageHeader
      config={{
        title: businessTitles.viewBusinessProfile,
        margin: 60,
        back: false,
      }}>
      <Grid container direction="column">
        {/* <Grid container justify="center" alignItems="center">
        <img src={defaultImage} className={classes.imageStyle} alt="" />
      </Grid> */}
        <Grid container>
          <Grid item container xs={6} direction="column">
            <Typography variant="h6" style={{fontWeight: 600}}>
              {businessTitles.companyName}
            </Typography>
            <Typography variant="h6" style={{marginTop: 8}}>
              {businessProfileDoc.companyName}
            </Typography>
          </Grid>
          <Grid item container xs={6} direction="column">
            <Typography variant="h6" style={{fontWeight: 600}}>
              {businessTitles.companyNumber}
            </Typography>
            <Typography variant="h6" style={{marginTop: 8}}>
              {businessProfileDoc.companyNumber}
            </Typography>
          </Grid>

          {/* Hide country field for non Livestock user */}
          {businessProfile.isLivestock() && (
            <Grid
              item
              container
              xs={6}
              direction="column"
              style={{marginTop: 15}}>
              <Typography variant="h6" style={{fontWeight: 600}}>
                {businessTitles.country}
              </Typography>
              <Typography variant="h6" style={{marginTop: 8}}>
                {businessProfileDoc.businessCountry ?? 'Australia'}
              </Typography>
            </Grid>
          )}
          {businessProfile.isArgentina() && (
            <Grid
              item
              container
              xs={6}
              direction="column"
              style={{marginTop: 15}}>
              <Typography variant="h6" style={{fontWeight: 600}}>
                {businessTitles.CUIG}
              </Typography>
              <Typography variant="h6" style={{marginTop: 8}}>
                {businessProfileDoc.cuig}
              </Typography>
            </Grid>
          )}
        </Grid>

        {businessProfileDoc.industryType === 'PLANTS' && (
          <>
            <Grid container style={{marginTop: 64}}>
              <Grid item container xs={6} direction="column">
                <Typography variant="h6" style={{fontWeight: 600}}>
                  {businessTitles.licenseNumber}
                </Typography>
                <Typography variant="h6" style={{marginTop: 8}}>
                  {businessProfileDoc.licenseNumber}
                </Typography>
              </Grid>
              <Grid item container xs={6} direction="column">
                <Typography variant="h6" style={{fontWeight: 600}}>
                  {businessTitles.licenseExpiryDate}
                </Typography>
                <Typography variant="h6" style={{marginTop: 8}}>
                  {moment(businessProfileDoc.licenseExpiryDate).format(
                    'DD/MM/YYYY',
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item>
              <Typography variant="h2" role="label" style={{marginTop: '80px'}}>
                {businessTitles.permits}
              </Typography>
            </Grid>
            {permitsList?.map((permit, index: number) => (
              <Grid item xs={12} key={'permit' + index}>
                <div className={classes.titleContainer}>
                  <ListItem
                    style={{paddingLeft: 0}}
                    button
                    onClick={handlePermitCollapse(index)}>
                    <Grid container justify="space-between">
                      <Typography variant="h3" role="label">
                        {`${businessTitles.permit} ${index + 1}`}
                      </Typography>
                      {permitsCollapse[index] ? <ExpandLess /> : <ExpandMore />}
                    </Grid>
                  </ListItem>
                </div>
                <Collapse in={permitsCollapse[index]}>
                  <Grid container style={{marginTop: 64}}>
                    <Grid item container xs={6} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        {businessTitles.permitNumber}
                      </Typography>
                      <Typography variant="h6" style={{marginTop: 8}}>
                        {permit.permitNumber}
                      </Typography>
                    </Grid>
                    <Grid item container xs={6} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        {businessTitles.permitExpiryDate}
                      </Typography>
                      <Typography variant="h6" style={{marginTop: 8}}>
                        {moment(permit.permitExpiryDate).format('DD/MM/YYYY')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            ))}
          </>
        )}

        <Grid container item>
          <Typography variant="h2" role="label" style={{marginTop: 80}}>
            {businessTitles.myLocation}{' '}
            {businessProfile.isLivestock() &&
              !businessProfile.isArgentina() && <>e.g. PIC/PID</>}
          </Typography>
        </Grid>
        {businessProfileDoc.location?.map((location, index) => (
          <Grid item xs={12} key={'location' + index}>
            <div className={classes.titleContainer}>
              <ListItem
                style={{paddingLeft: 0}}
                button
                onClick={handleCollapse('location', index)}>
                <Grid container justify="space-between">
                  <Typography variant="h3" role="label">
                    {location?.locationNickname}
                  </Typography>
                  {collapse[index]?.location ? <ExpandLess /> : <ExpandMore />}
                </Grid>
              </ListItem>
            </div>
            <Collapse in={collapse[index]?.location}>
              <Grid container direction="column">
                {/* 1 */}
                <Grid container style={{marginTop: 24}}>
                  <Grid item container xs={6} direction="column">
                    <Typography variant="h6" style={{fontWeight: 600}}>
                      {businessTitles.locationNickname}
                    </Typography>
                    <Typography variant="h6" style={{marginTop: 8}}>
                      {location?.locationNickname}
                    </Typography>
                  </Grid>
                  <Grid item container xs={6} direction="column">
                    <Typography variant="h6" style={{fontWeight: 600}}>
                      {location.country === 'Argentina'
                        ? 'RENSPA'
                        : 'Location ID'}{' '}
                      {businessProfile.isLivestock() &&
                        location.country !== 'Argentina' && <>e.g. PIC/PID</>}
                    </Typography>
                    <Typography variant="h6" style={{marginTop: 8}}>
                      {businessProfile.isLivestock()
                        ? location?.PICAddress
                        : location?.locationId}
                    </Typography>
                  </Grid>
                </Grid>
                {/* 2 */}
                <Grid container style={{marginTop: 32}}>
                  <Grid item container xs={6} direction="column">
                    <Typography variant="h6" style={{fontWeight: 600}}>
                      {businessTitles.country}
                    </Typography>
                    <Typography variant="h6" style={{marginTop: 8}}>
                      {location?.country}
                    </Typography>
                  </Grid>
                  <Grid item container xs={6} direction="column">
                    <Typography variant="h6" style={{fontWeight: 600}}>
                      {businessTitles.state}
                    </Typography>
                    <Typography variant="h6" style={{marginTop: 8}}>
                      {location?.state}
                    </Typography>
                  </Grid>
                </Grid>
                {/* 3 */}
                <Grid container style={{marginTop: 32}}>
                  <Grid item container xs={6} direction="column">
                    <Typography variant="h6" style={{fontWeight: 600}}>
                      {businessTitles.suburb}
                    </Typography>
                    <Typography variant="h6" style={{marginTop: 8}}>
                      {location?.town}
                    </Typography>
                  </Grid>
                  <Grid item container xs={6} direction="column">
                    <Typography variant="h6" style={{fontWeight: 600}}>
                      {businessTitles.postcode}
                    </Typography>
                    <Typography variant="h6" style={{marginTop: 8}}>
                      {location?.postcode}
                    </Typography>
                  </Grid>
                </Grid>
                {/* 4 */}
                <Grid container style={{marginTop: 32}}>
                  <Grid item container xs={6} direction="column">
                    <Typography variant="h6" style={{fontWeight: 600}}>
                      {businessTitles.address}
                    </Typography>
                    <Typography variant="h6" style={{marginTop: 8}}>
                      {location?.address}
                    </Typography>
                  </Grid>
                </Grid>
                {businessProfile.isLivestock() &&
                  businessProfile.isAustralia() && (
                    <>
                      {/* 5 */}
                      <Grid container style={{marginTop: 32}}>
                        <Grid item container xs={6} direction="column">
                          <Typography variant="h6" style={{fontWeight: 600}}>
                            {businessTitles.nlisUser}
                          </Typography>
                          <Typography variant="h6" style={{marginTop: 8}}>
                            {location?.NLISUserID}
                          </Typography>
                        </Grid>
                        <Grid item container xs={6} direction="column">
                          <Typography variant="h6" style={{fontWeight: 600}}>
                            {businessTitles.nlisPassword}
                          </Typography>
                          <Typography variant="h6" style={{marginTop: 8}}>
                            {location?.NLISPassword}
                          </Typography>
                        </Grid>
                      </Grid>
                      {/* 6 */}
                      <Grid container style={{marginTop: 32}}>
                        <Grid item container xs={6} direction="column">
                          <Typography variant="h6" style={{fontWeight: 600}}>
                            {businessTitles.lpaUser}
                          </Typography>
                          <Typography variant="h6" style={{marginTop: 8}}>
                            {location?.LPAUserID}
                          </Typography>
                        </Grid>
                        <Grid item container xs={6} direction="column">
                          <Typography variant="h6" style={{fontWeight: 600}}>
                            {businessTitles.lpaPassword}
                          </Typography>
                          <Typography variant="h6" style={{marginTop: 8}}>
                            {location?.LPAPassword}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
              </Grid>
            </Collapse>
          </Grid>
        ))}
        {businessProfile.isLivestock() && (
          <>
            <Grid container item>
              <Typography variant="h2" role="label" style={{marginTop: 80}}>
                {businessTitles.CattleIdentification}
              </Typography>
            </Grid>
            <Grid container style={{marginTop: 48}}>
              <Grid item container xs={6} direction="column">
                <Typography variant="h6" style={{fontWeight: 600}}>
                  {businessTitles.brandDescription}
                </Typography>
                <Typography variant="h6" style={{marginTop: 8}}>
                  {businessProfileDoc.brandDescription}
                </Typography>
              </Grid>
              <Grid item container xs={6} direction="column">
                <Typography variant="h6" style={{fontWeight: 600}}>
                  {businessTitles.cattleBrandSymbol}
                </Typography>
                <Typography variant="h6" style={{marginTop: 8}}>
                  <img
                    style={{maxWidth: 223, maxHeight: 110}}
                    src={businessProfileDoc.imageUri}
                    alt=""
                  />
                </Typography>
              </Grid>
            </Grid>
            {businessProfile.isOptiweigh() && (
              <>
                <Grid container item>
                  <Typography variant="h2" role="label" style={{marginTop: 80}}>
                    Optiweigh Settings
                  </Typography>
                </Grid>
                <Grid container style={{marginTop: 48}}>
                  <Grid item container>
                    <Grid item container xs={4} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        Client ID
                      </Typography>
                      <Typography variant="h6" style={{marginTop: 8}}>
                        {optiweighData.clientId}
                      </Typography>
                    </Grid>
                    <Grid item container xs={8} direction="column">
                      <Typography variant="h6" style={{fontWeight: 600}}>
                        API Key
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{marginTop: 8, wordBreak: 'break-all'}}>
                        {optiweighData.apiKey}
                      </Typography>
                    </Grid>
                    <Grid item container xs={4} direction="column">
                      <Typography
                        variant="h6"
                        style={{fontWeight: 600, marginTop: 32}}>
                        Interval
                      </Typography>
                      <Typography variant="h6" style={{marginTop: 8}}>
                        {optiweighData.interval
                          ? `Every ${optiweighData.interval}`
                          : ''}
                      </Typography>
                    </Grid>
                    <Grid item container xs={8} direction="column">
                      <Typography
                        variant="h6"
                        style={{fontWeight: 600, marginTop: 32}}>
                        Aglive API Key
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{marginTop: 8, wordBreak: 'break-all'}}>
                        {optiweighData.agliveKey}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}

        <CASL.Can I="update" a="business">
          <Grid item container justify="flex-end" style={{marginTop: 200}}>
            <MyButton
              disabled={userProfile?.role === 'admin' ? false : true}
              text={businessTitles.edit}
              variant="contained"
              onClick={() => history.push(EDIT_BUSINESS_PROFILE)}
            />
          </Grid>
        </CASL.Can>
      </Grid>
    </PageHeader>
  );
};

export default BusinessProfile;
