import React, {useState, useEffect, useCallback} from 'react';
import moment from 'moment';
import {Link, useHistory} from 'react-router-dom';
import {Grid, makeStyles} from '@material-ui/core';
import {useAppDispatch} from '../../../utils/hooks';

import SearchBar from '../../../presentation/SearchBar';
import MyTable from '../../../presentation/Table';
import withHeader from '../../../presentation/withHeader';

import {getBrandAgliveTokenId} from '../utils';
import {toggleModal} from '../../../store/modal/actions';
import {callAPI} from '../../../utils/network';
import {fetchBrands} from '../../../store/brand/actions';

import {useStyles} from '../../promotion/PromotionLibrary/styles';

import {
  SPINNER_TOGGLE_OFF,
  SPINNER_TOGGLE_ON,
} from '../../../store/spinner/types';
import API from '../../../config/api';
import CONSTANT from '../../../config/constant';

import {TokenService} from '@aglive/data-model';
import {WebErrorType} from '../../../utils/error';
import {HyperLink} from '../../../presentation/word';
import {CASL} from '@aglive/frontend-core';

const BRAND_LIBRARY_PATH = '/private/profile/brand';

const useLocalStyle = makeStyles({
  firstColumnWidth: {
    minWidth: '40%',
    width: '55%',
  },
});

type Props = {};

const ArchivedBrandLibrary: React.FC<Props> = () => {
  const classes = useStyles();
  const history = useHistory();
  const localClasses = useLocalStyle();

  const dispatch = useAppDispatch();

  const ability = CASL.useAbility(CASL.AbilityContext);
  const data = ['Brand', 'Date Created'];
  ability.can('update', 'brand') ? data.push('Action') : data.push('');

  const [query, setQuery] = useState('');
  const [brandList, setBrandList] = useState<Array<TokenService.BrandToken>>(
    [],
  );

  const loadBrandTokens = useCallback(async () => {
    const brandTokens = await fetchBrands(dispatch);
    if (brandTokens) {
      setBrandList(brandTokens.filter((brand) => brand.details.archive));
    }
  }, [dispatch]);

  useEffect(() => {
    loadBrandTokens();
  }, [loadBrandTokens]);

  const onUnarchive = async (brand: TokenService.BrandToken) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.BRAND,
              externalIds: {
                agliveToken: getBrandAgliveTokenId(brand),
              },
              activities: [
                {
                  type: 'UP_details',
                  details: {
                    archive: false,
                  },
                },
              ],
            },
          ],
        },
      });

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Updated',
          CTAHandler: () => history.push(BRAND_LIBRARY_PATH),
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };

  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <SearchBar query={query} setQuery={setQuery} label="Search Brand" />
      </Grid>

      <MyTable
        firstColumnWidth={localClasses.firstColumnWidth}
        heads={data}
        rows={brandList
          ?.filter((brand) =>
            brand.details.name.toLowerCase().includes(query.toLowerCase()),
          )
          .map((brand) => [
            <Link
              to={{
                pathname: `${BRAND_LIBRARY_PATH}/view/${getBrandAgliveTokenId(
                  brand,
                )}`,
              }}
              className={classes.hyperlink}>
              {brand.details.name}
            </Link>,

            moment(brand.createdAt).format('DD/MM/YYYY'),
            <CASL.Can I="update" a="brand">
              <div style={{display: 'flex'}}>
                <HyperLink
                  onClick={(e) => {
                    e.preventDefault();
                    onUnarchive(brand);
                  }}
                  href="">
                  Unarchive
                </HyperLink>
              </div>
            </CASL.Can>,
          ])}
      />
    </>
  );
};

export default withHeader(
  {
    title: 'Archived Brands',
    back: true,
  },
  ArchivedBrandLibrary,
);
