import React, {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import COLOR from '../../styled/colors';
import SearchBar from '../../presentation/SearchBar';
import MyButton from '../../presentation/button';
import MyTable from '../../presentation/Table';
import withHeader from '../../presentation/withHeader';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import {makeStyles, Grid, Typography} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {ManageUserType} from '../../store/add/types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {initialUser} from '../../store/add/reducers';
import {createUserProject, featchAllUsers} from '../../store/add/actions';
import {getUserProfile} from '../../store/profile/actions';
import {PageHeader} from '../../presentation/withHeader';
import CONSTANT from '../../config/constant';
import {CASL} from '@aglive/frontend-core';

type Props = {};

const filterDataInitialState = {
  role: '' as string,
  status: '' as string,
};

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  searchBarContainer: {
    flexGrow: 1,
    marginRight: 50,
  },
  buttonContainer: {
    marginTop: 6,
    marginRight: 2,
  },
  filterRow: {
    flexDirection: 'row',
    display: 'flex',
    marginTop: 8,
    marginBottom: 30,
    alignItems: 'center',
  },
  formControl: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 210,
    maxHeight: 56,
    marginRight: 20,
  },
}));

const UserLibrary: React.FC<Props> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {path} = useRouteMatch();
  const userid = useAppSelector((state) => state.auth.wallet);
  const userProfile = useAppSelector((state) => state.user.userProfileData);
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const ability = CASL.useAbility(CASL.AbilityContext);

  const userLibraryLabels =
    businessProfile.businessCountry === 'Argentina'
      ? CONSTANT.ES_LABELS
      : CONSTANT.EN_LABELS;
  const data = [
    userLibraryLabels.name,
    userLibraryLabels.phone,
    userLibraryLabels.email,
    userLibraryLabels.role,
    userLibraryLabels.status,
  ];
  ability.can('update', 'user')
    ? data.push(userLibraryLabels.action)
    : data.push('');

  const roleList = [
    {label: userLibraryLabels.none, value: userLibraryLabels.none},
    {label: userLibraryLabels.admin, value: 'admin'},
    {label: userLibraryLabels.user, value: 'user'},
  ];
  // Read-only feature is currently not available to Angus account
  if (userProfile.businessId === CONSTANT.WARAKIRRI_BUSINESS_ID) {
    roleList.push({label: userLibraryLabels.readOnly, value: 'read-only'});
  }
  const statusList = [
    userLibraryLabels.none,
    userLibraryLabels.active,
    userLibraryLabels.inactive,
    userLibraryLabels.pending,
  ] as const;
  const [query, setQuery] = useState('');

  useEffect(() => {
    dispatch(featchAllUsers(userid));
    dispatch(getUserProfile(userid));
  }, []);

  const wholeList: Array<ManageUserType> = useAppSelector(
    (state) => state.add.users,
  );
  const [filterData, setFilterData] = useState(filterDataInitialState);

  const handleSelect =
    (key: keyof typeof filterDataInitialState) =>
    (e: React.ChangeEvent<{name?: string; value: unknown}>) =>
      setFilterData((prevState) => ({...prevState, [key]: e.target?.value}));

  return (
    <PageHeader
      config={{title: userLibraryLabels.userLibrary, margin: 60, back: false}}>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label={userLibraryLabels.search}
          />
        </Grid>
        <CASL.Can I="create" a="user">
          <Grid item className={classes.buttonContainer}>
            <MyButton
              text={userLibraryLabels.createNew}
              variant="contained"
              width={160}
              fontSize={18}
              onClick={() =>
                dispatch(createUserProject(initialUser)).then((uniqueID) =>
                  history.push(`${path}/${uniqueID}`),
                )
              }
            />
          </Grid>
        </CASL.Can>
      </Grid>
      <Grid item className={classes.filterRow}>
        <Typography
          variant="h6"
          role="label"
          style={{fontWeight: 600, marginRight: 20}}>
          {userLibraryLabels.filters}
        </Typography>
        <FormControl
          variant="outlined"
          fullWidth
          className={classes.formControl}>
          {filterData.role === '' && (
            <InputLabel id="user-library-select-role" shrink={false}>
              {`---${userLibraryLabels.role}---`}
            </InputLabel>
          )}
          <Select
            labelId="user-library-select-role"
            value={filterData.role}
            onChange={handleSelect('role')}>
            {roleList.map((role) => (
              <MenuItem value={role.value} key={role.value}>
                {role.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          fullWidth
          className={classes.formControl}>
          {filterData.status === '' && (
            <InputLabel id="user-library-select-status" shrink={false}>
              {`---${userLibraryLabels.status}---`}
            </InputLabel>
          )}
          <Select
            labelId="user-library-select-status"
            value={filterData.status}
            onChange={handleSelect('status')}>
            {statusList.map((status) => (
              <MenuItem value={status}>{status}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <MyTable
        heads={data}
        rows={wholeList
          .filter(
            (user) =>
              filterRole(user, filterData.role, userLibraryLabels) &&
              filterStatus(user, filterData.status, userLibraryLabels),
          )
          .filter((user) =>
            user.name.toLowerCase().includes(query.toLowerCase()),
          )
          .map((user, index) => {
            let status = '';
            let displayRole = '';
            let enStatus = '';
            if ('deactivatedDate' in user && user.deactivatedDate) {
              enStatus = 'Inactive';
              status = userLibraryLabels.inactive;
            } else if ('addedDate' in user) {
              status = userLibraryLabels.active;
              enStatus = 'Active';
            } else {
              status = userLibraryLabels.pending;
              enStatus = 'Pending';
            }
            roleList.forEach((role) => {
              if (role.value === user.role) {
                displayRole = role.label;
              }
            });

            return [
              <Button
                onClick={() => {
                  if (status === userLibraryLabels.pending) {
                    history.push({
                      pathname: `${path}/view/${user.invitationCode}`,
                      state: {status: enStatus},
                    });
                  } else {
                    history.push({
                      pathname: `${path}/view/${user.userId}`,
                      state: {status: enStatus},
                    });
                  }
                }}>
                <span
                  style={{
                    color: COLOR.GREENT_TEXT,
                    textTransform: 'capitalize',
                  }}>
                  <u>{user.name}</u>
                </span>
              </Button>,
              user.phone,
              user.email,
              displayRole,
              status,
              <CASL.Can I="update" a="user">
                <div style={{display: 'flex', marginRight: -100}}>
                  {/* TODO HACK */}
                  <div style={{marginRight: 20}}>
                    <EditIcon
                      onClick={() => {
                        if (status === userLibraryLabels.pending) {
                          history.push({
                            pathname: `${path}/${user.invitationCode}`,
                            state: {status: enStatus},
                          });
                        } else {
                          history.push({
                            pathname: `${path}/${user.userId}`,
                            state: {status: enStatus},
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </CASL.Can>,
            ];
          })}
      />
    </PageHeader>
  );
};

function determineUserStatus(
  user: ManageUserType,
  userLibraryLabels: typeof CONSTANT.EN_LABELS,
) {
  if ('deactivatedDate' in user && user.deactivatedDate) {
    return userLibraryLabels.inactive;
  } else if ('addedDate' in user) {
    return userLibraryLabels.active;
  } else {
    return userLibraryLabels.pending;
  }
}

function filterStatus(
  user: ManageUserType,
  status: string,
  userLibraryLabels: typeof CONSTANT.EN_LABELS,
) {
  switch (status) {
    case '':
    case userLibraryLabels.none:
      return true;
    default:
      return determineUserStatus(user, userLibraryLabels) === status;
  }
}

function filterRole(
  user: ManageUserType,
  role: string,
  userLibraryLabels: typeof CONSTANT.EN_LABELS,
) {
  switch (role) {
    case '':
    case userLibraryLabels.none:
      return true;
    default:
      return user.role === role;
  }
}

export default UserLibrary;
