import {makeStyles} from '@material-ui/core';

import COLOR from '../../styled/colors';

export const useStyle = makeStyles((theme) => ({
  progressBarContainer: {
    margin: theme.spacing(8),
  },
  step: {
    width: '32px',
    height: '32px',
    backgroundColor: COLOR.GRAY_BORDER,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.h6.fontWeight,
    position: 'relative',
  },
  stepLabel: {
    position: 'absolute',
    width: '200px',
    textAlign: 'center',
    bottom: -30,
  },
  accomplished: {
    backgroundColor: COLOR.GREEN_BUTTON,
  },
  activeStep: {
    width: '40px',
    height: '40px',
    fontWeight: 700,
  },
  clickableStep: {
    cursor: 'pointer',
  },
}));
