import {makeStyles} from '@material-ui/core';

import COLOR from '../../../styled/colors';

export const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  searchBarContainer: {
    flexGrow: 1,
  },
  buttonContainer: {
    marginTop: 6,
    marginRight: 2,
  },
  hyperlink: {
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    color: COLOR.GREENT_TEXT,
  },
  previewModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
