import React, { useState } from 'react';
import { Box, Grid, Modal, Typography } from '@material-ui/core';
import withHeader from '../../../presentation/withHeader';
import MainLandingCard, {MainLandingCardType} from './MainLandingCard';
import PromotionLanding from '../../promotion/PromotionLanding';
import SampleSecurityCode from '../../../img/promotion/SampleSecurityCode.svg';
import SampleProductPromotion from '../../../img/promotion/SampleProductPromotion.svg';
import { promotionLandingContent } from '../../promotion/PromotionLanding/promotionLandingContent';
import MyButton from '../../../presentation/button';


const MAIN_LANDING_CONFIG = (
  onOpen: (arg0: React.ReactNode) => void,
  onClose: () => void,
  ): MainLandingCardType[] => [
  {
    title: 'Promotion Codes',
    subtitle: 'Build brand awareness',
    subtitleExample: 'e.g. Poster, wobbler, display',
    helperText: 'A camera scan will show basic info. Detailed info is only displayed in an app scan',
    features: [
      {
        mark: true,
        feature: 'Logo and Images',
        scanView: ['Camera', 'App'],
      },
      {
        mark: true,
        feature: 'Customisable Content',
        scanView: ['Camera', 'App'],
      },
      {
        mark: true,
        feature: 'Video Links',
        scanView: ['App'],
      },
      {
        mark: true,
        feature: 'Attachments',
        scanView: ['App'],
      },
      {
        mark: false,
        feature: 'Standard Delivery History',
        scanView: ['App'],
      },
      {
        mark: false,
        feature: 'Confidential Mode',
        scanView: ['App'],
      },
    ],
    benefits: [
      'Online and offline promotional posters, banners, shelf or store displays etc.',
      'Use simple tools to build brand awareness',
      'Tell your brand story with a simple scan'
    ],
    selectPath: 'promotionCodes/new',
    onViewSample: () => onOpen(
      <Grid container direction="column" alignItems="center" style={{gap: 20}}>
        <PromotionLanding
          promotionImage={SampleProductPromotion}
          promotionLandingContent={promotionLandingContent('productPromotion')}
          showPromotionHeading={false}
        />
        <MyButton
          text="Close"
          variant="contained"
          onClick={onClose}
        />
      </Grid>
    )
  },
  {
    title: 'Security Codes',
    subtitle: 'Tell a deeper product story',
    subtitleExample: 'e.g. Shipping, export, retail',
    helperText: 'A camera scan will show basic info. Detailed info is only displayed in an app scan',
    features: [
      {
        mark: true,
        feature: 'Logo and Images',
        scanView: ['Camera', 'App'],
      },
      {
        mark: true,
        feature: 'Customisable Content',
        scanView: ['Camera', 'App'],
      },
      {
        mark: true,
        feature: 'Video Links',
        scanView: ['App'],
      },
      {
        mark: true,
        feature: 'Attachments',
        scanView: ['App'],
      },
      {
        mark: true,
        feature: 'Standard Delivery History',
        scanView: ['App'],
      },
      {
        mark: true,
        feature: 'Confidential Mode',
        scanView: ['App'],
      },
    ],
    benefits: [
      'Tell a deeper product story',
      'Display detailed information to help earn premium prices',
      'Use confidential mode on delivery history to hide sensitive information',
      'Enhance consumer engagement with certificates, verified information, and surveys'
    ],
    selectPath: 'securityCodes/new',
    onViewSample: () => onOpen(
      <Grid container direction="column" alignItems="center" style={{gap: 20}}>
        <PromotionLanding
          promotionImage={SampleSecurityCode}
          promotionLandingContent={promotionLandingContent('securityCodeLanding')}
          showPromotionHeading={false}
        />
        <MyButton
          text="Close"
          variant="contained"
          onClick={onClose}
        />
      </Grid>
    )
  },
];

const MainLanding: React.FC<{}> = () => {
  const [modalState, setModalState] = useState({
    show: false,
    content: null,
  });

  const onClose = () => setModalState(prevState => ({
    ...prevState,
    show: false
  }));

  const onOpen = (content: React.ReactNode) => setModalState({
    show: true,
    content
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'max-content',
    bgcolor: 'background.paper',
    padding: 20
  };
  

  return (
    <Grid container direction="column">
      <Grid item style={{alignSelf: 'center', margin: '60px 0'}}>
        <Typography variant="h2" style={{fontSize: 25}} gutterBottom>
          Select your code type
        </Typography>
      </Grid>

      <Grid item container style={{justifyContent: 'space-evenly'}}>
        {
          MAIN_LANDING_CONFIG(onOpen, onClose).map(config => (
            <MainLandingCard config={config} key={config.title} />
          ))
        }
      </Grid>

      <Modal
        open={modalState.show}
        onClose={onClose}
        >
          <Box sx={style}>
            {modalState.content}
          </Box>
        </Modal>
    </Grid>
  );
};

export default withHeader(
  {
    title: 'Codes',
    margin: 0
  },
  MainLanding,
);
