import styled from "styled-components";
import COLOR from "../styled/colors";
import {Link} from "react-router-dom";

const FontFamily = "Oswald";

export const Header1 = styled.h1`
  margin: 0;
  font-size: 50px;
  font-family: ${FontFamily};
  line-height: 74px;
  color: ${(props) => (props.invert ? COLOR.BLACK_BG : COLOR.WHITE)};
  font-weight: 400;
`;

export const Header2 = styled.h2`
  margin: 0;
  font-size: 35px;
  font-family: ${FontFamily};
  line-height: 52px;
  color: ${(props) => (props.invert ? COLOR.BLACK_BG : COLOR.WHITE)};
  font-weight: 400;
  padding-top: ${(props) => (props.pt ? props.pt : "")};
`;

export const Header3 = styled.h3`
  margin: 0;
  font-size: 25px;
  font-family: ${FontFamily};
  line-height: 37px;
  color: ${(props) => (props.invert ? COLOR.BLACK_BG : COLOR.WHITE)};
  font-weight: 400;
`;

export const Paragraph = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  font-family: Open Sans;
  line-height: 22px;
  letter-spacing: 0;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "")};
  color: ${(props) => (props.color ? props.color : COLOR.BLACK_BG)};
  margin: ${(props) => (props.margin ? props.margin : "1em 0em 1em 0em")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "")};
`;

export const HyperLink = styled.a`
  font-size: 16px;
  font-family: Open Sans;
  line-height: 22px;
  color: ${(props) => (props.invert ? COLOR.WHITE : COLOR.GREENT_TEXT)};
`;

export const RouterLink = styled(Link)`
  font-size: 16px;
  font-family: Open Sans;
  line-height: 22px;
  color: ${(props) => (props.invert ? COLOR.WHITE : COLOR.GREENT_TEXT)};
`;
