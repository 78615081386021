import React, {useEffect, useReducer, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import {
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
  InputLabel,
  Select,
  FormHelperText,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import moment from 'moment';
import {TokenService} from '@aglive/data-model';
import Qrcode from 'qrcode.react';
import {pdf} from '@react-pdf/renderer';

import {PageHeader} from '../../../presentation/withHeader';
import ProgressBar from '../../../presentation/ProgressBar';
import TellYourStory from '../TellYourStory';
import {useStyles} from './styles';
import MyButton from '../../../presentation/button';
import {useAppDispatch, useAppSelector} from '../../../utils/hooks';
import {fetchBrands} from '../../../store/brand/actions';
import MobDetails from '../MobDetails';
import {validateData} from './utils';
import {
  assetPromotionReducer,
  INITIAL_STATE,
} from './assetPromotionState/reducer';
import DisplayPoster from '../DisplayPoster';
import {
  createPromotion,
  editPromotion,
  getLinkMobList,
  getOnePromotion,
} from '../../../store/promotion/actions';
import {toggleModal, toggleModalOff} from '../../../store/modal/actions';
import ScanPosterModalContent from './ScanPosterModalContent';
import {Buttons} from '../../../presentation/ButtonsGroup';
import {useDownloadPoster} from '../../../utils/promotion';
import {Message} from '../../../presentation/Modal';
import LocalActions from './assetPromotionState/actions';
import {localDispatchDecorator} from '../ProductProfile/CreateProductProfile/productProfileState/actions';
import {
  SPINNER_TOGGLE_OFF,
  SPINNER_TOGGLE_ON,
} from '../../../store/spinner/types';
import PromotionPDF from '../PromotionPDF';
import CONSTANT from '../../../config/constant';

const CreateAssetPromotion: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {id} = useParams<{id: string}>();

  const [assetPromotionState, localDispatch] = useReducer(
    assetPromotionReducer,
    INITIAL_STATE,
  );
  const localDispatchAction = localDispatchDecorator(localDispatch);

  const userId = useAppSelector((state) => state.auth.wallet);
  const isAngus = useAppSelector(
    // (state) => !!state.user.businessProfileData.angusProfile,
    (state) =>
      state.user.businessProfileData.industryType === 'ANIMALS' &&
      state.user.businessProfileData.businessType === 'Angus',
  );

  const [assetPromotionValidation, setAssetPromotionValidation] = useState(
    INITIAL_STATE.payload,
  );

  // Custom hooks for download poster
  const {
    promotionToDownload,
    setPromotionToDownload,
    QRCodeImage,
    getQRCodeSize,
    downloadPosterHandler,
  } = useDownloadPoster();

  // Fetch existing promotion token if id is found in URL
  useEffect(() => {
    (async () => {
      const brandTokens = await fetchBrands(dispatch);
      const mobList = await dispatch(getLinkMobList(isAngus));

      let currentBrand = '';

      if (id) {
        const promotionToken = await dispatch(
          getOnePromotion(id, history, 'ANIMALS'),
        );
        if (promotionToken && promotionToken.details.type === 'ANIMALS') {
          const promotionDetails = promotionToken.details;
          currentBrand = promotionDetails.brand.agliveToken;
          localDispatch(LocalActions.retrieveAssetPromotion(promotionDetails));
          localDispatch(LocalActions.storeOldPromotion(promotionDetails));
          localDispatch(
            LocalActions.editMainSectionImageOption(
              promotionDetails.story.image ? 'image' : '',
            ),
          );
          localDispatch(
            LocalActions.editFooterImageOption(
              promotionDetails.display.footerImage ? 'image' : '',
            ),
          );
        }
      }

      if (brandTokens) {
        const filteredBrandList = brandTokens.filter(
          (brandToken) =>
            !brandToken.details.archive ||
            brandToken.externalIds.find(
              (externalId) => 'agliveToken' in externalId,
            ).agliveToken === currentBrand,
        );

        if (currentBrand) {
          const brandIndex = filteredBrandList.findIndex(
            (brand) =>
              brand.externalIds.find(
                (externalId) => 'agliveToken' in externalId,
              ).agliveToken === currentBrand,
          );
          localDispatch(LocalActions.selectBrandIndex(brandIndex));
        }

        localDispatch(LocalActions.getBrandList(filteredBrandList));
      }
      if (mobList) {
        localDispatch(LocalActions.getLinkMobList(mobList));
      }
    })();
  }, [dispatch, userId, id, history, isAngus]);

  // Validate input on keyup after submit button is clicked
  useEffect(() => {
    if (
      assetPromotionState.state.submitted ||
      assetPromotionState.state.currentStepError
    ) {
      const [validatedData] = validateData(
        assetPromotionState.payload,
        assetPromotionState.state.mainSectionImageOption,
        assetPromotionState.state.footerImageOption,
        assetPromotionState.state.submitted
          ? 'display'
          : assetPromotionState.state.section,
        assetPromotionState.state.submitted,
      );
      setAssetPromotionValidation(validatedData);
    }
  }, [
    assetPromotionState.payload,
    assetPromotionState.state.footerImageOption,
    assetPromotionState.state.mainSectionImageOption,
    assetPromotionState.state.submitted,
    assetPromotionState.state.currentStepError,
    assetPromotionState.state.section,
  ]);

  // Prompt success modal only after the poster PDF is ready (useEffect below will only run for published promotion)
  useEffect(() => {
    if (promotionToDownload && QRCodeImage.length > 0) {
      promptSuccessModal(promotionToDownload, 'publish');
    }
  }, [promotionToDownload, QRCodeImage]);

  const progressBarSteps = [
    {
      label: 'Tell Your Story',
      onClick: () => localDispatch(LocalActions.navigate('story')),
    },
    {
      label: 'Mob Details',
      onClick: () => localDispatch(LocalActions.navigate('mob')),
    },
    {
      label: 'Display',
      onClick: () => {
        // Prompt error modal if missing details in mob details
        assetPromotionState.state.section === 'mob' && checkMobMedia();
        localDispatch(LocalActions.navigate('display'));
      },
    },
  ];

  const downloadPoster = async () => {
    if (promotionToDownload) {
      dispatch({type: SPINNER_TOGGLE_ON});
      const QRSize = await getQRCodeSize(promotionToDownload, isAngus);
      const blob = await pdf(
        <PromotionPDF
          story={promotionToDownload?.details.story}
          display={promotionToDownload?.details.display}
          isAngus={isAngus}
          QRCodeImage={QRCodeImage}
          QRSize={QRSize}
        />,
      ).toBlob();
      downloadPosterHandler(blob, promotionToDownload?.details.name);
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };

  const checkMobMedia = () => {
    if (!assetPromotionState.payload.mob.media.url.trim().length) {
      dispatch(
        toggleModal({
          status: 'warning',
          title: 'Just One More Step',
          subtitle: 'Image or Video Required in Mob Details',
          button: 'Close',
        }),
      );
    }
  };

  const handleSave = async (type: 'draft' | 'publish') => {
    localDispatch(LocalActions.setSubmitFlag(type));
    const [validatedData, error, section, storyTab, mobTab] = validateData(
      assetPromotionState.payload,
      assetPromotionState.state.mainSectionImageOption,
      assetPromotionState.state.footerImageOption,
      'display',
      type,
    );
    setAssetPromotionValidation(validatedData);

    // Bring user to the page/tab that has errors
    section && localDispatch(LocalActions.navigate(section));
    storyTab !== null && localDispatch(LocalActions.changeTab(storyTab));
    mobTab !== null && localDispatch(LocalActions.changeMobTab(mobTab));

    // Prompt error modal if missing details in mob details
    checkMobMedia();

    if (
      type === 'publish' &&
      (!assetPromotionState.payload.display.startDate ||
        !assetPromotionState.payload.display.endDate ||
        moment(assetPromotionState.payload.display.endDate).valueOf() <
          moment().valueOf())
    ) {
      // Prompt error modal if invalid date provided
      dispatch(
        toggleModal({
          status: 'failed',
          title: 'Error',
          subtitle: 'Valid expiry date required',
          button: 'Close',
          renderButton: (
            <Buttons
              leftButtonTitle="Cancel"
              rightButtonTitle="Set Date"
              leftButtonOnClick={() => {
                dispatch(toggleModalOff());
                history.push('/private/assets/promotions');
              }}
              rightButtonOnClick={() => {
                dispatch(toggleModalOff());
                localDispatch(LocalActions.navigate('display'));
              }}
            />
          ),
        }),
      );
    } else if (error === 0) {
      let promotionToken: void | TokenService.PromotionToken;
      if (!id) {
        promotionToken = await dispatch(
          createPromotion(assetPromotionState.payload, type === 'publish'),
        );
      } else {
        await dispatch(
          editPromotion(
            assetPromotionState.payload,
            id,
            type === 'publish',
            assetPromotionState.state.originalAssetPromotion,
          ),
        );
        promotionToken = await dispatch(
          getOnePromotion(id, history, 'ANIMALS'),
        );
      }

      if (promotionToken && type === 'publish') {
        if (
          'story' in promotionToken.details &&
          promotionToken.details.story.logoType === 'brand'
        ) {
          promotionToken.details.story.logo =
            assetPromotionState.state.brandList[
              +assetPromotionState.state.selectedBrand
            ]?.logo;
        }
        setPromotionToDownload(promotionToken as TokenService.AnimalPromotion);
      } else if (promotionToken) {
        promptSuccessModal(promotionToken, 'draft');
      }
    }
  };

  const promptSuccessModal = (
    promotionToken: TokenService.PromotionToken,
    type: 'draft' | 'publish',
  ) => {
    const payload: Message = {
      status: 'success',
      title: type === 'publish' ? 'Published' : 'Saved',
      button: 'Close',
      customContent: (
        <ScanPosterModalContent
          story={assetPromotionState.payload.story}
          display={assetPromotionState.payload.display}
          status={promotionToken.details.status}
          footerImageOption={
            assetPromotionState.payload.display.footerImage ? 'image' : ''
          }
          agliveToken={promotionToken.externalIds[0].agliveToken}
          isAngus={isAngus}
          brandLogo={
            assetPromotionState.state.selectedBrand !== ''
              ? assetPromotionState.state.brandList[
                  +assetPromotionState.state.selectedBrand
                ]?.logo
              : null
          }
        />
      ),
      width: 520,
      CTAHandler: () => {
        history.push('/private/assets/promotions');
      },
    };

    if (type === 'publish') {
      delete payload.CTAHandler;
      payload.renderButton = (
        <Buttons
          leftButtonTitle="Close"
          rightButtonTitle="Download"
          leftButtonOnClick={() => {
            dispatch(toggleModalOff());
            history.push('/private/assets/promotions');
          }}
          rightButtonOnClick={downloadPoster}
        />
      );
    }

    dispatch(toggleModal(payload));
  };

  const CreateAssetPromotionContent = (
    <>
      <Grid container spacing={8} justifyContent="center">
        <Grid item xs={6}>
          <Typography variant="h6" role="label" style={{fontWeight: 700}}>
            Promotion Name
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={assetPromotionState.payload.name}
            onChange={(e) => {
              localDispatch(LocalActions.editName(e.target.value));
            }}
            error={!!assetPromotionValidation.name}
            helperText={assetPromotionValidation.name}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" role="label" style={{fontWeight: 700}}>
            Brand
          </Typography>
          <FormControl
            fullWidth
            variant="outlined"
            error={!!assetPromotionValidation.brand.agliveToken}>
            <InputLabel id="create-assetPromotion-select-brand">
              Please select
            </InputLabel>
            <Select
              value={
                assetPromotionState.state.brandList.length > 0
                  ? assetPromotionState.state.selectedBrand
                  : ''
              }
              labelId="create-assetPromotion-select-brand"
              label="Please select"
              onChange={(e) => {
                const index = +e.target.value;
                localDispatch(
                  LocalActions.editBrand(
                    assetPromotionState.state.brandList[index]?.agliveToken,
                  ),
                );
                localDispatch(LocalActions.selectBrandIndex(index));
              }}>
              {assetPromotionState.state.brandList.map((brand, index) => (
                <MenuItem value={index} key={brand.agliveToken}>
                  {brand.name}
                </MenuItem>
              ))}
            </Select>
            {!!assetPromotionValidation.brand.agliveToken && (
              <FormHelperText>
                {assetPromotionValidation.brand.agliveToken}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <ProgressBar
            percent={assetPromotionState.state.progressBarPosition}
            steps={progressBarSteps}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.stepContainer}>
        {assetPromotionState.state.section === 'story' && (
          <TellYourStory
            tabValue={assetPromotionState.state.focusedTabIndex}
            story={assetPromotionState.payload.story}
            mainSectionImageOption={
              assetPromotionState.state.mainSectionImageOption
            }
            isAngus={isAngus}
            brandLogo={
              assetPromotionState.state.selectedBrand !== ''
                ? assetPromotionState.state.brandList[
                    +assetPromotionState.state.selectedBrand
                  ]?.logo
                : null
            }
            validationState={assetPromotionValidation.story}
            onTabChange={localDispatchAction(LocalActions.changeTab)}
            onMainSectionImageOptionChange={localDispatchAction(
              LocalActions.editMainSectionImageOption,
            )}
            onLogoChange={localDispatchAction(LocalActions.editLogo)}
            onLogoTypeChange={localDispatchAction(LocalActions.editLogoType)}
            onMainSectionImageChange={localDispatchAction(
              LocalActions.editImage,
            )}
            onHeadlineChange={localDispatchAction(LocalActions.editHeadline)}
            onContentChange={localDispatchAction(LocalActions.editContent)}
            onAddMilestonesEntry={localDispatchAction(
              LocalActions.addMilestonesEntry,
            )}
            onDeleteMilestonesEntry={localDispatchAction(
              LocalActions.deleteMilestonesEntry,
            )}
            onDupMilestonesEntry={localDispatchAction(
              LocalActions.dupMilestonesEntry,
            )}
            onMilestonesTitleChange={localDispatchAction(
              LocalActions.editMilestonesTitle,
            )}
            onMilestonesDetailsChange={localDispatchAction(
              LocalActions.editMilestonesDetails,
            )}
            onAddAdditionalEntry={localDispatchAction(
              LocalActions.addAdditionalEntry,
            )}
            onDeleteAdditionalEntry={localDispatchAction(
              LocalActions.deleteAdditionalEntry,
            )}
            onDupAdditionalEntry={localDispatchAction(
              LocalActions.dupAdditionalEntry,
            )}
            onAdditionalTitleChange={localDispatchAction(
              LocalActions.editAdditionalTitle,
            )}
            onAdditionalContentChange={localDispatchAction(
              LocalActions.editAdditionalContent,
            )}
            onAdditionalMediaTypeChange={localDispatchAction(
              LocalActions.editAdditionalMediaType,
            )}
            onAdditionalUrlChange={localDispatchAction(
              LocalActions.editAdditionalUrl,
            )}
          />
        )}
        {assetPromotionState.state.section === 'mob' && (
          <MobDetails
            tabValue={assetPromotionState.state.focusedMobTabIndex}
            mob={assetPromotionState.payload.mob}
            mobList={assetPromotionState.state.mobList}
            validationState={assetPromotionValidation.mob}
            onMediaItemChange={localDispatchAction(LocalActions.editMediaItem)}
            onMediaUrlChange={localDispatchAction(LocalActions.editMediaUrl)}
            onLinkMobChange={localDispatchAction(LocalActions.editLinkMob)}
            onDisplayChange={localDispatchAction(LocalActions.editDisplay)}
            onMsaChange={localDispatchAction(LocalActions.editMsa)}
            onSignedChange={localDispatchAction(LocalActions.editSigned)}
            onDeclarationsChange={localDispatchAction(
              LocalActions.editDeclarations,
            )}
            onAttachmentsChange={localDispatchAction(
              LocalActions.editAttachments,
            )}
            onAttachmentAdd={localDispatchAction(LocalActions.addAttachment)}
            onAttachmentDelete={localDispatchAction(
              LocalActions.deleteAttachment,
            )}
            onTabChange={localDispatchAction(LocalActions.changeMobTab)}
          />
        )}
        {assetPromotionState.state.section === 'display' && (
          <DisplayPoster
            display={assetPromotionState.payload.display}
            story={assetPromotionState.payload.story}
            status={assetPromotionState.payload.status}
            footerImageOption={assetPromotionState.state.footerImageOption}
            validationState={assetPromotionValidation.display}
            onFooterImageOptionChange={localDispatchAction(
              LocalActions.editFooterImageOption,
            )}
            onFooterImageChange={localDispatchAction(
              LocalActions.editFooterImage,
            )}
            onStartDateChange={localDispatchAction(LocalActions.editStartDate)}
            onEndDateChange={localDispatchAction(LocalActions.editEndDate)}
            qrOverlay={true}
            isAngus={isAngus}
            originalEndDate={
              assetPromotionState.state.originalAssetPromotion?.display.endDate
            }
            brandLogo={
              assetPromotionState.state.selectedBrand !== ''
                ? assetPromotionState.state.brandList[
                    +assetPromotionState.state.selectedBrand
                  ]?.logo
                : null
            }
          />
        )}
      </Grid>
      <Grid
        container
        justifyContent={
          assetPromotionState.state.section === 'story'
            ? 'flex-end'
            : 'space-between'
        }
        alignItems="center"
        className={classes.stepHyperlinkContainer}>
        {assetPromotionState.state.section !== 'story' && (
          <Grid item>
            <Grid
              container
              alignItems="center"
              onClick={() =>
                localDispatch(LocalActions.navigatePreviousStep())
              }>
              <ChevronLeftIcon className={classes.chevron} />
              <Typography className={classes.hyperlink}>
                Previous Step
              </Typography>
            </Grid>
          </Grid>
        )}
        {assetPromotionState.state.section !== 'display' && (
          <Grid item>
            <Grid
              container
              alignItems="center"
              onClick={() => {
                // Prompt error modal if missing details in mob details
                assetPromotionState.state.section === 'mob' && checkMobMedia();
                localDispatch(LocalActions.navigateNextStep());
              }}>
              <Typography className={classes.hyperlink}>Next Step</Typography>
              <ChevronRightIcon className={classes.chevron} />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item container justifyContent="flex-end" spacing={3}>
        {assetPromotionState.payload.status === 'draft' && (
          <Grid item>
            <MyButton
              text="Save as Draft"
              variant="outlined"
              buttonClass={classes.contentButton}
              onClick={() => {
                handleSave('draft');
              }}
            />
          </Grid>
        )}
        <Grid item>
          <MyButton
            text="Save and Publish"
            variant="contained"
            onClick={() => {
              handleSave('publish');
            }}
            disabled={
              (!id || assetPromotionState.payload.status === 'draft') &&
              assetPromotionState.state.progressBarPosition !== 100
            }
          />
        </Grid>
      </Grid>

      <div style={{display: 'none'}}>
        <Qrcode
          value={
            promotionToDownload?.externalIds[0].agliveToken
              ? `${CONSTANT.SCAN_URL(
                  promotionToDownload?.externalIds[0].agliveToken,
                )}`
              : 'Invalid code'
          }
          size={256}
          id="qrCode"
        />
      </div>
    </>
  );

  return (
    <PageHeader
      config={{
        title: id ? 'Edit Promotion' : 'Create Promotion',
        margin: 60,
        back: true,
      }}>
      {CreateAssetPromotionContent}
    </PageHeader>
  );
};

export default CreateAssetPromotion;
