import {BusinessMisc, PluginModel, TokenService} from '@aglive/data-model';
export interface userProfileTypes {
  id: number | string;
  image: string;
  name: string;
  role: string;
  mobileNumber: string;
  emailAddress: string;
  signature: string;
  address: string;
  businessId: string;
  externalIds: {userId: string};
  country?: string;
}

export interface locationTypes {
  geofence?: Array<{lat: number, lng: number}>;
  locationNickname: string;
  locationType?: string;
  PICAddress?: string;
  locationId?: string;
  country?: string;
  address: string;
  postcode: string;
  town: string;
  state: string;
  LPAUserID?: string;
  LPAPassword?: string;
  NLISUserID?: string;
  NLISPassword?: string;
  tagAlertStandard?: BusinessMisc.TagAlertStandard;
}

export interface optiweighType{
  clientId: string;
  apiKey: string;
  agliveKey: string;
  interval: string;
}
export interface optiweighDeleteType extends optiweighType{
  delete?: boolean;
}
export interface editBusinessProfileTypes {
  businessScope: string;
  businessType: string;
  companyName: string;
  companyNumber: string;
  licenseNumber?: string;
  licenseExpiryDate?: string|Date; // initialized value string
  brandDescription?: string;
  industryType: string;
  imageUri?: string;
  location: Array<locationTypes>;
  angusProfile?: angusProfileType;
  permits?: Array<BusinessMisc.Permit>;
  optiweigh?: optiweighType;
  businessCountry?:string;
}
/*export interface businessProfileTypes extends editBusinessProfileTypes {
  industryType: string;
  users?: Array<any>;
}*/
//type test = TokenService.BusinessToken['details'];
export type businessProfileTypes = TokenService.BusinessToken['details'];

export type angusProfileType = {
  NLISPassword: string;
  NLISUserID: string;
  abn: string;
  acctID: string;
  angusAuthToken: string;
  angusTechPassword: string;
  angusTechUsername: string;
  businessName: string;
  isVerifiedProducer: boolean;
  properties: Array<any>;
};

export interface profileState {
  readonly userProfileData: userProfileTypes;
  readonly businessProfileData: TokenService.BusinessToken['details'];
  readonly plugins?: TokenService.BusinessToken['plugins'];
  readonly reports?: Array<PluginModel.PluginReport>;
}

export const POST_BUSINESS_PROFILE_DATA = 'POST_BUSINESS_PROFILE_DATA';
export const POST_USER_PROFILE_DATA = 'POST_USER_PROFILE_DATA';
export const UPDATE_USER_PROFILE_DATA = 'UPDATE_USER_PROFILE_DATA';
export const DROP_PROFILE_DATA = 'DROP_PROFILE_DATA';
export const POST_PLUGIN_DATA = 'POST_PLUGIN_DATA';
export const POST_REPORT_DATA = 'POST_REPORT_DATA';

export interface postUserType {
  type: typeof POST_USER_PROFILE_DATA;
  payload: userProfileTypes;
}

export interface postBusinessType {
  type: typeof POST_BUSINESS_PROFILE_DATA;
  payload: TokenService.BusinessToken['details'];
}

export interface postPluginType {
  type: typeof POST_PLUGIN_DATA;
  payload: TokenService.BusinessToken['plugins'];
}

export interface postReportType {
  type: typeof POST_REPORT_DATA;
  payload: Array<PluginModel.PluginReport>;
}

export type ProfileTypes = postUserType 
  | postBusinessType
  | { type: typeof DROP_PROFILE_DATA }
  | postPluginType
  | postReportType

export type PolygonPaths = Array<{
    lat: number,
    lng: number,
}>;
