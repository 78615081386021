import {makeStyles} from '@material-ui/core';

import COLOR from '../../../styled/colors';

export const useStyles = makeStyles((theme) => ({
  tabContainer: {
    border: `1px solid ${COLOR.GRAY_BORDER}`,
    borderTop: 0,
    height: '600px',
    overflow: 'auto',
  },
  fieldContainer: {
    backgroundColor: COLOR.GRAY_SOLID,
  },
  errorHelperText: {
    backgroundColor: COLOR.GRAY_SOLID,
    margin: 0,
  },
  contentButton: {
    borderColor: COLOR.GREEN_BUTTON,
    color: COLOR.GREEN_BUTTON,
  },
  uploadBtn: {
    width: '100%',
    height: '60px',
  },
  dateField: {
    backgroundColor: COLOR.WHITE,
    padding: theme.spacing(1),
    border: `1px solid ${COLOR.GRAY_BORDER}`,
  },
  dateFieldError: {
    border: `1px solid ${COLOR.RED}`,
  },
  dateFieldErrorText: {
    color: COLOR.RED,
    fontSize: 14,
    marginTop: theme.spacing(0.5),
  },
  dateFieldFont: {
    fontSize: '12px',
    '@media (min-width:1600px)': {
      fontSize: '16px',
    },
  },
  dateDivider: {
    height: '10px',
    margin: theme.spacing(0, 1.5),
    border: `1px solid ${COLOR.GRAY_BORDER}`,
  },
}));
