import API from '../../config/api';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import CONSTANT from '../../config/constant';
import {toggleModal} from '../modal/actions';
import {callAPI} from '../../utils/network';
import {WebErrorType} from '../../utils/error';
import { TokenService } from '@aglive/data-model';

export const fetchBrands = async (
  dispatch: any,
  errorHandler?: (e: any) => void,
  ): Promise<Array<TokenService.BrandToken>> => {
  try {
    dispatch({type: SPINNER_TOGGLE_ON});
    const response = await callAPI({
      url: API.POST.getTokenbyAddr,      
      method: 'POST',
      data: {
        latestDetails: true,
        status: ['exist'],
        type: [CONSTANT.ASSETTYPE.BRAND],
        activityType: []
      },
    });
  
    return response;
  } catch (e) {
    const error = e as WebErrorType;

    if (errorHandler) {
      errorHandler(e);
    } else {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        })
      );
    }
  } finally {
    dispatch({ type: SPINNER_TOGGLE_OFF });
  }
}

export async function addBrandToBusiness(
  businessId: string,
  brandId: string,
) {
  try {
    const businessResponse = await callAPI<Array<TokenService.BusinessToken>>({
      url: API.POST.getTokenbyExternalId,
      method: 'POST',
      data: {
        latestDetails: true,
        status: ['exist'],
        activityType: [],
        externalIds: [{agliveToken:businessId}],
      },
    });
    const business=businessResponse[0];
    let brandList = 'brands' in business.details
      ? business.details.brands
      : [];
    brandList.push({externalIds: {agliveToken: brandId}});
    const response = await callAPI({
      url: API.POST.createActivity,
      method: 'POST',
      data: {
        tokens: [
          {
            type: CONSTANT.ASSETTYPE.BUSINESS,
            externalIds: {agliveToken: businessId},
            activities: [
              {
                type: 'UP_details',
                details: {brands: brandList},
              },
            ],
          },
        ],
      },
    });
    return response;
  } catch (e) {
    throw e;
  }
}

