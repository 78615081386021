import React, {useRef, useState} from 'react';

import {Tooltip, Typography} from '@material-ui/core';
import {TokenService} from '@aglive/data-model';

import PosterPreview from '../PosterPreview';
import {useStyles} from './styles';
import moment from 'moment';
import CONSTANT from '../../../config/constant';

interface ScanPosterModalContentProps {
  story: TokenService.ProductToken['details']['story'];
  display: TokenService.PromotionToken['details']['display'];
  status: TokenService.PromotionToken['details']['status'];
  footerImageOption: 'image' | '';
  agliveToken: string;
  isAngus?: boolean;
  archived?: boolean;
  brandLogo: string | null;
}

const ScanPosterModalContent: React.FC<ScanPosterModalContentProps> = ({
  story,
  display,
  status,
  footerImageOption,
  agliveToken,
  isAngus,
  archived = false,
  brandLogo,
}) => {
  const classes = useStyles();
  const [openTooltip, setOpenTooltip] = useState(false);
  let timer = useRef<NodeJS.Timeout>();

  const copyMediaLink = () => {
    clearTimeout(timer.current);
    navigator.clipboard.writeText(CONSTANT.SCAN_URL(agliveToken));
    setOpenTooltip(true);
    timer.current = setTimeout(() => {
      setOpenTooltip(false);
    }, 1500);
  };

  return (
    <>
      <PosterPreview
        story={story}
        display={display}
        footerImageOption={footerImageOption}
        qrOverlay={false}
        agliveToken={agliveToken}
        scale={0.45}
        border={true}
        angusVerified={isAngus}
        brandLogo={brandLogo}
      />
      <div style={{marginTop: '10px'}}>
        <Typography
          align="center"
          variant="h6"
          style={{fontWeight: 700, fontSize: 14}}
          gutterBottom={true}>
          Test Your Code
        </Typography>
        <div style={{padding: '5px'}}>
          <Typography align="center" variant="h6" style={{fontSize: 14}}>
            <span style={{fontWeight: 700}}>Basic Info:</span> Scan this QR Code
            with phone camera
          </Typography>
          <Typography align="center" variant="h6" style={{fontSize: 14}}>
            <span style={{fontWeight: 700}}>Detailed Info:</span> Scan this QR
            Code with the Aglive app
          </Typography>
        </div>
        {display.endDate &&
          moment(display.endDate).valueOf() > moment().valueOf() &&
          status === 'published' && !archived && (
            <Tooltip
              title="Copied!"
              aria-label="copied"
              open={openTooltip}
              classes={{tooltip: classes.copiedTooltip}}
              disableFocusListener
              disableHoverListener
              disableTouchListener>
              <Typography
                className={classes.hyperlink}
                style={{fontSize: 14}}
                align="center"
                onClick={copyMediaLink}>
                Copy digital media link to clipboard
              </Typography>
            </Tooltip>
          )}
      </div>
    </>
  );
};

export default ScanPosterModalContent;
