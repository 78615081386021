import {Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography} from '@material-ui/core';
import withHeader from '../../presentation/withHeader';
import MyButton from '../../presentation/button';
import DatePicker from '../../presentation/DatePicker';
import { useState } from 'react';
import moment from 'moment';
import { MultipleSelect } from '../../presentation/MultipleSelect';
import { useAppSelector } from '../../utils/hooks';

const reports = ['Movement Report','Transfer Report','Treatment Report','Inventory per Location Report'];

const OtherReports = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loc, setLoc] = useState([]);
  const [site, setSite] = useState([]);
  const siteList = useAppSelector((state) => state.site.map((st) => st.details.siteName));
  const PICs = useAppSelector((state) => state.user.businessProfileData.location.map((loc) => loc.PICAddress));

  return (
    <>
      <Grid container justify="space-between">
        <Grid
          container
          justifyContent="flex-start"
          spacing={4} xs={8}>
          <Grid item xs={12}>
            <Typography variant="h3" role="label">
              Select Report
            </Typography>
          </Grid>
          <Grid container item xs={6}>
            <Typography variant="h6" role="label" style={{fontWeight: 700, marginBottom: 10}}>
              Start Date
            </Typography>
            <Grid container direction="column">
              <DatePicker dateValue={moment(startDate).format('YYYY-MM-DD')} errorStatus={true} handleChange={setStartDate} />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Typography variant="h6" role="label" style={{fontWeight: 700, marginBottom: 10}}>
              End Date
            </Typography>
            <Grid container direction="column">
              <DatePicker dateValue={moment(endDate).format('YYYY-MM-DD')} errorStatus={true} handleChange={setEndDate} />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Typography variant="h6" role="label" style={{fontWeight: 700, marginBottom: 10}}>
              Location
            </Typography>
            <Grid container direction="column">
              <MultipleSelect
                  dataSet={PICs ?? []}
                  selectedData={loc.length ? loc : []}
                  setSelectedData={setLoc}
                />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Typography variant="h6" role="label" style={{fontWeight: 700, marginBottom: 10}}>
              Site Map
            </Typography>
            <Grid container direction="column">
              <MultipleSelect
                  dataSet={siteList ?? []}
                  selectedData={site.length ? site : []}
                  setSelectedData={setSite}
                />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Typography variant="h6" role="label" style={{fontWeight: 700, marginBottom: 10}}>
              Report Type
            </Typography>
            <Grid container direction="column">
              <FormControl variant="outlined">
                <InputLabel id="report-select">{'Please select'}</InputLabel>
                <Select
                  labelId="report-select"
                  label="Please select"
                  >
                  {reports.map((rep) => (
                    <MenuItem value={rep} key={rep}>
                      {rep}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justify="flex-end">
          <Box mt={15}>
            <MyButton
              text="Download CSV"
              variant="contained"
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default withHeader(
  {
    title: 'Other Reports',
    margin: 40,
  },
  OtherReports,
);