import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {Grid, Typography} from '@material-ui/core';
import {useAppSelector} from '../../utils/hooks';
import MyButton from '../../presentation/button';
import {PageHeader} from '../../presentation/withHeader';
import {ConsigneeTypes} from '../../store/add/types';
import { CASL } from '@aglive/frontend-core';

const ViewConsignee: React.FC<{}> = () => {
  const history = useHistory();
  const {id} = useParams<{id: string}>();
  const consigneeList: Array<ConsigneeTypes> = useAppSelector(
    (state) => state.add.consigneeData,
  );
  const targetIndex = consigneeList.findIndex(
    (consignee) => consignee.agliveToken === id,
  );
  const consignee = {...consigneeList[targetIndex]};
  return (
    <PageHeader config={{title: 'View Consignee', margin: 60, back: true}}>
      <Grid container spacing={3}>
        <Grid item container direction="column" xs={6}>
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'Consignee Name'}
          </Typography>
          <Typography variant="h6" style={{marginTop: 8}}>
            {consignee.receiverName}
          </Typography>
        </Grid>
        <Grid item container direction="column" xs={6}>
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'Consignee Email'}
          </Typography>
          <Typography variant="h6" style={{marginTop: 8}}>
            {consignee.receiverEmail}
          </Typography>
        </Grid>
        <Grid item container direction="column" xs={6}>
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'Consignee Phone'}
          </Typography>
          <Typography variant="h6" style={{marginTop: 8}}>
            {consignee.receiverPhone}
          </Typography>
        </Grid>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'Country'}
          </Typography>
          <Typography variant="h6" style={{marginTop: 8}}>
            {consignee.receiverCountry}
          </Typography>
        </Grid>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'Post Code'}
          </Typography>
          <Typography variant="h6" style={{marginTop: 8}}>
            {consignee.receiverPostCode}
          </Typography>
        </Grid>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'Town'}
          </Typography>
          <Typography variant="h6" style={{marginTop: 8}}>
            {consignee.receiverTown}
          </Typography>
        </Grid>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'State/Province/Territory'}
          </Typography>
          <Typography variant="h6" style={{marginTop: 8}}>
            {consignee.receiverState}
          </Typography>
        </Grid>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'Consignee Location ID (if applicable)'}
          </Typography>
          <Typography variant="h6" style={{marginTop: 8}}>
            {consignee.receiverPIC}
          </Typography>
        </Grid>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {'Consignee Address'}
          </Typography>
          <Typography variant="h6" style={{marginTop: 8}}>
            {consignee.receiverAddress}
          </Typography>
        </Grid>
        <Grid item container justify="flex-end" style={{marginTop: 300}}>
          <CASL.Can I="update" a="consignee">
            <MyButton
              text={'Edit'}
              variant="contained"
              onClick={() => history.push(`/private/add/consignees/${id}`)}
            />
          </CASL.Can>
        </Grid>
      </Grid>
    </PageHeader>
  );
};
export default ViewConsignee;
