import React, {useEffect, useState} from 'react';

import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import {TokenService} from '@aglive/data-model';

import {useStyles} from './styles';
import FieldHeading from '../../../presentation/FieldHeading';
import LabelData from '../../../presentation/LabelData';
import {mobInfoDeclarations} from '../MobDetails/mobInfoDeclarations';
import DeclarationCheckbox from '../MobDetails/DeclarationCheckbox';

interface ViewMobMainSectionProps {
  mob: TokenService.AnimalPromotion['details']['mob'];
}

const ViewMobMainSection: React.FC<ViewMobMainSectionProps> = ({mob}) => {
  const classes = useStyles();

  const [mobDisplayOption, setMobDisplayOption] = useState<
    'mob_animal' | 'mob' | 'animal'
  >('mob_animal');

  useEffect(() => {
    if (!mob?.display.isMob && mob?.display.isAnimal) {
      setMobDisplayOption('animal');
    } else if (mob?.display.isMob && !mob?.display.isAnimal) {
      setMobDisplayOption('mob');
    } else {
      setMobDisplayOption('mob_animal');
    }
  }, []);

  return (
    <>
      <FieldHeading
        title="Image or Video*"
        subtitle="Enhance your story with an image or video"
      />
      <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
        <LabelData
          label="Image"
          field={
            (mob?.media.item === 'image' &&
              decodeURIComponent(mob?.media.url?.split('/').reverse()[0])) ||
            '-'
          }
        />
        <LabelData
          label="Video Link"
          field={(mob?.media.item === 'video' && mob?.media.url) || '-'}
        />
      </Box>

      <Typography variant="h6" style={{fontWeight: 700, marginBottom: '10px'}}>
        Link Mob
      </Typography>
      <Typography variant="h6" role="label">
        {mob?.linkMob?.mobName || '-'}
      </Typography>

      <Box mt={3}>
        <FieldHeading
          title="Display"
          subtitle="Select the information to display when QR code is scanned"
        />
        <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
          <RadioGroup
            aria-label="displayType"
            name="displayType"
            value={mobDisplayOption}>
            <FormControlLabel
              value="mob_animal"
              control={<Radio color="primary" />}
              label="Display Mob and Animal Info"
              disabled
            />
            <FormControlLabel
              value="mob"
              control={<Radio color="primary" />}
              label="Display Mob Info Only"
              disabled
            />
            <FormControlLabel
              value="animal"
              control={<Radio color="primary" />}
              label="Display Animal Info Only"
              disabled
            />
          </RadioGroup>
        </Box>
      </Box>

      <Box mt={3} style={{opacity: mob.linkMob ? 1 : 0.6}}>
        <FieldHeading title="Declaration" subtitle="" />
        <Box py={2} px={3} mb={3} className={classes.fieldContainer}>
          {/* Mob Info */}
          {mobInfoDeclarations.map((mobInfoDeclaration) => (
            <DeclarationCheckbox
              containerStyle={classes.mobInfoDeclaration}
              checked={mob.linkMob?.info[mobInfoDeclaration.key] || false}
              message={mobInfoDeclaration.message}
              disabled
              key={mobInfoDeclaration.key}
            />
          ))}
          {/* MSA declaration */}
          <DeclarationCheckbox
            containerStyle={classes.mobInfoDeclaration}
            checked={mob.msa}
            message="I am a Meat Standards Australia (MSA) registered producer."
            disabled
          />
          {/* Signature */}
          <DeclarationCheckbox
            containerStyle={classes.signatureContainer}
            checked={mob.signed}
            message="Apply signature:
                    <br />
                    <br />I declare that, I am the owner or the person
                    responsible for the husbandry of the cattle and all the
                    information is true and correct."
            disabled
          />
        </Box>
      </Box>
    </>
  );
};

export default ViewMobMainSection;
