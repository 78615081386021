import React from 'react';
import {useAppSelector} from '../../utils/hooks';
import withHeader from '../../presentation/withHeader';
import {HyperLink} from '../../presentation/word';
import MyButton from '../../presentation/button';
import CSVUploader from '../../presentation/CSVUploader';
import {AssetDialogMessage, assetStyles} from './Components';

import {
  Grid,
  makeStyles,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import CONSTANT from '../../config/constant';
import COLOR from '../../styled/colors';
import {ManageAssetByCSVObject} from './ManageAssetByCSVObject';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles((theme) => ({
  linksContainer: {
    width: 400,
    marginTop: 20,
  },
  buttonGrid: {
    width: '100%',
    marginTop: 150,
  },
  searchableFormControl: {
    width: '100%',
    maxWidth: 480,
    '& .MuiOutlinedInput-root': {
      height: 48,
    },
    '& label': {
      fontFamily: theme.typography.body1.fontFamily,
      color: theme.palette.common.black,
    },
  },
}));

const ManageAssetByCSV: React.FC<{}> = () => {
  const classes = {...assetStyles(), ...useStyles()};
  const userProfile = useAppSelector((state) => state.user.userProfileData);
  const account =
    userProfile.businessId === CONSTANT.WARAKIRRI_BUSINESS_ID
      ? 'warakirri'
      : 'normal';
  const manageCSV = ManageAssetByCSVObject(account);

  const disableSubmit =
    !manageCSV.state.file ||
    !(manageCSV.dataSelection?.dropDown ? manageCSV.dataSelection?.dropDown.every(
      (field) => !field.required || (field.required && field.value),
    ) : true)

  return (
    <>
      <Grid container direction="column">
        <Grid item container justify="space-between" style={{marginBottom: 50}}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" role="label" className={classes.subTitle}>
              Select Template
            </Typography>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              size="small">
              <InputLabel
                id="manage-asset-select-template"
                className={classes.selectLabel}>
                Please select
              </InputLabel>

              <Select
                className={classes.select}
                labelId="manage-asset-select-template"
                label="Please select"
                value={manageCSV.state.index}
                onChange={manageCSV.updateNormalForm}>
                {manageCSV.manageData().map(({label, index}) => (
                  <MenuItem value={index} key={index}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            container
            alignItems="flex-end"
            justify="space-between"
            className={classes.linksContainer}>
            {manageCSV.state.index !== '' && (
              <>
                <HyperLink
                  href={
                    CONSTANT.TEMPLATE[
                      manageCSV.isWarakirri
                        ? 'WARAKIRRI_MANAGEMENT'
                        : 'MANAGEMENT'
                    ][manageCSV.dataSelection.value].TEMP
                  }>
                  Download CSV Template
                </HyperLink>
                <HyperLink
                  href={
                    CONSTANT.TEMPLATE[
                      manageCSV.isWarakirri
                        ? 'WARAKIRRI_MANAGEMENT'
                        : 'MANAGEMENT'
                    ][manageCSV.dataSelection.value].EXAMPLE
                  }>
                  Download Sample CSV
                </HyperLink>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {manageCSV.dataSelection?.dropDown &&
            manageCSV.dataSelection.dropDown.map((field) => {
              return field.ifDisplay ? (
                field.hasSearch ? (
                  /**searchable select dropdown */
                  <Grid item xs={6} sm={6} id={field.title} key={field.title}>
                    <Typography variant="h6" className={classes.subTitle}>
                      {field.title}
                    </Typography>
                    <Autocomplete
                      options={field.list}
                      className={classes.searchableFormControl}
                      onChange={(event, value) => {
                        field.onChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Please select"
                          color="success"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  /**normal select dropdown */
                  <Grid item xs={6} sm={6} id={field.title} key={field.title}>
                    <Typography variant="h6" className={classes.subTitle}>
                      {field.title}
                    </Typography>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      size="small">
                      <InputLabel
                        id="asset-induction-select-location"
                        className={classes.selectLabel}>
                        Please select
                      </InputLabel>
                      <Select
                        labelId="asset-induction-select-breed"
                        className={classes.select}
                        value={field.value}
                        onChange={field.onChange}
                        label="Please select">
                        {field.list?.map((item: string) => (
                          <MenuItem value={item} key={item} id={item}>
                            {field.ifUpperCase ? item.toUpperCase() : item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )
              ) : (
                <></>
              );
            })}
        </Grid>
        <CSVUploader
          file={manageCSV.state.file}
          setFile={(data) => {
            manageCSV.setFile(data);
          }}
          setCSVData={(data) => {
            manageCSV.setCSVData(data);
          }}
        />
        <Grid container justify="flex-end" className={classes.buttonGrid}>
          <MyButton
            onClick={() => {
              manageCSV.validateWithSchemaFunction();
            }}
            text="Upload CSV"
            variant="contained"
            disabled={disableSubmit}
          />
        </Grid>
      </Grid>
      {manageCSV.state.open && (
        <AssetDialogMessage
          open={manageCSV.state.open}
          isErr={manageCSV.state.validationResult.type ? true : false}
          fileName={manageCSV.state.file?.name}
          handleClose={() => {
            manageCSV.closeAssetDialog();
          }}
          validationResult={manageCSV.state.validationResult}
          rightButtonClick={() => {
            manageCSV.assetDialogRightButton();
          }}
          rightButtonClickIsErr={() => {
            manageCSV.assetDialogrightButtonClickIsErr();
          }}
          csvData={manageCSV.state.csvData}
        />
      )}
    </>
  );
};

export default withHeader(
  {
    title: 'Manage Asset: CSV Template',
    margin: 40,
    back: true,
  },
  ManageAssetByCSV,
);
