export const mobInfoDeclarations = [
  {
    key: 'ERP' as const,
    message:
      'In the past six months none of these animals have been on a property listed on the ERP database or placed under any restrictions because of chemical residues',
  },
  {
    key: 'BRD' as const,
    message:
      'All of these animals have received Bovine Respiratory Disease (BRD) vaccinations',
  },
  {
    key: 'WHP' as const,
    message:
      'In the past 60 days none of these animals have consumed any material that was still within a witholding period when harvested, collected or first grazed',
  },
  {
    key: 'WHP_ESI' as const,
    message:
      'None of these animals are still within a withholding period (WHP) or an export slaughter interval (ESI) as set by APVMA or SAFEMEAT following treatment with a verterinary drug or chemical',
  },
  {
    key: 'fiveInOne' as const,
    message:
      'All of these animals have received a clostridial vaccination (e.g. 5 in 1)',
  },
];
