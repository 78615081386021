// React
import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
// Material UI Imports
import {
  Grid,
  Typography,
  TextField,
  makeStyles,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
// Other packages
import produce from 'immer';

// Presentation, Container, Styles
import ProductPagePhone from '../../presentation/MockupPhoneProduct';
import MyButton from '../../presentation/button';
import COLOR from '../../styled/colors';
import Tabs from '../../presentation/Tab';
import TabPanel from '../../presentation/TabPanel';
import FieldHeading from '../../presentation/FieldHeading';
import TextFieldWithLimit from '../../presentation/TextFieldWithLimit';
import AdditionalContent from '../shared/AdditionalContent';
import Attachment from '../shared/Attachment';
import {PageHeader} from '../../presentation/withHeader';

// Redux
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {
  AssetProfileDetails,
  RawAssetProfile,
} from '../../store/assetProfile/types';
import {fileUploader} from '../../store/product/actions';
import {
  createAssetProfile,
  editAssetProfile,
  fetchSingleAssetProfile,
} from '../../store/assetProfile/actions';
import {getBusinessProfile} from '../../store/profile/actions';

interface Props {}

enum TabMap {
  mainDisplay,
  additionalContent,
  attachments,
}

const TabLabels = Object.values(TabMap).slice(0, 3);

const initialAssetProfile = (contentLength = 1, attachmentLength = 1) => {
  const initialState: AssetProfileDetails = {
    assetName: '',
    mainDisplay: {
      item: '',
      url: '',
      permitNumber: '',
      species: '',
      strain: '',
      subStrain: '',
    },
    content: [],
    attachment: [],
    archived: false,
  };

  for (let i = 0; i < contentLength; i++) {
    initialState.content.push({subheading: '', content: ''});
  }

  for (let i = 0; i < attachmentLength; i++) {
    initialState.attachment.push({title: '', url: ''});
  }

  return initialState;
};
const CreateAssetProfile: React.FC<Props> = () => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {id} = useParams<{id: string}>();

  // States
  const [assetProfile, setAssetProfile] = useState<AssetProfileDetails>({
    ...initialAssetProfile(),
  });
  const [validationState, setValidationState] = useState({
    ...initialAssetProfile(),
  });
  const [oldAssetProfile, setOldAssetProfile] = useState<RawAssetProfile>();
  const [tabValue, setTabValue] = useState(TabMap.mainDisplay);
  const userId = useAppSelector((state) => state.auth.wallet);
  const permitArray = useAppSelector((state) =>
    state.user.businessProfileData.industryType === 'PLANTS'
      ? state.user.businessProfileData.permits.map((permit) => ({
          permitNumber: permit.permitNumber,
          permitID: permit?.permitID ?? undefined,
        }))
      : [],
  );

  // Side Effects
  useEffect(() => {
    dispatch(getBusinessProfile());
  }, []);

  useEffect(() => {
    async function fetchAssetProfile() {
      const fetchedAssetProfile = await dispatch(
        fetchSingleAssetProfile(id, history),
      );
      if (fetchedAssetProfile) {
        const {content, attachment} = fetchedAssetProfile.details;
        setAssetProfile(fetchedAssetProfile.details);
        setValidationState({
          ...initialAssetProfile(content.length, attachment.length),
        });
        setOldAssetProfile(fetchedAssetProfile);
      }
    }
    if (id) {
      fetchAssetProfile();
    }
  }, [id]);

  // Handle all input fields
  const handleInputChange = (value: any, ...keys: Array<string | number>) => {
    setAssetProfile((prevState) => {
      return produce(prevState, (draft) => {
        let tempState = draft;
        // Go to the key's location and set the new value
        if (keys.length === 1) {
          tempState[keys[0]] = value;
        } else {
          keys.forEach((key, index) => {
            tempState = tempState[key];
            if (index === keys.length - 2) {
              if (keys[index + 1] === 'permitNumber') {
                tempState['permitID'] = permitArray.find(
                  (permit) => permit.permitNumber === value,
                ).permitID;
                tempState[keys[index + 1]] = value;
              } else {
                tempState[keys[index + 1]] = value;
              }
            }
          });
        }
      });
    });
  };
  // Handle Main Display tab's radio buttons
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setAssetProfile((prevState) => {
      return produce(prevState, (draft) => {
        draft.mainDisplay.item = e.target.value as '' | 'image' | 'video';
        draft.mainDisplay.url = '';
      });
    });
    setValidationState((prevState) => ({
      ...prevState,
      mainDisplay: {...prevState.mainDisplay, url: ''},
    }));
  };
  // Handle file uploads
  const uploadImageHandler = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10485760) {
        window.alert('Upload image size more than 10mb ！');
      } else {
        const response = await fileUploader(userId, e.target.files[0]);
        const responseData = response[0].data;
        if (typeof responseData == 'string') {
          handleInputChange(responseData, 'mainDisplay', 'url');
        }
      }
    }
  };
  const displayImageUpload = React.useRef(null);
  const handleImageClick = () => {
    displayImageUpload.current.click();
  };
  // Handle form submit
  const handleSave = () => {
    const validatedData = validateData(assetProfile);
    setValidationState(validatedData);

    if (isAllowedtoSubmit(validatedData)) {
      if (!id) {
        dispatch(
          createAssetProfile(
            assetProfile,
            () => {
              history.goBack();
            },
            history,
          ),
        );
      } else {
        dispatch(
          editAssetProfile(assetProfile, oldAssetProfile, () => {
            history.goBack();
          }),
        );
      }
    }
  };

  const CreateAssetProfileContent = (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="body1" role="label" style={{fontWeight: 600}}>
            Asset Name
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={assetProfile?.assetName}
            onChange={(e) => handleInputChange(e.target.value, 'assetName')}
            error={!!validationState.assetName}
            helperText={validationState.assetName}
          />
        </Grid>
      </Grid>

      <Grid container style={{marginTop: 60}}>
        <Grid item xs={12}>
          <Typography variant="h2" role="label" style={{marginBottom: 10}}>
            Asset Details
          </Typography>
          <Divider />
        </Grid>
      </Grid>

      <Grid item container spacing={3}>
        {/* Reuse the mockup phone from Product Profile */}
        <Grid
          item
          container
          xs={12}
          lg={5}
          style={{marginTop: 50, paddingLeft: 30}}
          justify="center">
          <ProductPagePhone
            isShowHistory={false}
            page={{
              mainDisplay: assetProfile.mainDisplay,
              content: assetProfile.content,
              attachment: assetProfile.attachment,
            }}
            isProduct={false}
          />
        </Grid>

        {/* Render 3 tabs */}
        <Grid item xs={12} lg={7} style={{marginTop: 70}}>
          <Tabs
            tabHeaders={TabLabels as string[]}
            value={tabValue}
            setValue={setTabValue}>
            {/* Main Display tab */}
            <TabPanel
              isShown={tabValue === TabMap.mainDisplay}
              index={TabMap.mainDisplay}>
              <Box p={3} style={{border: `1px solid ${COLOR.GRAY_BORDER}`, borderTop: 0}}>
                {/* Image or Video section */}
                <FieldHeading
                  title="Image or Video"
                  subtitle="Enhance your story with an image or video"
                />
                <Box p={3} marginBottom={3} className={classes.fieldContainer}>
                  <RadioGroup
                    aria-label="item"
                    name="item"
                    value={assetProfile.mainDisplay.item}
                    onChange={handleRadioChange}>
                    {/* Handle Image */}
                    <FormControlLabel
                      value="image"
                      control={<Radio color="primary" />}
                      label="Image"
                    />
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      spacing={3}
                      style={{
                        paddingLeft: '40px',
                      }}>
                      <Grid item xs={7}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={
                            assetProfile.mainDisplay.item === 'image'
                              ? decodeURIComponent(
                                  assetProfile.mainDisplay.url
                                    .split('/')
                                    .reverse()[0])
                              : ''
                          }
                          placeholder={'png, jpg, or jpeg'}
                          disabled
                          error={
                            !!validationState.mainDisplay.url &&
                            assetProfile.mainDisplay.item === 'image'
                          }
                          helperText={
                            assetProfile.mainDisplay.item === 'image'
                              ? validationState.mainDisplay.url
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <MyButton
                          text={'Upload File'}
                          variant="outlined"
                          onClick={handleImageClick}
                          buttonClass={`${classes.contentButton} ${classes.uploadBtn}`}
                          disabled={assetProfile.mainDisplay.item !== 'image'}
                        />
                        <input
                          type="file"
                          onChange={uploadImageHandler}
                          ref={displayImageUpload}
                          style={{height: 0, width: 0}}
                        />
                      </Grid>
                    </Grid>
                    {/* Handle Video URL (Currently commented out as it is not included in Cannabis Phase 1) */}
                    {/* <FormControlLabel
                      value="video"
                      control={<Radio color="primary" />}
                      label="Video URL"
                    />
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      spacing={3}
                      style={{
                        paddingLeft: '40px',
                      }}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={
                            assetProfile.mainDisplay.item === 'video'
                              ? assetProfile.mainDisplay.url
                              : ''
                          }
                          placeholder={'Enter URL'}
                          className={classes.inputField}
                          disabled={assetProfile.mainDisplay.item !== 'video'}
                          error={
                            !!validationState.mainDisplay.url &&
                            assetProfile.mainDisplay.item === 'video'
                          }
                          helperText={
                            assetProfile.mainDisplay.item === 'video'
                              ? validationState.mainDisplay.url
                              : ''
                          }
                          FormHelperTextProps={{
                            className: classes.errorHelperText,
                          }}
                          onChange={(e) =>
                            handleInputChange(
                              e.target.value,
                              'mainDisplay',
                              'url',
                            )
                          }
                        />
                      </Grid>
                    </Grid> */}
                    {/* No Image */}
                    <FormControlLabel
                      value=""
                      control={<Radio color="primary" />}
                      label="No Image"
                    />
                  </RadioGroup>
                </Box>

                {/* Asset Info section */}
                <FieldHeading
                  title={
                    assetProfile.assetName
                      ? assetProfile.assetName
                      : 'Asset Name'
                  }
                  subtitle="Enter the basic info for your asset"
                />
                <Box p={3} className={classes.fieldContainer}>
                  <Grid item container direction="column">
                    <Typography
                      variant="h6"
                      style={{
                        fontWeight: 'bold',
                        marginBottom: 10,
                      }}>
                      {'Permit Number'}
                    </Typography>
                    <FormControl
                      variant="outlined"
                      style={{marginBottom: '20px'}}
                      className={classes.inputField}
                      error={!!validationState.mainDisplay.permitNumber}>
                      <InputLabel id="create-assetProfile-select-permit">
                        Please select
                      </InputLabel>
                      <Select
                        value={assetProfile.mainDisplay.permitNumber}
                        labelId="create-assetProfile-select-permit"
                        label="Please select"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleInputChange(
                            e.target.value as string,
                            'mainDisplay',
                            'permitNumber',
                          );
                        }}>
                        {permitArray.map((permit) => (
                          <MenuItem
                            value={permit.permitNumber}
                            key={permit.permitID}>
                            {permit.permitNumber}
                          </MenuItem>
                        ))}
                      </Select>
                      {!!validationState.mainDisplay.permitNumber && (
                        <FormHelperText className={classes.errorHelperText}>
                          {validationState.mainDisplay.permitNumber}
                        </FormHelperText>
                      )}
                    </FormControl>
                    {/* Species */}
                    <TextFieldWithLimit
                      title={`Species`}
                      content={assetProfile.mainDisplay.species}
                      limit={30}
                      style={classes.inputField}
                      placeholder="Enter text"
                      onChange={(value: string) =>
                        handleInputChange(value, 'mainDisplay', 'species')
                      }
                      error={!!validationState.mainDisplay.species}
                      helperText={validationState.mainDisplay.species}
                    />
                    {/* Strain */}
                    <TextFieldWithLimit
                      title={`Strain`}
                      content={assetProfile.mainDisplay.strain}
                      limit={30}
                      style={classes.inputField}
                      placeholder="Enter text"
                      onChange={(value: string) =>
                        handleInputChange(value, 'mainDisplay', 'strain')
                      }
                      error={!!validationState.mainDisplay.strain}
                      helperText={validationState.mainDisplay.strain}
                    />
                    {/* Sub Strain */}
                    <TextFieldWithLimit
                      title={`Sub Strain`}
                      content={assetProfile.mainDisplay.subStrain}
                      limit={30}
                      style={classes.inputField}
                      placeholder="Enter text"
                      onChange={(value: string) =>
                        handleInputChange(value, 'mainDisplay', 'subStrain')
                      }
                      error={!!validationState.mainDisplay.subStrain}
                      helperText={validationState.mainDisplay.subStrain}
                    />
                  </Grid>
                </Box>
              </Box>
            </TabPanel>
            {/* Additional Content tab */}
            <TabPanel
              isShown={tabValue === TabMap.additionalContent}
              index={TabMap.additionalContent}>
              <Box p={3} style={{border: `1px solid ${COLOR.GRAY_BORDER}`, borderTop: 0}}>
                <Grid container direction="column" justify="space-between">
                  <AdditionalContent
                    handleInputChange={handleInputChange}
                    setValidationState={setValidationState}
                    state={assetProfile}
                    validationState={validationState}
                  />
                </Grid>
              </Box>
            </TabPanel>
            {/* Attachment tab */}
            <TabPanel
              isShown={tabValue === TabMap.attachments}
              index={TabMap.attachments}>
              <Box p={3} style={{border: `1px solid ${COLOR.GRAY_BORDER}`, borderTop: 0}}>
                <Grid container direction="column" justify="space-between">
                  <Attachment
                    handleInputChange={handleInputChange}
                    setValidationState={setValidationState}
                    state={assetProfile}
                    validationState={validationState}
                  />
                </Grid>
              </Box>
            </TabPanel>
          </Tabs>
        </Grid>
      </Grid>

      <Grid item container justify="flex-end" spacing={3}>
        <Grid item>
          <MyButton
            text="Cancel"
            variant="outlined"
            buttonClass={classes.contentButton}
            onClick={() => {
              history.goBack();
            }}
          />
        </Grid>
        <Grid item>
          <MyButton text="Save" variant="contained" onClick={handleSave} />
        </Grid>
      </Grid>
    </>
  );

  return (
    <PageHeader
      config={{
        title: id ? 'Edit Asset Profile' : 'Create Asset Profile',
        margin: 60,
        back: true,
      }}>
      {CreateAssetProfileContent}
    </PageHeader>
  );
};

const validateData = (assetProfile: AssetProfileDetails) => {
  const validationState = produce(
    initialAssetProfile(
      assetProfile.content.length,
      assetProfile.attachment.length,
    ),
    (draft) => {
      /* Main Display Tab */
      // Validate asset name
      if (!assetProfile.assetName.trim().length)
        draft.assetName = 'This field is required';
      // else if (!/^[^ ]*$/.test(assetProfile.assetName))
      //   draft.assetName = 'Must not contain white space';
      // Validate image/video is uploaded when being selected
      if (
        ['video', 'image'].includes(assetProfile.mainDisplay.item) &&
        !assetProfile.mainDisplay.url.trim().length
      )
        draft.mainDisplay.url = 'This field is required';
      // Validate permit number
      if (!assetProfile.mainDisplay.permitNumber.trim().length)
        draft.mainDisplay.permitNumber = 'This field is required';
      // Validate species
      if (!assetProfile.mainDisplay.species.trim().length)
        draft.mainDisplay.species = 'This field is required';
      // Validate strain
      if (!assetProfile.mainDisplay.strain.trim().length)
        draft.mainDisplay.strain = 'This field is required';
      // Validate sub strain
      if (!assetProfile.mainDisplay.subStrain.trim().length)
        draft.mainDisplay.subStrain = 'This field is required';

      // validate attachment title
      // if there is an uploaded attachment, check for the title
      if (assetProfile.attachment.length) {
        assetProfile.attachment.forEach(({title, url}, index) => {
          if (url.length && !title.length) {
            draft.attachment[index].title = 'This field is required';
          }
        });
      }
    },
  );

  return validationState;
};

const isAllowedtoSubmit = (validationState: AssetProfileDetails) => {
  return !Object.entries(validationState).some(([key, value]) => {
    if (key === 'content') {
      return validationState.content.some((content) =>
        Object.values(content).some((value) => value),
      );
    } else if (key === 'attachment') {
      return validationState.attachment.some((attachment) =>
        Object.values(attachment).some((value) => value),
      );
    } else if (key === 'mainDisplay') {
      return Object.values(validationState.mainDisplay).some((value) => value);
    } else {
      return value;
    }
  });
};

const useStyle = makeStyles((theme) => ({
  contentButton: {
    borderColor: COLOR.GREEN,
    color: COLOR.GREEN,
  },
  fieldContainer: {
    backgroundColor: COLOR.GRAY_SOLID,
  },
  inputField: {
    backgroundColor: COLOR.WHITE,
    width: '100%',
  },
  uploadBtn: {
    width: '100%',
  },
  errorHelperText: {
    backgroundColor: COLOR.GRAY_SOLID,
    margin: 0,
  },
}));

export default CreateAssetProfile;
