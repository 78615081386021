import React from 'react';
import {Typography, Grid, makeStyles } from '@material-ui/core';
import COLOR from '../../../../styled/colors';
import TextFieldWithLimit from '../../../../presentation/TextFieldWithLimit';
import { TokenService } from '@aglive/data-model';

type displayTypes = 
  TokenService.BrandToken["details"]["aboutUs"]["headLine"] &
  {
    onHeadingChange: (text: string) => void;
    onAddressChange: (text: string) => void;
    onWebsiteChange: (text: string) => void;
  };

const useStyle = makeStyles((theme) => ({
  pagesPreviewContainer: {
    borderRightWidth: 1,
    borderRightStyle: "solid",
    borderRightColor: theme.palette.grey[200],
    marginTop: 25,
    flexGrow: 1,
  },
  tabs:{
    backgroundColor:COLOR.WHITE,
  }
}));

const CreateAboutUsTabHeadline: React.FC<displayTypes> = ({
  heading,
  address,
  website,

  onHeadingChange,
  onAddressChange,
  onWebsiteChange
}) => {
  const classes = useStyle();

  return (
    <>
      <Grid item container direction="column">
        <TextFieldWithLimit
          title={'Heading*'}
          content={heading}
          limit={30}
          style={classes.tabs}
          onChange={onHeadingChange}
        />
        <TextFieldWithLimit
          title={'Address'}
          content={address}
          limit={280}
          style={classes.tabs}
          onChange={onAddressChange}
        />
        <TextFieldWithLimit
          title={'Website'}
          content={website} 
          style={classes.tabs}
          onChange={onWebsiteChange}
        />
      </Grid>
      <Grid
        item
        container
        direction="row"
        style={{
          backgroundColor:'#FFFFFF',
          marginTop:144,
          justifyContent: "center",
          alignItems: "center"
        }}>
        <Typography variant="h6" role="label" style={{ fontWeight: 500 }}>
          Tell your company story to consumers
        </Typography>
      </Grid>
    </>
  );
};

export default CreateAboutUsTabHeadline;