import {makeStyles} from '@material-ui/core';

import COLOR from '../../styled/colors';

export const useStyles = makeStyles((theme) => ({
  mediaPreview: {
    height: 150,
    width: '100%',
    position: 'relative',
  },
  media: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  defaultMediaText: {
    fontSize: 12,
    color: COLOR.WHITE,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 600,
  },
  previewDetailsContainer: {
    width: '90%',
    padding: theme.spacing(1, 0),
  },
  previewDefaultText: {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
  },
  previewHeader: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: theme.typography.fontFamily,
  },
  fieldContainer: {
    padding: theme.spacing(1.5, 0),
    borderBottom: `1px solid ${COLOR.GRAY_SOLID}`,
  },
  infoHeader: {
    padding: theme.spacing(1, 0),
    paddingTop: theme.spacing(4),
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
  },
  mobTable: {
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    '& tr': {
      borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
    },
    '& td': {
      padding: theme.spacing(1, 0),
    },
    '& tr > td:nth-child(odd)': {
      width: '25%',
    },
    '& tr > td:nth-child(even)': {
      textAlign: 'center',
      width: '20%',
    },
  },
  checkIcon: {
    color: COLOR.GREEN_BUTTON,
  },
  animalsTableHeader: {
    backgroundColor: COLOR.GRAY_SOLID,
    padding: theme.spacing(1, 1),
    marginTop: theme.spacing(1),
  },
  hyperlink: {
    color: COLOR.GREENT_TEXT,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  animalRecord: {
    padding: theme.spacing(1, 1),
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
  },
  hideRecord: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    backgroundColor: COLOR.GRAY_SOLID,
    textAlign: 'right',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  hideIcon: {
    fontSize: 20,
    color: COLOR.GREENT_TEXT,
  },
  hideBtn: {
    color: COLOR.GREENT_TEXT,
    cursor: 'pointer',
    textDecoration: 'underline',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
