import React, {useState} from 'react';
import {useAppSelector, useAppDispatch} from '../../utils/hooks';
import clone from 'clone';

import CenteredTree from './SiteCenteredTree';
import withHeader from '../../presentation/withHeader';

import {
  Typography,
  Grid,
  FormControl,
  Select,
  makeStyles,
  MenuItem,
  TextField,
  InputLabel,
} from '@material-ui/core';
import SiteLayerTree from './SiteLayerTree';
import MyButton from '../../presentation/button';
import {useHistory, useParams, Redirect} from 'react-router-dom';
import {
  modifySiteToken,
  cloneNode,
  targetParentNode,
  targetNode,
  siblingNamingFunction,
  childNamingFunction,
  validateTree,
} from '../../store/site/actions';
import {LayerNode, SiteTokenResponse} from '../../store/site/types';
import CONSTANT from '../../config/constant';
import useSiteOptions from './useSiteOptions';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    marginBottom: 80,
  },
  dropdownContainer: {
    marginRight: 50,
  },
  buttonContainer: {
    marginTop: 20,
  },
}));

const EditSite: React.FC<{siteState: SiteTokenResponse}> = ({siteState}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {regions, propertyTypes} = useSiteOptions();
  const siteTokensResArr = useAppSelector((state) => state.site);
  const isPlants = useAppSelector(
    (state) => state.user.businessProfileData.industryType === 'PLANTS',
  );
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const isAustralia =
    businessProfile.businessCountry === 'Australia' ||
    !businessProfile.businessCountry;

  const isWarakirri = useAppSelector((state) => state.user.userProfileData.businessId === CONSTANT.WARAKIRRI_BUSINESS_ID);
  // Dropdown
  const locations = useAppSelector((state) => state.location.location).filter(
    ({PICAddress}) =>
      siteState.details.location === PICAddress ||
      !siteTokensResArr.find(
        (siteTokenRes) => siteTokenRes.details.location === PICAddress,
      ),
  );
  const [selectedLocation, setSelectedLocation] = useState(
    locations.find(
      (location) => location.PICAddress === siteState.details.location,
    ),
  );
  const [selectedRegion, setSelectedRegion] = useState<string>(
    siteState.details.region,
  );
  const [selectedPropertyType, setSelectedPropertyType] = useState<string>(
    siteState.details.propertyType,
  );

  // Site Data Structure
  const [tree, setTree] = useState(siteState.details.layers);

  // Tree Setters
  const changeSiteName = (name: string) =>
    setTree((prevState) => {
      let nextState: LayerNode = clone(prevState);
      nextState.name = name;

      return nextState;
    });

  const addSiblingNode = (args: Array<number>) =>
    setTree((prevState) =>
      cloneNode(prevState, (ptr) => {
        ptr = targetParentNode(ptr, args);

        ptr.children.push({
          // name the new node based on the name of the last sibling
          name: siblingNamingFunction(
            ptr.children[ptr.children.length - 1].name,
          ),
          id: siblingNamingFunction(ptr.children[ptr.children.length - 1].name),
          layerName: '',
          children: [],
        });
      }),
    );

  const addChildNode = (args: Array<number>) =>
    setTree((prevState) =>
      cloneNode(prevState, (ptr) => {
        ptr = targetNode(ptr, args);

        ptr.children = ptr.children || [];

        ptr.children.push({
          // name the new node by appending a digit based on the parent node
          name: childNamingFunction(ptr.name, ptr.children.length + 1),
          id: childNamingFunction(ptr.name, ptr.children.length + 1),
          layerName: '',
          children: [],
        });
      }),
    );

  const removeNode = (args: Array<number>) =>
    setTree((prevState) =>
      cloneNode(prevState, (ptr) =>
        targetParentNode(ptr, args).children.splice(args[args.length - 1], 1),
      ),
    );

  const changeLayerName = (name: string, args: Array<number>) =>
    setTree((prevState) =>
      cloneNode(prevState, (ptr) => {
        targetNode(ptr, args).layerName = name;
      }),
    );

  return (
    <>
      {/* PIC and Site Name */}
      <Grid alignItems="center" container style={{marginBottom: 20}}>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginBottom: 10,
            }}>
            {`Select Location ${
              isPlants ? '' : isAustralia ? 'e.g. PIC' : 'e.g. PID'
            }`}
          </Typography>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="location-select">{'Please select'}</InputLabel>
            <Select
              labelId="location-select"
              label="Please select"
              defaultValue={siteState.details.location}
              className={classes.dropdownContainer}
              onChange={(event) =>
                setSelectedLocation(
                  locations.find(
                    (location) => location.PICAddress === event.target.value,
                  ),
                )
              }>
              {locations.map(({PICAddress}) => (
                <MenuItem value={PICAddress} key={PICAddress}>
                  {PICAddress}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginTop: 10,
            }}>
            {'Site Name'}
          </Typography>
          <TextField
            style={{
              marginTop: 8,
              width: '100%',
            }}
            value={tree.name}
            onChange={(e) => changeSiteName(e.target.value)}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: false,
            }}
          />
        </Grid>
      </Grid>

      {/* Region and Property Type */}
      {isWarakirri && <Grid alignItems="center" container className={classes.headerContainer}>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginBottom: 10,
            }}>
            Region
          </Typography>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="region-select">{'Please select'}</InputLabel>
            <Select
              labelId="region-select"
              label="Please select"
              value={selectedRegion || ''}
              className={classes.dropdownContainer}
              onChange={(event) =>
                setSelectedRegion(event.target.value as string)
              }>
              {regions.map((region) => (
                <MenuItem value={region} key={region}>
                  {region}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              marginBottom: 10,
            }}>
            Property Type
          </Typography>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="property-type-select">{'Please select'}</InputLabel>
            <Select
              labelId="property-type-select"
              label="Please select"
              value={selectedPropertyType || ''}
              onChange={(event) =>
                setSelectedPropertyType(event.target.value as string)
              }>
              {propertyTypes.map((propertyType) => (
                <MenuItem value={propertyType} key={propertyType}>
                  {propertyType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>}

      <Grid container>
        <Grid container item xs={6} direction="column">
          <Grid item style={{paddingBottom: 40}}>
            <Typography variant="h2">Build Site Layers</Typography>
          </Grid>
          <Grid item>
            <SiteLayerTree
              tree={tree}
              addSibling={addSiblingNode}
              addChild={addChildNode}
              removeNode={removeNode}
              changeLayerName={changeLayerName}
              index={[]}
              allowDelete={tree.children.length > 1}
            />
          </Grid>
        </Grid>
        <Grid item container xs={6} direction="column">
          <Grid item style={{paddingBottom: 35}}>
            <Typography variant="h2">Site Map Preview</Typography>
          </Grid>
          <Grid item>
            <CenteredTree tree={tree} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container justify="flex-end" className={classes.buttonContainer}>
        <MyButton
          disabled={!validateTree(tree) || !tree.name || !selectedLocation}
          text={'Save'}
          variant="contained"
          onClick={() =>
            dispatch(
              modifySiteToken(
                tree,
                siteState,
                selectedLocation.PICAddress,
                history,
                selectedRegion,
                selectedPropertyType,
              ),
            )
          }
        />
      </Grid>
    </>
  );
};

const EditStateGuard = () => {
  const {agliveToken} = useParams<{agliveToken: string}>();
  const siteState = useAppSelector((state) =>
    state.site.find(
      (token) => token.externalIds[0].agliveToken === agliveToken,
    ),
  );

  if (siteState) {
    return <EditSite siteState={siteState} />;
  } else {
    return <Redirect to="/private/register/site" />;
  }
};

export default withHeader(
  {
    title: 'Edit Site Map',
    back: true,
  },
  EditStateGuard,
);
