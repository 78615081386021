import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Grid, Typography, TextField} from '@material-ui/core';

type TextFieldType = {
  title: string;
  name: string;
  initialValue: string;
  touched: boolean;
  setResult: (error: string, value: string) => void;
  validate: (value: string) => {isValid: boolean; error: string};
  placeholder?: string;
  multiline?: boolean;
  style?: string;
};

const TextFieldwithValidation: React.FC<TextFieldType> = ({
  title,
  name,
  initialValue,
  touched,
  setResult,
  validate,
  placeholder,
  multiline,
  style,
}) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(null);
  const [_touched, setTouched] = useTouched(touched);

  useEffect(() => {
    console.log('value', value, error);
    setResult(error, value);
  }, [value, error]);

  useEffect(() => {
    if (_touched) {
      const validation = validate(value);
      if (!validation.isValid) {
        setError(validation.error);
      } else setError('');
    }
  }, [_touched, value]);

  return (
    <Grid item container xs={6} lg={6} md={6} sm={6} direction="column">
      <Typography variant="h6" style={{fontWeight: 600}}>
        {title}
      </Typography>
      <TextField
        placeholder={placeholder}
        value={value}
        variant="outlined"
        className={style}
        multiline={multiline ? true : false}
        error={error}
        onChange={(e) => {
          setValue(e.target.value);
          setTouched(true);
        }}
        helperText={error}
      />
    </Grid>
  );
};

const useTouched = (
  touched: boolean,
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [_touched, setTouched] = useState(touched);

  useEffect(() => {
    setTouched(touched);
  }, [touched]);

  return [_touched, setTouched];
};

export default TextFieldwithValidation;
