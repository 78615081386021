import API from '../../config/api';
import {AppThunk} from '../types';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import {toggleModal} from '../modal/actions';
import CONSTANT from '../../config/constant';
import {callAPI} from '../../utils/network';
import {WebErrorType} from '../../utils/error';
import {
  FETCH_GROUP_PROJECTS,
  GroupDataTypes,
  formType,
  fileType,
  FETCH_ASSET_PROJECTS,
} from './types';
import moment from 'moment';
import axios, {AxiosRequestConfig} from 'axios';
import {authUrls} from '../../utils/auth';
import {NetworkError} from '../../utils/error';

export function fetchGroupProjects(userId: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response = await callAPI({
        url: API.POST.getTokenbyAddr,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          type: [
            CONSTANT.ASSETTYPE.GROUP,
            CONSTANT.ASSETTYPE.ASSET,
            CONSTANT.ASSETTYPE.PLANT,
          ],
          activityType: [],
        },
      });

      dispatch({
        type: FETCH_GROUP_PROJECTS,
        payload: response.filter(
          (token) => token.type === CONSTANT.ASSETTYPE.GROUP,
        ),
      });
      const asset = response.filter(
        (token) =>
          token.type === CONSTANT.ASSETTYPE.ASSET ||
          token.type === CONSTANT.ASSETTYPE.PLANT,
      );
      // const getAssetList=asset.filter(token=>{
      //   if(!token.details.hasOwnProperty('groups')){
      //     return token;
      //   }
      //   else if(token.details.groups.length===0){
      //     return token;
      //   }
      // })
      dispatch({
        type: FETCH_ASSET_PROJECTS,
        payload: asset,
      });
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function addMoveActivity(
  group: GroupDataTypes,
  groupPIC: string,
  groupSite: string,
  destination: string,
  site: string,
  forms: Array<formType>,
  files: Array<fileType>,
  successCB: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const {
        auth: {wallet},
      } = getState();
      if (wallet) {
        const fileArray = files.filter((file) => file.url !== '');
        const formArray = forms
          .filter((form) => form.url !== '')
          .map((form) => {
            delete form.checked;
            return form;
          });
        const activity = {
          type: 'move',
          details: {
            destination: {
              location: destination,
              site: site,
            },
            origin: {
              location: groupPIC,
              site: groupSite,
            },
          },
        };
        if (formArray.length > 0) {
          activity.details['forms'] = formArray;
        }
        if (fileArray.length > 0) {
          activity.details['files'] = fileArray;
        }
        await callAPI({
          url: API.POST.createActivity,
          method: 'POST',
          data: {
            tokens: [
              {
                type: CONSTANT.ASSETTYPE.GROUP,
                externalIds: {agliveToken: group.externalIds[0].agliveToken},
                activities: [activity],
              },
            ],
          },
        });
        activity.details['moveWithinGroup'] = true;
        group.details.items.map(
          async (item: {[agliveToken: string]: string}) => {
            await callAPI({
              url: API.POST.createActivity,
              method: 'POST',
              data: {
                tokens: [
                  {
                    externalIds: {agliveToken: item.agliveToken},
                    activities: [activity],
                  },
                ],
              },
            });
          },
        );
        dispatch(
          toggleModal({
            status: 'success',
            title: 'Created',
            button: 'Close',
            CTAHandler: successCB,
          }),
        );
      }
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

type csvInputT = [
  Date, //activity date
  string, // name
  string, // item
  string, // by
  string, // grom
  string, //to
];
function generateCSV(codeTokens: Array<csvInputT>, csvName = 'move.csv'): void {
  // https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
  const CSV_HEADER = [['Date', 'Name', 'Item', 'By', 'From', 'To']];

  let csvContent =
    'data:text/csv;charset=utf-8,' +
    CSV_HEADER.map((row) => row.join(',')).join('\n') +
    '\n' +
    codeTokens.map((row) => row.join(',')).join('\n');

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', csvName);
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file
}

type showListType = {
  activity: {[key: string]: any};
  name: string;
  items: Array<{agliveToken: string}>;
  externalId: string;
  checked: boolean;
};
export function downloadMoveCSV(
  showList: Array<showListType>,
  user: string,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    const selectedActivity = showList.filter((item) => item.checked === true);
    const csvData = [];
    selectedActivity.map((item) => {
      if (item.items) {
        item.items.map((child) => {
          csvData.push([
            moment(item.activity.createAt).format('DD/MM/YYYY'),
            item.name,
            child.agliveToken,
            user,
            item.activity.details.origin.location +
              '  ' +
              item.activity.details.origin.site,
            item.activity.details.destination.location +
              '  ' +
              item.activity.details.destination.site,
          ]);
        });
      } else {
        csvData.push([
          moment(item.activity.createAt).format('DD/MM/YYYY'),
          'Items',
          item.externalId,
          user,
          item.activity.details.origin.location +
            '  ' +
            item.activity.details.origin.site,
          item.activity.details.destination.location +
            '  ' +
            item.activity.details.destination.site,
        ]);
      }
    });
    generateCSV(
      csvData,
      'move' + moment(new Date()).format('DD/MM/YYYY') + '.csv',
    );
  };
}
export async function fetchAllAssets(config: AxiosRequestConfig) {
  try {
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json',
    };

    if (
      authUrls
        .filter((url) => url !== API.POST.createUserWithAuth)
        .includes(config.url)
    ) {
      Object.assign(config, {withCredentials: true});
    }

    const response = await axios.request<any>(config);

    return Promise.resolve(response);
  } catch (e) {
    if (e.response?.status === 401 && e.response.data) {
      e.response.data.title = 'Session expired';
      e.response.data.details =
        'Scan the QR code on the portal with the Aglive app to login';
    }
    console.error('callAPI -> error', e);
    throw new NetworkError(e);
  }
}
