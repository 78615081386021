import React, { useState, useEffect, useRef } from 'react';
import produce from 'immer';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

import SearchBar from "../../presentation/SearchBar";
import withHeader from '../../presentation/withHeader';
import CodeLibraryTable from './CodeLibraryTable';
import { ComponentToPrint } from '../site/ViewSite';
import { INITIAL_PRINT_STATE, CustomPrintModal } from './CustomPrintModal';

import { makeStyles, Grid } from "@material-ui/core";
import { fetchCodeEntries } from '../../store/code/actions';
import { iCodeEntryDocumentResponse } from '../../store/code/types';
import { PlantCodeDocumentDetails } from '../../store/plant/types';

const tableHeader = ['Asset Profile', 'Available Codes', 'Date Created', 'Action'];

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  searchBarContainer: {
    flexGrow: 1,
  },
}));

const UsedCodes: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const unprocessedCodeList = useAppSelector(state => state.code) as Array<iCodeEntryDocumentResponse<PlantCodeDocumentDetails>>;
  const usedCodeList = unprocessedCodeList.filter(entry => entry.details.used);

  const userid = useAppSelector(state => state.auth.wallet);
  const [query, setQuery] = useState('');
  const industryType = useAppSelector(
    (state) => state.user.businessProfileData.industryType,
  );
  useEffect(() => {
    dispatch(fetchCodeEntries(userid));
  }, []);

    // printing content
  // printing logic - print state holds the information necessary to build modal (assetProfileName, permit, qrCode.length)
  // pressing on 'print' icon will trigger the modal,
  // pressing 'cancel' reset the printState, pressing 'print' will set the content (printContent) on the print prompt (hidden at this stage) (ComponentToPrint)
  // the useEffect hook is triggered on printContent changes and will invoke handlePrint and show the print prompt
  const componentRef = useRef();
  const [printState, setPrintState] = useState(INITIAL_PRINT_STATE);
  const [printContent, setPrintContent] = useState([] as typeof INITIAL_PRINT_STATE.qrcode); // this is used to invoke useEffect hook
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (printContent.length) {
      handlePrint();
    }
  }, [printContent]);

  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label="Search"
          />
        </Grid>
      </Grid>

      <CodeLibraryTable 
        tableHeader={tableHeader}
        codeList={usedCodeList}
        query={query}
        onPrint={(entry: iCodeEntryDocumentResponse<PlantCodeDocumentDetails>) => {
          setPrintState({
            show: true,
            tokenSize: 1,
            qrcode: entry.details.codes,
            modalInfo: [{
              title: 'Asset Profile',
              value: entry.details.assetProfile.assetName,
            }, {
              title: 'Permit',
              value: entry.details.assetProfile.mainDisplay.permitNumber,
            }, {
              title: 'No. of Codes to Print',
              value: String(entry.details.codes.length),
            }],

            description: [
              {
                label: 'Species',
                value: entry.details.assetProfile.mainDisplay.species,
              },
              {
                label: 'Strain',
                value: `${entry.details.assetProfile.mainDisplay.strain} - ${entry.details.assetProfile.mainDisplay.subStrain}`,
              }
            ],
          });
        }}
      />

      <div style={{ display: 'none' }}>
        <ComponentToPrint 
          tokenSize={printState.tokenSize}
          qrcode={printContent}
          description={printContent.map(() => printState.description)}
          ref={componentRef}
        />
      </div>

      {printState.show && (
        <CustomPrintModal 
          show={printState.show}
          content={printState.qrcode}
          tokenSize={printState.tokenSize}
          modalInfo={printState.modalInfo}
          setPrintContent={setPrintContent}
          onPrint={() => {}}
          onSizeChange={e => setPrintState(prevState => 
            produce(prevState, draft => {
              draft.tokenSize = Number(e.target.value);
            })
          )}
          onCancel={() => setPrintState(INITIAL_PRINT_STATE)}
        />
      )}
    </>
  );
};

export default withHeader(
  {
    title: "View History",
    back: true
  },
  UsedCodes
);
