export default {
   //A
   AT_LEAST_VALIDATION: 'No Unique IDs',
   ASSET_INDUCTION: 'Asset Induction',
   //B
   //E
   //H
   HEADER_NOT_MATCH: 'Unmatched CSV Headers',
   //S
   SIMPLE_VALIDATION: 'Validation Fails at',
   //U
   UNIQUE_VALIDATION: 'Duplicate IDs',
}