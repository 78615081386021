import API from '../../config/api';

import {AppThunk} from '../types';
import {
  FETCH_CODE_TOKENS,
  iCodeEntryDocument,
  iCodeEntryDocumentRequest,
  iCodeEntryDocumentResponse,
} from './types';
import {toggleModal} from '../modal/actions';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import {callAPI} from '../../utils/network';
import {WebErrorType, NetworkError} from '../../utils/error';
import { PlantCodeDocumentDetails } from '../plant/types';

export type createTokenRes = Array<{
  externalIds: [
    {
      agliveToken: string;
      timestamp: any; // TODO
    },
  ];
  hash: string;
}>;

export type bulkCreateTokenRes = {
  message: string;
  data: Array<string>;
}

export const fetchEntryAction = <T>(entries: Array<iCodeEntryDocumentResponse<T>>) => ({
  type: FETCH_CODE_TOKENS,
  payload: entries
});

export async function fetchEntries<T = PlantCodeDocumentDetails>(userId: string, docId?: string) {
  let params: any = {
    type: 'code',
    userId,
  };

  if (docId) {
    params.docIds = [docId];
  }

  const docResponse = await callAPI<Array<iCodeEntryDocumentResponse<T>>>({
    url: API.GET.getDocuments,
    method: 'GET',
    params
  });

  return docResponse;
}

export function fetchCodeEntries(userId: string): AppThunk<Promise<Array<iCodeEntryDocumentResponse<PlantCodeDocumentDetails>>>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const docResponse = await fetchEntries<PlantCodeDocumentDetails>(userId);

      dispatch(fetchEntryAction(docResponse.filter(entry => entry.details?.assetProfile)));

      return docResponse;
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: e.message,
        }),
      );
    } finally {      
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function downloadCodeEntryCSV(
  codeEntry: iCodeEntryDocumentResponse<PlantCodeDocumentDetails>,
  csvContent: Array<csvInputT>,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const {
        auth: {wallet},
      } = getState();
      dispatch({type: SPINNER_TOGGLE_ON});

      // generate csv to download
      let csvFileName = `${codeEntry.details.assetProfile.assetName}-${new Date(codeEntry.createdAt).getTime()}.csv`;
      generateCSV(csvContent, csvFileName, PLANT_CSV_HEADER);

      if (!codeEntry.details.used) {
        await updateCodeEntry(codeEntry);
        dispatch(fetchCodeEntries(wallet));
      }
    } catch (e) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: 'Something went wrong!',
          subtitle: e?.message || 'Please try again.',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export async function updateCodeEntry(codeEntry: iCodeEntryDocumentResponse<PlantCodeDocumentDetails | iCodeEntryDocument>) {
  try {
    // mark code as used
    const response = await callAPI({
      url: API.POST.postDocument,
      method: 'PUT',
      data: {
        userId: codeEntry.userId,
        type: codeEntry.type,
        docId: codeEntry.docId,
        details: {
          ...codeEntry.details,
          used: true,
        },
      } as iCodeEntryDocumentRequest,
    });

    return response;
  } catch (e) {
    throw new NetworkError(e);
  }
}

export type csvInputT = [
  string, // agliveTokenId
  string,
  string, // product name / asset name
  string, // brand name / asset site map
  string, // company name
  string, // creator email
];

export const PRODUCT_CSV_HEADER = [
  ['Token', 'Type', 'Product', 'Brand', 'Company', 'Generated by'],
  [
    '<Token ID>',
    '<Token Type>',
    '<Product Name>',
    '<Brand Name>',
    '<Company Name>',
    '<Account user that generated tokens>',
  ],
];

export const PLANT_CSV_HEADER = [
  ['Token', 'Type', 'Asset Name', 'Location', 'Company', 'Generated by'],
  [
    '<Token ID>',
    '<Token Type>',
    '<Asset Name>',
    '<Location>',
    '<Company Name>',
    '<Account user that generated tokens>',
  ],
];

export function generateCSV(
  codeTokens: Array<csvInputT>,
  csvName = 'codes.csv',
  csvHeader,
): void {
  // https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
  let csvContent =
    'data:text/csv;charset=utf-8,' +
    csvHeader.map((row) => row.join(',')).join('\n') +
    '\n' +
    codeTokens.map((row) => row.join(',')).join('\n');
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', csvName);
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file
}
