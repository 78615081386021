import React from 'react';
import { Grid, Typography } from '@material-ui/core';

type TabHeadingType = {
  heading: string;
  subheading: string;
  containerStyle?: any;
};

const TabHeading: React.FC<TabHeadingType> = ({
  heading,
  subheading,
  containerStyle
}) => (
  <Grid 
    item
    container 
    style={{
      marginBottom: 20,
      ...containerStyle
    }} 
    direction="column"
  >
    <Typography variant="h6" style={{fontWeight: 700}}>
      {heading}
    </Typography>
    <Typography variant="h6" style={{fontSize: 14}}>
      {subheading}
    </Typography>
  </Grid>
);

export default TabHeading;
