import axios, {AxiosRequestConfig} from 'axios';
import {GlobalAccessToken} from '@aglive/frontend-core';

import {NetworkError} from './error';
import API from '../config/api';
import {refresh, authUrls} from './auth';
import CONSTANT from '../config/constant';
import {MobileGeolocation} from './type';

export async function callAPI<T = any>(config: AxiosRequestConfig) {
  try {
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json',
    };

    if (
      authUrls
        .filter((url) => url !== API.POST.createUserWithAuth)
        .includes(config.url)
    ) {
      Object.assign(config, {withCredentials: true});
    }

    const response = await axios.request<T>(config);

    return response.data;
  } catch (e) {
    if (e.response?.status === 401 && e.response.data) {
      e.response.data.title = 'Session expired';
      e.response.data.details =
        'Scan the QR code on the portal with the Aglive app to login';
    }
    console.error('callAPI -> error', e);
    throw new NetworkError(e);
  }
}

export const initiateAxiosInterceptor = () => {
  axios.interceptors.request.use((config) => {
    return new Promise((resolve, reject) => {
      if (!authUrls.includes(config.url)) {
        const userAuthToken = GlobalAccessToken.authHeader();
        if (userAuthToken) {
          Object.assign(config, {headers: {Authorization: userAuthToken}});
        }
      }
      // Attach geolocation to header depending on the routes, this function will mutate the config object
      attachGeolocation(config);

      return resolve(config);
    });
  });

  // refresh token if AccessToken expired
  // first time 401 then call refresh()
  // - if success -> continue axios with new header
  // - if fail -> cannot refresh since refresh token expired -> logout
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const {config, response} = error;
      let data = {};
      if (config.data) {
        try {
          data = JSON.parse(config.data);
        } catch (e) {
          data = config.data;
        }
      }
      let newConfig = {...config, data};

      // refresh when first time 401
      const needRefresh =
        response?.status === 401 &&
        config &&
        !config.__isRetryRequest &&
        !authUrls.includes(config.url);

      if (!needRefresh) {
        return Promise.reject(error);
      }

      newConfig = {...newConfig, _retry: true};
      try {
        if (!GlobalAccessToken.getRefreshFlag()) {
          GlobalAccessToken.setRefreshFlag(true);
          const res: any = await refresh();
          if (!GlobalAccessToken.setTokenFromRes({data: {...res}})) {
            return Promise.reject(error);
          }
          GlobalAccessToken.setRefreshFlag(false);
        } else {
          while (GlobalAccessToken.getRefreshFlag()) {
            await new Promise((resolve) =>
              setTimeout(
                () => resolve(console.log('Awaiting new access token...')),
                300,
              ),
            );
          }
        }
        if (GlobalAccessToken.authHeader() !== '') {
          const header = {Authorization: GlobalAccessToken.authHeader()};
          newConfig = {...newConfig, headers: header};
          return axios(newConfig);
        } else {
          return Promise.reject(error);
        }
      } catch (err) {
        GlobalAccessToken.setTokenFromRes({data: {accessToken: ''}});
        GlobalAccessToken.setRefreshFlag(false);
        return Promise.reject(err);
      }
    },
  );
};

export const attachGeolocation = (config: AxiosRequestConfig) => {
  const geolocation = localStorage.getItem(
    CONSTANT.USER_GEOLOCATION_STORAGE_KEY,
  );

  if (geolocation) {
    try {
      const parsedGeolocation: MobileGeolocation = JSON.parse(geolocation);

      // Add location to request header
      config.headers = {
        ...config.headers,
        lat: parsedGeolocation.location.lat,
        lng: parsedGeolocation.location.lng,
        accuracy: parsedGeolocation.accuracy,
      };
    } catch (error) {
      console.error('Error when parsing geolocation');
    }
  }

  return config;
};
