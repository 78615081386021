import { makeStyles } from "@material-ui/core";
import COLOR from "../../../styled/colors";

export const useStyle = makeStyles((theme) => ({
  fieldContainer: {
    backgroundColor: COLOR.GRAY_SOLID,
  },
  inputField: {
    backgroundColor: COLOR.WHITE,
    width: '100%',
  },
  contentButton: {
    borderColor: COLOR.GREEN,
    color: COLOR.GREEN,
  },
}));
