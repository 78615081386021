import React from 'react';
import { FormControl, FormLabel, Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

const RadioButtonGroup = ({title, options, ...props}) => (
  <FormControl component="fieldset">
    <FormLabel component="legend" style={{marginBottom: 30}}>
      <Typography variant='h3'>{title}</Typography>
    </FormLabel>
    <RadioGroup {...props}>
      {options.map(option => (
        <FormControlLabel control={<Radio color='primary' />} {...option} />
      ))}
    </RadioGroup>
  </FormControl>
);

export default RadioButtonGroup;
