import React from 'react';

import {makeStyles, Grid, Typography} from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';

import {iHealthOrganization} from './types';
import COLOR from '../../styled/colors';

interface Props {
  organization: iHealthOrganization;
}

const useStyles = makeStyles((theme) => ({
  organizationContainer: {
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: theme.spacing(1),
    height: '150px',
  },
  organizationLogo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 10,
    width: '70%',
    '@media (min-width:1400px)': {
      width: '60%',
    },
    '@media (min-width:1600px)': {
      width: '50%',
    },
    maxHeight: '140px',
  },
  organizationName: {
    fontSize: '20px',
    '@media (min-width:1400px)': {
      fontSize: '22px',
    },
  },
  phoneContainer: {
    margin: theme.spacing(1, 0),
  },
  phoneIcon: {
    fontSize: '18px',
  },
  phoneNumber: {
    fontSize: '16px',
    fontFamily: 'Open Sans',
    color: COLOR.GREENT_TEXT,
    marginLeft: theme.spacing(1),
  },
}));

const HealthOrganization: React.FC<Props> = ({organization}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      className={classes.organizationContainer}>
      <Grid item container xs={6} justify="center" alignItems="center" direction="column">
        <Grid item>
          <img
            src={organization.logo}
            alt={organization.name}
            className={classes.organizationLogo}
          />
        </Grid>
        <Grid item>
          <a href={organization.url} target="_blank" rel="noreferrer" className={classes.phoneNumber} style={{marginLeft: 0}}>Learn more</a>
        </Grid>
      </Grid>
      <Grid item container xs={6} justify="center" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="h4"
            role="label"
            className={classes.organizationName}>
            {organization.name}
          </Typography>
          <Grid
            container
            className={classes.phoneContainer}
            alignItems="center">
            <Grid item>
              <PhoneIcon className={classes.phoneIcon} />
            </Grid>
            <Grid item>
              <a
                href={`tel:${organization.phoneNumber}`}
                className={classes.phoneNumber}>
                {organization.phoneNumber}
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HealthOrganization;
