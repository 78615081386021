import React, {useState, useEffect} from 'react';
import moment from 'moment';
import COLOR from '../../styled/colors';

import {useHistory, useRouteMatch} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';

import {Buttons} from '../../presentation/ButtonsGroup';
import SearchBar from '../../presentation/SearchBar';
import MyButton from '../../presentation/button';
import MyTable from '../../presentation/Table';
import withHeader from '../../presentation/withHeader';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import {makeStyles, Grid, Button} from '@material-ui/core';
import {
  fetchSiteTokens,
  deleteSiteToken,
  nodeCount,
} from '../../store/site/actions';
import {SiteTokenResponse} from '../../store/site/types';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import {CASL} from '@aglive/frontend-core';

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  searchBarContainer: {
    flexGrow: 1,
    marginRight: 50,
  },
  buttonContainer: {
    marginTop: 6,
    marginRight: 2,
  },
  label: {
    textTransform: 'none',
  },
}));

const SiteLibrary: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {path} = useRouteMatch();
  const siteTokensResArr = useAppSelector((state) => state.site)?.sort(
    (token, token2) => {
      return token.details.siteName > token2.details.siteName ? 1 : -1;
    },
  );
  const locationState = useAppSelector((state) => state.location.location);
  const availableLocations = locationState.length
    ? locationState.filter(
        ({PICAddress}) =>
          !siteTokensResArr.find(
            (siteTokenRes) => siteTokenRes.details.location === PICAddress,
          ),
      )
    : [];
  const allowDuplicate = availableLocations.length >= 1;

  const duplicateHandler = (siteTokenRes: SiteTokenResponse) => {
    if (allowDuplicate) {
      history.push(`${path}/new`, {
        tree: siteTokenRes.details.layers,
        location: siteTokenRes.details.location,
        region: siteTokenRes.details.region,
        propertyType: siteTokenRes.details.propertyType,
      });
    } else {
      alert('You do not have any other locations to add site!');
      return null;
    }
  };

  const [query, setQuery] = useState('');

  const ability = CASL.useAbility(CASL.AbilityContext);
  const TABLE_HEADER = ['Site Map Name', 'No. of Layers', 'Date Created'];
  ability.can('update', 'site')
    ? TABLE_HEADER.push('Action')
    : TABLE_HEADER.push('');

  useEffect(() => {
    dispatch(fetchSiteTokens());
  }, []);

  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <Autocomplete
            options={siteTokensResArr?.map(o => o.details.siteName) ?? []}
            freeSolo={true}
            onChange={(event, value) => {
              value ? setQuery(value) : setQuery("")
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search by Site Map Name"
                color="success"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <CASL.Can I="create" a="site">
          <Grid item className={classes.buttonContainer}>
            <MyButton
              text={'Create New'}
              variant="contained"
              width={160}
              fontSize={18}
              onClick={() => history.push(`${path}/new`)}
            />
          </Grid>
        </CASL.Can>
      </Grid>
      <MyTable
        heads={TABLE_HEADER}
        rows={siteTokensResArr
          ?.filter((token) =>
            token.details.siteName.toLowerCase().includes(query.toLowerCase()),
          )
          .map((siteTokenRes) => [
            <Button
              onClick={() =>
                history.push(
                  `${path}/${siteTokenRes.externalIds[0].agliveToken}`,
                )
              }
              classes={{
                label: classes.label,
              }}>
              <span style={{color: COLOR.GREENT_TEXT}}>
                <u>{siteTokenRes.details.siteName}</u>
              </span>
            </Button>,
            nodeCount(siteTokenRes.details.layers) - 1, // - 1 because this will count the root node as well
            moment(siteTokenRes.externalIds[0].timestamp).format('DD/MM/YYYY'),
            <CASL.Can I="update" a="site">
              <div style={{display: 'flex', marginRight: -100}}>
                {/* TODO HACK */}
                <div style={{marginRight: 20}}>
                  <EditIcon
                    onClick={() =>
                      history.push(
                        `${path}/edit/${siteTokenRes.externalIds[0].agliveToken}`,
                      )
                    }
                  />
                </div>
                <div style={{marginRight: 20}}>
                  <FileCopyIcon
                    style={{
                      color: allowDuplicate ? 'black' : 'grey',
                    }}
                    onClick={() => duplicateHandler(siteTokenRes)}
                  />
                </div>
                <div style={{marginRight: 20}}>
                  <DeleteIcon
                    onClick={() =>
                      dispatch(
                        toggleModal({
                          status: 'warning',
                          title: 'Delete Site?',
                          subtitle: 'This action cannot be undone',
                          renderButton: (
                            <Buttons
                              leftButtonTitle="Cancel"
                              rightButtonTitle="Delete"
                              leftButtonOnClick={() =>
                                dispatch(toggleModalOff())
                              }
                              rightButtonOnClick={() =>
                                dispatch(deleteSiteToken(siteTokenRes, history))
                              }
                            />
                          ),
                        }),
                      )
                    }
                  />
                </div>
              </div>
            </CASL.Can>,
          ])}
      />
    </>
  );
};

export default withHeader(
  {
    title: 'Site Map Library',
  },
  SiteLibrary,
);
