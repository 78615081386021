// React
import React, {useState, useEffect} from 'react';
import {useHistory, Link, useLocation} from 'react-router-dom';
// Material UI Imports
import {
  makeStyles,
  Grid,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  Typography,
  TextField,
} from '@material-ui/core';

// Presentation, Container, Styles
import MyButton from '../../presentation/button';
import withHeader from '../../presentation/withHeader';
import COLOR from '../../styled/colors';
// Redux
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {RawAssetProfile, REMOVE_TEMP_ASSET_PROFILE} from '../../store/assetProfile/types';
import {fetchDropdownOptions, generateAssets} from '../../store/plant/actions';
import {SiteTokenResponse} from '../../store/site/types';
import {flattenTree} from '../../store/site/actions';
import { getBusinessProfile } from '../../store/profile/actions';
// Others
import CONSTANT from '../../config/constant';
import InputLabel from '@material-ui/core/InputLabel';

const CREATE_ASSET_PROFILE_PATH = '/private/assets/assetProfile/new';

const initialState = {
  assetProfile: '',
  pic_id: '', // pic_id is supposed to be replaced with a proper ID used for Cannabis in later phase
  site: '',
  quantity: '',
};

type DropdownOptions = {
  assetProfiles: Array<RawAssetProfile>;
  sites: Array<{name: string; id: string; layerName: string}> | null;
};

const GenerateCodes: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  // States
  const [validationState, setValidationState] = useState({...initialState});
  const [assetDetails, setAssetDetails] = useState({...initialState});
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOptions>({
    assetProfiles: [],
    sites: [],
  });
  const [sites, setSites] = useState<Array<SiteTokenResponse>>([]);
  const locations = useAppSelector(
    (state) => state.user.businessProfileData.location,
  );
  const tempAssetProfile = useAppSelector(
    (state) => state.assetProfile.tempAssetProfile,
  );

  // Side Effects
  useEffect(() => {
    // Fetch Asset Profiles and Sites
    dispatch(fetchDropdownOptions()).then((response) => {
      let initialDropdownOptions: DropdownOptions = {
        assetProfiles: [],
        sites: [],
      };

      if (response) {
        response.forEach((token) => {
          if (token.type === CONSTANT.ASSETTYPE.ASSET_PROFILE) {
            !(token as RawAssetProfile).details.archived &&
              initialDropdownOptions.assetProfiles.push(
                token as RawAssetProfile,
              );
          } else if (token.type === CONSTANT.ASSETTYPE.SITE) {
            setSites((prevState) =>
              prevState.concat(token as SiteTokenResponse),
            );
          }
        });
      }
      setDropdownOptions(initialDropdownOptions);
    });
    // Fetch latest business profile to get updated locations
    dispatch(getBusinessProfile());
  }, []);

  // Retrieve site based on selected location (pic_id)
  useEffect(() => {
    handleInputChange('', 'site');
    const siteList = sites.find(
      (site) => site.details.location === assetDetails.pic_id,
    );
    if (siteList) {
      const siteArray = flattenTree(siteList.details.layers);
      if (siteArray.length > 1) {
        siteArray.shift();
        if (siteArray) {
          setDropdownOptions((prevState) => ({...prevState, sites: siteArray}));
        }
      }
    } else {
      setDropdownOptions((prevState) => ({...prevState, sites: []}));
    }
  }, [assetDetails.pic_id, sites]);

  useEffect(() => {
    /**
     * If temporary asset profile is found in redux, it means that the user just 
     * returned from create asset profile page. Thus, populate the dropdown and
     * remove the temp asset profile.
     */
    if (tempAssetProfile) {
      handleInputChange(tempAssetProfile.externalIds[0].agliveToken, 'assetProfile');
      dispatch({type: REMOVE_TEMP_ASSET_PROFILE});
    }
  }, [tempAssetProfile]);

  // Handle all input fields
  const handleInputChange = (value: any, key: string) => {
    setAssetDetails((prevState) => ({...prevState, [key]: value}));
  };

  const handleGenerate = (download: boolean) => {
    const validatedData = validateData(assetDetails);
    setValidationState(validatedData);

    if (isAllowedtoSubmit(validatedData)) {
      const payload = {
        ...assetDetails,
        quantity: parseInt(assetDetails.quantity),
      };
      let callBackPath = '';
      if (download) {
        callBackPath = '/private/codes/generate/used';
      } else {
        callBackPath = '/private/codes/generate';
      }
      dispatch(
        generateAssets(
          payload,
          download,
          dropdownOptions.assetProfiles.find(
            (assetProfile) =>
              assetProfile.externalIds[0].agliveToken === payload.assetProfile,
          ),
          () => {
            history.push(callBackPath);
          },
        ),
      );
    }
  };

  return (
    <>
      <Grid container className={classes.settingContainer} spacing={3}>
        {/* Asset Profile */}
        <Grid item xs={12} lg={6}>
          <Grid container item direction="column">
            <Grid item>
              <Typography variant="h6" className={classes.inputLabel}>
                {'Asset Profile'}
              </Typography>
            </Grid>
            <Grid item>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!validationState.assetProfile}>
                <InputLabel id="demo-simple-select-outlined-label">{"Please select"}</InputLabel>
                <Select
                  label={"Please Select"}
                  value={assetDetails.assetProfile}
                  onChange={(e) =>
                    handleInputChange(e.target.value, 'assetProfile')
                  }>
                  {dropdownOptions.assetProfiles.map((assetProfile) => (
                    <MenuItem
                      value={assetProfile.externalIds[0].agliveToken}
                      key={assetProfile.externalIds[0].agliveToken}>
                      {assetProfile.details.assetName}
                    </MenuItem>
                  ))}
                </Select>
                <Grid
                  container
                  justify={
                    !!validationState.assetProfile
                      ? 'space-between'
                      : 'flex-end'
                  }>
                  {!!validationState.assetProfile && (
                    <FormHelperText>
                      {validationState.assetProfile}
                    </FormHelperText>
                  )}
                  <Link to={{pathname: CREATE_ASSET_PROFILE_PATH, state:{prevState: location.pathname}}} className={classes.hyperlink}>
                    Create new
                  </Link>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {/* Location */}
        <Grid item xs={12} lg={6}>
          <Grid container item direction="column">
            <Grid item>
              <Typography variant="h6" className={classes.inputLabel}>
                {'Location'}
              </Typography>
            </Grid>
            <Grid container item>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!validationState.pic_id}>
                <InputLabel id="demo-simple-select-outlined-label">{"Please select"}</InputLabel>
                <Select
                  label={"Please Select"}
                  value={assetDetails.pic_id}
                  onChange={(e) => handleInputChange(e.target.value, 'pic_id')}>
                  {locations.map((location) => (
                    <MenuItem
                      value={location.PICAddress}
                      key={location.PICAddress}>
                      {location.PICAddress}
                    </MenuItem>
                  ))}
                </Select>
                <Grid item>
                  {!!validationState.pic_id && (
                    <FormHelperText>{validationState.pic_id}</FormHelperText>
                  )}
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {/* Quantity to Generate */}
        <Grid item xs={12} lg={6}>
          <Grid container item direction="column">
            <Grid item>
              <Typography variant="h6" className={classes.inputLabel}>
                {'Quantity to Generate'}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                value={assetDetails.quantity}
                type="number"
                variant="outlined"
                error={!!validationState.quantity}
                helperText={validationState.quantity}
                InputProps={{inputProps: {min: 1}}}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value.replace(/[^0-9]/g, ''),
                    'quantity',
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>
        {/* Site Map */}
        <Grid item xs={12} lg={6}>
          <Grid container item direction="column">
            <Grid item>
              <Typography variant="h6" className={classes.inputLabel}>
                {'Site Map'}
              </Typography>
            </Grid>
            <Grid item>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!validationState.site}>
                <InputLabel id="demo-simple-select-outlined-label">{"Please select"}</InputLabel>
                <Select
                  label={"Please Select"}
                  value={assetDetails.site}
                  onChange={(e) => handleInputChange(e.target.value, 'site')}>
                  {dropdownOptions.sites.map((site) => (
                    <MenuItem value={site.layerName} key={site.layerName}>
                      {site.layerName}
                    </MenuItem>
                  ))}
                </Select>
                <Grid item>
                  {!!validationState.site && (
                    <FormHelperText>{validationState.site}</FormHelperText>
                  )}
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        justify="flex-end"
        className={classes.buttonGrid}
        spacing={3}>
        <Grid item>
          <MyButton
            text="Save"
            variant="outlined"
            buttonClass={classes.saveButton}
            onClick={() => handleGenerate(false)}
          />
        </Grid>
        <Grid item>
          <MyButton
            text="Save and Download"
            variant="contained"
            onClick={() => handleGenerate(true)}
          />
        </Grid>
      </Grid>
    </>
  );
};

const validateData = (assetDetails: typeof initialState) => {
  const validationState = {...initialState};

  // Validate asset profile is selected
  if (!assetDetails.assetProfile.trim().length)
    validationState.assetProfile = 'This field is required';

  // Validate location (pic_id) is selected
  if (!assetDetails.pic_id.trim().length)
    validationState.pic_id = 'This field is required';

  // Validate quantity has been entered
  if (!assetDetails.quantity.trim().length)
    validationState.quantity = 'This field is required';
  else if (parseInt(assetDetails.quantity) < 1)
    validationState.quantity = 'Quantity must be greater than 0';

  return validationState;
};

const isAllowedtoSubmit = (validationState: typeof initialState) => {
  return !Object.values(validationState).some((value) => value);
};

const useStyles = makeStyles((theme) => ({
  settingContainer: {
    marginBottom: 30,
  },
  buttonGrid: {
    width: '100%',
    marginTop: 170,
  },
  inputLabel: {
    fontWeight: 'bold',
    marginBottom: 10,
  },
  saveButton: {
    borderColor: COLOR.GREEN,
    color: COLOR.GREEN,
  },
  hyperlink: {
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    color: COLOR.GREENT_TEXT,
  }
}));

export default withHeader(
  {
    title: 'Generate Assets',
    back: true,
  },
  GenerateCodes,
);
