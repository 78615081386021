import produce from 'immer';

import { TokenService } from "@aglive/data-model";
import { isFormValid } from "../../../promotion/ProductProfile/CreateProductProfile/productProfileState/actions";
import { navigateStepAction } from "../../../promotion/ProductProfile/CreateProductProfile/productProfileState/types";
import { SecuritCodeActionTypes } from "./actions";
import { iCodeEntryDocument, iCodeEntryDocumentResponse } from '../../../../store/code/types';
import { RecursivePartial } from '../../../../utils/type';

export const INITIAL_CODE_STATE = (
  canSubmit = false,
) => ({
  state: {
    section: 'deliveryHistory' as 'deliveryHistory' | 'generateCode',
    progressBarPosition: 0 as 0 | 100,

    product: {
      isOpen: false,
      productProfileArray: [] as Array<TokenService.ProductToken>,
      selectedProductProfile: null as TokenService.ProductToken,
    },

    quantity: '',
    canSubmit, // state to track whether the button is enabled
    isValidated: false, // will change to true after validation tests pass,
    // useEffect hook below will submit form right after the isValidated is true
  },
  payload: {
    // note: the type of the token will change according to container value
    container: 'item',
    // will be filled at submission
    // date: '', 
    // source: {
    //   companyName: '',
    //   companyNumber: '',
    //   creatorEmail: ''
    // },
    product: {
      agliveToken: ''
    },

    // Delivery History
    showDeliveryHistory: true,
    showMap: false,
    isConfidential: false
  } as Omit<TokenService.ProductSecurityCode["details"], 'date' | 'source'>,
  validation: {
    product: '',
    quantity: ''
  }
});

export function securityCodeReducer<T = ReturnType<typeof INITIAL_CODE_STATE>>(
  state: T & ReturnType<typeof INITIAL_CODE_STATE>,
  action: SecuritCodeActionTypes | navigateStepAction) {
  switch (action.type) {
    case 'navigate':
      return produce(state, draft => {
        draft.state.section = action.payload;

        if (action.payload === 'deliveryHistory') {
          draft.state.progressBarPosition = 0;
        } else if (action.payload === 'generateCode') {
          draft.state.progressBarPosition = 100;
          draft.state.canSubmit = true;
        }
      });
    case 'navigate/next_step':
      return produce(state, draft => {
        draft.state.section = 'generateCode';
        draft.state.progressBarPosition = 100;
        draft.state.canSubmit = true;
      });
    case 'navigate/prev_step':
      return produce(state, draft => {
        draft.state.section = 'deliveryHistory';
        draft.state.progressBarPosition = 0;
      });
    
    // product related operations
    case 'load/load_productProfiles':
      return produce(state, draft => {
        draft.state.product.productProfileArray = action.payload;
      });
    case 'toggle/product_dropdown':
      return produce(state, draft => {
        draft.state.product.isOpen = !draft.state.product.isOpen;
      });
    case 'select/product_token_id':
      return produce(state, draft => {
        draft.payload.product.agliveToken = action.payload;
        draft.state.product.selectedProductProfile = 
          state.state.product.productProfileArray.find(profile => 
            profile.externalIds[0].agliveToken === action.payload
          );
      });

    // delivery operations
    case 'delivery/toggle_showDeliveryHistory':
      return produce(state, draft => {
        reduceToggleShowDeliveryHistory(
          draft.payload,
          state.payload
        );
      });
    case 'delivery/toggle_mapView':
      return produce(state, draft => {
        reduceToggleMapView(
          draft.payload,
          state.payload
        );
      })
    case 'delivery/toggle_confidentialMode':
      return produce(state, draft => {
        reduceToggleConfidentialMode(
          draft.payload,
          state.payload
        );
      });

    // code related operations
    case 'code/select_productContainer':
      return produce(state, draft => {
        draft.payload.container = action.payload;
      });
    case 'code/input_quantity':
      return produce(state, draft => {
        draft.state.quantity = action.payload;
      });

    // validation
    case 'submit/validate_form':
      return produce(state, draft => {
        // sanitize validation state if re-submit
        draft.validation.product = '';
        draft.validation.quantity = '';

        // metadata validation
        if (!draft.payload.product.agliveToken.length) {
          draft.validation.product = 'This field is required';
        }

        // quantity validation
        if (!draft.state.quantity.length) {
          draft.validation.quantity = 'This field is required';
        } else if (!Number(draft.state.quantity)) {
          draft.validation.quantity = 'Please enter a valid number';
        } else if (Number(draft.state.quantity) <= 0) {
          draft.validation.quantity = 'Please enter a valid number';
        }

        // if all tests passed, allow form submission
        if (isFormValid(draft.validation)) {
          draft.state.isValidated = true;
        }
      });
    default:
      return state;
  }
}

export const reduceToggleShowDeliveryHistory = (
  draftProduct: RecursivePartial<iCodeEntryDocumentResponse<iCodeEntryDocument>["details"]["product"]>,
  stateProduct: RecursivePartial<iCodeEntryDocumentResponse<iCodeEntryDocument>["details"]["product"]>,
) => {
  draftProduct.showDeliveryHistory = !draftProduct.showDeliveryHistory;

  // disable mapView and confidential mode switch is deliveryHistory is false
  if (stateProduct.showDeliveryHistory) {
    draftProduct.showMap = false;
    draftProduct.isConfidential = false;
  }
};

export const reduceToggleMapView = (
  draftProduct: RecursivePartial<iCodeEntryDocumentResponse<iCodeEntryDocument>["details"]["product"]>,
  stateProduct: RecursivePartial<iCodeEntryDocumentResponse<iCodeEntryDocument>["details"]["product"]>,
) => {
  // only enable switch if showDeliveryHistory is on and confidential mode is off
  if (stateProduct.showDeliveryHistory && !stateProduct.isConfidential) {
    draftProduct.showMap = !stateProduct.showMap;
  }
};

export const reduceToggleConfidentialMode = (
  draftProduct: RecursivePartial<iCodeEntryDocumentResponse<iCodeEntryDocument>["details"]["product"]>,
  stateProduct: RecursivePartial<iCodeEntryDocumentResponse<iCodeEntryDocument>["details"]["product"]>,
) => {
  // only enable switch if showDeliveryHistory is on
  if (stateProduct.showDeliveryHistory) {
    draftProduct.isConfidential = !draftProduct.isConfidential;
  }

  if (!stateProduct.isConfidential) {
    // if user switch on confidential mode, switch off map view
    draftProduct.showMap = false;
  }
}

