import produce from 'immer';
import {
  addState,
  addTypes,
  ManageUserType,
  CREATE_USER,
  FETCH_USERS,
  EDIT_CONSIGNEE_DATA,
  DELETE_CONSIGNEE_DATA,
  POST_CONSIGNEE_DATA,
  FETCH_CONSIGNEE_DATA,
  FETCH_CONSIGNMENT_DATA,
  DELETE_CONSIGNMENT_DATA,
  ConsigneeTypes,
  ConsignmentTypes,
  FETCH_CERESTAG_DATA,
  CerestagType,
  ADD_CERESTAG,
  REPLACE_CERESTAG,
  REMOVE_CERESTAG,
} from './types';

const initialState: addState = {
  users: [],
  consigneeData: [],
  consignmentData: [],
  cerestagData: {
    totalRow: 0,
    cerestag: [],
  },
};
export const initialUser: ManageUserType = {
  name: '',
  role: '',
  email: '',
  phone: '',
  userId: '',
};
export const initailConsignee: ConsigneeTypes = {
  receiverName: '',
  receiverEmail: '',
  receiverPhone: '',
  receiverAddress: '',
  receiverPostCode: '',
  receiverTown: '',
  receiverState: '',
  receiverPIC: '',
  receiverCountry: '',
};
export const initailConsignment: ConsignmentTypes = {
  name: '',
  forms: [],
  formsCount: '',
  status: [],
  consignee: {id: '', pic: ''},
  answers: [],
  attachments: [],
  shareTo: {mobile: [], email: []},
  destination: {
    pic: '',
    postcode: '',
    town: '',
    state: '',
    address: '',
  },
  animalSelectionMode: '',
  assets: [],
  declaration: {userId: ''},
  createdAt: null,
  eNVDS3Link: '',
};

export const initailCerestag: CerestagType[] = [];

export default function addReducer(state = initialState, action): addState {
  switch (action.type) {
    case CREATE_USER:
      return produce(state, (draft) => {
        draft.users.push(action.payload);
      });
    case FETCH_USERS:
      return produce(state, (draft) => {
        draft.users = action.payload;
      });
    case POST_CONSIGNEE_DATA:
      return produce(state, (draft) => {
        draft.consigneeData.push(action.payload);
      });
    case FETCH_CONSIGNEE_DATA:
      return produce(state, (draft) => {
        draft.consigneeData = action.payload;
      });
    case EDIT_CONSIGNEE_DATA:
      return produce(state, (draft) => {
        draft.consigneeData[action.payload.index] =
          action.payload.consigneeData;
      });
    case DELETE_CONSIGNEE_DATA:
      return produce(state, (draft) => {
        draft.consigneeData.splice(action.payload, 1);
      });
    case FETCH_CONSIGNMENT_DATA:
      return produce(state, (draft) => {
        draft.consignmentData = action.payload;
      });
    case DELETE_CONSIGNMENT_DATA:
      return produce(state, (draft) => {
        draft.consignmentData.splice(action.payload, 1);
      });
    case FETCH_CERESTAG_DATA:
      return produce(state, (draft) => {
        const {totalRow, cerestag} = action.payload;
        draft.cerestagData.cerestag = cerestag;
        draft.cerestagData.totalRow = totalRow;
      });
    case ADD_CERESTAG:
      return produce(state, (draft) => {
        const {index, tagVid} = action.payload;
        draft.cerestagData.cerestag[index].vid = tagVid;
      });
    case REPLACE_CERESTAG:
      return produce(state, (draft) => {
        const {index, tagVid} = action.payload;
        draft.cerestagData.cerestag[index].vid = tagVid;
      });
    case REMOVE_CERESTAG:
      return produce(state, (draft) => {
        const {index} = action.payload;
        const {vid, ...rest} = draft.cerestagData.cerestag[index];
        draft.cerestagData.cerestag[index] = rest;
      });
    default:
      return state;
  }
}
