import { TokenService } from "@aglive/data-model";
import { INITIAL_BRAND_STATE, pageArray } from "./reducer";

// metadata operations
const editName = (value: string) => ({
  type: 'input/name' as const,
  payload: value
});

const navigatePage = (value: typeof pageArray[number]) => ({
  type: 'navigate/switch_page' as const,
  payload: value
});

const navigateTab = (value: number, page: keyof typeof INITIAL_BRAND_STATE["state"]["tabValue"]) => ({
  type: 'navigate/switch_tab' as const,
  payload: {
    tab: value,
    page
  }
});

// launch screen actions
// display
const uploadMainLogo = (url: string) => ({
  type: 'upload/launchScreen_mainLogo' as const,
  payload: url
});

const uploadSecondaryLogo = (url: string) => ({
  type: 'upload/launchScreen_secondaryLogo' as const,
  payload: url
});

const editLogotText = (text: string) => ({
  type: 'input/launchScreen_logoText' as const,
  payload: text
});

// theme
const pickMainColor = (colorStr: string) => ({
  type: 'pick/launchScreen_mainColor' as const,
  payload: colorStr
});

const pickTextColor = (colorStr: string) => ({
  type: 'pick/launchScreen_textColor' as const,
  payload: colorStr
});

// aboutUs page actions
// display
const selectItemType = (itemType: 'image' | 'video') => ({
  type: 'select/aboutUS_displayItemType' as const,
  payload: itemType
});

const editDisplayImage = (imageUrl: string) => ({
  type: 'upload/aboutUS_displayImage' as const,
  payload: imageUrl
});

const editDisplayVideo = (videoUrl: string) => ({
  type: 'input/aboutUs_displayVideo' as const,
  payload: videoUrl
});

// headline
const editHeadlineHeading = (text: string) => ({
  type: 'input/aboutUs_headlineHeading' as const,
  payload: text
});

const editHeadlineAddress = (text: string) => ({
  type: 'input/aboutUs_headlineAddress' as const,
  payload: text
});

const editHeadlineWebsite = (text: string) => ({
  type: 'input/aboutUs_headlineWebsite' as const,
  payload: text
});

// content
const editContentDescription = (text: string) => ({
  type: 'input/aboutUs_contentDescription' as const,
  payload: text
});

// theme
const pickAboutUsMainColor = (colorStr: string) => ({
  type: 'pick/aboutUs_mainColor' as const,
  payload: colorStr
});

const pickAboutUsTextColor = (colorStr: string) => ({
  type: 'pick/aboutUs_textColor' as const,
  payload: colorStr
});

// load token
export const loadBrandToken = (token: TokenService.BrandToken) => ({
  type: 'load/load_brand' as const,
  payload: token
});

export type baseBrandActionTypes = ReturnType<
  | typeof editName
  | typeof navigatePage
  | typeof navigateTab

  | typeof uploadMainLogo
  | typeof uploadSecondaryLogo
  | typeof editLogotText

  | typeof pickMainColor
  | typeof pickTextColor

  | typeof selectItemType
  | typeof editDisplayImage
  | typeof editDisplayVideo

  | typeof editHeadlineHeading
  | typeof editHeadlineAddress
  | typeof editHeadlineWebsite

  | typeof editContentDescription

  | typeof pickAboutUsMainColor
  | typeof pickAboutUsTextColor
  >;

export type loadBrandActionTypes = ReturnType<typeof loadBrandToken>;

export default {
  editName,
  navigatePage,
  navigateTab,

  uploadMainLogo,
  uploadSecondaryLogo,
  editLogotText,
  pickMainColor,
  pickTextColor,
  
  selectItemType,
  editDisplayImage,
  editDisplayVideo,

  editHeadlineHeading,
  editHeadlineAddress,
  editHeadlineWebsite,

  editContentDescription,

  pickAboutUsMainColor,
  pickAboutUsTextColor
};