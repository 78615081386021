import { iCodeEntryDocument } from "../../../store/code/types";
import { SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON } from "../../../store/spinner/types";
import { callAPI } from "../../../utils/network";
import API from '../../../config/api';
import { TokenService } from "@aglive/data-model";
import { WebErrorType } from "../../../utils/error";
import { toggleModal } from "../../../store/modal/actions";
import { fetchEntries, fetchEntryAction } from "../../../store/code/actions";


export const fetchSecurityCodeEntries = async (
  dispatch: any,
  userid: string,
  used: boolean,
  fetchProductProfiles: boolean,
  ) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const codeListRes = await fetchEntries<iCodeEntryDocument>(userid);
      const codeList = codeListRes.filter(entry => entry.details.product && entry.details.used === used);

      dispatch(fetchEntryAction(codeList));

      let uniqueProfileMap = {};

      if (fetchProductProfiles) {
        // to keep track of unique product profiles id
        const uniqueProductProfileIdArr = [...new Set(codeList.map(entry => entry.details.product.product.agliveToken))];

        if (uniqueProductProfileIdArr.length) {
          // fetch product Profiles for library display
          const productProfileTokens: Array<TokenService.ProductToken> = await callAPI({
            url: API.POST.getTokenbyExternalId,
            method: 'POST',
            data: {
              latestDetails: true,
              status: ['exist'],
              activityType: [],
              externalIds: uniqueProductProfileIdArr.map(tokenId => ({
                agliveToken: tokenId
              })),
            }
          });
  
          uniqueProductProfileIdArr.forEach(tokenId => {
            uniqueProfileMap[tokenId] = productProfileTokens.find(token => token.externalIds[0].agliveToken === tokenId);
          });
        }
      }

      return {
        codeList,
        uniqueProfileMap
      };
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: e.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };