import moment from 'moment';
import * as Papa from 'papaparse';
import CONSTANT from '../../config/constant';

export function csvToJson(buffer: string): Array<Record<string, any>> {
  return Papa.parse(buffer, {header: true}).data;
}

type FormatJsonData = {
  date: string;
}

export function formatJsonData(
  data: Record<string, string>,
  format: FormatJsonData,
) {
  return Object.entries(data).reduce((result, [key, val]) => {
    let formattedVal = val;
    if (key.toLocaleLowerCase().includes('date') && format.date) {
      const dd_mm_yyy = CONSTANT.DD_MM_YYYY_REGEX.test(val)
        ? 'DD-MM-YYYY'
        : undefined;
      let date = moment(val, dd_mm_yyy);
      formattedVal = date.format(format.date);
    }
    return {...result, [key]: formattedVal};
  }, {});
}

export function jsonToCsv(data: Array<Record<string, any>>) {
  return Papa.unparse(data, {header: true});
}