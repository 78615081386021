import React from 'react';
import {Grid, makeStyles, Typography, Box} from '@material-ui/core';

import TextField from '@material-ui/core/TextField';

import Tabs from '../../../presentation/Tab';
import TabPanel from '../../../presentation/TabPanel';
import TextFieldWithLimit from '../../../presentation/TextFieldWithLimit';
import {
  MockUpPhoneBrandLaunchScreen,
  MockUpPhoneBrandSplashScreen,
  MockUpPhoneBrandAboutUs,
} from '../../../presentation/MockupPhoneBrand';
import SmallMockupPhoneBrand from '../../../presentation/SmallMockupPhoneBrand';
import CreateLaunchTabDisplay from './brandTabs/CreateLaunchTabDisplay';
import CreateLaunchTabTheme from './brandTabs/CreateLaunchTabTheme';
import CreateAboutUsTabDisplay from './brandTabs/CreateAboutUsTabDisplay';
import CreateAboutUsTabHeadline from './brandTabs/CreateAboutUsTabHeadline';
import CreateAboutUsTabTheme from './brandTabs/CreateAboutUsTabTheme';

import {
  AboutUsTabKeys,
  AboutUsTabMap,
  LaunchScreenTabKeys,
  LaunchScreenTabMap,
  INITIAL_BRAND_STATE,
  pageArray
} from './brandProfileState/reducer';

import { localDispatchDecorator } from '../../promotion/ProductProfile/CreateProductProfile/productProfileState/actions';
import localActions, { baseBrandActionTypes } from './brandProfileState/actions';

import COLOR from '../../../styled/colors';

export const useStyle = makeStyles((theme) => ({
  pagesPreviewContainer: {
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.grey[200],
    marginTop: 25,
    flexGrow: 1,
  },
  tabsContent: {
    backgroundColor: COLOR.WHITE,
  },
  contentButton: {
    borderColor: COLOR.GREEN,
    color: COLOR.GREEN,
  },
}));

type BaseBrandProps = {
  brandState: typeof INITIAL_BRAND_STATE;
  localDispatch: React.Dispatch<baseBrandActionTypes>;
  disabled?: boolean
}

const BaseBrand: React.FC<BaseBrandProps> = ({
  brandState,
  localDispatch,
}) => {
  const classes = useStyle();
  const localDispatchAction = localDispatchDecorator(localDispatch);

  return (
    <>
      <Grid item container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h6" role="label" style={{fontWeight: 600}}>
            Brand Name
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={brandState.payload.name}
            onChange={(e) => localDispatch(localActions.editName(e.target.value))}
          />
        </Grid>
      </Grid>
      <Grid item container style={{marginTop: 32}} spacing={3}>
        <Grid item xs={2} container direction="column">
          <Typography variant="h6" role="label" style={{fontWeight: 600}}>
            Select page to preview
          </Typography>
          <Grid item>
            {pageArray.map(pageName => (
              <SmallMockupPhoneBrand
                onClick={() => localDispatch(localActions.navigatePage(pageName))}
                pageName={pageName}
                isFocused={brandState.state.page === pageName}
                brand={brandState.payload}
              />
            ))}
          </Grid>
        </Grid>
        {(() => {
          switch (brandState.state.page) {
            case 'Launch Screen':
              return (
                <>
                  <Grid item xs={4} style={{marginTop: 50, paddingLeft: 30}}>
                    <MockUpPhoneBrandLaunchScreen page={brandState.payload.launchScreen} />
                  </Grid>
                  <Grid item xs={6} style={{marginTop: 70}}>
                    <Tabs
                      tabHeaders={LaunchScreenTabKeys as string[]}
                      value={brandState.state.tabValue.launchScreen}
                      setValue={value => localDispatch(localActions.navigateTab(value, 'launchScreen'))}>
                      <TabPanel
                        isShown={brandState.state.tabValue.launchScreen === LaunchScreenTabMap.display}
                        index={LaunchScreenTabMap.display}>
                        <Box p={3} style={{backgroundColor: COLOR.GRAY_SOLID}}>
                          <CreateLaunchTabDisplay
                            {...brandState.payload.launchScreen.display}
                            onLogoTextChange={localDispatchAction(localActions.editLogotText)}
                            onMainLogoChange={localDispatchAction(localActions.uploadMainLogo)}
                            onSecondaryLogoChange={localDispatchAction(localActions.uploadSecondaryLogo)}
                          />
                        </Box>
                      </TabPanel>
                      <TabPanel
                        isShown={brandState.state.tabValue.launchScreen === LaunchScreenTabMap.theme}
                        index={LaunchScreenTabMap.theme}>
                        <Box p={3} style={{backgroundColor: COLOR.GRAY_SOLID}}>
                          <CreateLaunchTabTheme
                            {...brandState.payload.launchScreen.theme}
                            onMainChange={localDispatchAction(localActions.pickMainColor)}
                            onTextChange={localDispatchAction(localActions.pickTextColor)}
                          />
                        </Box>
                      </TabPanel>
                    </Tabs>
                  </Grid>
                </>
              );
            case 'Splash Page':
              return (
                <Grid item xs={4} style={{marginTop: 50, paddingLeft: 30}}>
                  <MockUpPhoneBrandSplashScreen page={brandState.payload.launchScreen} />
                </Grid>
              );
            case 'About Us':
              return (
                <>
                  <Grid item xs={4} style={{marginTop: 50, paddingLeft: 30}}>
                    <MockUpPhoneBrandAboutUs page={brandState.payload.aboutUs} />
                  </Grid>
                  <Grid item xs={6} style={{marginTop: 70}}>
                    <Tabs
                      tabHeaders={AboutUsTabKeys as string[]}
                      value={brandState.state.tabValue.aboutUs}
                      setValue={value => localDispatch(localActions.navigateTab(value, 'aboutUs'))}>
                      <TabPanel
                        isShown={brandState.state.tabValue.aboutUs === AboutUsTabMap.display}
                        index={AboutUsTabMap.display}>
                        <Box p={3} style={{backgroundColor: COLOR.GRAY_SOLID}}>
                          <CreateAboutUsTabDisplay
                            {...brandState.payload.aboutUs.display}
                            onItemChange={localDispatchAction(localActions.selectItemType)}
                            onImageUrlChange={localDispatchAction(localActions.editDisplayImage)}
                            onVideoUrlChange={videoUrl => {}}
                          />
                        </Box>
                      </TabPanel>
                      <TabPanel
                        isShown={brandState.state.tabValue.aboutUs === AboutUsTabMap.headline}
                        index={AboutUsTabMap.headline}>
                        <Box p={3} style={{backgroundColor: COLOR.GRAY_SOLID}}>
                          <CreateAboutUsTabHeadline
                            {...brandState.payload.aboutUs.headLine}
                            onHeadingChange={localDispatchAction(localActions.editHeadlineHeading)}
                            onAddressChange={localDispatchAction(localActions.editHeadlineAddress)}
                            onWebsiteChange={localDispatchAction(localActions.editHeadlineWebsite)}
                          />
                        </Box>
                      </TabPanel>
                      <TabPanel
                        isShown={brandState.state.tabValue.aboutUs === AboutUsTabMap.content}
                        index={AboutUsTabMap.content}>
                        <Box p={3} style={{backgroundColor: COLOR.GRAY_SOLID}}>
                          <TextFieldWithLimit
                            title={'Description'}
                            content={brandState.payload.aboutUs.content.description}
                            style={classes.tabsContent}
                            limit={400}
                            multiline
                            rows={10}
                            onChange={localDispatchAction(localActions.editContentDescription)}
                          />
                        </Box>
                      </TabPanel>
                      <TabPanel
                        isShown={brandState.state.tabValue.aboutUs === AboutUsTabMap.theme}
                        index={AboutUsTabMap.theme}>
                        <Box p={3} style={{backgroundColor: COLOR.GRAY_SOLID}}>
                          <CreateAboutUsTabTheme
                            {...brandState.payload.aboutUs.theme}
                            onMainChange={localDispatchAction(localActions.pickAboutUsMainColor)}
                            onHeadingChange={localDispatchAction(localActions.pickAboutUsTextColor)}
                          />
                        </Box>
                      </TabPanel>
                    </Tabs>
                  </Grid>
                </>
              );
          }
        })()}
      </Grid>
    </>
  );
};

export default BaseBrand;
