export type SiteToken = {
  siteName: string;
  location: string;
  layers: LayerNode;
  region?: string;
  propertyType?: string;
};

export type SiteQRCode = {
  id: string; // `${siteState.externalIds[0].agliveToken}_${node.id}`
  siteName: string; // corresponds to SiteToken's siteName
  layerName: string; // user-defined name for individual node, corresponds to LayerNode's layerName
  location: string; // PIC
};

// config for react d3 tree node, purely for UI purpose
export const NodeShapeConfig = {
  shape: 'circle',
  shapeProps: {
    r: 30,
  },
};

export type LayerNode = {
  name: string; // required for react-d3-tree, displayed on the graph eg 1.2.1
  // must be a string otherwise error will be raised by react-d3-tree
  id: string; // required for react-d3-tree eg grandparent.parent.node up until root node
  layerName: string; // customizable by users thru text fields
  children: Array<LayerNode>;
};

export type SiteTokenResponse = {
  userId: string;
  type: string;
  tokenId: string;
  status: Array<string>;
  hash: string;
  externalIds: Array<{
    agliveToken: string;
    timestamp: string;
  }>;
  details: SiteToken;
  activities: Array<any>;
};

export interface SiteOptions {
  regions: string[];
  propertyTypes: string[];
}

export type siteState = Array<SiteTokenResponse>;

export const FETCH_SITE_TOKENS = 'FETCH_SITE_TOKENS';
export const DROP_SITE_TOKENS = 'DROP_SITE_TOKENS';
