import { TokenService } from "@aglive/data-model";
import { iCodeEntryDocument, iCodeEntryDocumentResponse } from "../../../../store/code/types";

const loadTokens = (payload: {
  entry: iCodeEntryDocumentResponse<iCodeEntryDocument>;
  productProfile: TokenService.ProductToken
}) => ({
  type: 'load/load_tokens' as const,
  payload,
});

export type EditCodeActionTypes = ReturnType<
  typeof loadTokens
>;

export default {
  loadTokens
};
