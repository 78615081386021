import {makeStyles} from '@material-ui/core';

import COLOR from '../../../styled/colors';

export const useStyles = makeStyles((theme) => ({
  tabContainer: {
    border: `1px solid ${COLOR.GRAY_BORDER}`,
    borderTop: 0,
    height: '600px',
    overflow: 'auto',
  },
  fieldContainer: {
    backgroundColor: COLOR.GRAY_SOLID,
  },
  errorHelperText: {
    backgroundColor: COLOR.GRAY_SOLID,
    margin: 0,
  },
  contentButton: {
    borderColor: COLOR.GREEN_BUTTON,
    color: COLOR.GREEN_BUTTON,
  },
  uploadBtn: {
    width: '100%',
    height: '60px',
  },
  inputField: {
    backgroundColor: COLOR.WHITE,
    width: '100%',
  },
  checkbox: {
    padding: 0,
    marginRight: theme.spacing(1),
  },
  errorText: {
    color: COLOR.RED,
  },
  mobInfoDeclaration: {
    marginBottom: theme.spacing(2.5),
  },
  signatureContainer: {
    paddingTop: theme.spacing(3),
    borderTop: `1px solid ${COLOR.GRAY_BORDER}`,
  },
}));
