import {AxiosRequestConfig} from 'axios';

import {DashboardCardType} from '../DashboardCard';
import {DashboardChartBarProps} from '../DashboardChartBar';
import {DashboardChartPieProps} from '../DashboardChartPie';

type DashboardWidgetPayloadType = {
  key?: string; // key is the key of the dynamic state and the key to access the fetched data, will need to decouple them next
  dependentKey?: Array<string>;
  source?:
    | string
    | AxiosRequestConfig
    | ((
        dataStore: any, // the dataStore contains all fetched data requestsed from all the different widgets and you can access the required data with the key assigned to the widget
        dep: Record<string, {value: string | string[]}>, // the dependencies contains all the necessary states for dynamic widgets such as dropdowns and you can access the relevant states with the key assigned to the widget
      ) => AxiosRequestConfig);
  transformData?: (
    dataStore: any,
    dep?: Record<string, {value: string | string[]}>,
  ) => any; // TODO return inputSchema
  composition?: Array<{
    type: typeof DashboardWidgetType[keyof typeof DashboardWidgetType];
    payload: DashboardWidgetPayloadType;
  }>;
};

export type DashboardConfigType = DashboardColumnConfigType;
type DashboardColumnConfigType = {
  styles?: any;
  metadata?: any; // name etc organisational purposes
  rows: Array<DashboardRowConfigType>;
};

export type DashboardRowConfigType = {
  styles?: any;
  widgets: Array<
    | {
        type: typeof DashboardWidgetType.CARD;
        payload: DashboardCardType & DashboardWidgetPayloadType;
      }
    | {
        type: typeof DashboardWidgetType.DROPDOWN;
        payload: {
          title: string;
        } & DashboardWidgetPayloadType;
        styles?: any;
      }
    | {
      type: typeof DashboardWidgetType.MULTIDROPDOWN;
      payload: {
        title: string;
      } & DashboardWidgetPayloadType;
      styles?: any;
    }
    | {
        type: typeof DashboardWidgetType.TABLE;
        payload: {
          pagination?: boolean
          columns: Array<{
            name: string;
            title: string;
            width: string;
          }>;
        } & DashboardWidgetPayloadType;
      }
    | {
        type: typeof DashboardWidgetType.BAR;
        payload: {
          title: string;
        } & DashboardWidgetPayloadType &
          Omit<DashboardChartBarProps, 'data'>;
      }
    | {
        type: typeof DashboardWidgetType.PIE;
        payload: {
          title: string;
        } & DashboardWidgetPayloadType &
          Omit<DashboardChartPieProps, 'data'>;
      }
  >;
};

export const DashboardWidgetType = {
  CARD: 'card',
  DROPDOWN: 'dropdown',
  TABLE: 'table',
  MULTIDROPDOWN: 'multidropdown',

  // CHART
  PIE: 'pie',
  BAR: 'bar',
} as const;
