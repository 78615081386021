export const inductionSchema = {
  Australia: {
    id: 'inductionSchema',
    type: 'array',
    items: {
      type: 'object',
      properties: {
        rfid: {
          type: 'string',
        },
        ownership: {
          type: 'string',
        },
        visual_tag: {
          type: ['number', 'string'],
        },
        society_number: {
          type: ['number', 'string'],
        },
        stud_id: {
          type: ['number', 'string'],
        },
        additional_tag: {
          type: ['number', 'string'],
        },
        external_identify: {
          type: ['number', 'string'],
        },
        management_id: {
          type: ['number', 'string'],
        },
        management_name: {
          type: 'string',
        },
        lot_id: {
          type: ['number', 'string'],
        },
        lot_name: {
          type: 'string',
        },
        paddock_id: {
          type: ['number', 'string'],
        },
        paddock_name: {
          type: 'string',
        },
        pen_id: {
          type: ['number', 'string'],
        },
        pen_name: {
          type: 'string',
        },
        zone_id: {
          type: ['number', 'string'],
        },
        zone_name: {
          type: 'string',
        },
        unit: {
          type: 'string',
          enum: ['kg', 'pound'],
        },
        species: {
          type: 'string',
        },
        species_type: {
          type: 'string',
        },
        breed: {
          type: 'string',
        },
        breed_type: {
          type: 'string',
        },
        induction_date: {
          type: 'string',
          pattern: /(^\d{4}-\d{2}-\d{2}$)|(^\d{2}\/\d{2}\/\d{4}$)/,
        },
        induction_weight: {
          type: 'number',
          minimum: 0,
        },
        breeder_pic_id: {
          type: 'string',
        },
        breeder_pic_name: {
          type: 'string',
        },
        origin_pic_id: {
          type: 'string',
        },
        origin_pic_name: {
          type: 'string',
        },
        maturity: {
          type: 'string',
        },
        sex: {
          type: 'string',
        },
        date_of_birth: {
          type: 'string',
          format: 'date',
        },
        weight_at_birth: {
          type: 'number',
          minimum: 0,
        },
        dry_sheep_equivalent: {
          type: 'number',
        },
        shearing_date: {
          type: 'string',
          format: 'date',
        },
        eartag_mark_name: {
          type: 'string',
        },
        eartag_pic: {
          type: 'string',
        },
        genetic_sire: {
          type: ['number', 'string'],
        },
        genetic_dam: {
          type: ['number', 'string'],
        },
      },
      required: [
        'rfid',
        'unit',
        'species',
        'species_type',
        'breed',
        'breed_type',
        'induction_date',
      ],
    },
    minItems: 1,
    // "maxItems": 20000 required if there is a limitation of backend
  },
  Canada: {
    id: 'canada_inductionSchema',
    type: 'array',
    items: {
      type: 'object',
      properties: {
        visual_tag: {
          type: ['number', 'string'],
        },
        visual_tag_colour: {
          type: 'string',
        },
        ceres_tag_esn: {
          type: ['number', 'string'],
        },
        ceres_tag_vid: {
          type: ['number', 'string'],
        },
        sex: {
          type: 'string',
        },
      },
      required: ['ceres_tag_esn', 'ceres_tag_vid'],
    },
    minItems: 1,
  },
  Warakirri: {
    // Please help to update birthDateSchema as well if any changes
    id: 'warakirri_inductionSchema',
    type: 'array',
    items: {
      type: 'object',
      properties: {
        rfid: {
          type: 'string',
        },
        visual_tag: {
          type: ['number', 'string'],
        },
        breed: {
          enum: ['hh', 'jj', 'xb', 'aa'],
        },
        induction_date: {
          type: 'string',
          pattern: /(^(0?[1-9]|[1-2][0-9]|(3)[0-1])(\/)((0?[1-9])|((1)[0-2]))(\/)\d{4}$)/,
          customError: 'does not match DD/MM/YYYY format',
        },
        induction_weight: {
          type: 'number',
          minimum: 0,
          customError: 'is not a number',
        },
        breeder_site: {
          type: 'string',
        },
        sex: {
          enum: ['male', 'female'],
        },
        date_of_birth: {
          type: 'string',
          pattern: /(^(0?[1-9]|[1-2][0-9]|(3)[0-1])(\/)((0?[1-9])|((1)[0-2]))(\/)\d{4}$)/,
          customError: 'does not match DD/MM/YYYY format',
        },
        genetic_sire: {
          type: ['number', 'string'],
        },
        genetic_dam: {
          type: ['number', 'string'],
        },
        birth_season: {
          enum: ['spring', 'autumn'],
        },
        birth_year: {
          type: 'number',
          minimum: 1900,
          maximum: 9999,
          customError: 'does not match YYYY format',
        },
      },
      required: [
        'rfid',
        'breed',
        'induction_date',
        'breeder_site',
        'sex',
        'birth_season',
        'birth_year',
      ],
    },
  },
};

export const weightSchema = {
  id: 'weightSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      rfid: {
        type: 'string',
      },
      visual_tag: {
        type: ['number', 'string'],
      },
      society_number: {
        type: ['number', 'string'],
      },
      stud_id: {
        type: ['number', 'string'],
      },
      additional_tag: {
        type: ['number', 'string'],
      },
      external_identify: {
        type: ['number', 'string'],
      },
      unit: {
        type: 'string',
        enum: ['kg', 'pound'],
      },
      weight: {
        type: 'number',
        minimum: 0,
      },
      date_of_weight: {
        type: 'string',
        format: 'date',
      },
    },
    required: ['rfid', 'unit', 'weight', 'date_of_weight'],
  },
  minItems: 1,
  // "maxItems": 20000 required if there is a limitation of backend
};

export const warakirriSchema = {
  id: 'warakirri_weightSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      rfid: {
        type: 'string',
      },
      weight: {
        type: 'number',
        minimum: 0,
      },
      date_of_weight: {
        type: 'string',
        pattern: /(^(0?[1-9]|[1-2][0-9]|(3)[0-1])(\/)((0?[1-9])|((1)[0-2]))(\/)\d{4}$)/,
        customError: 'does not match DD/MM/YYYY format',
      },
    },
    required: ['rfid', 'weight', 'date_of_weight'],
  },
  minItems: 1,
};

export const pregnancySchema = {
  id: 'pregnancySchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      rfid: {
        type: 'string',
      },
      visual_tag: {
        type: ['number', 'string'],
      },
      society_number: {
        type: ['number', 'string'],
      },
      stud_id: {
        type: ['number', 'string'],
      },
      additional_tag: {
        type: ['number', 'string'],
      },
      external_identify: {
        type: ['number', 'string'],
      },
      birth_productivity: {
        type: 'string',
      },
      conception_insemination: {
        type: 'string',
        format: 'date',
      },
      conception_rate: {
        type: 'number',
      },
      conception_status: {
        type: 'string',
      },
      conception_method: {
        type: 'string',
      },
      appraised_by: {
        type: 'string',
      },
      appraisal_test: {
        type: 'string',
        format: 'date',
      },
      joined_date: {
        type: 'string',
        format: 'date',
      },
      joined_due_date: {
        type: 'string',
        format: 'date',
      },
      joined_due_date_season: {
        type: 'string',
      },
      progeny_number: {
        type: 'number',
        minimum: 0,
      },
      progeny_dam_identity: {
        type: ['number', 'string'],
      },
      progeny_sire_identity: {
        type: ['number', 'string'],
      },
      date_of_birth: {
        type: 'string',
        format: 'date',
      },
      progeny_birth_weight_unit: {
        type: 'string',
        enum: ['kg', 'lbs'],
      },
      progeny_birth_weight: {
        type: 'number',
        minimum: 0,
      },
      progeny_gender: {
        type: 'string',
      },
    },
    required: ['rfid'],
  },
  minItems: 1,
  // "maxItems": 20000 required if there is a limitation of backend
};

export const warakirriPregancySchema = {
  id: 'warakirri_pregnancySchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      rfid: {
        type: 'string',
      },
      conception_status: {
        enum: ['empty', 'joined', 'ptic'],
      },
      conception_method: {
        enum: ['conventional', 'bull', 'sexed'],
      },
      due_date: {
        type: 'string',
        pattern: /(^(0?[1-9]|[1-2][0-9]|(3)[0-1])(\/)((0?[1-9])|((1)[0-2]))(\/)\d{4}$)/,
        customError: 'does not match DD/MM/YYYY format',
      },
    },
    if: {
      properties: {conception_status: {const: 'empty'}},
    },
    then: {
      required: ['rfid', 'conception_status'],
    },
    else: {
      required: ['rfid', 'conception_status', 'conception_method'],
    },
  },
};

export const warakirriStatusSchema = {
  id: 'warakirri_statusSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      rfid: {
        type: 'string',
      },
      date: {
        type: 'string',
        pattern: /(^(0?[1-9]|[1-2][0-9]|(3)[0-1])(\/)((0?[1-9])|((1)[0-2]))(\/)\d{4}$)/,
        customError: 'does not match DD/MM/YYYY format',
      },
      status: {
        enum: ['dead', 'sold', 'exported'],
      },
      who_sold_to: {
        type: 'string',
      },
      reason: {
        type: 'string',
      },
    },
    required: ['rfid', 'status', 'date'],
  },
  minItems: 1,
};
export const deceasedSchema = {
  id: 'deceasedSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      rfid: {
        type: 'string',
      },
      visual_tag: {
        type: ['number', 'string'],
      },
      society_number: {
        type: ['number', 'string'],
      },
      stud_id: {
        type: ['number', 'string'],
      },
      additional_tag: {
        type: ['number', 'string'],
      },
      external_identify: {
        type: ['number', 'string'],
      },
      death_reason: {
        type: 'string',
      },
      deceased_date: {
        type: 'string',
        format: 'date',
      },
      disposal_method: {
        type: 'string',
      },
    },
    required: ['rfid'],
  },
  minItems: 1,
  // "maxItems": 20000 required if there is a limitation of backend
};

export const heightSchema = {
  id: 'heightSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      rfid: {
        type: 'string',
      },
      visual_tag: {
        type: ['number', 'string'],
      },
      society_number: {
        type: ['number', 'string'],
      },
      stud_id: {
        type: ['number', 'string'],
      },
      additional_tag: {
        type: ['number', 'string'],
      },
      external_identify: {
        type: ['number', 'string'],
      },
      unit: {
        type: 'string',
        enum: ['cm', 'inch'],
      },
      height: {
        type: 'number',
        minimum: 0,
      },
      date_of_height: {
        type: 'string',
        format: 'date',
      },
    },
    required: ['rfid', 'unit', 'height', 'date_of_height'],
  },
  minItems: 1,
  // "maxItems": 20000 required if there is a limitation of backend
};

export const treatmentSchema = {
  id: 'treatmentSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      rfid: {
        type: 'string',
      },
      treatment_date: {
        type: 'string',
        pattern: /(^(0?[1-9]|[1-2][0-9]|(3)[0-1])(\/)((0?[1-9])|((1)[0-2]))(\/)\d{4}$)/,
        customError: 'does not match DD/MM/YYYY format',
      },
      batch_number: {
        type: 'number',
      },
      batch_expiry_date: {
        type: 'string',
        pattern: /(^(0?[1-9]|[1-2][0-9]|(3)[0-1])(\/)((0?[1-9])|((1)[0-2]))(\/)\d{4}$)/,
        customError: 'does not match DD/MM/YYYY format',
      },
      dose: {
        type: 'number',
      },
      treated_by: {
        type: 'string',
      },
      reason_for_treatment: {
        type: 'string',
      },
    },
    required: [
      'rfid',
      'treatment_date',
      'dose',
      'treated_by',
    ],
  },
  minItems: 1,
  // "maxItems": 20000 required if there is a limitation of backend
};

export const traitSchema = {
  id: 'traitSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      rfid: {
        type: 'string',
      },
      visual_tag: {
        type: ['number', 'string'],
      },
      society_number: {
        type: ['number', 'string'],
      },
      stud_id: {
        type: ['number', 'string'],
      },
      additional_tag: {
        type: ['number', 'string'],
      },
      external_identify: {
        type: ['number', 'string'],
      },
      traits: {
        type: ['number', 'string'],
      },
      traits_date: {
        type: 'string',
        format: 'date',
      },
    },
    required: ['rfid', 'traits', 'traits_date'],
  },
  minItems: 1,
  // "maxItems": 20000 required if there is a limitation of backend
};

export const moveSchema = {
  id: 'moveSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      rfid: {
        type: 'string',
      },
      visual_tag: {
        type: ['number', 'string'],
      },
      society_number: {
        type: ['number', 'string'],
      },
      stud_id: {
        type: ['number', 'string'],
      },
      additional_tag: {
        type: ['number', 'string'],
      },
      external_identify: {
        type: ['number', 'string'],
      },
      origin_pic_id: {
        type: 'string',
      },
      origin_pic_name: {
        type: 'string',
      },
      pic_group_id: {
        type: 'string',
      },
      pic_group_name: {
        type: 'string',
      },
      location_pic_id: {
        type: 'string',
      },
      location_pic_name: {
        type: 'string',
      },
      location_pic_group_id: {
        type: 'string',
      },
      location_pic_group_name: {
        type: 'string',
      },
      move_date: {
        type: 'string',
        customError: 'does not match YYYY-MM-DD format',
        pattern: /^\d{4}-\d{2}-\d{2}$/, // YYYY-MM-DD
      },
    },
    required: ['rfid', 'location_pic_id', 'location_pic_name', 'move_date'],
  },
  minItems: 1,
  // "maxItems": 20000 required if there is a limitation of backend
};
export const warakirriMoveSchema = {
  id: 'warakirri_moveSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      rfid: {
        type: 'string',
      },
      move_date: {
        type: 'string',
        customError: 'does not match DD/MM/YYYY format',
        pattern: /(^(0?[1-9]|[1-2][0-9]|(3)[0-1])(\/)((0?[1-9])|((1)[0-2]))(\/)\d{4}$)/,
      },
    },
    required: ['rfid', 'move_date'],
  },
  minItems: 1,
  // "maxItems": 20000 required if there is a limitation of backend
};

export const warakirriRfidReplacementSchema = {
  id: 'warakirri_rfidReplacementSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      old_rfid: {
        type: 'string',
      },
      new_rfid: {
        type: 'string',
      },
    },
    required: ['old_rfid', 'new_rfid'],
  },
  minItems: 1,
  // "maxItems": 20000 required if there is a limitation of backend
};

export const warakirriVisualTagReplacementSchema = {
  id: 'warakirri_visualTagReplacementSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      rfid: {
        type: 'string',
      },
      new_visual_tag: {
        type: ['number', 'string'],
      },
    },
    required: ['rfid', 'new_visual_tag'],
  },
  minItems: 1,
  // "maxItems": 20000 required if there is a limitation of backend
};

export const warakirriCalfInfoUpdateSchema = {
  id: 'warakirri_calfInfoUpdateSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      rfid: {
        type: 'string',
      },
      date_of_birth: {
        type: 'string',
        pattern: /(^(0?[1-9]|[1-2][0-9]|(3)[0-1])(\/)((0?[1-9])|((1)[0-2]))(\/)\d{4}$)/,
        customError: 'does not match DD/MM/YYYY format',
      },
      genetic_sire: {
        type: ['number', 'string'],
      },
      genetic_dam: {
        type: ['number', 'string'],
      },
    },
    required: ['rfid'],
  },
  minItems: 1,
}


export const digirodeoAddUHF = {
  id: 'digirodeo_uhf',
  apiType: 'Digirodeo',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      rfid: {
        type: 'string',
      },
      uhf: {
        type: 'string',
      },
    },
    required: ['rfid', 'uhf'],
  },
  minItems: 1,
};
export const uniqueIds = {
  Australia: ['rfid'],
  Canada: ['ceres_tag_esn', 'ceres_tag_vid'],
  Warakirri: ['rfid'],
  Group: ['name']
};

export const uniqueIdSchema = {
  id: 'uniqueIdSchema',
  type: 'object',
  additionalProperties: {
    type: 'array',
    uniqueItems: true,
  },
};

export const exportDataField = {
  identity: {
    rfid: {
      label: 'RFID',
      value: true,
    },
    QR_CODE: {
      label: 'QR Code',
      value: true,
    },
    visual_tag: {
      label: 'Visual Tag',
      value: true,
    },
    society_number: {
      label: 'Society Number',
      value: true,
    },
    stud_id: {
      label: 'Stud Id',
      value: true,
    },
    additional_tag: {
      label: 'Additional Tag',
      value: true,
    },
    external_identify: {
      label: 'External Identity',
      value: true,
    },
  },

  location: {
    pic_id: {
      label: 'PIC ID',
      value: true,
    },
    pic_name: {
      label: 'PIC Name',
      value: true,
    },
    management_id: {
      label: 'Management ID',
      value: true,
    },
    management_name: {
      label: 'Management Name',
      value: true,
    },
    lot_id: {
      label: 'Lot ID',
      value: true,
    },
    lot_name: {
      label: 'Lot Name',
      value: true,
    },
    paddock_id: {
      label: 'Paddock ID',
      value: true,
    },
    paddock_name: {
      label: 'Paddock Name',
      value: true,
    },
    pen_id: {
      label: 'Pen ID',
      value: true,
    },
    pen_name: {
      label: 'Pen Name',
      value: true,
    },
    zone_id: {
      label: 'Zone ID',
      value: true,
    },
    zone_name: {
      label: 'Zone Name',
      value: true,
    },
    breeder_pic_id: {
      label: 'Breeder PIC',
      value: true,
    },
    breeder_pic_name: {
      label: 'Breed PIC Name',
      value: true,
    },
    origin_pic_id: {
      label: 'Origin PIC ID',
      value: true,
    },
    origin_pic_name: {
      label: 'Origin PIC Name',
      value: true,
    },
    eartag_pic: {
      label: 'EarTag PIC',
      value: true,
    },
  },

  induction: {
    unit: {
      label: 'Unit',
      value: true,
    },
    species: {
      label: 'Species',
      value: true,
    },
    species_type: {
      label: 'Species Type',
      value: true,
    },
    breed: {
      label: 'Breed',
      value: true,
    },
    breed_type: {
      label: 'Breed Type',
      value: true,
    },
    induction_date: {
      label: 'Induction Date',
      value: true,
    },
    induction_weight: {
      label: 'Induction Weight',
      value: true,
    },
    maturity: {
      label: 'Maturity',
      value: true,
    },
    sex: {
      label: 'Sex',
      value: true,
    },
    date_of_birth: {
      label: 'Date Of Birth',
      value: true,
    },
    weight_at_birth: {
      label: 'Birth Weight',
      value: true,
    },
    dry_sheep_equivalent: {
      label: 'Dry Sheep Equivalent (DSE)',
      value: true,
    },
    shearing_date: {
      label: 'Shearing Date (Only for Sheep)',
      value: true,
    },
    eartag_mark_name: {
      label: 'EarTag / Mark Name',
      value: true,
    },
    genetic_sire: {
      label: 'Genetic Sire',
      value: true,
    },
    genetic_dam: {
      label: 'Genetic Dam',
      value: true,
    },
  },

  weight: {
    unit: {
      label: 'Unit',
      value: true,
    },
    last_current_weight: {
      label: 'Last Weight/Current Weight',
      value: true,
    },
    date_of_weight: {
      label: 'Date Of Weight',
      value: true,
    },
    last_weight_gain: {
      label: 'Last Weight Gain',
      value: true,
    },
    average_weight_gain: {
      label: 'Average Daily Gain',
      value: true,
    },
    weight_history: {
      label: 'Weight History',
      value: true,
    },
    estimate_date_of_weight: {
      label: 'Estimated Date Of Weight',
      value: true,
    },
    estimate_date_of_weight_date: {
      label: '',
      value: new Date(),
    },
    estimate_weight: {
      label: 'Estimated Weight',
      value: true,
    },
    estimate_weight_unit: {
      label: 'Unit',
      value: 'KG',
    },
    estimate_weight_number: {
      label: 'Number',
      value: 0.7,
    },
  },

  pregnancy: {
    appraisal_test: {
      label: 'Appraisal Test',
      value: true,
    },
    appraised_by: {
      label: 'Appraisal By',
      value: true,
    },
    birth_productivity: {
      label: 'Birth Productivity',
      value: true,
    },
    conception_insemination: {
      label: 'Conception/Insemination',
      value: true,
    },
    conception_method: {
      label: 'Conception Method',
      value: true,
    },
    conception_rate: {
      label: 'Conception Rate',
      value: true,
    },
    conception_status: {
      label: 'Conception Status',
      value: true,
    },
    date_of_birth: {
      label: 'Date Of Birth',
      value: true,
    },
    joined_date: {
      label: 'Joined Date',
      value: true,
    },
    joined_due_date: {
      label: 'Joined Due Date',
      value: true,
    },
    joined_due_date_season: {
      label: 'Joined due Date-Season',
      value: true,
    },
    progeny_birth_weight_unit: {
      label: 'Progeny Birth Weight Unit',
      value: true,
    },
    progeny_birth_weight: {
      label: 'Progeny Birth Weight',
      value: true,
    },
    progeny_dam_identity: {
      label: 'Progeny Dam Identify',
      value: true,
    },
    progeny_gender: {
      label: 'Progeny Gender',
      value: true,
    },
    progeny_number: {
      label: 'Progeny Number',
      value: true,
    },
    progeny_sire_identity: {
      label: 'Progeny Sire Identify',
      value: true,
    },
  },

  deceased: {
    deceased: {
      label: 'Deceased',
      value: true,
    },
    death_reason: {
      label: 'Deceased Reason',
      value: true,
    },
    deceased_date: {
      label: 'Deceased Date',
      value: true,
    },
    disposal_method: {
      label: 'Disposal Method',
      value: true,
    },
  },

  height: {
    unit: {
      label: 'Unit',
      value: true,
    },
    current_height: {
      label: 'Current Height',
      value: true,
    },
    date_of_height: {
      label: 'Date Of Height',
      value: true,
    },
    last_height: {
      label: 'Last Height',
      value: true,
    },
    previous_date: {
      label: 'Previous Date',
      value: true,
    },
    recent_height_gain: {
      label: 'Recent Height Gain',
      value: true,
    },
    number_of_days: {
      label: 'Number of Days',
      value: true,
    },
    daily_height_gain: {
      label: 'Daily Height Gain',
      value: true,
    },
  },

  treatment: {
    current_treatment: {
      label: 'Treatment',
      value: true,
    },
    treatment_date: {
      label: 'Treatment Date',
      value: true,
    },
    whp: {
      label: 'WHP',
      value: true,
    },
    whp_ends: {
      label: 'WHP Ends At',
      value: true,
    },
    esi: {
      label: 'ESI',
      value: true,
    },
    esi_ends: {
      label: 'ESI Ends At',
      value: true,
    },
    last_treatment: {
      label: 'Last Treatment',
      value: true,
    },
  },

  traits: {
    traits: {
      label: 'Traits',
      value: true,
    },
    traits_date: {
      label: 'Date Trait Recorded',
      value: true,
    },
  },
};

export const recordSiresSchema_OLD = {
  id: 'recordSiresSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      pic_number: {
        type: 'string',
      },
      angus_sire_id: {
        type: 'string',
      },
      ai_sire__no_yes_: {
        enum: ['yes', 'no'],
      },
      rfid: {
        type: 'string',
      },
      nlis_id: {
        type: 'string',
      },
    },
    required: ['pic_number', 'angus_sire_id', 'ai_sire__no_yes_'],
    if: {
      properties: {
        ai_sire__no_yes_: {const: 'No'},
      },
    },
    then: {
      anyOf: [
        {
          required: ['rfid'],
        },
        {
          required: ['nlis_id'],
        },
      ],
    },
  },
};

export const aiJoiningSchema = {
  id: 'aiJoiningSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      calving_year__yyyy_: {
        type: 'number',
        minimum: 1900,
        maximum: 9999,
      },
      angus_sire_id: {
        type: 'string',
      },
      no__of_females_joined: {
        type: 'number',
        minimum: 1,
      },
    },
    required: ['calving_year__yyyy_', 'angus_sire_id', 'no__of_females_joined'],
  },
};

export const sireGroupsSchema_OLD = {
  id: 'sireGroupsSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      calving_year__yyyy_: {
        type: 'number',
        minimum: 1900,
        maximum: 9999,
      },
      sire_group_name: {
        type: 'string',
      },
      joining_method__natural_ai_both_: {
        type: 'string',
      },
      angus_sire_id: {
        type: 'string',
      },
      rfid: {
        type: 'string',
      },
      nlis_id: {
        type: 'string',
      },
      ai___angus_sires_id: {
        type: 'string',
      },
    },
    required: [
      'calving_year__yyyy_',
      'sire_group_name',
      'joining_method__natural_ai_both_',
    ],
    allOf: [
      {
        if: {
          properties: {
            joining_method__natural_ai_both_: {
              pattern: /^(AI|ai)$/i,
            },
          },
        },
        then: {
          required: ['ai___angus_sires_id'],
        },
      },
      {
        if: {
          properties: {
            joining_method__natural_ai_both_: {
              pattern: /^(BOTH|both)$/i,
            },
          },
        },
        then: {
          anyOf: [
            {required: ['ai___angus_sires_id']},
            {required: ['rfid']},
            {required: ['nlis_id']},
          ],
        },
      },
      {
        if: {
          properties: {
            joining_method__natural_ai_both_: {
              pattern: /^(NATURAL|natural)$/i,
            },
          },
        },
        then: {
          anyOf: [{required: ['rfid']}, {required: ['nlis_id']}],
        },
      },
    ],
  },
};

export const recordAnimalsSchema_OLD = {
  id: 'recordAnimalsSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      pic_number: {
        type: 'string',
      },
      mob_name: {
        type: 'string',
      },
      animal_rfid: {
        type: 'string',
      },
      animal_nlis_id: {
        type: 'string',
      },
      visual_tag: {
        type: ['number', 'string'],
      },
      sex__steer_female_mixed_: {
        type: 'string',
      },
      birth_year__yyyy_: {
        type: 'number',
        minimum: 1900,
        maximum: 9999,
      },
      angus_sire_id: {
        type: 'string',
      },
      sire_group_name__as_recorded_in_sire_groups_: {
        type: 'string',
      },
      breeder_pic_number: {
        type: 'string',
      },
      birth_range_by_month___start_month__mm_yyyy_: {
        type: 'string',
        customError: 'does not match MM/YYYY or mmm-YY format',
        oneOf: [{pattern: /^\d{2}\/\d{4}$/}, {pattern: /^\w{3}-\d{2}$/}],
      },
      birth_range_by_month___end_month__mm_yyyy_: {
        type: 'string',
        customError: 'does not match MM/YYYY or mmm-YY format',
        oneOf: [{pattern: /^\d{2}\/\d{4}$/}, {pattern: /^\w{3}-\d{2}$/}],
      },
    },
    required: ['pic_number', 'mob_name', 'breeder_pic_number'],
    anyOf: [
      {
        required: ['animal_rfid'],
      },
      {
        required: ['animal_nlis_id'],
      },
    ],
    if: {
      properties: {
        sex__steer_female_mixed_: {
          enum: ['steer', 'mixed', 'female'],
        },
      },
      required: ['sex__steer_female_mixed_'],
    },
    then: {
      oneOf: [
        {
          required: ['angus_sire_id'],
        },
        {
          required: ['sire_group_name__as_recorded_in_sire_groups_'],
        },
      ],
    },
  },
};

export const recordAnimalsSchema = {
  id: 'recordAnimalsSchema2',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      pic_number: {
        type: 'string',
      },
      mob_name: {
        type: 'string',
      },
      animal_rfid_or_nlis_id: {
        type: 'string',
      },
      visual_tag: {
        type: ['number', 'string'],
      },
      sex__steer_female_mixed_: {
        type: 'string',
      },
      birth_year__yyyy_: {
        type: 'number',
        minimum: 1900,
        maximum: 9999,
      },
      angus_sire_id_or_sire_group_name__as_recorded_in_sire_groups_: {
        type: 'string',
      },
      breeder_pic_number: {
        type: 'string',
      },
      birth_range_by_month___start_month__mm_yyyy_: {
        type: 'string',
        customError: 'does not match MM/YYYY or mmm-YY format',
        oneOf: [{pattern: /^\d{2}\/\d{4}$/}, {pattern: /^\w{3}-\d{2}$/}],
      },
      birth_range_by_month___end_month__mm_yyyy_: {
        type: 'string',
        customError: 'does not match MM/YYYY or mmm-YY format',
        oneOf: [{pattern: /^\d{2}\/\d{4}$/}, {pattern: /^\w{3}-\d{2}$/}],
      },
    },
    required: [
      'pic_number',
      'mob_name',
      'breeder_pic_number',
      'animal_rfid_or_nlis_id',
      'angus_sire_id_or_sire_group_name__as_recorded_in_sire_groups_',
    ],
    if: {
      properties: {
        sex__steer_female_mixed_: {
          enum: ['steer', 'mixed', 'female'],
        },
      },
      required: ['sex__steer_female_mixed_'],
    },
  },
};

export const sireGroupsSchema = {
  id: 'sireGroupsSchema2',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      calving_year__yyyy_: {
        type: 'number',
        minimum: 1900,
        maximum: 9999,
      },
      sire_group_name: {
        type: 'string',
      },
      angus_sire_id: {
        type: 'string',
      },
    },
    required: ['calving_year__yyyy_', 'sire_group_name', 'angus_sire_id'],
  },
};

export const recordSiresSchema = {
  id: 'recordSiresSchema2',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      pic_number: {
        type: 'string',
      },
      angus_sire_id: {
        type: 'string',
      },
      ai_sire__no_yes_: {
        enum: ['yes', 'no'],
      },
    },
    required: ['pic_number', 'angus_sire_id', 'ai_sire__no_yes_'],
  },
};

export const consigmentAnimalsSchema = {
  id: 'consignment_animalSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      rfid: {
        type: 'string',
      },
    },
    required: ['rfid'],
  },
  minItems: 1,
};

export const groupSchema = {
  id: 'groupSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      rfid: {
        type: 'string',
      }
    },
    required: ['rfid'],
  },
  minItems: 1
};

export const groupIdSchema = {
  id: 'groupIdSchema',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      id: {
        type: 'string',
      }
    },
    required: ['id'],
  },
  minItems: 1
};
