import React from 'react';
import Qrcode from "qrcode.react";
import {makeStyles, Grid, Typography, FormControl, Select, MenuItem} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';

import COLOR from '../../styled/colors';

import { iCodeEntryDocument, iCodeEntryDocumentResponse } from "../../store/code/types";
import MyButton from '../../presentation/button';
import { PlantCodeDocumentDetails } from '../../store/plant/types';

export const INITIAL_PRINT_STATE = {
  // modal related
  show: false,
  tokenSize: 1,
  modalInfo: [{
    title: 'Asset Profile',
    value: '',
  }, {
    title: 'Permit',
    value: '',
  }, {
    title: 'No. of Codes to Print',
    value: '',
  }],
  
  // print content related
  qrcode: [] as Array<string>, // this will be passed to printContent to trigger print prompt
  description: [
    {
      label: '',
      value: '',
    },
    {
      label: '',
      value: '',
    }
  ],
};

const useStyles = makeStyles((theme) => ({
  // printing related
  printButton: {
    color: COLOR.GREEN,
    borderColor: COLOR.GREEN,
    width: 200
  },
  button: {
    width: 200
  },
  modalTextContainer: {
    margin: 0
  },
  modalTitleContainer: {
    fontWeight: 600
  },
  modalTextMarginContainer: {
    marginTop: 15
  },
  dropdownContainer: {
    minWidth: 200
  },
  remarkText: {
    fontSize: 12
  },
}));

type CustomPrintModalT = {
  show: typeof INITIAL_PRINT_STATE.show;
  content: typeof INITIAL_PRINT_STATE.qrcode;
  tokenSize: typeof INITIAL_PRINT_STATE.tokenSize;
  modalInfo: typeof INITIAL_PRINT_STATE.modalInfo;
  setPrintContent: (arg: typeof INITIAL_PRINT_STATE.qrcode) => void;
  onSizeChange: (event: React.ChangeEvent<{ name?: string; value: unknown; }>, child: React.ReactNode) => void;
  onPrint: () => void;
  onCancel: () => void;
}

export const CustomPrintModal: React.FC<CustomPrintModalT> = ({
  show,
  content,
  tokenSize,
  modalInfo,
  setPrintContent,
  onSizeChange,
  onPrint,
  onCancel,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={show}
      onClose={onCancel}
      aria-labelledby="qr-code-icon"
      aria-describedby="qr-code-print-modal"
      // PaperProps={{style: {"overflowY": 'visible',width: 480, height: 350}}}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle disableTypography>
        <Typography variant="h3" style={{paddingTop: 20, paddingBottom: 10, borderBottom: `${COLOR.GREENT_TEXT} 4px solid`}}>Print Codes</Typography>
      </DialogTitle>
      <DialogContent style={{height: 450}}>
        <Grid container xs={8} justify="center" style={{paddingTop: 20}}>
          <Typography variant="h6" className={classes.modalTitleContainer}>Print Preview</Typography>
        </Grid>
        <Grid container style={{paddingTop: 20}}>
          <Grid item container xs={8} justify="center">
            <Qrcode
              value={content[0]}
              style={{ width: 300, height: 300, paddingTop: 10 }}
            />
          </Grid>
          <Grid item container direction="column" xs={4}>
            {modalInfo.map(({title, value}, index) => (
              <React.Fragment key={title}>
                <Typography
                  variant="h6"
                  className={`${classes.modalTextContainer} ${classes.modalTitleContainer} ${index ? classes.modalTextMarginContainer : ''}`}>
                  {title}
                </Typography>
                <Typography variant="h6" className={classes.modalTextContainer}>{value}</Typography>
              </React.Fragment>
            ))}
            
            <Grid item>
              <Typography variant="h6" style={{ fontWeight: 600, padding: '10px 0' }}>
                Token Size ( 1- 8 )
              </Typography>
              <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">{"Please select"}</InputLabel>
                <Select
                  label={"Please Select"}
                  value={tokenSize}
                  onChange={onSizeChange}
                  className={classes.dropdownContainer}
                >
                  {Array(8).fill(null).map((_, index) => <MenuItem value={index + 1} key={index + 1}>Size {index + 1}</MenuItem>)}
                </Select>
              </FormControl>
              <Typography variant="h6" className={`${classes.modalTextContainer} ${classes.remarkText}`}>
                Actual Print Size = {tokenSize}" x {tokenSize}"
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{display: 'flex', justifyContent: 'center', marginBottom: 30}}>
        <MyButton
          text="Cancel"
          variant="outlined"
          onClick={onCancel}
          buttonClass={`${classes.printButton} ${classes.button}`}
        />
        <MyButton
          text="Print"
          variant="contained"
          buttonClass={classes.button}
          onClick={async () => {
            setPrintContent([...content]);
            await onPrint();
            onCancel();
          }}
          // this part will shallow clone content and force the printContent to update and trigger the useEffect hook
        />
      </DialogActions>
    </Dialog>
  );
};

export default CustomPrintModal;
