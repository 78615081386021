import React from 'react';

import {makeStyles, Grid, Typography} from '@material-ui/core';

import withHeader from '../../presentation/withHeader';
import {healthSupportConfig} from './healthSupportConfig';
import COLOR from '../../styled/colors';
import HealthOrganization from './HealthOrganization';

const useStyles = makeStyles((theme) => ({
  countryContainer: {
    marginBottom: theme.spacing(8),
  },
  countryHeading: {
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const HealthSupport: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container>
      {Object.entries(healthSupportConfig).map(([country, organizations]) => (
        <Grid container item xs={12} className={classes.countryContainer}>
          <Grid item xs={12} className={classes.countryHeading}>
            <Typography variant="h3" role="label">
              {country}
            </Typography>
          </Grid>
          <Grid item container justify="space-between" spacing={4}>
            {organizations.map((organization) => (
              <Grid item xs={12} md={4}>
                <HealthOrganization organization={organization} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default withHeader(
  {
    title: 'Health Support',
    margin: 60,
  },
  HealthSupport,
);
