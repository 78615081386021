import React from 'react';
import { TokenService } from '@aglive/data-model';
import { Box, Grid } from '@material-ui/core';
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";

import TextFieldWithLimit from '../../../presentation/TextFieldWithLimit';
import TabHeading from '../TabHeading';
import MyButton from '../../../presentation/button';

import useStyles from './styles';

import COLOR from '../../../styled/colors';

type contentType = {
  content: TokenService.ProductToken["details"]["pages"][number]["content"];
  onAdd: () => void;
  onDelete: (subheadingIndex: number) => void;
  onDup: (subheadingIndex: number) => void;
  onChange: (keys: Array<number | string>, value: any) => void;
}

const CreateProductTabContent: React.FC<contentType> = ({ 
  onAdd,
  onDelete,
  onDup,
  content,
  onChange 
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      {content.map(({subheading, content}, index) => (
        <CreateProductSubheadingCard
          heading={`Subheading ${index + 1}`}
          subheadingValue={subheading}
          content={content}
          onDelete={() => onDelete(index)}
          onDup={() => onDup(index)}
          onSubheadingChange={(value: string) => onChange(['content', index, 'subheading'], value)}
          onContentChange={(value: string) => onChange(['content', index, 'content'], value)}
        />
      ))}

      <Grid item style={{alignSelf: 'flex-end'}}>
        <MyButton
          onClick={onAdd}
          variant="outlined"
          text="Add"
          width={250}
          buttonClass={classes.contentButton}
        />
      </Grid>
    </Grid>
  );
};

type CreateProductSubheadingCardType = {
  heading: string;
  subheadingValue: string;
  content: string;
  onDelete: () => void;
  onDup: () => void;
  onSubheadingChange: (value: string) => void;
  onContentChange: (value: string) => void;
}

const CreateProductSubheadingCard: React.FC<CreateProductSubheadingCardType> = ({
  heading,
  subheadingValue,
  content,
  onDelete,
  onDup,
  onSubheadingChange,
  onContentChange
}) => {
  const classes = useStyles();
  
  return (
    <Grid item container direction="column" style={{marginBottom: 28}}>
      <Grid item container justify="space-between">
        <Grid item container xs={9}>
          <TabHeading heading={heading} subheading="Add more details" />
        </Grid>
        <Grid item container xs={3} justify="flex-end">
          <DeleteIcon
            style={{ paddingRight: 24, marginTop:16 }}
            onClick={onDelete}
          />
          <FileCopyIcon 
            style={{ marginTop: 16, marginRight: 16 }}
            onClick={onDup}
          />
        </Grid>
      </Grid>

      <Box p={3} style={{backgroundColor: COLOR.GRAY_SOLID}}>
        <Grid item container direction="column">
          <TextFieldWithLimit
            title="Subheading"
            content={subheadingValue}
            limit={30}
            onChange={onSubheadingChange}
            placeholder="Enter text"
            containerStyle={classes.textFieldContainer}
            style={classes.textField}
          />

          <TextFieldWithLimit
            title="Content"
            content={content}
            multiline
            rows={7}
            limit={600}
            onChange={onContentChange}
            placeholder="Add content here"
            style={classes.textField}
          />
        </Grid>
      </Box>
    </Grid>
  );
};

export default CreateProductTabContent;
