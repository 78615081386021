import React from 'react';

import produce from 'immer';
import {Grid, Box} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';

import {useStyle} from './styles';
import TextFieldWithLimit from '../../../presentation/TextFieldWithLimit';
import COLOR from '../../../styled/colors';
import {AdditionalContent as iAdditionalContent} from '../../../store/assetProfile/types';
import FieldHeading from '../../../presentation/FieldHeading';
import MyButton from '../../../presentation/button';

interface AdditionalContentProps {
  validationState: any;
  handleInputChange: (value: any, ...keys: Array<string | number>) => void;
  setValidationState: React.Dispatch<React.SetStateAction<any>>;
  state: {content: Array<iAdditionalContent>};
}

const AdditionalContent: React.FC<AdditionalContentProps> = ({
  validationState,
  handleInputChange,
  setValidationState,
  state,
}) => {
  const classes = useStyle();

  const onDup = (index: number) => {
    handleInputChange(
      state.content.reduce((accum, curVal, j) => {
        accum.push(curVal);
        if (index === j) {
          accum.push(curVal);
        }
        return accum;
      }, []),
      'content',
    );
    setValidationState((prevState) =>
      produce(prevState, (draft) => {
        draft.content = draft.content.reduce((accum, curVal, j) => {
          accum.push(curVal);
          if (index === j) {
            accum.push(curVal);
          }
          return accum;
        }, []);
      }),
    );
  };

  const onDelete = (index: number) => {
    handleInputChange(
      state.content.length > 1
        ? state.content.filter((_, j) => index !== j)
        : state.content,
      'content',
    );
    setValidationState((prevState) =>
      produce(prevState, (draft) => {
        draft.content =
          draft.content.length > 1
            ? draft.content.filter((_, i) => index !== i)
            : draft.content;
      }),
    );
  };

  return (
    <>
      {state.content.map((content, index) => (
        <Grid item key={index}>
          <Grid
            item
            container
            direction="column"
            style={{backgroundColor: COLOR.WHITE}}>
            <Grid item container direction="column">
              <Grid item container justify="space-between">
                <Grid item>
                  <FieldHeading
                    title={`Subheading ${index + 1}`}
                    subtitle="Add more details"
                  />
                </Grid>
                <Grid item>
                  {index !== 0 && (
                    <DeleteIcon
                      style={{
                        paddingRight: 24,
                        marginTop: 16,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        onDelete(index);
                      }}
                    />
                  )}

                  <FileCopyIcon
                    style={{marginTop: 16, marginRight: 16, cursor: 'pointer'}}
                    onClick={() => {
                      onDup(index);
                    }}
                  />
                </Grid>
              </Grid>
              <Box p={3} marginBottom={3} className={classes.fieldContainer}>
                <Grid item container direction="column">
                  <TextFieldWithLimit
                    title={'Subheading'}
                    content={content.subheading}
                    style={classes.inputField}
                    limit={30}
                    placeholder="Enter text"
                    onChange={(value) => {
                      handleInputChange(value, 'content', index, 'subheading');
                    }}
                    error={!!validationState.content[index].subheading}
                    helperText={validationState.content[index].subheading}
                  />
                  <TextFieldWithLimit
                    title="Content"
                    content={content.content}
                    style={classes.inputField}
                    limit={600}
                    placeholder="Enter text here"
                    onChange={(value) => {
                      handleInputChange(value, 'content', index, 'content');
                    }}
                    multiline={true}
                    rows={5}
                    error={!!validationState.content[index].content}
                    helperText={validationState.content[index].content}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ))}

      <Grid item style={{alignSelf: 'flex-start'}}>
        <MyButton
          onClick={() => {
            handleInputChange(
              [...state.content, {subheading: '', content: ''}],
              'content',
            );
            setValidationState((prevState) =>
              produce(prevState, (draft) => {
                draft.content.push({subheading: '', content: ''});
              }),
            );
          }}
          variant="outlined"
          text="Add"
          width={250}
          buttonClass={classes.contentButton}
        />
      </Grid>
    </>
  );
};

export default AdditionalContent;
