import React from 'react';
import moment from 'moment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';

import {MockupPhoneNewWithNav} from '../../../presentation/MockUpPhoneNew';
import { HyperLink } from '../../../presentation/word';

import WaterMarks from "../../../img/Watermarks.png";
import startIcon from '../../../img/accept.png';
import middleIcon from '../../../img/release.png';

import COLOR from '../../../styled/colors';

export const useStyles = makeStyles((theme) => ({
  contentContainer: {
    height: 570,
    paddingTop: 30,
    width: '100%'
  },
  showMapButtonContainer: {
    borderTop: `1px solid ${COLOR.GRAY_BORDER}`,
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
    margin: '10px 0 20px 22px',
  },
  deliveryHistoryContainer: {
    marginLeft: 0
  },

  protectedByContainer: {
    alignSelf: 'center', paddingTop: 20
  },
  protectedBy: {
    width: 80,
    height: 20
  },

  verticalLine: {
    transform: 'scale(0.5, 1)',
    margin: 0,
    marginTop: -3,
    height: 50,
    border: `1px solid ${COLOR.GREEN_HIGHLIGHT}`,
    backgroundColor: COLOR.GREEN_HIGHLIGHT
  },
  subtitileText: {
    fontSize: 10,
    color: COLOR.GRAY_TEXT
  }
}));

const MOCK_UP_DATA = (isConfidential) => [
  {
    date: new Date('2021-01-27T01:10:00'),
    title: 'Your Company Name',
    subtitle: isConfidential ? 'Secure Location' : 'State, Country',
    isLinkStyle: !isConfidential
  },
  {
    date: new Date('2021-01-28T08:10:00'),
    title: isConfidential ? 'Trusted Partner' : 'Receiver 1',
    subtitle: isConfidential ? 'Secure Location' : 'State, Country',
    isLinkStyle: !isConfidential
  },
  {
    date: new Date('2021-01-29T08:10:00'),
    title: isConfidential ? 'Trusted Partner' : 'Receiver 2',
    subtitle: isConfidential ? 'Secure Location' : 'State, Country',
    isLinkStyle: !isConfidential
  },
  {
    date: new Date('2021-01-29T08:10:00'),
    title: isConfidential ? 'Trusted Partner' : 'Receiver 3',
    subtitle: isConfidential ? 'Secure Location' : 'State, Country',
    isLinkStyle: !isConfidential
  },
  {
    date: new Date('2021-01-29T08:10:00'),
    title: isConfidential ? 'Trusted Partner' : 'Receiver 4',
    subtitle: isConfidential ? 'Secure Location' : 'State, Country',
    isLinkStyle: !isConfidential
  },
];

type MockupPhoneDeliveryType = {
  showDeliveryHistory: boolean;
  showMap: boolean;
  isConfidential: boolean;
}

const MockupPhoneDelivery: React.FC<MockupPhoneDeliveryType> = ({
  showDeliveryHistory,
  showMap,
  isConfidential,
}) => {
  const classes = useStyles();

  return (
    <MockupPhoneNewWithNav
      backgroundColor={COLOR.BLACK_BG}
      navTitle={"Delivery History"}
    >
      <Grid container direction="column">
        <Grid
          item
          container
          direction="column"
          className={classes.contentContainer}
          spacing={3}
        >
          {showMap && (
            <Grid
              item
              container
              className={classes.showMapButtonContainer}
              xs={11}
              justifyContent="space-between"
            >
              <Grid item>
                <Typography variant="subtitle1" style={{fontSize: 14}}>
                  Map View
                </Typography>
              </Grid>
              <Grid item>
                <ChevronRightIcon fontSize="small" />
              </Grid>
            </Grid>
          )}
          <Grid item container className={classes.deliveryHistoryContainer}>
            {MOCK_UP_DATA(isConfidential).map((node, index, arr) => (
              <DeliveryNode
                position={index}
                leftTitle={moment(node.date).format("DD MMM YYYY")}
                leftSubtitle={moment(node.date).format("hh:mm")}
                rightTitle={
                  node.isLinkStyle ? (
                    <HyperLink
                      onClick={() => {}}
                      style={{
                        textDecoration: 'underline',
                        fontSize: 14
                      }}
                    >
                      {node.title}
                    </HyperLink>
                  ) : node.title
                }
                rightSubtitle={node.subtitle}
                renderTail={index !== arr.length - 1}
              />
            ))}
          </Grid>
        </Grid>

        <Grid item className={classes.protectedByContainer}>
          <img
            src={WaterMarks}
            className={classes.protectedBy}
            alt="protected by Aglive"
          />
        </Grid>
      </Grid>
    </MockupPhoneNewWithNav>
  );
};

type DeliveryNodeType = {
  position: number;
  leftTitle: string;
  leftSubtitle: string;
  rightTitle: string | JSX.Element;
  rightSubtitle: string;
  renderTail: boolean;
}

const DeliveryNode: React.FC<DeliveryNodeType> = ({
  position,
  leftTitle,
  leftSubtitle,
  rightTitle,
  rightSubtitle,
  renderTail
}) => {
  const classes = useStyles();
  
  return (
    <Grid item container>
      <Grid item container direction="column" xs={5}>
        <Grid item style={{alignSelf: 'flex-end'}}>
          <DeliveryNodeTitle>{leftTitle}</DeliveryNodeTitle>
        </Grid>
        <Grid item style={{alignSelf: 'flex-end'}}>
          <DeliveryNodeSubtitle>{leftSubtitle}</DeliveryNodeSubtitle>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction="column"
        xs={2}
        style={{paddingTop: position === 0 ? 3 : undefined}}
        alignItems="center"
      >
        <Grid item>
          <img src={position ? startIcon : middleIcon} alt="" style={{
            width: 10
          }} />
        </Grid>
        {renderTail && (
          <Grid item>
            <hr className={classes.verticalLine} />
          </Grid>
        )}
      </Grid>

      <Grid item container direction="column" xs={5}>
        <Grid item>
          {typeof rightTitle === 'string' ? (
            <DeliveryNodeTitle>{rightTitle}</DeliveryNodeTitle>
          ) : (
            rightTitle
          )}
        </Grid>
        <Grid item>
          <DeliveryNodeSubtitle>{rightSubtitle}</DeliveryNodeSubtitle>
        </Grid>
      </Grid>
    </Grid>
  );
};

const DeliveryNodeTitle = ({ children }) => (
  <Typography variant="h6" style={{fontSize: 14}}>
    {children}
  </Typography>
);

const DeliveryNodeSubtitle = ({ children }) => {
  const classes = useStyles();
  
  return (
    <Typography variant="h6" className={classes.subtitileText}>
      {children}
    </Typography>
  );
};

export default MockupPhoneDelivery;
