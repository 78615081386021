import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {useHistory} from 'react-router';
import {useAppDispatch} from '../../../utils/hooks';

import {Button, Grid} from '@material-ui/core';

import {useStyles} from './styles';
import SearchBar from '../../../presentation/SearchBar';
import MyTable from '../../../presentation/Table';
import withHeader from '../../../presentation/withHeader';
import {HyperLink} from '../../../presentation/word';

import { fetchProductProfileTokens } from '../ProductProfileLibrary/utils';
import { toggleModal } from '../../../store/modal/actions';
import { SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON } from '../../../store/spinner/types';
import toggleQRCodeModal from '../ProductProfile/CreateProductProfile/toggleQRCodeModal';
import { unarchiveProductProfile } from './utils';

import COLOR from '../../../styled/colors';
import CONSTANT from '../../../config/constant';
import GreenQRCodeIcon from '../../../img/GreenQRCodeIcon.svg';

import { WebErrorType } from '../../../utils/error';
import {TokenService} from '@aglive/data-model';

const CREATE_PRODUCT_PROFILE_LIBRARY_PATH = '/private/products/productProfile';

const tableHeaders = [
  'Product Name',
  'Date Created',
  'Preview',
  'Action',
];

const ArchivedProductProfileLibrary: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [query, setQuery] = useState('');
  const [productProfiles, setProductProfiles] = useState<Array<TokenService.ProductToken>>([]);

  useEffect(() => {
    fetchProductProfileTokens()
      .then(profiles => setProductProfiles(profiles.filter(profile => profile.details.archive)))
      .catch(e => {
        const error = e as WebErrorType;

        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          })
        );
      });
  }, []);

  const onUnarchive = async (productProfile: TokenService.ProductToken) => {
    try {
      dispatch({ type: SPINNER_TOGGLE_ON });
      await unarchiveProductProfile(productProfile);

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Updated',
          CTAHandler: () => history.push(CREATE_PRODUCT_PROFILE_LIBRARY_PATH),
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        })
      );
    } finally {
      dispatch({ type: SPINNER_TOGGLE_OFF });
    }
  };

  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <SearchBar
          query={query}
          setQuery={setQuery}
          label="Search Product Name"
        />
      </Grid>
      <MyTable
        firstColumnWidth={classes.firstColumnWidth}
        heads={tableHeaders}
        rows={productProfiles
          ?.filter(profile => profile.details.name.toLowerCase().includes(query.toLowerCase()))
          .map(profile => [
            <Link
              to={{
                pathname: `${CREATE_PRODUCT_PROFILE_LIBRARY_PATH}/view/${profile.externalIds[0].agliveToken}`
              }}
              className={classes.hyperlink}>
              {profile.details.name}
            </Link>,
            moment(profile.externalIds[0].timestamp).format(
              'DD/MM/YYYY',
            ),
            <Button
              onClick={() => toggleQRCodeModal(
                dispatch,
                history,
                {
                  status: '',
                  title: 'Preview',
                  QRCodeContent: CONSTANT.SCAN_URL(profile.externalIds[0].agliveToken),
                  hideGenerate: true,
                }
              )}>
              <img src={GreenQRCodeIcon} alt='QR Code Icon' />
              <span
                style={{
                  color: COLOR.GREENT_TEXT,
                  textTransform: 'capitalize',
                  marginLeft: '8px',
                }}>
                <u>Preview</u>
              </span>
            </Button>,
            <div style={{display: 'flex'}}>
              <HyperLink
                onClick={(e) => {
                  e.preventDefault();
                  onUnarchive(profile);
                }}
                href="">
                Unarchive
              </HyperLink>
            </div>,
          ])
        }
      />
    </>
  );
};

export default withHeader(
  {
    title: 'Archived Product Profiles',
    back: true,
  },
  ArchivedProductProfileLibrary,
);
