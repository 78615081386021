import { TokenService } from '@aglive/data-model';

import API from '../../../config/api';
import CONSTANT from '../../../config/constant';
import { callAPI } from '../../../utils/network';

export const unarchiveProductProfile = async (productProfile: TokenService.ProductToken) => {
  const response = await callAPI({
    url: API.POST.createActivity,
    method: 'POST',
    data: {
      tokens: [
        {
          type: CONSTANT.ASSETTYPE.PRODUCT,
          externalIds: {
            agliveToken: productProfile.externalIds[0].agliveToken,
          },
          activities: [
            {
              type: 'UP_details',
              details: {
                archive: false
              },
            },
          ],
        },
      ]
    }
  });

  return response;
};
