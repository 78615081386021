import { Typography } from '@material-ui/core';
import React from 'react';

import {useStyles} from './styles';

type PromotionHeadingProps = {
  title?: string;
  subtitle?: string;
}

const PromotionHeading: React.FC<PromotionHeadingProps> = ({
  title = 'Start a promotion project today!',
  subtitle = 'Imagine creating a powerful promotion like the ones below'
}) => {
  const classes = useStyles();

  return (
    <div>
      <Typography
        component="h3"
        className={classes.startProjectHeading}
        align="center">
        {title}
      </Typography>
      <Typography className={classes.startProjectDesc} align="center">
        {subtitle}
      </Typography>
    </div>
  );
};

export default PromotionHeading;
