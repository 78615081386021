import React, {useState, useRef, useEffect} from 'react';
import {useReactToPrint} from 'react-to-print';
import {TokenService} from '@aglive/data-model';

import {useAppDispatch} from '../hooks';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../../store/spinner/types';

export const usePrintPoster = (isAngus: boolean) => {
  const dispatch = useAppDispatch();
  const [printContent, setPrintContent] = useState<{
    display: TokenService.PromotionToken['details']['display'];
    story: TokenService.ProductToken['details']['story'];
    agliveToken: string;
  }>();
  const [customQRWidth, setCustomQRWidth] = useState<number | undefined>(
    undefined,
  );
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setPrintContent(undefined);
    },
  });

  useEffect(() => {
    if (printContent) {
      // The function below is used for calculating the QR code size based on the footer image
      (async () => {
        if (printContent.display.footerImage) {
          dispatch({type: SPINNER_TOGGLE_ON});
          let waitImage = true;
          let waitCount = 5;
          const footerImage = new Image();
          footerImage.src = printContent.display.footerImage;
          footerImage.onload = function () {
            const tempHeight = (360 / footerImage.width) * footerImage.height;
            const renderedHeight = tempHeight < 270 ? tempHeight : 270;
            const newHeight = 270 - renderedHeight + 160 + (isAngus ? -25 : 20);
            if (newHeight < 220) {
              setCustomQRWidth(newHeight);
            } else {
              setCustomQRWidth(220);
            }
            waitImage = false;
          };
          do {
            // Delay printing to ensure the images are fetched before printing
            // Feel free to change this if there is a proper way to handle image pre-render
            // Wait for a maximum of 5 seconds
            await new Promise((resolve) => setTimeout(resolve, 1000));
            waitCount--;
          } while (waitImage && waitCount > 0);
          dispatch({type: SPINNER_TOGGLE_OFF});
        } else {
          setCustomQRWidth(undefined);
        }
        handlePrint();
        return;
      })();
    }
  }, [printContent]);

  return {printContent, setPrintContent, customQRWidth, componentRef};
};
