import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';
import DialogMessage from '../../presentation/DialogMessage';
import ResultTable from './ResultTable';
import WORD from '../../intl/en';
import COLOR from '../../styled/colors';

import {JSONSchemaValidationError} from '../../utils/csvReader';

export const assetStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(6),
  },
  subTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontWeight: 600, // TODO
  },
  formControl: {
    width: '100%',
    maxWidth: 480,
  },
  select: {
    height: 48,
    borderColor: COLOR.GRAY_BORDER,
  },
  selectLabel: {
    paddingTop: 2,
  },
  buttonGrid: {
    width: '100%',
    marginTop: 200,
  },
  emptyGrid: {
    maxWidth: 480,
    height: 10,
  },
  linksContainer: {
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  linksBetween: {
    marginRight: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  saveButton: {
    marginTop: 20,
    backgroundColor: COLOR.RED_ANGUS,
    borderColor: COLOR.RED_ANGUS,
    '&:disabled': {
      backgroundColor: `rgba(230,16,16,0.12)`,
    },
    '&:hover': {
      backgroundColor: COLOR.RED_ANGUS,
      borderColor: COLOR.RED_ANGUS,
      boxShadow: 'none',
    },
  },
}));

type AssetDialogMessageProps = {
  open: boolean;
  isErr: boolean;
  fileName: string;
  handleClose: () => void;
  validationResult: {
    type: string | null;
    validationError: Array<JSONSchemaValidationError>;
  };
  rightButtonClick: () => void;
  rightButtonClickIsErr: () => void;
  csvData: Array<Array<string>>;
};

export const AssetDialogMessage: React.FC<AssetDialogMessageProps> = (
  props,
) => {
  const classes = assetStyles();
  const validationErrorSlice =
    props.validationResult.validationError.length > 30
      ? props.validationResult.validationError.slice(0, 30)
      : props.validationResult.validationError;
  const word: {[key: string]: string} = WORD;
  return (
    <DialogMessage
      open={props.open}
      title={
        props.isErr
          ? `Failed to Import ${props.fileName}`
          : `Confirm Upload ${props.fileName}`
      }
      handleClose={props.handleClose}
      leftButtonText={'Cancel'}
      leftButtonClick={props.handleClose}
      rightButtonText={props.isErr ? 'Upload CSV Again' : 'Confirm and Save'}
      rightButtonClick={
        props.isErr ? props.rightButtonClickIsErr : props.rightButtonClick
      }>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" className={classes.subTitle}>
            {'Total Record Rows in CSV'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="body2">{props.csvData.length}</Typography>
        </Grid>
      </Grid>
      {props.isErr && (
        <Grid className={classes.subTitle} container>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" className={classes.subTitle}>
              {'CSV Validation Results:'}
            </Typography>
            {validationErrorSlice.length !== 0 && (
              <Typography variant="h6">
                {'Total Error: '}{' '}
                {props.validationResult.validationError.length}
              </Typography>
            )}
            {props.validationResult.validationError.length > 30 && (
              <Typography variant="h6">
                {'Only show the first 30 error'}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" className={classes.subTitle}>
              {props.validationResult.type
                ? word[props.validationResult.type]
                : ''}
            </Typography>
          </Grid>

          {validationErrorSlice.length !== 0 &&
            ResultTable(
              {
                type: props.validationResult.type,
                validationError: validationErrorSlice,
              },
              props.csvData,
            )}
        </Grid>
      )}
    </DialogMessage>
  );
};

export function cleanSelectedFile(elementId: string) {
  (document.getElementById(elementId) as HTMLInputElement).value = '';
}
