import React, { useState, useRef, useEffect } from "react";
import Tree from "react-d3-tree";

import { LayerNode, NodeShapeConfig } from '../../store/site/types';

const containerStyles = {
  width: "100%",
  height: 800,
  border: '1px #DDD solid'
};

const CenteredTree: React.FC<{ tree: LayerNode }> = ({ tree }) => {
  const treeContainer = useRef<HTMLDivElement>(null);
  const [translate, setTranslate] = useState({
    x: 0,
    y: 0
  });

  useEffect(() => {
    const dimensions = treeContainer.current?.getBoundingClientRect();
    setTranslate({
      x: dimensions?.width / 2 || 0,
      y: 60,
    })
  }, []);

  return (
    <div style={containerStyles} ref={treeContainer}>
      <Tree
        data={adjustedTree(tree)}
        translate={translate}
        orientation={"vertical"}
        pathFunc="step"
        styles={{
          links: {
            strokeWidth: 0.5,
          },
          nodes: {
            node: {
              rect: {
                fill: '#D9D9D9',
                stroke: '#D9D9D9',
              },
              circle: {
                fill: '#D9D9D9',
                stroke: '#D9D9D9',
              },
              name: {
                fontFamily: "Open Sans",
                fontWeight: 100
              }
            },
            leafNode: {
              circle: {
                fill: '#D9D9D9',
                stroke: '#D9D9D9',
              },
              name: {
                fontFamily: "Open Sans",
                fontWeight: 100
              }
            },
          },
        }}
        nodeSvgShape={NodeShapeConfig}
        separation={{
          siblings: 0.75,
          nonSiblings: 0.5,
        }}
        textLayout={{
          textAnchor: 'middle', 
          x: 0, 
          y: 0, 
        }}
        transitionDuration={0}
      />
    </div>
  );
}

function adjustedTree(tree: LayerNode) {
  return {
    ...tree,
    nodeSvgShape: {  
      shape: 'rect',
      shapeProps: {
        width: 400,
        height: 50,
        x: -200,
        y: 0
      },
    },
    textLayout: {
      textAnchor: 'middle', 
      x: 0, 
      y: 25, 
    }
  };
}

export default CenteredTree;
