import styled from 'styled-components';
import agliveLogo from '../img/agliveLogo.png';

const Container = styled.div`
  background-color: white;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Logo = styled.img`
  width: 118px;
  height: 38.34px;
  align-self: center;
  margin-left: 80px;
`;

const LinkContainer = styled.div`
  align-self: center;
  margin-right: 80px;
`;

const CustomLink = styled.a`
  text-decoration: none;
  border-right: 1px solid #373935;
  padding-right: 10px;
  padding-left: 10px;
  :last-child {
    border-right: none;
  }
  font-size: 16px;
  color: #373935;
`;

export default function Header() {
  return (
    <Container>
      <Logo src={agliveLogo} />
      <LinkContainer id="link">
        <CustomLink
          href="https://aglive.com/wp-content/uploads/2020/05/Aglive-Privacy-Policy.pdf"
          target="_blank">
          {/* <FormattedMessage
                id="PRIVACY_POLICY"
                defaultMassage="Privacy Policy"
              /> */}
          Privacy Policy
        </CustomLink>
        <CustomLink
          href="https://aglive.com/wp-content/uploads/2021/01/Aglive-User-License-Agreement.pdf"
          target="_blank">
          {/* <FormattedMessage
                id="TERMS_AND_CONDITION"
                defaultMassage="Terms & Conditions"
              /> */}
          Terms & Conditions
        </CustomLink>
        <CustomLink href="https://aglive.com/contact-us/" target="_blank">
          {/* <FormattedMessage id="CONTACT_US" defaultMassage="Contact us" /> */}
          Contact us
        </CustomLink>
      </LinkContainer>
    </Container>
  );
}
