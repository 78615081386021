import React, {useState, forwardRef} from 'react';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {useParams, Redirect, useHistory} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MyButton from '../../presentation/button';
import {PageHeader} from '../../presentation/withHeader';
import COLOR from '../../styled/colors';
import {submitProject, inviteUserAgain} from '../../store/add/actions';
import {Grid, makeStyles, Typography} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {ManageUserType} from '../../store/add/types';
import {useLocation} from 'react-router-dom';
import CONSTANT from '../../config/constant';
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import {useEffect} from 'react';

const useStyle = makeStyles((theme) => ({
  formControl: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 56,
    marginRight: 8,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    color: COLOR.GREEN_BUTTON,
    borderColor: COLOR.GREEN_BUTTON,
    marginRight: 50,
  },
}));

const GridInput: React.FC<{label: string}> = (props) => (
  <Grid item xs={12} sm={6}>
    <Typography variant="h6" role="label" style={{fontWeight: 600}}>
      {props.label}
    </Typography>
    {props.children}
  </Grid>
);

const AddUser: React.FC<{}> = () => {
  const classes = useStyle();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {id} = useParams<{id: string}>();
  const userList: Array<ManageUserType> = useAppSelector(
    (state) => state.add.users,
  );
  const userid = useAppSelector((state) => state.auth.wallet);
  const userProfile = useAppSelector((state) => state.user.userProfileData);
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const addUserLabels =
    businessProfile.businessCountry === 'Argentina'
      ? CONSTANT.ES_LABELS
      : CONSTANT.EN_LABELS;
  const location = useLocation<string>();
  const originStatus = location.state ? Object.values(location.state)[0] : '';
  const targetProjectIndex =
    originStatus === 'Pending'
      ? userList.findIndex((project) => project.invitationCode === id)
      : userList.findIndex((project) => project.userId === id);
  const user = userList[targetProjectIndex];
  const originRole = user?.role;
  const roleList = [
    {label: addUserLabels.admin, value: 'admin'},
    {label: addUserLabels.user, value: 'user'},
  ];
  // Read-only feature is currently not available to Angus account
  if (userProfile.businessId === CONSTANT.WARAKIRRI_BUSINESS_ID) {
    roleList.push({label: addUserLabels.readOnly, value: 'read-only'});
  }
  const statusList = [addUserLabels.active, addUserLabels.inactive];
  const enStatusList = ['Active', 'Inactive'];
  const initialData = {
    userId: user?.userId ? user?.userId : user?.invitationCode,
    name: user?.name,
    role: user?.role,
    phone: user?.phone,
    email: user?.email,
    status: originStatus as string,
    statusChange: '',
    roleChange: '',
  };

  const initialStatusIndex = enStatusList.findIndex(
    (status) => initialData.status === status,
  );
  const [userInfo, setUserInfo] = useState(initialData);
  const [validEmail, setValidEmail] = useState(false);
  const [userStatus, setUserStatus] = useState(
    initialStatusIndex === -1 ? '' : initialStatusIndex,
  );

  const [mobileValue, setMobileValue] = useState(
    /^\+/.test(userInfo.phone) ? userInfo.phone : '+61' + userInfo.phone,
  );

  const handleChange = (value: string, key: string) => {
    if (key === 'role') {
      setUserInfo((prevState) => ({
        ...prevState,
        [key]: value,
      }));
      if (value === originRole) {
        setUserInfo((prevState) => ({
          ...prevState,
          roleChange: '',
        }));
      } else {
        setUserInfo((prevState) => ({
          ...prevState,
          roleChange: 'Change',
        }));
      }
    } else if (key === 'status') {
      setUserStatus(value);
      setUserInfo((prevState) => ({
        ...prevState,
        [key]: enStatusList[value],
      }));
      if (enStatusList[value] !== originStatus) {
        if (enStatusList[value] === 'Inactive') {
          setUserInfo((prevState) => ({
            ...prevState,
            statusChange: 'Inactive',
          }));
        } else {
          setUserInfo((prevState) => ({
            ...prevState,
            statusChange: 'Active',
          }));
        }
      } else {
        setUserInfo((prevState) => ({
          ...prevState,
          statusChange: '',
        }));
      }
    } else {
      setUserInfo((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const emailValidation = (value: string) => {
    const pattern = /\S+@\S+\.\S+/;
    if (!pattern.test(value)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };

  useEffect(() => {
    handleChange(mobileValue, 'phone');
  }, [mobileValue]);

  // Redirect user back to UserLibrary if product could not be found
  if (!user) return <Redirect to="/private/add/users" />;

  const AddUserContent = (
    <>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <GridInput label={addUserLabels.name}>
            <TextField
              fullWidth
              variant="outlined"
              value={userInfo.name}
              onChange={(e) => handleChange(e.target.value, 'name')}
              disabled={false}
            />
          </GridInput>

          <GridInput label={addUserLabels.role}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}>
              {userInfo.role === '' && (
                <InputLabel id="add-user-select-role" shrink={false}>
                  {`---${addUserLabels.role}---`}
                </InputLabel>
              )}
              <Select
                labelId="add-user-select-role"
                value={userInfo.role}
                onChange={(e) => handleChange(e.target.value as string, 'role')}
                label="role">
                {roleList.map((role) => {
                  return <MenuItem value={role.value}>{role.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </GridInput>

          <GridInput label={addUserLabels.phone}>
            <PhoneInput
              international
              defaultCountry="AU"
              placeholder={addUserLabels.enterPhoneNumber}
              countryCallingCodeEditable={false}
              inputComponent={MobileInput}
              value={mobileValue}
              countries={['AU', 'CA', 'AR']}
              onChange={setMobileValue}
              helperText={addUserLabels.mobileWarning}
            />
          </GridInput>

          <GridInput label={addUserLabels.emailAddress}>
            <TextField
              fullWidth
              variant="outlined"
              value={userInfo.email}
              onChange={(e) => handleChange(e.target.value, 'email')}
              disabled={false}
              onBlur={(e) => emailValidation(e.target.value)}
              error={validEmail}
              helperText={validEmail ? addUserLabels.inputValidEmail : ''}
            />
          </GridInput>

          {userInfo.status !== '' && (
            <GridInput label={addUserLabels.status}>
              {originStatus === 'Pending' ? (
                <Typography variant="h6" role="label">
                  {addUserLabels.pendingAcceptance}
                </Typography>
              ) : (
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}>
                  {userStatus === '' && (
                    <InputLabel id="add-user-select-status" shrink={false}>
                      {`---${addUserLabels.status}---`}
                    </InputLabel>
                  )}
                  <Select
                    labelId="add-user-select-status"
                    value={userStatus}
                    onChange={(e) =>
                      handleChange(e.target.value as string, 'status')
                    }
                    label="role">
                    {statusList.map((status, index) => {
                      return <MenuItem value={index}>{status}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              )}
            </GridInput>
          )}
        </Grid>
      </div>
      <Grid
        item
        container
        direction="row"
        justify="flex-end"
        style={{position: 'absolute', bottom: 0, right: 50}}>
        {originStatus === 'Pending' && (
          <MyButton
            text={addUserLabels.inviteAgain}
            variant="outlined"
            buttonClass={classes.button}
            onClick={() =>
              dispatch(
                inviteUserAgain(
                  userInfo.userId,
                  userid,
                  businessProfile.businessCountry,
                  () => {
                    history.push('/private/add/users');
                  },
                ),
              )
            }
          />
        )}
        <MyButton
          text={
            userInfo.status === '' ? addUserLabels.invite : addUserLabels.save
          }
          variant="contained"
          disabled={validEmail || !isPossiblePhoneNumber(userInfo.phone ?? '')}
          onClick={() => {
            if (originStatus === 'Pending') {
              dispatch(
                submitProject(
                  userInfo,
                  userid,
                  businessProfile.businessCountry,
                  () => {
                    history.push('/private/add/users');
                  },
                  true,
                ),
              );
            } else {
              dispatch(
                submitProject(
                  userInfo,
                  userid,
                  businessProfile.businessCountry,
                  () => {
                    history.push('/private/add/users');
                  },
                ),
              );
            }
          }}
        />
      </Grid>
    </>
  );
  return (
    <PageHeader
      config={{
        title:
          userInfo.status === ''
            ? addUserLabels.addUser
            : addUserLabels.editUser,
        margin: 60,
        back: true,
      }}>
      {AddUserContent}
    </PageHeader>
  );
};

export default AddUser;

/** mobile phone material ui input component */
const PhoneInputCustom = (props, ref) => {
  const phoneValidation = (value) => {
    if (!isPossiblePhoneNumber(value)) {
      setValidPhone(true);
    } else {
      setValidPhone(false);
    }
  };
  const [validPhone, setValidPhone] = useState(false);
  const error = props.error ?? validPhone;
  return (
    <TextField
      {...props}
      inputRef={ref}
      fullWidth
      variant="outlined"
      onBlur={(e) => phoneValidation(e.target.value)}
      error={error}
      helperText={error ? props.helperText : ''}
    />
  );
};
export const MobileInput = forwardRef(PhoneInputCustom);
