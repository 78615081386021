import React from 'react';

interface iTabPanel {
  isShown: boolean;
  index: number;
  children: React.ReactNode;
}

const TabPanel: React.FC<iTabPanel> = ({isShown, index, children}) => (
  <div
    role="tabpanel"
    hidden={!isShown}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    style={{marginBottom: 30}}
  >
    {isShown && children}
  </div>
);

export default TabPanel;
