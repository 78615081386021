import {makeStyles} from '@material-ui/core';
import COLOR from '../../../styled/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1E7B3E',
  },
  formContainer: {
    marginBottom: 40,
  },
  buttonGrid: {
    width: '100%',
    marginTop: 170,
  },
  button: {
    marginRight: 20,
  },
  picDropdown: {
    marginTop: 25,
    paddingRight: '20px',
  },
  cell: {
    width: '100%',
  },
  tableRoot: {
    '& [class^="TableContainer-root-"]': {
      minHeight: 450,
    },
  },
  rowDetailsContainer: {
    width: '100%',
  },
  tokenDetailsContainer: {
    borderRight: `1px solid ${COLOR.GRAY_BORDER}`,
    overflow: 'auto',
  },
  activityDetailsContainer: {
    overflow: 'auto',
  },
  noActivityText: {
    marginTop: theme.spacing(2),
  },
}));
