import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

import {Grid} from '@material-ui/core';

import {MainHeading, WidgetContainer} from './styles';
import {CustomizedLegend, LegendPayloadType} from './DashboardChart';

export type DashboardChartBarProps = {
  data: Array<any>;
  xLabel: string;
  yLabel: string;
  xAxis: Array<{key: string; label?: string; style?: any}>;
  legend: LegendPayloadType;
  bars: Array<
    Array<{
      key: string;
      fill: string;
    }>
  >;
};

const renderCustomizedLabel = (props) => {
  const {x, y, width, height, value} = props;

  return value ? (
    <g>
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle">
        {value}
      </text>
    </g>
  ) : (
    <></>
  );
};

const renderBarSum = (props) => {
  const {x, y, width, value} = props;

  return value ? (
    <g>
      <text
        x={x + width / 2}
        y={y - 8}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle">
        {value}
      </text>
    </g>
  ) : (
    <></>
  );
};

const DashboardChartBar: React.FC<DashboardChartBarProps> = ({
  data,
  xLabel,
  yLabel,
  xAxis,
  legend,
  bars,
}) => {
  return (
    <>
      <div style={{marginLeft: 20}}>
        <MainHeading>{yLabel}</MainHeading>
      </div>
      <div style={{marginBottom: 30}}>
        <ResponsiveContainer width="95%" height={500}>
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 20,
              left: 20,
              bottom: 5,
            }}
            barGap={10}
            barSize={60}>
            {xAxis.map((axis, index) => (
              <XAxis
                key={axis.key}
                dataKey={axis.key}
                xAxisId={index}
                axisLine={!index}
                tickLine={false}
                tickFormatter={(tick) => axis?.label || tick}
                tick={axis?.style}
              />
            ))}
            <YAxis />
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              iconType="circle"
              wrapperStyle={{
                paddingLeft: 150,
              }}
              payload={legend.payload}
              content={<CustomizedLegend />}
            />
            {bars
              .map((bar, index) =>
                bar.map((stack) => (
                  <Bar
                    key={`${stack.key}-${index}`}
                    dataKey={stack.key}
                    isAnimationActive={false}
                    stackId={index}
                    fill={stack.fill}
                    label={renderBarSum}>
                    <LabelList
                      dataKey={stack.key}
                      content={renderCustomizedLabel}
                    />
                    {/* <LabelList dataKey={stack.key} content={renderBarSum} /> */}
                  </Bar>
                )),
              )
              .flat()}
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          marginBottom: 30,
        }}>
        <MainHeading>{xLabel}</MainHeading>
      </div>
    </>
  );
};

type DashboardChartBarWidgetProps = {
  title: string;
} & DashboardChartBarProps;

const DashboardChartBarWidget: React.FC<DashboardChartBarWidgetProps> = ({
  title,
  ...props
}) => {
  return (
    <WidgetContainer item container direction="column" style={{padding: 30}}>
      <Grid item container justifyContent="center" style={{marginBottom: 30}}>
        <MainHeading>{title}</MainHeading>
      </Grid>

      <Grid>
        <DashboardChartBar {...props} />
      </Grid>
    </WidgetContainer>
  );
};

export default DashboardChartBarWidget;
