import React from 'react';
import Qrcode from 'qrcode.react';
import { Grid, Typography } from '@material-ui/core';

import { Buttons } from '../../../../presentation/ButtonsGroup';

import { toggleModal, toggleModalOff } from '../../../../store/modal/actions';
import { CREATE_PRODUCT_PROMOTION_PATH, CREATE_SECURITY_CODE_PATH, stateHasPrevState } from './CreateProductProfile';
import { Message } from '../../../../presentation/Modal';

const CREATE_PRODUCT_PROFILE_LIBRARY_PATH = '/private/products/productProfile';
const CODES_LANDING_PATH = '/private/codes';

const toggleQRCodeModal = (
  dispatch,
  history,
  config: {
    status: Message["status"],
    title: string,
    QRCodeContent: string,
    hideGenerate?: boolean,
  }
) => {
  const modalPayload: Message = {
    status: config.status,
    title: config.title,
    width: 600,
    customContent: (
      <Grid container direction="column" alignItems="center">
        <Grid item style={{margin: 35}}>
          <Qrcode
            value={config.QRCodeContent}
            size={248}
          />
        </Grid>
        <Grid item style={{marginBottom: 20}}>
          <Typography variant="h6" style={{fontWeight: 600}}>Sample Product Preview</Typography>
        </Grid>
        <Grid item container justify="center" style={{marginBottom: 20}}>
          <Typography variant="h6"><strong>Basic Info: </strong> Scan this QR Code with phone camera</Typography>
          <Typography variant="h6"><strong>Detailed Info: </strong>Scan this QR Code with the Aglive App</Typography>
        </Grid>
      </Grid>
    ),
  }

  if (config.hideGenerate) {
    modalPayload.button = 'Close';
  } else {
    modalPayload.renderButton = (
      <Buttons
        leftButtonTitle="Generate Codes Later"
        rightButtonTitle="Generate Codes Now"
        leftButtonOnClick={() => {
          dispatch(toggleModalOff());
          history.push(CREATE_PRODUCT_PROFILE_LIBRARY_PATH);
        }}
        rightButtonOnClick={() => {
          dispatch(toggleModalOff());
          if (history?.location?.state) {
            const {state} = history.location;
            if (
              stateHasPrevState(state) &&
              (state.prevLocation === CREATE_PRODUCT_PROMOTION_PATH ||
                state.prevLocation === CREATE_SECURITY_CODE_PATH)
            ) {
              history.goBack();
              return;
            }
          }
          history.push(CODES_LANDING_PATH);
        }}
      />
    )
    modalPayload.CTAHandler = () => history.push(CREATE_PRODUCT_PROFILE_LIBRARY_PATH);
  }

  dispatch(toggleModal(modalPayload));
}

export default toggleQRCodeModal;
