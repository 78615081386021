import React from 'react';
import {Checkbox, FormControl, makeStyles, Typography} from '@material-ui/core';
import '../container/reports/styles.css';
import {Picky} from 'react-picky';
import 'react-picky/dist/picky.css';
import './MultipleSelect.css';
import COLOR from '../styled/colors';

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 56,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    paddingTop: theme.spacing(0.3),
    paddingBottom: theme.spacing(0.3),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
  },
}));

export type MultipleSelectOptionsType = string | {label: string; value: string};

export const MultipleSelect: React.FC<{
  dataSet: Array<MultipleSelectOptionsType>;
  selectedData: Array<MultipleSelectOptionsType>;
  setSelectedData: (arg0: any) => void;
  value?: Array<MultipleSelectOptionsType>;
  variant?: 'outlined' | 'standard' | 'filled';
  placeholder?: string;
  dropdownHeight?: number;
  numberDisplayed?: number;
  id?: string;
}> = ({
  dataSet,
  selectedData,
  setSelectedData,
  variant,
  value,
  placeholder,
  dropdownHeight,
  numberDisplayed = 3,
  id
}) => {
  const classes = useStyles();
  const options = dataSet?.map((data) => ({
    label: typeof data === 'string' ? data : data.label,
    value: typeof data === 'string' ? data : data.value,
  }));

  const handleChange = (selectedData: Array<MultipleSelectOptionsType>) => {
    setSelectedData(selectedData);
  };
  return (
    <FormControl
      variant={variant ?? 'outlined'}
      className={`${classes.formControl} custom-picky`}>
      <Picky
        id={id ?? "picky"}
        labelKey="label"
        valueKey="value"
        options={options}
        value={value ?? selectedData}
        includeSelectAll={true}
        includeFilter={true}
        multiple={true}
        onChange={handleChange}
        dropdownHeight={dropdownHeight || 600}
        buttonProps={{
          style: {
            height: 58,
            border: `1px solid ${COLOR.GRAY_BORDER}`,
            fontFamily: 'Open Sans',
            fontSize: '16px',
            borderRadius: '5px',
            padding: '1rem 0.75rem',
          },
        }}
        placeholder={placeholder ?? 'None selected'}
        numberDisplayed={numberDisplayed}
        renderSelectAll={({
          filtered,
          tabIndex,
          allSelected,
          toggleSelectAll,
          multiple,
        }) => {
          if (multiple && !filtered) {
            return (
              <div
                tabIndex={tabIndex}
                role="option"
                aria-selected={allSelected === 'all' ? 'true' : 'false'}
                className={`${
                  allSelected === 'all' ? 'option selected' : 'option'
                } ${classes.listItem}`}
                onClick={toggleSelectAll}
                onKeyDown={toggleSelectAll}>
                <Checkbox
                  className={classes.checkbox}
                  color="primary"
                  checked={allSelected === 'all'}
                />
                <Typography variant="inherit" role="label">
                  Select All
                </Typography>
              </div>
            );
          }
        }}
        render={({
          isSelected,
          item,
          selectValue,
          labelKey,
          valueKey,
          multiple,
        }) => {
          return (
            <li
              className={`${isSelected ? 'selected' : ''} ${classes.listItem}`}
              key={item[valueKey]}
              onClick={() => selectValue(item)}>
              <Checkbox
                className={classes.checkbox}
                color="primary"
                checked={isSelected}
              />
              <Typography variant="inherit" role="label">
                {item[labelKey]}
              </Typography>
            </li>
          );
        }}
      />
    </FormControl>
  );
};
