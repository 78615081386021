import React, {useEffect, useReducer} from 'react';
import produce from 'immer';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import {Grid, Typography} from '@material-ui/core';

import MyButton from '../../../presentation/button';
// import MockupPhoneBrand from "../../presentation/MockupPhoneBrand";
import SmallMockupPhoneBrand from '../../../presentation/SmallMockupPhoneBrand';
import withHeader from '../../../presentation/withHeader';

import {
  baseBrandReducer,
  INITIAL_BRAND_STATE,
  pageArray,
} from './brandProfileState/reducer';
import localActions, {
  baseBrandActionTypes,
  loadBrandActionTypes,
  loadBrandToken,
} from './brandProfileState/actions';
import {fetchBrandToken} from '../utils';

import {useStyle} from './BaseBrand';
import {WebErrorType} from '../../../utils/error';
import {toggleModal} from '../../../store/modal/actions';
import {
  MockUpPhoneBrandAboutUs,
  MockUpPhoneBrandLaunchScreen,
  MockUpPhoneBrandSplashScreen,
} from '../../../presentation/MockupPhoneBrand';
import {CASL} from '@aglive/frontend-core';

const viewBrandReducer = (
  state: typeof INITIAL_BRAND_STATE,
  action: baseBrandActionTypes | loadBrandActionTypes,
) => {
  switch (action.type) {
    case 'load/load_brand':
      return produce(state, (draft) => {
        draft.payload = action.payload.details;
      });
    default:
      return baseBrandReducer(state, action);
  }
};

const ViewBrand: React.FC<{}> = () => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();

  const {id} = useParams<{id: string}>(); // IDs start from 1
  const EDIT_PRODUCT_PATH = `/private/profile/brand/edit/${id}`;

  useEffect(() => {
    fetchBrandToken(dispatch, id)
      .then((targetBrand) => localDispatch(loadBrandToken(targetBrand)))
      .catch((e) => {
        const error = e as WebErrorType;

        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          }),
        );
      });
  }, []);

  const [viewBrandState, localDispatch] = useReducer(
    viewBrandReducer,
    INITIAL_BRAND_STATE,
  );
  const isArchived = viewBrandState.payload.archive;

  return (
    <Grid container direction="column">
      <Grid item xs={6}>
        <Typography variant="h6" role="label" style={{fontWeight: 600}}>
          Brand
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          value={viewBrandState.payload.name}
          onChange={() => {}}
          disabled={true}
        />
      </Grid>

      <Grid item container style={{marginTop: 32}} spacing={3}>
        <Grid item xs={2} container direction="column">
          <Typography variant="h6" role="label" style={{fontWeight: 600}}>
            Select page to preview
          </Typography>
          <Grid
            item
            container
            direction="column"
            className={classes.pagesPreviewContainer}>
            <Grid item>
              {pageArray.map((pageName) => (
                <SmallMockupPhoneBrand
                  onClick={() =>
                    localDispatch(localActions.navigatePage(pageName))
                  }
                  pageName={pageName}
                  isFocused={viewBrandState.state.page === pageName}
                  brand={viewBrandState.payload}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4} style={{marginTop: 50, paddingLeft: 30}}>
          {viewBrandState.state.page === 'Launch Screen' && (
            <MockUpPhoneBrandLaunchScreen
              page={viewBrandState.payload.launchScreen}
            />
          )}
          {viewBrandState.state.page === 'Splash Page' && (
            <MockUpPhoneBrandSplashScreen
              page={viewBrandState.payload.launchScreen}
            />
          )}
          {viewBrandState.state.page === 'About Us' && (
            <MockUpPhoneBrandAboutUs page={viewBrandState.payload.aboutUs} />
          )}
        </Grid>
      </Grid>

      <CASL.Can I="update" a="brand">
        {!isArchived && (
          <Grid item container justify="flex-end">
            <MyButton
              text="Edit"
              variant="contained"
              onClick={() => history.push(EDIT_PRODUCT_PATH)}
            />
          </Grid>
        )}
      </CASL.Can>
    </Grid>
  );
};

export default withHeader(
  {
    title: 'View Brand',
    margin: 60,
    back: true,
  },
  ViewBrand,
);
