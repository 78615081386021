import React from 'react';
import {LegendType} from 'recharts';
import {Grid, Typography} from '@material-ui/core';

export const CustomizedLegend = (props) => {
  const {payload} = props;
  return (
    <>
      {props.title && (
        <Typography
          variant="h6"
          role="label"
          style={{fontWeight: 600, marginBottom: 15}}>
          {props.title}
        </Typography>
      )}
      {payload.map((entry, index) => (
        <Grid item container key={index}>
          <div
            style={{
              backgroundColor: entry.color,
              borderRadius: '50%',
              width: 22,
              height: 22,
              marginRight: 12,
              marginTop: 3,
            }}
          />
          <Typography
            variant="h6"
            key={`item-${index}`}
            style={{marginBottom: 8}}>
            {entry.value}{props.showValue?.length && props.showValue[index]}
          </Typography>
        </Grid>
      ))}
    </>
  );
};

export type LegendPayloadType = {
  title?: string;
  payload?: Array<{
    value: string;
    type: LegendType;
    id: string;
    color: string;
  }>;
};
