import Paper from '@material-ui/core/Paper';
import React, {CSSProperties, useState} from 'react';
import {
  Grid as GridTable,
  DragDropProvider,
  Table,
  TableHeaderRow,
  TableColumnReordering,
  TableColumnVisibility,
  ColumnChooser,
  Toolbar,
  PagingPanel,
  TableFilterRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import {
  PagingState,
  CustomPaging,
  FilteringState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
  EditingState,
  Column,
} from '@devexpress/dx-react-grid';
import {Getter} from '@devexpress/dx-react-core';
import withHeader from '../../presentation/withHeader';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  GridSize,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TableCell,
  TextField,
  Typography,
} from '@material-ui/core';
import MyButton from '../../presentation/button';
import COLOR from '../../styled/colors';
import {MultipleSelect} from '../../presentation/MultipleSelect';
import * as PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import {styled, withStyles} from '@material-ui/core/styles';
import {useReport} from './ReportBusinessObject';
import {SubPageType} from './ReportsFields';
import {getSubpages, ReportColumn} from './ReportsFields';
import DatePicker from '../../presentation/DatePicker';
import {connectProps} from '@devexpress/dx-react-core';
import {OuterFilterFields} from './OuterFilterFields';
import {useParams} from 'react-router-dom';
import DialogMessage from '../../presentation/DialogMessage';
import GlobalStyles from '@mui/material/GlobalStyles';
import {useAppSelector} from '../../utils/hooks';
import MyTable from '../../presentation/Table';
import {HyperLink} from '../../presentation/word';
import Link from '@mui/material/Link/Link';
import CONSTANT from '../../config/constant';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1E7B3E',
  },
  formContainer: {
    marginBottom: 40,
  },
  buttonGrid: {
    width: '100%',
    marginTop: 170,
  },
  button: {
    marginRight: 20,
  },
  picDropdown: {
    marginTop: 25,
    paddingRight: '20px',
  },
  cell: {
    width: '100%',
  },
  tableRoot: {
    '& > div > div:nth-child(2)': {
      minHeight: 450,
    },
  },
  dividerStyle: {
    width: '100%',
    marginTop: 10,
  },
  hidden: {
    display: 'none',
  },
}));

const TABLE_DATA_EMPTY_STYLE: CSSProperties = {
  width: '100%',
  paddingTop: 20,
  paddingBottom: 20,
  textAlign: 'center',
};

const StyledTable = styled(Table.Table)(({theme}) => ({
  [`&.data-table`]: {
    '& thead tr:first-child th': {
      fontWeight: 'bold',
    },
  },
}));

const TableComponent = (props) => (
  <StyledTable {...props} className={'data-table'} />
);

const HeaderComponentBase = ({classes, ...props}) => (
  <Table.TableHead {...props} style={{backgroundColor: COLOR.GRAY_SOLID}} />
);

// TableEditColumn.HeaderCellProps
const EditColumnHeaderComponent: React.FC<TableEditColumn.HeaderCellProps> = ({
  ...props
}) => {
  return (
    <TableEditColumn.HeaderCell {...props}>
      {'Details'}
    </TableEditColumn.HeaderCell>
  );
};

const CustomFilterCellBase = ({column, classes, report, subPages}) => {
  const columnTitle = report.initialColumns['management'][
    report.state.subPage ?? subPages[0]
  ].find((item) => item.name === column.name)?.title;
  switch (column.type) {
    case 'text':
    case 'number':
      return (
        <TableCell
          key={`cell-${column.name}-${
            report.state.fieldConditions[column.name]?.filterValue ?? ''
          }`}
          className={classes.cell}>
          <TextField
            id={column.name}
            key={column.name}
            type={column.type}
            defaultValue={
              report.state.fieldConditions[column.name]?.filterValue ?? null
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              report.handleTextfieldChange(e, column)
            }
            placeholder={`Enter ${columnTitle}`}
            inputProps={{
              style: {textAlign: 'left', height: 'inherit', fontSize: '15px'},
            }}
          />
        </TableCell>
      );
    case 'multiSelect':
      return (
        <TableCell className={classes.cell}>
          <MultipleSelect
            id={column.name}
            dataSet={report.state.multiSelectOptions[column.name]}
            value={report.state.selectedFilters[column.name] ?? []}
            selectedData={report.state.selectedFilters[column.name] ?? []}
            setSelectedData={(selectedData) =>
              report.handleMultiSelectChange(
                column.name,
                Array.isArray(selectedData)
                  ? selectedData
                  : [{label: selectedData, value: selectedData}],
              )
            }
            variant="outlined"
            placeholder="Select"
            dropdownHeight={300}
            numberDisplayed={column.numberDisplayed ?? 1}
          />
        </TableCell>
      );
    case 'dateRange':
      return (
        <TableCell className={classes.cell}>
          <DatePicker
            label=""
            id={column.name}
            range={true}
            dateValue={
              report.state.fieldConditions[column.name]?.filterValue ?? null
            }
            handleChange={(date) =>
              report.handleDateRangeChange(column.name, date)
            }
            fontSize="16px"
            withBorder={false}
            format={'dd-MM-yyyy'}
            placeholder={'dd-mm-yyyy'}
            textAlign={'left'}
            emptyLabel={''}
            errorStatus={report.state.errorState[column.name]?.status}
            errorMessage={report.state.errorState[column.name]?.message}
          />
        </TableCell>
      );
  }
};
const CustomFilterCellStyles = (theme: {spacing: (arg0: number) => any}) => ({
  itemText: {
    paddingLeft: theme.spacing(1),
  },
});
const CustomFilterCell = withStyles(CustomFilterCellStyles, {
  name: 'FilterCell',
})(CustomFilterCellBase);
const OriginalFilterCell = (props) => {
  const {column, report, subPages} = props;
  const cols =
    report.initialColumns['management'][report.state.subPage ?? subPages[0]];
  // get array of columns need custom filter
  const customFilterColumns = cols.map((col) => {
    if (col.customFilter) return col.name;
  });
  if (customFilterColumns.includes(column.name)) {
    return <CustomFilterCell {...props} />;
  }
  return <TableFilterRow.Cell {...props} />;
};

const ManagementReport = () => {
  const classes = useStyles();
  const {type} = useParams<{type: string}>();
  const businessReports = useAppSelector((state) => state.user.reports);
  let businessReportGroup: {[key: string]: Array<string>} = {};
  businessReports?.forEach((br) => {
    if (br['group']) {
      if (!businessReportGroup[br['group']]) {
        businessReportGroup[br['group']] = [];
      }
      businessReportGroup[br['group']].push(br.type);
    }
  });
  const subPage = (
    type === 'Management-Report' ||
    businessReportGroup[type?.replace(/-/g, ' ')]
      ? ''
      : type?.replace(/-/g, ' ')
  ) as SubPageType;
  const report = useReport('management', subPage, businessReportGroup[type?.replace(/-/g, ' ')] ? type?.replace(/-/g, ' ') : undefined);
  const reportTypeSelected = !!report.state.report || report.state.report === 0;
  const businessData = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const FilterCell = connectProps(OriginalFilterCell, () => ({
    report: report,
    subPages: getSubpages(businessData.industryType === 'PLANTS'),
  }));
  const [openMore, setOpenMore] = useState(false);
  const [viewDetailIds, setViewDetailIds] = useState([]);
  const hideColumnList = report.state.hideColumn.map(
    (item) => item['columnName'],
  );

  const displayColumnChooser =
    report.state.columnVisibility.filter((col) => col.togglingEnabled === false)
      .length !== report.state.columns.length;

  const hideDetails = report.state.hiddenColumns.includes('details');

  const IconCellBase = ({
    style,
    expanded,
    classes,
    onToggle,
    tableColumn,
    tableRow,
    row,
    className,
    status,
    ...restProps
  }) => {
    const handleClick = () => {
      setViewDetailIds([tableRow]);
      setOpenMore(true);
    };
    return (
      <>
        <TableCell style={{textAlign: 'center'}} {...restProps}>
          <Button onClick={() => handleClick()}>
            <span
              style={{color: COLOR.GREENT_TEXT, textTransform: 'capitalize'}}>
              <u>{'View'}</u>
            </span>
          </Button>
        </TableCell>
      </>
    );
  };

  const IconCell = connectProps(IconCellBase, () => ({classes}));

  const IconEditCell = (props) => {
    return <IconCell {...props} />;
  };

  const parseURL = (url: string, options: {value?: string}) => {
    if (url === 'promotion' && options.value) {
      return CONSTANT.SCAN_URL(options.value);
    }

    return url;
  };

  const CellComponent = (props: Table.DataCellProps) => {
    const {column} = props;
    const columnConfig = report.state.columns.find(
      (c) => c.name === column.name,
    ) as Column & ReportColumn;
    if (columnConfig?.url) {
      const parsedUrl = parseURL(columnConfig.url, {value: props.value});
      return (
        <TableCell>
          <Link target="_blank" rel="noreferrer" href={parsedUrl}>
            {props.value}
          </Link>
        </TableCell>
      );
    }
    return <Table.Cell {...props} />;
  };
  return (
    <Grid container>
      <Grid container justifyContent="space-between">
        {!subPage && (
          <Grid
            container
            className={classes.formContainer}
            item
            xs={4}
            justifyContent="space-between">
            {/* <Grid item xs={6} className={classes.picDropdown}>
              <Typography variant="h6" role="label" style={{fontWeight: 600}}>
                {report.localizedPIC}
              </Typography>
              <Grid item>
                <MultipleSelect
                  dataSet={report.locationPicAddr}
                  selectedData={report.state.locations}
                  setSelectedData={report.updateLocation}
                />
              </Grid>
            </Grid> */}
            <Grid item xs={12}>
              <Grid item>
                <Typography
                  variant="h6"
                  role="label"
                  style={{fontWeight: 600, marginBottom: 10}}>
                  Report Type
                </Typography>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="report-type-select">
                    {'Please select'}
                  </InputLabel>
                  <Select
                    id={'ReportType'}
                    labelId="report-type-select"
                    label="Please select"
                    name="report"
                    defaultValue=""
                    onChange={report.handleSubPageChange}>
                    <MenuItem value={''}>{'Please select'}</MenuItem>
                    {report.formMap
                      .filter((item) => !item.hide && item.group != '')
                      .map(({label}, index) => (
                        <MenuItem value={index} key={index} id={`${index}`}>
                          {label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}
        {report.state.subPage &&
          report.state.subPage.toLowerCase().replace(/\s/g, '_') ===
            report.formMap[report.state.report]?.key.toLowerCase() && (
            <Grid
              container
              justifyContent="flex-start"
              className={classes.formContainer}
              spacing={4}>
              {report.formMap[report.state.report].outerFilterFields?.map(
                (field) => {
                  return (
                    <OuterFilterFields
                      state={report.state.outerFilter}
                      optionalState={
                        field.key === 'locations' ||
                        field.key === 'establishmentId'
                          ? report
                          : report.state
                      } // for multiselect dataSet use => report.state.sites
                      setState={
                        field.customUpdate
                          ? report.updateOuterInnerFilters
                          : report.updateOuterFilter
                      }
                      errorState={report.state.errorState}
                      field={field}
                      key={field.key}
                      customItem={
                        field.key === 'locations'
                          ? report.localizedPIC
                          : undefined
                      }
                    />
                  );
                },
              )}
            </Grid>
          )}
        <Grid container>
          <Grid item xs={4}>
            <MyButton
              text={'Generate'}
              variant="contained"
              id={'GenerateButton'}
              width={280}
              disabled={!reportTypeSelected || !report.allowGenerate}
              onClick={report.handleClickGenerate}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box mt={3} style={{maxWidth: '100%'}}>
        {report.state.tableGenerated && (
          <>
            <Paper
              style={{marginTop: 30}}
              data-cy="ReportTable"
              className={
                report.state.columns.some((item) => item['customFilter']) &&
                classes.tableRoot
              }>
              <GridTable
                rows={report.state.rows}
                columns={
                  report.state.columns.some((c) => c['viewDetails'])
                    ? [
                        ...report.state.columns.filter(
                          (c) => !c['viewDetails'],
                        ),
                        {
                          name: 'details',
                          title: 'Details',
                        },
                      ]
                    : report.state.columns
                }>
                {report.state.columnVisibility.some(
                  (item) => item['togglingEnabled'],
                ) &&
                report.state.columns.some((item) => item['customFilter']) ? (
                  <Table
                    columnExtensions={report.columnExtensions}
                    cellComponent={CellComponent}
                  />
                ) : (
                  <Table
                    headComponent={HeaderComponentBase}
                    columnExtensions={report.columnExtensions}
                    cellComponent={CellComponent}
                  />
                )}

                <Table
                  tableComponent={TableComponent}
                  headComponent={HeaderComponentBase}
                  columnExtensions={
                    report.state.columns.some((c) => c['viewDetails'])
                      ? [
                          ...report.columnExtensions,
                          {
                            columnName: 'details',
                            width: 0,
                          },
                        ]
                      : report.columnExtensions
                  }
                  cellComponent={CellComponent}
                />
                {report.state.columnVisibility.some(
                  (item) => item['togglingEnabled'],
                ) && <DragDropProvider />}
                <PagingState
                  currentPage={report.state.currentPage}
                  onCurrentPageChange={report.changePage}
                  pageSize={report.state.pageSize}
                  onPageSizeChange={report.changePageSize}
                />

                {!report.state.hidePagination && (
                  <CustomPaging totalCount={report.state.totalData} />
                )}

                <FilteringState
                  defaultFilters={[]}
                  columnExtensions={report.state.columnFilter}
                />
                <IntegratedFiltering />
                <SortingState
                  sorting={report.state.sorting}
                  onSortingChange={report.changeSorting}
                  columnExtensions={report.state.columnSort}
                />
                <IntegratedSorting />
                {/* <Table columnExtensions={report.columnExtensions}/> */}
                <TableColumnReordering
                  order={
                    report.state?.columnOrder ??
                    Object.keys(report.defaultRowFields['management'])
                  }
                  onOrderChange={report.changeColumnOrder}
                />
                <EditingState
                  editingRowIds={viewDetailIds}
                  onCommitChanges={() => {}}
                />
                {!hideDetails &&
                  report.state.columns.some((item) => item['viewDetails']) && (
                    <TableEditColumn
                      cellComponent={IconEditCell}
                      headerCellComponent={EditColumnHeaderComponent}
                    />
                  )}
                <Getter
                  name="tableColumns"
                  computed={({tableColumns}) => {
                    const result = [
                      ...tableColumns.filter(
                        (c) => c.type !== TableEditColumn.COLUMN_TYPE,
                      ),
                      {
                        key: 'editCommand',
                        type: TableEditColumn.COLUMN_TYPE,
                        width: 140,
                      },
                    ];
                    return result;
                  }}
                />

                <TableHeaderRow showSortingControls />

                {report.state.columns.some((item) => item['customFilter']) && (
                  <TableFilterRow cellComponent={FilterCell} />
                )}

                <TableColumnVisibility
                  hiddenColumnNames={
                    report.state.hiddenColumns.length
                      ? report.state.hiddenColumns
                      : hideColumnList
                  }
                  onHiddenColumnNamesChange={report.changeHiddenColumn}
                  columnExtensions={report.state.columnVisibility}
                />

                {displayColumnChooser && <Toolbar />}
                {displayColumnChooser && (
                  <ColumnChooser
                    //@ts-ignore
                    itemComponent={Item}
                  />
                )}

                {!report.state.hidePagination && (
                  <PagingPanel pageSizes={[5, 10, 15, 20]} />
                )}
              </GridTable>

              {report.state.columns.some((item) => item['viewDetails']) && (
                <DialogMessage
                  open={openMore}
                  title={'View Details'}
                  handleClose={() => setOpenMore(false)}
                  size="md"
                  marginBottom={50}
                  /*dialogStyle={{overflow: 'hidden'}}*/
                >
                  <>
                    <Grid
                      container
                      item
                      direction="row"
                      style={{marginTop: 20}}
                      justifyContent="space-between"
                      alignItems="flex-start">
                      {report.state.columns
                        .filter((user) => user['viewDetails'])
                        .map((colConfig: ReportColumn) => {
                          const itemValue =
                            viewDetailIds[0]?.row[colConfig['name']];
                          return (
                            <Grid
                              key={colConfig.name}
                              item
                              xs={
                                colConfig['columnSize']
                                  ? (colConfig['columnSize'] as GridSize)
                                  : 'auto'
                              }>
                              {colConfig['displayTable'] &&
                              colConfig['tableRowsRenderer'] ? (
                                <Grid item style={{marginBottom: 20}}>
                                  <Typography
                                    variant="h3"
                                    style={{marginBottom: 10}}>
                                    {colConfig['title']}
                                  </Typography>
                                  <MyTable
                                    dataEmptyStyle={TABLE_DATA_EMPTY_STYLE}
                                    heads={colConfig['tableColumns']}
                                    rows={
                                      viewDetailIds
                                        ? itemValue?.map((val) => {
                                            return colConfig[
                                              'tableColumns'
                                            ].map((colName) => {
                                              const renderer =
                                                colConfig['tableRowsRenderer'][
                                                  colName
                                                ];
                                              return renderer
                                                ? renderer(val)
                                                : JSON.stringify(val).replace(
                                                    '"',
                                                    '',
                                                  );
                                            });
                                          })
                                        : null
                                    }></MyTable>
                                </Grid>
                              ) : (
                                <Grid item style={{marginBottom: 20}}>
                                  <Typography
                                    variant="h6"
                                    role="label"
                                    key={colConfig['title']}
                                    style={{fontWeight: 700, marginBottom: 10}}>
                                    {colConfig['title']}
                                  </Typography>
                                  <Typography
                                    variant="h6"
                                    role="label"
                                    style={{marginBottom: 10}}>
                                    {viewDetailIds && !Array.isArray(itemValue)
                                      ? itemValue
                                      : null}
                                  </Typography>
                                  <Divider
                                    className={classes.dividerStyle}
                                    style={{opacity: 0.5}}
                                  />
                                </Grid>
                              )}
                            </Grid>
                          );
                        })}
                    </Grid>
                    <GlobalStyles
                      styles={{
                        h1: {color: 'grey'},
                        '*::-webkit-scrollbar': {
                          width: '0.2em',
                        },
                        '*::-webkit-scrollbar-track': {
                          '-webkit-box-shadow':
                            'inset 0 0 6px rgba(0,0,0,0.09)',
                        },
                        '*::-webkit-scrollbar-thumb': {
                          backgroundColor: 'rgba(0,0,0,.1)',
                        },
                      }}
                    />
                  </>
                </DialogMessage>
              )}
            </Paper>
            <Grid
              container
              justifyContent="flex-end"
              className={classes.buttonGrid}>
              <MyButton
                id={'DownloadCSV'}
                text={'Download as CSV'}
                variant="contained"
                disabled={!reportTypeSelected || !report.state.rows.length}
                onClick={report.fetchReport({download: true, sorting: true})}
              />
            </Grid>
          </>
        )}
      </Box>
    </Grid>
  );
};

export default withHeader(
  {
    title: 'Management Report',
    margin: 40,
    useSlug: 'type',
  },
  ManagementReport,
);

const StyledCheckbox = withStyles({
  root: {
    padding: 0,
    color: COLOR.GREEN_BUTTON,
    '&$checked': {
      color: COLOR.GREEN_BUTTON,
    },
  },
  checked: {},
})(Checkbox);

const ItemBase = ({
  item: {column, hidden},
  disabled,
  onToggle,
  classes,
  ...restProps
}: {
  item: {column: any; hidden: boolean};
  disabled: boolean;
  onToggle: any;
  classes: any;
}) => (
  <ListItem
    id={`Hide-${column.name}`}
    key={column.name}
    component="li"
    disabled={disabled}
    onClick={!disabled ? onToggle : null}
    {...restProps}>
    <StyledCheckbox
      checked={!hidden}
      tabIndex={-1}
      disableRipple
      disabled={disabled}
    />
    <ListItemText
      className={classes.itemText}
      primary={column.title || column.name}
    />
  </ListItem>
);

ItemBase.propTypes = {
  item: PropTypes.shape({
    column: PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
    }),
    hidden: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

ItemBase.defaultProps = {
  onToggle: () => {},
  disabled: false,
};
//@ts-ignore
const Item = withStyles(CustomFilterCellStyles, {name: 'Item'})(ItemBase);
