import React from 'react';
import withHeader from '../../../presentation/withHeader';

import SamplePoster from '../../../img/promotion/SamplePoster.svg';
import SampleSecurityCode from '../../../img/promotion/SampleSecurityCode.svg';
import { Container, Grid } from '@material-ui/core';
import PromotionHeading from '../PromotionLanding/PromotionHeading';
import { useStyles } from '../PromotionLanding/styles';
import PromotionLandingContent from '../PromotionLanding/PromotionLandingContentCom';
import { promotionLandingContent } from '../PromotionLanding/promotionLandingContent';
import MyButton from '../../../presentation/button';
import { useHistory, useRouteMatch } from 'react-router';

interface MainLandingProps {
  actionButton?: boolean;
}

export const MainLanding: React.FC<MainLandingProps> = ({
  actionButton
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { path } = useRouteMatch();

  return (
    <Container maxWidth="lg">
      <PromotionHeading />

      <Grid container className={classes.landingContainer} justifyContent="center">
        <Grid item container justifyContent="center" style={{gap: 30, marginTop: 20}}>
          <Grid item xs={4}>
            <img
              src={SamplePoster}
              alt="Sample Poster"
              width="100%"
              className={classes.promotionImage} />
          </Grid>
          <Grid item xs={4}>
            <img
              src={SampleSecurityCode}
              alt="Sample Security Code"
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={10}>
          <PromotionLandingContent content={promotionLandingContent('productProfile')} leftIndent tryItOutGap={false} />
          {actionButton &&
            <Grid item container justifyContent="center">
              <MyButton
                text="Create"
                variant="contained"
                width={300}
                fontSize={20}
                buttonClass={classes.button}
                onClick={() => history.push(`${path}/productProfile/new`)}
              />
            </Grid>
          }
        </Grid>
      </Grid>
    </Container>
  );
};

export default withHeader(
  {
    title: 'Products',
    margin: 40
  },
  MainLanding,
);
