import React, { useState, useEffect } from 'react';
import withHeader from '../../presentation/withHeader';
import { Grid, Typography, makeStyles, Link, } from '@material-ui/core';
import CSVUploader from '../../presentation/CSVUploader';
import { HyperLink } from '../../presentation/word';
import MyButton from '../../presentation/button';
import { AssetDialogMessage, cleanSelectedFile, assetStyles } from '../asset/Components';
import CONSTANT from '../../config/constant';
import { Buttons } from '../../presentation/ButtonsGroup';
import {
  validateWithSchema,
  JSONSchemaValidationError,
} from '../../utils/csvReader';
import { uniqueIds } from '../../config/schema';
import { ValidationErrorType, NetworkError } from '../../utils/error';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { toggleModal, toggleModalOff } from '../../store/modal/actions';
import { createTokensByCSV } from '../../store/asset/actions';
import COLOR from '../../styled/colors';
import { getSireGroups, deleteSireGroup } from '../../store/angus/actions';
import { Line } from '../../presentation/Line';
import SearchBar from '../../presentation/SearchBar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from "@material-ui/core/TableCell";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

export const useStyles = makeStyles((theme) => ({
  subTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
    paddingBottom: "10px",
  },
  searchBarContainer: {
    flexGrow: 1,
    marginTop: 20,
    marginBottom: 20,
    maxWidth: 350,
  },
  greyBox: {
    backgroundColor: COLOR.GRAY_SOLID,
  },
  link: {
    color: COLOR.GREEN_BUTTON,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  cursor: {
    cursor: 'pointer'
  }
}));

const SireGroups: React.FC<{ Props }> = (props) => {
  const classes = { ...assetStyles(), ...useStyles() };
  const history = useHistory();
  const [file, setFile] = useState(null);
  const [query, setQuery] = useState('');
  const [csvData, setCSVData] = useState([]);
  const [validationResult, setValidationResult] = useState({
    type: null as string,
    validationError: [] as Array<JSONSchemaValidationError>,
  });
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const userId = useAppSelector(state => state.auth.wallet);
  const [groups, setGroups] = useState([]);
  const businessProfile = useAppSelector(
    state => state.user.businessProfileData,
  );
  const uniqueIdTypes = uniqueIds.Australia;
  const isAnimalBusiness = businessProfile.industryType == "ANIMALS"

  useEffect(() => {
    dispatch(getSireGroups(userId)).then((resp) => setGroups(resp));
  }, []);

  const createDate = (group) => {
    return new Date(group.externalIds[0].timestamp).getDate() + '-' +
      (new Date(group.externalIds[0].timestamp).getMonth() + 1).toString().padStart(2, "0") + '-' +
      new Date(group.externalIds[0].timestamp).getFullYear()
  };

  const deleteGroup = (group: any) => {
    const agliveToken = group.externalIds.filter(id => id.agliveToken)[0].agliveToken;
    const angusAuthToken = isAnimalBusiness? businessProfile.angusProfile.angusAuthToken: undefined
    dispatch(deleteSireGroup(
      userId,
      agliveToken,
      angusAuthToken,
      () => dispatch(getSireGroups(userId)).then((resp) => setGroups(resp))
    ));
    setGroups(groups);
  }

  const createGroupList = () => {
    let groupList = [];
    groups.forEach(group => {
      const createdDate = createDate(group);
      const group2 = { ...group, details: { ...group.details, created_on: createdDate, externalIds: group.externalIds } };
      groupList.push({
        nameText: group.details.group_name,
        name:
          <Link
            className={classes.link}
            onClick={() => {
              history.push(`/private/verified/sireGroups/details`, { details: group2 });
            }}
          >
            {group.details.group_name}
          </Link>,
        id: group.details.sire_group_id,
        sireCount: group.details.group_sires?.length?group.details.group_sires?.length:0,
        date: createdDate,
        edit: <>
          <EditIcon
            className={classes.cursor}
            onClick={() => {
              history.push(`/private/verified/sireGroups/details`, { details: group2 });
            }}
          />
          <DeleteIcon
            className={classes.cursor}
            onClick={() => {
              dispatch(
                toggleModal({
                  status: 'warning',
                  title: 'Delete Group?',
                  subtitle: 'This action cannot be undone. Ungrouped animals remain in your account',
                  renderButton: (
                    <Buttons
                      leftButtonTitle="No"
                      rightButtonTitle="Yes"
                      leftButtonOnClick={() => {
                        dispatch(toggleModalOff());
                      }}
                      rightButtonOnClick={() => {
                        dispatch(toggleModalOff());
                        deleteGroup(group);
                      }}
                    />
                  ),
                }),
              );
            }}
          />
        </>
      });
    });
    return groupList;
  }

  const header = [
    { name: 'Group Name', id: 'Angus Group ID', sireCount: 'Number of Sires', date: 'Date Created', edit: 'Edit' },
  ];

  const rows = createGroupList();

  return (
    <>
      <CSVUploader file={file} setFile={setFile} setCSVData={setCSVData} />

      <Grid container justify="flex-end" style={{ maxWidth: window.innerWidth / 2 }}>
        <Grid item className={classes.linksContainer}>
          <HyperLink
            className={classes.linksBetween}
            href={CONSTANT.ASSETINDUCTION.SIREGROUPS.CSVTEMP}>
            Download CSV Template
          </HyperLink>
          <HyperLink href={CONSTANT.ASSETINDUCTION.SIREGROUPS.CSVSAMPLE}>
            Download Example and Instructions
          </HyperLink>
        </Grid>
      </Grid>

      <Grid container justify="flex-end">
        <MyButton
          disabled={csvData.length ? false : true}
          buttonClass={classes.saveButton}
          text={'Save'}
          variant="contained"
          onClick={() => {
            validateWithSchema(csvData, CONSTANT.ASSETINDUCTION.SIREGROUPS.SCHEMA, true, uniqueIdTypes)
              .then(() => {
                setValidationResult({ type: null, validationError: [] });
                setCSVData((prevCSVEntries) =>
                  prevCSVEntries.map((CSVEntry) => {
                    CSVEntry.externalIds = uniqueIdTypes.reduce(
                      (accumIDs, curID) => ({
                        ...accumIDs,
                        [curID]: CSVEntry[curID],
                      }),
                      {},
                    );
                    uniqueIdTypes.map((id) => delete CSVEntry[id]);

                    return CSVEntry;
                  }),
                );
              })
              .catch((error) => {
                const e = error as ValidationErrorType<
                  Array<JSONSchemaValidationError>
                >;
                setValidationResult({
                  type: e.type,
                  validationError: e.error,
                });
              });
            setOpen(true);
          }}
        />
      </Grid>

      <Grid container>
        <Typography variant='h3'>Existing Groups</Typography>
      </Grid>
      <Line />

      <Grid container>
        <Grid className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label="Search by Group Name"
          />
        </Grid>

        <TableContainer>
          <Table>
            <TableBody>
              {header.map((cell) => (
                <TableRow key={cell.name} className={classes.greyBox}>
                  <TableCell align="left" style={{ fontWeight: 700 }}>{cell.name}</TableCell>
                  <TableCell align="center" style={{ fontWeight: 700 }}>{cell.id}</TableCell>
                  <TableCell align="center" style={{ fontWeight: 700 }}>{cell.sireCount}</TableCell>
                  <TableCell align="center" style={{ fontWeight: 700 }}>{cell.date}</TableCell>
                  <TableCell align="center" style={{ fontWeight: 700 }}>{cell.edit}</TableCell>
                </TableRow>
              ))}
              {rows?.filter(res => res.nameText.toLowerCase().includes(query.toLocaleLowerCase()))
                .map((row) => (
                  <TableRow key={row.name}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="center">{row.id}</TableCell>
                    <TableCell align="center">{row.sireCount}</TableCell>
                    <TableCell align="center">{row.date}</TableCell>
                    <TableCell align="center">{row.edit}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

      </Grid>

      {open && (
        <AssetDialogMessage
          open={open}
          isErr={validationResult.type ? true : false}
          fileName={file.name}
          handleClose={() => {
            cleanSelectedFile('csvReader');
            setFile('');
            setCSVData([]);
            setValidationResult({type: null, validationError: []});
            setOpen(false);
          }}
          validationResult={validationResult}
          rightButtonClick={() => {
            setOpen(false);
            dispatch(
              createTokensByCSV({
                template: CONSTANT.ASSETINDUCTION.SIREGROUPS.TEMPLATE,
                accountId: isAnimalBusiness? businessProfile.angusProfile.acctID: undefined,
                angusAuthToken: isAnimalBusiness? businessProfile.angusProfile.angusAuthToken: undefined,
                type: CONSTANT.ASSETINDUCTION.SIREGROUPS.TYPE,
                nlisUserId: businessProfile.location[0].NLISUserID,
                nlisPassword: businessProfile.location[0].NLISPassword,
                nlisEmail: businessProfile.users[0].email,
                file,
                csvData,
              }, () => dispatch(getSireGroups(userId)).then((resp) => setGroups(resp))),
            )
              .then(() => {
                cleanSelectedFile('csvReader');
                setFile('');
              })
              .catch((error) => {
                cleanSelectedFile('csvReader');
                setFile('');
                if (error instanceof NetworkError) {
                  const isTable =
                    Array.isArray(error.error) &&
                    error.error[0].source?.data?.length;

                  if (isTable) {
                    const errorList = [];
                    let data = error.error[0].source.data;
                    // This for loop is here because foreach doesn't want to work.  I don't know why
                    for(let i = 0;i < data.length;i++){
                      const errors = data[i];
                      const e = {row: errors.row, message: errors.errors[0]};
                      errorList.push(e);
                    }
                    /*data.foreach((errors: any) => {
                      errorList.push([errors.row, errors.errors[0]]);
                    });*/

                    dispatch(
                      toggleModal({
                        status: 'failed',
                        title: error.title,
                        errorInfo: errorList,
                        renderButton: (
                          <Buttons
                            leftButtonTitle="Cancel"
                            rightButtonTitle="Upload Csv Again"
                            leftButtonOnClick={() => {
                              dispatch(toggleModalOff());
                              cleanSelectedFile('csvReader');
                              setFile('');
                              setCSVData([]);
                            }}
                            rightButtonOnClick={() => {
                              dispatch(toggleModalOff());
                              cleanSelectedFile('csvReader');
                              setFile('');
                              setCSVData([]);
                            }}
                          />
                        ),
                      }),
                    );
                  } else {
                    dispatch(
                      toggleModal({
                        status: 'failed',
                        title: error.title,
                        subtitle: error.message,
                      }),
                    );
                    console.error(
                      'Error.error undefined. Modal will display fallback message',
                    );
                  }
                } else {
                  dispatch(
                    toggleModal({
                      status: 'failed',
                      title: error.title,
                      subtitle: error.message,
                    }),
                  );
                }
              });
          }}
          rightButtonClickIsErr={() => {
            cleanSelectedFile('csvReader');
            setFile('');
            setCSVData([]);
            setValidationResult({ type: null, validationError: [] });
            setOpen(false);
          }}
          csvData={csvData}
        />
      )}
    </>
  );
};

export default withHeader(
  {
    title: 'Sires Groups',
    margin: 60,
    back: true,
  },
  SireGroups,
);