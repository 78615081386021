import {TokenService} from '@aglive/data-model';

export const STORE_TEMP_PRODUCT = 'STORE_TEMP_PRODUCT';
export const REMOVE_TEMP_PRODUCT = 'REMOVE_TEMP_PRODUCT';

interface StoreTempProductAction {
  type: typeof STORE_TEMP_PRODUCT;
  payload: TokenService.ProductToken;
}

interface RemoveTempProductAction {
  type: typeof REMOVE_TEMP_PRODUCT;
}

export type ProductActions = StoreTempProductAction | RemoveTempProductAction;
