import {PromotionLandingContentT} from './types';
import CattleCaseStudy from '../../../img/promotion/CattleCaseStudy.png';

export const promotionLandingContent = (
  promotionType: 'cattlePromotion' | 'productPromotion' | 'productProfile' | 'securityCode' | 'securityCodeLanding',
): PromotionLandingContentT => {
  switch (promotionType) {
    case 'cattlePromotion':
      return {
        phoneCamera: {
          description:
            'Create your own marketing page that works with a phone camera scan',
          tryItOut:
            "Scan this sample code with your phone's camera to explore the marketing possibilities",
        },
        agliveApp: {
          description:
            "Provide proof of your cattle's genetics and other information such as treatments, weight, traits, pregnancy, and certification",
          tryItOut:
            'Scan the same code with Aglive App to see even deeper mob or consignment info',
        },
        saleYard: {
          description:
            'Embed the dual scan QR code in a promotional poster and imagine using a powerful marketing tool to tell your story',
        },
        caseStudy: {
          image: CattleCaseStudy,
          title: '$150 per head - Sale Price Premium',
          description:
            "Use of the Aglive Platform helped deliver a $150 per head premium for Robert Mackenzie of Macka's Australian Black Angus Beef at a Bowe and Lidbury Auction.",
        },
      };
    case 'productPromotion':
      return {
        phoneCamera: {
          description:
            'Create your own marketing page that works with a phone camera scan',
          tryItOut:
            "Scan this sample code with your phone's camera to explore the marketing possibilities",
        },
        agliveApp: {
          description:
            'Create and provide your customers with a broader product story by including additional information such as videos and attachments',
          tryItOut:
            'Scan the same code with Aglive App to see even deeper product item information',
        },
        saleYard: {
          customTitle: 'Promotion Poster',
          description:
            'Embed the dual scan QR code in a promotional poster and imagine using a powerful marketing tool to tell your story',
        },
      };
    case 'productProfile':
      return {
        phoneCamera: {
          description:
            'Create your own marketing page that works with a phone camera scan',
          tryItOut:
            "Scan sample codes with your phone's camera to explore the marketing possibilities",
        },
        agliveApp: {
          description:
            'Provide proof of your products provenance and include additional information such as videos and attachments',
          tryItOut:
            'Scan sample codes with Aglive App to see even deeper product item information',
        },
        saleYard: {
          customTitle: 'Promotion Code or Security Code',
          description:
            'Embed a dual scan QR code on a promotional poster or on each individual item and imagine using a powerful marketing tool to tell your story',
        },
      };
    case 'securityCode':
      return {
        phoneCamera: {
          description:
            'Create your own marketing page that works with a phone camera scan',
          tryItOut:
            "Scan sample codes with your phone's camera to explore the marketing possibilities",
        },
        agliveApp: {
          description:
            'Provide proof of your products provenance and include additional information such as videos and attachments',
          tryItOut:
            'Scan the same code with Aglive App to see even deeper product item information',
        },
        saleYard: {
          customTitle: 'Security Code',
          description:
            'Embed a dual scan QR code on each individual item and imagine using a powerful marketing tool to tell your story',
        },
      }
    case 'securityCodeLanding':
      return {
        phoneCamera: {
          description:
            'Create your own marketing page that works with a phone camera scan',
          tryItOut:
            "Scan this sample code with your phone's camera to explore the marketing possibilities",
        },
        agliveApp: {
          description:
            'Provide proof of your products provenance and include additional information such as videos and attachments',
          tryItOut:
            'Scan the same code with Aglive App to see even deeper product item information',
        },
        saleYard: {
          customTitle: 'Shipping Code',
          description:
            'Embed a dual scan QR code on each individual item and imagine using a powerful marketing tool to tell your story',
        },
      }
    default:
      return {
        phoneCamera: {
          description: '',
          tryItOut: '',
        },
        agliveApp: {
          description: '',
          tryItOut: '',
        },
        saleYard: {
          description: '',
        },
      };
  }
};
