import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {toggleModal} from '../../store/modal/actions';
import {getProfiles} from '../../store/profile/actions';
import {useHistory, useLocation} from 'react-router-dom';
import Dashboard from './Dashboard';
import AssetProfileLibrary from '../assetProfile/AssetProfileLibrary';
import ProductProfileLibrary from '../promotion/ProductProfileLibrary';
import TrackAnimal from '../map/TrackAnimal';

type HomeProps = {};

const Home: React.FC<HomeProps> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {state} = useLocation<{fromAuth?: boolean}>();
  const userid = useAppSelector((state) => state.auth.wallet);
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const plugins = useAppSelector((state) => state.user.plugins);
  const isCeresTag = plugins?.find((plugin) => plugin.name === 'Ceres Tag');
  const isProducts = businessProfile.industryType === 'PRODUCTS';
  const isCannabis =
    businessProfile.industryType === 'PLANTS' &&
    businessProfile.subIndustryType === 'Cannabis';
  const isCanadaCerestag =
    isCeresTag && businessProfile.businessCountry === 'Canada';

  // if isAngus, route to Angus Dashboard
  useEffect(() => {
    dispatch(getProfiles(userid));
  }, []);

  useEffect(() => {
    if (
      state?.fromAuth &&
      businessProfile.companyName &&
      businessProfile.users?.find((user) => user.userId === userid)
    ) {
      if (
        businessProfile.industryType === 'ANIMALS' &&
        businessProfile.businessType === 'Angus'
      ) {
        history.push('/private/verified');
      } else {
        dispatch(
          toggleModal({
            status: 'success',
            title: 'Welcome',
            subtitle: [''],
            button: 'Continue',
            CTAHandler: () => {
              if (
                businessProfile.industryType === 'ANIMALS' &&
                businessProfile.businessType === 'Angus'
              ) {
                history.push('/private/verified');
              } else {
                //history.push('/private/register/asset');
              }
            },
          }),
        );
      }
    }
  }, [state?.fromAuth, businessProfile?.companyName]);

  useEffect(() => {
    if (state?.fromAuth) {
      if (isCannabis) {
        history.push('/private/assets/assetProfile');
        dispatch(
          toggleModal({
            status: 'success',
            title: 'Welcome',
            subtitle: [''],
            button: 'Continue',
          }),
        );
      } else if (isProducts) {
        history.push('/private/products/productProfile');
        dispatch(
          toggleModal({
            status: 'success',
            title: 'Welcome',
            subtitle: [''],
            button: 'Continue',
          }),
        );
      } else if (isCanadaCerestag) {
        history.push('/private/profile/map');
        dispatch(
          toggleModal({
            status: 'success',
            title: 'Welcome',
            subtitle: [''],
            button: 'Continue',
          }),
        );
      }
    }
  }, [
    isCanadaCerestag,
    isCannabis,
    isProducts,
    state?.fromAuth,
  ]);

  if (state?.fromAuth) {
    if (
      businessProfile.industryType === 'PLANTS' &&
      businessProfile.subIndustryType === 'Cannabis'
    ) {
      return <AssetProfileLibrary />;
    } else if (isProducts) {
      return <ProductProfileLibrary />;
    } else if (isCanadaCerestag) {
      return <TrackAnimal />;
    } else {
      return <Dashboard />;
    }
  } else {
    return <Dashboard />;
  }
};

export default Home;
