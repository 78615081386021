import {Marker, InfoWindow} from '@react-google-maps/api';
import OptiweighScale from '../img/OptiweighScale.png';
import MyButton from './button';
import moment from 'moment';

import SearchBar from './SearchBar';
import {memo} from 'react';

export type MarkerType = {
  lat: number;
  lng: number;
  info: {
    esn: string;
    rfid: string;
    circleRadius: number;
    activityText: string;
    icon?: string;
    warning?: {
      color: string;
    };
    errors?: Array<string>;
    temperature: string;
    date: string;
    history?: Array<HistoryType>;
    weight?: number;
    unit?: string;
    weightDate?: string;
    avgDailyGain?: number;
  };
};

export type HistoryType = {
  lat: number;
  lng: number;
  date: string;
};

type Props = {
  markerOnClick: (e: google.maps.MapMouseEvent) => void;
  infoWindowOnCloseClick: () => void;
  historyOnClick: () => void;
  clickedHistory: boolean;
  historyIndex: number;
  marker: MarkerType;
  index: number;
  activeMarker: number;
  historyText: string;
};

const AnimalMarker = ({
  marker,
  index,
  markerOnClick,
  clickedHistory,
  historyIndex,
  activeMarker,
  infoWindowOnCloseClick,
  historyOnClick,
  historyText,
}: Props) => {
  return (
    <Marker
      animation={google.maps.Animation.DROP}
      icon={{
        url: marker.info.icon || '',
        scaledSize: new window.google.maps.Size(32, 32),
        anchor: new google.maps.Point(16, 16),
      }}
      key={`Marker-${index}`}
      position={{lat: marker.lat, lng: marker.lng}}
      options={{
        opacity: clickedHistory === true && historyIndex !== index ? 0.5 : 1,
      }}
      clickable={
        clickedHistory === true && historyIndex !== index ? false : true
      }
      onClick={markerOnClick}>
      {activeMarker === index && (
        <InfoWindow onCloseClick={infoWindowOnCloseClick}>
          <AnimalPopup {...{marker, historyOnClick, historyText}} />
        </InfoWindow>
      )}
    </Marker>
  );
};

const AnimalPopup = ({
  marker,
  historyText,
  historyOnClick,
}: Pick<Props, 'marker' | 'historyOnClick' | 'historyText'>) => {
  return (
    <div>
      <h3>
        {marker.info.rfid ? `${marker.info.rfid} (${marker.info.esn})` : marker.info.esn}
      </h3>
      {marker.info.errors && marker.info.errors.length > 0 && (
        <p>
          <strong style={{color: marker.info.warning?.color}}>
            {marker.info.errors.join(', ')}
          </strong>
        </p>
      )}
      <p>
        <strong>Latitude: </strong>
        <span>{marker.lat}</span>
      </p>
      <p>
        <strong>Longitude: </strong>
        <span>{marker.lng}</span>
      </p>
      <p>
        <strong>Accuracy: </strong>
        <span>
          {marker.info.circleRadius === 300
            ? '> 100m'
            : marker.info.circleRadius === 0
            ? 'None'
            : `${marker.info.circleRadius}m`}
        </span>
      </p>
      <p>
        <strong>Date/Time: </strong>
        <span>
          {moment(marker.info.date).format('MMMM Do yyyy, h:mm:ss a')}
        </span>
      </p>
      <p>
        <strong>Activity Level: </strong>
        <span>{marker.info.activityText}</span>
      </p>
      <p>
        <strong>Device Temperature: </strong>
        <span>{marker.info.temperature}&deg;C</span>
      </p>
      {marker.info.weight && marker.info.unit && (
        <>
          <p>
            <strong>Weight: </strong>
            <span>
              {marker.info.weight} {marker.info.unit} (
              {moment(marker.info.weightDate, 'YYYY-MM-DD').isValid()
                ? moment(marker.info.weightDate).format('MMMM Do YYYY')
                : marker.info.weightDate}
              )
            </span>
          </p>
          <p>
            <strong>Average Daily Gain: </strong>
            <span>
              {marker.info.avgDailyGain} {marker.info.unit}{' '}
            </span>
          </p>
        </>
      )}
      {marker.info.history && (
        <p>
          <MyButton
            variant="contained"
            onClick={historyOnClick}
            text={historyText}></MyButton>
        </p>
      )}
    </div>
  );
};

interface OptiweighMarkerType {
  location: google.maps.LatLngLiteral;
  clickOptiweighScale: () => void;
  closeOptiweighPopup: () => void;
  search: (query: string) => void;
  query: string;
  toggleWeightSidebar: () => void;
  showInfo: boolean;
  showSidebar: boolean;
  weightsNum: number;
}

const OptiweighMarker = memo((props: OptiweighMarkerType) => (
  <Marker
    animation={google.maps.Animation.DROP}
    icon={{
      url: OptiweighScale,
      scaledSize: new window.google.maps.Size(48, 48),
      anchor: new google.maps.Point(16, 16),
    }}
    key={`Weight-Scale`}
    position={props.location}
    clickable={true}
    onClick={props.clickOptiweighScale}>
    {props.showInfo && (
      <InfoWindow onCloseClick={props.closeOptiweighPopup}>
        <div>
          <p>
            <strong>Total Number: </strong>
            <span>{props.weightsNum}</span>
          </p>
          {props.showSidebar && (
            <SearchBar
              query={props.query}
              setQuery={props.search}
              label="Search by Identifier"
            />
          )}
          <p>
            <MyButton
              width={300}
              variant="contained"
              onClick={props.toggleWeightSidebar}
              text={`${
                props.showSidebar ? 'Hide' : 'Show'
              } Details`}></MyButton>
          </p>
        </div>
      </InfoWindow>
    )}
  </Marker>
));

export {AnimalMarker, OptiweighMarker};
