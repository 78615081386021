
import React, {useReducer} from 'react';
import {useAppDispatch, useAppSelector} from '../../../utils/hooks';
import {useHistory} from 'react-router-dom';
import {Grid} from '@material-ui/core';

import BaseBrand from './BaseBrand';
import MyButton from '../../../presentation/button';
import withHeader from '../../../presentation/withHeader';

import { baseBrandReducer, INITIAL_BRAND_STATE } from './brandProfileState/reducer';
import { SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON } from '../../../store/spinner/types';
import { callAPI } from '../../../utils/network';
import { WebErrorType } from '../../../utils/error';
import { toggleModal } from '../../../store/modal/actions';

import API from '../../../config/api';
import CONSTANT from '../../../config/constant';
import { addBrandToBusiness } from '../../../store/brand/actions';
import { BRAND_LIBRARY_PATH, getBrandAgliveTokenId } from '../utils';
import { TokenService } from '@aglive/data-model';

const CreateBrand: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const businessId = useAppSelector(state => state.user.userProfileData.businessId);

  const [createBrandState, localDispatch] = useReducer(baseBrandReducer, INITIAL_BRAND_STATE);

  const onSubmit = async () => {
    try {
      // create brand
      dispatch({type: SPINNER_TOGGLE_ON});
      const payloadWithBusiness = {
        ...createBrandState.payload,
        business: {
          ...createBrandState.payload.business,
          // tokenId: business[0].tokenId,
          externalIds: {
            // ...targetBrand.business.externalIds,
            agliveToken: businessId
          },
        },
      };

      const token: { message: string; data: Array<TokenService.BrandToken> } = await callAPI({
        url: API.POST.createToken,
        method: 'POST',
        data: {
          tokens: [{
            type: CONSTANT.ASSETTYPE.BRAND,
            tokenId: '',
            details: payloadWithBusiness,
          }],
        },
      });
      // add the new brand to business
      await addBrandToBusiness(
        businessId,
        getBrandAgliveTokenId(token.data[0]),
      );
      
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Created',
          button: 'Close',
          CTAHandler: () => history.push(BRAND_LIBRARY_PATH),
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  }

  return (
    <Grid container direction="column">
      {/* <ProductHeader product={product} handleChange={handleChange} /> */}
      <BaseBrand
        localDispatch={localDispatch}
        brandState={createBrandState}
      />

      <Grid item container justify="flex-end">
        <MyButton
          text="Save"
          variant="contained"
          onClick={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default withHeader(
  {
    title: 'Create Brand',
    margin: 60,
    back: true,
  },
  CreateBrand
);
