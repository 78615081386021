import React, {ReactElement} from 'react';

import {Grid, GridSize, Typography} from '@material-ui/core';

import CowIcon from '../../img/Cow.svg';
import ScaleIcon from '../../img/Scale.svg';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import {MainHeading} from './styles';

import styled from 'styled-components';
import COLOR from '../../styled/colors';

type CardContentText = string | number | ReactElement;

export type DashboardCardType = {
  title: string;
  subtitle: string;
  icon: string;
  cardContent?: Array<[CardContentText, CardContentText]>;
  sizing?: Array<number>;
};

type DashboardCardProps = {
  children?: ReactElement; // pass in either cardContent or children to render content
} & DashboardCardType;

const IconSelector: React.FC<{icon: string}> = ({icon}) => {
  // icon gallery
  switch (icon) {
    // # C
    case 'Cow':
      return <img src={CowIcon} alt="cow" />;
    // # D
    case 'Dollar':
      return <AttachMoneyOutlinedIcon fontSize="large" />;
    // # S
    case 'Scale':
      return <img src={ScaleIcon} alt="scale" />;
  }
};

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  subtitle,
  icon,
  cardContent,
  children,
  sizing,
}) => {
  const isCustomCom = (value: CardContentText) =>
    typeof value !== 'string' && typeof value !== 'number';
  const CardContent = children || (
    <Grid style={{marginTop: 60}}>
      {cardContent?.map(([row, value], index) => (
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          alignContent="space-between"
          style={{marginTop: 40}}
          key={index}>
          <Grid item xs={sizing ? (sizing[0] as GridSize) : 6}>
            {isCustomCom(row) ? row : <RowTitle>{row}</RowTitle>}
          </Grid>
          <Grid item xs={sizing ? (sizing[1] as GridSize) : 6}>
            {isCustomCom(value) ? (
              value
            ) : (
              <RowValue align="right">{value}</RowValue>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <CardContainer>
      <Grid item container direction="row">
        <IconContainer>
          <IconSelector icon={icon} />
        </IconContainer>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          container
          direction="column"
          style={{marginLeft: 12, marginTop: 12}}>
          <MainHeading>{title}</MainHeading>
          <SubHeading>{subtitle}</SubHeading>
        </Grid>
      </Grid>
      {CardContent}
    </CardContainer>
  );
};

const CardContainer = styled(Grid)`
  min-height: 300px;
  width: 20vw;
  border-radius: 5%;
  border: 1px solid ${COLOR.GRAY_BORDER};
  padding: 20px;
`;

const IconContainer = styled(Grid)`
  border-radius: 50%;
  background-color: ${COLOR.GRAY_SOLID};
  padding: 20px;
  padding-bottom: 18px;
`;

const SubHeading = ({children}) => (
  <Typography
    style={{
      fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeight: 400,
      opacity: 0.4,
    }}>
    {children}
  </Typography>
);

const RowTitle = styled(Typography)`
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  vertical-align: bottom;
  padding-top: 8px;
`;

const RowValue = styled(Typography)`
  font-family: 'DM Sans', sans-serif;
  font-size: 25px;
  font-weight: 400;
  align: right;
  color: ${COLOR.GREEN_HIGHLIGHT};
`;

export default DashboardCard;
