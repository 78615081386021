export interface authState {
   readonly isLoggedIn: boolean;
   readonly walletLoginId: string|null;
   readonly wallet: string;
}

export const GET_QRCODE='GET_QRCODE';
export const LOGIN='LOGIN';
export const LOGIN_SUCCESS='LOGIN_SUCCESS';
export const LOGOUT='LOGOUT';
export const RESET_ERROR='RESET_ERROR';

interface GetQrcode {
   type: typeof GET_QRCODE;
   payload: string;
}

interface LoginSuccess {
   type: typeof LOGIN_SUCCESS;
   payload: string;
}

interface LoginT {
   type: typeof LOGIN;
}

interface LogoutT {
   type: typeof LOGOUT;
}

interface ResetErrorT {
   type: typeof RESET_ERROR;
}

export type AuthTypes=
   LoginSuccess|
   GetQrcode|
   LoginT|
   LogoutT|
   ResetErrorT;
