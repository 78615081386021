import React, { useEffect, useState, useLayoutEffect } from 'react';
import {
  Grid,
  makeStyles,
  Card,
  Typography,
  CardActionArea,
  CardContent,
  CardMedia,
  Link,
} from '@material-ui/core';
import {
  Header2,
  Paragraph,
  HyperLink,
} from "../../presentation/word";
import COLOR from "../../styled/colors";
import IconRecordSires from "../../img/IconRecordSires.svg";
import IconAiJoining from "../../img/IconAiJoining.svg";
import IconSireGroups from "../../img/IconSireGroups.svg";
import IconRecordAnimals from "../../img/IconRecordAnimals.svg";
import IconOrderTags from "../../img/IconOrderTags.svg";
import IconAnimalProfile from "../../img/IconAnimalProfile.svg";
import { useHistory } from 'react-router';

const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: "40px",
    paddingRight: "40px"
  },
  title: {
    borderBottom: "1px solid #D9D9D9",
    paddingBottom: "16px",
    marginBottom: "16px",
  },
  card: {
    marginBottom: 14,
    paddingBottom: 10,
  },
  cardLink: {
    '&:hover': {
      color: COLOR.RED,
    }
  },
  cardImage: {
    objectFit: "contain",
    backgroundColor: COLOR.GRAY_SOLID,
  }
});

const data = [
  {
    title: "Record Sires",
    index: 0,
    img: IconRecordSires,
    url: "/private/verified/recordSires",
  },
  {
    title: "AI Joining",
    index: 1,
    img: IconAiJoining,
    url: "/private/verified/aiJoining",
  },
  {
    title: "Sire Groups",
    index: 2,
    img: IconSireGroups,
    url: "/private/verified/sireGroups",
  },
  {
    title: "Record Animals",
    index: 3,
    img: IconRecordAnimals,
    url: "/private/verified/recordAnimals",
  },
  {
    title: "Order Tags",
    index: 4,
    img: IconOrderTags,
    url: "/private/verified/orderTags",
  },
  {
    title: "Animal Profile",
    index: 5,
    img: IconAnimalProfile,
    url: "/private/verified/animalProfile",
  },
];

const VerifiedActions: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }
  const [width, height] = useWindowSize();

  return (
    <div>
      <Grid
        container
        spacing={4}
        className={classes.gridContainer}
        justify="center"
      >
        <Grid item md={12}>
          <Typography variant="h1" component="h2" align="center" className={classes.title}>Angus Verified</Typography>
          <Typography variant="h3" component="h3" align="center">Pick an action below</Typography>
        </Grid>

        {data.map(({ title, index, img, url }) => (
          <Grid item xs={12} sm={6} md={4} key={index} >
            <Card className={classes.card} style={{ maxHeight: height * 0.26, maxWidth: height * 0.35 }}>
              <Link className={classes.cardLink} onClick={() => { history.push(url) }} component={CardActionArea} >
                <CardMedia
                  component="img"
                  alt={title}
                  height={height * 0.19}
                  src={img ? img : ''}
                  title={title}
                  className={classes.cardImage}
                />
                <CardContent>
                  <Paragraph fontWeight={700} color={COLOR.BLACK_BG} margin="0" textAlign={"center"} fontSize={"1.46em"}>
                    {title}
                  </Paragraph>
                </CardContent>
              </Link>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default VerifiedActions;