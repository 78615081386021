import {makeStyles} from '@material-ui/core';

import COLOR from '../../../styled/colors';

export const useStyles = makeStyles((theme) => ({
  scanSample: {
    backgroundColor: COLOR.WHITE,
    width: 360,
  },
  scanSampleText: {
    padding: theme.spacing(2),
  },
  previewContainer: {
    backgroundColor: COLOR.WHITE,
    width: 360,
    position: 'relative',
  },
  mainContainer: {
    padding: theme.spacing(4, 3),
    paddingBottom: 0,
  },
  secondaryContainer: {
    padding: theme.spacing(4),
    paddingTop: 0,
  },
  angusVerifiedContainer: {
    marginTop: theme.spacing(2),
    backgroundColor: COLOR.GRAY_SOLID,
    width: '100%',
    padding: '1px 0 1px 0',
  },
  angusVerifiedText: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    marginLeft: theme.spacing(1),
  },
  protectedBy: {
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
    paddingBottom: theme.spacing(1),
    verticalAlign: 'center',
    width: '100%',
  },
  imageContainer: {
    marginTop: theme.spacing(3),
    position: 'relative',
  },
  logo: {
    width: '270px',
    height: '130px',
    objectFit: 'contain',
  },
  defaultImageText: {
    fontSize: 12,
    color: COLOR.WHITE,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 600,
  },
  discoverMsgContainer: {
    margin: theme.spacing(2, 0),
    width: '220px',
  },
  footerContainer: {
    width: '100%',
    position: 'absolute',
    display: 'flex',
    bottom: 0,
  },
  footerImage: {
    width: '100%',
    maxHeight: '270px',
    objectFit: 'contain',
  },
  qrContainer: {
    position: 'relative',
    padding: theme.spacing(1),
    aspectRatio: '1/1',
    backgroundColor: COLOR.WHITE,
  },
  qrOverlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  qrOverlayInner: {
    backgroundColor: COLOR.GRAY_SOLID,
    width: '70%',
    height: '70%',
    position: 'absolute',
    top: '15%',
    left: '15%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
  },
}));
