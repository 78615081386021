import React from "react";
import { makeStyles } from "@material-ui/core";
import IPhone8 from "../img/IPhone8.png";

interface iMockupPhone {
  children: JSX.Element,
  backgroundColor?:string
}

const MockupPhone: React.FC<iMockupPhone> = ({ children, backgroundColor }) => {
  const useStyle = makeStyles((theme) => ({
    iPhone8: {
      backgroundImage: `url(${IPhone8})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      display: "flex",
      flexDirection: "column",
      width: 281,
      height: 621,
      backgroundColor: backgroundColor ? backgroundColor : "#6D7278",
      paddingLeft: 20,
      borderRadius: "49px",
    },
  }));
  const classes = useStyle();

  return (
    <div className={classes.iPhone8}>
      {children}
    </div>
  );
};

export default MockupPhone;
