import React, { useEffect, useReducer } from 'react';
import produce from 'immer';
import { Grid, Link } from '@material-ui/core';
import { useAppDispatch } from '../../../../utils/hooks';
import { useHistory, useParams } from 'react-router';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import MyButton from '../../../../presentation/button';
import ProgressBar from '../../../../presentation/ProgressBar';
import { PageHeader } from '../../../../presentation/withHeader';
import ProductHeader from '../../ProductHeader';
import ViewProductDetails from '../../ViewProductDetails';
import ViewTellYourStory from '../../ViewTellYourStory';

import { SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON } from '../../../../store/spinner/types';
import { toggleModal } from '../../../../store/modal/actions';

import { INITIAL_CREATE_PROFILE_STATE, navigateTo } from '../CreateProductProfile/productProfileState/reducer';
import { viewProfileActions } from './productProfileState/types';
import localActions from './productProfileState/actions';

import { findTargetBrand } from '../../../brand/utils';
import { stateHandler } from '../CreateProductProfile/productProfileState/actions';
import { fetchProductProfileTokens } from '../../ProductProfileLibrary/utils';

import { WebErrorType } from '../../../../utils/error';
import { TokenService } from '@aglive/data-model';

import COLOR from '../../../../styled/colors';
import { fetchBrands } from '../../../../store/brand/actions';

export const EDIT_PRODUCT_PROFILE_PATH = '/private/products/productProfile/edit';

const INITIAL_VIEW_PROFILE_STATE = stateHandler(
  INITIAL_CREATE_PROFILE_STATE,
  {
    payload: {
      brand: null as TokenService.BrandToken, // replaced brand because brand only contains agliveToken
    }
  }
);

const productProfileReducer = (
  state: typeof INITIAL_VIEW_PROFILE_STATE,
  action: viewProfileActions) => {
  switch (action.type) {
    case 'load/load_token':
      return produce(state, draft => {
        draft.payload = {
          ...action.payload.profileToken,
          brand: action.payload.brandToken,
        };
      });
    case 'view_navigate':
      return produce(state, draft => {
        navigateTo(draft.state, action.payload);
      });
    case 'view_navigate/prev_step':
      return produce(state, draft => {
        navigateTo(draft.state, 'story');
      });
    case 'view_navigate/next_step':
      return produce(state, draft => {
        navigateTo(draft.state, 'pages');
      }); 
    default: 
      return state;
  }
}

// determine if the productProfile is archived, then render accordingly
const ViewProductProfile: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [productProfileState, localDispatch] = useReducer(productProfileReducer, INITIAL_VIEW_PROFILE_STATE);
  const isArchived = productProfileState.payload.archive;

  useEffect(() => {
    async function fetchTargetToken() {
      try {
        dispatch({ type: SPINNER_TOGGLE_ON });
        const [targetToken] = await fetchProductProfileTokens([{agliveToken: id}]);

        const brands: Array<TokenService.BrandToken> = await fetchBrands(dispatch);

        const targetBrand = findTargetBrand(
          brands,
          targetToken.details.brand.agliveToken
        );

        localDispatch(localActions.loadToken(
          targetToken.details,
          targetBrand
        ));
      } catch (e) {
        const error = e as WebErrorType;

        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          })
        );
      } finally {
        dispatch({ type: SPINNER_TOGGLE_OFF });
      }
    }

    fetchTargetToken();
  }, []);

  const Content = (
    <Grid container direction="column">
      <Grid item>
        <ProductHeader
          productName={productProfileState.payload.name}
          productBrand={productProfileState.payload.brand}
          onNameChange={() => {}}
          onBrandChange={() => {}}
          disabled
          productNameError=""
          productBrandError=""
        />
      </Grid>

      <Grid item style={{alignSelf: 'center', width: '60%'}}>
        <ProgressBar
          percent={productProfileState.state.progressBarPosition}
          steps={[{
            label: 'Tell Your Story',
            onClick: () => localDispatch({ type: 'view_navigate', payload: 'story' })
          }, {
            label: 'Product Details',
            onClick: () => localDispatch({ type: 'view_navigate', payload: 'pages' })
          }]}
        />
      </Grid>

      <Grid item container style={{marginTop: 50}}>
        {(() => {
          switch (productProfileState.state.section) {
            case 'story':
              return (
                <ViewTellYourStory
                  brandLogo={productProfileState.payload.brand?.details.launchScreen.display.mainLogo || null}
                  story={productProfileState.payload.story}
                  mainSectionImageOption={
                    productProfileState.payload.story.image?.length
                    ? 'image'
                    : ''}
                />
              );
            case 'pages':
              return (
                <ViewProductDetails detailsArr={productProfileState.payload.pages} />
              );
          }
        })()}
      </Grid>

      <Grid
        item
        container
        justify="space-between"
        style={{
          borderTop: `1px solid ${COLOR.GRAY_BORDER}`,
          marginTop: 40,
          paddingTop: 20,
          marginBottom: 75,
        }}>
          <Link
            component="button"
            underline="always"
            variant="body2"
            style={{
              color: COLOR.GREEN_BUTTON,
              visibility: productProfileState.state.section !== 'story' ? 'visible' : 'hidden'
            }}
            onClick={() => localDispatch({ type: 'view_navigate/prev_step' })}
          >
            <ChevronLeftIcon
              fontSize="small"
              style={{
                marginBottom: -5,
                marginRight: 10,
              }}
            />
            Previous Step
          </Link>

          <Link
            component="button"
            underline="always"
            variant="body2"
            style={{
              color: COLOR.GREEN_BUTTON,
              visibility: productProfileState.state.section !== 'pages' ? 'visible' : 'hidden'
            }}
            onClick={() => localDispatch({ type: 'view_navigate/next_step' })}
          >
            Next Step
            <ChevronRightIcon
              fontSize="small"
              style={{
                marginBottom: -5,
                marginLeft: 10,
              }}
            />
          </Link>

      </Grid>

      {!isArchived && <Grid item container justify="flex-end">
        <MyButton
          text="Edit"
          variant="contained"
          onClick={() => {
            history.push(`${EDIT_PRODUCT_PROFILE_PATH}/${id}`);
          }}
        />
      </Grid>}
    </Grid>
  );

  return (
    <PageHeader
      config={{
        title: isArchived ? 'View Archived Product Profile' : 'View Product Profile',
        back: true,
      }}>
      {Content}
    </PageHeader>
  );
};

export default ViewProductProfile;
