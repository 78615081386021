import {TokenService} from '@aglive/data-model';
import {ProductActions, REMOVE_TEMP_PRODUCT, STORE_TEMP_PRODUCT} from './types';

const initialState: {tempProduct?: TokenService.ProductToken} = {};

export default function productReducer(
  state = initialState,
  action: ProductActions,
): typeof initialState {
  switch (action.type) {
    case STORE_TEMP_PRODUCT:
      return {tempProduct: action.payload};
    case REMOVE_TEMP_PRODUCT:
      return {...state, tempProduct: undefined};
    default:
      return state;
  }
}
