import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import DialogMessage from "../../presentation/DialogMessage";

export const assetStyles = makeStyles((theme) => ({
   text: {
      marginTop: theme.spacing(3),
   },
   subTitle: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      fontWeight: 600, // TODO
   },

}));

type ConfirmMoveModalProps = {
   open: boolean;
   groupName: string;
   originPic:string;
   originSite:string;
   destinationPic:string;
   destinationSite:string;
   handleClose: () => void;
   rightButtonClick: () => void;
};

const ConfirmMoveModal: React.FC<ConfirmMoveModalProps> = (props) => {
   const classes = assetStyles();
   return (
     <DialogMessage
       open={props.open}
       title={`Confirm Move`}
       handleClose={props.handleClose}
       leftButtonText={"Cancel"}
       leftButtonClick={props.handleClose}
       rightButtonText={"Confirm and Save"}
       rightButtonClick={
         props.rightButtonClick
       }>
       <Typography variant="h6" className={classes.text}>
         {"You are about to move "}{props.groupName}
       </Typography>
       <Typography variant="h6" className={classes.subTitle}>
           {"From"}
       </Typography>
       <Typography variant="h6" >
           {'Location: '}{props.originPic}
       </Typography>
       <Typography variant="h6" >
           {'Site: '}{props.originSite}
       </Typography>
       <Typography variant="h6" className={classes.subTitle}>
           {"To"}
       </Typography>
       <Typography variant="h6" >
           {'Location: '}{props.destinationPic}
       </Typography>
       <Typography variant="h6" >
           {'Site: '}{props.destinationSite}
       </Typography>
     </DialogMessage>
   );
};

export default ConfirmMoveModal;
