import React, {useState, useEffect, useCallback} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import { useHistory, useRouteMatch } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';

import { Button, Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { Buttons } from '../../../presentation/ButtonsGroup';
import { MainLanding } from '../MainLanding/MainLanding';
import MyButton from '../../../presentation/button';
import SearchBar from '../../../presentation/SearchBar';
import MyTable from '../../../presentation/Table';
import withHeader from '../../../presentation/withHeader';

import { archiveProductProfile, fetchProductProfileTokens } from './utils';
import { toggleModal, toggleModalOff } from '../../../store/modal/actions';
import { SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON } from '../../../store/spinner/types';
import toggleQRCodeModal from '../ProductProfile/CreateProductProfile/toggleQRCodeModal';
import { callAPI } from '../../../utils/network';
import { addProductToBrand } from '../../../store/product/actions';

import {useStyles} from './styles';

import ArchiveIcon from '../../../img/ArchiveIcon.svg';
import GreenQRCodeIcon from '../../../img/GreenQRCodeIcon.svg';

import COLOR from '../../../styled/colors';
import API from '../../../config/api';
import CONSTANT from '../../../config/constant';

import { TokenService } from '@aglive/data-model';
import { WebErrorType } from '../../../utils/error';

const tableHeaders = [
  'Product Name',
  'Date Created',
  'Preview',
  'Actions',
];

const ProductProfileLibrary: React.FC<{}> = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {path} = useRouteMatch();

  const userid = useAppSelector(state => state.auth.wallet);
  const [query, setQuery] = useState('');
  const [productProfiles, setProductProfiles] = useState<Array<TokenService.ProductToken | null>>(null);

  // https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
  const loadProductProfileTokens = useCallback(async () => {
    try {
      dispatch({ type: SPINNER_TOGGLE_ON });
      const profiles = await fetchProductProfileTokens();

      setProductProfiles(profiles);
    } catch (e) {
      const error = e as WebErrorType;

      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        })
      );
    } finally {
      dispatch({ type: SPINNER_TOGGLE_OFF });
    }
  }, []);

  useEffect(() => {
    loadProductProfileTokens();
  }, [loadProductProfileTokens]);

  const onArchive = (productProfile: TokenService.ProductToken) => {
    dispatch(
      toggleModal({
        status: 'warning',
        title: 'Archive?',
        subtitle: 'Archived items remain active and results will still be displayed when they are scanned',
        renderButton: (
          <Buttons
            leftButtonTitle="No"
            rightButtonTitle="Yes"
            leftButtonOnClick={() => {
              dispatch(toggleModalOff());
            }}
            rightButtonOnClick={async () => {
              try {
                dispatch(toggleModalOff());
                dispatch({ type: SPINNER_TOGGLE_ON });
                await archiveProductProfile(productProfile);
                dispatch(
                  toggleModal({
                    status: 'success',
                    title: 'Archived',
                    CTAHandler: () => history.push(`${path}/archived`)
                  }),
                );
              } catch (e) {
                const error = e as WebErrorType;
                dispatch(
                  toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                  })
                );
              } finally {
                dispatch({ type: SPINNER_TOGGLE_OFF });
              }
            }}
          />
        )
      })
    );
  };

  const onCopy = async (productProfile: TokenService.ProductToken) => {
    try {
      dispatch({ type: SPINNER_TOGGLE_ON });

      const response: { message: string; data: Array<TokenService.ProductToken> } = await callAPI({
        url: API.POST.createToken,
        method: 'POST',
        data: {
          tokens: [{
            type: CONSTANT.ASSETTYPE.PRODUCT,
            tokenId: '',
            details: {
              ...productProfile.details,
              name: `${productProfile.details.name} - Copy`
            }
          }]
        }
      });

      // add to brand
      await addProductToBrand(
        userid,
        productProfile.details.brand.agliveToken,
        response.data[0].externalIds[0].agliveToken
      );

      loadProductProfileTokens();
    } catch (e) {
      const error = e as WebErrorType;

      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({ type: SPINNER_TOGGLE_OFF });
    }
  };
  
  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label="Search Product Name"
          />
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <MyButton
            text={'Create New'}
            variant="contained"
            width={160}
            fontSize={18}
            onClick={() => history.push(`${path}/new`)}
          />
        </Grid>
      </Grid>

      {productProfiles && (
        !!productProfiles.length ? (
          <>
            <MyTable
              firstColumnWidth={classes.firstColumnWidth}
              heads={tableHeaders}
              rows={productProfiles
                ?.filter(profile => !profile.details.archive)
                .filter(profile => profile.details.name.toLowerCase().includes(query.toLowerCase()))
                .map(profile => [
                  <Link
                    to={{
                      pathname: `${path}/view/${profile.externalIds[0].agliveToken}`
                    }}
                    className={classes.hyperlink}>
                    {profile.details.name}
                  </Link>,
                  moment(profile.externalIds[0].timestamp).format('DD/MM/YYYY'),
                  <Button onClick={() => toggleQRCodeModal(
                    dispatch,
                    history,
                    {
                      status: '',
                      title: 'Preview',
                      QRCodeContent: CONSTANT.SCAN_URL(profile.externalIds[0].agliveToken),
                    }
                  )}>
                    <img src={GreenQRCodeIcon} alt='QR Code Icon' />
                    <span
                      style={{
                        color: COLOR.GREENT_TEXT,
                        textTransform: 'capitalize',
                        marginLeft: '8px',
                      }}>
                      <u>Preview</u>
                    </span>
                  </Button>,
                  <div style={{display: 'flex', gap: 20, width: 'fit-content'}}>
                    <div>
                      <EditIcon
                        style={{cursor: 'pointer'}}
                        onClick={() =>
                          history.push(`${path}/edit/${profile.externalIds[0].agliveToken}`)
                        }
                      />
                    </div>
                    <div>
                      <FileCopyIcon
                        onClick={() => onCopy(profile)}
                        style={{cursor: 'pointer'}}
                      />
                    </div>
                    <div>
                      <img
                        src={ArchiveIcon}
                        width="27px"
                        height="27px"
                        style={{cursor: 'pointer'}}
                        onClick={() => onArchive(profile)}
                        alt="Archive Promotion"
                      />
                    </div>
                  </div>,
                ])
              }
            />

            <Grid item style={{marginTop: 20}}>
              <Link to={`${path}/archived`} className={classes.hyperlink}>
                Archived Product Profiles
              </Link>
            </Grid>
          </>) : (
            <MainLanding />
          )
      )}
    </>
  );
};

export default withHeader(
  {
    title: 'Product Profiles',
    margin: 40
  },
  ProductProfileLibrary
);
