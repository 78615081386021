import React, {useState, useEffect} from 'react';
import withHeader from '../../presentation/withHeader';
import {
  Grid,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Link,
} from '@material-ui/core';
import CSVUploader from '../../presentation/CSVUploader';
import {HyperLink} from '../../presentation/word';
import MyButton from '../../presentation/button';
import {
  AssetDialogMessage,
  cleanSelectedFile,
  assetStyles,
} from '../asset/Components';
import CONSTANT from '../../config/constant';
import {
  validateWithSchema,
  JSONSchemaValidationError,
} from '../../utils/csvReader';
import {
  ValidationErrorType,
  NetworkError,
  handleUploadErrors,
} from '../../utils/error';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import {createTokensByCSV} from '../../store/asset/actions';
import COLOR from '../../styled/colors';
import {Line} from '../../presentation/Line';
import SearchBar from '../../presentation/SearchBar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {deleteMob, getMobs} from '../../store/angus/actions';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {useHistory} from 'react-router-dom';
import {Buttons} from '../../presentation/ButtonsGroup';

const uniqueIdTypes = ['animal_rfid'];

export const useStyles = makeStyles((theme) => ({
  subTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
    paddingBottom: '10px',
  },
  searchBarContainer: {
    flexGrow: 1,
    marginTop: 20,
    marginBottom: 20,
    maxWidth: 350,
  },
  greyBox: {
    backgroundColor: COLOR.GRAY_SOLID,
  },
  link: {
    color: COLOR.GREEN_BUTTON,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  cursor: {
    cursor: 'pointer',
  },
}));

const RecordAnimals: React.FC<{Props}> = (props) => {
  const classes = {...assetStyles(), ...useStyles()};
  const [file, setFile] = useState(null);
  const [csvData, setCSVData] = useState([]);
  const history = useHistory();
  const [validationResult, setValidationResult] = useState({
    type: null as string,
    validationError: [] as Array<JSONSchemaValidationError>,
  });
  const [open, setOpen] = useState(false);
  const [mobs, setMobs] = useState([]);
  const [query, setQuery] = useState('');
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.auth.wallet);
  const [checked, setChecked] = useState({
    registed: false,
    declared: false,
    signed: false,
  });
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const isAnimalBusiness = businessProfile.industryType == 'ANIMALS';
  const userProfileData = useAppSelector((state) => state.user.userProfileData);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({...checked, [event.target.name]: event.target.checked});
  };

  const {registed, declared, signed} = checked;
  const noOfCheckedBoxes = [registed, declared, signed].filter((v) => v).length;

  useEffect(() => {
    dispatch(getMobs()).then((resp) => setMobs(resp));
  }, []);

  useEffect(() => {
    setChecked({
      registed: false,
      declared: false,
      signed: false,
    });
  }, [file]);

  function ShowDeclaration() {
    if (csvData.length) {
      return (
        <Grid container>
          <FormControl component="fieldset">
            <Typography variant="h3" className={classes.subTitle}>
              Declaration
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={registed}
                    onChange={handleChange}
                    color="primary"
                    name="registed"
                  />
                }
                label={
                  <div>
                    <span>
                      I declare that all Angus animals uploaded to the Angus
                      Verified program meet all requirements of the{' '}
                    </span>
                    <Link
                      href="https://www.angusaustralia.com.au/content/uploads/2019/04/VBAB-Guide100419.pdf"
                      target="_blank">
                      Black Angus Cattle Assessment Guidelines
                    </Link>
                  </div>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={declared}
                    onChange={handleChange}
                    color="primary"
                    name="declared"
                  />
                }
                label="I declare this information to be true and correct and now submit it to Angus Australia"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={signed}
                    onChange={handleChange}
                    color="primary"
                    name="signed"
                  />
                }
                label="Apply Signature"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      );
    }
    return null;
  }

  const createDate = (mob) => {
    return (
      new Date(mob.externalIds[0].timestamp).getDate() +
      '-' +
      (new Date(mob.externalIds[0].timestamp).getMonth() + 1)
        .toString()
        .padStart(2, '0') +
      '-' +
      new Date(mob.externalIds[0].timestamp).getFullYear()
    );
  };

  const createMobsList = () => {
    let mobList = [];
    mobs.forEach((mob, idx) => {
      const createdDate = createDate(mob);
      const mob2 = {
        ...mob,
        details: {
          ...mob.details,
          created_on: createdDate,
          externalIds: mob.externalIds,
        },
      };
      mobList.push({
        nameText: mob.details.mob_name,
        name: (
          <Link
            className={classes.link}
            onClick={() => {
              history.push(`/private/verified/recordAnimals/mob-details`, {
                details: mob2,
              });
            }}>
            {mob.details.mob_name}
          </Link>
        ),
        pic: mob.details.pic,
        animalCount: mob.details.calves.add.length,
        date: createdDate,
        edit: (
          <>
            <EditIcon
              className={classes.cursor}
              onClick={() => {
                history.push(`/private/verified/recordAnimals/mob-details`, {
                  details: mob2,
                });
              }}
            />
            {mob.details.calves.add.length === 0 && (
              <DeleteIcon
                className={classes.cursor}
                onClick={() => {
                  dispatch(
                    toggleModal({
                      status: 'warning',
                      title: 'Delete Mob?',
                      subtitle:
                        'This action cannot be undone. Ungrouped animals remain in your account',
                      renderButton: (
                        <Buttons
                          leftButtonTitle="No"
                          rightButtonTitle="Yes"
                          leftButtonOnClick={() => {
                            dispatch(toggleModalOff());
                          }}
                          rightButtonOnClick={() => {
                            dispatch(toggleModalOff());
                            dispatch(
                              deleteMob(
                                userId,
                                {agliveToken: mob.externalIds[0].agliveToken},
                                () => {
                                  let mobCopy = JSON.parse(
                                    JSON.stringify(mobs),
                                  );
                                  delete mobCopy[idx];
                                  setMobs(mobCopy);
                                },
                              ),
                            );
                          }}
                        />
                      ),
                    }),
                  );
                }}
              />
            )}
          </>
        ),
      });
    });
    return mobList;
  };

  const header = [
    {
      name: 'Mob Name',
      pic: 'PIC Number',
      animalCount: 'Number of Animals',
      date: 'Date Created',
      edit: 'Edit',
    },
  ];

  const rows = createMobsList();

  return (
    <>
      <CSVUploader file={file} setFile={setFile} setCSVData={setCSVData} />
      <Grid
        container
        justify="flex-end"
        style={{maxWidth: window.innerWidth / 2}}>
        <Grid item className={classes.linksContainer}>
          <HyperLink
            className={classes.linksBetween}
            href={CONSTANT.ASSETINDUCTION.RECORDANIMALS.CSVTEMP}>
            Download CSV Template
          </HyperLink>
          <HyperLink href={CONSTANT.ASSETINDUCTION.RECORDANIMALS.CSVSAMPLE}>
            Download Example and Instructions
          </HyperLink>
        </Grid>
      </Grid>

      <ShowDeclaration />

      <Grid container justify="flex-end">
        <MyButton
          disabled={csvData.length && noOfCheckedBoxes === 3 ? false : true}
          buttonClass={classes.saveButton}
          text={'Save'}
          variant="contained"
          onClick={() => {
            validateWithSchema(
              csvData,
              CONSTANT.ASSETINDUCTION.RECORDANIMALS.SCHEMA,
              true,
              uniqueIdTypes,
            )
              .then(() => {
                setValidationResult({type: null, validationError: []});
                setCSVData((prevCSVEntries) =>
                  prevCSVEntries.map((CSVEntry) => {
                    CSVEntry.externalIds = uniqueIdTypes.reduce(
                      (accumIDs, curID) => ({
                        ...accumIDs,
                        [curID]: CSVEntry[curID],
                      }),
                      {},
                    );
                    uniqueIdTypes.map((id) => delete CSVEntry[id]);

                    return CSVEntry;
                  }),
                );
              })
              .catch((error) => {
                const e = error as ValidationErrorType<
                  Array<JSONSchemaValidationError>
                >;
                setValidationResult({
                  type: e.type,
                  validationError: e.error,
                });
              });
            setOpen(true);
          }}
        />
      </Grid>
      <Grid container>
        <Typography variant="h3">Existing Mobs</Typography>
      </Grid>
      <Line />

      <Grid container>
        <Grid className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label="Search by Mob Name"
          />
        </Grid>

        <TableContainer>
          <Table>
            <TableBody>
              {header.map((cell) => (
                <TableRow key={cell.name} className={classes.greyBox}>
                  <TableCell align="left" style={{fontWeight: 700}}>
                    {cell.name}
                  </TableCell>
                  <TableCell align="center" style={{fontWeight: 700}}>
                    {cell.pic}
                  </TableCell>
                  <TableCell align="center" style={{fontWeight: 700}}>
                    {cell.animalCount}
                  </TableCell>
                  <TableCell align="center" style={{fontWeight: 700}}>
                    {cell.date}
                  </TableCell>
                  <TableCell align="center" style={{fontWeight: 700}}>
                    {cell.edit}
                  </TableCell>
                </TableRow>
              ))}
              {rows
                .filter((row) =>
                  row.nameText
                    .toLowerCase()
                    .includes(query.toLocaleLowerCase()),
                )
                .map((row) => (
                  <TableRow key={row.name}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="center">{row.pic}</TableCell>
                    <TableCell align="center">{row.animalCount}</TableCell>
                    <TableCell align="center">{row.date}</TableCell>
                    <TableCell align="center">{row.edit}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {open && (
        <AssetDialogMessage
          open={open}
          isErr={validationResult.type ? true : false}
          fileName={file.name}
          handleClose={() => {
            cleanSelectedFile('csvReader');
            setFile('');
            setCSVData([]);
            setValidationResult({type: null, validationError: []});
            setOpen(false);
          }}
          validationResult={validationResult}
          rightButtonClick={() => {
            setOpen(false);
            dispatch(
              createTokensByCSV(
                {
                  template: CONSTANT.ASSETINDUCTION.RECORDANIMALS.TEMPLATE,
                  accountId: isAnimalBusiness
                    ? businessProfile.angusProfile.acctID
                    : undefined,
                  angusAuthToken: isAnimalBusiness
                    ? businessProfile.angusProfile.angusAuthToken
                    : undefined,
                  type: CONSTANT.ASSETINDUCTION.RECORDANIMALS.TYPE,
                  nlisUserId: businessProfile.location[0].NLISUserID,
                  nlisPassword: businessProfile.location[0].NLISPassword,
                  nlisEmail: businessProfile.users[0].email,
                  file,
                  csvData,
                  businessId: userProfileData.businessId,
                },
                () => dispatch(getMobs()).then((resp) => setMobs(resp)),
                "You'll get NLIS emails when using Angus Verified. Click the 'Opt-Out' link in one of these emails.",
              ),
            )
              .then(() => {
                cleanSelectedFile('csvReader');
                setFile('');
              })
              .catch((error) => {
                cleanSelectedFile('csvReader');
                setFile('');
                if (error instanceof NetworkError) {
                  const processedError = handleUploadErrors(
                    error,
                    csvData.length,
                    'animals',
                  );
                  dispatch(
                    toggleModal({
                      status: processedError.status,
                      title: processedError.title ? processedError.title : '',
                      subtitle: processedError.subtitle
                        ? processedError.subtitle
                        : '',
                      errorInfo: processedError.errorInfo
                        ? processedError.errorInfo
                        : '',
                    }),
                  );
                } else {
                  dispatch(
                    toggleModal({
                      status: 'failed',
                      title: error.title,
                      subtitle: error.message,
                    }),
                  );
                }
              });
          }}
          rightButtonClickIsErr={() => {
            cleanSelectedFile('csvReader');
            setFile('');
            setCSVData([]);
            setValidationResult({type: null, validationError: []});
            setOpen(false);
          }}
          csvData={csvData}
        />
      )}
    </>
  );
};

export default withHeader(
  {
    title: 'Record Animals',
    margin: 60,
    back: true,
  },
  RecordAnimals,
);
