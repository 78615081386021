import {useEffect, useState} from 'react';

import {getSiteOptions} from '../../store/site/actions';
import {useAppDispatch} from '../../utils/hooks';

const useSiteOptions = () => {
  const dispatch = useAppDispatch();

  const [regions, setRegions] = useState<string[]>([]);
  const [propertyTypes, setPropertyTypes] = useState<string[]>([]);

  useEffect(() => {
    dispatch(getSiteOptions()).then(({regions, propertyTypes}) => {
      setRegions(regions);
      setPropertyTypes(propertyTypes);
    });
  }, [dispatch]);

  return {regions, propertyTypes};
};

export default useSiteOptions;
