import { makeStyles } from "@material-ui/core";
import COLOR from "../../../../styled/colors";

const useStyles = makeStyles((theme) => ({
  productContainer: {
    marginBottom: 80,
  },
  dropdownContainer: {
    marginRight: 50, 
    width: 500
  },
  buttonContainer: {
    alignSelf: 'center',
  },
  buttonGrid: {
    width: "100%",
    marginTop: 170,
  },
  hyperlink: {
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    color: COLOR.GREENT_TEXT,
  },
}));

export default useStyles;