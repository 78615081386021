// Action Types
export const FETCH_ASSET_PROFILE = 'FETCH_ASSET_PROFILE';
export const STORE_TEMP_ASSET_PROFILE = 'STORE_TEMP_ASSET_PROFILE';
export const REMOVE_TEMP_ASSET_PROFILE = 'REMOVE_TEMP_ASSET_PROFILE';

// Action types
interface FetchAssetProfileAction {
  type: typeof FETCH_ASSET_PROFILE;
  payload: RawAssetProfile[];
}

interface StoreTempAssetProfileAction {
  type: typeof STORE_TEMP_ASSET_PROFILE;
  payload: RawAssetProfile;
}

interface RemoveTempAssetProfileAction {
  type: typeof REMOVE_TEMP_ASSET_PROFILE;
}

export type AssetProfileActions = FetchAssetProfileAction | StoreTempAssetProfileAction | RemoveTempAssetProfileAction;
// Raw Asset Profile type returned from getTokenByExternalIds API
export interface RawAssetProfile {
  externalIds: Array<{
    agliveToken: string;
    timestamp: string;
  }>;
  status: Array<string>;
  type: string;
  tokenId: string;
  details: AssetProfileDetails;
  userId: string;
  hash: string;
  activities: Array<{
    type: string;
    userId: string;
    createdAt: string;
    hash: string;
  }>;
}

export interface AssetProfileDetails extends AssetProfilePage {
  assetName: string;
  createdBy?: string;
  archived: boolean;
}

/**
 * Asset Profile Page related types, taken from Product types
 * TODO: Probably could combine the types here with the Product types
 */
export interface AssetProfilePage {
  mainDisplay: MainDisplay;
  content: Array<AdditionalContent>;
  attachment: Array<Attachment>;
}

export interface MainDisplay {
  item: 'video' | 'image' | '';
  url: string; // URL of the Image uploaded to S3 or the Video URL
  permitNumber: string;
  permitID?: string;
  species: string;
  strain: string;
  subStrain: string;
}

export interface AdditionalContent {
  subheading: string;
  content: string;
}

export interface Attachment {
  title: string;
  url: string; // URL after uploading to s3
}

