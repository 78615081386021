import BeyondBlue from "../../img/health-organization/BeyondBlue.png";
import Lifeline from "../../img/health-organization/Lifeline.png";
import RuralAids from "../../img/health-organization/RuralAids.png";
import CanadaSuicidePrevention from "../../img/health-organization/CanadaSuicidePrevention.png";

export const healthSupportConfig = {
  Australia: [
    {
      logo: BeyondBlue,
      name: 'Beyond Blue',
      phoneNumber: '1300 22 4636',
      url: 'https://www.beyondblue.org.au/'
    },
    {
      logo: Lifeline,
      name: 'Lifeline',
      phoneNumber: '13 11 14',
      url: 'https://www.lifeline.org.au/'
    },
    {
      logo: RuralAids,
      name: 'Rural Aid',
      phoneNumber: '1300 327 624',
      url: 'https://www.ruralaid.org.au/'
    },
  ],
  Canada: [
    {
      logo: CanadaSuicidePrevention,
      name: 'Canada Suicide Prevention Service',
      phoneNumber: '1833 456 4566',
      url: 'https://www.dcogt.com/'
    },
  ],
};
