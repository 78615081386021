import React from 'react';
import {Redirect, useHistory, useLocation, useParams} from 'react-router-dom';
import {Grid, Typography} from '@material-ui/core';

import {ManageUserType} from '../../store/add/types';
import {useAppSelector} from '../../utils/hooks';
import CONSTANT from '../../config/constant';
import {PageHeader} from '../../presentation/withHeader';
import MyButton from '../../presentation/button';
import {CASL} from '@aglive/frontend-core';

const ViewUser: React.FC = () => {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const userList: Array<ManageUserType> = useAppSelector(
    (state) => state.add.users,
  );
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );

  const viewUserLabels =
    businessProfile.businessCountry === 'Argentina'
      ? CONSTANT.ES_LABELS
      : CONSTANT.EN_LABELS;
  const location = useLocation<string>();
  const originStatus = location.state ? Object.values(location.state)[0] : '';
  const targetProjectIndex =
    originStatus === 'Pending'
      ? userList.findIndex((project) => project.invitationCode === id)
      : userList.findIndex((project) => project.userId === id);
  const user = userList[targetProjectIndex];
  const userInfo = {
    name: user?.name,
    role: user?.role,
    phone: user?.phone,
    email: user?.email,
    status: originStatus as string,
  };

  const getUserRole = (role: string) => {
    switch (role) {
      case 'admin':
        return viewUserLabels.admin;
      case 'read-only':
        return viewUserLabels.readOnly;
      case 'user':
      default:
        return viewUserLabels.user;
    }
  };

  const dataToRender = [
    {label: viewUserLabels.name, value: userInfo.name},
    {label: viewUserLabels.role, value: getUserRole(userInfo.role)},
    {label: viewUserLabels.phone, value: userInfo.phone},
    {label: viewUserLabels.emailAddress, value: userInfo.email},
    {
      label: viewUserLabels.status,
      value: viewUserLabels[userInfo.status.toLowerCase()],
    },
  ];

  // Redirect user back to UserLibrary if product could not be found
  if (!user) return <Redirect to="/private/add/users" />;

  const ViewUserContent = (
    <Grid container>
      <Grid item container>
        {dataToRender.map((data) => (
          <Grid item xs={6} style={{marginBottom: 60}}>
            <Typography
              variant="body2"
              role="label"
              style={{fontWeight: 700}}
              gutterBottom>
              {data.label}
            </Typography>
            <Typography variant="body2" role="label">
              {data.value}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <CASL.Can I="update" a="user">
        <Grid
          item
          container
          justify="flex-end"
          style={{position: 'absolute', bottom: 30, right: 100}}>
          <MyButton
            text="Edit"
            variant="contained"
            onClick={() =>
              history.push({
                pathname: `/private/add/users/${id}`,
                state: {status: originStatus},
              })
            }
          />
        </Grid>
      </CASL.Can>
    </Grid>
  );

  return (
    <PageHeader
      config={{
        title: viewUserLabels.viewUser,
        margin: 30,
        back: true,
      }}>
      {ViewUserContent}
    </PageHeader>
  );
};

export default ViewUser;
