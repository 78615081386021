import React, { useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import COLOR from '../../../../styled/colors';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss'
import { TokenService } from '@aglive/data-model';

type displayTypes = 
  TokenService.BrandToken["details"]["aboutUs"]["theme"] &
  {
    onMainChange: (colorStr: string) => void;
    onHeadingChange: (colorStr: string) => void;
  }

const CreateAboutUsTabTheme: React.FC<displayTypes> = ({
  main,
  heading,

  onMainChange,
  onHeadingChange
}) => {
  const [displayColorPicker,setDisplayColorPicker]=useState(false);
  const [displayColorPicker2,setDisplayColorPicker2]=useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  const handleChange = (color: { hex: string }) => {
    onMainChange(color.hex);
  };

  const handleClick2 = () => {
    setDisplayColorPicker2(!displayColorPicker2);
  };
  const handleClose2 = () => {
    setDisplayColorPicker2(false);
  };
  const handleChange2 = (color: { hex: string }) => {
    onHeadingChange(color.hex);
  };

  const styles = reactCSS({
    'default': {
      color: {
        width: '48px',
        height: '32px',
        background: main,
      },
      color2: {
        width: '48px',
        height: '32px',
        background: heading,
      },
      swatch: {
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });
  return (
    <Grid item container direction="column" >
      <Grid item container direction="column" style={{backgroundColor:COLOR.WHITE}}>
        <Grid item container style={{marginBottom:131}}>
          <Grid item xs={6} container direction="column" alignItems="center">         
            <div style={{marginTop:48,marginBottom:8}}>
            < Typography variant="h6" role="label" style={{marginBottom:8, fontWeight: 600}}>
                Main
            </Typography>
              <Grid item xs={6} container direction="column" style={{ border: `1px solid ${COLOR.GRAY_BORDER}`,width: '250px',height: '32px',}}>
                <div>
                  <div style={ styles.swatch } onClick={ handleClick }>
                    <div style={ styles.color } />
                  </div>
                  { displayColorPicker ? <div style={ styles.popover }>
                    <div style={ styles.cover } onClick={ handleClose }/>
                    <SketchPicker color={ main } onChange={ handleChange } />
                  </div> : null }
                </div>
                < Typography variant="h6" role="label" >
                  {main}
                </Typography>
              </Grid>            
            </div>
          </Grid>
          <Grid item xs={6} container direction="column" alignItems="center">         
            <div style={{marginTop:48,marginBottom:8}}>
            < Typography variant="h6" role="label" style={{marginBottom:8, fontWeight: 600}}>
                Heading
            </Typography>
            <Grid item xs={6} container direction="column" style={{ border: `1px solid ${COLOR.GRAY_BORDER}`,width: '250px',height: '32px',}}>
                <div>
                  <div style={ styles.swatch } onClick={ handleClick2 }>
                    <div style={ styles.color2 } />
                  </div>
                  { displayColorPicker2 ? <div style={ styles.popover }>
                    <div style={ styles.cover } onClick={ handleClose2 }/>
                    <SketchPicker color={ heading } onChange={ handleChange2 } />
                  </div> : null }
                </div>
                < Typography variant="h6" role="label" >
                  {heading}
                </Typography>
              </Grid>  
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateAboutUsTabTheme;