import withHeader from '../../presentation/withHeader';
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import RadioButtonGroup from '../../presentation/RadioButtonGroup';
import TextField from '@material-ui/core/TextField';
import MyButton from '../../presentation/button';
import { assetStyles } from '../asset/Components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import InputMask from 'react-input-mask';

const AnimalProfile: React.FC<{}> = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [inputValue, setInputValue] = useState('');
  const [radioSelection, setRadioSelection] = useState('rfid');
  const classes = { ...assetStyles() };
  const handleChange = e => {
    setRadioSelection(e.target.value);  
    setInputValue(''); 
  };

  const radioValues = [
    {
      label: 'Animal RFID',
      value: 'rfid',
    },
    {
      label: 'Animal NLIS ID',
      value: 'nlis',
    },
    {
      label: 'Angus Sire ID',
      value: 'sireID',
    },
  ];
  let firstRadioValues = [];
  let remainderRadioValues = [];
  if (radioSelection === 'rfid') {
    remainderRadioValues = radioValues.splice(1);
    firstRadioValues = radioValues;
  } else if (radioSelection === 'nlis') {
    remainderRadioValues = radioValues.splice(2);
    firstRadioValues = radioValues;
  } else if (radioSelection === 'sireID') {
    remainderRadioValues = radioValues.splice(3);
    firstRadioValues = radioValues;
  }

  const getPlaceholderText = () => {
    if (radioSelection === 'rfid') {
      return 'e.g. 982 xxxxxxxxxxxx';
    } else if (radioSelection === 'nlis') {
      return 'e.g. AB123456ABC01234';
    } else if (radioSelection === 'sireID') {
      return 'e.g. ABCP123';
    }
  }

  return (<>
    <Grid>
      <RadioButtonGroup
        onChange={handleChange}
        title="Search by"
        value={radioSelection}
        options={firstRadioValues}
      />
      {radioSelection === 'rfid' && <Grid item xs={12} sm={6}>   
        <InputMask
          mask={radioSelection === 'rfid' ? "999 999999999999" : ''}
          maskChar={null}
          value={inputValue}
          placeholder={getPlaceholderText()}
          onChange={(e) => setInputValue(e.target.value.toUpperCase())}
          inputProps={{ maxLength: 16, style: { textTransform: 'uppercase' }}}
        >
          {(props) => 
          <TextField 
            {...props}
            fullWidth
            variant="outlined" 
            style={{ marginLeft: 30}}
          />}
        </InputMask>
      </Grid>}

      {radioSelection !== 'rfid' && <Grid item xs={12} sm={6}>   
        <TextField
          fullWidth
          variant="outlined"
          value={inputValue}
          inputProps={{ maxLength: radioSelection === 'sireID' ? 10 : 16, style: { textTransform: 'uppercase' }}}
          placeholder={getPlaceholderText()}
          onChange={(e) => setInputValue(e.target.value.toUpperCase())}
          disabled={false}
          style={{ marginLeft: 30 }}/>
      </Grid>}

      <RadioButtonGroup
        onChange={handleChange}
        title=""
        aria-label="manage-asset"
        name="manageBy"
        value={radioSelection}
        options={remainderRadioValues}
      />

    </Grid>
    <Grid container justify="flex-end" >
      <MyButton
        disabled={radioSelection === 'sireID' ? inputValue.length < 5 : inputValue.length < 16}
        buttonClass={classes.saveButton}
        text={'Next'}
        variant="contained"
        onClick={() => {
          if (radioSelection === 'sireID') {
            history.push(`${path}/view-sire`, { sireID: inputValue });
          } else {
            const idObj = radioSelection === 'rfid' ? { rfid: inputValue } : { nlis: inputValue }
            history.push({ pathname: `${path}/view-animal`, state: idObj });
          }
        }}
      />
    </Grid>

  </>);
};

export default withHeader(
  {
    title: 'Animal Profile',
    margin: 60,
    back: true,
  },
  AnimalProfile,
);