import React from 'react';

import {Grid, Typography} from '@material-ui/core';
import {TokenService} from '@aglive/data-model';
import ReactPlayer from 'react-player';

import COLOR from '../../styled/colors';
import {useStyles} from './styles';
import MockupPhoneNew from '../MockUpPhoneNew';
import agliveLogo from '../../img/agliveLogo.png';
import NewDefaultImage from '../../img/NewDefaultImage.png';
import AngusVerified from '../../img/IconRecordAnimals.svg';
import StartIcon from '../../img/release.png';
import MiddleIcon from '../../img/accept.png';

interface TellYourStoryProps {
  story: TokenService.ProductToken['details']['story'];
  mainSectionImageOption: 'image' | '';
  brandLogo: string | null;
  angusVerified?: boolean;
}

const MockupPhoneTellYourStory: React.FC<TellYourStoryProps> = ({
  story,
  mainSectionImageOption,
  brandLogo,
  angusVerified,
}) => {
  const classes = useStyles();

  return (
    <MockupPhoneNew backgroundColor={COLOR.GRAY_SOLID}>
      <div className={classes.previewContainer}>
        <div className={classes.headerContainer}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column">
            <Grid item className={classes.protectedBy}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}>
                <Grid item>
                  <Typography
                    variant="body2"
                    display="inline"
                    style={{fontWeight: 600}}>
                    Protected by
                  </Typography>
                </Grid>
                <Grid item>
                  <img src={agliveLogo} alt="Aglive Logo" width="70px" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        {angusVerified && (
          <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            className={classes.angusVerifiedContainer}>
            <Grid item>
              <img src={AngusVerified} alt="Angus Verified" width="31.5px" />
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                display="inline"
                className={classes.angusVerifiedText}>
                Angus Verified
              </Typography>
            </Grid>
          </Grid>
        )}

        <div className={classes.sectionContainer}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column">
            {(story?.logoType === 'user' ||
              (story?.logoType === 'brand' && brandLogo)) && (
              <Grid item>
                <div className={classes.imageContainer}>
                  <img
                    src={story?.logoType === 'user'
                      ? story?.logo || NewDefaultImage
                      : brandLogo}
                    alt="Promotion Logo"
                    className={classes.logo}
                  />
                  {!(story?.logo || (story?.logoType === 'brand' && brandLogo)) && (
                    <Typography className={classes.defaultImageText}>
                      Logo
                    </Typography>
                  )}
                </div>
              </Grid>
            )}

            {mainSectionImageOption === 'image' && (
              <Grid item>
                <div className={classes.imageContainer}>
                  <img
                    src={story?.image || NewDefaultImage}
                    alt="Main Section Img"
                    className={classes.mainSectionImg}
                  />
                  {!story?.image && (
                    <Typography className={classes.defaultImageText}>
                      Image
                    </Typography>
                  )}
                </div>
              </Grid>
            )}

            <Grid item>
              <Typography align="center" className={classes.previewDefaultText}>
                {story?.headline[0]}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center" className={classes.previewHeader}>
                {story?.headline[1]}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                align="center"
                className={classes.previewDefaultText}
                gutterBottom={true}>
                {story?.headline[2]}
              </Typography>
            </Grid>

            <Grid item container>
              <Typography
                className={`${classes.previewDefaultText} ${classes.paragraphText}`}>
                {story?.content}
              </Typography>
            </Grid>
          </Grid>
        </div>

        {story?.milestones.length > 0 && (
          <>
            <div className={classes.additionalSectionHeading}>
              <div className={classes.greenMarker}></div>
              <div>
                <Typography className={classes.previewHeader}>
                  Key Milestones
                </Typography>
              </div>
            </div>
            <div className={classes.sectionContainer}>
              <div className={classes.milestonesContainer}>
                {story?.milestones.map((milestone, index) => (
                  <Grid item container key={index}>
                    <Grid
                      item
                      container
                      direction="column"
                      xs={2}
                      style={{paddingTop: index === 0 ? 3 : undefined}}
                      alignItems="center">
                      <Grid item>
                        <img
                          src={index === 0 ? StartIcon : MiddleIcon}
                          alt=""
                          style={{
                            width: index === 0 ? 10 : 8,
                          }}
                        />
                      </Grid>
                      {index < story?.milestones.length - 1 && (
                        <Grid item>
                          <hr className={classes.chainLine} />
                        </Grid>
                      )}
                    </Grid>
                    <Grid item container direction="column" xs={10}>
                      <Grid item>
                        <Typography
                          className={`${classes.previewHeader} ${classes.paragraphText}`}>
                          {milestone.title}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          className={`${classes.previewDefaultText} ${classes.paragraphText} ${classes.milestoneDetails}`}>
                          {milestone.details}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </div>
            </div>
          </>
        )}

        {story?.additional.map((additional, index) => (
          <React.Fragment key={index}>
            <div className={classes.additionalSectionHeading}>
              <div className={classes.greenMarker}></div>
              <div>
                <Typography className={classes.previewHeader}>
                  {additional.title}
                </Typography>
              </div>
            </div>
            <div className={classes.sectionContainer}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column">
                <Grid item container>
                  <Typography
                    className={`${classes.previewDefaultText} ${classes.paragraphText}`}>
                    {additional.content}
                  </Typography>
                </Grid>

                {additional.mediaType === 'image' && (
                  <Grid item>
                    <div className={classes.imageContainer}>
                      <img
                        src={additional.url || NewDefaultImage}
                        alt="Additional Section Img"
                        className={classes.additionalSectionImg}
                      />
                      {!additional.url && (
                        <Typography className={classes.defaultImageText}>
                          Image
                        </Typography>
                      )}
                    </div>
                  </Grid>
                )}
                {additional.mediaType === 'video' && (
                  <Grid item>
                    <div className={classes.imageContainer}>
                      {additional.url && ReactPlayer.canPlay(additional.url) ? (
                        <ReactPlayer
                          url={additional.url}
                          width="100%"
                          height="100%"
                          controls
                        />
                      ) : (
                        <>
                          <img
                            src={NewDefaultImage}
                            alt="Additional Section Video"
                            className={classes.additionalSectionImg}
                          />
                          <Typography className={classes.defaultImageText}>
                            Video
                          </Typography>
                        </>
                      )}
                    </div>
                  </Grid>
                )}
              </Grid>
            </div>
          </React.Fragment>
        ))}
      </div>
    </MockupPhoneNew>
  );
};

export default MockupPhoneTellYourStory;
