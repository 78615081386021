import React, {useState} from 'react';
import withHeader from '../../presentation/withHeader';
import {
  Grid,
  Typography,
  makeStyles,
  TextField,
  MenuItem,
  FormControl,
  Select,
  CardContent,
  Card,
} from '@material-ui/core';
import {ToggleButtonGroup, ToggleButton} from '@material-ui/lab';
import InputLabel from '@material-ui/core/InputLabel';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {assetStyles} from '../asset/Components';
import MyButton from '../../presentation/button';
import OrderTagOne from '../../img/OrderTagOne.png';
import OrderTagTwo from '../../img/OrderTagTwo.png';
import COLOR from '../../styled/colors';
import {Paragraph} from '../../presentation/word';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import ErrorIcon from '@material-ui/icons/Error';
import {orderTags} from '../../store/angus/actions';

export const useStyles = makeStyles((theme) => ({
  root: {},
  PicNumber: {
    maxWidth: '240px',
    minWidth: '240px',
  },
  TagButtonWrapper: {
    display: 'flex',
    width: 'inherit',
  },
  TagButton: {
    border: `1px solid ${COLOR.GRAY_BORDER}`,
    borderRadius: '0',
    '&:first-of-type': {
      marginRight: '2%',
    },
    '&:nth-of-type(2)': {
      borderLeft: `1px solid ${COLOR.GRAY_BORDER}`,
    },
  },
  TagImage: {
    width: '108px',
    height: '140px',
    padding: '18px 60px',
  },
  card: {
    backgroundColor: COLOR.GRAY_SOLID,
    border: `1px solid ${COLOR.GRAY_BORDER}`,
    borderRadius: 0,
    width: '100%',
  },
  Subtitle: {
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
    paddingBottom: '10px',
    marginTop: '30px',
    width: '100%',
  },
  SubtitleNote: {
    color: COLOR.GRAY_TEXT,
    marginTop: '18px',
  },
  redBorder: {
    borderWidth: 3,
    borderColor: 'red',
    '&:nth-of-type(2)': {
      borderLeft: `3px solid red`,
    },
  },
}));

const OrderTags: React.FC<{Props}> = (props) => {
  const dispatch = useAppDispatch();
  const classes = {...assetStyles(), ...useStyles()};
  const [tagSelection, setTagSelection] = useState('tagOne');
  const [selectedPic, setSelectedPic] = useState('');
  const [tagCount, setTagCount] = useState('');
  const [tagStart, setTagStart] = useState('');
  const [tagEnd, setTagEnd] = useState('');
  const handleChange = (e, newTagSelection: null) => {
    setTagSelection(newTagSelection);
  };
  const userId = useAppSelector((state) => state.auth.wallet);
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const isAnimalBusiness = businessProfile.industryType == "ANIMALS"
  const saveDisabled = () => {
    if (tagSelection !== 'tagOne' && tagSelection !== 'tagTwo') {
      return true;
    }
    if (tagSelection === 'tagTwo') {
      return (
        tagCount.length < 1 ||
        selectedPic.length < 2 ||
        tagStart.length < 1 ||
        tagEnd.length < 1
      );
    }
    return tagCount.length < 1 || selectedPic.length < 2;
  };

  const getPICs = () => {
    if (isAnimalBusiness) {
      return businessProfile.angusProfile.properties.map((prop) => prop.picNum);
    } else {
      return []
    }
  };
  const picNumbers = getPICs();

  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Paragraph color={COLOR.BLACK_BG} fontWeight={700} margin={'10px 0'}>
            PIC Number
          </Paragraph>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.PicNumber} variant="outlined">
            <Select
              labelId=""
              id="picNumber"
              value={selectedPic}
              onChange={(event) =>
                setSelectedPic(event.target.value as string)
              }>
              {picNumbers?.map((pic, index) => {
                return (
                  <MenuItem value={pic} key={index}>
                    {pic}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Typography variant="h3">Tag Selection</Typography>
      </Grid>

      <Grid container item xs={12}>
        <ToggleButtonGroup
          className={classes.TagButtonWrapper}
          value={tagSelection}
          onChange={handleChange}
          exclusive={true}>
          <ToggleButton
            value="tagOne"
            className={`classes.TagButton ${
              tagSelection === 'tagOne' ? classes.redBorder : ''
            }`}>
            <img className={classes.TagImage} src={OrderTagOne} alt="" />
          </ToggleButton>
          <ToggleButton
            value="tagTwo"
            className={`classes.TagButton ${
              tagSelection === 'tagTwo' ? classes.redBorder : ''
            }`}>
            <img className={classes.TagImage} src={OrderTagTwo} alt="" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Card className={classes.card}>
            <CardContent>
              <Typography>Size: Female Maxi</Typography>
              <Typography>Colour: Yellow</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Paragraph color={COLOR.BLACK_BG} fontWeight={700} margin={'10px 0'}>
            Number of Tags
          </Paragraph>
          <TextField
            variant="outlined"
            id="tagNumber"
            label=""
            helperText=""
            value={tagCount}
            onChange={(e) => setTagCount(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Paragraph color={COLOR.GRAY_TEXT}>
            The number of tags is limited to the number of Angus Verified
            animals
          </Paragraph>
        </Grid>
      </Grid>

      {tagSelection === 'tagTwo' && (
        <>
          <Grid container item xs={12}>
            <Typography variant="h3" className={classes.Subtitle}>
              Tag Sequence
            </Typography>
            <Paragraph color={COLOR.GRAY_TEXT} className={classes.SubtitleNote}>
              Up to six characters
            </Paragraph>
          </Grid>

          <Grid item xs={6}>
            <Paragraph color={COLOR.BLACK_BG} fontWeight={700} margin={'5px 0'}>
              Start
            </Paragraph>
            <TextField
              variant="outlined"
              id="tagNumber"
              label=""
              helperText=""
              placeholder="e.g. 8001"
              value={tagStart}
              onChange={(e) => setTagStart(e.target.value)}
            />
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Paragraph
                color={COLOR.BLACK_BG}
                fontWeight={700}
                margin={'10px 0'}>
                End
              </Paragraph>
              <TextField
                variant="outlined"
                id="tagNumber"
                label=""
                helperText=""
                placeholder="e.g. 8050"
                value={tagEnd}
                onChange={(e) => setTagEnd(e.target.value)}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Grid container xs={12} item justify="flex-end">
        <MyButton
          onClick={() => {
            const isPlain = tagSelection === 'tagOne';
            const data = {
              acct_id: isAnimalBusiness? businessProfile.angusProfile.acctID: undefined,
              tag_type: isPlain ? 1 : 2,
              tag_num: parseInt(tagCount),
              seq_start: isPlain ? '' : tagStart,
              seq_end: isPlain ? '' : tagEnd,
              angusAuthToken: isAnimalBusiness? businessProfile.angusProfile.angusAuthToken: undefined,
              pic: selectedPic,
            };
            dispatch(orderTags(userId, data))
              .then((resp) => {
                dispatch(
                  toggleModal({
                    status: 'success',
                    title: 'Request Submitted',
                    subtitle: [
                      'Your order form will be',
                      'emailed to you by Angus Australia',
                    ],
                    renderButton: (
                      <>
                        <Grid container item xs={12} justify="center">
                          <Grid
                            justify="center"
                            style={{
                              background: COLOR.ORANGE,
                              padding: 16,
                              paddingTop: 2,
                              paddingBottom: 2,
                              display: 'flex',
                              flexDirection: 'row',
                              marginBottom: 10,
                              maxWidth: 319,
                            }}>
                            <ErrorIcon
                              style={{
                                paddingLeft: 10,
                                paddingTop: 24,
                                fill: 'white',
                              }}
                            />
                            <Paragraph
                              text-align="center"
                              color="white"
                              style={{textAlign: 'center'}}>
                              Take this form to your local reseller to complete
                              your tag order
                            </Paragraph>
                          </Grid>
                          <MyButton
                            onClick={() => {
                              setSelectedPic('');
                              setTagCount('');
                              setTagStart('');
                              setTagEnd('');
                              dispatch(toggleModalOff());
                            }}
                            variant="contained"
                            text={'Close'}
                            marginTop={20}
                          />
                        </Grid>
                      </>
                    ),
                  }),
                );
              })
              .catch((err) => {
                dispatch(
                  toggleModal({
                    status: 'failed',
                    title: err.title ? 'Error' : 'Unexpected Error',
                    subtitle:
                      err.error?.data?.message ??
                      err.message ??
                      'Unexpected Error',
                  }),
                );
              });
          }}
          disabled={saveDisabled()}
          text={'Save'}
          variant="contained"
          buttonClass={classes.saveButton}
        />
      </Grid>
    </Grid>
  );
};

export default withHeader(
  {
    title: 'Order Tags',
    margin: 60,
    back: true,
  },
  OrderTags,
);
