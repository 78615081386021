import * as Sentry from "@sentry/react";
import { IMPORT_LOCATION } from "../location/types";
import { toggleModal } from "../modal/actions";
import { SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON } from "../spinner/types";
import walletConnect from "../../utils/walletConnect";
import { appThunkLogout } from "../auth/actions";
import {
  IJsonRpcRequest,
  IJsonRpcResponseSuccess,
  IJsonRpcResponseError,
} from "@walletconnect/types";
import { WebError } from "../../utils/error";

type TimerId = number;
type goBack = BC_SUCCESS_CONFIG;

interface iRequestPayload {
  method: "aglive_Transaction";
  params: [string, any, TimerId, goBack];
}
interface iFirstRes extends IJsonRpcRequest {
  params: [string, any];
}

interface iSecondRes extends IJsonRpcResponseSuccess {
  id: TimerId;
  result: goBack;
}

export function callRequestHandler(dispatch, history) {
  return (
    error: any,
    payload: iFirstRes | iSecondRes | IJsonRpcResponseError | undefined
  ) => {
    try {
      if (error) throw new WebError('WALLETCONNECT_ERROR', error);
      if (
        payload &&
        "method" in payload &&
        payload.method === "aglive_Transaction"
      ) {
        if (payload.params) {
          // TODO uses 0th item as header
          switch (payload.params[0]) {
            case IMPORT_LOCATION:
              dispatch({
                type: IMPORT_LOCATION,
                payload: payload.params[1],
              });
              break;
            // TODO
            default:
              throw new WebError('WALLETCONNECT_ERROR', payload);
          }
        }
      } else if ("id" in payload && "result" in payload) {
        // TODO legacy code to be removed
        dispatch({ type: SPINNER_TOGGLE_OFF });

        // clear timer initialized in sendCustomRequest
        clearTimeout(payload.id);

        dispatch(
          toggleModal({
            status: "success",
            title: "Created",
            CTAHandler: () => {
              if (typeof payload.result === "boolean") {
                return payload.result ? history.goBack() : history.go(0);
              } else {
                switch (payload.result.type) {
                  case 'code':
                    return payload.result.goBack ? history.goBack() : history.go(0);
                  default:
                    throw new Error('unregconized payload');
                }
              }
            }
          })
        );
      } else if ("error" in payload) {
        // TODO
        dispatch({ type: SPINNER_TOGGLE_OFF });
        dispatch(
          toggleModal({
            status: "failed",
            title: "Rejected",
            subtitle: payload.error?.message,
          })
        );
      }
    } catch (e) {
      dispatch({ type: SPINNER_TOGGLE_OFF });
      if (e.message === "WEBSOCKET_ERROR") {
        dispatch(
          toggleModal({
            status: "failed",
            title: "Failed",
            subtitle: e.message,
            button: "Close",
            CTAHandler: () => {
              dispatch(appThunkLogout());
              window.location.reload();
            },
          })
        );
      }
      dispatch(toggleModal({
        status: 'failed',
        title: e.title,
        subtitle: e.message,
      }));
    }
  };
}

type BC_SUCCESS_CONFIG = boolean | {
  type: 'code';
  goBack: boolean;
};

export function sendCustomRequest(
  dispatch,
  payload: [string, any],
  goBack: BC_SUCCESS_CONFIG = true
) {
  dispatch(
    toggleModal({
      status: "warning",
      title: "Security Verification",
      subtitle: 'Tap "Yes" on the Aglive app to verify',
      button: "Verify Now",
      CTAHandler: () => {
        dispatch({ type: SPINNER_TOGGLE_ON });

        let timeoutID = setTimeout(
          (asyncDispatch) => {
            asyncDispatch({ type: SPINNER_TOGGLE_OFF });

            // disconnect the session and prompt re-login
            Sentry.captureException({
              source: "utils.ts sendCustomRequest",
              error: "Wallet Connection Time Out",
              payload,
            });
            asyncDispatch(
              toggleModal({
                status: "failed",
                title: "Connection lost",
                subtitle: "Please login again to retry.",
                button: "Login Again",
                CTAHandler: () => dispatch(appThunkLogout()),
              })
            );
          },
          1 * 60 * 1000,
          dispatch
        );

        let params = [...payload, timeoutID, goBack];

        walletConnect
          .sendCustomRequest({
            method: "aglive_Transaction",
            params,
          } as iRequestPayload)
          .catch((e) => {
            throw e;
          });
      },
    })
  );
}
