import {TokenService} from '@aglive/data-model';
import produce from 'immer';
import moment from 'moment';

import {INITIAL_STATE} from './productPromotionState/reducer';

export const validateData = (
  productPromotion: TokenService.ProductPromotion['details'],
  footerImageOption: 'image' | '',
  submissionType: 'draft' | 'publish',
): [TokenService.ProductPromotion['details'], number] => {
  let error = 0;
  const validationState = produce(INITIAL_STATE.payload, (draft) => {
    if (!productPromotion.name.trim().length) {
      draft.name = 'This field is required';
      ++error;
    }
    if (!productPromotion.product.agliveToken.trim().length) {
      draft.product.agliveToken = 'This field is required';
      ++error;
    }
    // Display
    if (
      footerImageOption === 'image' &&
      !productPromotion.display.footerImage?.trim().length
    ) {
      draft.display.footerImage = 'This field is required';
      ++error;
    }
    // Only go through start date / end date validations when user publishes the promotion
    if (submissionType === 'publish') {
      if (productPromotion.display.startDate === '') {
        draft.display.startDate = 'This field is required';
        ++error;
      }
      if (productPromotion.display.endDate === '') {
        draft.display.endDate = 'This field is required';
        ++error;
      } else if (
        draft.display.startDate === '' &&
        moment(productPromotion.display.startDate).valueOf() >
          moment(productPromotion.display.endDate).valueOf()
      ) {
        draft.display.endDate = 'End date must be after start date';
        ++error;
      } else if (
        productPromotion.status === 'published' &&
        moment(productPromotion.display.endDate).valueOf() < moment().valueOf()
      ) {
        draft.display.endDate = 'New end date must be in the future';
        ++error;
      }
    }
  });

  return [validationState, error];
};
