import React from "react";

export default function () {
  return (
    <svg
      width="28px"
      height="28px"
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink">
      <title>Icon: Mail</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Dashboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="D-Home" transform="translate(-489.000000, -711.000000)">
          <g id="Login-" transform="translate(431.000000, 298.000000)">
            <g id="Contact:-Mail" transform="translate(58.000000, 413.000000)">
              <g id="Icon:-Mail">
                <circle
                  id="Oval"
                  fill="#373935"
                  fillRule="nonzero"
                  cx="14"
                  cy="14"
                  r="14"></circle>
                <g
                  id="mail_outline-24px"
                  transform="translate(6.000000, 6.000000)">
                  <polygon id="Path" points="0 0 17 0 17 17 0 17"></polygon>
                  <path
                    d="M14.1666667,2.83333333 L2.83333333,2.83333333 C2.05416667,2.83333333 1.42375,3.47083333 1.42375,4.25 L1.41666667,12.75 C1.41666667,13.5291667 2.05416667,14.1666667 2.83333333,14.1666667 L14.1666667,14.1666667 C14.9458333,14.1666667 15.5833333,13.5291667 15.5833333,12.75 L15.5833333,4.25 C15.5833333,3.47083333 14.9458333,2.83333333 14.1666667,2.83333333 Z M14.1666667,12.75 L2.83333333,12.75 L2.83333333,5.66666667 L8.5,9.20833333 L14.1666667,5.66666667 L14.1666667,12.75 Z M8.5,7.79166667 L2.83333333,4.25 L14.1666667,4.25 L8.5,7.79166667 Z"
                    id="Shape"
                    fill="#FFFFFF"
                    fillRule="nonzero"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
