import {makeStyles} from '@material-ui/core';

import COLOR from '../../../styled/colors';

export const useStyles = makeStyles((theme) => ({
  startProjectHeading: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '32px',
    color: COLOR.GRAY_TEXT,
    fontWeight: 700,
  },
  startProjectDesc: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    color: COLOR.GRAY_TEXT,
  },
  landingContainer: {
    border: `1px solid ${COLOR.GRAY_BORDER}`,
    borderRadius: '24px',
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  landingContentContainer: {
    marginBottom: theme.spacing(3),
  },
  landingContentSubheader: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: '20px',
  },
  promotionImage: {
    maxHeight: '500px',
    objectFit: 'contain',
  },
  caseStudyContainer: {
    backgroundColor: COLOR.GRAY_SOLID,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  caseStudyDetails: {
    padding: theme.spacing(0, 2),
  },
  caseStudyLabel: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 700,
    fontSize: theme.typography.fontSize,
  },
  button: {
    marginTop: theme.spacing(4),
    height: 60,
  },
}));
