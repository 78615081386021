import {TokenService} from '@aglive/data-model';
import {
  TellYourStoryAdditionalAddEntryAction,
  TellYourStoryAdditionalContentAction,
  TellYourStoryAdditionalMediaTypeAction,
  TellYourStoryAdditionalUrlAction,
  TellYourStoryAdditionalTitleAction,
  TellYourStoryContentAction,
  TellYourStoryHeadlineAction,
  TellYourStoryImageAction,
  TellYourStoryLogoAction,
  TellYourStoryLogoTypeAction,
  TellYourStoryAdditionalDeleteEntryAction,
  TellYourStoryAdditionalDupEntryAction,
  TellYourStoryMilestonesAddEntryAction,
  TellYourStoryMilestonesDeleteEntryAction,
  TellYourStoryMilestonesDupEntryAction,
  TellYourStoryMilestonesTitleAction,
  TellYourStoryMilestonesDetailsAction,
  TellYourStoryTabAction,
} from './types';

const changeTab = (value: number): TellYourStoryTabAction => ({
  type: 'TYS/select_tab',
  payload: value,
})

// Main Section
const editLogo = (value: string): TellYourStoryLogoAction => ({
  type: 'TYS/logo',
  payload: value,
});

const editLogoType = (
  value: TokenService.ProductToken['details']['story']['logoType'],
): TellYourStoryLogoTypeAction => ({
  type: 'TYS/logo_type',
  payload: value,
});

const editImage = (value: string): TellYourStoryImageAction => ({
  type: 'TYS/image',
  payload: value,
});

const editHeadline = (
  headlineIndex: number,
  value: string,
): TellYourStoryHeadlineAction => ({
  type: 'TYS/headline',
  payload: {
    headlineIndex,
    value,
  },
});

const editContent = (value: string): TellYourStoryContentAction => ({
  type: 'TYS/content',
  payload: value,
});

// Milestones
const addMilestonesEntry = (): TellYourStoryMilestonesAddEntryAction => ({
  type: 'TYS/milestones_add_entry',
});

const deleteMilestonesEntry = (
  index: number,
): TellYourStoryMilestonesDeleteEntryAction => ({
  type: 'TYS/milestones_delete_entry',
  payload: index,
});

const dupMilestonesEntry = (
  index: number,
): TellYourStoryMilestonesDupEntryAction => ({
  type: 'TYS/milestones_dup_entry',
  payload: index,
});

const editMilestonesTitle = (
  index: number,
  value: string,
): TellYourStoryMilestonesTitleAction => ({
  type: 'TYS/milestones_title',
  payload: {
    index,
    value,
  },
});

const editMilestonesDetails = (
  index: number,
  value: string,
): TellYourStoryMilestonesDetailsAction => ({
  type: 'TYS/milestones_details',
  payload: {
    index,
    value,
  },
});

// Additional Sections
const addAdditionalEntry = (): TellYourStoryAdditionalAddEntryAction => ({
  type: 'TYS/additional_add_entry',
});

const deleteAdditionalEntry = (
  index: number,
): TellYourStoryAdditionalDeleteEntryAction => ({
  type: 'TYS/additional_delete_entry',
  payload: index,
});

const dupAdditionalEntry = (
  index: number,
): TellYourStoryAdditionalDupEntryAction => ({
  type: 'TYS/additional_dup_entry',
  payload: index,
});

const editAdditionalTitle = (
  index: number,
  value: string,
): TellYourStoryAdditionalTitleAction => ({
  type: 'TYS/additional_title',
  payload: {
    index,
    value,
  },
});

const editAdditionalContent = (
  index: number,
  value: string,
): TellYourStoryAdditionalContentAction => ({
  type: 'TYS/additional_content',
  payload: {
    index,
    value,
  },
});

const editAdditionalMediaType = (
  index: number,
  value: TokenService.ProductToken['details']['story']['additional'][number]['mediaType'],
): TellYourStoryAdditionalMediaTypeAction => ({
  type: 'TYS/additional_media_type',
  payload: {
    index,
    value,
  },
});

const editAdditionalUrl = (
  index: number,
  value: string,
): TellYourStoryAdditionalUrlAction => ({
  type: 'TYS/additional_url',
  payload: {
    index,
    value,
  },
});

export default {
  changeTab,
  editLogo,
  editLogoType,
  editImage,
  editHeadline,
  editContent,
  addMilestonesEntry,
  deleteMilestonesEntry,
  dupMilestonesEntry,
  editMilestonesTitle,
  editMilestonesDetails,
  addAdditionalEntry,
  deleteAdditionalEntry,
  dupAdditionalEntry,
  editAdditionalTitle,
  editAdditionalContent,
  editAdditionalMediaType,
  editAdditionalUrl,
};
