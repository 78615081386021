import Paper from '@material-ui/core/Paper';
import {
  Grid as GridTable,
  TableHeaderRow,
  Table,
  PagingPanel
} from '@devexpress/dx-react-grid-material-ui';
import {Box, Grid, Typography} from '@material-ui/core';
import withHeader from '../../presentation/withHeader';
import MyButton from '../../presentation/button';
import {useReport} from './ReportBusinessObject';
import { OuterFilterFields } from './OuterFilterFields';
import { CustomPaging, PagingState } from '@devexpress/dx-react-grid';

const CeresTagReport = () => {
  const report = useReport('ceres');

  return (
    <>
      <Grid container justify="space-between">
        <Grid item style={{marginBottom: 10}}>
          <Typography variant="h3" role="label">
            Select Report
          </Typography>
        </Grid>
        <Grid
          item
          container
          spacing={3}
          alignContent="space-between"
          style={{marginBottom: 30}}>
          <Grid
          container
          item
          justifyContent="flex-start"
          style={{marginTop: 20}}
          spacing={4}>
          {report.formMap[0].outerFilterFields?.map((field) => {
            return <OuterFilterFields
              state={report.state.outerFilter}
              optionalState={report} // for multiselect dataSet use => report.locationPicAddr
              setState={report.updateOuterFilter}
              errorState={report.state.errorState}
              field={field}
              key={field.key}
              customItem={report.localizedPIC}
            />
          })}
          </Grid>
        </Grid>
        <Grid item container justify="flex-end" spacing={3}>
          <Grid item>
            <MyButton
              disabled={!report.state.outerFilter.locations?.length}
              onClick={report.handleClickGenerate}
              text="Preview Report"
              variant="contained"
            />
          </Grid>
          <Grid item>
            <MyButton
              disabled={!report.state.outerFilter.locations?.length}
              onClick={report.fetchReport({download: true})}
              text="Download CSV"
              variant="contained"
            />
          </Grid>
        </Grid>
      </Grid>
      <Box mt={3} style={{maxWidth: '100%', position: 'relative', zIndex: 0}}>
        {report.state.rows && report.columns && 
        <Paper>
          <GridTable rows={report.state.rows} columns={report.columns}>
            <Table columnExtensions={report.columnExtensions} />
            <PagingState
              currentPage={report.state.currentPage}
              onCurrentPageChange={report.changePage}
              pageSize={report.state.pageSize}
              onPageSizeChange={report.changePageSize}
            />
            <CustomPaging totalCount={report.state.totalData} />
            <PagingPanel pageSizes={[5, 10, 15, 20]} />
            <TableHeaderRow />
          </GridTable>
        </Paper>
        }
      </Box>
    </>
  );
};

export default withHeader(
  {
    title: 'Ceres Tag Report',
    margin: 40,
  },
  CeresTagReport,
);