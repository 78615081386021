import {
  RawAssetProfile,
  FETCH_ASSET_PROFILE,
  STORE_TEMP_ASSET_PROFILE,
  REMOVE_TEMP_ASSET_PROFILE,
  AssetProfileActions
} from './types';

const initialState: {
  fetchedAssetProfiles: RawAssetProfile[];
  tempAssetProfile?: RawAssetProfile;
} = {
  fetchedAssetProfiles: [],
};

const assetProfileReducer = (state = initialState, action: AssetProfileActions) => {
  switch (action.type) {
    case FETCH_ASSET_PROFILE:
      return {...state, fetchedAssetProfiles: action.payload};
    case STORE_TEMP_ASSET_PROFILE:
      return {...state, tempAssetProfile: action.payload};
    case REMOVE_TEMP_ASSET_PROFILE:
      return {fetchedAssetProfiles: state.fetchedAssetProfiles};
    default:
      return state;
  }
};

export default assetProfileReducer;
