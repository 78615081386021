import API from '../../config/api';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import {AppThunk} from '../../store/types';
import {toggleModal} from '../modal/actions';
import {callAPI} from '../../utils/network';
import {WebErrorType} from '../../utils/error';
import {
  ManageUserType,
  ConsigneeTypes,
  ConsignmentTypes,
  FETCH_CONSIGNMENT_DATA,
  CREATE_USER,
  FETCH_USERS,
  POST_CONSIGNEE_DATA,
  FETCH_CONSIGNEE_DATA,
  FETCH_CERESTAG_DATA,
  REMOVE_CERESTAG,
  ADD_CERESTAG,
  REPLACE_CERESTAG,
} from './types';
import {api} from '@aglive/frontend-core';
import {AUTH_TOKEN, envdService} from '../../env';
import {IMPORT_LOCATION} from '../location/types';
import CONSTANT from '../../config/constant';

const tokenService = 'https://auth.dev.mvp.aglive.com';
export function createUserProject(
  project: ManageUserType,
): AppThunk<Promise<string>> {
  return (dispatch, getState) => {
    const state = getState();
    // deep clone project and return iHashedProduct
    const newProject = JSON.parse(JSON.stringify(project));
    const uniqueID = `${state.auth.wallet.slice(0, 7)}-${Date.now()}`;
    newProject.userId = uniqueID;
    dispatch({
      type: CREATE_USER,
      payload: newProject,
    });

    return Promise.resolve(uniqueID);
  };
}

export function featchAllUsers(userId: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const response = await api.featchAllUsers(
        userId,
        tokenService,
        envdService,
        AUTH_TOKEN,
      );
      const users = response.details.users;
      dispatch({
        type: FETCH_USERS,
        payload: users,
      });
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

type UserInfo = {
  userId: string;
  name: string;
  role: string;
  phone: string;
  email: string;
  status: string;
  statusChange: string;
  roleChange: string;
};
export function submitProject(
  userInfo: UserInfo,
  userId: string,
  businessCountry: string,
  successCB: () => void,
  ifPending?: boolean,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const businessId = getState().user.userProfileData.businessId;
      if (userInfo.status) {
        const ifActive = userInfo.statusChange === 'Active' ? true : false;
        const ifDeactive = userInfo.statusChange === 'Inactive' ? true : false;
        const ifRoleChange = userInfo.roleChange === 'Change' ? true : false;
        await api.editUser(
          userId,
          businessId,
          userInfo,
          ifRoleChange,
          ifActive,
          ifDeactive,
          tokenService,
          envdService,
          AUTH_TOKEN,
          ifPending,
        );

        dispatch(
          toggleModal({
            status: 'success',
            title:
              businessCountry === 'Argentina'
                ? CONSTANT.ES_LABELS.updated
                : CONSTANT.EN_LABELS.updated,
            button:
              businessCountry === 'Argentina'
                ? CONSTANT.ES_LABELS.close
                : CONSTANT.EN_LABELS.close,
            CTAHandler: successCB,
          }),
        );
      } else {
        const res: any = await api.addUser(
          userId,
          businessId,
          userInfo,
          tokenService,
          envdService,
          AUTH_TOKEN,
        );
        dispatch(
          toggleModal({
            status: 'success',
            title:
              businessCountry === 'Argentina'
                ? CONSTANT.ES_LABELS.invited
                : CONSTANT.EN_LABELS.invited,
            button:
              businessCountry === 'Argentina'
                ? CONSTANT.ES_LABELS.close
                : CONSTANT.EN_LABELS.close,
            subtitle: `${
              businessCountry === 'Argentina'
                ? CONSTANT.ES_LABELS.invitedMessage
                : CONSTANT.EN_LABELS.invitedMessage
            } ${res.data[0].response.invitationCode}`,
            CTAHandler: successCB,
          }),
        );
      }
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function inviteUserAgain(
  invitationCode: string,
  userId: string,
  businessCountry: string,
  successCB: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const businessId = getState().user.userProfileData.businessId;
      const res: any = await api.inviteUserAgain(
        userId,
        businessId,
        invitationCode,
        tokenService,
        envdService,
        AUTH_TOKEN,
      );
      dispatch(
        toggleModal({
          status: 'success',
          title:
            businessCountry === 'Argentina'
              ? CONSTANT.ES_LABELS.invited
              : CONSTANT.EN_LABELS.invited,
          button:
            businessCountry === 'Argentina'
              ? CONSTANT.ES_LABELS.close
              : CONSTANT.EN_LABELS.close,
          subtitle: `${
            businessCountry === 'Argentina'
              ? CONSTANT.ES_LABELS.invitedMessage
              : CONSTANT.EN_LABELS.invitedMessage
          } ${res.data[0].response.invitationCode}`,
          CTAHandler: successCB,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function getLocation(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const businessId = getState().user.userProfileData.businessId;
      const businessResponse = await callAPI({
        url: API.POST.getTokenbyExternalId,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          activityType: [],
          externalIds: [{agliveToken: businessId}],
        },
      });
      const locationArray = businessResponse[0].details.location
        ? businessResponse[0].details.location
        : [];
      dispatch({
        type: IMPORT_LOCATION,
        payload: locationArray,
      });
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function fetchConsigneeData() {
  return async (dispatch, getState) => {
    try {
      const businessId = getState().user.userProfileData.businessId;
      const data = {
        latestDetails: true,
        status: ['exist'],
        type: ['consignee'],
        activityType: [],
        businessId: [businessId],
      };
      const response = await callAPI({
        url: API.POST.getTokenByBusiness,
        method: 'POST',
        data: data,
      });
      const consigneeData = [];
      response?.map((item) =>
        consigneeData.push(
          Object.assign(item.details, {
            agliveToken: item.externalIds[0].agliveToken,
            createdAt: item.createdAt,
          }),
        ),
      );

      dispatch({
        type: FETCH_CONSIGNEE_DATA,
        payload: consigneeData,
      });
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    }
  };
}
export function createConsignee(
  consignee: ConsigneeTypes,
): AppThunk<Promise<string>> {
  return (dispatch, getState) => {
    const state = getState();
    // deep clone project and return iHashedProduct
    if (consignee.agliveToken) {
      delete consignee.agliveToken;
    }
    if (consignee.createdAt) {
      delete consignee.createdAt;
    }
    const newProject = JSON.parse(JSON.stringify(consignee));
    const uniqueID = `${state.auth.wallet.slice(0, 7)}-${Date.now()}`;
    newProject.agliveToken = uniqueID;
    dispatch({
      type: POST_CONSIGNEE_DATA,
      payload: newProject,
    });

    return Promise.resolve(uniqueID);
  };
}

export function postConsignee(
  consignee: ConsigneeTypes,
  successCB: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      delete consignee.agliveToken;
      await callAPI<{
        message: string;
        data: Array<ConsigneeTypes>;
      }>({
        url: API.POST.createToken,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.CONSIGNEE,
              tokenId: '',
              details: consignee,
            },
          ],
        },
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Created',

          button: 'Close',

          CTAHandler: successCB,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function editConsignee(
  consignee: ConsigneeTypes,
  successCB: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const agliveToken = consignee.agliveToken;
      delete consignee.agliveToken;
      delete consignee.createdAt;
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.CONSIGNEE,
              externalIds: {
                agliveToken,
              },
              activities: [
                {
                  type: 'UP_details',
                  details: consignee,
                },
              ],
            },
          ],
        },
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Updated',
          button: 'Close',
          CTAHandler: successCB,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function deleteConsignee(
  consignee: ConsigneeTypes,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const agliveToken = consignee.agliveToken;
      const data = {
        tokens: [
          {
            type: CONSTANT.ASSETTYPE.CONSIGNEE,
            externalIds: {
              agliveToken: agliveToken,
            },
            activities: [
              {
                type: 'DEL_token',
                details: {
                  reason: 'no reason',
                },
              },
            ],
          },
        ],
      };
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: data,
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Deleted',
          button: 'Close',
          CTAHandler: () => dispatch(fetchConsigneeData()),
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function fetchConsignmentData() {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const businessId = getState().user.userProfileData.businessId;
      const data = {
        latestDetails: true,
        status: ['exist'],
        type: ['consignment'],
        activityType: [],
        businessId: [businessId],
      };
      const response = await callAPI({
        url: API.POST.getTokenByBusiness,
        method: 'POST',
        data: data,
      });
      const consignmentData = [];
      const userProfileRes = await callAPI({
        url: API.POST.getTokenbyAddr,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          activityType: [],
          type: ['user'],
        },
      });
      const activities = userProfileRes[0].activities?.filter(
        (activity) => activity.type === 'outConsignment',
      );

      const agliveToken = activities
        .map((item) => item.details.consignment)
        .filter((item) => item);

      if (agliveToken.length !== 0) {
        const outConsignment = await callAPI({
          url: API.POST.getTokenbyExternalId,
          method: 'POST',
          data: {
            externalIds: agliveToken,
          },
        });

        outConsignment.map((item) => {
          if (item.details.name) {
            consignmentData.push({
              agliveToken: item.externalIds[0].agliveToken,
              forms: item.details.forms ?? [],
              formsCount: item.details.forms?.length ?? 0,
              name: item.details.name,
              consignee: item.details.consignee ?? {id: '', pic: ''},
              destination: item.details.destination ?? {
                pic: '',
                postcode: '',
                town: '',
                state: '',
                address: '',
              },
              eNVDS3Link: item.details.eNVDS3Link ?? '',
              externalIds: item.externalIds ?? [],
              answers: item.details.answers ?? [],
              attachments: item.details.attachments ?? [],
              shareTo: item.details.shareTo ?? {mobile: [], email: []},
              animalSelectionMode: item.details.animalSelectionMode,
              assets: item.details.assets ?? [],
              declaration: item.details.declaration,
              csvFile: item.details.csvFile ?? {},
              mob: item.details.mob ?? {},
              template: item.details.template,
              status: ['completed'],
              createdAt: item.createdAt,
            });
          }
        });
      }
      response?.forEach((item) =>
        consignmentData.push({
          agliveToken: item.externalIds[0].agliveToken,
          forms: item.details.forms ?? [],
          formsCount: item.details.forms?.length ?? 0,
          name: item.details.name,
          consignee: item.details.consignee ?? {id: '', pic: ''},
          destination: item.details.destination ?? {
            pic: '',
            postcode: '',
            town: '',
            state: '',
            address: '',
          },
          externalIds: item.externalIds ?? [],
          eNVDS3Link: item.details.eNVDS3Link ?? '',
          answers: item.details.answers ?? [],
          attachments: item.details.attachments ?? [],
          shareTo: item.details.shareTo ?? {mobile: [], email: []},
          animalSelectionMode: item.details.animalSelectionMode,
          assets: item.details.assets ?? [],
          declaration: item.details.declaration,
          csvFile: item.details.csvFile ?? {},
          mob: item.details.mob ?? {},
          template: item.details.template ?? [],
          status: item.status,
          createdAt: item.createdAt,
        }),
      );
      consignmentData.sort(
        (a, b) =>
          new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf(),
      );
      dispatch({
        type: FETCH_CONSIGNMENT_DATA,
        payload: consignmentData,
      });
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}
export function deleteConsignment(
  consignment: ConsignmentTypes,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const agliveToken = consignment.agliveToken;
      const data = {
        tokens: [
          {
            type: CONSTANT.ASSETTYPE.CONSIGNMENT,
            externalIds: {
              agliveToken: agliveToken,
            },
            activities: [
              {
                type: 'DEL_token',
                details: {
                  reason: 'no reason',
                },
              },
            ],
          },
        ],
      };
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: data,
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Deleted',
          button: 'Close',
          CTAHandler: () => dispatch(fetchConsignmentData()),
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}
export function createConsignment(
  consignemnt,
  fetchWrongRfids: (rifd: Array<string>) => void,
  successCB: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      await callAPI<{
        message: string;
        data: Array<ConsigneeTypes>;
      }>({
        url: API.POST.createToken,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.CONSIGNMENT,
              details: consignemnt,
            },
          ],
        },
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Created',

          button: 'Close',

          CTAHandler: successCB,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      if (e.error.details === 'Animal not found in database/storage') {
        fetchWrongRfids(e.error.source.rfids);
      } else {
        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          }),
        );
      }
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}
export function submitConsignment(
  consignment,
  successCB: () => void,
  fetchWrongRfids: (rifd: Array<string>) => void,
  docId?: string,
  updateDocId?: (agToken: string, cons: ConsignmentTypes) => void,
  originalConsignment?: ConsignmentTypes,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      let agliveToken = '';
      if (docId) {
        agliveToken = docId;
        await callAPI({
          url: API.POST.createActivity,
          method: 'POST',
          data: {
            tokens: [
              {
                type: CONSTANT.ASSETTYPE.CONSIGNMENT,
                externalIds: {
                  agliveToken,
                },
                activities: [
                  {
                    type: 'DEL_details',
                    details: {
                      forms: originalConsignment.forms,
                      destination: originalConsignment.destination,
                      answers: originalConsignment.answers,
                      shareTo: originalConsignment.shareTo,
                      assets: originalConsignment.assets,
                      attachments: originalConsignment.attachments,
                      template: originalConsignment.template,
                    },
                  },
                  {
                    type: 'UP_details',
                    details: consignment,
                  },
                ],
              },
            ],
          },
        });
      } else {
        const response = await callAPI({
          url: API.POST.createToken,
          method: 'POST',
          data: {
            tokens: [
              {
                type: CONSTANT.ASSETTYPE.CONSIGNMENT,
                details: consignment,
              },
            ],
          },
        });
        agliveToken = response.data[0].externalIds[0].agliveToken;
      }

      if (updateDocId && agliveToken) {
        updateDocId(agliveToken, docId ? undefined : consignment);
      }

      const activeDetails = {
        atttachment: consignment.attachments,
        shareTo: {
          email: consignment.shareTo.email,
          mobile: consignment.shareTo.mobile,
        },
      };
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.CONSIGNMENT,
              externalIds: {
                agliveToken,
              },
              activities: [
                {
                  type: CONSTANT.OPERATIONTYPE.SUBMIT_CONSIGNMENT,
                  details: activeDetails,
                },
              ],
            },
          ],
        },
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Submitted',
          button: 'Close',
          CTAHandler: successCB,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      if (
        e.error?.details === 'Animal not found in database/storage' ||
        e.error?.details === 'Animal missing. No longer found in the database'
      ) {
        fetchWrongRfids(e.error.source.rfids);
      } else if (
        e.error?.code === 'E_EXTERNAL_ERROR' &&
        e.error?.source.Error_data.split('\\')[0].includes(
          'is not a valid value for PIC',
        )
      ) {
        dispatch(
          toggleModal({
            status: 'warning',
            title: 'Invalid PIC',
            subtitle: 'Make sure the destination PIC is valid',
            button: 'Update Now',
          }),
        );
      } else {
        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          }),
        );
      }
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function editConsignment(
  consignment,
  agliveToken: string,
  originalConsignment,
  successCB: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.CONSIGNMENT,
              externalIds: {
                agliveToken,
              },
              activities: [
                {
                  type: 'DEL_details',
                  details: {
                    forms: originalConsignment.forms,
                    destination: originalConsignment.destination,
                    answers: originalConsignment.answers,
                    shareTo: originalConsignment.shareTo,
                    assets: originalConsignment.assets,
                    attachments: originalConsignment.attachments,
                    template: originalConsignment.template,
                  },
                },
                {
                  type: 'UP_details',
                  details: consignment,
                },
              ],
            },
          ],
        },
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Updated',
          button: 'Close',
          CTAHandler: successCB,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function fetchCeresTags(
  currentPage: number,
  pageSize: number,
  fieldCondition: {[key: string]: any},
): AppThunk<Promise<boolean>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const payload = {
        getFilters: true,
        itemPerPage: pageSize,
        output: 'grid',
        pages: currentPage + 1,
        fieldCondition: Object.values(fieldCondition),
      };
      const response: any = await callAPI({
        url: API.POST.getFilteredToken,
        method: 'POST',
        data: payload,
        params: {},
      });
      dispatch({
        type: FETCH_CERESTAG_DATA,
        payload: {
          totalRow: response.totalRow,
          cerestag: response.filteredResult,
        },
      });
      return true;
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function addOrRemoveTag(
  rowId: number,
  agliveToken: string,
  vid: string,
  actionType: 'add_tag' | 'remove_tag',
): AppThunk<Promise<any | void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.ASSET,
              externalIds: {
                agliveToken: agliveToken,
              },
              activities: [
                {
                  type: actionType,
                  details: {
                    tag_type: 'ceres_tag',
                    ceres_tag_vid: vid,
                  },
                },
              ],
            },
          ],
        },
      });

      dispatch(
        toggleModal({
          status: 'success',
          title: actionType === 'add_tag' ? 'Saved' : 'Deleted',
          button: 'Close',
          CTAHandler: () => {
            dispatch({
              type: actionType === 'add_tag' ? ADD_CERESTAG : REMOVE_CERESTAG,
              payload:
                actionType === 'add_tag'
                  ? {index: rowId, tagVid: vid}
                  : {index: rowId},
            });
          },
        }),
      );

      return true;
    } catch (e) {
      const error = e as WebErrorType;
      if (error.code === 'E_DUPLICATE_FOUND') {
        throw error;
      }
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function replaceTag(
  rowId: number,
  agliveToken: string,
  vid: string,
  originalVid: string,
): AppThunk<Promise<any | void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.ASSET,
              externalIds: {
                agliveToken: agliveToken,
              },
              activities: [
                {
                  type: 'replace_tag',
                  details: {
                    tag_type: 'ceres_tag',
                    ceres_tag_vid: vid,
                    original_ceres_tag_vid: originalVid,
                  },
                },
              ],
            },
          ],
        },
      });

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Updated',
          button: 'Close',
          CTAHandler: () => {
            dispatch({
              type: REPLACE_CERESTAG,
              payload: {index: rowId, tagVid: vid},
            });
          },
        }),
      );
      return true;
    } catch (e) {
      const error = e as WebErrorType;
      if (error.code === 'E_DUPLICATE_FOUND') {
        throw error;
      }
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function saveAttach(
  consignmentAttach,
  userId: string,
  agliveToken: string,
  successCB: () => void,
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.USER,
              externalIds: {userId: userId},
              activities: [
                {
                  type: CONSTANT.ASSETTYPE.ADD_CONSIGNMENT_ATTACHMENT,
                  details: {
                    agliveToken: agliveToken,
                    attachments: consignmentAttach,
                  },
                },
              ],
            },
          ],
        },
      });
      dispatch(
        toggleModal({
          status: 'success',
          title: 'Updated',
          button: 'Close',
          CTAHandler: successCB,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}
