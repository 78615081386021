const COLOR={
   AA_BLACK: '#333333',
   AA_RED: '#CE5142',
   BLACK: '#000',
   BLACK_BG: '#373935',
   BLUE_BG: '#1c252c',
   BLUE_POLYGON: '#25AAD3',
   BLUE_BORDER:'#BEE8FF',
   GRAY_BORDER: '#D9D9D9',
   GRAY_BUTTON: '#F5F3F5',
   GRAY_ICON: '#9B9B9B',
   GRAY_SOLID: '#F6F7F8',
   GRAY_TEXT: '#7E7E7E',
   GREEN: '#1E7B3E',
   GREEN_BUTTON: '#3DAE2B',
   GREEN_OPTION: '#1F7C3E',
   GREENT_TEXT: '#3FAE2A',
   GREEN_LIGHT: '#87D978',
   GREEN_HIGHLIGHT: '#34B53A',
   ORANGE: '#FF980C',
   RED: '#E61010',
   RED_WARNING: '#FF0707',
   RED_ANGUS: '#CE5142',
   WHITE: '#fff',
};

export default COLOR;
