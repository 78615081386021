import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux';
import type { RootState, AppThunk } from '../store/types'

// Use throughout your app instead of plain `useAppDispatch` and `useAppSelector`
type DispatchOverload = {
  <T extends AnyAction>(arg0: T): T; // if T is a simple action object, return the action object
  <T>(arg0: AppThunk<T>): T; // if T is an AppThunk, return the promise inside
}
export const useAppDispatch: () => DispatchOverload = () => useDispatch<DispatchOverload>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector