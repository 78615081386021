import {useEffect, useCallback, useState} from 'react';

import {Paper} from '@material-ui/core';
import {
  Grid as GridTable,
  Table,
  TableHeaderRow,
  TableColumnVisibility,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import {
  PagingState,
  CustomPaging,
  RowDetailState,
} from '@devexpress/dx-react-grid';

import withHeader from '../../../presentation/withHeader';
import {useStyles} from './styles';
import RowDetail from './RowDetails';
import {COLUMNS, fetchActivitiesReport, formatRow} from './utils';
import {useAppDispatch} from '../../../utils/hooks';
import {WebErrorType} from '../../../utils/error';
import {toggleModal} from '../../../store/modal/actions';
import {
  SPINNER_TOGGLE_OFF,
  SPINNER_TOGGLE_ON,
} from '../../../store/spinner/types';

const ActivitiesReport = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [report, setReport] = useState([]);
  // Change report response to table format
  const formattedRows = formatRow(report);

  // Fetch report from API
  const loadActivitiesReport = useCallback(async () => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const response = await fetchActivitiesReport();
      setReport(response);
    } catch (e) {
      const error = e as WebErrorType;

      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  }, [dispatch]);

  useEffect(() => {
    loadActivitiesReport();
  }, [loadActivitiesReport]);

  return (
    <Paper style={{marginTop: 30}} className={classes.tableRoot}>
      <GridTable rows={formattedRows} columns={COLUMNS}>
        <Table
          columnExtensions={[
            {columnName: 'agliveToken', width: '350px'},
            {columnName: 'type', width: '200px'},
            {columnName: 'updatedAt', width: '250px'},
            {columnName: 'activityGeolocation', width: '250px'},
            {columnName: 'creationGeolocation', width: '250px'},
          ]}
        />
        <PagingState />
        <CustomPaging />
        <TableHeaderRow />
        <TableColumnVisibility />
        <RowDetailState />
        <TableRowDetail contentComponent={RowDetail} />
      </GridTable>
    </Paper>
  );
};

export default withHeader(
  {
    title: 'Activities Report',
    margin: 40,
  },
  ActivitiesReport,
);
