import API from '../../config/api';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import CONSTANT from '../../config/constant';
import {toggleModal} from '../modal/actions';
import {callAPI} from '../../utils/network';
import {WebErrorType} from '../../utils/error';
import {TokenService} from '@aglive/data-model';
import {FETCH_FORM_DOCS, FormAnswer, FormDoc, FormDocResponse} from './types';
import {AppThunk} from '../types';

export function saveFormTemplate(
  formType: string,
  formName: string,
  answers: FormAnswer[],
  history: any,
): AppThunk<Promise<any | void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const validAnswers = answers.filter(
        (answer) =>
          answer.questionId !== undefined &&
          answer.value !== null &&
          answer.value !== '',
      );
      const response = await callAPI({
        url: API.POST.postFormTemplate,
        method: 'POST',
        data: {
          type: 'template',
          name: formName,
          formType: formType,
          answers: validAnswers,
        },
      });

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Saved',
          button: 'Close',
          CTAHandler: history.goBack,
        }),
      );
      return response;
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function fetchFormTemplates(): AppThunk<Promise<boolean>> {
  return async (dispatch, getState) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const response: FormDocResponse[] = await callAPI({
        url: API.GET.getFormTemplates,
        method: 'GET',
        data: {},
      });

      const fromDocs: FormDoc[] = response.map((form) => ({
        createdAt: form.createdAt,
        docId: form.docId,
        formType: form.formType,
        formName: form.details.name,
      }));

      dispatch({
        type: FETCH_FORM_DOCS,
        payload: fromDocs,
      });

      return true;
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export const fetchSingleForm = async (
  docId: string,
): Promise<FormDocResponse> => {
  try {
    const response: FormDocResponse = await callAPI({
      url: API.GET.getDocuments + '/template?id=' + docId,
      method: 'GET',
      data: {},
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export function editSingleForm(
  docId: string,
  formType: string,
  formName: string,
  answers: FormAnswer[],
  history: any,
): AppThunk<Promise<any | void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const validAnswers = answers.filter(
        (answer) =>
          answer.questionId !== undefined &&
          answer.value !== null &&
          answer.value !== undefined,
      );

      const response = await callAPI({
        url: API.PUT.updateDocument,
        method: 'PUT',
        data: {
          docId: docId,
          type: 'template',
          details: {
            name: formName,
            answers: validAnswers,
          },
        },
      });

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Saved',
          button: 'Close',
          CTAHandler: history.goBack,
        }),
      );
      return response;
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function deleteForm(docId: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      await callAPI({
        url: API.DELETE.deleteDocument,
        method: 'DELETE',
        data: {
          docId: docId,
          type: 'template',
        },
      });

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Deleted',
          CTAHandler: () => dispatch(fetchFormTemplates()),
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}
