import React from "react";

export default function() {
  return (
    <svg
      width="28px"
      height="28px"
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <title>Icon: Phone</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Dashboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="D-Home" transform="translate(-777.000000, -711.000000)">
          <g id="Login-" transform="translate(431.000000, 298.000000)">
            <g
              id="Contact:-Phone"
              transform="translate(346.000000, 413.000000)"
            >
              <g id="Icon:-Phone">
                <circle
                  id="Oval"
                  fill="#373935"
                  fillRule="nonzero"
                  cx="14"
                  cy="14"
                  r="14"
                ></circle>
                <g id="call-24px" transform="translate(7.000000, 7.000000)">
                  <polygon id="Path" points="0 0 15 0 15 15 0 15"></polygon>
                  <path
                    d="M12.395,9.56555556 C11.6433333,9.56555556 10.9161111,9.44333333 10.2377778,9.22333333 C10.0238889,9.15 9.78555556,9.205 9.62055556,9.37 L8.66111111,10.5738889 C6.93166667,9.74888889 5.31222222,8.19055556 4.45055556,6.4 L5.64222222,5.38555556 C5.80722222,5.21444444 5.85611111,4.97611111 5.78888889,4.76222222 C5.56277778,4.08388889 5.44666667,3.35666667 5.44666667,2.605 C5.44666667,2.275 5.17166667,2 4.84166667,2 L2.72722222,2 C2.39722222,2 2,2.14666667 2,2.605 C2,8.28222222 6.72388889,13 12.395,13 C12.8288889,13 13,12.615 13,12.2788889 L13,10.1705556 C13,9.84055556 12.725,9.56555556 12.395,9.56555556 Z"
                    id="Path"
                    fill="#FFFFFF"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
