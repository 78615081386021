import React from 'react';
import {Typography} from '@material-ui/core';

interface Props {
  title: string;
  subtitle: string;
  titleStyle?: string;
  subtitleStyle?: string;
}

const FieldHeading: React.FC<Props> = ({title, subtitle, titleStyle, subtitleStyle}) => {
  return (
    <>
      <Typography variant="h6" className={titleStyle}>
        <strong>{title}</strong>
      </Typography>
      <Typography variant="h6" style={{marginBottom: 15, fontSize: 14}} className={subtitleStyle}>
        {subtitle}
      </Typography>
    </>
  );
};

export default FieldHeading;
