import { MODAL_TOGGLE_ON, MODAL_TOGGLE_OFF, ModalTypes } from "./types";
import { Message } from "../../presentation/Modal";
import { initialState as initialModalState } from "./reducers";

export function toggleModal(messageConfig: Message): ModalTypes {
  return {
    type: MODAL_TOGGLE_ON,
    payload: {
      ...initialModalState,
      isOpen: true,
      ...messageConfig,
    }
  };
}

export function toggleModalOff(): ModalTypes { 
  return {
    type: MODAL_TOGGLE_OFF,
  };
}