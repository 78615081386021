import React from 'react';
import {useHistory} from 'react-router-dom';

import {Paper, Grid, makeStyles, Theme} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {DropdownOption} from './types';
import COLOR from '../../styled/colors';

interface Props {
  options: DropdownOption[];
  className?: string;
  setDropdownVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  dropdownContainer: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    borderRadius: 0,
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
  },
  triangle: {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid white',
    position: 'absolute',
    right: 20,
    top: -10,
  },
  menuOption: {
    fontFamily: 'Open Sans',
    borderBottom: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: COLOR.GRAY_BORDER,
    padding: theme.spacing(0.5, 0),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: COLOR.GRAY_SOLID,
    },
  },
  chevron: {
    color: COLOR.GRAY_TEXT,
  },
}));

const DropdownMenu: React.FC<Props> = ({
  options,
  className,
  setDropdownVisible,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleDropdown = (option: DropdownOption) => {
    if (setDropdownVisible) {
      setDropdownVisible(false);
    }
    if (option.type === 'link') {
      window.open(option.destination, '_blank');
    } else {
      history.push(option.destination);
    }
  };

  return (
    <Paper
      elevation={5}
      className={`${classes.dropdownContainer} ${className}`}>
      <Grid container>
        <Grid item className={classes.triangle}></Grid>
        {options.map((option) => (
          <Grid
            item
            container
            className={classes.menuOption}
            alignItems="center"
            key={option.label}
            onClick={() => {
              handleDropdown(option);
            }}>
            <Grid item xs={11}>
              {option.label}
            </Grid>
            <Grid item xs={1}>
              <ChevronRightIcon className={classes.chevron} />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default DropdownMenu;
