import { spinnerState, SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF } from "./types";

const initialState: spinnerState = {
  isOpen: false,
  ongoingRequests: 0
}

export default function spinnerReducer(
  state = initialState,
  action
) {
  switch(action.type) {
    case SPINNER_TOGGLE_ON:
      return {isOpen: true, ongoingRequests: state.ongoingRequests + 1};
    case SPINNER_TOGGLE_OFF:
      return {isOpen: state.ongoingRequests > 1 ? true : false, ongoingRequests: state.ongoingRequests - 1};
    default:
      return state;
  }
}