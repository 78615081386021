import withHeader from '../../presentation/withHeader';
import React, {useState, useEffect} from 'react';
import {Grid, Typography, makeStyles, withStyles} from '@material-ui/core';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import MyButton from '../../presentation/button';
import {assetStyles} from '../asset/Components';
import COLOR from '../../styled/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import {Line} from '../../presentation/Line';
import {useLocation, useHistory} from 'react-router-dom';
import {getSireProfile} from '../../store/angus/actions';

export const useStyles = makeStyles((theme) => ({
  greyBox: {
    backgroundColor: COLOR.GRAY_SOLID,
    marginTop: 20,
    marginBottom: 80,
    padding: 15,
    maxWidth: window.innerWidth / 2,
  },
  buttons: {
    display: 'flex',
    marginTop: 20,
  },
  button: {
    marginRight: 30,
    color: COLOR.WHITE,
    backgroundColor: COLOR.RED,
    borderColor: COLOR.RED,
    '&:hover': {
      backgroundColor: COLOR.RED,
      borderColor: COLOR.RED,
    },
  },
}));

const ViewSireProfile: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const state: any = useLocation().state;
  const history = useHistory();
  const {sireID} = state;
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const {wallet} = useAppSelector((state) => state.auth);
  const isAnimalBusiness = businessProfile.industryType == 'ANIMALS';
  const classes = {...assetStyles(), ...useStyles()};
  const details = {
    acct_id: '',
    birth_date: '',
    is_ai: '',
    nlis_id: '',
    pic: '',
    rfid: '',
    sire_id: '',
    verified_Date: '',
  };
  const [sireDetails, setSireDetails] = useState(details);
  useEffect(() => {
    const acctID = isAnimalBusiness
      ? businessProfile.angusProfile.acctID
      : undefined;
    const angusAuthToken = isAnimalBusiness
      ? businessProfile.angusProfile.angusAuthToken
      : undefined;

    const externalID = {angusId: acctID + '-' + sireID};
    dispatch(getSireProfile(externalID, angusAuthToken, wallet))
      .then((resp) => {
        setSireDetails({
          ...resp[0].details,
          verified_date: resp[0].externalIds[0].timestamp,
        });
      })
      .catch(() => {});
  }, []);

  const rows = [
    {name: 'Sex', value: 'Male'},
    {name: 'Birth Month', value: sireDetails.birth_date?.replace(/\//g, '-')},
    {name: 'Sire ID', value: sireID},
  ];

  const TableCell = withStyles({
    root: {
      borderBottom: 'none',
    },
  })(MuiTableCell);

  return (
    <>
      <Grid>
        <Typography variant="h3">Details</Typography>
        <Grid container className={classes.greyBox}>
          <TableContainer>
            <Table>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left" style={{fontWeight: 700}}>
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Typography variant="h3">Profile</Typography>
        <Line />
      </Grid>
      <Grid container className={classes.buttons}>
        <MyButton
          disabled={false}
          text={'Angus Verified'}
          variant="outlined"
          buttonClass={classes.button}
          onClick={() => {
            history.push(`/private/verified/animalProfile/angus-verified`, {
              title: 'Sire Profile',
              details: sireDetails,
            });
          }}
        />
        <MyButton
          disabled={false}
          text={'Treatment'}
          variant="outlined"
          buttonClass={classes.button}
          onClick={() => {
            history.push(`/private/verified/animalProfile/treatment`, {
              title: 'Sire Profile',
            });
          }}
        />
      </Grid>
    </>
  );
};

export default withHeader(
  {
    title: 'Sire Profile',
    margin: 60,
    back: true,
  },
  ViewSireProfile,
);
