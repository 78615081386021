import React, { CSSProperties } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import COLOR from '../styled/colors';
import { Typography } from '@material-ui/core';

const DefaultEmptyStyle: CSSProperties = {
  width: '100%',
  paddingTop: 200,
  paddingBottom: 200,
  textAlign: 'center',
};

const StyledTableCell = withStyles((theme: any) => ({ // TODO Fix TS error
  head: {
    backgroundColor: COLOR.GRAY_SOLID,
    // color: theme.palette.primary.main,
    ...theme.typography.h6,
    fontWeight: 'bold'
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

type MyTableProps = {
  heads: Array<string>;
  rows: Array<Array<string | React.ReactNode>>;
  firstColumnWidth?: any;
  dataEmptyStyle?: CSSProperties;
}

const MyTable: React.FC<MyTableProps> = (props) => {
  return (
    <TableContainer>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {props.heads.map(title => (
              <StyledTableCell align="left" key={title}>{title}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map(([name, ...row], index) => (
            <TableRow key={index}>
              <StyledTableCell component="th" scope="row" className={props.firstColumnWidth}>{name}</StyledTableCell>
              {row.map((data, index) => (
                <StyledTableCell align="left" key={index}>{data}</StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {props.rows?.length === 0 && (
        <div style={props.dataEmptyStyle ?? DefaultEmptyStyle}>
          <Typography variant="h6">
            There is no items to be displayed!
          </Typography>
        </div>
      )}
    </TableContainer>
  );
}

export default MyTable;
