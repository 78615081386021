import { makeStyles } from "@material-ui/core/styles";
import COLOR from '../styled/colors';
import mergedLogo from '../img/merged-logo.png';

const useStyles = makeStyles((theme) => ({
  powerByView: {
    display:'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  powerByText: {
    color: COLOR.GRAY_ICON,
  },
}));

// The ViewSite PrintContent directly uses the config here, please take consideration when changing the css below
const PowerBy = () => {
  const classes = useStyles();

  return (
    <div className={classes.powerByView}>
      <div className={classes.powerByText}>
        <span>{'Protected by'}</span>
      </div>
      <img
        style={{marginLeft: 2, verticalAlign: 'sub'}}
        src={mergedLogo}
        alt="Aglive Logo"
      />
    </div>
  );
};

export default PowerBy;
