import produce from 'immer';

import {IMPORT_LOCATION, locationState, locationTypes, DROP_LOCATION} from './types';

const initialState: locationState = {
  location:[],
};

export default function locationReducer(
  state = initialState,
  action: locationTypes
): locationState {
  switch(action.type) {
    case IMPORT_LOCATION:
      return produce(state, draft => {
        draft.location=action.payload;
      });
    case DROP_LOCATION:
      return initialState;
    default:
      return state;
  }
}
