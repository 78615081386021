import WalletConnect from "@walletconnect/client";
import CONSTANTS from "../config/constant";
import {
  ITxData,
  IJsonRpcRequest,
} from "@walletconnect/types";
import { WebError } from "./error";
const {
  BRIDGE,
  WALLET_CONNECT,
} = CONSTANTS.WALLETCONNECT;

const getWalletConnectFromLocalStorage = () => {
  const session = localStorage.getItem(WALLET_CONNECT);
  if (session) return new WalletConnect(JSON.parse(session));
  else return new WalletConnect({ bridge: BRIDGE });
};

const initWalletConnect = async (): Promise<WalletConnect> => {
  try {
    const connector = await getWalletConnectFromLocalStorage();
    if (!connector.connected) {
      await connector.createSession();
    }
    
    return connector;
  } catch (e) {
    console.error("initWalletConnect->error", e);
    throw new WebError('WALLETCONNECT_ERROR', e);
  }
};

//disconnect the session with mobile
const killSession = (): void => {
  const connector = getWalletConnectFromLocalStorage();
  connector && connector.connected && connector.killSession();
};

//send transaction to mobile event "call_request" and receive result from mobile
const sendTransaction = async (tx: ITxData): Promise<any> => {
  const connector = getWalletConnectFromLocalStorage();
  if (connector) {
    const result = await connector.sendTransaction(tx);
    return result;
  }
};

//send custom request to mobile event "call_request" and receive result from mobile
const sendCustomRequest = async (
  payload: Partial<IJsonRpcRequest>
): Promise<any> => {
  try {
    const connector = getWalletConnectFromLocalStorage();

    if (!connector) throw new WebError('WALLETCONNECT_ERROR', connector);

    const result = await connector.sendCustomRequest(payload);
    return result;
  } catch (e) {
    throw e;
  }
  // }
  // else throw new Error(ERROR.OFFLINE);
};

export default {
  initWalletConnect,
  sendCustomRequest,
  killSession,
  sendTransaction,
  getWalletConnectFromLocalStorage,
};
