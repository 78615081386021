import React, { useState, useEffect } from 'react';
import withHeader from '../../presentation/withHeader';
import {
  Grid,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  makeStyles
} from '@material-ui/core';
import CSVUploader from '../../presentation/CSVUploader';
import { HyperLink } from '../../presentation/word';
import MyButton from '../../presentation/button';
import { AssetDialogMessage, cleanSelectedFile, assetStyles } from '../asset/Components';
import CONSTANT from '../../config/constant';
import {
  validateWithSchema,
  JSONSchemaValidationError,
} from '../../utils/csvReader';
import { uniqueIds } from '../../config/schema';
import { ValidationErrorType } from '../../utils/error';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { toggleModal } from '../../store/modal/actions';
import { manageAssetByCSV } from '../../store/asset/actions';
import COLOR from '../../styled/colors';
import AiJoiningImage from "../../img/AiJoining.png";

export const useStyles = makeStyles((theme) => ({
  subTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
    paddingBottom: "10px",
  },
  Image: {
    width: "106px",
    height: "106px",
  },
  Checkbox: {
    '&$checked': {
      backgroundColor: "primary[100]",
    }
  },
}));

const AddSireToGroup: React.FC<{ Props }> = (props) => {
  const classes = { ...assetStyles(), ...useStyles() };
  const [file, setFile] = useState(null);
  const [csvData, setCSVData] = useState([]);
  const [validationResult, setValidationResult] = useState({
    type: null as string,
    validationError: [] as Array<JSONSchemaValidationError>,
  });
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const userid = useAppSelector(state => state.auth.wallet);
  const [checked, setChecked] = useState({
    registed: false,
    updated: false,
    declared: false,
    signed: false,
  });
  const businessProfile = useAppSelector(
    state => state.user.businessProfileData,
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  const { registed, updated, declared, signed } = checked;
  const noOfCheckedBoxes = [registed, updated, declared, signed].filter((v) => v).length;
  const uniqueIdTypes = uniqueIds.Australia;
  const isAnimalBusiness = businessProfile.industryType == "ANIMALS"

  useEffect(() => {
    setChecked({
      registed: false,
      updated: false,
      declared: false,
      signed: false,
    });
  }, [file]);

  function ShowDeclaration() {
    if (csvData.length) {
      return (
        <Grid container>
          <FormControl component="fieldset" >
            <Typography variant='h3' className={classes.subTitle} >Declaration</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={registed} onChange={handleChange} color="primary" name="registed" />}
                label="I declare that all Angus sires recorded are registered sires with Angus Australia"
              />
              <FormControlLabel
                control={<Checkbox checked={updated} onChange={handleChange} color="primary" name="updated" />}
                label="I acknowledge my responsibility to identify each AI sire and update the number of females joined to an AI sire each year in AI Joining"
              />

              <img className={classes.Image} src={AiJoiningImage} />

              <FormControlLabel
                control={<Checkbox checked={declared} onChange={handleChange} color="primary" name="declared" />}
                label="I declare this information to be true and correct and now submit it to Angus Australia"
              />

              <FormControlLabel
                control={<Checkbox checked={signed} onChange={handleChange} color="primary" name="signed" />}
                label="Apply Signature"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      );
    }
    return null;
  };

  return (
    <>
      <CSVUploader file={file} setFile={setFile} setCSVData={setCSVData} />

      <Grid container>
        <Grid item className={classes.linksContainer}>
          <HyperLink
            className={classes.linksBetween}
            href={CONSTANT.ASSETINDUCTION.SIREGROUPS.CSVTEMP}>
            Download CSV Template
          </HyperLink>
          <HyperLink href={CONSTANT.ASSETINDUCTION.SIREGROUPS.CSVSAMPLE}>
            Download Example and Instructions
          </HyperLink>
        </Grid>
      </Grid>

      <ShowDeclaration />

      <Grid container justify="flex-end" >
        <MyButton
          disabled={csvData.length && (noOfCheckedBoxes === 4) ? false : true}
          buttonClass={classes.saveButton}
          text={'Save'}
          variant="contained"
          onClick={() => {
            validateWithSchema(csvData, CONSTANT.ASSETINDUCTION.SIREGROUPS.SCHEMA, true, uniqueIdTypes)
              .then(() => {
                setCSVData((prevCSVEntries) =>
                  prevCSVEntries.map((CSVEntry) => {
                    CSVEntry.externalIds = uniqueIdTypes.reduce(
                      (accumIDs, curID) => ({
                        ...accumIDs,
                        [curID]: CSVEntry[curID],
                      }),
                      {},
                    );
                    uniqueIdTypes.map((id) => delete CSVEntry[id]);

                    return CSVEntry;
                  }),
                );
              })
              .catch((error) => {
                const e = error as ValidationErrorType<
                  Array<JSONSchemaValidationError>
                >;
                setValidationResult({
                  type: e.type,
                  validationError: e.error,
                });
              });
            setOpen(true);
          }}
        />
      </Grid>

      {open && (
        <AssetDialogMessage
          open={open}
          isErr={validationResult.type ? true : false}
          fileName={file.name}
          handleClose={() => {
            cleanSelectedFile('csvReader');
            setFile('');
            setCSVData([]);
            setValidationResult({type: null, validationError: []});
            setOpen(false);
          }}
          validationResult={validationResult}
          rightButtonClick={() => {
            setOpen(false);
            dispatch(
              manageAssetByCSV({
                template: CONSTANT.ASSETINDUCTION.SIREGROUPS.ACTIVITYTEMPLATE,
                accountId: isAnimalBusiness? businessProfile.angusProfile.acctID: undefined,
                angusAuthToken: isAnimalBusiness? businessProfile.angusProfile.angusAuthToken: undefined,
                activityType: (CONSTANT.ASSETINDUCTION.SIREGROUPS.ACTIVITYTYPE as any),
                file,
                csvData,
              }),
            )
              .then(() => {
                cleanSelectedFile('csvReader');
                setFile('');
              })
              .catch((error) => {
                cleanSelectedFile('csvReader');
                setFile('');
                dispatch(
                  toggleModal({
                    status: 'failed',
                    title: error.title,
                    subtitle: error.message,
                  }),
                );
              });
          }}
          rightButtonClickIsErr={() => {
            cleanSelectedFile('csvReader');
            setFile('');
            setCSVData([]);
            setValidationResult({ type: null, validationError: [] });
            setOpen(false);
          }}
          csvData={csvData}
        />
      )}
    </>
  );
};

export default withHeader(
  {
    title: 'Add Sire',
    margin: 60,
    back: true,
  },
  AddSireToGroup,
);