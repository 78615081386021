import {makeStyles} from '@material-ui/core';

import COLOR from '../../../styled/colors';

export const useStyles = makeStyles((theme) => ({
  stepContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
  },
  contentButton: {
    borderColor: COLOR.GREEN_BUTTON,
    color: COLOR.GREEN_BUTTON,
  },
  stepHyperlinkContainer: {
    marginBottom: theme.spacing(4),
  },
  hyperlink: {
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    color: COLOR.GREENT_TEXT,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  chevron: {
    color: COLOR.GREENT_TEXT,
    cursor: 'pointer',
  },
}));
