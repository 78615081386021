import { TokenService } from '@aglive/data-model';
import { loadTokenAction } from './types';

const loadToken = (
  profileToken: TokenService.ProductToken["details"],
  brandToken: TokenService.BrandToken,
  ): loadTokenAction => ({
  type: 'load/load_token',
  payload: {
    profileToken,
    brandToken
  }
});

export default {
  loadToken
};