import { TokenService } from "@aglive/data-model";
import { iDocumentRequest } from "../../utils/type";
import { PlantCodeDocumentDetails } from '../plant/types';
import { fetchEntryAction } from './actions';

export type codeActions = ReturnType<typeof fetchEntryAction>;

export type codeState = Array<iCodeEntryDocumentResponse<unknown>>;

// Document for Code entry
export interface iCodeEntryDocument {
  used: boolean;
  codes: Array<string>;
  product: TokenService.ProductSecurityCode["details"];
}

export interface iCodeEntryDocumentRequest extends iDocumentRequest {
  details: iCodeEntryDocument | PlantCodeDocumentDetails;
}

export interface iCodeEntryDocumentResponse <T>{
  _id: string;
  docId: string;
  details: T;
  type: 'code';
  userId: string;
  createdAt: string;
  updatedAt: string;
  __v: any;
  id: string;
}

export const FETCH_CODE_TOKENS = 'FETCH_CODE_TOKENS';
