import walletConnect from '../../utils/walletConnect';

import { toggleModal } from '../modal/actions';

import { IJsonRpcRequest } from '@walletconnect/types';
import { AppThunk } from '../types';
import { GET_QRCODE, LOGIN_SUCCESS, LOGIN, LOGOUT, RESET_ERROR, AuthTypes } from './types';
import { locationTypes, DROP_LOCATION } from '../location/types';
import { callRequestHandler } from '../asset/utils';

import CONSTANTS from '../../config/constant';
import { WebErrorType, WebError, NetworkError, WEB_ERROR } from '../../utils/error';
import { SPINNER_TOGGLE_OFF } from '../spinner/types';
import { DROP_SITE_TOKENS } from '../site/types';
import { DROP_PROFILE_DATA } from '../profile/types';
import { GlobalAccessToken } from '@aglive/frontend-core';
import { callAPI } from '../../utils/network';
import API from '../../config/api';
import { authLogout } from '../../utils/auth'
import { DeviceUUID } from 'device-uuid';
import WalletConnect from '@walletconnect/client';

const {
  CONNECT,
  SESSION_UPDATE,
  CALL_REQUEST,
  DISCONNECT,
  WALLET_CONNECT,
} = CONSTANTS.WALLETCONNECT;

export function getQrcode(history: any, closeFunction?: (arg0: any) => void): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      // get randomId from server
      //      const res = await axios(API.GET.getQR, {
      //      headers: {
      //         'Accept': '*/*',
      //         'Accept-Encoding': 'gzip, deflate',
      //         'Access-Control-Request-Headers': 'access-control-allow-methods, access-control-allow-origin',
      //         'Access-Control-Request-Method': 'GET',
      //         'Connection': 'keep-alive',
      //         'Host': `${API.GET.socketURL}`,
      //         'Origin': 'https://dev-aglive.web.app'
      //      },
      //   });

      // check if there's a cached session in localstorage, if there is, remove session
      if (localStorage.getItem(WALLET_CONNECT)) {
        localStorage.removeItem(WALLET_CONNECT)
      }

      // initate session with WalletConnect
      dispatch(initWalletConnect(history)).then(connector => {
        dispatch({
          type: GET_QRCODE,
          payload: connector.uri
        });
  
        //connect to event 'connect' so that it will receive the payload after the mobile approve the session request
        connector.on(CONNECT, async (error: Error | null, payload: IJsonRpcRequest) => {
          if (error) throw new WebError("OFFLINE_ERROR");
          if (payload) {
            const hasMessageAndSignature = payload.params[0].accounts[1] && payload.params[0].accounts[2];
            if (hasMessageAndSignature) {
              try {
                let deviceId = localStorage.getItem(CONSTANTS.DEVICE_ID);
                if (!deviceId) {
                  deviceId = new DeviceUUID().get();
                  localStorage.setItem(CONSTANTS.DEVICE_ID, deviceId);
                }
                const data = { message: payload.params[0].accounts[1], signature: payload.params[0].accounts[2], deviceId };
                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams.get('state') && urlParams.get('client_id')) {
                  data['state'] = urlParams.get('state');
                  data['client_id'] = urlParams.get('client_id');
                }

                // Stores geolocation obtained from mobile (if it is provided in the payload)
                const geolocation: string | undefined = payload.params[0].accounts[3];
                if (geolocation) {
                  localStorage.setItem(
                    CONSTANTS.USER_GEOLOCATION_STORAGE_KEY,
                    geolocation,
                  );
                } else {
                  localStorage.removeItem(CONSTANTS.USER_GEOLOCATION_STORAGE_KEY);
                }

                const res = await callAPI({url: API.POST.authUser, method: 'POST', data: data});
                if (res && GlobalAccessToken.setTokenFromRes({ data: { ...res } })) {
                  if (res.mode === 'ceres tag integration' && urlParams.get('redirect_uri')) {
                    window.location.href = `${urlParams.get('redirect_uri')}?access_token=${res.accessToken}&state=${res.state}&token_type=access_token&expires_in=${res.accessTokenExpiration}`
                  } else {
                    dispatch({
                      type: LOGIN_SUCCESS,
                      payload: payload.params[0].accounts[0]
                    });
                  }
                }
                else {
                  console.log('GlobalAccessToken.setTokenFromRes(res) =>> fail')
                }
              } catch (error) {
                console.error(error);
              }
            } 
          }
        });
        if (closeFunction) {
          connector.on('close', e => {
            if (!navigator.onLine) {
              new NetworkError(e).sendErrortoSentry()
              closeFunction({
                title: WEB_ERROR.WALLETCONNECT_ERROR.title,
                message: WEB_ERROR.WALLETCONNECT_ERROR.details,
              });
            } else {
              closeFunction({
                title: WEB_ERROR.QR_EXPIRE.title,
                message: WEB_ERROR.QR_EXPIRE.details,
              });
            }
          });
        }
      })
      .catch(e => {
        const error = e as WebErrorType;
        dispatch(toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Try Again',
          CTAHandler: () => window.location.reload(),
        }));
      });
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(toggleModal({
        status: 'failed',
        title: error.title,
        subtitle: error.message,
        button: 'Try Again',
        CTAHandler: () => window.location.reload(),
      }));
  }
  }
}

export function login(): AuthTypes {
  return {
    type: LOGIN,
  };
}

export function appThunkLogout(skipRevoke = false): AppThunk<Promise<boolean>> {
  return async (dispatch) => {
    await logout(dispatch, skipRevoke)
    return Promise.resolve(true);
  }
}

// This way we can call it outside a component
export const logout = async (dispatch: (arg0: AuthTypes | locationTypes | any) => void, skipRevoke = false) => {
  walletConnect.killSession();
  localStorage.removeItem(WALLET_CONNECT);
  if (skipRevoke === false) {
    await authLogout();
  }
  dispatch({ type: LOGOUT });
  sessionStorage.removeItem('persist:auth'); // HACK
  dispatch({ type: DROP_LOCATION });
  dispatch({ type: DROP_SITE_TOKENS });
  dispatch({ type: DROP_PROFILE_DATA });
}

export function resetError(): AuthTypes {
  return {
    type: RESET_ERROR,
  };
}

export function initWalletConnect(history: any): AppThunk<Promise<WalletConnect>> {
  return async (dispatch) => {
    try {
      const connector = await walletConnect.initWalletConnect();

      if (!connector) throw new WebError('WALLETCONNECT_ERROR', connector);
      
      connector.on(
        SESSION_UPDATE,
        (error: Error | null, payload: IJsonRpcRequest) => {
          if (error) throw new WebError("OFFLINE_ERROR", error);
        }
      );

      connector.on(DISCONNECT, (error: any | null, payload: any) => {
        if (error) {
          dispatch({ type: SPINNER_TOGGLE_OFF });

          dispatch(toggleModal({
            status: 'failed',
            title: 'Blockchain Error',
            subtitle: error.message,
          }));
        }
      });

      connector.on(
        CALL_REQUEST,
        (error: any | null, payload: IJsonRpcRequest) => {
          // if (error) throw error; // new Error(ERROR.OFFLINE);
          console.log("subscribeToEvents call_request->payload", payload, error);
          if (error) {
            callRequestHandler(dispatch, history)(new WebError('WALLETCONNECT_ERROR', String(error)), payload as any);
          } else {
            callRequestHandler(dispatch, history)(error, payload as any);
          }
        }
      );

      connector.on('error', e => {
        new NetworkError(e).sendErrortoSentry()
        dispatch(toggleModal({
          status: 'failed',
          title: WEB_ERROR.WALLETCONNECT_ERROR.title,
          subtitle: WEB_ERROR.WALLETCONNECT_ERROR.details,
          button: 'Reload',
          CTAHandler: () => window.location.reload(),
        }));
      });

      return Promise.resolve(connector);
    } catch (e) {
      if (e instanceof WebError) {
        callRequestHandler(dispatch, history)(e, undefined);
      } else {
        throw new NetworkError(e);
      }
    }
  }
}
