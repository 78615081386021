import produce from 'immer';
import {AuthTypes, authState, GET_QRCODE, LOGIN_SUCCESS, LOGOUT} from './types';

const initialState: authState={
   isLoggedIn: false,
   walletLoginId: null,
   wallet: null,
}

export default function authReducer(
   state=initialState,
   action: AuthTypes,
): authState {
   switch(action.type) {
      case GET_QRCODE:
         return produce(state, draft => {
            draft.walletLoginId = action.payload;
         });
      case LOGIN_SUCCESS:
         return produce(state, draft => {
            draft.isLoggedIn=true;
            draft.wallet=action.payload;
         });
      case LOGOUT:
         return initialState;
      default:
         return state;
   }
}
