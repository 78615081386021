import React, {useState} from 'react';

import {Box, Grid} from '@material-ui/core';
import {TokenService} from '@aglive/data-model';

import {useStyles} from './styles';
import Tabs from '../../../presentation/Tab';
import TabPanel from '../../../presentation/TabPanel';
import MockupPhoneMobDetails from '../../../presentation/MockupPhoneMobDetails';
import ViewProductTabAttachment from '../ViewProductDetails/ViewProductTabAttachment';
import ViewMobMainSection from './ViewMobMainSection';

enum TabMap {
  mainSection,
  attachments,
}
const TabLabels = Object.values(TabMap).slice(0, 2);

interface MobDetailsProps {
  mob: TokenService.AnimalPromotion['details']['mob'];
}

const ViewMobDetails: React.FC<MobDetailsProps> = ({mob}) => {
  const classes = useStyles();

  const [tabValue, setTabValue] = useState(TabMap.mainSection);

  return (
    <>
      <Grid item container xs={12} lg={5} justifyContent="center">
        <MockupPhoneMobDetails mob={mob} />
      </Grid>
      <Grid item xs={12} lg={7}>
        <Tabs
          tabHeaders={TabLabels as string[]}
          value={tabValue}
          setValue={setTabValue}>
          <TabPanel
            isShown={tabValue === TabMap.mainSection}
            index={TabMap.mainSection}>
            <Box p={3} className={classes.tabContainer}>
              <ViewMobMainSection mob={mob} />
            </Box>
          </TabPanel>
          <TabPanel
            isShown={tabValue === TabMap.attachments}
            index={TabMap.attachments}>
            <Box p={3} className={classes.tabContainer}>
              <ViewProductTabAttachment attachment={mob.attachment} />
            </Box>
          </TabPanel>
        </Tabs>
      </Grid>
    </>
  );
};

export default ViewMobDetails;
