import {FETCH_FORM_DOCS, formState} from './types';

const initialState: formState = [];

export default function formReducer(state = initialState, action): formState {
  switch (action.type) {
    case FETCH_FORM_DOCS:
      return action.payload;
    default:
      return state;
  }
}
