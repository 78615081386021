import React from 'react';

import {ProgressBar, Step} from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';

import COLOR from '../../styled/colors';
import {useStyle} from './styles';

interface ProgressStep {
  label: string;
  onClick?: () => void;
}

interface ProgressBarProps {
  percent: number;
  steps: ProgressStep[];
  styles?: {
    progressBarContainerStyle?: string;
    filledBackground?: string;
    unfilledBackground?: string;
    progressBarHeight?: number;
    stepStyle?: string;
    stepLabelStyle?: string;
    activeStepStyle?: string;
    accomplishedStyle?: string;
  };
}

const StepProgressBar: React.FC<ProgressBarProps> = ({
  percent,
  steps,
  styles,
}) => {
  const classes = useStyle();

  return (
    <div
      className={
        styles?.progressBarContainerStyle || classes.progressBarContainer
      }>
      <ProgressBar
        percent={percent}
        filledBackground={styles?.filledBackground || COLOR.GREEN_BUTTON}
        unfilledBackground={styles?.unfilledBackground || COLOR.GRAY_BORDER}
        height={styles?.progressBarHeight ?? 2}>
        {steps.map((step) => (
          <Step key={step.label}>
            {({accomplished, position}) => (
              <>
                <div
                  className={`${styles?.stepStyle || classes.step} ${
                    accomplished
                      ? styles?.accomplishedStyle || classes.accomplished
                      : ''
                  } ${
                    position === percent
                      ? styles?.activeStepStyle || classes.activeStep
                      : ''
                  } ${step.onClick ? classes.clickableStep : ''}`}
                  onClick={step.onClick}>
                  <div className={styles?.stepLabelStyle || classes.stepLabel}>
                    {step.label}
                  </div>
                </div>
              </>
            )}
          </Step>
        ))}
      </ProgressBar>
    </div>
  );
};

export default StepProgressBar;
