import clone from 'clone';

import API from '../../config/api';
import CONSTANT from '../../config/constant';

import {AppThunk} from '../types';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import {toggleModal} from '../modal/actions';
import {
  SiteToken,
  LayerNode,
  FETCH_SITE_TOKENS,
  SiteTokenResponse,
  SiteOptions,
} from './types';
import {callAPI} from '../../utils/network';
import {WebErrorType, WebError} from '../../utils/error';

export function submitSiteToken(
  tree: LayerNode,
  location: string,
  history: any,
  allowDuplicate?: boolean,
  region?: string,
  propertyType?: string,
): AppThunk<Promise<void>> {
  // locationType
  return async (dispatch, getState) => {
    try {
      if (!tree || !location) throw new WebError('INVALID_INPUT');

      // sanitize input
      const siteTokensResArr = getState().site;
      if (!allowDuplicate) {
        const isDuplicatePIC = siteTokensResArr.find(
          (siteTokenRes) => siteTokenRes.details.location === location,
        );
        if (isDuplicatePIC)
          throw new WebError('INVALID_INPUT', {error: 'Repeated use of PIC'});
      }

      const {
        auth: {wallet},
      } = getState();
      dispatch({type: SPINNER_TOGGLE_ON});

      const token: SiteToken = {
        siteName: tree.name,
        location: location, // .PICAddress,
        layers: tree,
        region,
        propertyType,
      };

      await callAPI({
        url: API.POST.createToken,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.SITE,
              tokenId: '',
              externalIds: {},
              generateAgliveToken: true,
              details: token,
            },
          ],
        },
      });

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Created',
          CTAHandler: history.goBack,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function fetchSiteTokens(): AppThunk<Promise<boolean>> {
  return async (dispatch, getState) => {
    try {
      const {
        auth: {wallet},
      } = getState();
      dispatch({type: SPINNER_TOGGLE_ON});

      const response = await callAPI({
        url: API.POST.getTokenbyAddr,
        method: 'POST',
        data: {
          latestDetails: true,
          status: ['exist'],
          type: [CONSTANT.ASSETTYPE.SITE],
          activityType: [],
        },
      });

      dispatch({
        type: FETCH_SITE_TOKENS,
        payload: response,
      });

      return true;
    } catch (e) {
      if (e.code === 'E_NOT_FOUND') {
        dispatch({
          type: FETCH_SITE_TOKENS,
          payload: [],
        });
      } else {
        const error = e as WebErrorType;
        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          }),
        );
      }
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function fetchCattleTreatment(isWarakirri: boolean): AppThunk<Promise<Array<any>>> {
  return async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      const docResponse = await callAPI({
        url: API.GET.getTreatmentDocument,
        method: 'GET',
        params: {
          type: isWarakirri ? 'warakirriCattleTreatment' : 'cattleTreatment',
          propertyName: 'status',
          value: 'active',
        },
      });

      dispatch({type: SPINNER_TOGGLE_OFF});
      return docResponse;
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: e.message,
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function modifySiteToken(
  modifiedTree: LayerNode,
  oldSiteToken: SiteTokenResponse,
  selectedLocation: string,
  history,
  region?: string,
  propertyType?: string,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const {
        auth: {wallet},
      } = getState();
      dispatch({type: SPINNER_TOGGLE_ON});

      const token: SiteToken = {
        siteName: modifiedTree.name,
        location: selectedLocation,
        layers: modifiedTree,
        region,
        propertyType,
      };
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              externalIds: {
                agliveToken: oldSiteToken.externalIds[0].agliveToken,
              },
              activities: [
                {
                  type: 'DEL_details',
                  details: {
                    layers: {
                      children: oldSiteToken.details.layers.children,
                    },
                  },
                },
                {
                  type: 'UP_details',
                  details: token,
                },
              ],
            },
          ],
        },
      });

      dispatch(fetchSiteTokens()).then((complete) =>
        dispatch(
          toggleModal({
            status: 'success',
            title: 'Updated',
            CTAHandler: history.goBack,
          }),
        ),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function deleteSiteToken(
  siteTokenRes: SiteTokenResponse,
  history,
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const {
        auth: {wallet},
      } = getState();
      dispatch({type: SPINNER_TOGGLE_ON});

      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: siteTokenRes.type,
              externalIds: {
                agliveToken: siteTokenRes.externalIds[0].agliveToken,
              },
              activities: [
                {
                  type: 'DEL_token',
                  details: siteTokenRes.details,
                },
              ],
            },
          ],
        },
      });

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Deleted',
          CTAHandler: () => dispatch(fetchSiteTokens()),
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}

export function nodeCount(tree: LayerNode): number {
  if (tree.children?.length) {
    return tree.children.reduce(
      (accum, curNode) => accum + nodeCount(curNode),
      1,
    );
  } else {
    return 1;
  }
}

export function flattenTree(tree: LayerNode): Array<{
  name: string;
  id: string;
  layerName: string;
}> {
  if (tree.children?.length) {
    return tree.children.reduce(
      (accumArr, curNode) => [...accumArr, ...flattenTree(curNode)],
      [
        {
          name: tree.name,
          id: tree.id,
          layerName: tree.layerName,
        },
      ],
    );
  } else {
    delete tree.children;
    return [tree];
  }
}

export function validateTree(tree: LayerNode): boolean {
  if (!tree.layerName) return false;

  if (tree.children?.length) {
    for (const childNode of tree.children) {
      if (!validateTree(childNode)) return false;
    }
  }

  return true;
}

export function cloneNode(
  state: LayerNode,
  targetHandler: (arg0: LayerNode) => any,
): LayerNode {
  let nextState: LayerNode = clone(state);
  let ptr = nextState;

  targetHandler(ptr);
  return nextState;
}

export function targetParentNode(
  target: LayerNode,
  args: Array<number>,
): LayerNode {
  // move pointer to the target's parent node and push a new node
  for (let i = 0; i < args.length - 1; i++) {
    target = target.children[args[i]];
  }

  return target;
}

export function targetNode(target: LayerNode, args: Array<number>): LayerNode {
  // move pointer to the target node
  for (let i = 0; i < args.length; i++) {
    target = target.children[args[i]];
  }

  return target;
}

export function siblingNamingFunction(siblingName: string) {
  let oldNameArr = siblingName.split('.');
  const oldNameIndex = Number(oldNameArr[oldNameArr.length - 1]);
  oldNameArr[oldNameArr.length - 1] = String(oldNameIndex + 1);

  return oldNameArr.join('.');
}

export function childNamingFunction(parentName: string, index: number) {
  let parentNameArr = parentName.split('.');
  return [...parentNameArr, String(index)].join('.');
}

function removeEmptyChildren(tree: LayerNode) {
  if (tree.children?.length) {
    tree.children = tree.children.map((child) => removeEmptyChildren(child));

    return tree;
  } else {
    delete tree.children;
    return tree;
  }
}

export const getSiteOptions =
  (): AppThunk<Promise<SiteOptions>> => async (dispatch) => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});
      const res = await fetch(CONSTANT.SITE_OPTIONS_URL);
      return res.json();
    } catch (error) {
      const webError = error as WebErrorType;
      dispatch(
        toggleModal({
          status: 'failed',
          title: webError.title,
          subtitle: webError.message,
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
