// React
import React, {useState, useEffect} from 'react';
import {useHistory, useRouteMatch, Link} from 'react-router-dom';
// Material UI Imports
import {makeStyles, Grid} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ArchiveIcon from '@material-ui/icons/Archive';
// Other packages
import moment from 'moment';
import produce from 'immer';

// Presentation, Container, Styles
import COLOR from '../../styled/colors';
import withHeader from '../../presentation/withHeader';
import SearchBar from '../../presentation/SearchBar';
import MyButton from '../../presentation/button';
import MyTable from '../../presentation/Table';
import {Buttons} from '../../presentation/ButtonsGroup';
// Redux
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import {
  createAssetProfile,
  fetchAssetProfiles,
  manageArchiveAssetProfile,
} from '../../store/assetProfile/actions';
import {RawAssetProfile} from '../../store/assetProfile/types';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';

interface Props {}

const tableHeaders = ['Asset Name', 'Created by', 'Created on', 'Actions'];

const AssetProfileLibrary: React.FC<Props> = () => {
  const history = useHistory();
  const classes = useStyles();
  const {path} = useRouteMatch();
  const dispatch = useAppDispatch();

  // States
  const [query, setQuery] = useState('');
  const assetProfileList: Array<RawAssetProfile> = useAppSelector(
    (state) => state.assetProfile.fetchedAssetProfiles,
  );

  // Side Effects
  useEffect(() => {
    dispatch(fetchAssetProfiles());
  }, [dispatch]);

  const copyAssetProfile= (assetProfile: RawAssetProfile) => {
    dispatch(
      createAssetProfile(
        produce(assetProfile.details, (draft) => {
          draft.assetName = `${draft.assetName} - Copy`;
        }),
      ),
    ).then(() => {
      dispatch(fetchAssetProfiles());
    });
  };

  const archiveAssetProfile = (assetProfile: RawAssetProfile) => {
    dispatch(
      toggleModal({
        status: 'warning',
        title: 'Archive?',
        subtitle:
          'Archived items remain active and results will still be displayed when they are scanned',
        renderButton: (
          <Buttons
            leftButtonTitle="No"
            rightButtonTitle="Yes"
            leftButtonOnClick={() => {
              dispatch(toggleModalOff());
            }}
            rightButtonOnClick={() => {
              dispatch(
                manageArchiveAssetProfile(assetProfile, true, () => {
                  dispatch(fetchAssetProfiles()); // refetch data
                }),
              );
            }}
          />
        ),
      }),
    );
  };

  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label="Search Asset Profile"
          />
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <MyButton
            text={'Create New'}
            variant="contained"
            width={160}
            fontSize={18}
            onClick={() => history.push(`${path}/new`)}
          />
        </Grid>
      </Grid>

      <MyTable
        heads={tableHeaders}
        rows={assetProfileList
          ?.filter((assetProfile) => !assetProfile.details.archived)
          .filter((assetProfile) =>
            assetProfile.details.assetName.includes(query),
          )
          .map((assetProfile, index) => [
            <Button
              onClick={() =>
                history.push(
                  `${path}/view/${
                    Object.values(assetProfile.externalIds)[0].agliveToken
                  }`,
                )
              }>
              <span
                style={{color: COLOR.GREENT_TEXT, textTransform: 'capitalize'}}>
                <u>{assetProfile.details.assetName}</u>
              </span>
            </Button>,
            assetProfile.details.createdBy,
            moment(assetProfile.externalIds[0].timestamp).format(
              'DD/MM/YYYY',
            ),
            <div style={{display: 'flex', marginRight: -100}}>
              <div style={{marginRight: 20}}>
                <EditIcon
                  style={{cursor: 'pointer'}}
                  onClick={() =>
                    history.push(
                      `${path}/edit/${
                        Object.values(assetProfile.externalIds)[0].agliveToken
                      }`,
                    )
                  }
                />
              </div>
              <div style={{marginRight: 20}}>
                <FileCopyIcon
                  onClick={() => copyAssetProfile(assetProfile)}
                  style={{cursor: 'pointer'}}
                />
              </div>
              <div style={{marginRight: 20}}>
                <ArchiveIcon
                  onClick={() => archiveAssetProfile(assetProfile)}
                  style={{cursor: 'pointer'}}
                />
              </div>
            </div>,
          ])}
      />

      <Grid item style={{marginTop: 20}}>
        <Link to={`${path}/archived`} className={classes.hyperlink}>Archived Assets</Link>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  searchBarContainer: {
    flexGrow: 1,
    marginRight: 50,
  },
  buttonContainer: {
    marginTop: 6,
    marginRight: 2,
  },
  hyperlink: {
    fontSize: '16px',
    fontFamily: 'Open Sans',
    lineHeight: '22px',
    color: COLOR.GREENT_TEXT,
  },
}));

export default withHeader(
  {
    title: 'Asset Profile Library',
  },
  AssetProfileLibrary,
);
