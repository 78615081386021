import {DropdownOption} from './types';

export const getMenuOptions = (isAngus: boolean): DropdownOption[] => {
  const menuOptions: DropdownOption[] = [];

  if (isAngus) {
    menuOptions.push({
      type: 'link',
      label: 'Angus Help Centre',
      destination: 'https://help.aglive.com/angus-verified/',
    });
  }

  menuOptions.push(
    {
      type: 'link',
      label: 'Aglive Help Centre',
      destination: 'https://help.aglive.com/aglive/',
    },
    {
      type: 'navigation',
      label: 'Health Support',
      destination: '/private/healthSupport',
    },
  );

  return menuOptions;
};
