import React, {useState, useEffect} from 'react';
import clone from 'clone';
import {useAppDispatch, useAppSelector} from '../utils/hooks';
import {Link, useRouteMatch, useLocation} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import ProfileIcon from '@material-ui/icons/AccountBoxOutlined';
import RegisterIcon from '@material-ui/icons/PlaylistAdd';
import ActivityIcon from '@material-ui/icons/Keyboard';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import ProductIcon from '@material-ui/icons/LocalOffer';
import ReportIcon from '@material-ui/icons/Assignment';
import CodesIcon from '../img/CodesIcon.png';
import VerifiedIcon from './renderVerifiedIcon';
import {PluginModel} from '@aglive/data-model';

import COLOR from '../styled/colors';
import {businessProfileTypes, userProfileTypes} from '../store/profile/types';
import CONSTANT from '../config/constant';

import {CASL} from '@aglive/frontend-core';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: theme.palette.secondary.main,
    minWidth: '200px',
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(8),
  },
  profileImage: {
    margin: 'auto',
    width: '96px',
    height: '96px',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  profileName: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 50,
  },
  profileIcon: {
    width: '96px',
    fontSize: '96px',
    color: 'white',
  },
  menuIcon: {
    height: 24,
    width: 24,
    marginRight: '16px',
    color: '#373935',
  },
  nestedLinkStyle: {
    display: 'inline-block',
    marginBottom: 0,
  },
  selectedNested: {
    backgroundColor: COLOR.GRAY_SOLID,
    paddingBottom: 0,
  },
  verifiedIcon: {
    height: 41,
    width: 41,
  },
}));

type Props = {};

// config about sidebar item
type MenuT = {
  label: string;
  linkIcon: JSX.Element;
  subroutes?: {
    [key: string]: {
      to: string;
      label: string;
      disabled?: boolean;
      remove?: boolean;
    };
  };
  to?: string;
  onClick?: () => void;
  disabled?: true;
  remove?: boolean;
};

// keep track of the UI state
type SidebarNode = {
  isExpand: boolean;
  subroutes?: {
    [key: string]: boolean;
  };
};

// key must be in the route eg. products/productProfile
interface MenuState {
  verified: MenuT;
  home: MenuT;
  profile: MenuT;
  register: MenuT;
  add: MenuT;
  activity: MenuT;
  codes: MenuT;
  assets: MenuT;
  products: MenuT;
  report: MenuT;
  unverified: MenuT;
}

type MenuKeys = keyof MenuState;

const createMenuState = (
  classes: ReturnType<typeof useStyles>,
  dispatch: ReturnType<typeof useAppDispatch>,
  businessProfile: businessProfileTypes,
  userProfile: userProfileTypes,
  ability: CASL.Ability,
  businessReports?: Array<PluginModel.PluginReport>,
  plugins?: Array<PluginModel.PluginType>,
): Partial<MenuState> => {
  const ifAustralia =
    !businessProfile.businessCountry ||
    businessProfile.businessCountry === 'Australia';
  const initialMenu: MenuState = {
    verified: {
      label: 'Angus Verified',
      linkIcon: <VerifiedIcon className={classes.verifiedIcon} />,
      to: '/verified',
      subroutes: {
        recordSires: {
          to: '/verified/recordSires',
          label: 'Record Sires',
        },
        aiJoining: {
          to: '/verified/aiJoining',
          label: 'AI Joining',
        },
        sireGroups: {
          to: '/verified/sireGroups',
          label: 'Sire Groups',
        },
        recordAnimals: {
          to: '/verified/recordAnimals',
          label: 'Record Animals',
        },
        orderTags: {
          to: '/verified/orderTags',
          label: 'Order Tags',
        },
        animalProfile: {
          to: '/verified/animalProfile',
          label: 'Animal Profile',
        },
        avpresale: {
          to: '/verified/avpresale',
          label: 'Pre-Sale Report',
        },
      },
      remove:
        !ifAustralia ||
        !(
          businessProfile.industryType === 'ANIMALS' &&
          businessProfile.businessType === 'Angus'
        ),
    },
    home: {
      label: 'Dashboard',
      to: '/',
      linkIcon: <HomeIcon className={classes.menuIcon} />,
    },
    profile: {
      label: 'Profile',
      linkIcon: <ProfileIcon className={classes.menuIcon} />,
      subroutes: {
        users: {
          to: '/profile/users',
          label: 'User Profile',
        },
        businessProfile: {
          to: '/profile/businessProfile',
          label: 'Business Profile',
          remove: ability.cannot('read', 'business'),
        },
        brand: {
          to: '/profile/brand',
          label: 'Brand Profile',
          remove:
            businessProfile.businessCountry === 'Argentina' ||
            ability.cannot('read', 'brand'),
        },
        map: {
          to: '/profile/map',
          label: 'Map',
          remove:
            businessProfile.industryType !== 'ANIMALS' || 
            (businessProfile.businessCountry === 'Argentina' &&
            (!plugins || !plugins.includes('Ceres Tag'))),
        },
        plugins: {
          to: '/profile/plugins',
          label: 'Plugins',
          remove: !CONSTANT.ENABLE_PLUGIN,
        },
      },
    },
    register: {
      label: 'Register',
      linkIcon: <RegisterIcon className={classes.menuIcon} />,
      subroutes: {
        asset: {
          to: '/register/asset',
          label: 'Asset Induction',
          disabled: false,
          remove: ability.cannot('create', 'asset'),
        },
        // form: {
        //   to: '/register/form',
        //   label: 'Form'
        // },
        // certificate: {
        //   to: '/register/certificate',
        //   label: 'Certificate'
        // }
        site: {
          to: '/register/site',
          label: 'Site',
          remove:
            businessProfile.businessCountry === 'Argentina' ||
            ability.cannot('read', 'site'),
        },
      },
      remove:
        ability.cannot('create', 'asset') && ability.cannot('read', 'site'),
    },
    add: {
      label: 'Add',
      linkIcon: <AddIcon className={classes.menuIcon} />,
      subroutes: {
        users: {
          to: '/add/users',
          label: 'Users',
        },
        formTemplate: {
          to: '/add/formTemplate',
          label: 'Saved Forms',
          remove: businessProfile.industryType !== 'ANIMALS'
        },
        consignees: {
          to: '/add/consignees',
          label: 'Consignees',
        },
        cerestag: {
          to: '/add/cerestag',
          label: 'Ceres Tag',
          remove: !plugins || !plugins.includes('Ceres Tag'),
        },
      },
      remove: ability.cannot('read', 'user'),
    },
    activity: {
      label: 'Activity',
      linkIcon: <ActivityIcon className={classes.menuIcon} />,
      subroutes: {
        manage: {
          to: '/activity/manage',
          label: 'Manage Asset',
          remove: ability.cannot('update', 'asset') || businessProfile.industryType !== 'ANIMALS'
        },
        move: {
          to: '/activity/move',
          label: 'Move',
          remove: businessProfile['multiSitePerPic'],
        },
        consignment: {
          to: '/activity/consignment',
          label: 'Consignments',
          remove: ability.cannot('read', 'consignment'),
        },
        group: {
          to: '/activity/group',
          label: 'Groups/Mobs',
          remove: ability.cannot('read', 'group'),
        }
      },
      remove: businessProfile.businessCountry === 'Argentina',
    },
    codes: {
      label: 'Codes',
      to: '/codes',
      linkIcon: (
        <img
          src={CodesIcon}
          alt="Italian Trulli"
          className={classes.menuIcon}
        />
      ),
      // linkIcon={<ActivityIcon className={classes.menuIcon} />}
      subroutes: {
        promotionCodes: {
          to: '/codes/promotionCodes',
          label: 'Promotion Codes',
          remove: businessProfile.industryType === 'PLANTS',
        },
        securityCodes: {
          to: '/codes/securityCodes',
          label: 'Security Codes',
          remove: businessProfile.industryType === 'PLANTS',
        },
        generate: {
          to: '/codes/generate', // only for plant codes
          label: 'Generate',
          remove: businessProfile.industryType !== 'PLANTS',
        },
      },
      remove:
        (businessProfile.industryType !== 'PRODUCTS' &&
          businessProfile.industryType !== 'PLANTS') ||
        businessProfile.businessCountry === 'Argentina',
    },
    assets: {
      label: 'Assets',
      to: '/assets',
      linkIcon: <ProductIcon className={classes.menuIcon} />,
      subroutes: {
        promotions: {
          to: '/assets/promotions',
          label: 'Promotions',
          remove: businessProfile.industryType === 'PLANTS',
        },
        assetProfile: {
          to: '/assets/assetProfile',
          label: 'Asset Profile',
          remove: businessProfile.industryType === 'ANIMALS',
        },
      },
      remove:
        (businessProfile.industryType !== 'PLANTS' &&
          businessProfile.industryType !== 'ANIMALS') ||
        businessProfile.businessCountry === 'Argentina' ||
        (userProfile.businessId === CONSTANT.WARAKIRRI_BUSINESS_ID &&
          ability.cannot('read', 'promotion')),
    },
    products: {
      label: 'Products',
      to: '/products',
      linkIcon: <ProductIcon className={classes.menuIcon} />,
      subroutes: {
        productProfile: {
          to: '/products/productProfile',
          label: 'Product Profile',
        },
        // items: false,
        // packageType: false,
        // packages: false,
      },
      remove:
        businessProfile.industryType !== 'PRODUCTS' ||
        businessProfile.businessCountry === 'Argentina',
    },
    report: {
      label: 'Reports',
      linkIcon: <ReportIcon className={classes.menuIcon} />,
      remove:
        businessProfile.businessCountry &&
        businessProfile.businessCountry !== 'Australia' &&
        businessReports?.length === 0,
      subroutes: {
        // export: {
        //   to: '/report/export',
        //   label: 'Export Data',
        // },
        // managementReport: {
        //   to: '/report/Management-Report',
        //   label: 'Management Report',
        //   remove: businessProfile.businessCountry !== 'Australia'
        // },
        otherReports: {
          to: '/report/otherReports',
          label: 'Others',
          remove: businessProfile.industryType !== 'PLANTS',
        },
        /*ceresTagReport: {
          to: '/report/ceresTagReport',
          label: 'Ceres Tag Report',
          remove: businessProfile.businessCountry === 'Argentina',
        },*/
      },
    },
    unverified: {
      label: 'Angus Verified',
      to: '/verified',
      linkIcon: <VerifiedIcon className={classes.verifiedIcon} />,
      disabled: true,
      remove:
        businessProfile.industryType === 'PLANTS' ||
        !ifAustralia ||
        (businessProfile.industryType === 'ANIMALS' &&
          businessProfile.businessType === 'Angus'),
    },
  };

  if (businessProfile.industryType === 'PLANTS') {
    initialMenu.register.subroutes.asset = {
      ...initialMenu.register.subroutes.asset,
      disabled: true,
    };
    delete initialMenu.assets.to;
  }

  if (businessProfile.industryType !== 'PRODUCTS') {
    delete initialMenu.codes.to;
  }

  if (businessReports?.length) {
    businessReports.forEach((rp) => {
      const rename = (rp['group'] ?? rp.type).replace(/\s/g, '-');
      if (initialMenu.report.subroutes[rename]) {
        return;
      }
      initialMenu.report.subroutes[rename] = {
        to: `/report/${rename}`,
        label: rp['group'] ?? rp.type,
        remove:
          userProfile.role?.startsWith('establishment-') &&
          !rename?.toLowerCase()?.startsWith('supply-report'),
      };
    });
  }

  return initialMenu;
};

// TODO: Decouple URL related logic away from sidebar
const Sidebar: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {path} = useRouteMatch();
  const loc = useLocation();

  const userProfile = useAppSelector((state) => state.user.userProfileData);
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const {plugins, reports} = useAppSelector((state) => state.user);

  const ability = CASL.useAbility(CASL.AbilityContext);

  // add manual conditions here to manipulate the object
  let initialMenuState = createMenuState(
    classes,
    dispatch,
    businessProfile,
    userProfile,
    ability,
    reports,
    plugins
      ?.filter((plg) => plg.status === 'activated')
      .map((plg) => plg.name) as Array<PluginModel.PluginType>,
  );

  // control the UI part of the sidebar
  let initialSidebarState = {}; // {[key in MenuKeys]: SidebarNode}

  for (const [curKey, curVal] of Object.entries(initialMenuState)) {
    initialSidebarState[curKey] = {
      isExpand: false,
    };

    if ('subroutes' in curVal) {
      initialSidebarState[curKey].subroutes = {};

      for (const subroute of Object.keys(curVal.subroutes)) {
        initialSidebarState[curKey].subroutes[subroute] = false;
      }
    }
  }

  const [sidebar, setSidebar] = useState<{[key in MenuKeys]: SidebarNode} | {}>(
    initialSidebarState,
  );

  const handleMenuSelect = (menuName: MenuKeys, forceExpand?: boolean) => () =>
    setSidebar(() => {
      let nextState = clone(initialSidebarState);
      if (forceExpand) {
        nextState[menuName].isExpand = true;
      } else {
        nextState[menuName].isExpand = !nextState[menuName]?.isExpand;
      }
      return nextState;
    });

  const handleChildSelect = (menuName: MenuKeys, childName: string) => () =>
    setSidebar(() => {
      let nextState = clone(initialSidebarState);

      nextState[menuName].isExpand = true;
      nextState[menuName].subroutes[childName] =
        !nextState[menuName].subroutes[childName];

      return nextState;
    });

  // set subroute as active if user directly visit from URL
  // HACK this is to prevent sidebar from re-rendering after businessProfile is fetched again
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (businessProfile.companyName && firstRender) {
      setFirstRender(false);
      setSidebar(initialSidebarState);
    } else {
      if (loc.pathname.split('/')[2] === 'healthSupport') {
        setSidebar(initialSidebarState);
      } else {
        const currPath = loc.pathname.split('/');
        if (currPath[2] in initialSidebarState) {
          handleMenuSelect(currPath[2] as MenuKeys, true)();

          if (currPath[3] in initialSidebarState[currPath[2]].subroutes) {
            handleChildSelect(currPath[2] as MenuKeys, currPath[3])();
          }
        } else {
          handleMenuSelect('home', true)();
        }
      }
    }
  }, [businessProfile.companyName, firstRender, loc.pathname]);

  return (
    <Grid item xs={2} className={classes.sidebar}>
      {userProfile?.image ? (
        <Avatar alt="Remy Sharp" className={classes.profileImage}>
          <img
            src={userProfile?.image}
            alt=""
            className={classes.profileIcon}
          />
        </Avatar>
      ) : (
        <Avatar alt="Remy Sharp" className={classes.profileImage}>
          <AccountCircleIcon className={classes.profileIcon} />
        </Avatar>
      )}
      <Typography variant="h6" className={classes.profileName}>
        {userProfile?.name}
      </Typography>
      <nav className={classes.lists} aria-label="mailbox folders">
        <List>
          {Object.entries(initialMenuState)
            .filter(([key, obj]) => !obj.remove)
            .map(
              ([
                key,
                {label, linkIcon, subroutes = {}, to, onClick, disabled},
              ]) => (
                <ListItemParent
                  key={key}
                  label={label}
                  linkIcon={linkIcon}
                  to={to}
                  state={sidebar[key]}
                  onClick={
                    onClick !== undefined
                      ? onClick
                      : handleMenuSelect(key as MenuKeys)
                  }
                  disabled={disabled}>
                  {Object.entries(subroutes)
                    .filter(([key, obj]) => !obj.remove)
                    .map(([childKey, {to, label, disabled}]: any) => (
                      <ListItemParent
                        key={childKey}
                        label={label}
                        to={to}
                        state={{isExpand: sidebar[key]?.subroutes[childKey]}}
                        onClick={handleChildSelect(key as MenuKeys, childKey)}
                        disabled={disabled}
                        className={`${classes.nested} ${
                          sidebar[key]?.subroutes[childKey]
                            ? classes.selectedNested
                            : ''
                        }`}
                        isSubMenu={true}
                      />
                    ))}
                </ListItemParent>
              ),
            )}
        </List>
      </nav>
    </Grid>
  );
};

type ListItemParentProps = {
  label: string;
  linkIcon?: JSX.Element;
  to?: string;
  state: SidebarNode;
  onClick: () => void;
  disabled?: true;
  children?: any;
  className?: string;
  isSubMenu?: boolean;
};

const ListItemParent: React.FC<ListItemParentProps> = ({
  label,
  linkIcon,
  to,
  state,
  onClick,
  disabled,
  children,
  className,
  isSubMenu,
}) => {
  const {path} = useRouteMatch();
  const classes = useStyles();

  return (
    <>
      <li>
        <ListItem
          button
          component={to && Link}
          to={to && `${path}${to}`}
          onClick={onClick}
          disabled={disabled}
          style={{backgroundColor: state?.isExpand && COLOR.GRAY_SOLID}}
          className={className}>
          {linkIcon}
          <ListItemText
            disableTypography
            primary={
              <>
                <Typography variant="h4" style={{marginLeft: 13}}>
                  {label}
                </Typography>
                {isSubMenu && state.isExpand && (
                  <hr
                    style={{
                      backgroundColor: 'green',
                      height: 4,
                      width: '90%',
                      marginTop: 8,
                      marginBottom: 0,
                      border: '1px solid green',
                    }}
                  />
                )}
              </>
            }
          />
          {children?.length ? (
            state?.isExpand ? (
              <ExpandLess />
            ) : (
              <ExpandMore style={{color: COLOR.GRAY_BORDER}} />
            )
          ) : state?.isExpand && !isSubMenu ? (
            <hr
              style={{
                backgroundColor: 'green',
                height: 4,
                width: 1000,
                marginTop: 40,
                marginBottom: -8,
                border: '1px solid green',
              }}
            />
          ) : null}
        </ListItem>
      </li>

      {children?.length ? (
        <Collapse
          component="li"
          in={state?.isExpand}
          timeout="auto"
          unmountOnExit>
          <List disablePadding>{children}</List>
        </Collapse>
      ) : null}
    </>
  );
};

export default Sidebar;
