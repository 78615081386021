import React, { useState } from 'react';

import withHeader from '../../presentation/withHeader';

import MyButton from '../../presentation/button';
import {useHistory} from 'react-router-dom';
import RadioButtonGroup from '../../presentation/RadioButtonGroup';
import { Grid, makeStyles, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';

const data = [
  {
    label: "CSV Template",
    value: "csv",
    key: "csv",
  }, 
  {
    label: "Single Asset (Coming Soon)",
    value: "single",
    key: "single",
    disabled: true
  }, 
  {
    label: "Group by Location (Coming Soon)",
    value: "group",
    key: "group",
    disabled: true
  }
];

const useStyle = makeStyles(theme => ({
  bodyContainer: {
    height: 500
  },
  dropdownContainer: {
    marginLeft: 35, 
    width: 500
  },
  buttonContainer: {
    alignSelf: 'flex-end'
  }
}));

const ManageAsset: React.FC<{}> = () => {
  const classes = useStyle();
  const [value, setValue] = useState(null as unknown as string);
  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);
  const history = useHistory();
  // const {location: {pathname}} = history;
  // const path = `${pathname}${pathname.slice(-1) === '/' ? '' : '/'}csv`; // TODO research on correct navigation method

  return (
    <Grid container direction='column' justify='space-between' className={classes.bodyContainer}>
      <Grid item container direction='column'>
        <RadioButtonGroup
          onChange={handleChange}
          title="Manage by:"
          aria-label="manage-asset"
          name="manageBy"
          value={value}
          options={data}
        />
        <Grid item>
          <FormControl variant="outlined" className={classes.dropdownContainer}>
            <InputLabel id="manage-asset-select-location" disabled>Please select</InputLabel>
            <Select
              labelId="manage-asset-select-location"
              value={'Location 1'}
              disabled
              label="Please select"
            >
              {[{label: 'Location 1', value: 'Location 1'}].map(({label, value}) => <MenuItem value={value} key={label}>{label}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item className={classes.buttonContainer}>
        <MyButton
          disabled={!value}
          onClick={() => history.push('/private/activity/csv')}
          text='Next'
          variant='contained'
        />
      </Grid>
    </Grid>
  );
};

export default withHeader({
  title: 'Manage Asset',
  margin: 60,
  back: true,
}, ManageAsset);
