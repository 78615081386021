import React, {useState, useEffect} from 'react';
import moment from 'moment';
import COLOR from '../../styled/colors';

import {useHistory, useRouteMatch} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';

import {Buttons} from '../../presentation/ButtonsGroup';
import SearchBar from '../../presentation/SearchBar';
import MyButton from '../../presentation/button';
import MyTable from '../../presentation/Table';
import withHeader from '../../presentation/withHeader';
import EditIcon from '@material-ui/icons/Edit';

import DeleteIcon from '@material-ui/icons/Delete';

import {makeStyles, Grid, Button} from '@material-ui/core';
import {toggleModal, toggleModalOff} from '../../store/modal/actions';
import {CASL} from '@aglive/frontend-core';

import {deleteGroup, fetchGroupData} from '../../store/group/actions';
const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    marginBottom: 30,
  },
  searchBarContainer: {
    flexGrow: 1,
    marginRight: 50,
  },
  buttonContainer: {
    marginTop: 6,
    marginRight: 2,
  },
  label: {
    textTransform: 'none',
  },
}));

const GroupLibrary: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {path} = useRouteMatch();

  const [query, setQuery] = useState('');
  const mob = useAppSelector((state) => state.group.groupData);

  const ability = CASL.useAbility(CASL.AbilityContext);
  const TABLE_HEADER = [
    'Group/Mob Name ',
    'Items/Animals',
    'Location e.g. PIC',
    'Date Created',
  ];
  ability.can('update', 'group')
    ? TABLE_HEADER.push('Action')
    : TABLE_HEADER.push('');

  useEffect(() => {
    dispatch(fetchGroupData());
  }, []);

  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar
            query={query}
            setQuery={setQuery}
            label="Search by group/mob name"
          />
        </Grid>
        <CASL.Can I="create" a="group">
          <Grid item className={classes.buttonContainer}>
            <MyButton
              text={'Create New'}
              variant="contained"
              width={160}
              fontSize={18}
              onClick={() => history.push(`${path}/new`)}
            />
          </Grid>
        </CASL.Can>
      </Grid>
      <MyTable
        heads={TABLE_HEADER}
        rows={mob
          ?.filter((item) =>
            item.name.toLowerCase().includes(query.toLowerCase()),
          )
          .map((mobItem,index) => [
            <Button
              onClick={() =>
                history.push(`${path}/view/${mobItem.agliveToken}`)
              }
              classes={{
                label: classes.label,
              }}>
              <span style={{color: COLOR.GREENT_TEXT}}>
                <u>{mobItem.name}</u>
              </span>
            </Button>,
            mobItem.animalsItemsCount,
            mobItem.pic_id,
            moment(mobItem.createdAt).format('DD/MM/YYYY'),
            <CASL.Can I="update" a="group">
              <div style={{display: 'flex'}}>
                <div style={{marginRight: 20}}>
                  <EditIcon
                  id={`editGroup-${index}`}
                    onClick={() =>
                      history.push(`${path}/edit/${mobItem.agliveToken}`)
                    }
                  />
                </div>
                <div style={{marginRight: 20}}>
                  <DeleteIcon
                  id={`deleteGroup-${index}`}
                    onClick={() =>
                      dispatch(
                        toggleModal({
                          status: 'warning',
                          title: 'Delete Group/Mob?',
                          subtitle: [
                            'This action cannot be undone',
                            'Ungrouped items remain in your account',
                          ],
                          renderButton: (
                            <Buttons
                              leftButtonTitle="Cancel"
                              rightButtonTitle="Delete"
                              leftButtonOnClick={() =>
                                dispatch(toggleModalOff())
                              }
                              rightButtonOnClick={() => {
                                dispatch(toggleModalOff());
                                dispatch(deleteGroup(mobItem));
                              }}
                            />
                          ),
                        }),
                      )
                    }
                  />
                </div>
              </div>
            </CASL.Can>,
          ])}
      />
    </>
  );
};

export default withHeader(
  {
    title: 'Groups/Mobs',
  },
  GroupLibrary,
);
