import axios, {Method, ResponseType, AxiosRequestConfig} from 'axios';

import API from '../../config/api';
import {AppThunk} from '../types';
import {SPINNER_TOGGLE_ON, SPINNER_TOGGLE_OFF} from '../spinner/types';
import {toggleModal} from '../modal/actions';
import {
  iCreateAssetMetaData,
  iUpdateAssetMetaData,
  MultiSelectSetType,
} from './types';
import {checkFileValid} from "../../utils/fileValidator"
import {callAPI} from '../../utils/network';
import {NetworkError} from '../../utils/error';
import {RouteComponentProps} from 'react-router-dom';
// {
//   pic_id: location,
//   pic_name: Object.values(locationState.location).find(item => item.PICAddress === location).locationNickname,
//   userid,
//   assetType: CONSTANT.ASSETTYPE.ASSET,
//   file,
//   csvData,
// },

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

export function createTokensByCSV(
  metaData: iCreateAssetMetaData,
  successCB = () => {},
  subtitle?: string,
  isGroup?: boolean,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      //Check file is valid before submitting
      await checkFileValid(metaData.file)

      const formData = new FormData();
      Object.entries(metaData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.delete('csvData'); // only pass csvData to obtain asset data

      // onClick sendTransaction to mobile
      // open spinner wait for result

      dispatch({type: SPINNER_TOGGLE_ON});

      const data = await callAPI({
        url: isGroup ? API.POST.postGroupCSV : API.POST.postCSV,
        method: 'POST',
        data: formData,
      });

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Successfully Completed',
          button: 'Close',
          CTAHandler: successCB,
          subtitle: subtitle,
        }),
      );
      dispatch({type: SPINNER_TOGGLE_OFF});

      return Promise.resolve(data);
    } catch (e) {
      dispatch({type: SPINNER_TOGGLE_OFF});

      return Promise.reject(new NetworkError(e));
    }
  };
}

// {
//   template: data[index].value as any,
//   userid,
//   updateType: data[index].updateType,
//   file,
//   csvData
// },

export function manageAssetByCSV(
  metaData: iUpdateAssetMetaData,
  isGroup?: boolean
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    try {
      //Check file is valid before submitting
      await checkFileValid(metaData.file)
      
      const formData = new FormData();
      Object.entries(metaData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.delete('csvData'); // only pass csvData to obtain asset data

      dispatch({type: SPINNER_TOGGLE_ON});

      const data = await callAPI({
        url: isGroup ? API.POST.updateGroupAssetsByCSV : API.POST.updateAssetsByCSV,
        method: 'POST',
        data: formData,
      });

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Successfully Completed',
          button: 'Close',
        }),
      );

      dispatch({type: SPINNER_TOGGLE_OFF});

      return Promise.resolve(data);
    } catch (e) {
      dispatch({type: SPINNER_TOGGLE_OFF});

      return Promise.reject(new NetworkError(e));
    }
  };
}

export function exportReport(data: any): AppThunk<Promise<void>> {
  return async (dispatch) => {
    try {
      const url = `${API.POST.exportReport}`;
      const PostMethod: Method = 'POST';
      const blobResType: ResponseType = 'blob';

      const config = {
        url,
        method: PostMethod,
        data,
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: blobResType,
      };

      downloadCSV(config, undefined, (error) =>
        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          }),
        ),
      );
    } catch (e) {
      const error = new NetworkError(e);
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        }),
      );
    }
  };
}

export async function fetchReports(config: AxiosRequestConfig) {
  // const response = await axios.request(config);

  // if (response.status !== 200) throw new NetworkError(response);
  // return Promise.resolve(response.data);
  const response = await callAPI(config);
  return Promise.resolve(response);
}

export async function fetchCeresReports(config: AxiosRequestConfig) {
  // const response = await axios.request(config);

  // if (response.status !== 200) throw new NetworkError(response);
  // return Promise.resolve(response.data);
  const response = await callAPI(config);
  return Promise.resolve(response);
}

export async function downloadCSV(
  config: AxiosRequestConfig,
  reportName = 'report.csv',
  errorCB: (e: NetworkError) => void,
) {
  try {
    const response = await axios.request(config);

    if (response.status !== 200) throw new NetworkError(response);
    let csvData = new Blob([response.data], {
      type: 'text/csv;charset=utf-8;',
    });
    //IE11 & Edge
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(csvData, reportName);
    } else {
      //In FF link must be added to DOM to be clicked
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(csvData);
      link.setAttribute('download', reportName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (e) {
    const error = new NetworkError(e);

    errorCB(error);
  }
}

export async function fetchCSV(userid: string) {
  try {
    const data = await callAPI({
      url: API.POST.getCSVbyUser,
      method: 'POST',
      data: {
        activityType: [],
        externalIds: [
          {
            userId: userid,
          },
        ],
      },
    });

    return data;
  } catch (e) {
    throw new NetworkError(e);
  }
}

export function generateManagementReportCSV(
  csvHeader: Array<Array<string>>,
  codeTokens: Array<Array<string>>,
  csvName = 'ManagementReport.csv',
): AppThunk {
  return () => {
    let csvContent =
      'data:text/csv;charset=utf-8,' +
      csvHeader.map((row) => row.join(',')).join('\n') +
      '\n' +
      codeTokens.map((row) => row.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', csvName);
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file
  };
}

export function getAssetGroups(
  type: 'group' | 'asset',
  pic_id: string,
): AppThunk<Promise<any>> {
  return async (dispatch) => {
    dispatch({type: SPINNER_TOGGLE_ON});
    try {
      const response = await callAPI({
        url: API.GET.getTokensWithFilters,
        method: 'GET',
        params: {
          type,
          pic_id,
        },
      });

      return Promise.resolve(response);
    } catch (error) {
      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title ?? 'Unexpected Error',
          subtitle: error.message ?? 'Unexpected Error',
        }),
      );
      return Promise.reject(new NetworkError(error));
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  };
}