import React, { useEffect, useReducer } from 'react';
import produce from 'immer';
import { useParams, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks';

import { Grid, Typography } from '@material-ui/core';

import MyButton from '../../../../presentation/button';
import CreateProductProfileDelivery from '../../../promotion/ProductProfile/CreateProductProfileDelivery';
import withHeader from '../../../../presentation/withHeader';

import codeActions, { SecuritCodeActionTypes } from '../GenerateCodes/actions';
import localActions from './actions';
import { toggleModal } from '../../../../store/modal/actions';
import { SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON } from '../../../../store/spinner/types';
import { callAPI } from '../../../../utils/network';
import { reduceToggleConfidentialMode, reduceToggleMapView, reduceToggleShowDeliveryHistory } from '../GenerateCodes/reducer';
import { fetchProductProfileTokens } from '../../../promotion/ProductProfileLibrary/utils';
import { fetchEntries } from '../../../../store/code/actions';

import useStyles from '../GenerateCodes/styles';

import API from '../../../../config/api';

import { TokenService } from '@aglive/data-model';
import { WebErrorType } from '../../../../utils/error';
import { iCodeEntryDocument, iCodeEntryDocumentResponse } from '../../../../store/code/types';
import { EditCodeActionTypes } from './actions';

const INITIAL_EDIT_CODE_STATE = {
  state: {
    productProfile: null as TokenService.ProductToken,
    oldEntry: null as iCodeEntryDocumentResponse<iCodeEntryDocument>
  },
  payload: {
    entry: null as iCodeEntryDocumentResponse<iCodeEntryDocument>
  }
};

const editCodeReducer = (
  state: typeof INITIAL_EDIT_CODE_STATE,
  action: SecuritCodeActionTypes | EditCodeActionTypes) => {
    switch (action.type) {
      case 'load/load_tokens':
        return produce(state, draft => {
          draft.state.oldEntry = action.payload.entry;
          draft.payload.entry = action.payload.entry;
          draft.state.productProfile = action.payload.productProfile;
        });
      
      // delivery operations
      case 'delivery/toggle_showDeliveryHistory':
        return produce(state, draft => {
          reduceToggleShowDeliveryHistory(
            draft.payload.entry.details.product,
            state.payload.entry.details.product,
          );
        });
      case 'delivery/toggle_mapView':
        return produce(state, draft => {
          reduceToggleMapView(
            draft.payload.entry.details.product,
            state.payload.entry.details.product,
          );
        });
      case 'delivery/toggle_confidentialMode':
        return produce(state, draft => {
          reduceToggleConfidentialMode(
            draft.payload.entry.details.product,
            state.payload.entry.details.product,
          );
        });
      default:
        return state;
    }
  }

const EditCode: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { entryId } = useParams<{ entryId: string }>();
  const userID = useAppSelector(state => state.auth.wallet);
  const [securityCodeState, localDispatch] = useReducer(editCodeReducer, INITIAL_EDIT_CODE_STATE);

  const codeList = useAppSelector((state) => state.code) as Array<iCodeEntryDocumentResponse<iCodeEntryDocument>>;

  useEffect(() => {
    async function loadTokens() {
      try {
        let targetEntry: iCodeEntryDocumentResponse<iCodeEntryDocument> = null;
        if (codeList.length) {
          // find the targeted entry
          targetEntry = codeList.find(entry => entry.docId === entryId);
        } else {
          [targetEntry] = await fetchEntries<iCodeEntryDocument>(userID, entryId);
        }

        const [productProfile] = await fetchProductProfileTokens([{agliveToken: targetEntry.details.product.product.agliveToken}]);
        localDispatch(localActions.loadTokens({
          entry: targetEntry,
          productProfile,
        }));
      } catch (e) {
        const error = e as WebErrorType;
            
        dispatch(
          toggleModal({
            status: 'failed',
            title: error.title,
            subtitle: error.message,
          })
        );
      }
    }

    loadTokens();
  }, []);

  const onSave = async () => {
    try {
      dispatch({type: SPINNER_TOGGLE_ON});

      // TODO add back end bulkUpdate request code here
      await callAPI({
        url: API.POST.postDocument,
        method: 'PUT',
        data: securityCodeState.payload.entry
      })

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Updated',
          button: 'Close',
          CTAHandler: history.goBack,
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;

      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  }

  return (
    <Grid container direction="column">
      <Grid direction="column" item container className={classes.productContainer}>
        <Grid item container style={{ marginBottom: 50 }}>
          <Grid xs={6} item container direction="column">
            <Typography
              variant='h6'
              style={{ 
                fontWeight: 'bold',
                marginBottom: 10,
              }}
            >
              Product Name
            </Typography>
            <Typography variant='h6'>{securityCodeState.state.productProfile?.details.name}</Typography>
          </Grid>

          <Grid xs={6} item container direction="column">
            <Typography
              variant='h6'
              style={{ 
                fontWeight: 'bold',
                marginBottom: 10,
              }}
            >
              Available Codes
            </Typography>
            <Typography variant='h6'>{securityCodeState.state.oldEntry?.details.codes.length}</Typography>
          </Grid>
        </Grid>

        <Grid item container style={{marginTop: 50}}>
          <CreateProductProfileDelivery
            showDeliveryHistory={securityCodeState.payload.entry?.details.product.showDeliveryHistory || false}
            showMap={securityCodeState.payload.entry?.details.product.showMap || false}
            isConfidential={securityCodeState.payload.entry?.details.product.isConfidential || false}
            onHistoryToggle={() => localDispatch(codeActions.toggleDeliveryHistory())}
            onMapViewToggle={() => localDispatch(codeActions.toggleDeliveryMapView())}
            onConfidentialModeToggle={() => localDispatch(codeActions.toggleDeliveryConfidentialMode())}

            mediaType={securityCodeState.state.productProfile?.details.pages[0].display.item}
            mediaUrl={securityCodeState.state.productProfile?.details.pages[0].display.url}
            headline1={securityCodeState.state.productProfile?.details.pages[0].headline[0]}
            headline2={securityCodeState.state.productProfile?.details.pages[0].headline[1]}
            headline3={securityCodeState.state.productProfile?.details.pages[0].headline[2]}
            contentArr={securityCodeState.state.productProfile?.details?.pages[0].content}
            attachmentArr={securityCodeState.state.productProfile?.details.pages[0].attachment}
          />
        </Grid>
      </Grid>

      <Grid item container justify="flex-end">
        <MyButton
          text="Save"
          variant="contained"
          onClick={onSave}
        />
      </Grid>
    </Grid>
  );
};

export default withHeader(
  {
    title: "Edit Security Codes",
    back: true
  },
  EditCode
);
