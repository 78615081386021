
import { makeStyles } from "@material-ui/core";
import COLOR from "../../../styled/colors";

export const useStyle = makeStyles((theme) => ({
    contentButton: {
      borderColor: COLOR.GREEN,
      color: COLOR.GREEN,
    },
    fieldContainer: {
      backgroundColor: COLOR.GRAY_SOLID,
    },
    inputField: {
      backgroundColor: COLOR.WHITE,
      width: '100%',
    },
    uploadBtn: {
      width: '100%',
    },
    errorHelperText: {
      backgroundColor: COLOR.GRAY_SOLID,
      margin: 0,
    },
  }));