import React from 'react';

import {Grid, Typography} from '@material-ui/core';
import {CustomCheckbox} from '../../asset/MoveTable';
import {useStyles} from './styles';

interface DeclarationCheckboxProps {
  checked: boolean;
  message: string;
  errorText?: string;
  disabled?: boolean;
  containerStyle?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DeclarationCheckbox: React.FC<DeclarationCheckboxProps> = ({
  checked,
  message,
  disabled,
  errorText,
  containerStyle,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" className={containerStyle}>
      <Grid item style={{opacity: disabled ? 0.5 : 1}}>
        <CustomCheckbox
          className={`${classes.checkbox} ${errorText && classes.errorText}`}
          checked={checked}
          onChange={(e) => onChange && onChange(e)}
          disabled={disabled}
        />
      </Grid>
      <Grid item style={{flex: 1, marginTop: '2px'}}>
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
        <Typography
          variant="body2"
          className={classes.errorText}
          style={{fontSize: 14}}>
          {errorText}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DeclarationCheckbox;
