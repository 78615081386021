import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import SmallIPhone8 from "../img/SmallIPhone8.png";
import WaterMarks from "../img/Watermarks.png";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import COLOR from '../../src/styled/colors';
import defaultImage from '../img/defaultImage.png';
import Divider from '@material-ui/core/Divider';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { pageArray } from "../container/brand/BrandProfile/brandProfileState/reducer";
import { TokenService } from "@aglive/data-model";

const useStyle = makeStyles((theme) => ({
  mobilePreviewContainer: {
    width: 136,
    height: 136,
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
    marginRight: 16,
    borderBottom: `4px solid ${COLOR.GREEN_OPTION}`,
    backgroundColor: COLOR.GRAY_SOLID,
  },
  imgMain: {
    // borderRadius: '50%', 
    width: '25px',
    height:' 11px',
    marginTop:10,
  },
  imageView:{
    display: "flex",
    justifyContent: "center",
  },
  secondaryText:{
    marginTop:2,
    display: "flex",
    justifyContent: "center",
  },
  imgsecondary: {
    // borderRadius: '50%', 
    width: '13px',
    height:'5px',
    marginTop:2,
  },

}));

interface iSmallIMockPhone {
  onClick: () => void;
  pageName: typeof pageArray[number];
  isFocused: boolean;
  brand: TokenService.BrandToken["details"];
}

const SmallMockPhone: React.FC<iSmallIMockPhone> = ({
  onClick,
  pageName,
  isFocused,
  brand,
}) => {
  const classes = useStyle();

  return (
    <>
      <Typography variant="h6" style={{ fontWeight: 600 , marginTop:24}}>
        {pageName}
      </Typography>
      <div
        className={classes.mobilePreviewContainer}
        onClick={onClick}
        style={{
          border: !isFocused && "none",
          backgroundColor: !isFocused && "white",
        }}>
        {pageName==='Launch Screen'&& <ProductPageLaunch page={brand.launchScreen}/>}
        {pageName==='Splash Page'&& <ProductPageSplash page={brand.launchScreen}/>}
        {pageName==='About Us'&& <ProductPageAboutUs page={brand.aboutUs}/>}
      </div>
    </>
  );
};

interface iProductMockupPhoneLaunch {
  page: TokenService.BrandToken["details"]["launchScreen"]; 
};

const ProductPageLaunch: React.FC<iProductMockupPhoneLaunch> = ({page}) => {
  const useStyle2 = makeStyles((theme) => ({
    buttonView: {
      display: "flex",
      justifyContent: "center",
      marginLeft:8,
      marginRight:8,
    },
    smalliPhone8: {
      backgroundImage: `url(${SmallIPhone8})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: 50,
      height: 105,
      display: "flex",
      flexDirection: "column",
      backgroundColor: page.theme.main,
      borderRadius: 7,
      marginLeft: 61,
      marginTop: 18,
    },
    rectangle:{
      height: 5,
      width: 15,
      backgroundColor: page.theme.main,
      marginTop:8,
      display: "flex",
      justifyContent: "center",
    },
    rectangle2:{
      height: 4,
      width: 13,
      backgroundColor: COLOR.WHITE,
      border:`0.1px solid ${page.theme.main}`,
      marginTop:2,
      display: "flex",
      justifyContent: "center",
    }
  }));
  const classes = useStyle();
  const classes2 = useStyle2();

  return (
    <div className={classes2.smalliPhone8}>
      <div
        style={{
          backgroundColor: "white",
          marginTop: 20,
          width: 37,
          height: 71.5,
          borderRadius: "3px 3px 0 0",
          marginLeft: 6.15,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}>
        <div className={classes.imageView}>
          <img
            src={page.display.mainLogo || defaultImage}
            alt="Display preview"
            className={classes.imgMain}
          />
        </div>
        <div className={classes.secondaryText}>
          <Typography
            role="label"
            style={{                           
              color: page.theme.text,
              fontSize:1.5, 
            }}>
            {page.display.logoText}
          </Typography>
        </div>
        <div className={classes.imageView}>
          <img
            src={page.display.secondaryLogo || defaultImage}
            alt="Display preview"
            className={classes.imgsecondary}
          />
        </div>

        <div  className={classes2.buttonView}>
          <div className={classes2.rectangle}>
            <Typography                    
              role="label"
              style={{                           
                color: COLOR.WHITE,
                fontSize:1.5, 
                marginTop:1.5
              }}>
              Create Account
            </Typography>
          </div>
        </div>
        <div className={classes2.buttonView}>
          {/* <MyButton
          text={'Recover Account'}
          variant="outlined"
          // width={8}
          fontSize={1.5}
          buttonClass={classes2.button2}
          onClick={()=>{}}
          />  */}
          <div className={classes2.rectangle2}>
            <Typography                    
              role="label"
              style={{                           
                color: page.theme.main,
                fontSize:1.5, 
                marginTop:1.5
              }}>
              Recover Account
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 1.5,
          }}>
          <img src={WaterMarks} style={{ width: 13, height: 2 }} alt="" />
        </div>
      </div>
    </div>
  )
};

const ProductPageSplash: React.FC<iProductMockupPhoneLaunch> = ({page}) => {
  const useStyle2 = makeStyles((theme) => ({
    imgMain: {
      // borderRadius: '50%', 
      width: '25px',
      height:' 11px',
      marginTop:18,
    },
    secondaryText: {
      marginTop:5,
      display: "flex",
      justifyContent: "center",
    },
    imgsecondary: {
      // borderRadius: '50%', 
      width: '13px',
      height:'5px',
      marginTop:5,
    },
    smalliPhone8: {
      backgroundImage: `url(${SmallIPhone8})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: 50,
      height: 105,
      display: "flex",
      flexDirection: "column",
      backgroundColor: page.theme.main,
      borderRadius: 7,
      marginLeft: 61,
      marginTop: 18,
    },
  }));

  const classes = useStyle();
  const classes2 = useStyle2();

  return(
    <div className={classes2.smalliPhone8}>
      <div
        style={{
          backgroundColor: "white",
          marginTop: 20,
          width: 37,
          height: 71.5,
          borderRadius: "3px 3px 0 0",
          marginLeft: 6.15,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}>
        <div className={classes.imageView}>
          <img
            src={page.display.mainLogo || defaultImage}
            alt="Display preview"
            className={classes2.imgMain}
          />
        </div>
        <div className={classes2.secondaryText}>
          <Typography           
            role="label"
            style={{                           
              color: page.theme.text,
              fontSize:2, 
              fontWeight: 600,
            }}>
            {page.display.logoText}
          </Typography>
        </div>
        <div className={classes.imageView}>
          <img
            src={page.display.secondaryLogo || defaultImage}
            alt="Display preview"
            className={classes2.imgsecondary}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 12,
          }}>
            <img src={WaterMarks} style={{ width: 13, height: 3 }} alt=""/>
        </div>
      </div>  
    </div>
  );
};

interface iProductMockupPhoneAboutUs {
  page: TokenService.BrandToken["details"]["aboutUs"]; 
};
const ProductPageAboutUs: React.FC<iProductMockupPhoneAboutUs> = ({page}) => {
  const useStyle2 = makeStyles((theme) => ({
    smalliPhone8: {
      backgroundImage: `url(${SmallIPhone8})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      width: 50,
      height: 105,
      display: "flex",
      flexDirection: "column",
      backgroundColor: page.theme.main,
      borderRadius: 7,
      marginLeft: 61,
      marginTop: 18,
    },
  }));

  const classes2 = useStyle2();

  return(
    <div className={classes2.smalliPhone8}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <ArrowBackIosIcon
          color="secondary"
          style={{
            marginTop: 15,
            marginLeft: 6.5,
            marginRight: 2,
            width: 3.5,
            height: 3,
            color:page.theme.heading,
          }}
        />
        <Typography
          variant="h5"
          role="label"
          style={{
            marginTop: 15,
            color: page.theme.heading,
            fontWeight: 200,
            fontSize: 1.5,
          }}>
          About Us
        </Typography>
      </div>

      <div
        style={{
          backgroundColor: "white",
          marginTop: 1,
          width: 37,
          height: 71.5,
          borderRadius: "3px 3px 0 0",
          marginLeft: 6.15,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}>
        <div>
          <div
            style={{
              width: '100%',
              height: 20,
              borderRadius: "3px 3px 0 0",               
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            {page.display.item === 'video'
              ? <img src={defaultImage} alt="Display preview" width="37" height="20" />
              : page.display.imageUrl
                ? <img src={page.display.imageUrl} alt="Display preview" width="37" height="20"/>
                : <img src={defaultImage} alt="Display preview"  width="37" height="20" />
            }
            {/* {page.display.imageUrl ? (
                <img src={page.display.imageUrl} alt="Display preview"  width= "100%"
                height="119" />
            ) : (
                <img src={defaultImage} alt="Display preview"  width= "100%"
                height="119" />
            )} */}

            {/* Image or Video here */}
          </div>
          <Typography
            variant="body1"
            style={{
              fontWeight: 600,
              fontSize: 2,
              marginTop: 4,
              marginLeft: 2,
            }}>
            {page.headLine.heading}
          </Typography>
          <div style={{display: "flex",justifyContent:"row"}}>
            <LocationOnIcon style={{ fontSize: 2 ,marginLeft: 9}} />
            <Typography
              variant="h6"
              style={{
                fontSize: 1.5,
                marginTop: 2,
                marginLeft: 2,
              }}>
              {page.headLine.address}
            </Typography>
          </div>
              
          <Typography
            variant="body2"
            style={{               
              fontSize: 1.5,
              marginTop: 2,
              marginLeft: 2,
            }}>
            <u>{page.headLine.website}</u>
          </Typography>
          <div style={{marginTop:2, marginBottom:2}}>
            <Divider light variant="middle"/>
          </div>
              
          <Typography
            variant="body2"
            style={{
              fontSize: 1.5,
              marginTop: 2,
              marginLeft: 2,
            }}>
            {page.content.description}
          </Typography>
        </div>
      </div>
    
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 1.5,
        }}>
        <img src={WaterMarks} style={{ width: 13, height: 3 }} alt=""/>
      </div>
    </div>
  );
};

export default SmallMockPhone;
