import React from 'react';

import moment from 'moment';
import {TokenService} from '@aglive/data-model';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PrintIcon from '@material-ui/icons/Print';
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state';
import {Menu, MenuItem} from '@material-ui/core';
import {PopupState as IPopupState} from 'material-ui-popup-state/core';

import ArchiveIcon from '../../../img/ArchiveIcon.svg';
import DownloadIcon from '../../../img/DownloadIcon.svg';
import {CompleteProductPromotion} from '../../../store/promotion/types';

interface PopupMenuProps {
  promotion: TokenService.AnimalPromotion | CompleteProductPromotion;
  setPrintContent: React.Dispatch<
    React.SetStateAction<{
      display: TokenService.PromotionToken['details']['display'];
      story: TokenService.ProductToken['details']['story'];
      agliveToken: string;
    }>
  >;
  downloadPoster: () => void;
  archivePromotion: (promotion: TokenService.PromotionToken) => void;
}

const PopupMenu: React.FC<PopupMenuProps> = ({
  promotion,
  setPrintContent,
  downloadPoster,
  archivePromotion,
}) => {
  const handleDownloadClick = (popupState: IPopupState) => {
    popupState.close();
    downloadPoster();
  };

  const handlePrintClick = (popupState: IPopupState) => {
    popupState.close();
    setPrintContent({
      display: promotion.details.display,
      story: promotion.details.story,
      agliveToken: promotion.externalIds[0].agliveToken,
    });
  };

  const handleArchiveClick = (popupState: IPopupState) => {
    popupState.close();
    archivePromotion(promotion);
  };

  return (
    <PopupState variant="popover">
      {(popupState) => (
        <>
          <MoreVertIcon
            style={{cursor: 'pointer'}}
            {...bindTrigger(popupState)}
          />
          <Menu
            {...bindMenu(popupState)}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}>
            {promotion.details.display.endDate &&
              moment(promotion.details.display.endDate).valueOf() >
                moment().valueOf() &&
              promotion.details.status === 'published' && (
                <div>
                  <MenuItem
                    onClick={() => {
                      handleDownloadClick(popupState);
                    }}>
                    <img
                      src={DownloadIcon}
                      width="27px"
                      height="27px"
                      style={{
                        cursor: 'pointer',
                        marginRight: '8px',
                      }}
                    />
                    Download
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handlePrintClick(popupState);
                    }}>
                    <PrintIcon
                      style={{
                        cursor: 'pointer',
                        marginRight: '8px',
                      }}
                    />
                    Print
                  </MenuItem>
                </div>
              )}
            <MenuItem
              onClick={() => {
                handleArchiveClick(popupState);
              }}>
              <img
                src={ArchiveIcon}
                width="27px"
                height="27px"
                style={{cursor: 'pointer', marginRight: '8px'}}
              />
              Archive
            </MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  );
};

export default PopupMenu;
