import { TokenService } from "@aglive/data-model";

// metadata actions
const navigatePages = (page: 'deliveryHistory' | 'generateCode') => ({
  type: 'navigate' as const,
  payload: page
});

// product related actions
const loadProductProfileTokens = (tokens: Array<TokenService.ProductToken>) => ({
  type: 'load/load_productProfiles' as const,
  payload: tokens,
});

const toggleProductDropdown = () => ({
  type: 'toggle/product_dropdown' as const,
});

const selectProductProfileId = (agliveTokenId: string) => ({
  type: 'select/product_token_id' as const,
  payload: agliveTokenId,
}) 

// delivery actions
const toggleDeliveryHistory = () => ({
  type: 'delivery/toggle_showDeliveryHistory' as const
});

const toggleDeliveryMapView = () => ({
  type: 'delivery/toggle_mapView' as const
});

const toggleDeliveryConfidentialMode = () => ({
  type: 'delivery/toggle_confidentialMode' as const
});

// code actions
const selectProductContainer = (container: TokenService.ProductSecurityCode["details"]["container"]) => ({
  type: 'code/select_productContainer' as const,
  payload: container
});

const inputCodeQuantity = (quantity: string) => ({
  type: 'code/input_quantity' as const,
  payload: quantity
});

// validation
const submitForm = () => ({
  type: 'submit/validate_form' as const,
});

export type SecuritCodeActionTypes = ReturnType<
  typeof loadProductProfileTokens
  | typeof navigatePages
  | typeof selectProductProfileId
  | typeof toggleProductDropdown

  | typeof toggleDeliveryHistory
  | typeof toggleDeliveryMapView
  | typeof toggleDeliveryConfidentialMode

  | typeof selectProductContainer
  | typeof inputCodeQuantity

  | typeof submitForm
>;

export default {
  loadProductProfileTokens,
  navigatePages,
  selectProductProfileId,
  toggleProductDropdown,

  toggleDeliveryHistory,
  toggleDeliveryMapView,
  toggleDeliveryConfidentialMode,

  selectProductContainer,
  inputCodeQuantity,

  submitForm,
};