import { TokenService } from "@aglive/data-model";
import produce from "immer";
import { baseBrandActionTypes } from "./actions";

export const pageArray = ['Launch Screen', 'Splash Page', 'About Us'] as const;

export enum LaunchScreenTabMap {
  display,
  theme,
}

export const LaunchScreenTabKeys = Object.values(LaunchScreenTabMap).slice(0, 2);

export enum AboutUsTabMap {
  display,
  headline,
  content,
  theme,
}

export const AboutUsTabKeys = Object.values(AboutUsTabMap).slice(0, 4);

export const INITIAL_LAUNCH_DISPLAY: TokenService.BrandToken["details"]["launchScreen"] = {
  display: {
    mainLogo: null,
    secondaryLogo: null,
    logoText: '',
  },
  theme: {
    main: '#6D7278',
    text: '#4A4A4A',
  },
};

export const INITIAL_ABOUT_US: TokenService.BrandToken["details"]["aboutUs"] = {
  display: {
    item: 'image',
    imageUrl: '',
    videoUrl: '',
  },
  headLine: {
    heading: '',
    address: '',
    website: '',
  },
  content: {
    description: '',
  },
  theme: {
    main: '#6D7278',
    heading: '#FFFFFF',
  },
};


export const INITIAL_BRAND_STATE = {
  state: {
    page: pageArray[0] as typeof pageArray[number],
    tabValue: {
      launchScreen: LaunchScreenTabMap.display,
      aboutUs: AboutUsTabMap.display,
    },
  },
  payload: {
    archive: false,
    name: '',
    launchScreen: INITIAL_LAUNCH_DISPLAY,
    aboutUs: INITIAL_ABOUT_US,
    products: [],
    externalIds: {
      agliveToken: ''
    },
    business: {
      name: '',
      tokenId: '',
      externalIds: {
        agliveToken: ''
      }
    }
  } as TokenService.BrandToken["details"],
  validation: {
    name: '',
  }
}
export const baseBrandReducer = <T>(
  state: T & typeof INITIAL_BRAND_STATE,
  action: baseBrandActionTypes) => {
    switch (action.type) {
      case 'navigate/switch_page':
        return produce(state, draft => {
          draft.state.page = action.payload;
        });
      case 'navigate/switch_tab':
        return produce(state, draft => {
          const { tab, page } = action.payload;
          draft.state.tabValue[page as string] = tab;
        });

      // metadata operations
      case 'input/name':
        return produce(state, draft => {
          draft.payload.name = action.payload;
        });  

      // launch screen operations
      case 'upload/launchScreen_mainLogo':
        return produce(state, draft => {
          draft.payload.launchScreen.display.mainLogo = action.payload;
        });
      case 'upload/launchScreen_secondaryLogo':
        return produce(state, draft => {
          draft.payload.launchScreen.display.secondaryLogo = action.payload;
        });
      case 'input/launchScreen_logoText':
        return produce(state, draft => {
          draft.payload.launchScreen.display.logoText = action.payload;
        });
      
      case 'pick/launchScreen_mainColor':
        return produce(state, draft => {
          draft.payload.launchScreen.theme.main = action.payload;
        });
      case 'pick/launchScreen_textColor':
        return produce(state, draft => {
          draft.payload.launchScreen.theme.text = action.payload;
        });

      // aboutUs page operations
      // display
      case 'select/aboutUS_displayItemType':
        return produce(state, draft => {
          draft.payload.aboutUs.display.item = action.payload;
        });
      case 'upload/aboutUS_displayImage':
        return produce(state, draft => {
          draft.payload.aboutUs.display.imageUrl = action.payload;
        });
      case 'input/aboutUs_displayVideo':
        return produce(state, draft => {
          draft.payload.aboutUs.display.videoUrl = action.payload;
        });

      // headline
      case 'input/aboutUs_headlineHeading':
        return produce(state, draft => {
          draft.payload.aboutUs.headLine.heading = action.payload;
        });
      case 'input/aboutUs_headlineAddress':
        return produce(state, draft => {
          draft.payload.aboutUs.headLine.address = action.payload;
        });
      case 'input/aboutUs_headlineWebsite':
        return produce(state, draft => {
          draft.payload.aboutUs.headLine.website = action.payload;
        });

      // content
      case 'input/aboutUs_contentDescription':
        return produce(state, draft => {
          draft.payload.aboutUs.content.description = action.payload;
        });

      // theme
      case 'pick/aboutUs_mainColor':
        return produce(state, draft => {
          draft.payload.aboutUs.theme.main = action.payload;
        });
      case 'pick/aboutUs_textColor':
        return produce(state, draft => {
          draft.payload.aboutUs.theme.heading = action.payload;
        });
      default: 
        return state;
    }
  };