import React, { useState } from 'react';
import {useAppSelector} from '../../../utils/hooks';
import { TokenService } from '@aglive/data-model';
import DeleteIcon from '@material-ui/icons/Delete';
import {Grid, Typography, TextField, Box, Button} from '@material-ui/core';

import TextFieldWithLimit from '../../../presentation/TextFieldWithLimit';
import MyButton from '../../../presentation/button';
import TabHeading from '../TabHeading';

import {fileUploader} from '../../../store/product/actions';

import useStyles from './styles';

import COLOR from '../../../styled/colors';

type attachmentType = {
  attachment: TokenService.ProductToken["details"]["pages"][number]["attachment"];
  onAdd: ()  => void;
  onChange: (keys: Array<number | string>, value: any) => void;
  onDelete: (attachmentIndex: number) => void;
};

const CreateProductTabAttachment: React.FC<attachmentType> = ({
  attachment,
  onAdd,
  onChange,
  onDelete, 
}) => {
  const userid = useAppSelector(state => state.auth.wallet);
  const classes = useStyles();
  const uploadFileHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const targetFile: File = e.target.files[0];
      if (targetFile.size > 10485760) {
        window.alert('Upload file size more than 20mb ！');
      } else {
        const response = await fileUploader(userid, targetFile);
        const url = response[0].data;

        onChange(['attachment', targetAttachmentIndex, 'url'], url);
      }
    }
  };
  const attachmentFileUpload = React.useRef<HTMLInputElement>(null);
  const handleClick = (targetIndex: number) => {
    setTargetAttachmentIndex(targetIndex);
    attachmentFileUpload.current?.click();
  };

  const [targetAttachmentIndex, setTargetAttachmentIndex] = useState(null);

  return (
    <Grid container direction="column">
      {attachment?.map(({title, url}, index) => (
        <CreateProductAttachmentCard
          heading={`Attachment ${index + 1}`}
          titleValue={title}
          url={url}
          onDelete={() => onDelete(index)}
          onTitleChange={(value: string) => onChange(['attachment', index, 'title'], value)}
          onUrlChange={() => handleClick(index)}
          key={index}
        />
      ))}
      <input
        type="file"
        onChange={uploadFileHandler}
        ref={attachmentFileUpload}
        style={{height: 0, width: 0}}
      />

      <Grid item style={{alignSelf: 'flex-end'}}>
        <MyButton
          onClick={onAdd}
          variant="outlined"
          text="Add"
          width={250}
          buttonClass={classes.contentButton}
        />
      </Grid>
    </Grid>
  );
};

type CreateProductAttachmentCardType = {
  heading: string;
  titleValue: string;
  url: string;
  onDelete: () => void;
  onTitleChange: (value: string) => void;
  onUrlChange: () => void;
}

const CreateProductAttachmentCard: React.FC<CreateProductAttachmentCardType> = ({
  heading,
  titleValue,
  url,
  onDelete,
  onTitleChange,
  onUrlChange
}) => {
  const classes = useStyles();

  return (
    <Grid item container direction="column" style={{marginBottom: 28}}>
      <Grid item container justify="space-between">
        <Grid item container xs={10}>
          <TabHeading
            heading={heading}
            subheading="Add a supporting file (pdf, png, doc, etc.)"
          />
        </Grid>
        <Grid>
          <DeleteIcon
            style={{ paddingRight: 24, marginTop:16 }}
            onClick={onDelete}
          />
        </Grid>
      </Grid>

      <Box p={3} style={{backgroundColor: COLOR.GRAY_SOLID}}>
        <Grid item container direction="column">
          <TextFieldWithLimit
            title="Attachment Title"
            content={titleValue}
            limit={30}
            onChange={onTitleChange}
            placeholder="Enter text"
            containerStyle={classes.textFieldContainer}
            style={classes.textField}
          />

          <Grid item container justifyContent="space-between">
            <Grid xs={9}>
              <TextField
                variant="outlined"
                value={decodeURIComponent(url?.split('/').reverse()[0]) || ''}
                placeholder="Image or document file"
                disabled
                style={{width: '98%'}}
              />
            </Grid>
            <Grid xs={3}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={onUrlChange}
                style={{
                  textTransform: 'none',
                  backgroundColor: 'white',
                  height: 58.3
                }}>
                <Typography variant="h4" style={{ color: COLOR.GREEN_BUTTON}} noWrap>Upload File</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default CreateProductTabAttachment;
