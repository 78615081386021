import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';

import {Grid, Typography} from '@material-ui/core';
import {TokenService} from '@aglive/data-model';

import withHeader from '../../../presentation/withHeader';
import {fetchBrands} from '../../../store/brand/actions';
import {getOnePromotion} from '../../../store/promotion/actions';
import {useAppDispatch, useAppSelector} from '../../../utils/hooks';
import {processBrandList} from '../CreateAssetPromotion/utils';
import {useStyles} from './styles';
import MyButton from '../../../presentation/button';
import ViewDisplayPoster from '../ViewDisplayPoster';
import {fetchProductProfileTokens} from '../ProductProfileLibrary/utils';

const EDIT_PRODUCT_PROMOTION_PATH = '/private/codes/promotionCodes/edit';

const ViewProductPromotion: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {id} = useParams<{id: string}>();

  const userId = useAppSelector((state) => state.auth.wallet);

  const [productPromotion, setProductPromotion] =
    useState<TokenService.PromotionToken['details']>();
  const [productProfile, setProductProfile] = useState<TokenService.ProductToken>();

  useEffect(() => {
    (async () => {
      const [brandTokens, promotion, productProfiles] = await Promise.all([
        fetchBrands(dispatch),
        dispatch(getOnePromotion(id, history, 'PRODUCTS')),
        fetchProductProfileTokens(),
      ]);
      // Overwrite logo if promotion's logo is selected as brand logo
      if (promotion && promotion.details.type === 'PRODUCTS') {
        const {product} = promotion.details;
        if (product.agliveToken) {
          const productProfile = productProfiles.find(
            (productProfile) =>
              productProfile.externalIds[0].agliveToken === product.agliveToken,
          );
          if (productProfile) {
            if (brandTokens) {
              const tempBrandList = processBrandList(brandTokens);
              if (
                tempBrandList?.length > 0 &&
                productProfile.details.story.logoType === 'brand'
              ) {
                const brandIndex = tempBrandList.findIndex(
                  (brand) =>
                    brand.agliveToken === productProfile.details.brand.agliveToken,
                );
                if (brandIndex !== -1) {
                  productProfile.details.story.logo = tempBrandList[brandIndex]?.logo;
                }
              }
            }
            setProductProfile(productProfile);
          }
        }
        setProductPromotion(promotion.details)
      }
    })();
  }, [dispatch, userId, id]);

  return (
    <>
      <Grid container spacing={8} justifyContent="center">
        <Grid item xs={6}>
          <Typography variant="h6" role="label" style={{fontWeight: 700}}>
            Promotion Name
          </Typography>
          <Typography variant="h6" role="label">
            {productPromotion?.name || '-'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" role="label" style={{fontWeight: 700}}>
            Product Name
          </Typography>
          <Typography variant="h6" role="label">
            {productProfile?.details.name || '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.stepContainer}>
        <ViewDisplayPoster
          display={productPromotion?.display}
          story={productProfile?.details.story}
          footerImageOption={
            productPromotion?.display.footerImage ? 'image' : ''
          }
          agliveToken={id}
          brandLogo={productProfile?.details.story.logo || null}
        />
      </Grid>
      {!productPromotion?.archive && (
        <Grid item container justifyContent="flex-end" spacing={3}>
          <Grid item>
            <MyButton
              text="Edit"
              variant="contained"
              onClick={() => {
                history.push(`${EDIT_PRODUCT_PROMOTION_PATH}/${id}`);
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withHeader(
  {
    title: 'View Promotion Code',
    margin: 60,
    back: true,
  },
  ViewProductPromotion,
);
