import React, { useState } from 'react';
import { PageHeader } from '../../presentation/withHeader';
import {
  Grid,
  Typography,
  makeStyles,
  Link
} from '@material-ui/core';
import { assetStyles } from '../asset/Components';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { toggleModal, toggleModalOff } from '../../store/modal/actions';
import COLOR from '../../styled/colors';
import { Line } from '../../presentation/Line';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from "@material-ui/core/TableCell";
import DeleteIcon from '@material-ui/icons/Delete';
import { Buttons } from '../../presentation/ButtonsGroup';
import { updateSireGroup } from '../../store/angus/actions';
import MyButton from '../../presentation/button';
import { useLocation, useHistory } from 'react-router-dom';
import TextFieldwithValidation from '../../presentation/TextFieldwithValidation';

export const useStyles = makeStyles((theme) => ({
  subTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${COLOR.GRAY_BORDER}`,
    paddingBottom: "10px",
  },
  marginTop: {
    marginTop: 60,
  },
  greyBox: {
    backgroundColor: COLOR.GRAY_SOLID,
    marginTop: 20,
    marginBottom: 80,
    padding: 15,
  },
  halfWidth: {
    width: window.innerWidth / 2,
  },
  tablePad: {paddingBottom: 16, paddingTop: 16},
  tableSpace: {padding:16, paddingLeft: 8, paddingRight: 8, borderBottom: 'none'},
  cursor: {
    cursor: 'pointer'
  },
  link: {
    color: COLOR.GREEN_BUTTON,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
}));

const SireGroupDetails: React.FC<{}> = (props) => {
  const history = useHistory();
  const classes = { ...assetStyles(), ...useStyles() };
  const dispatch = useAppDispatch();
  const state: any = useLocation().state;
  const [details, setDetails] = useState(state.details.details);
  const userId = useAppSelector(state => state.auth.wallet);
  const businessProfile = useAppSelector(
    state => state.user.businessProfileData,
  );
  const getJoiningMethod = () => {
    const joiningMethods = details?.group_sires?.map(group => group.is_ai);
    if (joiningMethods?.every(e => e === true)) return 'AI';
    if (joiningMethods?.every(e => e === false)) return 'Natural';
    if (joiningMethods?.length > 0) return 'Natural and AI';
    return '';
  }

  const groupDetails = [
    { name: 'Calving Year', value: 'Group Name', bold: true },
    { name: details.calving_year, value: details.group_name},
    { name: '',value: details.sire_group_id, underline: true },
    { name: 'Joining Method', value: 'Created On', bold: true },
    { name: getJoiningMethod(), value: details.created_on, underline: true },
  ];

  const siresHeader = [
    { name: 'Angus Sire ID', value: '' },
  ];
  const updateGroup = (sire: any) => {
    dispatch(toggleModalOff());
    const agliveToken = details.externalIds.filter(id => id.agliveToken)[0].agliveToken;
    const isAnimalBusiness = businessProfile.industryType == "ANIMALS"
    const trimmedGroupSires = details.group_sires.map((s: any) => {
      return {sire_id: s.sire_id, is_ai: s.is_ai};
    });
    const group_sires_exist = trimmedGroupSires.filter((s: any) => s.sire_id !== sire.sire_id);
    const updateDetails = {
      isAngusSireGroup: true,
      angusAuthToken: isAnimalBusiness? businessProfile.angusProfile.angusAuthToken: undefined,
      acct_id: isAnimalBusiness? businessProfile.angusProfile.acctID: undefined,
      group_id: details.sire_group_id,
      calving_year: details.calving_year,
      group_sires: group_sires_exist
    };
    dispatch(updateSireGroup(agliveToken, updateDetails));
    let newDetails = details;
    newDetails.group_sires = group_sires_exist;
    setDetails(newDetails);

    history.replace({
      pathname: `/private/verified/sireGroups/details`, 
      state: Object.assign(state, {details: Object.assign(state.details, {details: newDetails})})
    });
  }

  const createSiresTable = () => {
    let siresList = [];
    details?.group_sires?.forEach(sire => {
      siresList.push({
        id: sire.sire_id,
        delete:
          <DeleteIcon
            className={classes.cursor}
            onClick={() => {
              dispatch(
                toggleModal({
                  status: 'warning',
                  title: 'Remove Sire?',
                  subtitle: 'This sire will be removed from this Sire Group',
                  renderButton: (
                    <Buttons
                      leftButtonTitle="No"
                      rightButtonTitle="Yes"
                      leftButtonOnClick={() => {
                        dispatch(toggleModalOff());
                      }}
                      rightButtonOnClick={() => {
                        updateGroup(sire);
                      }}
                    />
                  ),
                }),
              );
            }}
          />
      });
    });
    return siresList;
  };
  const animalTagsBody = createSiresTable();

  const getRowStyle = (underline, bold) => {
    if (!underline) {
      return { fontWeight: bold ? 700 : 400, borderBottom: "none" };
    }
    return { fontWeight: bold ? 700 : 400, borderBottomColor: COLOR.GRAY_SOLID };
  }

  return (
    <PageHeader config={{ title: 'Sire Group Details', margin: 60, back: true }}>
      <Grid container xs={12} lg={6} md={8} sm={12}>
        <TableContainer>
          <Table padding='none'>
            <TableBody>
              {groupDetails.map((row, index) => (
                <TableRow key={row.name}>
                  <TableCell align="left" classes={{root: classes.tablePad}} style={getRowStyle(row.underline, row.bold)}>{row.name}</TableCell>
                  <TableCell classes={{root: classes.tableSpace}}>&nbsp;</TableCell>
                  <TableCell align="left" classes={{root: classes.tablePad}} style={getRowStyle(row.underline, row.bold)}>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container justify="flex-end">
        <MyButton
          buttonClass={classes.saveButton}
          text={'Edit Group Name'}
          variant="contained"
          onClick={() => {
            history.replace(
              `/private/verified/sireGroups/details/editGroupName`,
              {details},
            );
          }}
        />
      </Grid>

      <Typography variant='h3' className={classes.marginTop}>Sires</Typography>
      <Line />
      <Grid container xs={12} lg={4} md={6} sm={12}>
        <TableContainer>
          <Table>
            <TableBody>
              {siresHeader.map((cell) => (
                <TableRow key={cell.name} className={classes.greyBox}>
                  <TableCell align="left" style={{ fontWeight: 700 }}>{cell.name}</TableCell>
                  <TableCell align="left">{cell.value}</TableCell>
                </TableRow>
              ))}
              {animalTagsBody.map((row, index) => (
                <TableRow key={row.name} >
                  <TableCell align="left">
                    <Link className={classes.link} onClick={() => {
                      history.push(`/private/verified/animalProfile/view-sire`, { sireID: row.id });
                    }}>{row.id}</Link>
                  </TableCell>
                  <TableCell align="left">{row.delete}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container justify="flex-end">
        <MyButton
          buttonClass={classes.saveButton}
          text={'Add Sire'}
          variant="contained"
          onClick={() => {
            history.push(`/private/verified/sireGroups/details/add-sire`, { details: details });
          }}
        />
      </Grid>
    </PageHeader >
  );
};

export const EditGroupName = () => {
  const history = useHistory();
  const state: any = useLocation().state;
  const classes = {...assetStyles(), ...useStyles()};
  const dispatch = useAppDispatch();
  const [result, setResult] = useState({error: '', value: ''} as {
    error: string;
    value: string;
  });
  const [touched, setTouched] = useState(false);

  const validate = (value: string) => {
    if (value.length === 0) return {isValid: false, error: 'Required!'};
    else return {isValid: true, error: ''};
  };

  const closeModal = () => {
    const updatedGroup = Object.assign({}, state.details);
    updatedGroup.group_name = result.value;
    history.replace(`/private/verified/sireGroups/details`, {
      details: {details: updatedGroup},
    });
  };

  return (
    <PageHeader
      config={{title: 'Edit Sire Group Name', margin: 60, back: true}}>
      <Grid container direction="column">
        <TextFieldwithValidation
          // testID={'sireGroupName'}
          title={'Sire Group Name'}
          name={'sireGroupName'}
          initialValue={state.details.group_name}
          touched={touched}
          setResult={(error, value) => {
            setResult({error, value});
          }}
          validate={validate}
        />
        <Grid item container justify="flex-end">
          <MyButton
            buttonClass={classes.saveButton}
            text={'Save'}
            variant="contained"
            onClick={() => {
              setTouched(true);
              if (!result.error) {
                const agliveToken = state.details.externalIds.filter(
                  (id) => id.agliveToken,
                )[0].agliveToken;

                const updateDetails = {group_name: result.value};
                dispatch(
                  updateSireGroup(
                    agliveToken,
                    updateDetails,
                    false,
                    closeModal,
                  ),
                );
              }
            }}
          />
        </Grid>
      </Grid>
    </PageHeader>
  );
};

export default SireGroupDetails;