import moment from 'moment';
import {fetchReports} from '../../../store/asset/actions';
import API from '../../../config/api';

export const fetchActivitiesReport = async () => {
  const response = await fetchReports({
    url: API.POST.getActivitiesReport,
    method: 'POST',
  });

  return response;
};

export const COLUMNS = [
  {
    name: 'agliveToken',
    title: 'Aglive Token',
  },
  {
    name: 'type',
    title: 'Type',
  },
  {
    name: 'updatedAt',
    title: 'Updated At',
  },
  {
    name: 'activityGeolocation',
    title: 'Geolocation (Last Activity)',
  },
  {
    name: 'creationGeolocation',
    title: 'Geolocation (Creation)',
  },
];

export const formatRow = (reportData) =>
  reportData.map((data) => {
    const activityGeolocation =
      data.activities?.latestActivity.metadata?.location?.coordinates;
    const creationGeolocation = data.metadata?.location?.coordinates;
    return {
      agliveToken: data.externalIds.find(
        (externalId) => 'agliveToken' in externalId,
      ).agliveToken,
      type: data.type,
      updatedAt: moment(data.updatedAt).format('DD-MM-YYYY hh:mm:ss A'),
      activityGeolocation: `${
        activityGeolocation
          ? `${activityGeolocation[0]}, ${activityGeolocation[1]}`
          : '-'
      }`,
      creationGeolocation: `${
        creationGeolocation
          ? `${creationGeolocation[0]}, ${creationGeolocation[1]}`
          : '-'
      }`,
      latestActivity: data.activities?.latestActivity,
      details: data.details,
    };
  });
