import React, { useEffect, useRef } from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {TokenService} from '@aglive/data-model';

import {useStyles} from './styles';

export enum InfoMap {
  LT = 'LT',
  EU = 'EU',
  BRD = 'BRD Vax',
  WHP = 'WHP',
  WHP_ESI = 'ESI',
  fiveInOne = '5-in-1',
  ERP = 'ERP Status',
  angusVerified = 'Angus Verified',
}

interface MobTableProps {
  mobInfo: TokenService.AnimalPromotion['details']['mob']['linkMob']['info'];
  showAllInfo: boolean;
  setMobTableHeight?: React.Dispatch<React.SetStateAction<number>>
}

const MobTable: React.FC<MobTableProps> = ({mobInfo, showAllInfo, setMobTableHeight}) => {
  const classes = useStyles();

  const tableRef = useRef<HTMLTableElement>(null);

  const convertedRows = [];
  const negativeInfo = [];
  const checkIcon = (
    <CheckCircleIcon fontSize="small" className={classes.checkIcon} />
  );
  let infoCount = 0;

  useEffect(() => {
    if (setMobTableHeight) {
      setMobTableHeight(tableRef?.current?.clientHeight);
    }
  }, [tableRef?.current?.clientHeight, setMobTableHeight, showAllInfo, mobInfo]);

  // Create table row data
  Object.entries(InfoMap).forEach(([key, value]) => {
    if ((mobInfo[key] || (key === InfoMap.WHP && mobInfo.WHP_ESI)) && infoCount % 2 === 0) {
      convertedRows.push([value, checkIcon, '', '']);
      infoCount++;
    } else if (mobInfo[key] || (key === InfoMap.WHP && mobInfo.WHP_ESI)) {
      convertedRows[convertedRows.length - 1][2] = value;
      convertedRows[convertedRows.length - 1][3] = checkIcon;
      infoCount++;
    } else {
      negativeInfo.push(value);
    }
  });

  // Push all negative info at the end of array if they are required to be shown
  if (showAllInfo) {
    negativeInfo.forEach((info) => {
      if (infoCount % 2 === 0) {
        convertedRows.push([info, '', '', '']);
        infoCount++;
      } else {
        convertedRows[convertedRows.length - 1][2] = info;
        convertedRows[convertedRows.length - 1][3] = '';
        infoCount++;
      }
    });
  }

  return (
    <table className={`${classes.mobTable} ${classes.previewHeader}`} ref={tableRef}>
      <tbody>
        {convertedRows.map((row, index) => (
          <tr key={index}>
            {row.map((info, index) => (
              <td key={index}>{info}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MobTable;
