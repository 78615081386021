import {withStyles} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import COLOR from '../../styled/colors';

import {JSONSchemaValidationError} from '../../utils/csvReader';

const StyledTableCell = withStyles((_) => ({
  head: {
    backgroundColor: COLOR.GRAY_SOLID,
    color: 'black',
    width: 972,
    height: 46,
  },
  body: {
    fontSize: 14,
    width: 972,
    height: 46,
  },
}))(TableCell);
const simpleValidationTable = (errorSet: Array<JSONSchemaValidationError>) => {
  return errorSet.map((error) => {
    const propertyStr = error.property;
    const propertyStack = error.stack;
    const match = propertyStr.match(/\d+/);
    const getRow = match ? parseInt(match[0], 10) + 2 : null;
    let getId =
      typeof error.instance == 'string' || typeof error.instance == 'number'
        ? propertyStack.substr(
            propertyStack.indexOf('.') + 1,
            propertyStack.indexOf(' ') - propertyStack.indexOf('.') - 1,
          )
        : Array.isArray(error.argument)
        ? error.argument.join(', ')
        : typeof error.argument === 'object'
        ? error.argument.id
        : error.argument;
    // let getId = error.schema.label;

    let getErrorMessage = error.schema['customError'] ?? error.message;
    if (['anyOf', 'oneOf'].includes(error.name) && error.schema[error.name]) {
      const reqFields = [];
      error.schema[error.name].forEach((err) => {
        err.required?.forEach((req) => {
          reqFields.push(req);
        });
      });
      if (reqFields.length) {
        getErrorMessage = `Requires ${
          error.name === 'anyOf' ? 'at least one of' : 'only one of either of'
        } the following: ${reqFields.join(', ')}`;
        getId = reqFields.join(', ');
      }
    }
    const getValue =
      typeof error.instance == 'string'
        ? error.instance
        : typeof error.instance == 'number'
        ? error.instance
        : 'no value';

    return (
      <TableRow key={getId + 'row'}>
        <StyledTableCell key={getId}>{getId}</StyledTableCell>
        <StyledTableCell key={getRow}>{getRow}</StyledTableCell>
        <StyledTableCell key={getValue}>{getValue}</StyledTableCell>
        <StyledTableCell style={{color: 'red'}} key={getErrorMessage}>
          {getErrorMessage}
        </StyledTableCell>
      </TableRow>
    );
  });
};

const uniqueValidarionTable = (error: validationResultT, CSVData) => {
  return error.validationError.map((validationError) => {
    const propertyStr = validationError.property;
    const getId = propertyStr.substr(propertyStr.indexOf('.') + 1);
    const getErrorMessage = validationError.message;
    const instance = validationError.instance.sort();
    const multipleIdSet = [];
    let n = 0;
    while (n < instance.length - 1) {
      if (instance[n] === instance[n + 1]) {
        const temp = instance[n];
        multipleIdSet.push([temp, '']);
        n++;
        while (n < instance.length - 1 && instance[n] === instance[n + 1]) n++;
      } else n++;
    }
    const rowIdMap = new Map(multipleIdSet);

    for (let i = 0; i < CSVData.length; i++) {
      if (rowIdMap.has(CSVData[i][getId])) {
        let rfidTemp = CSVData[i][getId];
        rowIdMap.set(rfidTemp, rowIdMap.get(rfidTemp) + ' ' + (i + 2));
      }
    }
    const reslutArray = Array.from(rowIdMap);

    return reslutArray.map((result) => {
      return (
        <TableRow key={getId + 'row'}>
          <StyledTableCell key={getId}>{getId}</StyledTableCell>
          <StyledTableCell key={'result1'}>{result[1]}</StyledTableCell>
          <StyledTableCell key={'result0'}>{result[0]}</StyledTableCell>
          <StyledTableCell style={{color: 'red'}} key={getErrorMessage}>
            {getErrorMessage}
          </StyledTableCell>
        </TableRow>
      );
    });
  });
};

const atLeastValidationTable = (errorSet: validationResultT) => {
  return errorSet.validationError.map((error) => {
    const propertyStr = error.property;
    const match = propertyStr.match(/\d+/);
    const getRow = parseInt(match[0], 10) + 2;
    const getId = propertyStr.substr(propertyStr.indexOf('.') + 1);
    const getErrorMessage = error.message;
    return (
      <TableRow key={getId + 'row'}>
        <StyledTableCell key={getId}>{getId}</StyledTableCell>
        <StyledTableCell key={getRow}>{getRow}</StyledTableCell>
        <StyledTableCell key={'NoValue3'}>No Value</StyledTableCell>
        <StyledTableCell style={{color: 'red'}} key={getErrorMessage}>
          {getErrorMessage}
        </StyledTableCell>
      </TableRow>
    );
  });
};
const apiErrorTable = (errorSet) => {
  return errorSet.map((error) => {
    return (
      <TableRow key={error + 'row'}>
        <StyledTableCell key={error.row}>{error.row}</StyledTableCell>
        <StyledTableCell
          style={{color: 'red'}}
          //key={`${error.row}${error.message}`}>
          key={'ErrorMessage'}>
          {error.message}
        </StyledTableCell>
      </TableRow>
    );
  });
};

type validationResultT = {
  type: string;
  validationError: Array<JSONSchemaValidationError>;
};

const ResultTable = (
  validationResult: any, //validationResultT|"API_ERROR",
  csvData,
) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow key={'tableRow1'}>
            {validationResult !== 'API_ERROR' && (
              <StyledTableCell key={'Column(IDs)'}>Column(IDs)</StyledTableCell>
            )}
            <StyledTableCell key={'Row'}>Row</StyledTableCell>
            <StyledTableCell key={'Value'}>Value</StyledTableCell>
            {validationResult !== 'API_ERROR' && (
              <StyledTableCell key={'ErrorMessage'}>
                Error Message
              </StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {validationResult.type === 'SIMPLE_VALIDATION' &&
            simpleValidationTable(validationResult.validationError)}
          {validationResult.type === 'UNIQUE_VALIDATION' &&
            uniqueValidarionTable(validationResult, csvData)}
          {validationResult.type === 'AT_LEAST_VALIDATION' &&
            atLeastValidationTable(validationResult)}
          {validationResult === 'API_ERROR' && apiErrorTable(csvData)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ResultTable;
