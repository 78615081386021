import React, {useRef, useState, useEffect} from 'react';
import produce from 'immer';
import { useReactToPrint } from 'react-to-print';
import {useHistory, useRouteMatch, Link} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';

import {Grid} from '@material-ui/core';

import SearchBar from '../../presentation/SearchBar';
import MyButton from '../../presentation/button';
import withHeader from '../../presentation/withHeader';
import CodeLibraryTable from './CodeLibraryTable';
import { ComponentToPrint } from '../site/ViewSite';

import {fetchCodeEntries, updateCodeEntry} from '../../store/code/actions';
import { iCodeEntryDocumentResponse } from '../../store/code/types';
import { PlantCodeDocumentDetails } from '../../store/plant/types';
import CustomPrintModal, { INITIAL_PRINT_STATE } from './CustomPrintModal';

import useStyles from './SecurityCode/SecurityCodeLibrary/styles';

const tableHeader = ['Asset Profile', 'Available Codes', 'Date Created', 'Action'];

const CodeLibrary: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {path} = useRouteMatch();
  const userid = useAppSelector((state) => state.auth.wallet);
  const [query, setQuery] = useState('');

  useEffect(() => {
    dispatch(fetchCodeEntries(userid));
  }, []);

  const unprocessedCodeList = useAppSelector((state) => state.code) as Array<iCodeEntryDocumentResponse<PlantCodeDocumentDetails>>;
  const codeList = unprocessedCodeList.filter((entry) => !entry.details.used);

  // printing content
  // printing logic - print state holds the information necessary to build modal (assetProfileName, permit, qrCode.length)
  // pressing on 'print' icon will trigger the modal,
  // pressing 'cancel' reset the printState, pressing 'print' will set the content (printContent) on the print prompt (hidden at this stage) (ComponentToPrint)
  // the useEffect hook is triggered on printContent changes and will invoke handlePrint and show the print prompt
  const componentRef = useRef();
  const [printState, setPrintState] = useState(INITIAL_PRINT_STATE);
  const [printContent, setPrintContent] = useState([] as typeof INITIAL_PRINT_STATE.qrcode); // this is used to invoke useEffect hook
  const [printCodeEntry, setPrintCodeEntry] = useState<iCodeEntryDocumentResponse<PlantCodeDocumentDetails> | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (printContent.length) {
      handlePrint();
      dispatch(fetchCodeEntries(userid));
    }
  }, [printContent]);


  return (
    <>
      <Grid alignItems="center" container className={classes.bodyContainer}>
        <Grid item className={classes.searchBarContainer}>
          <SearchBar query={query} setQuery={setQuery} label={'Search'} />
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <MyButton
            text={'Generate Codes'}
            variant="contained"
            width={160}
            fontSize={18}
            onClick={() => history.push(`${path}/new`)}
          />
        </Grid>
      </Grid>

      <CodeLibraryTable
        tableHeader={tableHeader}
        codeList={codeList}
        query={query}
        onPrint={(entry: iCodeEntryDocumentResponse<PlantCodeDocumentDetails>) => {
          setPrintState({
            show: true,
            tokenSize: 1,
            modalInfo: [{
              title: 'Asset Profile',
              value: entry.details.assetProfile.assetName,
            }, {
              title: 'Permit',
              value: entry.details.assetProfile.mainDisplay.permitNumber,
            }, {
              title: 'No. of Codes to Print',
              value: String(entry.details.codes.length),
            }],

            qrcode: entry.details.codes,
            description: [
              {
                label: 'Species',
                value: entry.details.assetProfile.mainDisplay.species,
              },
              {
                label: 'Strain',
                value: `${entry.details.assetProfile.mainDisplay.strain} - ${entry.details.assetProfile.mainDisplay.subStrain}`,
              }
            ],
          });
          setPrintCodeEntry(entry);
        }}
      />

      <Grid item style={{paddingTop: 20}}>
        <Link to={`${path}/used`} className={classes.hyperlink}>Used Codes</Link>
      </Grid>

      <div style={{ display: 'none' }}>
        <ComponentToPrint 
          tokenSize={printState.tokenSize}
          qrcode={printContent}
          description={printContent.map(() => printState.description)}
          ref={componentRef}
        />
      </div>

      {printState.show && (
        <CustomPrintModal
          show={printState.show}
          content={printState.qrcode}
          tokenSize={printState.tokenSize}
          modalInfo={printState.modalInfo}
          setPrintContent={setPrintContent}
          onPrint={() => updateCodeEntry(printCodeEntry)}
          onSizeChange={e => setPrintState(prevState => 
            produce(prevState, draft => {
              draft.tokenSize = Number(e.target.value);
            })
          )}
          onCancel={() => setPrintState(INITIAL_PRINT_STATE)}
        />
      )}
    </>
  );
};

export default withHeader(
  {
    title: 'Codes',
  },
  CodeLibrary,
);
