import {TokenService} from '@aglive/data-model';
import {
  PromotionNameAction,
  PromotionRetrieveAction,
  SelectProductAction,
  FooterImageOptionAction,
  SubmitAction,
  OriginalEndDateAction,
  ProductProfileAction,
} from './types';
import DisplayActions from '../../DisplayPoster/displayPosterState/actions';

const editName = (value: string): PromotionNameAction => ({
  type: 'promotion/name',
  payload: value,
});

const retrieveProductPromotion = (
  productPromotion: TokenService.ProductPromotion['details'],
): PromotionRetrieveAction => ({
  type: 'promotion/retrieve_token',
  payload: productPromotion,
});

const selectProduct = (value: string): SelectProductAction => ({
  type: 'promotion/select_product',
  payload: value,
});

const editFooterImageOption = (
  value: 'image' | '',
): FooterImageOptionAction => ({
  type: 'promotion/footer_image_option',
  payload: value,
});

const editOriginalEndDate = (value: string): OriginalEndDateAction => ({
  type: 'promotion/original_end_date',
  payload: value,
});

const setSubmitFlag = (value: '' | 'draft' | 'publish'): SubmitAction => ({
  type: 'submit',
  payload: value,
});

const populateProductProfiles = (
  productProfiles: Array<TokenService.ProductToken>,
): ProductProfileAction => ({
  type: 'promotion/product_profile',
  payload: productProfiles,
});

export default {
  editName,
  retrieveProductPromotion,
  selectProduct,
  editFooterImageOption,
  editOriginalEndDate,
  setSubmitFlag,
  populateProductProfiles,
  ...DisplayActions,
};
