import React from 'react';
import MockupPhone from "./MockupPhone";
import { makeStyles, Typography } from "@material-ui/core";
import COLOR from '../styled/colors'
import defaultImage from "../img/defaultImage.png";
import MyButton from './button';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import WaterMarks from "../img/Watermarks.png";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Divider from '@material-ui/core/Divider';
import { TokenService } from '@aglive/data-model';


const useStyle = makeStyles((_) => ({
  imagePreviewContainer: {
    width: 237,
    borderRadius: "40px",
    height: 119,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imgMain: {
    // borderRadius: '50%', 
    width: '164px',
    height:'88px',
    marginTop:40,
  },
  imageView:{
    display: "flex",
    justifyContent: "center",
  },
  container:{
    position: 'relative',
  },
  secondaryText:{
    marginTop:16,
    display: "flex",
    justifyContent: "center",
  },
  imgsecondary: {
    // borderRadius: '50%', 
    width: '104px',
    height:'56px',
    marginTop:9,
  },

}))

interface iProductMockupPhoneLaunch {
  page: TokenService.BrandToken["details"]["launchScreen"]; 
};

export const MockUpPhoneBrandLaunchScreen: React.FC<iProductMockupPhoneLaunch> = ({page}) => {
  const useStyle2 = makeStyles((theme) => ({
    button:{
      marginTop:46,
      height:43,
    },
    buttonView:{
      display: "flex",
      justifyContent: "center",
    },
    button2:{
      height:43,
      marginTop:12,
      color:page.theme.main,
    }
  }));

  const classes = useStyle();
  const classes2 = useStyle2();

  return (
    <MockupPhone backgroundColor={page.theme.main}>
      <div
        style={{
          backgroundColor: "white",
          width: 237,
          height: 424,
          marginTop: 130,
          borderRadius: "15px 15px 0 0",
          marginLeft: 13,
          display: "flex",
          overflow: "hidden",
          overflowY: "scroll",
          flexDirection: 'column'
        }}>
        <div className={classes.imageView}>
          {page.display.mainLogo ? (
            <img src={page.display.mainLogo} alt="Display preview" className={classes.imgMain} />
          ) : (
            <div className={classes.container}>
              <img src={defaultImage} alt="Display preview" className={classes.imgMain}/>
              <Typography
                role="label"
                style={{     
                  fontSize:12,                       
                  color: COLOR.BLACK,
                  position: 'absolute',
                  bottom: '40px',
                  right:'47px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  fontWeight: 600,
                }}>
                Main
              </Typography>
            </div>
          )}
        </div>
        <div className={classes.secondaryText}>
          <Typography
            role="label"
            style={{                           
              color: page.theme.text,
              fontSize:9, 
            }}>
            {page.display.logoText}
          </Typography>
        </div>
        <div className={classes.imageView}>
          {page.display.secondaryLogo ? (
            <img src={page.display.secondaryLogo} alt="Display preview" className={classes.imgsecondary} />
          ) : (
            <div className={classes.container}>
              <img src={defaultImage} alt="Display preview" className={classes.imgsecondary}/>
              <Typography
                role="label"
                style={{        
                  fontSize:8,                   
                  color: COLOR.BLACK,
                  position: 'absolute',
                  bottom: '25px',
                  right:'23px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}>
                Secondary
              </Typography>
            </div>
          )}
        </div>
        <div className={classes2.buttonView}>
          <MyButton
            text={'Create Account'}
            variant="contained"
            width={193}
            fontSize={14}
            buttonClass={classes2.button}
            style={{
              backgroundColor: page.theme.main,
              borderColor: page.theme.main,
              '&:hover': {
                backgroundColor: page.theme.main,
                borderColor: page.theme.main,
              },
            }}
            onClick={()=>{}}
          /> 
        </div>
        <div className={classes2.buttonView}>
          <MyButton
            text={'Recover Account'}
            variant="outlined"
            width={193}
            fontSize={14}
            buttonClass={classes2.button2}
            onClick={()=>{}}
            style={{
              borderColor: page.theme.main,
              '&:hover': {
                borderColor: page.theme.main,
              },
            }}
          /> 
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
          }}>
          <img src={WaterMarks} style={{ width: 80, height: 20 }} alt="" />
        </div>
      </div>
    </MockupPhone>
  );
}

export const MockUpPhoneBrandSplashScreen: React.FC<iProductMockupPhoneLaunch> = ({page}) => {
  const useStyle2 = makeStyles((theme) => ({
    imgMain: {
      // borderRadius: '50%', 
      width: '164px',
      height:' 88px',
      marginTop:80,
    },
    imgsecondary: {
      // borderRadius: '50%', 
      width: '104px',
      height:' 56px',
      marginTop:19,
    },
  }));

  const classes = useStyle();
  const classes2 = useStyle2();

  return (
    <MockupPhone backgroundColor={page.theme.main}>
      <div
        style={{
          backgroundColor: "white",
          width: 237,
          height: 424,
          marginTop: 130,
          borderRadius: "15px 15px 0 0",
          marginLeft: 13,
          display: "flex",
          overflow: "hidden",
          overflowY: "scroll",
          flexDirection: 'column'
        }}>
        <div className={classes.imageView}>
          {page.display.mainLogo ? (
            <img src={page.display.mainLogo} alt="Display preview" className={classes2.imgMain} />
          ) : (
            <div className={classes.container}>
              <img src={defaultImage} alt="Display preview" className={classes2.imgMain}/>
              <Typography                        
                role="label"
                style={{     
                  fontSize:12,                       
                  color: COLOR.BLACK,
                  position: 'absolute',
                  bottom: '40px',
                  right:'50px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  fontWeight: 600,
                }}>
                Main
              </Typography>
            </div>
          )}
        </div>
        <div className={classes.secondaryText}>
          <Typography           
            role="label"
            style={{                           
              color: page.theme.text,
              fontSize:11, 
              fontWeight: 600,
            }}>
            {page.display.logoText}
          </Typography>
        </div>
        <div className={classes.imageView}>
          {page.display.secondaryLogo ? (
            <img src={page.display.secondaryLogo} alt="Display preview" className={classes2.imgsecondary} />
          ) : (
            <div className={classes.container}>
              <img src={defaultImage} alt="Display preview" className={classes2.imgsecondary}/>
              <Typography
                role="label"
                style={{        
                  fontSize:8,
                  color: COLOR.BLACK,
                  position: 'absolute',
                  bottom: '28px',
                  right:'25px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                }}>
                Secondary
              </Typography>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 80,
          }}>
          <img src={WaterMarks} style={{ width: 80, height: 20 }} alt="" />
        </div>
      </div>  
    </MockupPhone>
  );
};

interface iProductMockupPhoneAboutUs {
  page: TokenService.BrandToken["details"]["aboutUs"]; 
};

export const MockUpPhoneBrandAboutUs: React.FC<iProductMockupPhoneAboutUs> = ({page}) => {
  const classes = useStyle();

  return (
    <MockupPhone backgroundColor={page.theme.main}>
      <>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <ArrowBackIosIcon
            color="secondary"
            style={{
              marginTop: 95,
              marginLeft: 13,
              marginRight: 9,
              color:page.theme.heading,
              width: 18,
              height: 15,
            }}
          />
          <Typography
            variant="h5"
            role="label"
            style={{
              marginTop: 90,
              color: page.theme.heading,
              fontWeight: 400,
            }}>
            About Us
          </Typography>
        </div>

        <div
          style={{
            backgroundColor: "white",
            width: 237,
            height: 364,
            marginTop: 5,
            borderRadius: "15px 15px 0 0",
            marginLeft: 13,
            display: "flex",
            overflow: "hidden",
            overflowY: "scroll",
          }}>
          <div>
            <div className={classes.imagePreviewContainer}>
              {page.display.item==='video'
                ? <img src={defaultImage} alt="Display preview" width= "100%" height="119" />
                : page.display.imageUrl
                  ? <img src={page.display.imageUrl} alt="Display preview" width= "100%" height="119" />
                  : <img src={defaultImage} alt="Display preview"  width= "100%" height="119" />
              }
              {/* {page.display.imageUrl ? (
                  <img src={page.display.imageUrl} alt="Display preview"  width= "100%"
                  height="119" />
              ) : (
                  <img src={defaultImage} alt="Display preview"  width= "100%"
                  height="119" />
              )} */}

              {/* Image or Video here */}
            </div>
            <Typography
              variant="body1"
              style={{
                fontWeight: 600,
                fontSize: 12,
                marginTop: 20,
                marginLeft: 9,
              }}>
              {page.headLine.heading}
            </Typography>
            <div style={{display: "flex",justifyContent:"row"}}>
              <LocationOnIcon style={{ fontSize: 12 ,marginLeft: 9}} />
              <Typography
                variant="h6"
                style={{
                  fontSize: 9,
                  marginTop: 2,
                  marginLeft: 9,
                }}>
                {page.headLine.address}
              </Typography>
            </div>

            <Typography
              variant="body2"
              style={{               
                fontSize: 9,
                marginTop: 2,
                marginLeft: 9,
              }}>
              <u>{page.headLine.website}</u>
            </Typography>
            <div style={{marginTop:8, marginBottom:8}}>
              <Divider light variant="middle"/>
            </div>
                    
            <Typography
              variant="body2"
              style={{
                fontSize: 9,
                marginTop: 2,
                marginLeft: 9,
              }}>
              {page.content.description}
            </Typography>

          </div>
        </div>
        <div
          style={{
            backgroundColor: "white",
            width: 237,
            height: 70,
            marginLeft: 13,
            display: "flex",
            overflow: "hidden",
            overflowY: "scroll",
          }}>
          <div style={{width:'100%'}}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
              }}>
              <img src={WaterMarks} style={{ width: 80, height: 20 }} alt="" />
            </div>
          </div> 
        </div>
      </>
    </MockupPhone>
  );
};
