import React, {useEffect, useReducer} from 'react';
import produce from 'immer';
import {useAppDispatch} from '../../../utils/hooks';
import {useHistory, useParams} from 'react-router-dom';

import {Grid} from '@material-ui/core';

import BaseBrand from './BaseBrand';
import MyButton from '../../../presentation/button';
import withHeader from '../../../presentation/withHeader';

import { baseBrandReducer, INITIAL_BRAND_STATE } from './brandProfileState/reducer';
import { baseBrandActionTypes, loadBrandActionTypes, loadBrandToken } from './brandProfileState/actions';

import API from '../../../config/api';
import CONSTANT from '../../../config/constant';
import { BRAND_LIBRARY_PATH, fetchBrandToken } from '../utils';
import { WebErrorType } from '../../../utils/error';
import { toggleModal } from '../../../store/modal/actions';
import { SPINNER_TOGGLE_OFF, SPINNER_TOGGLE_ON } from '../../../store/spinner/types';
import { callAPI } from '../../../utils/network';
import { TokenService } from '@aglive/data-model';
import { stateHandler } from '../../promotion/ProductProfile/CreateProductProfile/productProfileState/actions';

const INITIAL_EDIT_BRAND_STATE = stateHandler(
  INITIAL_BRAND_STATE,
  {
    state: {
      mainLogo: '', // to track if mainLogo has changed after this edit, and update products appropriately
    }
  }
);

const editBrandReducer = (
  state: typeof INITIAL_EDIT_BRAND_STATE,
  action: baseBrandActionTypes | loadBrandActionTypes) => {
    switch (action.type) {
      case 'load/load_brand':
        return produce(state, draft => {
          draft.payload = action.payload.details;
          draft.state.mainLogo = action.payload.details.launchScreen.display.mainLogo;
        });
      default:
        return baseBrandReducer(state, action);
    }
  }

const EditBrand: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {id} = useParams<{ id: string }>();

  useEffect(() => {
    fetchBrandToken(dispatch, id)
    .then(targetBrand => localDispatch(loadBrandToken(targetBrand)))
    .catch(e => {
      const error = e as WebErrorType;

      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
        })
      );
    });
  }, []);

  const [editBrandState, localDispatch] = useReducer(editBrandReducer, INITIAL_EDIT_BRAND_STATE);

  const onSubmit = async () => {
    try {
      // edit brand
      dispatch({type: SPINNER_TOGGLE_ON});
      await callAPI({
        url: API.POST.createActivity,
        method: 'POST',
        data: {
          tokens: [
            {
              type: CONSTANT.ASSETTYPE.BRAND,
              externalIds: {
                agliveToken: id
              },
              activities: [
                {
                  type: 'UP_details',
                  details: editBrandState.payload,
                },
              ],
            },
          ],
        },
      });

      // update linked product profiles if any
      if (
        editBrandState.payload.products?.length && 
        editBrandState.payload.launchScreen.display.mainLogo !== editBrandState.state.mainLogo) {
        const linkedProductProfiles = await callAPI<Array<TokenService.ProductToken>>({
          url: API.POST.getTokenbyExternalId,
          method: 'POST',
          data: {
            latestDetails: true,
            status: ['exist'],
            externalIds: editBrandState.payload.products
          },
        });

        linkedProductProfiles.forEach(async (profile) => {
          if (profile.details.story.logoType === 'brand') {
            profile.details.story.logo = editBrandState.payload.launchScreen.display.mainLogo;
  
            await callAPI({
              url: API.POST.createActivity,
              method: 'POST',
              data: {
                tokens: [{
                  type: CONSTANT.ASSETTYPE.PRODUCT,
                  externalIds: {
                    agliveToken: profile.externalIds[0].agliveToken
                  },
                  activities: [
                    {
                      type: 'UP_details',
                      details: profile.details
                    }
                  ]
                }]
              }
            });
          }
        });
      }

      dispatch(
        toggleModal({
          status: 'success',
          title: 'Updated',
          button: 'Close',
          CTAHandler: () => history.push(BRAND_LIBRARY_PATH),
        }),
      );
    } catch (e) {
      const error = e as WebErrorType;

      dispatch(
        toggleModal({
          status: 'failed',
          title: error.title,
          subtitle: error.message,
          button: 'Close',
        }),
      );
    } finally {
      dispatch({type: SPINNER_TOGGLE_OFF});
    }
  }

  return (
    <Grid container direction="column">
      {/* <ProductHeader product={product} handleChange={handleChange} /> */}
      <BaseBrand
        localDispatch={localDispatch}
        brandState={editBrandState}
      />

      <Grid item container justify="flex-end">
        <MyButton
          text="Save"
          variant="contained"
          onClick={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default withHeader(
  {
    title: 'Edit Brand',
    margin: 60,
    back: true,
  },
  EditBrand
);
