// Reference: https://stackoverflow.com/a/45323523
import React, { useState, useEffect, useRef } from 'react';

const useComponentVisible = (initialIsVisible: boolean, dependentRef?: React.MutableRefObject<any>) => {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (dependentRef?.current && dependentRef.current.contains(event.target)) {
            setIsComponentVisible((prevState) => !prevState);
        }   
        else if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
}

export default useComponentVisible;
