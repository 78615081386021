import React, {useState, useEffect, useCallback} from 'react';
import {useAppDispatch, useAppSelector} from '../../utils/hooks';
import withHeader from '../../presentation/withHeader';
import MyButton from '../../presentation/button';
import {Grid, Typography, TextField} from '@material-ui/core';
import {updateUserProfile} from '../../store/profile/actions';
import {MobileInput} from '../add/AddEditUser';
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import {PageHeader} from '../../presentation/withHeader';
import CONSTANT from '../../config/constant';

const EditUserProfile: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector((state) => state.user.userProfileData);
  const businessProfile = useAppSelector(
    (state) => state.user.businessProfileData,
  );
  const userId = useAppSelector((state) => state.auth.wallet);
  const editState = {
    name: userProfile?.name,
    mobileNumber: userProfile?.mobileNumber,
    emailAddress: userProfile?.emailAddress,
  };
  const userLabels =
    businessProfile.businessCountry === 'Argentina'
      ? CONSTANT.ES_LABELS
      : CONSTANT.EN_LABELS;

  const [saving, setSaving] = useState(false);
  const [nameError, setNameError] = useState({
    isError: false,
    helperText: '',
  });
  const [phoneError, setPhoneError] = useState({
    isError: false,
    helperText: '',
  });
  const [emailAddressError, setEmailAddressError] = useState({
    isError: false,
    helperText: '',
  });
  const [editList, setEditList] = useState(editState);
  const [mobileValue, setMobileValue] = useState(
    /^\+/.test(editList.mobileNumber)
      ? editList.mobileNumber
      : '+61' + editList.mobileNumber,
  );

  const handleSelection = (key: keyof typeof editState) => (e) => {
    e.persist();
    setEditList((prevState) => ({...prevState, [key]: e.target.value}));
  };
  // Validate Input Field
  const handleInputFieldError = (inputFieldList: any) => {
    const inputFieldKeys = Object.keys(inputFieldList);

    inputFieldKeys.map((key) => {
      if (key === 'mobileNumber') {
        if (!isPossiblePhoneNumber(inputFieldList[key] ?? '')) {
          setPhoneError({
            isError: true,
            helperText: userLabels.validNumber,
          });
        } else {
          setPhoneError({isError: false, helperText: ''});
        }
      } else if (key === 'emailAddress') {
        let regex =
          /^([a-zA-Z0-9\.-]+)@([a-zA-Z0-9-]+).([a-z]{2,8})(.[a-z]{2,8})?$/;
        if (regex.test(inputFieldList[key]) === false) {
          setEmailAddressError({
            isError: true,
            helperText: userLabels.validEmail,
          });
        } else if (inputFieldList[key].trim() === '') {
          setEmailAddressError({
            isError: true,
            helperText: userLabels.requiredField,
          });
        } else {
          setEmailAddressError({isError: false, helperText: ''});
        }
      } else if (key === 'name') {
        if (inputFieldList[key] === '') {
          setNameError({isError: true, helperText: userLabels.requiredField});
        } else {
          setNameError({isError: false, helperText: ''});
        }
      }
    });
  };
  useEffect(() => {
    setEditList(editState);
  }, [userProfile]);
  useEffect(() => {
    setEditList((prevState) => ({...prevState, mobileNumber: mobileValue}));
  }, [mobileValue]);

  useEffect(() => {
    if (!saving) {
      return;
    }

    if (nameError.isError || phoneError.isError || emailAddressError.isError) {
      return;
    }
    dispatch(
      updateUserProfile(
        editList,
        userId,
        businessProfile.businessCountry,
      ),
    );
    setSaving(false);
  }, [nameError, emailAddressError, phoneError, saving]);

  return (
    <PageHeader
      config={{title: userLabels.editUserProfile, margin: 60, back: true}}>
      <Grid container direction="row" spacing={3}>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {userLabels.fullName}
          </Typography>
          <TextField
            variant="outlined"
            value={editList.name}
            onChange={handleSelection('name')}
            helperText={nameError.helperText}
            error={nameError.isError}
          />
        </Grid>
        <Grid item container xs={6} direction="column">
          <Typography variant="h6" style={{fontWeight: 600}}>
            {userLabels.phone}
          </Typography>
          <PhoneInput
            international
            defaultCountry="AU"
            placeholder={userLabels.enterNumber}
            countryCallingCodeEditable={false}
            inputComponent={MobileInput}
            value={mobileValue}
            countries={['AU', 'CA', 'AR']}
            onChange={setMobileValue}
          />
        </Grid>
        <Grid item container xs={6} direction="column" style={{marginTop: 32}}>
          <Typography variant="h6" style={{fontWeight: 600}}>
            {userLabels.emailAddress}
          </Typography>
          <TextField
            variant="outlined"
            value={editList.emailAddress}
            onChange={handleSelection('emailAddress')}
            helperText={emailAddressError.helperText}
            error={emailAddressError.isError}
          />
        </Grid>
        <Grid item container justify="flex-end" style={{marginTop: 300}}>
          <MyButton
            text={userLabels.save}
            variant="contained"
            onClick={() => {
              setSaving(true);
              handleInputFieldError(editList);
            }}
          />
        </Grid>
      </Grid>
    </PageHeader>
  );
};

export default EditUserProfile;
