import produce from 'immer';
import {ProductPromotionActions, InitialState} from './types';

export const INITIAL_STATE: InitialState = {
  state: {
    footerImageOption: 'image',
    originalEndDate: '',
    selectedProductProfile: 0,
    productProfiles: [],
    submitted: '',
  },
  payload: {
    status: 'draft',
    type: 'PRODUCTS',
    archive: false,
    name: '',
    product: {
      agliveToken: '',
    },
    display: {
      footerImage: null,
      startDate: '',
      endDate: '',
    },
  },
};

export const productPromotionReducer = (
  state: InitialState,
  action: ProductPromotionActions,
) => {
  switch (action.type) {
    case 'promotion/name':
      return produce(state, (draft) => {
        draft.payload.name = action.payload;
      });
    case 'promotion/select_product':
      return produce(state, (draft) => {
        draft.payload.product.agliveToken = action.payload;
        const productProfileIndex = state.state.productProfiles.findIndex(
          (productProfile) =>
            productProfile.externalIds[0].agliveToken === action.payload,
        );
        draft.state.selectedProductProfile = productProfileIndex;
      });
    case 'promotion/footer_image_option':
      return produce(state, (draft) => {
        draft.state.footerImageOption = action.payload;
      });
    case 'promotion/original_end_date':
      return produce(state, (draft) => {
        draft.state.originalEndDate = action.payload;
      });
    case 'promotion/product_profile':
      return produce(state, (draft) => {
        draft.state.productProfiles = action.payload;
      });
    case 'submit':
      return produce(state, (draft) => {
        draft.state.submitted = action.payload;
      });
    // Populate state with existing promotion
    case 'promotion/retrieve_token':
      return produce(state, (draft) => {
        draft.payload = action.payload;
      });
    // Display Poster
    case 'display/footer_image':
      return produce(state, (draft) => {
        draft.payload.display.footerImage = action.payload;
      });
    case 'display/start_date':
      return produce(state, (draft) => {
        draft.payload.display.startDate = action.payload;
      });
    case 'display/end_date':
      return produce(state, (draft) => {
        draft.payload.display.endDate = action.payload;
      });
    default:
      return state;
  }
};
