import withHeader from '../../presentation/withHeader';
import React, { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles, withStyles } from '@material-ui/core';
import MyButton from '../../presentation/button';
import { assetStyles } from '../asset/Components';
import COLOR from '../../styled/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import MuiTableCell from "@material-ui/core/TableCell";
import { Line } from '../../presentation/Line';
import { useLocation, useHistory } from 'react-router-dom';
import { getAnimalProfile, deleteAnimal} from '../../store/angus/actions';
import { Buttons } from '../../presentation/ButtonsGroup';
import { toggleModal, toggleModalOff } from '../../store/modal/actions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';

export const useStyles = makeStyles((theme) => ({
  greyBox: {
    backgroundColor: COLOR.GRAY_SOLID,
    marginTop: 20,
    marginBottom: 80,
    padding: 15,
    maxWidth: window.innerWidth / 2,
  },
  buttons: {
    display: 'flex',
    marginTop: 20,
  },
  button: {
    display: 'flex',
    flexShrink:1,
    marginRight: 30,
    color: COLOR.WHITE,
    backgroundColor: COLOR.RED,
    borderColor: COLOR.RED,
    '&:hover': {
      backgroundColor: COLOR.RED,
      borderColor: COLOR.RED,
    }
  },
}));

const TableCell = withStyles({
  root: {
    borderBottom: "none"
  }
})(MuiTableCell);

const ViewAnimalProfile: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const state = useLocation<{ rfid?: string; nlis?: string }>().state;
  const { rfid, nlis } = state;
  const details = {
    acct_id: '',
    birth_end: '',
    birth_start: '',
    birth_year: 0,
    breederPicAddress: '',
    externalIds: { nlis_id: '', rfid: '' },
    nlis_id: '',
    pic: '',
    recordedIn: '',
    rfid: '',
    sex: '',
    sire_group_id: '',
    sire_id: '',
    sire_nlis_id: '',
    sire_rfid: '',
    visual_tag: '',
    verified_date: '',
  }
  const [animalDetails, setAnimalDetails] = useState(details);
  const classes = { ...assetStyles(), ...useStyles() };
  const businessProfile = useAppSelector(
    state => state.user.businessProfileData,
  );
  const userId = useAppSelector(state => state.auth.wallet);
  useEffect(() => {
    dispatch(getAnimalProfile(rfid ? { rfid: rfid } : { nlis_id: nlis }))
    .then((resp) => {
      if(resp.length>0){
        setAnimalDetails({ ...resp[0].details, verified_date: resp[0].externalIds[0].timestamp });
      }
      else{
        dispatch(toggleModal({
          status: 'warning',
          title: 'Animal is not exist',
          CTAHandler: () => history.push(`/private/verified/animalProfile`)
        })); 
      }
    });
  }, []);

  const getBirthRange = () => {
    return (
      animalDetails.birth_start ? 
      animalDetails.birth_start.split('-')[1] + '-' +
      animalDetails.birth_start.split('-')[0] + ' to ' +
      animalDetails.birth_end.split('-')[1] + '-' +
      animalDetails.birth_end.split('-')[0]
      : ''
      )
  }

  const rows = [
    { name: 'Sex', value: animalDetails.sex },
    { name: 'Birth Range', value: getBirthRange() },
  ];

  return (<>
    <Grid>
      <Typography variant="h3">
        Details
      </Typography>
      <Grid container className={classes.greyBox}>
        <TableContainer>
          <Table>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left" style={{ fontWeight: 700 }}>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Typography variant="h3">
        Profile
      </Typography>
      <Line />
    </Grid>
    <Grid container spacing={3} className={classes.buttons} >
      <Grid item xs={4}>
          <MyButton
            disabled={false}
            text={'Angus Verified'}
            buttonClass={classes.button}
            variant="outlined"
            onClick={() => {
              history.push(`/private/verified/animalProfile/angus-verified`, { title: 'Animal Profile', details: animalDetails });
            }}
          />
        </Grid>
     
      {animalDetails.sex !== 'Steer' &&
        <Grid item xs={4}>
          <MyButton
            disabled={false}
            text={'Pregnancy'}
            buttonClass={classes.button}
            variant="outlined"
            onClick={() => {
              history.push(`/private/verified/animalProfile/Pregnancy`, { title: 'Animal Profile' });
            }}
          />
        </Grid>
      }
      
      <Grid item xs={4}>
        <MyButton
          disabled={false}
          text={'Treatment'}
          buttonClass={classes.button}
          variant="outlined"
          onClick={() => {
            history.push(`/private/verified/animalProfile/treatment`, { title: 'Animal Profile' });
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <MyButton
          disabled={false}
          text={'Delete Animal'}
          buttonClass={classes.button}
          variant="outlined"
          onClick={() => {
            dispatch(
              toggleModal({
                status: 'warning',
                title: 'Delete Animal?',
                subtitle: 'This action cannot be undone.',
                renderButton: (
                  <Buttons
                    leftButtonTitle="No"
                    rightButtonTitle="Yes"
                    leftButtonOnClick={() => {
                      dispatch(toggleModalOff());
                    }}
                    rightButtonOnClick={() => {
                      dispatch(toggleModalOff());
                      dispatch(deleteAnimal(
                        userId,rfid ? { rfid: rfid } : { nlis_id: nlis },
                        businessProfile.industryType == "ANIMALS"? businessProfile.angusProfile.angusAuthToken: undefined, 
                        history
                      ))
                    }}
                  />
                ),
              }),
            );
          }}
        />
      </Grid>
    </Grid>
  </>);
};

export default withHeader(
  {
    title: 'Animal Profile',
    margin: 60,
    back: true,
  },
  ViewAnimalProfile,
);