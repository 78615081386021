import produce from 'immer';
import _ from 'lodash';
import moment from 'moment';
import ReactPlayer from 'react-player/youtube';
import {TokenService} from '@aglive/data-model';

import {getBrandAgliveTokenId} from '../../brand/utils';
import {
  INITIAL_ADDITIONAL_SECTION,
  INITIAL_MILESTONES,
  INITIAL_MOB,
  INITIAL_STATE,
} from './assetPromotionState/reducer';
import {InitialState} from './assetPromotionState/types';
import {TabMap as StoryTabMap} from '../TellYourStory/TellYourStory';
import {TabMap as MobTabMap} from '../MobDetails/MobDetails';

export const processBrandList = (
  unprocessedBrandList: Array<TokenService.BrandToken>,
): Array<{
  name: string;
  agliveToken: string;
  logo: string;
}> => {
  return unprocessedBrandList.map((brand) => ({
    name: brand.details.name,
    agliveToken: getBrandAgliveTokenId(brand),
    logo: brand.details.launchScreen.display.mainLogo,
  }));
};

export const validateData = (
  assetPromotion: TokenService.AnimalPromotion['details'],
  mainSectionImageOption: 'image' | '',
  footerImageOption: 'image' | '',
  sectionToValidate: InitialState['state']['section'],
  submissionType?: 'draft' | 'publish' | '',
): [
  TokenService.AnimalPromotion['details'],
  number,
  InitialState['state']['section'],
  InitialState['state']['focusedTabIndex'],
  InitialState['state']['focusedMobTabIndex'],
] => {
  let error = 0;
  let section: InitialState['state']['section'] = null;
  let storyTab: InitialState['state']['focusedTabIndex'] = null;
  let mobTab: InitialState['state']['focusedMobTabIndex'] = null;

  const validationState = produce(INITIAL_STATE.payload, (draft) => {
    while (
      draft.story.additional.length < assetPromotion.story.additional.length
    ) {
      draft.story.additional.splice(
        draft.story.additional.length - 1,
        0,
        _.cloneDeep(INITIAL_ADDITIONAL_SECTION),
      );
    }

    while (
      draft.story.milestones.length < assetPromotion.story.milestones.length
    ) {
      draft.story.milestones.splice(
        draft.story.milestones.length - 1,
        0,
        _.cloneDeep(INITIAL_MILESTONES),
      );
    }

    // Validating the 'display' section means validating the entire form since 'display' is the last step
    if (sectionToValidate === 'display') {
      if (!assetPromotion.name.trim().length) {
        draft.name = 'This field is required';
        ++error;
      }
      if (!assetPromotion.brand.agliveToken.trim().length) {
        draft.brand.agliveToken = 'This field is required';
        ++error;
      }
    }
    // Tell Your Story - Main Section
    if (
      assetPromotion.story.logoType === 'user' &&
      !assetPromotion.story.logo?.trim().length
    ) {
      draft.story.logo = 'This field is required';
      ++error;
      section ?? (section = 'story');
      storyTab ?? (storyTab = StoryTabMap.mainSection);
    }
    if (
      mainSectionImageOption === 'image' &&
      !assetPromotion.story.image?.trim().length
    ) {
      draft.story.image = 'This field is required';
      ++error;
      section ?? (section = 'story');
      storyTab ?? (storyTab = StoryTabMap.mainSection);
    }
    // Tell Your Story - Additional Section
    assetPromotion.story.additional.forEach((additional, index) => {
      if (additional.mediaType === 'image' && !additional.url?.trim().length) {
        draft.story.additional[index].url = 'This field is required';
        ++error;
        section ?? (section = 'story');
        storyTab ?? (storyTab = StoryTabMap.additionalSection);
      } else if (
        additional.mediaType === 'video' &&
        !ReactPlayer.canPlay(additional.url)
      ) {
        draft.story.additional[index].url =
          'Please provide a valid YouTube URL';
        ++error;
        section ?? (section = 'story');
        storyTab ?? (storyTab = StoryTabMap.additionalSection);
      }
    });
    // Mob
    if (sectionToValidate !== 'story') {
      if (!assetPromotion.mob.linkMob?.mobId) {
        draft.mob.linkMob = {...INITIAL_MOB, mobId: 'This field is required'};
        ++error;
        section ?? (section = 'mob');
      }
      if (!assetPromotion.mob.media.url?.trim().length) {
        draft.mob.media.url = 'This field is required';
        ++error;
        section ?? (section = 'mob');
      } else if (
        assetPromotion.mob.media.item === 'video' &&
        !ReactPlayer.canPlay(assetPromotion.mob.media.url)
      ) {
        draft.mob.media.url = 'Please provide a valid YouTube URL';
        ++error;
        section ?? (section = 'mob');
      }
      if (assetPromotion.mob.linkMob && !assetPromotion.mob.msa) {
        // True flag here indicates the MSA is missing
        draft.mob.msa = true;
        ++error;
        section ?? (section = 'mob');
      }
      if (assetPromotion.mob.linkMob && !assetPromotion.mob.signed) {
        // True flag here indicates the signature is missing
        draft.mob.signed = true;
        ++error;
        section ?? (section = 'mob');
      }
      if (section === 'mob') {
        mobTab = MobTabMap.mainSection;
      }
    }
    // Display
    if (sectionToValidate === 'display') {
      if (
        footerImageOption === 'image' &&
        !assetPromotion.display.footerImage?.trim().length
      ) {
        draft.display.footerImage = 'This field is required';
        ++error;
        section ?? (section = 'display');
      }
      // Only go through start date / end date validations when user publishes the promotion
      if (submissionType === 'publish') {
        if (assetPromotion.display.startDate === '') {
          draft.display.startDate = 'This field is required';
          ++error;
          section ?? (section = 'display');
        }
        if (assetPromotion.display.endDate === '') {
          draft.display.endDate = 'This field is required';
          ++error;
          section ?? (section = 'display');
        } else if (
          draft.display.startDate === '' &&
          moment(assetPromotion.display.startDate).valueOf() >
            moment(assetPromotion.display.endDate).valueOf()
        ) {
          draft.display.endDate = 'End date must be after start date';
          ++error;
          section ?? (section = 'display');
        } else if (
          assetPromotion.status === 'published' &&
          moment(assetPromotion.display.endDate).valueOf() < moment().valueOf()
        ) {
          draft.display.endDate = 'New end date must be in the future';
          ++error;
          section ?? (section = 'display');
        }
      }
    }
  });

  return [validationState, error, section, storyTab, mobTab];
};
